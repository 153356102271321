import { useRef } from "react";

import { ReactComponent as SubMenuItemArrow } from "../../../../../../assets/return-down-forward-outline.svg";
import { apprenticeshipRiskCareerAssessmentMockScoreData as mockScore } from "../../../../../../utils/mockData";

import Interests from "../CareeringAhead/Interests";
import ApprenticeshipRisk from "./ApprenticeshipRisk";
import JumpToLink from "../../SharedChartComponents/JumpToLink";
import DashboardLayout from "../../../../../SharedComponents/DashboardLayout";
import HeadFirst from "../HeadFirst/HeadFirst";
import SideSection from "../../SharedChartComponents/SideSection";
import ReportHeader from "../../SharedChartComponents/ReportHeader";

export default function ApprenticeshipRiskCareerAssessment(props) {
    const { testId } = props;

    const apprenticeRisk = useRef(null);
    const headFirst = useRef(null);
    const interests = useRef(null);
    const interestsGraphicalSummary = useRef(null);
    const interestsDetailedSummary = useRef(null);
    const interestsRecommendations = useRef(null);

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop - (window.innerWidth < 1280 ? 170 : 20),
            behavior: "smooth",
        });
    };

    return (
        <DashboardLayout title={"Report"} requiredPermissions={[]}>
            <div className="flex flex-col gap-6">
                <ReportHeader reportName="Apprenticeship Risk & Career Assessment Report" userName="Psych Press" />
                <div
                    className={
                        "flex flex-row gap-2 print:hidden sticky top-4 left-4 bg-white p-3 rounded-xl " +
                        "border border-slate-100 shadow-lg z-50 relative xl:hidden print:hidden"
                    }
                >
                    <div className="flex flex-row flex-wrap gap-3">
                        <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                        <JumpToLink
                            text="Apprenticeship Risk"
                            colour={"primary"}
                            scrollTo={() => scrollToSection(apprenticeRisk)}
                        />
                        <JumpToLink text="HeadFirst" colour={"primary"} scrollTo={() => scrollToSection(headFirst)} />
                        <JumpToLink text="Interests" colour={"primary"} scrollTo={() => scrollToSection(interests)} />
                        <JumpToLink
                            text="Graphical summary"
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(interestsGraphicalSummary)}
                        />
                        <JumpToLink
                            text="Detailed interpretation"
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(interestsDetailedSummary)}
                        />
                        <JumpToLink
                            text="Recommendations"
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(interestsRecommendations)}
                        />
                    </div>
                </div>
                <div className={"grid grid-cols-1 xl:grid-cols-3 gap-6 print:p-0"}>
                    <div className="col-span-2 flex flex-col gap-8" data-testid={testId && testId}>
                        <div ref={apprenticeRisk}>
                            <ApprenticeshipRisk score={mockScore.apprenticeshipRisks.reportFields} />
                        </div>
                        <div ref={headFirst}>
                            <HeadFirst score={mockScore.headfirst} coacheeName="Psych Press" onlyContent />
                        </div>
                        <div ref={interests}>
                            <Interests
                                score={mockScore.interests}
                                graphicalSummaryRef={interestsGraphicalSummary}
                                detailedInterpretationRef={interestsDetailedSummary}
                                recommendationsRef={interestsRecommendations}
                                coacheeName="Psych Press"
                                onlyContent
                            />
                        </div>
                    </div>
                    <SideSection
                        extraContent={
                            <div
                                className={
                                    "flex flex-col gap-4 print:hidden sticky top-4 left-4 p-4 rounded-xl " +
                                    "shadow-xl border border-slate-100 z-50 hidden w-auto xl:flex 2xl:w-max " +
                                    "print:hidden bg-white mt-10"
                                }
                            >
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                                </div>
                                <div className="flex flex-col gap-3">
                                    <JumpToLink
                                        text="Apprentice Risk"
                                        colour={"primary"}
                                        scrollTo={() => scrollToSection(apprenticeRisk)}
                                    />
                                    <JumpToLink
                                        text="HeadFirst"
                                        colour={"primary"}
                                        scrollTo={() => scrollToSection(headFirst)}
                                    />
                                    <div className="flex flex-col gap-2">
                                        <JumpToLink
                                            text="Interests"
                                            colour={"primary"}
                                            scrollTo={() => scrollToSection(interests)}
                                        />
                                        <div className="flex flex-col gap-2 ml-2">
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Graphical summary"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(interestsGraphicalSummary)}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Detailed interpretation"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(interestsDetailedSummary)}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Recommendations"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(interestsRecommendations)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}
