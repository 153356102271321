import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowsRightLeftIcon, FlagIcon, UserPlusIcon, UsersIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away-subtle.css";

import {
    getCoacheesWithResumes,
    getCoacheesWithResumesForCoach,
    getPrograms,
    getUserCohortsCoacheesWithResumes,
} from "../../../../utils/api";
import {
    ACE_2_SURVEY_PLATFORM,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    CUSTOMER_PORTAL_PERMISSIONS,
    EMPLOYMENT_SERVICES,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import JobSeekerRow from "./JobSeekerRow";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import TabbedNavigationLayout from "../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import BasicMotion from "../../../SharedComponents/Motion/BasicMotion";
import BouncingBalls from "../../../SharedComponents/Loading/BouncingBalls";
import Button from "../../../SharedComponents/Button";

export default function JobSeekers() {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [allJobSeekers, setAllJobSeekers] = useState(false);
    const [isMyJobSeekers, setIsMyJobSeekers] = useState(false);
    const [userCohortsJobSeekers, setUserCohortsJobSeekers] = useState(false);
    const [allJobSeekersLoading, setAllJobSeekersLoading] = useState(true);
    const [tabContent, setTabContent] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [requestLoading, setRequestLoading] = useState(false);
    const [programWithUsersLoading, setProgramWithUsersLoading] = useState(true);

    const context = useContext(Context);

    const navigate = useNavigate();

    const location = useLocation();

    const fetchProgramsAndUsers = useCallback(
        async (abortController, isMyJobSeekers) => {
            if (!allJobSeekersLoading) {
                let jobSeekersResponse = null;

                try {
                    setRequestLoading(true);

                    if (!isMyJobSeekers) {
                        if (allJobSeekers) {
                            jobSeekersResponse = await getCoacheesWithResumes(abortController);
                        } else if (userCohortsJobSeekers) {
                            jobSeekersResponse = await getUserCohortsCoacheesWithResumes(abortController);
                        } else {
                            jobSeekersResponse = await getCoacheesWithResumesForCoach(abortController);

                            jobSeekersResponse.map((jobSeeker) => {
                                jobSeeker.coachIdentifier = context.profileDetails.userIdentifier;
                                jobSeeker.coachName = `${context.profileDetails.firstName} ${context.profileDetails.lastName}`;
                                return jobSeeker;
                            });
                        }
                    } else {
                        jobSeekersResponse = await getCoacheesWithResumesForCoach(abortController);

                        jobSeekersResponse.map((jobSeeker) => {
                            jobSeeker.coachIdentifier = context.profileDetails.userIdentifier;
                            jobSeeker.coachName = `${context.profileDetails.firstName} ${context.profileDetails.lastName}`;
                            return jobSeeker;
                        });
                    }

                    let programsWithUsers = await getPrograms();

                    const usersWithoutProgram = {
                        name: context.terminology.coachee.plural
                            ? `${context.terminology.coachee.plural} without programs`
                            : "Job seekers without programs",
                        users: [],
                    };

                    programsWithUsers.forEach((program) => {
                        program.users = [];

                        jobSeekersResponse.forEach((jobSeeker) => {
                            jobSeeker.cohorts &&
                                jobSeeker.cohorts.forEach((cohort) => {
                                    if (program.cohorts.includes(cohort) && !program.users.includes(jobSeeker)) {
                                        program.users.push(jobSeeker);
                                    }
                                });
                        });
                    });

                    jobSeekersResponse.forEach((jobSeeker) => {
                        if (!jobSeeker.cohorts || jobSeeker.cohorts.length === 0) {
                            usersWithoutProgram.users.push(jobSeeker);
                        }
                    });

                    if (usersWithoutProgram.users.length > 0) {
                        programsWithUsers.push(usersWithoutProgram);
                    }

                    if (!allJobSeekers) {
                        programsWithUsers = programsWithUsers.filter(
                            (program) => program.users && program.users.length > 0
                        );
                    }

                    if (programsWithUsers) {
                        setProgramWithUsersLoading(true);
                        const updatedTabList = programsWithUsers.map((program) => program.name);
                        const updatedTabContent = programsWithUsers.map((program) => ({
                            title: program.name,
                            content: (
                                <PaginatedSearch
                                    loading={loading}
                                    items={program.users}
                                    itemName={
                                        context.terminology.coachee ? context.terminology.coachee.plural : "Job seekers"
                                    }
                                    searchAttributes={["firstName", "lastName", "emailAddress", "cohorts", "coachName"]}
                                    columnNames={
                                        context.defaultApp === EMPLOYMENT_SERVICES
                                            ? [
                                                  <div className="flex items-center justify-center">
                                                      <Tippy
                                                          content={
                                                              context.terminology.coachee.singular +
                                                              " flags e.g. Missing resume or incomplete assessments."
                                                          }
                                                          className={
                                                              "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-primary-600 text-white"
                                                          }
                                                          arrow={false}
                                                          animation={"shift-away-subtle"}
                                                          placement={"right"}
                                                      >
                                                          <FlagIcon className="block h-3.5 w-3.5" />
                                                      </Tippy>
                                                  </div>,
                                                  "Name",
                                                  "Cohort",
                                                  context.terminology.coach
                                                      ? context.terminology.coach.singular
                                                      : "Consultant",
                                                  "Manage",
                                                  "Resume",
                                                  "Assessments",
                                              ]
                                            : [
                                                  <div className="flex items-center justify-center">
                                                      <Tippy
                                                          content={
                                                              context.terminology.coachee.singular +
                                                              " flags e.g. Missing resume or incomplete assessments."
                                                          }
                                                          className={
                                                              "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-primary-600 text-white"
                                                          }
                                                          arrow={false}
                                                          animation={"shift-away-subtle"}
                                                          placement={"right"}
                                                      >
                                                          <FlagIcon className="block h-3.5 w-3.5" />
                                                      </Tippy>
                                                  </div>,
                                                  "Name",
                                                  "Cohort",
                                                  context.terminology.coach
                                                      ? context.terminology.coach.singular
                                                      : "Consultant",
                                                  "Manage",
                                                  "Assessments",
                                              ]
                                    }
                                    ItemRow={JobSeekerRow}
                                    setAlert={setAlert}
                                    customPlaceholder={
                                        "Search " +
                                        (context.terminology.coachee
                                            ? context.terminology.coachee.plural.toLowerCase()
                                            : "coachees") +
                                        " by first name, last name or email address"
                                    }
                                />
                            ),
                            readonly: true,
                        }));
                        setTabList(updatedTabList);
                        setTabContent(updatedTabContent);

                        setProgramWithUsersLoading(false);
                    }
                } catch (err) {
                    setAlert(err.message);
                    setAlertType(ALERT_TYPE.ERROR);
                    setProgramWithUsersLoading(false);
                } finally {
                    // Update the loading state only if the request was not aborted
                    if (!abortController.signal.aborted) {
                        setRequestLoading(false);
                    }
                }
            }
            setLoading(false);
        },
        [
            allJobSeekers,
            allJobSeekersLoading,
            context.defaultApp,
            context.profileDetails.firstName,
            context.profileDetails.lastName,
            context.profileDetails.userIdentifier,
            context.terminology.coach,
            context.terminology.coachee,
            loading,
            userCohortsJobSeekers,
        ]
    );

    const handleParticipantsChange = () => {
        setIsMyJobSeekers(!isMyJobSeekers);
    };

    useEffect(() => {
        const abortController = new AbortController();

        fetchProgramsAndUsers(abortController, isMyJobSeekers);

        return () => {
            // Abort all the ongoing requests when component will unmount
            abortController.abort();
        };
    }, [fetchProgramsAndUsers, isMyJobSeekers]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllJobSeekers(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES));
            setUserCohortsJobSeekers(
                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_USER_COHORTS_COACHEES)
            );
            setAllJobSeekersLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout
            title={
                !isMyJobSeekers ? `${context.terminology.coachee.plural}` : `My ${context.terminology.coachee.plural}`
            }
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.VIEW_COACHEES_LIST,
                COACH_PLATFORM_PERMISSIONS.VIEW_COACHEES_WITH_ASSESSMENTS,
                CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST,
            ]}
            actionButton
            actionButtonRequiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.SETUP_COACHEE,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
            actionButtonText={`Setup ${context.terminology.coachee.singular}`}
            actionButtonIcon={<UserPlusIcon className="block h-3.5 w-3.5 mr-2" />}
            actionButtonOnClick={() => navigate(`/setup-user/${context.terminology.coachee.singular.toLowerCase()}`)}
            otherElements={
                context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text={
                            "Setup multiple " +
                            (context.terminology.coachee ? context.terminology.coachee.plural : "Coachees")
                        }
                        icon={<UsersIcon className={"h-4 w-4 text-white mr-2"} />}
                        iconPositionLeft
                        onClick={() => navigate(`/setup-multiple-${context.terminology.coachee.plural.toLowerCase()}`)}
                    />
                )
            }
            // Only show secondary button when user can see all job seekers or user cohort job seekers
            secondaryActionButton={allJobSeekers || userCohortsJobSeekers}
            secondaryActionButtonRequiredPermissions={[]}
            secondaryActionButtonText={
                isMyJobSeekers ? `${context.terminology.coachee.plural}` : `My ${context.terminology.coachee.plural}`
            }
            secondaryActionButtonIcon={
                <ArrowsRightLeftIcon className="block h-3.5 w-3.5 mr-2 stroke-current stroke-1" />
            }
            secondaryActionButtonOnClick={handleParticipantsChange}
        >
            <Alert text={alert} type={alertType} active={alert !== "canceled" && alert} close={() => setAlert("")} />
            {loading || programWithUsersLoading || allJobSeekersLoading ? (
                <div className={"col-span-full flex items-center justify-center pt-20 pb-24 w-full"}>
                    <BouncingBalls />
                </div>
            ) : loading ||
              programWithUsersLoading ||
              allJobSeekersLoading ||
              requestLoading ||
              (tabContent && tabContent.length > 0) ? (
                <TabbedNavigationLayout
                    contentLoading={loading || allJobSeekersLoading || programWithUsersLoading || requestLoading}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabList={tabList}
                    backgroundColour={"bg-slate-100"}
                    tabsRowOnly
                    tabContent={tabContent}
                    tabsTitle={"Programs"}
                    noContainer
                    secondaryTabs
                />
            ) : (
                <BasicMotion>
                    <PaginatedSearch
                        loading={loading}
                        items={[]}
                        itemName={context.terminology.coachee ? context.terminology.coachee.plural : "Job seekers"}
                        searchAttributes={["firstName", "lastName", "emailAddress", "cohorts", "coachName"]}
                        columnNames={
                            context.defaultApp === EMPLOYMENT_SERVICES
                                ? [
                                      <div className="flex items-center justify-center">
                                          <Tippy
                                              content={
                                                  context.terminology.coachee.singular +
                                                  " flags e.g. Missing resume or incomplete assessments."
                                              }
                                              className={
                                                  "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-primary-600 text-white"
                                              }
                                              arrow={false}
                                              animation={"shift-away-subtle"}
                                              placement={"right"}
                                          >
                                              <FlagIcon className="block h-3.5 w-3.5" />
                                          </Tippy>
                                      </div>,
                                      "Name",
                                      "Cohort",
                                      context.terminology.coach ? context.terminology.coach.singular : "Consultant",
                                      "Manage",
                                      "Resume",
                                      "Assessments",
                                  ]
                                : [
                                      <div className="flex items-center justify-center">
                                          <Tippy
                                              content={
                                                  context.terminology.coachee.singular +
                                                  " flags e.g. Missing resume or incomplete assessments."
                                              }
                                              className={
                                                  "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-primary-600 text-white"
                                              }
                                              arrow={false}
                                              animation={"shift-away-subtle"}
                                              placement={"right"}
                                          >
                                              <FlagIcon className="block h-3.5 w-3.5" />
                                          </Tippy>
                                      </div>,
                                      "Name",
                                      "Cohort",
                                      context.terminology.coach ? context.terminology.coach.singular : "Consultant",
                                      "Manage",
                                      "Assessments",
                                  ]
                        }
                        ItemRow={JobSeekerRow}
                        setAlert={setAlert}
                        customPlaceholder={
                            "Search " +
                            (context.terminology.coachee
                                ? context.terminology.coachee.plural.toLowerCase()
                                : "coachees") +
                            " by first name, last name or email address"
                        }
                    />
                </BasicMotion>
            )}
        </DashboardLayout>
    );
}
