import { useContext } from "react";
import { LockClosedIcon, UserIcon } from "@heroicons/react/24/solid";

import { Context } from "../../context/Context";

import LoginLayout from "./LoginLayout";
import Input from "../SharedComponents/Form/Input";
import Button from "../SharedComponents/Button";

function Login() {
    const context = useContext(Context);

    return (
        <LoginLayout>
            <div className="w-max w-[40rem] py-10 px-5 md:px-10 grid grid-flow-row gap-4 bg-white rounded-lg shadow-sm">
                <div className="grid-flow-row grid gap-4">
                    <div className="flex items-start justify-between">
                        <h1 className="font-semibold tracking-tight text-3xl text-indigo-900 text-left">Login</h1>
                        <img alt="logo" className="max-h-9" src={context.getImage("shortLogo")} />
                    </div>
                    <p className="text-left text-gray-500">Enter your details to log in below</p>
                </div>
                <div className="flex">
                    <form className="w-full">
                        <div className="grid grid-flow-row gap-2">
                            <Input
                                id="username"
                                type="text"
                                name="Username"
                                placeholder="Enter username or email"
                                Icon={<UserIcon className="h-4 w-4 text-gray-500" />}
                            />
                            <Input
                                id="password"
                                type="password"
                                name="Password"
                                placeholder="Enter password"
                                Icon={<LockClosedIcon className="h-4 w-4 text-gray-500" />}
                                subLabel="If you're an SSO user please leave this field (password) blank"
                            />
                        </div>
                        <div className="text-right mt-1">
                            <a href={"/forgot-password"} className="text-primary-600 font-medium text-sm">
                                Forgot your password?
                            </a>
                        </div>
                    </form>
                </div>
                <div className="grid grid-flow-row gap-2 mt-2">
                    <Button
                        text="Login"
                        extraStyling={
                            "w-full px-3 py-3 font-medium rounded-md focus:outline-none " +
                            "button hover:bg-primary-700 text-base text-white focus:bg-primary-700 " +
                            "text-white focus:ring-inset focus:ring-1 bg-primary-600 hover:bg-primary-700 " +
                            "focus:outline-none focus:ring-primary-600 justify-center"
                        }
                    />
                </div>
            </div>
        </LoginLayout>
    );
}

export default Login;
