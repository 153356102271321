import SausageLegendItem from "../Charts/CustomCharts/CustomLegends/SausageLegendItem";

export default function OverallAgreeanceLegend(props) {
    const { noContainer } = props;

    return (
        <div
            className={
                "flex flex-row gap-2 justify-start w-fit print:p-0 print:shadow-none items-center " +
                "print:border-none " +
                (noContainer ? "" : " bg-white p-3 rounded-xl border border-slate-100 shadow-sm sticky top-10 z-30")
            }
        >
            <SausageLegendItem label="Overall disagree" backgroundColour="bg-red-500" large />
            <SausageLegendItem label="Overall agree" backgroundColour="bg-green-500" large />
        </div>
    );
}
