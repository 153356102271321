import { useState } from "react";

import {
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    SALEABLE_GROWTH_BAR_CHART_PALETTE,
    SALEABLE_PROSPECTING_BAR_CHART_PALETTE,
    SALEABLE_SELLING_BAR_CHART_PALETTE,
    PPI_CATEGORY_COLOURS,
    PPI_SUPER_CATEGORY_COLOURS,
    RSI_CATEGORY_COLOURS,
} from "../../../../../../utils/constants";

import Scale from "../MenuDriven/ChartCards/Scale";
import TabbedNavigationLayout from "../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import BarChart from "../../Charts/CustomCharts/BarChart";
import CoachingGuidelineCard from "../MenuDriven/CoachingGuidelineCard";

export default function SaleableContent(props) {
    const { score, jumpToRefs, onlyCharts, coacheeName, showCoachingGuideline, printToPDFMode } = props;

    const [summaryTabIndex, setSummaryTabIndex] = useState(0);

    const CustomAxis = () => {
        return (
            <div className={"flex flex-col gap-1.5 mr-14"}>
                <div className={"flex flex-row w-full font-title text-xs xl:text-sm text-slate-400 font-medium"}>
                    <div className="w-33-percentage text-center">Low</div>
                    <div className="w-33-percentage text-center">
                        <span className="-ml-2">Average</span>
                    </div>
                    <div className="w-33-percentage text-center">
                        <span className="-ml-2"> High</span>
                    </div>
                </div>
                <div className="flex flex-row w-full">
                    <div
                        className={
                            "w-33-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5"
                        }
                    />
                    <div
                        className={
                            "w-33-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5"
                        }
                    />
                    <div className={"w-33-percentage border-slate-400 border-dashed border-2 border-b-0 h-3.5"} />
                </div>
            </div>
        );
    };

    const summaryList = ["Prospecting", "Selling", "Growth"];

    const summaryContent = summaryList.map((category) => {
        const filteredFields = score.reportFields
            .filter(
                (reportField) =>
                    reportField.index === "Personality Profile Index" && reportField.superCategory === category
            )
            .map((reportField) => ({
                percentScore: reportField.percentile,
                name: reportField.name,
                category: reportField.category,
            }))
            .sort((a, b) => a.category.localeCompare(b.category));

        const scales = score.reportFields
            .filter(
                (reportField) =>
                    reportField.index === "Personality Profile Index" && reportField.superCategory === category
            )
            .map((reportField) => ({
                coacheeName: coacheeName,
                percentage: reportField.percentile,
                name: reportField.name,
                scoreDescription: reportField.bandDescription,
                lowAdjective: reportField.inverseName,
                highAdjective: reportField.name,
                category: reportField.category,
                colour: PPI_CATEGORY_COLOURS[reportField.category].colour,
                textColour: PPI_CATEGORY_COLOURS[reportField.category].textColour,
                extraContent: (reportField.considerationsWithinTheWorkplace ||
                    reportField.coachingTips ||
                    reportField.interactiveEffects) && (
                    <CoachingGuidelineCard reportField={reportField} showCoachingGuideline={showCoachingGuideline} />
                ),
            }))
            .sort((a, b) => a.category.localeCompare(b.category))
            .map((scaleProps, index) => (
                <Scale
                    key={index}
                    item={scaleProps}
                    noPrintBreak={index === 0}
                    extraStyling={index === 0 ? "print:mt-16" : ""}
                    displayCategoryInName={printToPDFMode}
                />
            ));

        return {
            content: (
                <div className="flex flex-col gap-4 print:gap-16 print:break-before-page ">
                    <div className={`text-${PPI_SUPER_CATEGORY_COLOURS[category].colour}-600 font-medium text-base`}>
                        {category}
                    </div>
                    <BarChart
                        data={filteredFields}
                        percentageAxisLabels={{
                            first: "0%",
                            second: "25%",
                            third: "50%",
                            fourth: "75%",
                            fifth: "99%",
                        }}
                        colourPalette={
                            category === "Prospecting"
                                ? SALEABLE_PROSPECTING_BAR_CHART_PALETTE
                                : category === "Selling"
                                ? SALEABLE_SELLING_BAR_CHART_PALETTE
                                : SALEABLE_GROWTH_BAR_CHART_PALETTE
                        }
                        percentage
                        labelAddOn="ile"
                        percentageAxisMargin
                        noTrendLegend
                        filterText={category}
                        customTopAxis={<CustomAxis />}
                        noInfoBubbleOnPrint
                        barTransitionDuration={printToPDFMode ? "0" : "4"}
                    />
                    <div className="flex flex-col gap-4">{scales}</div>
                </div>
            ),
            readonly: true,
            tabSelectedClassName: `bg-${PPI_SUPER_CATEGORY_COLOURS[category].colour}-50 border border-${PPI_SUPER_CATEGORY_COLOURS[category].colour}-100 text-${PPI_SUPER_CATEGORY_COLOURS[category].colour}-600`,
            backgroundColour: "bg-slate-50 print:bg-white",
        };
    });

    return (
        <div className="flex flex-col gap-4">
            <div
                ref={jumpToRefs.personalityProfileIndex}
                className={
                    "flex flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                    "print:p-0 print:border-0 print:mt-4 print:shadow-none"
                }
            >
                <div className={"flex flex-col gap-2"}>
                    <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                        {score.personalityProfileIndex.title}
                    </div>
                    <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                        {score.personalityProfileIndex.description.map((paragraph, index) => (
                            <p key={index} className="text-slate-500 text-sm">
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <TabbedNavigationLayout
                        tabIndex={summaryTabIndex}
                        setTabIndex={setSummaryTabIndex}
                        tabList={summaryList}
                        tabContent={summaryContent}
                        tabsRowOnly
                        noTitle
                        displayOnlyContent={printToPDFMode === undefined ? true : printToPDFMode}
                        printGap={16}
                    />
                </div>
            </div>
            <div
                ref={jumpToRefs.responsibilityStyleIndex}
                className={
                    "flex flex-col gap-6 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                    "print:p-0 print:border-0 print:mt-4 print:shadow-none print:break-before-page"
                }
            >
                <div className={"flex flex-col gap-2"}>
                    <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                        {score.responseStyleIndex.title}
                    </div>
                    <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                        {score.responseStyleIndex.description.map((paragraph, index) => (
                            <p key={index} className="text-slate-500 text-sm">
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </div>
                <BarChart
                    data={score.reportFields
                        .filter((reportField) => reportField.index === "Response Style Index")
                        .map((reportField) => ({
                            percentScore: reportField.percentile,
                            name: reportField.name,
                            category: reportField.category,
                        }))
                        .sort((a, b) => a.category.localeCompare(b.category))}
                    percentageAxisLabels={{
                        first: "0%",
                        second: "25%",
                        third: "50%",
                        fourth: "75%",
                        fifth: "99%",
                    }}
                    colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                    percentage
                    labelAddOn="ile"
                    percentageAxisMargin
                    noTrendLegend
                    filterText="Prospecting"
                    customTopAxis={<CustomAxis />}
                    noInfoBubbleOnPrint
                    barTransitionDuration={printToPDFMode ? "0" : "4"}
                />
                <div className="flex flex-col gap-6 print:break-inside-avoid-page">
                    {score.reportFields
                        .filter((reportField) => reportField.index === "Response Style Index")
                        .map((reportField) => ({
                            coacheeName: coacheeName,
                            percentage: reportField.percentile,
                            name: reportField.name,
                            scoreDescription: reportField.bandDescription,
                            lowAdjective: reportField.inverseName,
                            highAdjective: reportField.name,
                            category: reportField.category,
                            colour: RSI_CATEGORY_COLOURS[reportField.category].colour,
                            textColour: RSI_CATEGORY_COLOURS[reportField.category].cardTextColour,
                            customChart: (
                                <BarChart
                                    data={[
                                        {
                                            percentScore: reportField.percentile,
                                            name: reportField.name,
                                            category: reportField.category,
                                        },
                                    ]}
                                    percentageAxisLabels={{
                                        first: "0%",
                                        second: "25%",
                                        third: "50%",
                                        fourth: "75%",
                                        fifth: "99%",
                                    }}
                                    colourPalette={[RSI_CATEGORY_COLOURS[reportField.category]]}
                                    percentage
                                    labelAddOn="ile"
                                    percentageAxisMargin
                                    noTrendLegend
                                    filterText="Prospecting"
                                    customTopAxis={<CustomAxis />}
                                    noInfoBubbleOnPrint
                                    barTransitionDuration={printToPDFMode ? "0" : "4"}
                                />
                            ),
                        }))
                        .sort((a, b) => a.category.localeCompare(b.category))
                        .map((scaleProps, index) => (
                            <Scale
                                key={index}
                                backgroundColour="bg-slate-50 border-slate-200 print:bg-white"
                                bubbleBackgroundColour="bg-white border-slate-100"
                                item={scaleProps}
                                displayCategoryInName={printToPDFMode}
                            />
                        ))}
                </div>
            </div>
            <div
                ref={jumpToRefs.saleableIntroduction}
                className={
                    "bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                    "print:p-0 print:border-0 print:mt-4 print:shadow-none print:break-before-page " +
                    (onlyCharts ? "hidden" : "block")
                }
            >
                <div className="flex flex-col gap-2">
                    <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                        Overview
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="text-slate-500 text-sm">{score.reportDescription}</p>
                    </div>
                </div>
            </div>
            <div
                ref={jumpToRefs.rationale}
                className={
                    "flex-col gap-8 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                    "print:p-0 print:border-0 print:mt-4 print:shadow-none " +
                    (onlyCharts ? "hidden" : "flex")
                }
            >
                <div className="flex flex-col gap-2">
                    <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                        Rationale
                    </div>
                    <div className="flex flex-col gap-2">
                        {score.rationale.description.map((paragraph, index) => (
                            <p key={index} className="text-slate-500 text-sm">
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
            <div
                ref={jumpToRefs.generalInformation}
                className={
                    "flex flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                    "print:p-0 print:border-0 print:mt-4 print:shadow-none " +
                    (onlyCharts ? "hidden" : "flex")
                }
            >
                <div className="flex flex-col gap-2">
                    <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                        Further information
                    </div>
                    {score.furtherInformation.description.map((paragraph, index) => (
                        <p key={index + "furtherinfo"} className="text-slate-500 text-sm">
                            {paragraph}
                        </p>
                    ))}
                </div>
                <div className="flex flex-col gap-4">
                    <div className={"flex flex-col gap-2"}>
                        <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                            General information for interpreting this report
                        </div>
                        {score.generalInformation.description.map((paragraph, index) => (
                            <p key={index} className="text-slate-500 text-sm">
                                {paragraph}
                            </p>
                        ))}
                    </div>
                    {score.generalInformation.subHeadings.map((subHeading, index) => (
                        <div key={index} data-item="scrollback-content" className={"flex flex-col gap-2"}>
                            <div className="text-secondary-600 font-medium text-sm">{subHeading.title}</div>
                            {subHeading.description.map((paragraph, index) => (
                                <p key={index} className="text-slate-500 text-sm print:break-inside-avoid-page">
                                    {paragraph}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
