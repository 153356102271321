import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowUturnLeftIcon, DocumentArrowDownIcon, DocumentTextIcon, UserIcon } from "@heroicons/react/24/solid";

import { Context } from "../../../../context/Context";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";
import { getCoachees, getCoacheesForCoach, uploadResume } from "../../../../utils/api";

import { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Alert from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import Input from "../../../SharedComponents/Form/Input";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";

export default function UploadResume() {
    const [alert, setAlert] = useState("");
    const [uploadLoading, setUploadLoading] = useState(false);
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coacheeIdentifier, setCoacheeIdentifier] = useState("");
    const [resumeName, setResumeName] = useState("");
    const [resumeFile, setResumeFile] = useState("");
    const [coacheesLoading, setCoacheesLoading] = useState(false);
    const [selectableCoachees, setSelectableCoachees] = useState([]);
    const [allCoachees, setAllCoachees] = useState(false);
    const [allCoacheesLoading, setAllCoacheesLoading] = useState(true);
    const [coachees, setCoachees] = useState([]);

    const navigate = useNavigate();

    const location = useLocation();

    const context = useContext(Context);

    const handleUploadResume = async () => {
        setUploadLoading(true);

        if (resumeName === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter a resume name.");
            setUploadLoading(false);
            return;
        }

        if (resumeFile === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please attach a resume file.");
            setUploadLoading(false);
            return;
        }

        if (coacheeIdentifier === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please add a " + context.terminology.coachee ? context.terminology.coachee.singular : "coachee");
            setUploadLoading(false);
            return;
        }

        try {
            await uploadResume(
                coacheeIdentifier,
                coachees.find((coachee) => coachee.userIdentifier === coacheeIdentifier).emailAddress,
                resumeFile,
                resumeName
            );

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert(
                "Successfully uploaded resume for " +
                    coachees.find((coachee) => coachee.userIdentifier === coacheeIdentifier).firstName
            );
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }

        setUploadLoading(false);
    };

    const fetchCoachees = useCallback(async () => {
        setCoacheesLoading(true);

        try {
            if (!allCoacheesLoading) {
                try {
                    let coacheesResponse = "";

                    if (allCoachees) {
                        coacheesResponse = await getCoachees();
                    } else {
                        coacheesResponse = await getCoacheesForCoach();
                    }

                    setCoachees(coacheesResponse);

                    coacheesResponse.forEach((coachee) => {
                        setSelectableCoachees((coachees) => [
                            {
                                value: coachee.userIdentifier,
                                label: coachee.firstName + " " + coachee.lastName + " (" + coachee.emailAddress + ")",
                            },
                            ...coachees,
                        ]);
                    });
                } catch (err) {
                    setAlert(err.message);
                    setAlertType(ALERT_TYPE.ERROR);
                }
            }
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setCoacheesLoading(false);
    }, [allCoachees, allCoacheesLoading]);

    useEffect(() => {
        fetchCoachees();

        if (location.state.coacheeIdentifier) {
            setCoacheeIdentifier(location.state.coacheeIdentifier);
        }
    }, [fetchCoachees, location.state.coacheeIdentifier]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllCoachees(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES));
            setAllCoacheesLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    return (
        <DashboardLayout
            title={"Upload Resume"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.UPLOAD_DOCUMENT_FOR_OTHER_USER]}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex flex-col"}>
                    <div className={"grid grid-flow-row xl:grid-cols-2 gap-6 p-4"}>
                        <div className={"col-span-2 lg:col-span-1 flex flex-col gap-3"}>
                            <Input
                                id="resumeName"
                                type="text"
                                label="Resume name"
                                placeholder={"Resume name"}
                                icon={<DocumentTextIcon className="h-4 w-4 text-slate-500" />}
                                onChange={setResumeName}
                            />
                        </div>
                        <div className={"col-span-2 lg:col-span-1 flex flex-col gap-3"}>
                            <div className="flex flex-col gap-2 w-full">
                                <div className={"flex justify-between w-full"}>
                                    <label
                                        htmlFor="reportFile"
                                        className="text-sm font-medium text-primary-600 w-full "
                                    >
                                        Resume file
                                    </label>
                                </div>
                                <div className="flex rounded-xl shadow-sm bg-slate-50 w-full cursor-pointer">
                                    <span className="sr-only">Choose File</span>
                                    <input
                                        id="resumeFile"
                                        type="file"
                                        className={
                                            "block w-full text-sm text-slate-600 file:mr-4 file:py-2 file:px-4 " +
                                            "file:rounded-xl file:border-0 file:text-xs file:font-medium " +
                                            "file:bg-primary-50 file:text-primary-700 file:cursor-pointer " +
                                            "hover:file:bg-primary-100 file:file:h-9"
                                        }
                                        onChange={(e) => setResumeFile(e.target.files[0])}
                                        accept="application/pdf, application/msword, .pdf, .doc, .docx"
                                    />
                                </div>
                                <div className={"text-xs text-slate-400 w-full text-right"}>
                                    Supported file formats: PDF, DOC, DOCX
                                </div>
                            </div>
                        </div>
                        <div className={"col-span-1 flex flex-col gap-3"}>
                            <MultiSelect
                                items={selectableCoachees}
                                labelText={
                                    context.terminology.coachee ? context.terminology.coachee.singular : "Coachee"
                                }
                                loading={coacheesLoading}
                                setChosenItems={setCoacheeIdentifier}
                                value={coacheeIdentifier}
                                icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 text-right rounded-b-xl w-full"
                    }
                >
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GRAY}
                        text={"Back"}
                        iconPositionLeft
                        icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text="Upload resume"
                        icon={<DocumentArrowDownIcon className="block h-3.5 w-3.5  mr-2" />}
                        iconPositionLeft
                        loading={uploadLoading}
                        onClick={handleUploadResume}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}
