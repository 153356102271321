import { motion, AnimatePresence } from "framer-motion";

export default function TabbedNavigationLoadingPlaceholder(props) {
    const { active } = props;

    return (
        <AnimatePresence>
            {active && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex flex-col gap-4 w-full">
                            <div className="flex flex-row gap-2">
                                <div className="h-9 bg-slate-200 rounded-xl w-16" />
                                <div className="h-9 bg-slate-200 rounded-xl w-20" />
                                <div className="h-9 bg-slate-200 rounded-xl w-16" />
                                <div className="h-9 bg-slate-200 rounded-xl w-32" />
                            </div>
                            <div className="h-8 bg-slate-200 rounded-xl w-24" />
                            <div className="h-10 bg-slate-200 rounded-xl w-full" />
                            <div className="h-20 bg-slate-200 rounded-xl w-full" />
                            <div className="h-24 bg-slate-200 rounded-xl w-full" />
                            <div className="h-20 bg-slate-200 rounded-xl w-full" />
                            <div className="h-16 bg-slate-200 rounded-xl w-full" />
                            <div className="h-8 bg-slate-200 rounded-xl w-full" />
                            <div className="place-self-end h-12 w-32 bg-slate-200 rounded-xl w-full" />
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
