import { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { updateSession, getOrganisationUsersLite } from "../../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../../utils/constants";
import { Context } from "../../../../../context/Context";

import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import SessionDetails from "./SessionDetails";
import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";

export default function Session() {
    const context = useContext(Context);
    const location = useLocation();
    const navigate = useNavigate();
    const session = location?.state?.session;
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [loading, setLoading] = useState(true);
    const [organisationUsers, setOrganisationUsers] = useState();
    const [sessionName, setSessionName] = useState(session?.name);
    const initialRender = useRef(true);
    const [sessionDateTime, setSessionDateTime] = useState(session?.startTime);
    const [sessionDuration, setSessionDuration] = useState(session?.durationMins);
    const [sessionIsCompleted, setSessionIsCompleted] = useState(session?.isCompleted);
    const [sessionLink, setSessionLink] = useState(session?.meetingUrl);
    const [sessionLocation, setSessionLocation] = useState(session?.meetingLocation);
    const [chosenUsers, setChosenUsers] = useState([]);
    const [sessionType, setSessionType] = useState("");

    const includesEditSessionPermission = context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.EDIT_SESSION);

    const handleEditSession = async () => {
        setLoading(true);

        if (sessionName === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session name.");
            setLoading(false);
            return;
        }

        if (sessionType === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please select a session type.");
            setLoading(false);
            return;
        }

        if (sessionDateTime === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session date time.");
            setLoading(false);
            return;
        }

        if (sessionDuration < 1 || sessionDuration === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session duration.");
            setLoading(false);
            return;
        }

        if (sessionType === "onsite" && !sessionLocation) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session location.");
            setLoading(false);
            return;
        }

        if (sessionType === "remote" && !sessionLink) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session link.");
            setLoading(false);
            return;
        }

        if (
            (sessionType === "remote" || sessionType === "hybrid") &&
            !document.getElementById("sessionLink").checkValidity()
        ) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Session link is not valid.");
            setLoading(false);
            return;
        }

        if (sessionType === "hybrid" && (!sessionLink || !sessionLocation)) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session location and session link.");
            setLoading(false);
            return;
        }

        if (chosenUsers.length < 2) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter at least 2 participants.");
            setLoading(false);
            return;
        }

        try {
            const participantsUserIdentifierList = [];

            chosenUsers.map((user) => participantsUserIdentifierList.push(user.value));

            await updateSession(
                session.meetingIdentifier,
                sessionName,
                sessionDateTime,
                sessionDuration,
                sessionIsCompleted,
                sessionLink,
                sessionLocation,
                participantsUserIdentifierList
            );

            context.fetchSessions();

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited session");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }

        setLoading(false);
    };

    const resetRadioFields = (sessionType) => {
        if (sessionType === "remote") {
            setSessionLink("");
            setSessionLocation(null);
        }
        if (sessionType === "onsite") {
            setSessionLink(null);
            setSessionLocation("");
        }
        if (sessionType === "hybrid") {
            setSessionLink("");
            setSessionLocation("");
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    useEffect(() => {
        if (session?.meetingLocation && !session?.meetingUrl) {
            setSessionType("onsite");
        }
        if (session?.meetingUrl && !session?.meetingLocation) {
            setSessionType("remote");
        }
        if (session?.meetingUrl && session?.meetingLocation) {
            setSessionType("hybrid");
        }
        const sanitizedResponse = session?.participants.map((user) => ({
            value: user.userIdentifier,
            label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")",
        }));
        setChosenUsers(sanitizedResponse);
    }, [session?.meetingLocation, session?.meetingUrl, session?.participants]);

    useEffect(() => {
        if (includesEditSessionPermission) {
            const fetchOrganisationUsers = async () => {
                setLoading(true);
                try {
                    const response = await getOrganisationUsersLite(false);
                    const sanitizedResponse = response.map((user) => ({
                        value: user.userIdentifier,
                        label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")",
                    }));
                    setOrganisationUsers(sanitizedResponse);
                } catch (err) {
                    setAlert(err.message);
                    setAlertType(ALERT_TYPE.ERROR);
                }
                setLoading(false);
            };
            fetchOrganisationUsers();
        }
    }, [includesEditSessionPermission]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (Date.now() >= new Date(sessionDateTime)) {
                setAlertType(ALERT_TYPE.WARNING);
                setAlert("Note: you're updating to a past event session.");
            } else {
                setAlert(null);
            }
        }
    }, [sessionDateTime]);

    useEffect(() => {
        let organisationSession = {};
        let mySession = {};

        if (context.organisationSessions) {
            organisationSession = context.organisationSessions.find(
                (session) => session.meetingIdentifier === location.state.session.meetingIdentifier
            );
        } else if (context.userSessions) {
            mySession = context.userSessions.find(
                (session) => session.meetingIdentifier === location.state.session.meetingIdentifier
            );
        }

        const sessionData = organisationSession ? organisationSession : mySession;

        // update current path with new state to persist data on refresh
        if (sessionData && includesEditSessionPermission) {
            navigate(".", { replace: true, state: { session: sessionData } });
        }
    }, [
        context.organisationSessions,
        context.userSessions,
        includesEditSessionPermission,
        location.state.session.meetingIdentifier,
        navigate,
    ]);

    return (
        <>
            {location?.state ? (
                <DashboardLayout
                    title={"Session: " + session.name}
                    requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_SESSIONS]}
                >
                    {includesEditSessionPermission ? (
                        <TabbedNavigationLayout
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            tabList={["Session details"]}
                            backgroundColour={"bg-slate-100"}
                            tabsRowOnly
                            tabContent={[
                                {
                                    title: "Session details",
                                    content: (
                                        <SessionDetails
                                            session={session}
                                            setSessionName={setSessionName}
                                            alert={alert}
                                            alertType={alertType}
                                            setAlert={setAlert}
                                            resetRadioFields={resetRadioFields}
                                            setSessionType={setSessionType}
                                            setSessionDateTime={setSessionDateTime}
                                            setSessionDuration={setSessionDuration}
                                            sessionType={sessionType}
                                            sessionLink={sessionLink}
                                            setSessionLink={setSessionLink}
                                            sessionLocation={sessionLocation}
                                            setSessionLocation={setSessionLocation}
                                            organisationUsers={organisationUsers}
                                            loading={loading}
                                            chosenUsers={chosenUsers}
                                            setChosenUsers={setChosenUsers}
                                            setSessionIsCompleted={setSessionIsCompleted}
                                            readOnly={false}
                                        />
                                    ),
                                    onSaveChanges: handleEditSession,
                                },
                            ]}
                        />
                    ) : (
                        <TabbedNavigationLayout
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            tabList={["Session details"]}
                            backgroundColour={"bg-slate-100"}
                            tabsRowOnly
                            tabContent={[
                                {
                                    title: "Session details",
                                    content: (
                                        <SessionDetails
                                            session={session}
                                            setSessionName={setSessionName}
                                            alert={alert}
                                            alertType={alertType}
                                            setAlert={setAlert}
                                            resetRadioFields={resetRadioFields}
                                            setSessionType={setSessionType}
                                            setSessionDateTime={setSessionDateTime}
                                            setSessionDuration={setSessionDuration}
                                            sessionType={sessionType}
                                            sessionLink={sessionLink}
                                            setSessionLink={setSessionLink}
                                            sessionLocation={sessionLocation}
                                            setSessionLocation={setSessionLocation}
                                            loading={loading}
                                            setSessionIsCompleted={setSessionIsCompleted}
                                            readOnly={true}
                                        />
                                    ),
                                },
                            ]}
                        />
                    )}
                </DashboardLayout>
            ) : (
                <DashboardLayout
                    title={"Session details"}
                    requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_SESSIONS]}
                >
                    <Alert type={ALERT_TYPE.WARNING} active={true}>
                        <div className="text-sm">
                            Oops! It looks like we are having some trouble retrieving your session details. Please
                            refresh the page or if you require immediate assistance, please contact us at{" "}
                            <a
                                className="link text-primary-600 hover:text-primary-700"
                                href="mailto:techsupport@psychpress.com.au?subject=The assessments server is down."
                            >
                                techsupport@psychpress.com.au
                            </a>{" "}
                            or call us at{" "}
                            <a className="link text-primary-600 hover:text-primary-700" href="tel:+61396700590">
                                +61 (0)3 9670 0590
                            </a>
                        </div>
                    </Alert>
                </DashboardLayout>
            )}
        </>
    );
}
