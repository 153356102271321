function Toggle(props) {
    const { name, text, triggered, backgroundColour, textColour, small, onClick } = props;
    return (
        <label onClick={onClick} htmlFor={name} className="flex items-center cursor-pointer gap-2">
            <div className="relative">
                <div
                    className={
                        "toggle-circle-background block rounded-full transition-colors " +
                        (small ? "w-8 h-5 " : "w-10 h-6 ") +
                        (triggered ? (backgroundColour ? backgroundColour : "bg-secondary-600") : "bg-slate-500")
                    }
                />
                <div
                    className={
                        "toggle-circle absolute top-1 bg-white rounded-full transition-left " +
                        (small ? "w-3 h-3 " : "w-4 h-4 ") +
                        (triggered ? "left-5" : "left-1")
                    }
                />
            </div>
            <div className={"text-sm font-medium select-none " + (textColour ? textColour : "text-secondary-600")}>
                {text}
            </div>
        </label>
    );
}

export default Toggle;
