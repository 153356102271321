import React from "react";
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Label,
    LabelList,
} from "recharts";

const data01 = [{ x: 100, y: 200, z: 400, name: "D.1" }];
const data02 = [{ x: 321, y: 100, z: 400, name: "D.2" }];

const data04 = [{ x: 321, y: 254, z: 400, name: "D.3" }];

const data03 = [{ x: 111, y: 54, z: 400, name: "D.4" }];

export default function BubbleGraph() {
    return (
        <ResponsiveContainer width="100%" height="100%" className={"mt-4"}>
            <ScatterChart
                margin={{
                    top: 15,
                    right: 15,
                    bottom: 30,
                    left: 15,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" dataKey="x" name="Overall points" strokeDasharray="3 3" stroke="#CBD5E1">
                    <Label
                        value="Stewardship and People Strengths points"
                        offset={-20}
                        position="insideBottom"
                        fill="#64748B"
                    />
                </XAxis>
                <YAxis type="number" dataKey="y" name="weight" strokeDasharray="3 3" stroke="#CBD5E1">
                    <Label
                        value="Overall points"
                        angle={270}
                        offset={"-6"}
                        position="left"
                        style={{ textAnchor: "middle" }}
                        fill="#64748B"
                    />
                </YAxis>
                <ZAxis type="number" dataKey="z" range={[0, 400]} name="score" />
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Scatter name="Department 1" data={data01} fill="#DB2777">
                    <LabelList dataKey="name" position="right" fill="#DB2777" />
                </Scatter>
                <Scatter name="Department 2" data={data02} fill="#7C3AED">
                    <LabelList dataKey="name" position="right" fill="#7C3AED" />
                </Scatter>
                <Scatter name="Department 3" data={data03} fill="#4F46E5">
                    <LabelList dataKey="name" position="right" fill="#4F46E5" />
                </Scatter>
                <Scatter name="Department 4" data={data04} fill="#059669">
                    <LabelList dataKey="name" position="right" fill="#059669" />
                </Scatter>
            </ScatterChart>
        </ResponsiveContainer>
    );
}
