import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlusIcon, ArrowUturnLeftIcon, TableCellsIcon, UsersIcon, RectangleStackIcon } from "@heroicons/react/24/solid";

import { createCohort, getPrograms, getOrganisationUsersLite } from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Button from "../../../SharedComponents/Button";
import Input from "../../../SharedComponents/Form/Input";

export default function CreateCohort() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [cohortName, setCohortName] = useState("");
    const [selectablePrograms, setSelectablePrograms] = useState([]);
    const [programName, setProgramName] = useState();
    const [loadingOrganisationUsers, setLoadingOrganisationUsers] = useState(true);
    const [loadingPrograms, setLoadingPrograms] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [organisationUsers, setOrganisationUsers] = useState([]);
    const [cohortMembers, setCohortMembers] = useState([]);

    const navigate = useNavigate();

    const handleCreateCohort = async () => {
        setSubmitLoading(true);
        try {
            const cohortMemberUserIdentifiers = cohortMembers.map((cohortMember) => cohortMember.value);
            await createCohort(cohortName, cohortMemberUserIdentifiers, programName);
            navigate("/cohorts", {
                state: {
                    alertType: ALERT_TYPE.SUCCESS,
                    alertMessage: 'Successfully created cohort "' + cohortName + '"',
                },
            });
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setSubmitLoading(false);
    };

    useEffect(() => {
        const fetchPrograms = async () => {
            setLoadingPrograms(true);
            try {
                const response = await getPrograms();

                response.forEach((program) => {
                    setSelectablePrograms((programs) => [
                        {
                            value: program.name,
                            label: program.name,
                        },
                        ...programs,
                    ]);
                });
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoadingPrograms(false);
        };

        const fetchOrganisationUsers = async () => {
            setLoadingOrganisationUsers(true);
            try {
                const response = await getOrganisationUsersLite(false);
                const sanitizedResponse = response.map((user) => ({
                    value: user.userIdentifier,
                    label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")",
                }));
                setOrganisationUsers(sanitizedResponse);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoadingOrganisationUsers(false);
        };

        fetchPrograms();
        fetchOrganisationUsers();
    }, []);

    return (
        <DashboardLayout title={"Create Cohort"} requiredPermissions={[COACH_PLATFORM_PERMISSIONS.CREATE_COHORT]}>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4 border border-slate-100 border-1 rounded-t-xl"}>
                        <div className={"grid grid-flow-row gap-6"}>
                            <div className={"grid grid-flow-row xl:grid-cols-2 gap-6"}>
                                <div className={"flex flex-col gap-3"}>
                                    <Input
                                        id="cohortName"
                                        type="text"
                                        label="Cohort name"
                                        placeholder={"Cohort name"}
                                        icon={<TableCellsIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setCohortName}
                                    />
                                </div>
                                <div className={"flex flex-col gap-3"}>
                                    <MultiSelect
                                        items={selectablePrograms}
                                        labelText={"Program"}
                                        loading={loadingPrograms}
                                        setChosenItems={setProgramName}
                                        icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                    />
                                </div>
                                <div className={"col-span-2 flex flex-col gap-3"}>
                                    <MultiSelect
                                        isMulti
                                        items={organisationUsers}
                                        labelText={"Cohort members"}
                                        loading={loadingOrganisationUsers}
                                        setChosenItems={setCohortMembers}
                                        icon={<UsersIcon className="h-4 w-4 text-slate-500" />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 " +
                            "text-right rounded-b-xl w-full"
                        }
                    >
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Create cohort"
                            icon={<PlusIcon className="block h-3.5 w-3.5  stroke-current stroke-1 mr-2" />}
                            iconPositionLeft
                            onClick={handleCreateCohort}
                            disabled={cohortName === "" || submitLoading}
                            loading={submitLoading}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
