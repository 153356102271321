import React, { useCallback, useEffect, useState } from "react";

import { getOrganisationUsersLite, getReleaseReportPermissionUsers } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import UserReleaseReportPermissionRow from "./UserReleaseReportPermissionRow";

export default function UserReportReleasePermissions(props) {
    const { setUserIdentifiersWithPermission, userIdentifiersWithPermission } = props;

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [usersWithoutReleaseReportPermission, setUsersWithoutReleaseReportPermission] = useState([]);
    const [usersWithReleaseReportPermission, setUsersWithReleaseReportPermission] = useState([]);
    const [loading, setLoading] = useState(true);
    const [latestUserInteractedWithPermission, setLatestUserInteractedWithPermission] = useState(true);
    const [latestUserInteractedWithoutPermission, setLatestUserInteractedWithoutPermission] = useState(true);

    const handleAddUserToggle = (userIdentifier, toggle, noPreviousAccessUser) => {
        let user;

        if (noPreviousAccessUser) {
            user = usersWithoutReleaseReportPermission.find(
                (organisationUser) => organisationUser.userIdentifier === userIdentifier
            );
        } else {
            user = usersWithReleaseReportPermission.find(
                (userWithAccess) => userWithAccess.userIdentifier === userIdentifier
            );
        }

        if (toggle) {
            setUsersWithReleaseReportPermission((usersWithAccess) => [user, ...usersWithAccess]);
            setUsersWithoutReleaseReportPermission(
                usersWithoutReleaseReportPermission.filter(
                    (organisationUser) => organisationUser.userIdentifier !== userIdentifier
                )
            );

            setUserIdentifiersWithPermission((usersWithAccess) => [...usersWithAccess, user.userIdentifier]);

            setLatestUserInteractedWithPermission(user);
        } else {
            setUsersWithoutReleaseReportPermission((organisationUsers) => [user, ...organisationUsers]);
            setUsersWithReleaseReportPermission(
                usersWithReleaseReportPermission.filter(
                    (userWithAccess) => userWithAccess.userIdentifier !== userIdentifier
                )
            );
            setUserIdentifiersWithPermission(
                userIdentifiersWithPermission.filter(
                    (userIdentifierNewAccess) => userIdentifierNewAccess !== userIdentifier
                )
            );
            setLatestUserInteractedWithoutPermission(user);
        }
    };

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const organisationUsersResponse = await getOrganisationUsersLite(true);

            //Requires GRANT_RELEASE_REPORT (COACH-U-RELEASEREPORTPERMISSION) from Backend
            const usersWithReleaseReportPermissionResponse = await getReleaseReportPermissionUsers();

            if (usersWithReleaseReportPermissionResponse) {
                setUsersWithReleaseReportPermission(usersWithReleaseReportPermissionResponse);

                setUsersWithoutReleaseReportPermission(
                    organisationUsersResponse.filter(
                        (orgUser) =>
                            !usersWithReleaseReportPermissionResponse.find(
                                (userWithAccess) => userWithAccess.userIdentifier === orgUser.userIdentifier
                            )
                    )
                );

                usersWithReleaseReportPermissionResponse.map((user) =>
                    setUserIdentifiersWithPermission((userIdentifiersWithPermission) => [
                        ...userIdentifiersWithPermission,
                        user.userIdentifier,
                    ])
                );
            }
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, [setUserIdentifiersWithPermission]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"flex flex-col gap-6"}>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-2">
                        Users that <span className={"underline"}>have</span> the permission to release reports
                    </div>
                    <PaginatedSearch
                        loading={loading}
                        items={usersWithReleaseReportPermission}
                        itemName={"users that have the permission to release reports"}
                        searchAttributes={["name"]}
                        columnNames={["Name", "User type", "Permission"]}
                        ItemRow={UserReleaseReportPermissionRow}
                        itemRowToggleOnChange={handleAddUserToggle}
                        customItemsPerPage={4}
                        accessDefaultToggleState={false}
                        latestItemInteracted={latestUserInteractedWithPermission}
                    />
                </div>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-4">
                        Users that <span className={"underline"}>don't have</span> the permission to release reports
                    </div>
                    <PaginatedSearch
                        loading={loading}
                        items={usersWithoutReleaseReportPermission}
                        itemName={"other users that don't have the permission to release reports"}
                        searchAttributes={["name"]}
                        columnNames={["Name", "User type", "Permission"]}
                        ItemRow={UserReleaseReportPermissionRow}
                        itemRowToggleOnChange={handleAddUserToggle}
                        customItemsPerPage={8}
                        noPreviousAccessUser={true}
                        accessDefaultToggleState={true}
                        latestItemInteracted={latestUserInteractedWithoutPermission}
                        noTableHead
                    />
                </div>
            </div>
        </>
    );
}
