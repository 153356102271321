import Alert, { ALERT_TYPE } from "../../../../../SharedComponents/Alert";

export default function AceMindsetIndexChart(props) {
    const { data, testId } = props;

    return (
        <div className="flex flex-col gap-2" data-testid={testId && testId}>
            {Object.keys(data).length !== 0 ? (
                <>
                    <div className="relative flex items-center transition-all">
                        {data.sectorBenchmark && (
                            <div
                                className={
                                    "z-50 absolute flex justify-end w-" +
                                    (data.sectorBenchmark + "-percentage ") +
                                    (data.sectorBenchmark === data.year1 && data.sectorBenchmark === data.year2
                                        ? "-mb-4"
                                        : data.sectorBenchmark === data.year1 || data.sectorBenchmark === data.year2
                                        ? "-mt-4"
                                        : "")
                                }
                            >
                                <div className={"h-7 w-3 rounded-xl bg-amber-600 border border-white"} />
                            </div>
                        )}
                        {data.year1 && (
                            <div
                                className={
                                    "z-50 absolute flex justify-end w-" +
                                    (data.year1 + "-percentage ") +
                                    (data.year1 === data.year2 ? "-mt-4" : "")
                                }
                            >
                                <div className={"h-7 w-3 rounded-xl bg-violet-600 border border-white"} />
                            </div>
                        )}
                        {data.year2 && (
                            <div className={"z-50 absolute flex justify-end w-" + (data.year2 + "-percentage ")}>
                                <div className={"h-7 w-3 rounded-xl bg-blue-600 border border-white"} />
                            </div>
                        )}
                        <div className="flex flex-row w-full rounded-xl text-sm font-medium my-1 shadow-sm overflow-hidden">
                            <div className="bg-pink-500 w-20-percentage h-12 opacity-50" />
                            <div className="bg-violet-500 w-20-percentage h-12 opacity-50" />
                            <div className="bg-blue-300 w-20-percentage h-12 opacity-50" />
                            <div className="bg-teal-500 w-20-percentage h-12 opacity-50" />
                            <div className="bg-lime-500 w-20-percentage h-12 opacity-50" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-1.5">
                        <div className="flex flex-row w-full">
                            <div className="w-20-percentage border-slate-300 border-dashed border-2 border-t-0 border-r-0 h-3.5"></div>
                            <div className="w-20-percentage border-slate-300 border-dashed border-2 border-t-0 border-r-0 h-3.5"></div>
                            <div className="w-20-percentage border-slate-300 border-dashed border-2 border-t-0 h-3.5"></div>
                            <div className="w-20-percentage border-slate-300 border-dashed border-2 border-t-0 border-l-0 h-3.5"></div>
                            <div className="w-20-percentage border-slate-300 border-dashed border-2 border-t-0 border-l-0 h-3.5"></div>
                        </div>
                        <div className="flex flex-row w-full font-title text-xs xl:text-sm text-slate-400 font-medium">
                            <div className="w-20-percentage text-center">Disparaging</div>
                            <div className="w-20-percentage text-center">Unhappy</div>
                            <div className="w-20-percentage text-center">Ambivalent</div>
                            <div className="w-20-percentage text-center">Happy</div>
                            <div className="w-20-percentage text-center">Delighted</div>
                        </div>
                    </div>
                </>
            ) : (
                <Alert text={"Data set for this chart is empty."} type={ALERT_TYPE.WARNING} active noMargins />
            )}
        </div>
    );
}
