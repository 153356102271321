import { useContext } from "react";
import { Context } from "../../../../context/Context";
import { useNavigate } from "react-router";
import { EyeIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, USER_GROUPS } from "../../../../utils/constants";

import Button from "../../../SharedComponents/Button";

export default function OrganisationUserRow(props) {
    const { item: user } = props;

    const context = useContext(Context);

    const navigate = useNavigate();

    const isCoachee = user.userGroups.includes(USER_GROUPS.COACHEE);

    return (
        <tr className={"hover:bg-slate-100 transition-colors group"}>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm font-medium text-primary-600"}>{user.firstName + " " + user.lastName}</div>
                <div className="text-sm text-slate-500 mt-1 group-hover:text-primary-600 transition-colors">
                    {user.emailAddress}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"flex flex-row gap-3 items-center"}>
                    <div className={"text-slate-500"}>
                        {user.mobileNumber ? (
                            <span className={"text-sm"}>{user.mobileNumber}</span>
                        ) : (
                            <span className={"text-xs text-slate-400 italic "}>Unavailable</span>
                        )}
                    </div>
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"flex flex-row gap-3 items-center"}>
                    <div className={"text-slate-500"}>
                        <span className={"text-sm group-hover:text-primary-600 transition-colors"}>
                            {user.userGroups.includes(USER_GROUPS.COACH) && isCoachee ? (
                                `${context.terminology.coach.singular} and ${context.terminology.coachee.singular}`
                            ) : user.userGroups.includes(USER_GROUPS.COACH) ? (
                                `${context.terminology.coach.singular}`
                            ) : isCoachee ? (
                                `${context.terminology.coachee.singular}`
                            ) : (
                                <span className={"text-xs text-slate-400 italic "}>Not setup</span>
                            )}
                        </span>
                    </div>
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <Button
                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                    icon={<EyeIcon className="block" />}
                    extraStyling={"text-xs h-8 w-8 rounded-full p-2"}
                    iconPositionLeft
                    onClick={() => {
                        navigate("/organisation-user-profile", {
                            state: { organisationUser: user },
                        });
                    }}
                />
            </td>
        </tr>
    );
}
