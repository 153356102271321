import BouncingBalls from "../Loading/BouncingBalls";
import BasicMotion from "../Motion/BasicMotion";

export default function Cards(props) {
    const {
        customCardGrid,
        loading,
        paginationLoading,
        itemsArray,
        items,
        ItemRow,
        setAlert,
        setAlertType,
        updateItemStatus,
    } = props;

    return (
        <div className={customCardGrid ? customCardGrid : "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4"}>
            {loading || paginationLoading ? (
                <div className={"col-span-full flex items-center justify-center pt-20 pb-24 w-full"}>
                    <BouncingBalls />
                </div>
            ) : items.length === 0 ? (
                !itemsArray ? (
                    <BasicMotion>
                        <div className={"col-span-full flex flex-col gap-3 w-full p-4 bg-white rounded-xl"}>
                            <span className={"text-base font-medium text-primary-600"}>
                                Sorry, there was an issue retrieving items for this list.
                            </span>
                            <div className={"text-sm text-slate-500"}>
                                Please refresh the page and{" "}
                                <a className={"font-semibold underline"} href="mailto:techsupport@psychpress.com.au">
                                    contact us
                                </a>{" "}
                                if this error persists.
                            </div>
                        </div>
                    </BasicMotion>
                ) : (
                    <div className={"col-span-full"}>
                        <BasicMotion>
                            <div className={"col-span-full flex flex-col gap-3 w-full p-4 bg-white rounded-xl"}>
                                <span className={"text-base font-medium text-primary-600"}>
                                    Sorry, this list is empty or there were no results for your search.
                                </span>
                                <div className={"text-sm text-slate-500"}>
                                    If searching, check your search term and try again or
                                    <a
                                        className={"font-semibold underline"}
                                        href="mailto:techsupport@psychpress.com.au"
                                    >
                                        {" "}
                                        contact us{" "}
                                    </a>{" "}
                                    if you think there's been an error.
                                </div>
                            </div>
                        </BasicMotion>
                    </div>
                )
            ) : (
                items.map((item, index) => (
                    <ItemRow
                        key={index}
                        item={item}
                        setAlert={setAlert}
                        setAlertType={setAlertType}
                        updateItemStatus={updateItemStatus}
                    />
                ))
            )}
        </div>
    );
}
