import diversityIllustration from "../../../../../assets/diversity.svg";

export default function SideSection(props) {
    const { extraContent, orgName, setContentInteracted, noDefaultContent } = props;

    return (
        <div className="flex flex-col gap-4 col-span-1 print:hidden">
            {extraContent && extraContent}
            {!noDefaultContent && (
                <div
                    className="flex flex-col gap-4"
                    onMouseEnter={() => setContentInteracted(true)}
                    onMouseLeave={() => setContentInteracted(false)}
                >
                    <div
                        className={
                            "bg-blue-50 p-4 rounded-xl border border-blue-100 shadow-sm " +
                            "print:p-0 print:border-0 print:mt-4 print:shadow-none print:bg-white"
                        }
                    >
                        <div className="space-y-3">
                            <p className="text-sm font-medium text-blue-500 ">Have a question about this report?</p>
                            <p className="text-slate-500 text-sm">
                                Contact us at <span className="text-blue-500">techsupport@psychpress.com.au</span>
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            "bg-amber-50 p-4 rounded-xl border border-amber-100 shadow-sm " +
                            "print:p-0 print:border-0 print:mt-4 print:shadow-none print:bg-white "
                        }
                    >
                        <div className="space-y-3">
                            <p className="text-sm font-medium text-amber-500 ">Confidentiality</p>
                            <div className="text-slate-500 grid grid-flow-row gap-3 text-sm">
                                <p>
                                    This highly confidential document is provided to the client on the candidate named
                                    on the cover sheet on the basis that the need for this confidentiality is
                                    recognised, accepted and that such confidentiality will be strictly maintained.
                                </p>
                                <p>
                                    It should therefore only be read by staff specifically involved with the selection,
                                    promotion or development of the person named, and stored securely with minimum
                                    access.
                                </p>
                                <p>
                                    Should a report be required at a later date, it can be obtained without further
                                    cost, from
                                    {orgName ? orgName : "Psych Press"} archives.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "bg-white p-4 rounded-xl border border-slate-100 shadow-sm " +
                            "print:p-0 print:border-0 print:mt-4 print:shadow-none print:bg-white "
                        }
                    >
                        <div className="space-y-3">
                            <p className="text-sm font-medium text-primary-600">Objectives</p>
                            <p className="text-slate-500 text-sm">
                                The report on the candidate's capabilities has been based on an assessment used to
                                provide objective information about the competencies.
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            "bg-white p-4 rounded-xl border border-slate-100 shadow-sm " +
                            "print:p-0 print:border-0 print:mt-4 print:shadow-none print:bg-white "
                        }
                    >
                        <div className="space-y-3">
                            <p className="text-sm font-medium text-primary-600 ">Cross validation of outcomes</p>
                            <p className="text-slate-500 text-sm">
                                This report provides objective information on the candidate's capabilities. We recommend
                                supplementing it with other information obtained from other sources such as interviews
                                or other reports.
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            "bg-white p-4 rounded-xl border border-slate-100 shadow-sm " +
                            "print:p-0 print:border-0 print:mt-4 print:shadow-none print:bg-white "
                        }
                    >
                        <div className="space-y-3">
                            <p className="text-sm font-medium text-primary-600 ">Cultural considerations</p>
                            <div className="flex justify-center min-w-full bg-transparent">
                                <img
                                    alt={(orgName ? orgName : "Psych Press") + " Portal Logo"}
                                    className="w-80"
                                    src={diversityIllustration}
                                />
                            </div>
                            <div className="text-slate-500 grid grid-flow-row gap-3 text-sm">
                                <p>
                                    It is important to take into consideration any cultural impacts when interpreting
                                    the reports. {orgName ? orgName : "Psych Press"} tries to ensure all assessments are
                                    culture fair and norm comparisons are done comparative to appropriate cultural
                                    groups or affiliations. However, when reviewing the report, it is always important
                                    to review using the context of the cultural lens. Please contact one of our{" "}
                                    {orgName ? orgName : "Psych Press"} consultants to help you provide more details for
                                    your report and the impact of culture on assessment results and subsequent
                                    interpretation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
