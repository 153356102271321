import { BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../utils/constants";

import BarChart from "../../Charts/CustomCharts/BarChart";

export default function CoachingGuideline(props) {
    const { jumpToRefs, noTitle, onlyCharts } = props;

    const CustomAxis = () => {
        return (
            <div className={"flex flex-col gap-1.5 mr-14"}>
                <div className={"flex flex-row w-full font-title text-xs xl:text-sm text-slate-400 font-medium"}>
                    <div className="w-33-percentage text-center">Low</div>
                    <div className="w-33-percentage text-center">
                        <span className="-ml-2"> Medium</span>
                    </div>
                    <div className="w-33-percentage text-center">
                        <span className="-ml-2"> High</span>
                    </div>
                </div>
                <div className="flex flex-row w-full">
                    <div
                        className={
                            "w-33-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5"
                        }
                    />
                    <div
                        className={
                            "w-33-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5"
                        }
                    />
                    <div className={"w-33-percentage border-slate-400 border-dashed border-2 border-b-0 h-3.5"} />
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col gap-4" ref={jumpToRefs.coachingGuideline}>
            {!noTitle && <div className="text-primary-600 font-medium">Coaching guideline</div>}
            <div className="flex flex-col gap-4">
                <div
                    ref={jumpToRefs.leadership}
                    className={"flex flex-col gap-6 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                >
                    <div className={"flex flex-col gap-4"}>
                        <div className="text-secondary-600 font-medium text-base">Leadership styles</div>
                        <div
                            className={
                                "flex-row gap-2 p-2 rounded-xl bg-slate-50 border " +
                                "border-slate-100 shadow-sm text-slate-500 print:hidden " +
                                (onlyCharts ? "hidden" : "flex")
                            }
                        >
                            <span className={"font-title text-sm"}>
                                Based on the work of the American Organisational Psychologist Bass, the Leadership
                                Styles describe which of a range of styles Mr. Sample is most likely to adopt. This may
                                be of relevance to a variety of situations where there is a requirement to manage
                                others. As with most personality characteristics, the profile only describes his most
                                likely styles and not performance. Effective performance will depend on many factors
                                including the organisational culture in which the individual is operating.
                            </span>
                        </div>
                    </div>
                    <BarChart
                        data={[
                            {
                                percentScore: 39,
                                name: "Directive",
                                category: "Directive",
                            },
                            {
                                percentScore: 71,
                                name: "Delegative",
                                category: "Delegative",
                            },
                            {
                                percentScore: 60,
                                name: "Participative",
                                category: "Participative",
                            },
                            {
                                percentScore: 52,
                                name: "Consultative",
                                category: "Consultative",
                            },
                            {
                                percentScore: 79,
                                name: "Negotiative",
                                category: "Negotiative",
                            },
                        ]}
                        percentageAxisLabels={{
                            first: "0%",
                            second: "25%",
                            third: "50%",
                            fourth: "75%",
                            fifth: "99%",
                        }}
                        colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                        percentage
                        labelAddOn="ile"
                        percentageAxisMargin
                        noTrendLegend
                        filterText="Leadership style"
                        customTopAxis={<CustomAxis />}
                    />
                    <div className="flex flex-col gap-4">
                        <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                            <div className="text-secondary-600 font-medium text-base">
                                Primary Leadership Style: Negotiative Leader
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-slate-500 text-sm">
                                    Negotiative Leaders motivate subordinates by encouraging them, through incentives
                                    etc., to work towards common objectives. Hence, through a process of negotiation
                                    attempts will be made to arrive at some mutually equitable arrangement with the
                                    other members of the team so as to motivate them to work in a particular way.
                                    Negotiative Leaders tend to rely on their skills of persuasion to achieve their
                                    stated goals. Many Negotiative Leaders have well developed image management skills
                                    and they typically utilise these to moderate their approach according to the
                                    circumstances in which they find themselves. This capability, coupled with a desire
                                    to 3 4 achieve, can mean that sometimes they adopt unconventional methods to achieve
                                    their desired objectives.
                                </p>
                            </div>
                        </div>
                        <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                            <div className="text-secondary-600 font-medium text-base">
                                Secondary Leadership Style: Delegative Leader
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-slate-500 text-sm">
                                    As the name suggests, the style of Delegative Leaders is characterised by delegating
                                    work to subordinates. Since their style is not strongly democratic, the process of
                                    delegation may not involve consultation. As a result, subordinates will generally be
                                    assigned work rather than have active input into how projects should be conducted.
                                    However, once the work has been assigned only little direction will be provided and
                                    subordinates will largely be expected to work with the minimum of supervision.
                                    Although such a leadership style may not be everybodyʹs preference those who are
                                    naturally independent may enjoy the freedom allowed by such managers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    ref={jumpToRefs.influencing}
                    className={"flex flex-col gap-6 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                >
                    <div className={"flex flex-col gap-4"}>
                        <div className="text-secondary-600 font-medium text-base">Influencing styles</div>
                        <div
                            className={
                                "flex-row gap-2 p-2 rounded-xl bg-slate-50 border " +
                                "border-slate-100 shadow-sm text-slate-500 print:hidden " +
                                (onlyCharts ? "hidden" : "flex")
                            }
                        >
                            <span className={"font-title text-sm"}>
                                The Influencing Styles describe which of a range of styles Mr. Sample is most likely to
                                adopt. This may be of interest in relevance to a variety of situations where there is a
                                requirement to influence others or sell a product, service or idea. As with most
                                personality characteristics, the profile only describes his most likely styles and not
                                performance. Effective performance will depend on many factors including the type of
                                product, the customer, the specific situation and the organisational culture in which
                                the individual is operating. Equally, different styles may be adopted according to the
                                demands of the situation and consequently a description of his predominant and secondary
                                influencing style is provided.
                            </span>
                        </div>
                    </div>
                    <BarChart
                        data={[
                            {
                                percentScore: 70,
                                name: "Con. communicator",
                                category: "Con. communicator",
                            },
                            {
                                percentScore: 61,
                                name: "Rapport communicator",
                                category: "Rapport communicator",
                            },
                            {
                                percentScore: 76,
                                name: "Culture fitter",
                                category: "Culture fitter",
                            },
                            {
                                percentScore: 71,
                                name: "Culture breaker",
                                category: "Culture breaker",
                            },
                            {
                                percentScore: 79,
                                name: "Enthusiast",
                                category: "Enthusiast",
                            },
                            {
                                percentScore: 48,
                                name: "Perseverer",
                                category: "Perseverer",
                            },
                            {
                                percentScore: 81,
                                name: "Business winner",
                                category: "Business winner",
                            },
                            {
                                percentScore: 42,
                                name: "Technician",
                                category: "Technician",
                            },
                            {
                                percentScore: 42,
                                name: "Admin. support",
                                category: "Admin. support",
                            },
                            {
                                percentScore: 51,
                                name: "Team manager",
                                category: "Team manager",
                            },
                        ]}
                        percentageAxisLabels={{
                            first: "0%",
                            second: "25%",
                            third: "50%",
                            fourth: "75%",
                            fifth: "99%",
                        }}
                        colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                        percentage
                        labelAddOn="ile"
                        percentageAxisMargin
                        noTrendLegend
                        filterText="Influencing style"
                        customTopAxis={<CustomAxis />}
                    />
                    <div className="flex flex-col gap-4">
                        <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                            <div className="text-secondary-600 font-medium text-base">
                                Primary Influencing Style: Business Winner
                            </div>
                            <div className={"flex flex-col gap-2"}>
                                <p className="text-slate-500 text-sm">
                                    The Business Winner typically attracts new opportunities from previously unknown
                                    sources. This will often be achieved through a combination of persistence and the
                                    preparedness to try novel and unconventional approaches to influencing or selling.
                                    With a tendency to be highly competitive they usually work hard for their successes
                                    but their tendency to be unconventional may cause discomfort amongst their
                                    colleagues. The Business Winner will usually be a particular asset in those times
                                    when existing areas have become saturated or when there is a particular requirement
                                    to expand into new markets.
                                </p>
                            </div>
                        </div>
                        <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                            <div className="text-secondary-600 font-medium text-base">
                                Secondary Influencing Style: Enthusiast
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-slate-500 text-sm">
                                    The Enthusiastʹs success is strongly linked to their ability to convey enthusiasm
                                    for the idea, service or product they are promoting. They can be fairly competitive
                                    individuals and they have a strong desire to succeed. Their energetic and keen
                                    approach can have a contagious effect on their audience, thus influencing the
                                    likelihood of a positive response to the idea, service or product they are
                                    promoting.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    ref={jumpToRefs.subordinate}
                    className={"flex flex-col gap-6 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                >
                    <div className="flex flex-col gap-4">
                        <div className="text-secondary-600 font-medium text-base">Subordinate styles</div>
                        <div
                            className={
                                "flex-row gap-2 p-2 rounded-xl bg-slate-50 border " +
                                "border-slate-100 shadow-sm text-slate-500 print:hidden " +
                                (onlyCharts ? "hidden" : "flex")
                            }
                        >
                            <span className={"font-title text-sm"}>
                                Based on the work of the American Organisational Psychologist Bass, the Subordinate
                                Styles describe which of a range of styles Mr. Sample is most likely to adopt. This may
                                be of relevance to a variety of situations where a particular management style is in
                                place. As with most personality characteristics, the profile only describes the style of
                                management to which he is most likely to respond and not effectiveness. Effective
                                performance will depend on many factors including the organisational culture in which
                                the individual is operating.
                            </span>
                        </div>
                    </div>
                    <BarChart
                        data={[
                            {
                                percentScore: 28,
                                name: "Receptive sub.",
                                category: "Receptive sub.",
                            },
                            {
                                percentScore: 52,
                                name: "Self-reliant sub.",
                                category: "Self-reliant sub.",
                            },
                            {
                                percentScore: 69,
                                name: "Collaborative sub.",
                                category: "Collaborative sub.",
                            },
                            {
                                percentScore: 28,
                                name: "Informative sub.",
                                category: "Informative sub.",
                            },
                            {
                                percentScore: 74,
                                name: "Reciprocating sub.",
                                category: "Reciprocating sub.",
                            },
                        ]}
                        percentageAxisLabels={{
                            first: "0%",
                            second: "25%",
                            third: "50%",
                            fourth: "75%",
                            fifth: "99%",
                        }}
                        colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                        percentage
                        labelAddOn="ile"
                        percentageAxisMargin
                        noTrendLegend
                        filterText="Influencing style"
                        customTopAxis={<CustomAxis />}
                    />
                    <div className="flex flex-col gap-4">
                        <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                            <div className="text-secondary-600 font-medium text-base">
                                Primary Subordinate Style: Reciprocating Subordinate
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-slate-500 text-sm">
                                    Reciprocating Subordinates tend to be individuals with an emotionally mature
                                    outlook, who rarely become upset by criticism or setbacks. As such they generally
                                    feel comfortable about promoting their own ideas or engaging in negotiations with
                                    managers concerning the best approach to projects. Hence, they are likely to be most
                                    complementary to the Negotiative Leader and, given that the Reciprocative
                                    Subordinates usually have strong views of their own, any exchanges between
                                    subordinate and manager will typically be productive.
                                </p>
                            </div>
                        </div>
                        <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                            <div className="text-secondary-600 font-medium text-base">
                                Secondary Subordinate Style: Collaborative Subordinate
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-slate-500 text-sm">
                                    Collaborative Subordinates believe that the problem‐solving power of the team is
                                    more than that of the individual members included within that team: their primary
                                    concern is that the team as a whole achieves its objective. Collaborative
                                    subordinates relish group discussions and will typically propose innovative ideas of
                                    their own, as well as being more than happy to discuss the ideas of others. As
                                    strong believers in constructive criticism, they show little reluctance when it
                                    comes to pointing out weaknesses in other peoplesʹ ideas; similarly they are usually
                                    happy to accept the criticisms of others. Collaborative Subordinates are at their
                                    most effective when working under managers who share their views about group
                                    participation ‐ i.e., those who encourage collaboration rather than those with a
                                    more directive style.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    ref={jumpToRefs.teamRoles}
                    className={"flex flex-col gap-6 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                >
                    <div className="flex flex-col gap-4">
                        <div className="text-secondary-600 font-medium text-base">Team roles</div>
                        <div
                            className={
                                "flex-row gap-2 p-2 rounded-xl bg-slate-50 border " +
                                "border-slate-100 shadow-sm text-slate-500 print:hidden " +
                                (onlyCharts ? "hidden" : "flex")
                            }
                        >
                            <span className={"font-title text-sm"}>
                                The Team Roles describe how Mr. Sample is likely to interact with his colleagues in a
                                team situation. The specific ways in which he will express his preferred team style may,
                                however, vary according to the situation. In addition, this behavioural style takes no
                                account of his intellectual approach to problems and the quality of his decisions. The
                                scores below indicate his general propensity for a particular team role orientation. It
                                must be noted that different styles may be adopted according to the demands of the
                                situation and consequently a description of his predominant and secondary team styles is
                                provided.
                            </span>
                        </div>
                    </div>
                    <BarChart
                        data={[
                            {
                                percentScore: 41,
                                name: "Co. coordinator",
                                category: "Co. coordinator",
                            },
                            {
                                percentScore: 62,
                                name: "Shape driver",
                                category: "Shape driver",
                            },
                            {
                                percentScore: 45,
                                name: "Evaluator-critic",
                                category: "Evaluator-critic",
                            },
                            {
                                percentScore: 50,
                                name: "Implementer",
                                category: "Implementer",
                            },
                            {
                                percentScore: 45,
                                name: "Team builder",
                                category: "Team builder",
                            },
                            {
                                percentScore: 69,
                                name: "Resource investigator",
                                category: "Resource investigator",
                            },
                            {
                                percentScore: 30,
                                name: "Inspector completor",
                                category: "Inspector completor",
                            },
                            {
                                percentScore: 38,
                                name: "Innovator",
                                category: "Innovator",
                            },
                        ]}
                        percentageAxisLabels={{
                            first: "0%",
                            second: "25%",
                            third: "50%",
                            fourth: "75%",
                            fifth: "99%",
                        }}
                        colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                        percentage
                        labelAddOn="ile"
                        percentageAxisMargin
                        noTrendLegend
                        filterText="Influencing style"
                        customTopAxis={<CustomAxis />}
                    />
                    <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                        <div className="text-secondary-600 font-medium text-base">
                            Team Role Combination ‐ Shaper‐Driver/Resource Investigator
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-slate-500 text-sm">
                                Mr. Sampleʹs outgoing, energetic and determined style is likely to enable him to achieve
                                a great deal whilst also maintaining cheerful work relationships. He will tend to enjoy
                                social stimulation, which enables him to make contacts outside the organisation. In
                                addition, his wit and charm should mean that colleagues enjoy working in his company.
                                Underneath this social ease, however, is likely to be a restless drive for results and
                                personal recognition. He has a strong and restless desire to see results quickly, so he
                                should have a high work rate, but he could spring into action a little too quickly and
                                cut corners to get things done. Nevertheless, this propensity for action means that he
                                should be capable of taking risks and make decisions on the spot. When he is concerned
                                about the task at hand, he may tend to become a little dominating but, usually, his
                                enthusiasm will motivate a group to give their best to the group effort. His social
                                skill is likely to give him the potential to steer a conversation in the direction he
                                desires and he should be able to smooth over any conflict he stirs up.
                            </p>
                            <p className="text-slate-500 text-sm">
                                A problem may, however, be that 8 9 he tends to jump a little too hastily into a project
                                without first analysing problems, in order to achieve quick results. Therefore, he may
                                need to develop patience and persistence for long‐term, demanding jobs, and in trusting
                                others to perform their roles on a project without his direct control. Nevertheless, he
                                is likely to be effective in an organisation as the kind of person who can take up a
                                challenge and inspire those he works with to perform as he sets the pace.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    ref={jumpToRefs.interpersonal}
                    className={
                        "flex-col gap-8 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                        (onlyCharts ? "hidden" : "flex")
                    }
                >
                    <div className="flex flex-col gap-4">
                        <div className="text-secondary-600 font-medium text-base">
                            Interpersonal and Relationships Building Styles
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-slate-500 text-sm">
                                Mr. Sample will like a good deal of contact with clients, managers or work colleagues
                                and will adopt quite an open style in his communications. He is likely to feel at ease
                                in the company of strangers, and will tend to come forward in work gatherings.
                                Expressing moderate levels of kindness, he is likely to be seen as relatively supportive
                                of colleagues. No more or less sympathetic and understanding than most, he will be able
                                to maintain a degree of distance from colleagues when necessary.
                            </p>
                            <p className="text-slate-500 text-sm">
                                While staying within the bounds of what is socially acceptable, Mr. Sample tends to be
                                spontaneous, enthusiastic and quite optimistic in his approach towards work. Fun loving,
                                he will enjoy animated lively work events and activities. Spontaneity quite appeals to
                                him. However, only with encouragement and support would he be tempted into taking rash
                                action. He is likely to be quite confident, venturesome and quite uninhibited in work
                                interactions. However his level of active participation will be held in check by an
                                awareness of work expectations. He will be attracted to work settings where he will be
                                able to relate easily and comfortably with clients, managers or work colleagues. He will
                                generally have little trouble with public speaking. His ability to withstand external
                                pressures without expending too much inner resources will enable him to face quite
                                difficult emotional situations.
                            </p>
                            <p className="text-slate-500 text-sm">
                                Generally preferring to work within a team, Mr. Sample enjoys group participation and
                                work recognition. He will tend to feel most comfortable working in a group setting,
                                where he can share his thoughts with others. This reliance on group support may reflect
                                a lack of individual resolution. As amenable and co‐operative as most, he will generally
                                not seek confrontation for confrontationʹs sake. Being moderately competitive, he should
                                not be averse to meeting work‐related challenges. While capable of being outspoken on
                                occasion, he should not be oblivious to othersʹ feelings and needs.
                            </p>
                            <p className="text-slate-500 text-sm">
                                As adaptable and accommodating as the norm, Mr. Sampleʹs initial approach is to give
                                clients, managers or work colleagues the benefit of the doubt. If he feels that his
                                trust is being abused, his tolerance may rapidly turn to scepticism. In workplace
                                interactions he maintains a balance by not being overbearing nor being too easily
                                dominated. He is no more or less assertive than most.
                            </p>
                            <p className="text-slate-500 text-sm">
                                Mr. Sample will be disinclined to become involved in situations where restraint and
                                composure are lost. Consequently he may wish to avoid challenging stakeholders when he
                                perceives such a danger may exist. He is likely to be quite concerned to avoid making
                                social blunders.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    ref={jumpToRefs.thinking}
                    className={
                        "flex-col gap-8 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                        (onlyCharts ? "hidden" : "flex")
                    }
                >
                    <div className="flex flex-col gap-2">
                        <div className="text-secondary-600 font-medium text-base">
                            Thinking and Decision‐making Styles
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-slate-500 text-sm">
                                Mr. Sample should, in most situations, be able to strike a balance between viewing
                                things from an intuitive, subjective perspective and a rational, objective stance. While
                                not being overly speculative in his approach, he is nonetheless likely to be as
                                receptive to experiences and emotions as most. Somewhat unconventional in his attitudes
                                and opinions, his views may tend to be at variance with those of most. This may lead him
                                to question older, established points of view and be hesitant about accepting the
                                status‐quo. His forcefulness combined with his work sophistication could make him fairly
                                instrumental as a change agent.
                            </p>
                            <p className="text-slate-500 text-sm">
                                Having an appreciation of creative issues whilst keeping functional considerations in
                                mind, Mr. Sample strikes a balance between the rational and the emotional in
                                decision‐making. He will generally give his attention to practical matters and will be
                                quite alert to external realities. Relatively down to earth, he will tend to focus on
                                the here and now. Alertness, caution and practical concern should contribute to a low
                                frequency of errors. Inclined to reject abstract ideas that have little practical
                                relevance, he will have little time for highly conceptual, theoretically‐minded
                                individuals.
                            </p>
                            <p className="text-slate-500 text-sm">
                                Having below average levels of self‐control, Mr. Sampleʹs behaviour and attitudes are
                                more a function of his own personal belief system than work norms and expectations.
                                Free‐thinking and quite spontaneous, he may be somewhat dismissive of authority. Not
                                having particularly high levels of self‐discipline or self‐control, he is not likely to
                                be overly concerned about his work standing. He may prefer to relate casually to others
                                rather than strictly observe formality and work etiquette. A personʹs status, position
                                or authority is unlikely to carry much weight with him ‐ he will tend to view clients,
                                managers or work colleagues on their merits. However, being diplomatic and aware of the
                                impact he may have on others, these attitudes may not always be evident.
                            </p>
                            <p className="text-slate-500 text-sm">
                                Manifestly rather unconstrained to some degree, Mr. Sample has an inclination to
                                circumvent rules, especially when it is expedient to do so. Unmindful of obligation, he
                                might be an extremely inconsistent or poor finisher, unless pressed. This may be more
                                apparent on work that he does not consider to be of great significance. He may prefer to
                                have a number of ongoing commitments, but lacking a strong sense of personal obligation,
                                is prone to put them down when they become tedious or repetitive. He is not prone to
                                deliberation and procrastination, preferring rapid action to lengthy contemplation.
                                Tending to view things from a global perspective, he will prefer to avoid work that
                                demands close attention to detail.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    ref={jumpToRefs.coping}
                    className={
                        "flex-col gap-8 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                        (onlyCharts ? "hidden" : "flex")
                    }
                >
                    <div className="flex flex-col gap-2">
                        <div className="text-secondary-600 font-medium text-base">Coping with Pressure and Stress</div>
                        <div className="flex flex-col gap-2">
                            <p className="text-slate-500 text-sm">
                                Mr. Sample is currently experiencing average levels of stress. Not unduly prone to mood
                                swings, he should have sufficient inner resources to cope with work demands. However, he
                                may nonetheless experience some stress coping with particularly demanding situations or
                                when placed under extreme emotional pressure.
                            </p>
                            <p className="text-slate-500 text-sm">
                                Relatively secure and fairly self‐assured, Mr. Sample is likely to be cheerful,
                                optimistic and free of apprehension, regrets and self‐doubt. Relatively sure of himself,
                                he is likely to appear confident, especially in work settings. If things go wrong he is
                                liable to blame others.
                            </p>
                            <p className="text-slate-500 text-sm">
                                As relaxed and composed as most, he is not usually troubled by feelings of irritability
                                and tension. Like most, however, he may become a little restless or tense when under
                                pressure but this should not cause him undue problems as he is likely to be able to
                                relax without too much difficulty. Whilst minor irritations should not generally upset
                                him, if his goals are repeatedly hindered he may become impatient or annoyed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
