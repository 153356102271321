import React, { useState, useEffect } from "react";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import { useLocation } from "react-router";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import SectionPlaceholder from "../../../SharedComponents/Loading/SectionPlaceholder";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, WORKFORCE_AUSTRALIA_URL } from "../../../../utils/constants";
import { getRecommendedJobInfo } from "../../../../utils/api";
import Button from "../../../SharedComponents/Button";
import { ArrowTopRightOnSquareIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

function RecommendedJob() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [recommendedJobInfo, setRecommendedJobInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandCourses, setExpandCourses] = useState([]);

    const location = useLocation();

    const renderWorkStatsName = (data) => {
        let result = data;

        if (data === "aged15To24") {
            result = "Aged 15 to 24";
        } else if (data === "aged25To34") {
            result = "Aged 25 to 34";
        } else if (data === "aged35To44") {
            result = "Aged 35 to 44";
        } else if (data === "aged45Plus") {
            result = "Aged 45 +";
        } else if (data === "fullTime") {
            result = "Full Time";
        } else if (data === "partTime") {
            result = "Part Time";
        } else if (data === "selfEmployed") {
            result = "Self Employed";
        } else if (data === "workforceSize") {
            result = "Workforce Size";
        }

        return result && result.length > 0 ? result[0].toUpperCase() + result.slice(1).toLowerCase() : result;
    };

    useEffect(() => {
        if (location.state.recommendedJobCode) {
            const fetchRecommendedJobInfo = async () => {
                setLoading(true);
                try {
                    const recommendedJobInfoResponse = await getRecommendedJobInfo(location.state.recommendedJobCode);
                    setRecommendedJobInfo(recommendedJobInfoResponse);
                } catch (err) {
                    setAlert(err.message);
                    setAlertType(ALERT_TYPE.ERROR);
                }
                setLoading(false);
            };
            fetchRecommendedJobInfo();
        }
    }, [location.state.recommendedJobCode]);

    return (
        <DashboardLayout
            title={"Recommended Job" + (recommendedJobInfo?.jobTitle ? ": " + recommendedJobInfo?.jobTitle : "")}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS]}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />

            {loading ? (
                <SectionPlaceholder />
            ) : (
                recommendedJobInfo && (
                    <div className="flex flex-col gap-3 text-sm">
                        <div className="flex flex-col gap-3 text-sm">
                            {recommendedJobInfo?.ozjacInformation?.jobDescription && (
                                <div className="bg-white rounded-xl p-4 shadow-sm">
                                    <div className="flex flex-col gap-2">
                                        <h3 className="text-sm font-medium text-primary-700 ">Job description</h3>

                                        <div className="text-slate-500">
                                            {recommendedJobInfo.ozjacInformation.jobDescription}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="bg-white rounded-xl p-4 shadow-sm">
                                <div className="flex flex-col gap-4">
                                    {recommendedJobInfo?.ozjacInformation?.commonActivities &&
                                        recommendedJobInfo.ozjacInformation.commonActivities.length > 0 && (
                                            <div className="flex flex-col gap-2">
                                                <h3 className="text-sm font-medium leading-6 text-primary-700 ">
                                                    Common activities
                                                </h3>

                                                <ul className="list-disc ml-8 text-slate-500">
                                                    {recommendedJobInfo.ozjacInformation.commonActivities.map(
                                                        (activity, index) => (
                                                            <li key={index}>{activity}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        )}

                                    {recommendedJobInfo?.ozjacInformation?.highestInterestCategories && (
                                        <div className="flex flex-col gap-2">
                                            <h3 className="text-sm font-medium text-primary-600 ">
                                                Highest Interest Categories
                                            </h3>

                                            <ul className="list-disc ml-8 text-slate-500">
                                                {Object.entries(
                                                    recommendedJobInfo.ozjacInformation.highestInterestCategories
                                                ).map(([key, value]) => (
                                                    <li key={key}>{value}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {recommendedJobInfo?.relatedJobsInAustralia &&
                            (recommendedJobInfo?.relatedJobsInAustralia?.ozJobName ||
                                recommendedJobInfo?.relatedJobsInAustralia?.relatedJobs) && (
                                <div className="bg-white rounded-xl p-4 shadow-sm">
                                    <div className="flex flex-col gap-4">
                                        <h3 className="text-sm font-medium leading-6 text-primary-700 ">
                                            Related jobs in Australia
                                        </h3>

                                        <div className="bg-slate-50 rounded-xl p-5 shadow-sm">
                                            <div className="flex flex-col gap-4">
                                                {recommendedJobInfo?.relatedJobsInAustralia?.ozJobName && (
                                                    <div className="flex flex-col gap-2">
                                                        <h4 className="text-sm font-medium text-primary-600 ">
                                                            In Australia, this occupation is known as:
                                                        </h4>

                                                        <ul className="list-disc ml-8 text-slate-500">
                                                            <li>
                                                                <span>
                                                                    {
                                                                        recommendedJobInfo.relatedJobsInAustralia
                                                                            .ozJobName
                                                                    }
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}

                                                {recommendedJobInfo?.relatedJobsInAustralia?.relatedJobs && (
                                                    <div className="flex flex-col gap-2">
                                                        <h4 className="font-medium text-primary-600 text-sm">
                                                            Related Jobs
                                                        </h4>

                                                        <ul className="list-disc ml-8 text-slate-500">
                                                            {recommendedJobInfo.relatedJobsInAustralia.relatedJobs.map(
                                                                (job, index) => (
                                                                    <li key={index}>
                                                                        <span>{job.jobName}</span>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        {recommendedJobInfo?.workforce && (
                            <div className="bg-white rounded-xl p-4 shadow-sm">
                                <div className="flex flex-col gap-4">
                                    <h3 className="text-sm font-medium leading-6 text-primary-700 ">
                                        Workforce - {recommendedJobInfo?.workforce?.workforceName}
                                    </h3>

                                    {recommendedJobInfo?.workforce?.workforceNote && (
                                        <p className="text-slate-500">{recommendedJobInfo?.workforce?.workforceNote}</p>
                                    )}

                                    {recommendedJobInfo?.workforce?.workforceDescription &&
                                        recommendedJobInfo?.workforce?.workforceDescription !== "-" && (
                                            <p className="text-slate-500">
                                                {recommendedJobInfo?.workforce?.workforceDescription}
                                            </p>
                                        )}

                                    {recommendedJobInfo?.workforce?.workStatistics && (
                                        <div className="bg-slate-50 rounded-xl p-5 shadow-sm">
                                            <div className="flex flex-col gap-4">
                                                <div className="flex flex-col gap-2">
                                                    <h4 className="text-sm font-medium text-primary-600 ">
                                                        Work Statistics
                                                    </h4>

                                                    <table className="table-auto bg-white rounded-xl">
                                                        <thead>
                                                            <tr className="bg-slate-100 rounded-t-xl">
                                                                <th className="text-slate-500 font-medium text-left p-2 rounded-tl-xl">
                                                                    Category/Information
                                                                </th>

                                                                <th className="text-slate-500 font-medium text-left p-2 rounded-tr-xl">
                                                                    Value
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="rounded-b-xl shadow-sm">
                                                            {Object.entries(
                                                                recommendedJobInfo?.workforce?.workStatistics
                                                            ).map(([key, value], index) => (
                                                                <tr key={index}>
                                                                    <td className="text-slate-500 p-2">
                                                                        {renderWorkStatsName(key)}
                                                                    </td>

                                                                    <td className="text-slate-500 p-2">
                                                                        {key !== "workforceSize"
                                                                            ? value.toString() + "%"
                                                                            : value.toString()}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {recommendedJobInfo?.jobProfiles && (
                            <div className="bg-white rounded-xl p-4 shadow-sm">
                                <div className="flex flex-col gap-4">
                                    <h3 className="text-sm font-medium leading-6 text-primary-700">Job Profiles</h3>

                                    <p className="text-slate-500">
                                        {recommendedJobInfo.jobProfiles.jobProfileDescription}
                                    </p>

                                    <div className="bg-slate-50 rounded-xl p-5 shadow-sm">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col gap-1">
                                                <h4 className="font-medium text-primary-600 text-sm">Duties</h4>

                                                {recommendedJobInfo.jobProfiles.duties ? (
                                                    (() => {
                                                        if (recommendedJobInfo.jobProfiles.duties.includes("\\par")) {
                                                            const duties =
                                                                recommendedJobInfo.jobProfiles.duties.split("\\par");

                                                            return duties.map((duty, index) => (
                                                                <p key={index} className="text-slate-500">
                                                                    {duty}
                                                                </p>
                                                            ));
                                                        } else {
                                                            return (
                                                                <p className="text-slate-500">
                                                                    {recommendedJobInfo.jobProfiles.duties}
                                                                </p>
                                                            );
                                                        }
                                                    })()
                                                ) : (
                                                    <p className="text-slate-500">N/A</p>
                                                )}
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <h4 className="font-medium text-primary-600 text-sm">
                                                    Specialisations
                                                </h4>

                                                {recommendedJobInfo.jobProfiles.specialis ? (
                                                    (() => {
                                                        if (
                                                            recommendedJobInfo.jobProfiles.specialis.includes("\\par")
                                                        ) {
                                                            const specialis =
                                                                recommendedJobInfo.jobProfiles.specialis.split("\\par");

                                                            return specialis.map((special, index) => (
                                                                <p key={index} className="text-slate-500">
                                                                    {special}
                                                                </p>
                                                            ));
                                                        } else {
                                                            return (
                                                                <p className="text-slate-500">
                                                                    {recommendedJobInfo.jobProfiles.specialis}
                                                                </p>
                                                            );
                                                        }
                                                    })()
                                                ) : (
                                                    <p className="text-slate-500">N/A</p>
                                                )}
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <h4 className="font-medium text-primary-600 text-sm">
                                                    Working Conditions
                                                </h4>

                                                {recommendedJobInfo.jobProfiles.workingConditions ? (
                                                    (() => {
                                                        if (
                                                            recommendedJobInfo.jobProfiles.workingConditions.includes(
                                                                "\\par"
                                                            )
                                                        ) {
                                                            const workingConditions =
                                                                recommendedJobInfo.jobProfiles.workingConditions.split(
                                                                    "\\par"
                                                                );

                                                            return workingConditions.map((workingCondition, index) => (
                                                                <p key={index} className="text-slate-500">
                                                                    {workingCondition}
                                                                </p>
                                                            ));
                                                        } else {
                                                            return (
                                                                <p className="text-slate-500">
                                                                    {recommendedJobInfo.jobProfiles.workingConditions}
                                                                </p>
                                                            );
                                                        }
                                                    })()
                                                ) : (
                                                    <p className="text-slate-500">N/A</p>
                                                )}
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <h4 className="font-medium text-primary-600 text-sm">
                                                    Personal Requirements
                                                </h4>

                                                {recommendedJobInfo.jobProfiles.personalRequirement ? (
                                                    (() => {
                                                        if (
                                                            recommendedJobInfo.jobProfiles.personalRequirement.includes(
                                                                "\\par"
                                                            )
                                                        ) {
                                                            const personalRequirement =
                                                                recommendedJobInfo.jobProfiles.personalRequirement.split(
                                                                    "\\par"
                                                                );

                                                            return personalRequirement.map((requirement, index) => (
                                                                <p key={index} className="text-slate-500">
                                                                    {requirement}
                                                                </p>
                                                            ));
                                                        } else {
                                                            return (
                                                                <p className="text-slate-500">
                                                                    {recommendedJobInfo.jobProfiles.personalRequirement}
                                                                </p>
                                                            );
                                                        }
                                                    })()
                                                ) : (
                                                    <p className="text-slate-500">N/A</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {recommendedJobInfo?.jobExplorerAndOutlook && (
                            <div className="bg-white rounded-xl p-4 shadow-sm">
                                <div className="flex flex-col gap-4">
                                    <h3 className="text-sm font-medium leading-6 text-primary-700 ">
                                        Job explorer & outlook
                                    </h3>

                                    <p className="text-slate-500">
                                        {recommendedJobInfo.jobExplorerAndOutlook.jobExplorerDescription}
                                    </p>

                                    <Button
                                        onClick={() => window.open(WORKFORCE_AUSTRALIA_URL, "_blank")}
                                        icon={<ArrowTopRightOnSquareIcon className="block h-3.5 w-3.5 ml-2" />}
                                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                        text="Job Explorer"
                                        smallButton
                                        extraStyling={"text-xs text-white"}
                                    />

                                    <div>
                                        <p className="text-slate-500">
                                            {recommendedJobInfo.jobExplorerAndOutlook.jobOutlookDescription}
                                        </p>
                                    </div>

                                    <Button
                                        onClick={() => window.open(WORKFORCE_AUSTRALIA_URL, "_blank")}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        icon={<ArrowTopRightOnSquareIcon className="block h-3.5 w-3.5 ml-2" />}
                                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                        text="Job Outlook"
                                        smallButton
                                        extraStyling={"text-xs text-white"}
                                    />
                                </div>
                            </div>
                        )}

                        {recommendedJobInfo?.courses && (
                            <div className="bg-white rounded-xl p-4 shadow-sm">
                                <div className="flex flex-col gap-2">
                                    <h3 className="text-sm font-medium leading-6 text-primary-700 ">Courses</h3>

                                    <ul className="grid lg:grid-cols-2 gap-x-6 gap-y-2">
                                        {recommendedJobInfo?.courses?.coursesNames &&
                                            recommendedJobInfo?.courses?.coursesNames.map((course, index) => (
                                                <div key={index}>
                                                    <li>
                                                        <div
                                                            className="flex flex-row items-center hover:underline cursor-pointer text-slate-500"
                                                            onClick={() => {
                                                                if (expandCourses.includes(course.courseId)) {
                                                                    setExpandCourses(
                                                                        expandCourses.filter(
                                                                            (job) => job !== course.courseId
                                                                        )
                                                                    );
                                                                } else {
                                                                    setExpandCourses([
                                                                        ...expandCourses,
                                                                        course.courseId,
                                                                    ]);
                                                                }
                                                            }}
                                                        >
                                                            {expandCourses.includes(course.courseId) ? (
                                                                <MinusCircleIcon className="block h-4 w-4 mr-2 text-primary-600 shrink-0" />
                                                            ) : (
                                                                <PlusCircleIcon className="block h-4 w-4 mr-2 text-primary-600 shrink-0" />
                                                            )}

                                                            {course.courseName}
                                                        </div>

                                                        {expandCourses.includes(course.courseId) && (
                                                            <div className="p-3">
                                                                <div className="flex flex-row flex-wrap gap-4 text-xs text-slate-500">
                                                                    {course?.courseSites &&
                                                                        course?.courseSites.map((courseSite) => (
                                                                            <div className="bg-slate-50 shadow-md p-3 rounded-xl">
                                                                                <div className="font-medium">
                                                                                    {courseSite.providerName}
                                                                                </div>
                                                                                <div>{courseSite.siteName}</div>
                                                                                <div>{courseSite.stateCode}</div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </li>
                                                </div>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                )
            )}
        </DashboardLayout>
    );
}

export default RecommendedJob;
