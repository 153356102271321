import { useContext, useEffect, useState } from "react";

import { COACH_PLATFORM_PERMISSIONS } from "../../../../../../../utils/constants";
import { Context } from "../../../../../../../context/Context";

import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import Completions from "./Snapshot/Completions";
import BouncingBalls from "../../../../../../SharedComponents/Loading/BouncingBalls";
import AceReportPicker from "./AceReportPicker";

export default function Demographics() {
    return (
        <DashboardLayout title="Demographics" requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}>
            <DemographicsElements />
        </DashboardLayout>
    );
}

export function DemographicsElements() {
    const [rows, setRows] = useState([]);

    const [hasHistoricalData, setHasHistoricalData] = useState(false);
    const [hasSectorBenchmarkData, setHasSectorBenchmarkData] = useState(false);

    const context = useContext(Context);

    useEffect(() => {
        if (!context.aceSurveyReportDataLoading) {
            let temporaryRows = [];

            context.aceSurveyReportData.demographics[0].items.forEach((demographicsItem) => {
                // A bit of data cleanup, TODO: Should move up to Context.js
                demographicsItem.data.historicalDemographics = demographicsItem.historicalDemographics;

                if (demographicsItem.historicalDemographics) {
                    setHasHistoricalData(true);
                }

                if (demographicsItem.data.sectorBenchmark) {
                    setHasSectorBenchmarkData(true);
                }

                temporaryRows.push(demographicsItem.data);
            });

            setRows(temporaryRows);
        }
    }, [context.aceSurveyReportData.demographics, context.aceSurveyReportDataLoading]);

    return (
        <div className="flex flex-col gap-6">
            <Completions />
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <div className="flex flex-col gap-6 break-inside-avoid-page">
                    <div className="flex flex-col gap-2">
                        <div className={"flex flex-row justify-between items-center"}>
                            <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                                Other demographic information
                            </h2>
                            <AceReportPicker small />
                        </div>
                        <p className="text-sm text-slate-500">
                            This table shows additional demographic information on respondents.
                        </p>
                    </div>
                    <div className="overflow-hidden border border-slate-100 rounded-xl shadow-sm">
                        <table className="table-fixed min-w-full">
                            <thead className="bg-slate-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className={
                                            "px-6 py-3 text-left text-xs font-medium " +
                                            "text-slate-500 uppercase tracking-wide"
                                        }
                                    >
                                        Variable
                                    </th>
                                    <th
                                        scope="col"
                                        className={"px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase "}
                                    >
                                        {context.aceSurveyReportData.currentSurveyLabel}
                                    </th>
                                    {hasHistoricalData && (
                                        <th
                                            scope="col"
                                            className={
                                                "px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase "
                                            }
                                        >
                                            {context.aceSurveyReportData.previousSurveyLabel}
                                        </th>
                                    )}
                                    {hasSectorBenchmarkData && (
                                        <th
                                            scope="col"
                                            className={
                                                "px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase "
                                            }
                                        >
                                            Sector benchmark
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {rows.map((demographicsItem, index) => (
                                    <tr key={index + "specialRow"}>
                                        <td className="px-6 py-4">
                                            <div className="text-sm text-primary-600 font-medium">
                                                {demographicsItem.current.itemText}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            {demographicsItem.current &&
                                            typeof demographicsItem.current.score === "object" ? (
                                                <div className="flex flex-row flex-wrap gap-2">
                                                    {Object.entries(demographicsItem.current.score).map(
                                                        ([key, value]) => (
                                                            <div key={key}>
                                                                <span className="text-sm font-medium text-secondary-600">
                                                                    {key}:
                                                                </span>{" "}
                                                                <span className="text-sm text-slate-600">
                                                                    {typeof value == "number" &&
                                                                        Math.round(value) + "%"}
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="text-sm text-slate-600">
                                                    {demographicsItem.current && demographicsItem.current.score}
                                                </div>
                                            )}
                                        </td>
                                        {hasHistoricalData && (
                                            <td className="px-6 py-4">
                                                {demographicsItem.historicalDemographics &&
                                                typeof demographicsItem.historicalDemographics.score === "object" ? (
                                                    <div className="flex flex-row flex-wrap gap-2">
                                                        {Object.entries(
                                                            demographicsItem.historicalDemographics.score
                                                        ).map(([key, value]) => (
                                                            <div key={key}>
                                                                <span className="text-sm font-medium text-secondary-600">
                                                                    {key}:
                                                                </span>{" "}
                                                                <span className="text-sm text-slate-600">
                                                                    {typeof value == "number" &&
                                                                        Math.round(value) + "%"}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="text-sm text-slate-600">
                                                        {demographicsItem.historicalDemographics &&
                                                            demographicsItem.historicalDemographics.score}
                                                    </div>
                                                )}
                                            </td>
                                        )}
                                        {hasSectorBenchmarkData && (
                                            <td className="px-6 py-4">
                                                {demographicsItem.sectorBenchmark &&
                                                typeof demographicsItem.sectorBenchmark.score === "object" ? (
                                                    <div className="flex flex-row flex-wrap gap-2">
                                                        {Object.entries(demographicsItem.sectorBenchmark.score).map(
                                                            ([key, value]) => (
                                                                <div key={key}>
                                                                    <span className="text-sm font-medium text-secondary-600">
                                                                        {key}:
                                                                    </span>{" "}
                                                                    <span className="text-sm text-slate-600">
                                                                        {typeof value == "number" &&
                                                                            Math.round(value) + "%"}
                                                                    </span>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="text-sm text-slate-600">
                                                        {demographicsItem.sectorBenchmark &&
                                                            demographicsItem.sectorBenchmark.score}
                                                    </div>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}
