import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlusIcon, RectangleStackIcon, ArrowUturnLeftIcon } from "@heroicons/react/24/solid";

import { createProgram } from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Button from "../../../SharedComponents/Button";
import Input from "../../../SharedComponents/Form/Input";

export default function CreateCohort() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [programName, setProgramName] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleCreateProgram = async () => {
        setLoading(true);
        try {
            await createProgram(programName);
            navigate("/programs", {
                state: {
                    alertType: ALERT_TYPE.SUCCESS,
                    alertMessage: 'Successfully created program "' + programName + '"',
                },
            });
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    return (
        <DashboardLayout title={"Create Program"} requiredPermissions={[COACH_PLATFORM_PERMISSIONS.CREATE_PROGRAM]}>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4 border border-slate-100 border-1 rounded-t-xl"}>
                        <div className={"grid grid-flow-row gap-6"}>
                            <div className={"grid grid-flow-row xl:grid-cols-2 gap-6"}>
                                <div className={"col-span-2 flex flex-col gap-3"}>
                                    <Input
                                        id="programName"
                                        type="text"
                                        label="Program name"
                                        placeholder={"Program name"}
                                        icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setProgramName}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 " +
                            "text-right rounded-b-xl w-full"
                        }
                    >
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Create program"
                            icon={<PlusIcon className="block h-3.5 w-3.5  stroke-current stroke-1 mr-2" />}
                            iconPositionLeft
                            onClick={handleCreateProgram}
                            disabled={programName === "" || loading}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
