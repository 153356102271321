import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/solid";

import { getCohorts, getPrograms } from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../utils/constants";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import CohortRow from "./CohortRow";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";

export default function Cohorts() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [cohorts, setCohorts] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentFilter, setCurrentFilter] = useState("all");
    const [filteredCohorts, setFilteredCohorts] = useState([]);

    const navigate = useNavigate();

    const location = useLocation();

    const handleFilter = useCallback(() => {
        if (currentFilter === "all") {
            setFilteredCohorts(cohorts);
        } else {
            setFilteredCohorts(cohorts);
            setFilteredCohorts(cohorts.filter((cohort) => cohort.program === currentFilter));
        }
    }, [cohorts, currentFilter]);

    const fetchCohorts = async () => {
        setLoading(true);
        try {
            const response = await getCohorts();
            setFilteredCohorts(response);
            setCohorts(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    };

    const fetchPrograms = async () => {
        try {
            const response = await getPrograms();
            setPrograms(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
    };

    useEffect(() => {
        fetchCohorts();
        fetchPrograms();
    }, []);

    useEffect(() => {
        handleFilter();
    }, [currentFilter, handleFilter]);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout
            title={"Cohorts"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_COHORTS]}
            actionButtonRequiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.CREATE_COHORT,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
            actionButton
            actionButtonText={"Create Cohort"}
            actionButtonIcon={<PlusIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
            actionButtonOnClick={() => navigate("/create-cohort")}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                loading={loading}
                items={filteredCohorts.slice(0).reverse()}
                itemName={"Cohorts"}
                searchAttributes={["name"]}
                columnNames={["Name", "Program", "Members", "Manage"]}
                ItemRow={CohortRow}
                filters={
                    <div>
                        <div className={"text-sm text-slate-400 mb-2"}>Program</div>
                        <select
                            id="programs"
                            name="programs"
                            className={
                                "text-primary-600 cursor-pointer hover:text-primary-700 bg-primary-50 p-2 " +
                                "rounded-xl shadow-sm flex w-fit font-medium " +
                                "bg-primary-100 rounded-xl shadow-sm pl-3 pr-10 py-2 text-left " +
                                "focus:outline-none outline-none focus:border-transparent ring-0 border-none " +
                                "sm:text-sm border border-primary-100"
                            }
                            onChange={(e) => setCurrentFilter(e.target.value)}
                        >
                            <option value="all">All programs</option>

                            {programs.map((program, index) => (
                                <option key={index} value={program.name}>
                                    {program.name}
                                </option>
                            ))}
                        </select>
                    </div>
                }
            />
        </DashboardLayout>
    );
}
