import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MegaphoneIcon } from "@heroicons/react/24/solid";

import { getAllRUThereUserInterviews, getRUThereUserInterviewsForCoach } from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import InterviewRow from "./InterviewRow";

export default function Interviews() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [practiceInterviews, setPracticeInterviews] = useState();
    const [filteredPracticeInterviews, setFilteredPracticeInterviews] = useState([]);
    const [allCoachees, setAllCoachees] = useState(false);
    const [allCoacheesLoading, setAllCoacheesLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [currentFilter, setCurrentFilter] = useState("all");

    const navigate = useNavigate();

    const context = useContext(Context);

    const location = useLocation();

    const fetchInterviews = useCallback(async () => {
        if (!allCoacheesLoading) {
            setLoading(true);
            let interviewsResponse = [];

            try {
                if (allCoachees) {
                    interviewsResponse = await getAllRUThereUserInterviews();
                    setPracticeInterviews(interviewsResponse);
                } else {
                    interviewsResponse = await getRUThereUserInterviewsForCoach();
                    setPracticeInterviews(interviewsResponse);
                }
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoading(false);
        }
    }, [allCoachees, allCoacheesLoading]);

    useEffect(() => {
        fetchInterviews();
    }, [fetchInterviews]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllCoachees(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES));
            setAllCoacheesLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    useEffect(() => {
        const handleFilter = () => {
            setLoading(true);

            if (currentFilter === "all") {
                setFilteredPracticeInterviews(practiceInterviews);
            } else {
                setFilteredPracticeInterviews(
                    practiceInterviews.filter((practiceInterview) => practiceInterview.completed === true)
                );
            }

            setLoading(false);
        };

        handleFilter();
    }, [currentFilter, practiceInterviews]);

    return (
        <DashboardLayout
            title={context.terminology.coachee.singular + " practice interviews"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_COMPLETED_PRACTICE_INTERVIEWS]}
            actionButton
            actionButtonRequiredPermissions={[COACH_PLATFORM_PERMISSIONS.CREATE_PRACTICE_INTERVIEW]}
            actionButtonText={"Recommend practice interview"}
            actionButtonIcon={<MegaphoneIcon className="block h-3.5 w-3.5 mr-2" />}
            actionButtonOnClick={() => navigate("/recommend-practice-interview")}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                loading={loading}
                items={filteredPracticeInterviews}
                itemName="practice interviews"
                disableSearch={!practiceInterviews || practiceInterviews.length === 1}
                searchAttributes={["campaignName", "firstName", "lastName", "emailAddress", "deadline"]}
                columnNames={[
                    "Interview",
                    context.terminology.coachee.singular,
                    "Deadline",
                    "Status",
                    context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COMPLETED_INTERVIEW_RECORDINGS) &&
                        "Recordings",
                ]}
                ItemRow={InterviewRow}
                fetchItems={fetchInterviews}
                setAlert={setAlert}
                setAlertType={setAlertType}
                filters={
                    <div className={"lg:flex items-center justify-between w-full"}>
                        <div>
                            <div className={"text-sm text-slate-400 mb-2"}>Interviews</div>
                            <select
                                id="default"
                                name="default"
                                className={
                                    "text-primary-600 cursor-pointer hover:text-primary-700 bg-primary-50 p-2 " +
                                    "rounded-xl shadow-sm flex w-fit font-medium " +
                                    "bg-primary-100 rounded-xl shadow-sm pl-3 pr-10 py-2 text-left " +
                                    "focus:outline-none outline-none focus:border-transparent ring-0 border-none " +
                                    "sm:text-sm border border-primary-100"
                                }
                                onChange={(e) => {
                                    setCurrentFilter(e.target.value);
                                }}
                            >
                                <option value="all">All interviews</option>
                                <option value="completed">Completed interviews</option>
                            </select>
                        </div>
                    </div>
                }
                overflowVisible
                customPlaceholder="Search practice interviews by interview name, first name, last name or email address"
            />
        </DashboardLayout>
    );
}
