import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../../context/Context";

import AceMindsetIndexChart from "../../../Charts/CustomCharts/AceMindsetIndexChart";
import SausageLegendItem from "../../../Charts/CustomCharts/CustomLegends/SausageLegendItem";
import TrendBubble from "../../../SharedChartComponents/TrendBubble";
import BarInfoBubble from "../../../Charts/CustomCharts/BarInfoBubble";

export default function ACEMindIndex(props) {
    const { data, demo, detailed, title, subtitle, questionText } = props;

    const [processedChartData, setProcessedChartData] = useState({});

    const context = useContext(Context);

    useEffect(() => {
        let processedChartData = {};

        Object.values(data).forEach((dataItem) => {
            if (dataItem.name === "Sector benchmark") {
                processedChartData.sectorBenchmark = dataItem.percentScore;
            } else if (
                dataItem.name === context.aceSurveyReportData.currentSurveyLabel ||
                dataItem.name === "ACE Score"
            ) {
                processedChartData.year2 = dataItem.percentScore;
                processedChartData.year1 = dataItem.historicalScore;
            }
        });

        setProcessedChartData(processedChartData);
    }, [context.aceSurveyReportData.currentSurveyLabel, data]);

    return (
        <div
            className={
                "flex flex-col gap-8 w-full bg-white p-4 rounded-xl border border-slate-100 shadow-sm " +
                "print:p-0 print:shadow-none print:border-none"
            }
        >
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center gap-2">
                        <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">{title}</h2>
                        {demo && <TrendBubble percentage={-2} text="vs benchmark" />}
                    </div>
                    {detailed && subtitle && demo && <p className="text-sm text-slate-500">{subtitle}</p>}
                </div>
                {questionText && (
                    <div className="text-sm text-secondary-600 font-medium bg-secondary-50 border border-secondary-100 rounded-xl p-2">
                        "{questionText}"
                    </div>
                )}
                <BarInfoBubble data={data[0]} staticPosition />
                <BarInfoBubble data={data[0]} showWhenPrinting />
                <AceMindsetIndexChart
                    data={
                        demo
                            ? { sectorBenchmark: 45, year1: 58, year2: 67 }
                            : {
                                  sectorBenchmark: processedChartData.sectorBenchmark,
                                  year2: processedChartData.year2,
                                  year1: processedChartData.year1,
                              }
                    }
                />
            </div>
            <div className="flex flex-row gap-8 w-full justify-center">
                {processedChartData.sectorBenchmark && (
                    <SausageLegendItem label="Sector benchmark" horizontal large backgroundColour="bg-amber-600" />
                )}
                {processedChartData.year2 && (
                    <SausageLegendItem
                        label={context.aceSurveyReportData.currentSurveyLabel}
                        horizontal
                        large
                        backgroundColour="bg-blue-600"
                    />
                )}
                {processedChartData.year1 && (
                    <SausageLegendItem
                        label={context.aceSurveyReportData.previousSurveyLabel}
                        horizontal
                        large
                        backgroundColour="bg-violet-600"
                    />
                )}
            </div>
        </div>
    );
}
