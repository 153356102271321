import { useCallback, useEffect, useRef, useState } from "react";
import Button from "./Button";
import { BASIC_ANIMATION_VARIANTS, BUTTON_VARIANT_TYPES } from "../../utils/constants";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";

export default function FatDropdown(props) {
    const {
        content,
        text,
        position,
        icon,
        buttonVariant,
        smallButton,
        onClose,
        closeFilters,
        fatDropdownRef,
        setOpenStateRef,
        noIcon,
    } = props;

    const [showFilters, setShowFilters] = useState(false);

    const wrapperRef = useRef();

    const handleClickListener = useCallback(
        (event) => {
            if (!wrapperRef.current.contains(event.target)) {
                setShowFilters(false);

                if (onClose) {
                    onClose();
                }
            } else {
                if (setOpenStateRef) {
                    setOpenStateRef(true);
                }
            }
        },
        [onClose, setOpenStateRef]
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickListener);

        return () => {
            document.removeEventListener("mousedown", handleClickListener);
        };
    }, [handleClickListener]);

    useEffect(() => {
        setShowFilters(false);
    }, [closeFilters]);

    useEffect(() => {
        if (setOpenStateRef) {
            setOpenStateRef(showFilters);
        }
    }, [setOpenStateRef, showFilters]);

    return (
        <div className="relative z-[100]" ref={wrapperRef}>
            <Button
                buttonRef={fatDropdownRef}
                colour={buttonVariant ? buttonVariant : BUTTON_VARIANT_TYPES.GRAY_DARKER}
                icon={
                    icon
                        ? icon
                        : !noIcon && (
                              <FunnelIcon
                                  className={
                                      "block stroke-current stroke-1 mr-2 " + (smallButton ? "h-3 w-3" : "h-3.5 w-3.5")
                                  }
                              />
                          )
                }
                iconPositionLeft
                secondIcon={
                    <ChevronDownIcon
                        className={"block stroke-current stroke-1 ml-2 " + (smallButton ? "h-3 w-3" : "h-3.5 w-3.5")}
                    />
                }
                text={text ? text : "Filter"}
                onClick={() => setShowFilters(!showFilters)}
                loadingIconSize={smallButton ? "h-3 w-3" : "h-3.5 w-3.5"}
                smallButton={smallButton}
            />
            <AnimatePresence>
                {showFilters && (
                    <motion.div
                        initial={"inactiveHeight"}
                        animate={"activeHeight"}
                        exit={"inactiveHeight"}
                        transition={{ duration: 0.2 }}
                        variants={BASIC_ANIMATION_VARIANTS}
                        className={"absolute " + (position ? position : "top-11")}
                    >
                        {content}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
