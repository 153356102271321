import { HEADFIRST_ILLUSTRATIONS } from "../../../../../../utils/constants";

export default function AtAGlanceOverallCard(props) {
    const { scale } = props;

    return (
        <div
            className={
                "shadow-sm p-4 rounded-xl col-span-2 " +
                (scale.percentage <= 34
                    ? "bg-emerald-50 border border-emerald-100"
                    : scale.percentage > 34 && scale.percentage < 74
                    ? "bg-amber-50 border border-amber-100"
                    : "bg-red-50 border border-red-100")
            }
        >
            <div className="flex flex-row gap-4">
                <img src={HEADFIRST_ILLUSTRATIONS[scale.name.toUpperCase()]} className="w-[100px]" alt="" />
                <div className="flex flex-col gap-2 w-full">
                    <div
                        className={
                            "flex flex-row justify-between items-center " +
                            (scale.percentage <= 34
                                ? "text-emerald-600"
                                : scale.percentage > 34 && scale.percentage < 74
                                ? "text-amber-500"
                                : "text-red-500")
                        }
                    >
                        <p className={"text-sm font-medium"}>Overall Wellbeing</p>
                    </div>
                    <div
                        className={
                            "transition transition-color duration-4000 rounded-xl " +
                            (scale.percentage <= 34
                                ? "text-emerald-600"
                                : scale.percentage > 34 && scale.percentage < 74
                                ? "text-amber-500"
                                : "text-red-500")
                        }
                    >
                        <p className={"leading-relaxed text-sm"}>
                            {scale.percentage <= 34
                                ? "This individual appears to be have high levels of wellbeing and is not likely to require additional assistance or support"
                                : scale.percentage > 34 && scale.percentage < 74
                                ? "This individual appears to be have moderate levels of wellbeing and is likely to require some assistance or support to be effective in the workplace."
                                : "This individual appears to be have lower levels of wellbeing and is likely to require additional assistance and support to be effective in the workplace."}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
