import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUturnLeftIcon, ArrowDownTrayIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import { isMobile } from "react-device-detect";

import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import barInfoBubbleScreenshot from "../../../../assets/barinfobubble.png";
import sectorBenchmarkScreenshot from "../../../../assets/sectorbenchmark.png";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Button from "../../../SharedComponents/Button";
import FancyCheckBox from "../../../SharedComponents/Form/FancyCheckBox";
import RadioButton from "../../../SharedComponents/Form/RadioButton";
import BasicMotion from "../../../SharedComponents/Motion/BasicMotion";

export default function CreateReportPDF() {
    const [chosenSections, setChosenSections] = useState([]);
    const [chosenReportTypeSections, setChosenReportTypeSections] = useState([]);
    const [chosenStates, setChosenStates] = useState(["vic", "sa", "wa", "act", "nsw", "tas", "nt"]);
    const [chosenOptions, setChosenOptions] = useState([]);
    const [reportType, setReportType] = useState("");

    const navigate = useNavigate();

    function processChosenItems(item, chosenItems, setChosenItems) {
        setChosenReportTypeSections([]);
        setReportType("custom");

        let updatedChosenItems = [];
        let updatedChosenReportTypeSections = [];

        if (chosenItems.some((chosenItem) => chosenItem === item)) {
            updatedChosenItems = chosenItems.filter((chosenItem) => item !== chosenItem);
            setChosenItems(updatedChosenItems);

            updatedChosenReportTypeSections = chosenReportTypeSections.filter((chosenItem) => item !== chosenItem);
            setChosenReportTypeSections(updatedChosenReportTypeSections);
        } else {
            if (!chosenReportTypeSections.some((chosenItem) => chosenItem === item)) {
                setChosenItems((oldChosenItems) => [...oldChosenItems, item]);
            }
        }
    }

    function handleChoosingReportType(event) {
        setChosenSections([]);
        setReportType(event.target.value);

        const defaultSections = ["executiveSummary", "demographics", "reportContext"];
        let currentChosenReportTypeSections = [];

        if (event.target.value === "national") {
            currentChosenReportTypeSections = ["national", ...defaultSections];
        } else if (event.target.value === "regional") {
            currentChosenReportTypeSections = ["regional", ...defaultSections];
        } else if (event.target.value === "nationalAndRegional") {
            currentChosenReportTypeSections = ["national", "regional", ...defaultSections];
        } else if (event.target.value === "fieldManagers") {
            currentChosenReportTypeSections = ["fieldManagers", ...defaultSections];
        } else if (event.target.value === "qualitative") {
            currentChosenReportTypeSections = ["qualitative", ...defaultSections];
        } else if (event.target.value === "allSections") {
            currentChosenReportTypeSections = [
                "national",
                "regional",
                "fieldManagers",
                "qualitative",
                ...defaultSections,
            ];
        }

        setChosenReportTypeSections(currentChosenReportTypeSections);
        setChosenSections(currentChosenReportTypeSections);
    }

    return (
        <DashboardLayout title={"Create report PDF"} requiredPermissions={[]}>
            <div className={"focus:outline-none shadow-sm rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"flex flex-col gap-6 p-4 border border-slate-100 border-1 rounded-t-xl"}>
                        <div className="flex flex-col gap-3">
                            <label className={"text-sm font-medium w-full text-secondary-600"}>
                                Choose from report templates
                            </label>
                            <fieldset>
                                <div
                                    className="flex flex-row flex-wrap gap-5 items-center"
                                    onChange={(event) => {
                                        handleChoosingReportType(event);
                                    }}
                                >
                                    <RadioButton
                                        id="national"
                                        name="reportType"
                                        value="national"
                                        labelText="National"
                                        checked={reportType === "national"}
                                    />
                                    <RadioButton
                                        id="regional"
                                        name="reportType"
                                        value="regional"
                                        labelText="Regional"
                                        checked={reportType === "regional"}
                                    />
                                    <RadioButton
                                        id="nationalAndRegional"
                                        name="reportType"
                                        value="nationalAndRegional"
                                        labelText="National and Regional"
                                        checked={reportType === "nationalAndRegional"}
                                    />
                                    <RadioButton
                                        id="fieldManagers"
                                        name="reportType"
                                        value="fieldManagers"
                                        labelText="Field managers"
                                        checked={reportType === "fieldManagers"}
                                    />
                                    <RadioButton
                                        id="qualitative"
                                        name="reportType"
                                        value="qualitative"
                                        labelText="Qualitative"
                                        checked={reportType === "qualitative"}
                                    />
                                    <RadioButton
                                        id="allSections"
                                        name="reportType"
                                        value="allSections"
                                        labelText="All sections"
                                        checked={reportType === "allSections"}
                                    />
                                    <div className={"h-4 w-1 bg-slate-200 rounded-xl"} />
                                    <RadioButton
                                        id="custom"
                                        name="reportType"
                                        value="custom"
                                        labelText="Custom"
                                        checked={reportType === "custom"}
                                    />
                                </div>
                            </fieldset>
                        </div>
                        <span className={"font-semibold italic text-primary-600 text-lg"}>OR</span>
                        <div className="flex flex-col gap-3">
                            <label className={"text-sm font-medium w-full text-secondary-600"}>
                                Choose report sections and make your own
                            </label>
                            <div
                                className={
                                    "flex flex-row flex-wrap gap-2 text-sm font-title text-primary-800 items-center justify-start " +
                                    "pb-1 print:hidden"
                                }
                            >
                                <FancyCheckBox
                                    labelText={"National"}
                                    name={"National"}
                                    colour={"blue"}
                                    value={
                                        chosenSections.some((chosenProgram) => chosenProgram === "national") ||
                                        chosenReportTypeSections.some((chosenProgram) => chosenProgram === "national")
                                    }
                                    setValue={() => processChosenItems("national", chosenSections, setChosenSections)}
                                />
                                <FancyCheckBox
                                    labelText={"Regional"}
                                    name={"Regional"}
                                    colour={"blue"}
                                    value={
                                        chosenSections.some((chosenProgram) => chosenProgram === "regional") ||
                                        chosenReportTypeSections.some((chosenProgram) => chosenProgram === "regional")
                                    }
                                    setValue={() => processChosenItems("regional", chosenSections, setChosenSections)}
                                />
                                <FancyCheckBox
                                    labelText={"Field Managers"}
                                    name={"Field Managers"}
                                    colour={"blue"}
                                    value={
                                        chosenSections.some((chosenProgram) => chosenProgram === "fieldManagers") ||
                                        chosenReportTypeSections.some(
                                            (chosenProgram) => chosenProgram === "fieldManagers"
                                        )
                                    }
                                    setValue={() =>
                                        processChosenItems("fieldManagers", chosenSections, setChosenSections)
                                    }
                                />
                                <FancyCheckBox
                                    labelText={"Qualitative"}
                                    name={"Qualitative"}
                                    colour={"blue"}
                                    value={
                                        chosenSections.some((chosenProgram) => chosenProgram === "qualitative") ||
                                        chosenReportTypeSections.some(
                                            (chosenProgram) => chosenProgram === "qualitative"
                                        )
                                    }
                                    setValue={() =>
                                        processChosenItems("qualitative", chosenSections, setChosenSections)
                                    }
                                />
                                <div className={"h-8 w-1 bg-slate-200 rounded-xl mx-2"} />
                                <FancyCheckBox
                                    labelText={"Executive summary"}
                                    name={"Executive summary"}
                                    colour={"purple"}
                                    value={
                                        chosenSections.some((chosenProgram) => chosenProgram === "executiveSummary") ||
                                        chosenReportTypeSections.some(
                                            (chosenProgram) => chosenProgram === "executiveSummary"
                                        )
                                    }
                                    setValue={() =>
                                        processChosenItems("executiveSummary", chosenSections, setChosenSections)
                                    }
                                />
                                <FancyCheckBox
                                    labelText={"Demographics"}
                                    name={"Demographics"}
                                    colour={"purple"}
                                    value={
                                        chosenSections.some((chosenProgram) => chosenProgram === "demographics") ||
                                        chosenReportTypeSections.some(
                                            (chosenProgram) => chosenProgram === "demographics"
                                        )
                                    }
                                    setValue={() =>
                                        processChosenItems("demographics", chosenSections, setChosenSections)
                                    }
                                />
                                <FancyCheckBox
                                    labelText={"Report context"}
                                    name={"Report context"}
                                    colour={"purple"}
                                    value={
                                        chosenSections.some((chosenProgram) => chosenProgram === "reportContext") ||
                                        chosenReportTypeSections.some(
                                            (chosenProgram) => chosenProgram === "reportContext"
                                        )
                                    }
                                    setValue={() =>
                                        processChosenItems("reportContext", chosenSections, setChosenSections)
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <label className={"text-sm font-medium w-full text-secondary-600"}>States/regions</label>
                            <div
                                className={
                                    "flex flex-row flex-wrap gap-4 text-sm font-title text-primary-800 items-center justify-start " +
                                    "pb-1 print:hidden"
                                }
                            >
                                <div className="flex flex-row flex-wrap gap-2">
                                    <FancyCheckBox
                                        labelText={"VIC"}
                                        name={"vic"}
                                        colour={"indigo"}
                                        value={chosenStates.some((chosenState) => chosenState === "vic")}
                                        setValue={() => processChosenItems("vic", chosenStates, setChosenStates)}
                                    />
                                    <FancyCheckBox
                                        labelText={"NSW"}
                                        name={"nsw"}
                                        colour={"indigo"}
                                        value={chosenStates.some((chosenState) => chosenState === "nsw")}
                                        setValue={() => processChosenItems("nsw", chosenStates, setChosenStates)}
                                    />
                                    <FancyCheckBox
                                        labelText={"WA"}
                                        name={"wa"}
                                        colour={"indigo"}
                                        value={chosenStates.some((chosenState) => chosenState === "wa")}
                                        setValue={() => processChosenItems("wa", chosenStates, setChosenStates)}
                                    />
                                    <FancyCheckBox
                                        labelText={"SA"}
                                        name={"sa"}
                                        colour={"indigo"}
                                        value={chosenStates.some((chosenState) => chosenState === "sa")}
                                        setValue={() => processChosenItems("sa", chosenStates, setChosenStates)}
                                    />
                                    <FancyCheckBox
                                        labelText={"NT"}
                                        name={"nt"}
                                        colour={"indigo"}
                                        value={chosenStates.some((chosenState) => chosenState === "nt")}
                                        setValue={() => processChosenItems("nt", chosenStates, setChosenStates)}
                                    />
                                    <FancyCheckBox
                                        labelText={"TAS"}
                                        name={"tas"}
                                        colour={"indigo"}
                                        value={chosenStates.some((chosenState) => chosenState === "tas")}
                                        setValue={() => processChosenItems("tas", chosenStates, setChosenStates)}
                                    />
                                    <FancyCheckBox
                                        labelText={"ACT"}
                                        name={"act"}
                                        colour={"indigo"}
                                        value={chosenStates.some((chosenState) => chosenState === "act")}
                                        setValue={() => processChosenItems("act", chosenStates, setChosenStates)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <label className={"text-sm font-medium w-full text-secondary-600"}>Options</label>
                            <div
                                className={
                                    "flex flex-row flex-wrap gap-4 text-sm font-title text-primary-800 items-center justify-start " +
                                    "pb-1 print:hidden"
                                }
                            >
                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-row gap-2 items-center">
                                        <FancyCheckBox
                                            labelText={
                                                "Show extra info (point change, sector benchmark comparison and effect sizes)"
                                            }
                                            name={"extraInfo"}
                                            colour={"emerald"}
                                            value={chosenOptions.some((chosenOption) => chosenOption === "extraInfo")}
                                            setValue={() =>
                                                processChosenItems("extraInfo", chosenOptions, setChosenOptions)
                                            }
                                            simpleCheckBox
                                            minimal
                                        />
                                        <BasicMotion>
                                            <Tippy
                                                content={
                                                    <div className="flex flex-col gap-2 leading-relaxed">
                                                        <img
                                                            src={barInfoBubbleScreenshot}
                                                            alt="Extra info screenshot"
                                                            className="w-92"
                                                        />
                                                    </div>
                                                }
                                                className={
                                                    "py-1.5 px-2 shadow-lg rounded-xl bg-white border border-slate-100 overflow-hidden"
                                                }
                                                arrow={false}
                                                animation={"shift-away-subtle"}
                                                placement={isMobile ? "bottom-start" : "right"}
                                                interactive
                                                trigger={isMobile ? "click" : "mouseenter focus"}
                                                hideOnClick={true}
                                            >
                                                <InformationCircleIcon
                                                    className={"shrink-0 block h-4.5 w-4.5 text-blue-600"}
                                                />
                                            </Tippy>
                                        </BasicMotion>
                                    </div>
                                    <div className="flex flex-row gap-2 items-center">
                                        <FancyCheckBox
                                            labelText={"Show sector benchmarks"}
                                            name={"sectorBenchmarks"}
                                            colour={"emerald"}
                                            value={chosenOptions.some(
                                                (chosenOption) => chosenOption === "sectorBenchmarks"
                                            )}
                                            setValue={() =>
                                                processChosenItems("sectorBenchmarks", chosenOptions, setChosenOptions)
                                            }
                                            simpleCheckBox
                                            minimal
                                        />
                                        <BasicMotion>
                                            <Tippy
                                                content={
                                                    <div className="flex flex-col gap-2 leading-relaxed">
                                                        <img
                                                            src={sectorBenchmarkScreenshot}
                                                            alt="Sector benchmark screenshot"
                                                            // className="w-[500px]"
                                                        />
                                                    </div>
                                                }
                                                className={
                                                    "py-1.5 px-2 shadow-lg rounded-xl bg-white border border-slate-100 overflow-hidden"
                                                }
                                                arrow={false}
                                                animation={"shift-away-subtle"}
                                                placement={isMobile ? "bottom-start" : "right"}
                                                interactive
                                                trigger={isMobile ? "click" : "mouseenter focus"}
                                                hideOnClick={true}
                                            >
                                                <InformationCircleIcon
                                                    className={"shrink-0 block h-4.5 w-4.5 text-blue-600"}
                                                />
                                            </Tippy>
                                        </BasicMotion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 " +
                            "text-right rounded-b-xl w-full"
                        }
                    >
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Download report as PDF"
                            icon={<ArrowDownTrayIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                            iconPositionLeft
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
