export default function Tag(props) {
    const { status, textColour, backgroundColour, isResource } = props;

    const resourceTypeStyles = {
        WORKSHOP: { text: "text-white", background: "bg-purple-500" },
        READING: { text: "text-white", background: "bg-pink-500" },
        COURSE: { text: "text-white", background: "bg-amber-500" },
        APP: { text: "text-white", background: "bg-purple-600" },
        ARTICLE: { text: "text-white", background: "bg-violet-500" },
        BLOG: { text: "text-white", background: "bg-indigo-500" },
        BLOGPOST: { text: "text-white", background: "bg-indigo-700" },
        BOOK: { text: "text-white", background: "bg-rose-500" },
        "FACT SHEET": { text: "text-white", background: "bg-orange-500" },
        FORUM: { text: "text-white", background: "bg-teal-500" },
        HELPLINE: { text: "text-white", background: "bg-rose-600" },
        "INFORMATION SHEET": { text: "text-white", background: "bg-orange-400" },
        PAMPHLET: { text: "text-white", background: "bg-blue-500" },
        PODCAST: { text: "text-white", background: "bg-sky-500" },
        PROGRAM: { text: "text-white", background: "bg-teal-600" },
        SERVICES: { text: "text-white", background: "bg-amber-400" },
        "SUPPORT SERVICE": { text: "text-white", background: "bg-amber-500" },
        VIDEO: { text: "text-white", background: "bg-fuchsia-700" },
        WORKBOOK: { text: "text-white", background: "bg-emerald-600" },
        WORKSHEET: { text: "text-white", background: "bg-emerald-500" },
        WEBPAGE: { text: "text-white", background: "bg-green-500" },
    };

    const resourceTypeStyle = resourceTypeStyles[status] || { text: "text-primary-600", background: "bg-primary-100" };

    return (
        <>
            {(() => {
                if (status === "Not Started" || status === "Expired") {
                    return <div className={"tag text-red-600 bg-red-100"}>{status}</div>;
                } else if (status === "Enrolled") {
                    return <div className={"tag text-blue-600 bg-blue-100"}>Enrolled</div>;
                } else if (status === "Completed" || status === "Released") {
                    return <div className={"tag text-green-600 bg-green-100"}>Completed</div>;
                } else if (status === "Started") {
                    return <div className={"tag text-blue-600 bg-blue-100"}>Started</div>;
                } else if (status === "Organisation") {
                    return <div className={"tag text-slate-600 bg-slate-200"}>Organisation</div>;
                } else if (status === "Organisation member") {
                    return <div className={"tag text-secondary-600 bg-secondary-100"}>Organisation member</div>;
                } else if (isResource && resourceTypeStyle) {
                    return (
                        <div className={`tag ${resourceTypeStyle.text} ${resourceTypeStyle.background}`}>{status}</div>
                    );
                } else if (status === "Course") {
                    return <div className={"tag text-amber-600 bg-amber-200"}>Course</div>;
                } else if (status === "Reading") {
                    return <div className={"tag text-pink-600 bg-pink-200"}>Reading</div>;
                } else if (status === "Workshop") {
                    return <div className={"tag text-purple-600 bg-purple-200"}>Workshop</div>;
                } else if (status === "Demo") {
                    return <div className={"tag text-secondary-600 bg-secondary-100"}>Demo</div>;
                } else if (status === "Low") {
                    return <div className={"tag text-red-600 bg-red-200"}>Low</div>;
                } else if (status === "Average") {
                    return <div className={"tag text-orange-600 bg-orange-200"}>Average</div>;
                } else if (status === "High") {
                    return <div className={"tag text-green-600 bg-green-100"}>High</div>;
                } else if (status === "Optional") {
                    return (
                        <div
                            className={
                                "text-[10px] p-1 px-1.5 rounded-full w-max font-medium text-slate-500 bg-slate-200"
                            }
                        >
                            Optional
                        </div>
                    );
                } else if (!status) {
                    return <div className={"text-xs text-slate-400 italic "}>Unavailable</div>;
                } else {
                    return (
                        <div
                            className={`tag ${textColour ? textColour : "text-primary-600"} ${
                                backgroundColour ? backgroundColour : "bg-primary-100"
                            }`}
                        >
                            {status}
                        </div>
                    );
                }
            })()}
        </>
    );
}
