import DetailCard from "../JobReadiness/DetailCard";

export default function ApprenticeshipRisk(props) {
    const { score, testId } = props;

    return (
        <div className="flex flex-col gap-4" data-testid={testId && testId}>
            <div className="text-primary-600 font-medium">Apprenticeship Risk</div>
            <div
                className={
                    "p-4 print:p-0 rounded-xl bg-white shadow-sm border " +
                    "border-slate-100 col-span-2 flex flex-col gap-8"
                }
            >
                <div className="col-span-2 flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-amber-600 font-medium"}>
                            Personal Details
                        </div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                <DetailCard label={"Name"} content={"Psych Press"} textColour={"text-amber-600"} />
                                <DetailCard label={"Date of birth"} content={"1990"} textColour={"text-amber-600"} />
                                <DetailCard label={"Employer"} content={"Psych Press"} textColour={"text-amber-600"} />
                                <DetailCard
                                    label={"Do you identify with any of the following groups?"}
                                    content={"None"}
                                    textColour={"text-amber-600"}
                                />
                                <DetailCard
                                    label={"Is English your native language?"}
                                    content={"Yes"}
                                    textColour={"text-amber-600"}
                                />
                                <DetailCard
                                    label={"What is your native language?"}
                                    content={"English"}
                                    textColour={"text-amber-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-lime-600 font-medium"}>
                            Language, Literacy, Numeracy
                        </div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                <DetailCard
                                    label={"How do you rate your ability to: Read English?"}
                                    content={score.englishReadingAbility}
                                    textColour={"text-lime-600"}
                                />
                                <DetailCard
                                    label={"How do you rate your ability to: Write English?"}
                                    content={score.englishWritingAbility}
                                    textColour={"text-lime-600"}
                                />
                                <DetailCard
                                    label={"How do you rate your ability to: Speak English?"}
                                    content={score.englishSpeakingAbility}
                                    textColour={"text-lime-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-emerald-600 font-medium"}>
                            Support Networks and Personal Circumstances
                        </div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={
                                        "Do you feel you have a supportive network of family, friends and community around you?"
                                    }
                                    content={score.HaveStableSupport}
                                    textColour={"text-emerald-600"}
                                />
                                <DetailCard
                                    label={
                                        "Do we need to consider any of the following as we support you to retain your apprenticeship?"
                                    }
                                    content={score.NeedAnySupportt}
                                    textColour={"text-emerald-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-teal-600 font-medium"}>Education</div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={
                                        "Have you started the training and assessment component of your apprenticeship/traineeship?"
                                    }
                                    content={score.HaveStartedTraining}
                                    textColour={"text-teal-600"}
                                />
                                <DetailCard
                                    label={
                                        "Do you feel you are coping with the study component of your apprenticeship/traineeship?"
                                    }
                                    content={score.CopingWithStudy}
                                    textColour={"text-teal-600"}
                                />
                                <DetailCard
                                    label={
                                        "What is the highest qualifications you completed before taking on this apprenticeship/traineeship?"
                                    }
                                    content={score.HighestQualification}
                                    textColour={"text-teal-600"}
                                />
                                <DetailCard
                                    label={"Please tell us the name of qualifications completed"}
                                    content={score.QualificationName}
                                    textColour={"text-teal-600"}
                                />
                                <DetailCard
                                    label={"Are you currently still at school?"}
                                    content={score.StillAtSchool}
                                    textColour={"text-teal-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-cyan-600 font-medium"}>Employment</div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={
                                        "What has been your main activity over the last two years prior to your apprenticeship/traineeship? Please choose what best describes you:"
                                    }
                                    content={score.MainActivityLastTwoYears}
                                    textColour={"text-cyan-600"}
                                />
                                <DetailCard
                                    label={"What is the longest period of employment you have had with any employer?"}
                                    content={score.LongestLengthEmployment}
                                    textColour={"text-cyan-600"}
                                />
                                <DetailCard
                                    label={"Following the above question, on what basis were you employed?"}
                                    content={score.WhatBasisEmployed}
                                    textColour={"text-cyan-600"}
                                />
                                <DetailCard
                                    label={"Are you happy with the hours you are getting at the moment?"}
                                    content={score.HappyWithHours}
                                    textColour={"text-cyan-600"}
                                />
                                <DetailCard
                                    label={"Are you currently actively looking for alternative employment?"}
                                    content={score.AlternativeEmployment}
                                    textColour={"text-cyan-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-sky-600 font-medium"}>Housing</div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={"Are you currently in secure, stable and safe accommodation?"}
                                    content={score.StableSafeAccommodation}
                                    textColour={"text-sky-600"}
                                />
                                <DetailCard
                                    label={"Are you at risk of becoming homeless in the near future?"}
                                    content={score.RiskOfHomeless}
                                    textColour={"text-sky-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-blue-600 font-medium"}>Finances</div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={"Are you coping with the cost of transport getting to and from work?"}
                                    content={score.CostOfTransport}
                                    textColour={"text-blue-600"}
                                />
                                <DetailCard
                                    label={
                                        "Do you have all the required work clothes and equipment you need to do your job properly?"
                                    }
                                    content={score.RequriedWorkCloths}
                                    textColour={"text-blue-600"}
                                />
                                <DetailCard
                                    label={"Do you feel that you are managing your expenses with your current pay?"}
                                    content={score.ManagingWithPay}
                                    textColour={"text-blue-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-indigo-600 font-medium"}>Health</div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={
                                        "Do you have any current physical or mental health issues that may impact your ability to find or sustain employment?"
                                    }
                                    content={score.HealthIssues}
                                    textColour={"text-indigo-600"}
                                />
                                <DetailCard
                                    label={"If yes, please advise what these are:"}
                                    content={score.WhatHealthIssues}
                                    textColour={"text-indigo-600"}
                                />
                                <DetailCard
                                    label={
                                        "Has the use of drugs or alcohol ever stopped you from sustaining employment?"
                                    }
                                    content={score.DrugsOrAlcohol}
                                    textColour={"text-indigo-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-violet-600 font-medium"}>Health</div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={"Do you need a Car or Drivers Licence for work?"}
                                    content={score.NeedDriversLicence}
                                    textColour={"text-violet-600"}
                                />
                                <DetailCard label={"Do you own a registered, reliable car?"} content={score.DoOwnCar} />
                                <DetailCard
                                    label={
                                        "Do you have a current car open, provisional or learners motor vehicle licence?"
                                    }
                                    content={score.CurrentLicense}
                                    textColour={"text-violet-600"}
                                />
                                <DetailCard
                                    label={"Please provide a brief description of transport issues"}
                                    content={score.IssuesGettingToWorkDescription}
                                    textColour={"text-violet-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-purple-600 font-medium"}>Health</div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={"Do you need a Car or Drivers Licence for work?"}
                                    content={score.NeedDriversLicence}
                                    textColour={"text-purple-600"}
                                />
                                <DetailCard
                                    label={"Do you own a registered, reliable car?"}
                                    content={score.DoOwnCar}
                                    textColour={"text-purple-600"}
                                />
                                <DetailCard
                                    label={
                                        "Do you have a current car open, provisional or learners motor vehicle licence?"
                                    }
                                    content={score.CurrentLicense}
                                    textColour={"text-purple-600"}
                                />
                                <DetailCard
                                    label={"Please provide a brief description of transport issues"}
                                    content={score.IssuesGettingToWorkDescription}
                                    textColour={"text-purple-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-fuchsia-600 font-medium"}>
                            Legal Issues
                        </div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={
                                        "Do you have any legal issues that may impact your ability to stay employed? i.e. Current or future court orders, previous convictions, loss of driver's licence, or unpaid fines?"
                                    }
                                    content={score.AnyLegaIssues}
                                    textColour={"text-fuchsia-600"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className={"flex flex-row items-center gap-2 text-pink-600 font-medium"}>
                            Employment Satisfaction
                        </div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                <DetailCard
                                    label={"How long have you been in your current position?"}
                                    content={score.AnyLegaIssues}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={"How important is this apprenticeship/traineeship to you?"}
                                    content={score.HowImportant}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={
                                        "Do you feel positive that this apprenticeship/traineeship will benefit your future?"
                                    }
                                    content={score.WillBenefit}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={"How satisfied are you with your apprenticeship/traineeship?"}
                                    content={score.HowSatisfied}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={"I feel confident that I understand the details of my role."}
                                    content={score.UnderstandRole}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={"I am developing positive relationships with others at work."}
                                    content={score.RelationshipsAtWork}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={"I know who to go to at work if I have a question or issue."}
                                    content={score.WhoToGoToForHelp}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={"My work environment feels safe and comfortable."}
                                    content={score.WorkEnvironmentComfort}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={
                                        "I feel good about the hours I work and my ability to maintain life away from work, such as hobbies, interests or family."
                                    }
                                    content={score.WorkLifeBalance}
                                    textColour={"text-pink-600"}
                                />
                                <DetailCard
                                    label={
                                        "Is there anything else you feel would be good for us to know about you, to support you in your apprenticeship or traineeship?"
                                    }
                                    content={score.FinalComments}
                                    textColour={"text-rose-600"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
