import {
    UserIcon,
    EnvelopeIcon,
    DocumentTextIcon,
    HashtagIcon,
    CalendarIcon,
    DocumentDuplicateIcon,
    ArrowDownTrayIcon,
    DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { useState, useContext } from "react";
import mime from "mime";
import { useNavigate } from "react-router-dom";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../../utils/constants";
import { downloadLatestReportForAssessment } from "../../../../../utils/api";
import { Context } from "../../../../../context/Context";

import Alert from "../../../../SharedComponents/Alert";
import Button from "../../../../SharedComponents/Button";
import Input from "../../../../SharedComponents/Form/Input";
import MultiSelect from "../../../../SharedComponents/Form/MultiSelect";

export default function ReportDetails(props) {
    const { report, setReportName, reportName, reportTags, setReportTags, alert, setAlert, alertType } = props;
    const navigate = useNavigate();
    const context = useContext(Context);
    const [downloading, setDownloading] = useState(false);

    let latestReport = "";
    latestReport = report?.documentVersions?.reduce((prev, current) =>
        prev.versionNumber > current.versionNumber ? prev : current
    );

    const handleDownload = async (documentIdentifier) => {
        setDownloading(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DOWNLOAD_REPORTS)) {
            setAlert("You do not have the necessary permission to download a report");
            setDownloading(false);
            return;
        }

        const documentResponse = await downloadLatestReportForAssessment(documentIdentifier);

        const { fileType } = documentResponse;

        if (!fileType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        let blob = await (await fetch(`data:${mimeType};base64,${documentResponse.content}`)).blob();

        if (!blob) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const downloadLink = document.createElement("a");

        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = documentResponse.fileName.split(".").join("_");
        downloadLink.click();

        setDownloading(false);
    };

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-4">
                <Input
                    id="coacheeName"
                    type="text"
                    label={(context.terminology.coachee ? context.terminology.coachee.singular : "Coachee") + " name"}
                    placeholder={
                        (context.terminology.coachee ? context.terminology.coachee.singular : "Coachee") + " name"
                    }
                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                    value={report.userName}
                    disabled
                />

                <Input
                    id="coacheeEmail"
                    type="email"
                    label="Coachee email"
                    placeholder={"Coachee email"}
                    icon={<EnvelopeIcon className="h-4 w-4 text-slate-500" />}
                    value={report.userEmail}
                    disabled
                />

                <Input
                    id="reportFileFormat"
                    type="text"
                    label="File format"
                    placeholder={"File Format"}
                    icon={<DocumentDuplicateIcon className="h-4 w-4 text-slate-500" />}
                    value={latestReport?.fileFormat?.toUpperCase()}
                    extraStyling={"place-content-between"}
                    disabled
                />

                <div className="flex flex-row gap-3">
                    <Input
                        id="reportDate"
                        type="text"
                        label="Latest report date"
                        placeholder={"Latest report date"}
                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                        value={new Date(latestReport.reportDate).toLocaleDateString()}
                        extraStyling={"place-content-between"}
                        disabled
                    />

                    <Input
                        id="version"
                        type="number"
                        label="Latest version"
                        placeholder={"Latest version"}
                        icon={<HashtagIcon className="h-4 w-4 text-slate-500" />}
                        value={latestReport.versionNumber}
                        extraStyling={"place-content-between"}
                        disabled
                    />
                </div>

                <Input
                    id="reportName"
                    type="text"
                    label="Report name"
                    placeholder={"Report name"}
                    icon={<DocumentTextIcon className="h-4 w-4 text-slate-500" />}
                    value={reportName}
                    extraStyling={"lg:col-span-2"}
                    onChange={setReportName}
                    backgroundColour={"bg-white"}
                />

                <div className={"flex flex-col gap-3 lg:col-span-2"}>
                    <MultiSelect
                        isMulti
                        items={reportTags}
                        labelText={"Report tags"}
                        setChosenItems={setReportTags}
                        chosenItems={reportTags}
                        isCreatable
                        backgroundColour={"#ffffff"}
                    />
                </div>

                <div className="flex flex-col gap-2 w-full">
                    <div className={"flex w-full"}>
                        <label htmlFor="Download" className="text-sm font-medium text-primary-600 w-full ">
                            Download latest report
                        </label>
                    </div>
                    {latestReport?.fileFormat === "JSON" || latestReport?.fileFormat === "json" ? (
                        <Button
                            text={"View"}
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            icon={<DocumentMagnifyingGlassIcon className="block h-3.5 w-3.5 mr-1" />}
                            extraStyling={"text-xs text-white"}
                            iconPositionLeft
                            smallButton
                            onClick={() =>
                                navigate("/view-report", {
                                    state: {
                                        userName: report.userName,
                                        reportName: report.name,
                                        reportIdentifier: report.identifier,
                                    },
                                })
                            }
                            loading={downloading}
                            loadingIconSize={"h-3.5 w-3.5 mr-1"}
                        />
                    ) : (
                        <Button
                            text={"Download"}
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-1" />}
                            extraStyling={"text-xs text-white"}
                            iconPositionLeft
                            smallButton
                            onClick={() => handleDownload(report.identifier)}
                            loading={downloading}
                            loadingIconSize={"h-3.5 w-3.5 mr-1"}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
