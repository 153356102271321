import CountUp from "react-countup";

import TrendBubble from "../../../SharedChartComponents/TrendBubble";

export default function SimpleMiniDataCard(props) {
    const { label, percentage, trendPercentage, totalNumber, currentNumber } = props;

    return (
        label && (
            <div className="flex flex-col p-3 gap-4 bg-white print:bg-slate-50 rounded-xl border border-1 border-slate-100 shadow-sm">
                <div className="flex gap-4 justify-between items-center">
                    <h2
                        className={
                            "text-lg font-title tracking-tight font-medium " +
                            (label === "Overall" || label === "Overall Pedders 2022"
                                ? "text-secondary-600 "
                                : "text-slate-400")
                        }
                    >
                        {label}
                    </h2>
                    {trendPercentage && <TrendBubble percentage={trendPercentage} text="vs 2022" />}
                </div>
                <div className="flex flex-row justify-between items-end gap-2 font-semibold">
                    <span className={"text-2xl font-semibold text-primary-600"}>
                        <CountUp end={percentage} duration={4} />%
                    </span>
                    {totalNumber !== null && (
                        <span className={"text-lg font-semibold text-slate-500"}>
                            <CountUp end={currentNumber} duration={4} />/<CountUp end={totalNumber} duration={4} />
                        </span>
                    )}
                </div>
            </div>
        )
    );
}
