import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { UserPlusIcon } from "@heroicons/react/24/solid";

import { getCoachesTrainingModuleStatuses } from "../../../../../utils/api";

import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../../utils/constants";
import { Context } from "../../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import CoachTrainingRow from "./CoachTrainingRow";

export default function CoachTraining() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coaches, setCoaches] = useState([]);
    const [latestCoachInteracted, setLatestCoachInteracted] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const context = useContext(Context);

    const fetchCoachesAndTrainingStatuses = useCallback(async (lastCoachInteractedEmail) => {
        setLatestCoachInteracted(lastCoachInteractedEmail);
        setLoading(true);
        try {
            const response = await getCoachesTrainingModuleStatuses();
            setCoaches(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchCoachesAndTrainingStatuses();
    }, [fetchCoachesAndTrainingStatuses]);

    return (
        <DashboardLayout
            title={context.terminology.coach.singular + " training"}
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.VIEW_ALL_TRAINING_MODULES,
                COACH_PLATFORM_PERMISSIONS.CREATE_TRAINING_MODULES,
                COACH_PLATFORM_PERMISSIONS.EDIT_STATUS_TRAINING_MODULE,
                COACH_PLATFORM_PERMISSIONS.VIEW_STATUS_TRAINING_MODULE,
            ]}
            actionButton
            actionButtonRequiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
            actionButtonText={"Setup " + (context.terminology.coach ? context.terminology.coach.singular : "Coach")}
            actionButtonIcon={<UserPlusIcon className="block h-3.5 w-3.5 mr-2" />}
            actionButtonOnClick={() => navigate(`/setup-user/${context.terminology.coach.singular.toLowerCase()}`)}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                loading={loading}
                items={coaches}
                itemName={context.terminology.coach ? context.terminology.coach.plural : "Coaches"}
                disableSearch={!coaches || coaches.length === 1}
                searchAttributes={["firstName", "lastName", "emailAddress"]}
                columnNames={[
                    context.terminology.coach.singular + " Details",
                    "Location",
                    "Overall progress",
                    "Training",
                ]}
                ItemRow={CoachTrainingRow}
                fetchItems={fetchCoachesAndTrainingStatuses}
                setAlert={setAlert}
                setAlertType={setAlertType}
                latestItemInteracted={latestCoachInteracted}
            />
        </DashboardLayout>
    );
}
