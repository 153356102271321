import { Disclosure, Transition } from "@headlessui/react";
import { UserGroupIcon, ChevronRightIcon, UsersIcon } from "@heroicons/react/24/solid";

export default function TreeLink(props) {
    const { children, name, parent, members, setGroupMembers, currentGroupChoice, setCurrentGroupChoice } = props;

    return (
        <div className={"cursor-pointer"} onClick={() => setCurrentGroupChoice(name)}>
            <Disclosure onClick={() => setGroupMembers(members)}>
                {({ open }) => (
                    <div className={"p-1 rounded-xl "}>
                        <Disclosure.Button
                            value={name}
                            className={
                                "flex items-center justify-between gap-2 p-2 rounded-lg w-full font-medium " +
                                (name === currentGroupChoice ? "text-primary-600 bg-primary-50 " : "text-slate-500 ") +
                                (parent ? "bg-slate-50 hover:bg-slate-100" : "bg-warm-gray-50 hover:bg-warm-gray-100")
                            }
                        >
                            <div className={"flex flex-row gap-2 items-center"}>
                                {parent ? (
                                    <UserGroupIcon className="block h-4 w-4" />
                                ) : (
                                    <UsersIcon className="block h-4 w-4" />
                                )}
                                {name} {members.length > 0 && "(" + members.length + ")"}
                            </div>

                            {children.props.groups.length > 0 && (
                                <ChevronRightIcon
                                    className={
                                        "block h-5 w-5 transition transition-transform " +
                                        `${open ? "transform rotate-90" : ""}`
                                    }
                                />
                            )}
                        </Disclosure.Button>
                        <Transition
                            show={children.props.groups.length === 0 ? false : open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className={"flex flex-col gap-2 mt-2 ml-4"} static>
                                {children}
                            </Disclosure.Panel>
                        </Transition>
                    </div>
                )}
            </Disclosure>
        </div>
    );
}
