import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, LIKERT_SCALE_OPTIONS } from "../../../../utils/constants";

import Modal from "../../../SharedComponents/Modal";
import Button from "../../../SharedComponents/Button";
import Tag from "../../../SharedComponents/Tag";
import Input from "../../../SharedComponents/Form/Input";
import LikertScale from "./LikertScale";

export default function SampleQuestions(props) {
    const { showSampleQeustions, setShowSampleQuestions } = props;

    return (
        <Modal
            active={showSampleQeustions}
            closeModal={() => setShowSampleQuestions(!showSampleQeustions)}
            content={
                <div className="flex flex-col gap-4 max-w-[640px]">
                    <div className="flex flex-row items-center justify-end">
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            icon={<XMarkIcon className="h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                            text="Close"
                            iconPositionLeft
                            onClick={() => setShowSampleQuestions(!showSampleQeustions)}
                            smallButton
                        />
                    </div>

                    <div className={"flex flex-col gap-2 justify-center overflow-hidden rounded-xl"}>
                        <div className={"flex justify-between flex-col bg-slate-50 rounded-xl p-3"}>
                            <div className={"grid grid-flow-row xl:grid-cols-2 gap-5 p-3"}>
                                <div className="flex flex-row justify-between items-center w-full col-span-2">
                                    <div className="font-medium text-primary-600 tracking-tight font-title text-lg">
                                        Sample questions
                                    </div>

                                    <Tag status="Sample" />
                                </div>

                                <div className="col-span-2">
                                    <div className="font-medium text-secondary-900 tracking-tight font-title">
                                        Employee Satisfaction
                                    </div>

                                    <div className="my-1 text-sm text-slate-500">
                                        Employee satisfaction is the extent to which employees are happy or content with
                                        their jobs and work environment.
                                    </div>
                                </div>

                                <div className={"flex flex-col gap-3 col-span-2"}>
                                    <Input
                                        id="questionTitle"
                                        type="text"
                                        label="1. What are your biggest challenges in your current role? "
                                        topSubLabel="This question measures employee satisfaction and engagement by asking employees to identify their biggest challenges."
                                        placeholder={"Question title"}
                                        onChange={[]}
                                        backgroundColour={"bg-white"}
                                        value={
                                            "A lack of resources, unable to complete my tasks to the best of my ability."
                                        }
                                    />

                                    <div className="flex flex-col gap-2 w-full">
                                        <div className={"flex justify-between w-full mb-2"}>
                                            <label className="text-sm font-medium text-primary-600 w-full ">
                                                2. I am satisfied with my job.
                                            </label>
                                        </div>

                                        <div className="bg-slate-200 p-2 rounded-xl flex flex-row items-center">
                                            <LikertScale scaleOptions={LIKERT_SCALE_OPTIONS[0]?.scaleItems} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    );
}
