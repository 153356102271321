function SectionPlaceholder() {
    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"flex flex-col gap-4"}>
                <div className={"animate-pulse px-4 py-3 bg-slate-200 rounded-xl h-4 w-2/4"}/>
                <div className={"animate-pulse px-4 py-3 bg-slate-200 rounded-xl w-full h-9"}/>
                <div className={"animate-pulse px-4 py-3 bg-slate-200 rounded-xl w-full h-16"}/>
                <div className={"animate-pulse px-4 py-3 bg-slate-200 rounded-xl w-full h-16"}/>
            </div>
        </div>
    );
}

export default SectionPlaceholder;
