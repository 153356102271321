import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import Button from "../../../SharedComponents/Button";

import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import { ArrowLongRightIcon, ArrowLongLeftIcon } from "@heroicons/react/24/solid";

import NavigationDot from "./NavigationDot";
import Slide from "./Slide";

export default function Slider(props) {
    const { slides, fullScreen } = props;
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    const goToPage = (page) => {
        setPage([page + 1]);
    };

    return (
        <>
            <div className={"mt-8 lg:mt-0 overflow-hidden w-full " + fullScreen ? "" : "h-92 "}>
                <AnimatePresence initial={false} custom={direction}>
                    {slides.map((slide, index) => (
                        <Slide key={index} page={index} currentPage={page}>
                            {slide}
                        </Slide>
                    ))}
                </AnimatePresence>
            </div>
            <div className={"items-center justify-between w-full " + (slides.length === 1 ? "hidden" : "flex")}>
                <Button
                    colour={BUTTON_VARIANT_TYPES.UNCOLOURED_BUTTON}
                    icon={<ArrowLongLeftIcon className="block h-5 w-5" />}
                    iconPositionLeft
                    onClick={() => paginate(-1)}
                    extraStyling={
                        "text-xs h-8 w-8 bg-slate-200 text-slate-500 rounded-full p-2 hover:bg-slate-300 " +
                        "hover:text-slate-600"
                    }
                    disabled={page <= 0}
                />
                <div className={"flex flex-row items-center justify-center gap-1"}>
                    {slides.map((slide, index) => (
                        <NavigationDot
                            key={index}
                            goToPage={() => goToPage(index - 1)}
                            currentPage={page}
                            page={index}
                        />
                    ))}
                </div>
                <Button
                    colour={BUTTON_VARIANT_TYPES.UNCOLOURED_BUTTON}
                    icon={<ArrowLongRightIcon className="block h-5 w-5" />}
                    onClick={() => paginate(1)}
                    extraStyling={
                        "text-xs h-8 w-8 bg-slate-200 text-slate-500 rounded-full p-2" +
                        " hover:bg-slate-300 hover:text-slate-600"
                    }
                    disabled={page >= slides.length - 1}
                />
            </div>
        </>
    );
}
