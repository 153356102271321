import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { getRUThereUserCompletedInterviewRecordings } from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import PracticeInterviewFeedbackRow from "./PracticeInterviewFeedbackRow";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import Button from "../../../SharedComponents/Button";

export default function PracticeInterviewFeedback() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [userInterviewRecordings, setUserInterviewRecordings] = useState([]);
    const [userInterviewRecordingsLoading, setUserInterviewRecordingsLoading] = useState(false);
    const [interview, setInterview] = useState([]);

    const location = useLocation();

    const navigate = useNavigate();

    const fetchUserInterviewRecordings = useCallback(async (interview) => {
        setUserInterviewRecordingsLoading(true);
        try {
            const recordings = await getRUThereUserCompletedInterviewRecordings(
                interview.interviewId,
                interview.campaignId
            );

            recordings.forEach((recording) => {
                recording.interviewInfo = interview;
            });

            setUserInterviewRecordings(recordings);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setUserInterviewRecordingsLoading(false);
    }, []);

    useEffect(() => {
        if (location.state.interview) {
            fetchUserInterviewRecordings(location.state.interview);
            setInterview(location.state.interview);
        }
    }, [fetchUserInterviewRecordings, location]);

    return (
        <DashboardLayout
            title={"Practice interview feedback: " + interview.campaignName}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_COMPLETED_PRACTICE_INTERVIEWS]}
        >
            <div className="flex flex-col gap-4">
                <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                <PaginatedSearch
                    items={userInterviewRecordings}
                    itemName={"recordings"}
                    searchAttributes={["campaignName", "question"]}
                    columnNames={["Question", "View feedback"]}
                    ItemRow={PracticeInterviewFeedbackRow}
                    customItemsPerPage={4}
                    overflowVisible
                    customPlaceholder="Search questions e.g. Question content"
                    loading={userInterviewRecordingsLoading}
                />
                <div className="flex justify-end">
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GRAY_DARKER}
                        text={"Back"}
                        iconPositionLeft
                        icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                        onClick={() => navigate("/practice-interviews")}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}
