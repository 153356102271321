function CircleLoadingPlaceholder(props) {
    const { children } = props;
    
    return (
        <div className={"animate-pulse bg-slate-200 w-40 h-40 xl:w-[12vw] xl:h-[12vw] rounded-full relative"}>
            {children && children}
        </div>
    );
}

export default CircleLoadingPlaceholder;
