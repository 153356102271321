import vgcccLogo from "../../assets/vgccc-logo.png";

export const sampleCapabilityMapVGCCCScore = {
    organisationLogo: vgcccLogo,
    reportName: "Capability Map",
    snapshot: [
        "The Human Capabilities are the markers of a person’s enduring attributes we know, from 30 years of research, make each of us unique. Results are intended to provide deeper understanding and insights to a allow you to harness your teams innate strengths and potential.",
        "In the context of work, profile results should be considered in combination with all other available and relevant information. ",
    ],
    understandYourTeam: {
        title: "Understand your Team",
        description: [
            "Your team is made up of unique individuals, each with different combinations of Human capabilities. Understand how to harness their potential",
        ],
    },
    reportLevelNames: ["Foundational", "Applied", "Accomplished", "Leading"],
    groups: [
        {
            name: "Overall",
            capabilityScores: [
                {
                    name: "Work Together",
                    selfAssessmentScore: 98,
                    mindsetScore: 86,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 61,
                    mindsetScore: 58,
                },
                {
                    name: "Accountability & Outcomes",
                    selfAssessmentScore: 49,
                    mindsetScore: 43,
                },
                {
                    name: "Stakeholder Relationships & Influence",
                    selfAssessmentScore: 54,
                    mindsetScore: 34,
                },
                {
                    name: "Digital & Data",
                    selfAssessmentScore: 45,
                    mindsetScore: 51,
                },
                {
                    name: "Decision Making & Judgment",
                    selfAssessmentScore: 54,
                    mindsetScore: 52,
                },
                {
                    name: "Social Responsibility & Harm Minimisation",
                    selfAssessmentScore: 58,
                    mindsetScore: 50,
                },

                {
                    name: "Systems Thinking",
                    selfAssessmentScore: 67,
                    mindsetScore: 68,
                },
                {
                    name: "Performance & Coaching",
                    selfAssessmentScore: 45,
                    mindsetScore: 67,
                },
                {
                    name: "Innovate & Improve",
                    selfAssessmentScore: 78,
                    mindsetScore: 67,
                },
                {
                    name: "Make It Happen",
                    selfAssessmentScore: 50,
                    mindsetScore: 45,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 53,
                    mindsetScore: 50,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 67,
                            mindsetScore: 65,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 54,
                            mindsetScore: 43,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 86,
                            mindsetScore: 65,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Digital & Data",
                    highlightType: "Most Prevalent",
                    percentScore: "67%",
                    description: "67% of the team are naturally strong in this area",
                },
                {
                    name: "Systems Thinking",
                    highlightType: "Potential to Unlock",
                    percentScore: "43%",
                    description: "43% of the team have untapped potential in this area",
                },
                {
                    name: "Make It Happen",
                    highlightType: "Blindspots",
                    percentScore: "7%",
                    description: "7% of the team may find this area challenging",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Adaptive Mindset",
                            percentScore: 59,
                        },
                        { name: "Systems Thinking", percentScore: 49 },
                        {
                            name: "Digital & Data",
                            percentScore: 44,
                        },
                        {
                            name: "Work Together",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Performance & Coaching", percentScore: 11, category: "Blindspots" },
                        { name: "Accountability & Outcomes", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        {
                            name: "Work Together",
                            percentScore: 65,
                            category: "Blindspots",
                        },
                    ],
                },
            ],
        },
        {
            name: "People and Culture",
            capabilityScores: [
                {
                    name: "Work Together",
                    selfAssessmentScore: 52,
                    mindsetScore: 48,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 61,
                    mindsetScore: 58,
                },
                {
                    name: "Accountability & Outcomes",
                    selfAssessmentScore: 49,
                    mindsetScore: 43,
                },
                {
                    name: "Stakeholder Relationships & Influence",
                    selfAssessmentScore: 36,
                    mindsetScore: 40,
                },
                {
                    name: "Decision Making & Judgment",
                    selfAssessmentScore: 54,
                    mindsetScore: 52,
                },
                {
                    name: "Digital & Data",
                    selfAssessmentScore: 45,
                    mindsetScore: 51,
                },
                {
                    name: "Systems Thinking",
                    selfAssessmentScore: 67,
                    mindsetScore: 68,
                },
                {
                    name: "Social Responsibility & Harm Minimisation",
                    selfAssessmentScore: 58,
                    mindsetScore: 50,
                },
                {
                    name: "Performance & Coaching",
                    selfAssessmentScore: 48,
                    mindsetScore: 55,
                },
                {
                    name: "Innovate & Improve",
                    selfAssessmentScore: 78,
                    mindsetScore: 67,
                },
                {
                    name: "Make It Happen",
                    selfAssessmentScore: 50,
                    mindsetScore: 45,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 53,
                    mindsetScore: 50,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 67,
                            mindsetScore: 65,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 54,
                            mindsetScore: 43,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 86,
                            mindsetScore: 65,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Innovate & Improve",
                    highlightType: "Most Prevalent",
                    percentScore: "77%",
                    description: "77% of the team are naturally strong in this area",
                },
                {
                    name: "Leadership",
                    highlightType: "Potential to Unlock",
                    percentScore: "40%",
                    description: "40% of the team have untapped potential in this area",
                },
                {
                    name: "Make it Happen",
                    highlightType: "Blindspots",
                    percentScore: "13%",
                    description: "13% of the team may find this area challenging",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Adaptive Mindset",
                            percentScore: 59,
                        },
                        { name: "Systems Thinking", percentScore: 49 },
                        {
                            name: "Digital & Data",
                            percentScore: 44,
                        },
                        {
                            name: "Work Together",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Performance & Coaching", percentScore: 11, category: "Blindspots" },
                        { name: "Accountability & Outcomes", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        {
                            name: "Work Together",
                            percentScore: 65,
                            category: "Blindspots",
                        },
                    ],
                },
            ],
        },
        {
            name: "Finance and Business Services",
            capabilityScores: [
                {
                    name: "Work Together",
                    selfAssessmentScore: 52,
                    mindsetScore: 48,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 61,
                    mindsetScore: 58,
                },
                {
                    name: "Accountability & Outcomes",
                    selfAssessmentScore: 49,
                    mindsetScore: 43,
                },
                {
                    name: "Stakeholder Relationships & Influence",
                    selfAssessmentScore: 36,
                    mindsetScore: 40,
                },
                {
                    name: "Digital & Data",
                    selfAssessmentScore: 45,
                    mindsetScore: 51,
                },
                {
                    name: "Decision Making & Judgment",
                    selfAssessmentScore: 54,
                    mindsetScore: 52,
                },
                {
                    name: "Social Responsibility & Harm Minimisation",
                    selfAssessmentScore: 58,
                    mindsetScore: 50,
                },
                {
                    name: "Systems Thinking",
                    selfAssessmentScore: 51,
                    mindsetScore: 53,
                },
                {
                    name: "Performance & Coaching",
                    selfAssessmentScore: 48,
                    mindsetScore: 55,
                },
                {
                    name: "Innovate & Improve",
                    selfAssessmentScore: 43,
                    mindsetScore: 47,
                },
                {
                    name: "Make It Happen",
                    selfAssessmentScore: 50,
                    mindsetScore: 45,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 53,
                    mindsetScore: 50,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 67,
                            mindsetScore: 65,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 54,
                            mindsetScore: 43,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 86,
                            mindsetScore: 65,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Make it Happen",
                    highlightType: "Most Prevalent",
                    percentScore: "71%",
                    description: "71% of the team are naturally strong in this area",
                },
                {
                    name: "Work Together",
                    highlightType: "Potential to Unlock",
                    percentScore: "32%",
                    description: "32% of the team have untapped potential in this area",
                },
                {
                    name: "Systems Thinking",
                    highlightType: "Blindspots",
                    percentScore: "16%",
                    description: "16% of the team may find this area challenging",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Adaptive Mindset",
                            percentScore: 59,
                        },
                        { name: "Systems Thinking", percentScore: 49 },
                        {
                            name: "Digital & Data",
                            percentScore: 44,
                        },
                        {
                            name: "Work Together",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Performance & Coaching", percentScore: 11, category: "Blindspots" },
                        { name: "Accountability & Outcomes", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        {
                            name: "Work Together",
                            percentScore: 65,
                            category: "Blindspots",
                        },
                    ],
                },
            ],
        },
        {
            name: "Regulatory Operations",
            capabilityScores: [
                {
                    name: "Work Together",
                    selfAssessmentScore: 56,
                    mindsetScore: 42,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 73,
                    mindsetScore: 67,
                },
                {
                    name: "Accountability & Outcomes",
                    selfAssessmentScore: 45,
                    mindsetScore: 32,
                },
                {
                    name: "Stakeholder Relationships & Influence",
                    selfAssessmentScore: 24,
                    mindsetScore: 31,
                },

                {
                    name: "Digital & Data",
                    selfAssessmentScore: 38,
                    mindsetScore: 47,
                },
                {
                    name: "Decision Making & Judgment",
                    selfAssessmentScore: 62,
                    mindsetScore: 53,
                },
                {
                    name: "Social Responsibility & Harm Minimisation",
                    selfAssessmentScore: 70,
                    mindsetScore: 48,
                },
                {
                    name: "Systems Thinking",
                    selfAssessmentScore: 53,
                    mindsetScore: 61,
                },
                {
                    name: "Performance & Coaching",
                    selfAssessmentScore: 47,
                    mindsetScore: 71,
                },
                {
                    name: "Innovate & Improve",
                    selfAssessmentScore: 33,
                    mindsetScore: 38,
                },
                {
                    name: "Make It Happen",
                    selfAssessmentScore: 62,
                    mindsetScore: 44,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 53,
                    mindsetScore: 50,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 67,
                            mindsetScore: 65,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 54,
                            mindsetScore: 43,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 86,
                            mindsetScore: 65,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Work Together",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Accountability & Outcomes",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Stakeholder Relationships & Influence",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Decision Making & Judgment",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Digital & Data",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Systems Thinking",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Social Responsibility & Harm Minimisation",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Performance & Coaching",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Innovate & Improve",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Make It Happen",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 53,
                            mindsetScore: 50,
                        },
                    ],
                },
            ],

            highlights: [
                {
                    name: "Leadership",
                    highlightType: "Most Prevalent",
                    percentScore: "66%",
                    description: "66% of the team are naturally strong in this area",
                },
                {
                    name: "Work Together",
                    highlightType: "Potential to Unlock",
                    percentScore: "31%",
                    description: "31% of the team have untapped potential in this area",
                },
                {
                    name: "Make it Happen",
                    highlightType: "Blindspots",
                    percentScore: "17%",
                    description: "17% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Systems Thinking",
                            percentScore: 59,
                        },
                        { name: "Adaptive Mindset", percentScore: 49 },
                        {
                            name: "Improve & Innovate",
                            percentScore: 44,
                        },
                        {
                            name: "Decision Making & Judgment",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Performance & Coaching", percentScore: 11, category: "Blindspots" },
                        { name: "Accountability & Outcomes", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        {
                            name: "Accountability & Outcomes",
                            percentScore: 65,
                            category: "Blindspots",
                        },
                    ],
                },
            ],
        },
    ],
};
