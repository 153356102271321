import { useCallback, useEffect, useState, useContext } from "react";
import {
    AtSymbolIcon,
    BriefcaseIcon,
    CakeIcon,
    DevicePhoneMobileIcon,
    PencilIcon,
    RectangleStackIcon,
    SparklesIcon,
    TableCellsIcon,
    UserIcon,
} from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, EMPLOYMENT_SERVICES } from "../../../../../utils/constants";
import { getUserMediaFile } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import Input from "../../../../SharedComponents/Form/Input";
import Button from "../../../../SharedComponents/Button";
import CircleLoadingPlaceholder from "../../../../SharedComponents/Loading/CircleLoadingPlaceholder";
import BasicMotion from "../../../../SharedComponents/Loading/Transitions/BasicMotion";
import AddAchievement from "./InteractiveForms/AddAchievement";
import MultiSelect from "../../../../SharedComponents/Form/MultiSelect";
import { Context } from "../../../../../context/Context";

export default function CoacheeDetails(props) {
    const {
        uploadProfilePhoto,
        personalDetails,
        setPersonalDetails,
        canEdit,
        educations,
        setEducations,
        qualifications,
        setQualifications,
        coacheeInterests,
        setCoacheeInterests,
        selectablePrograms,
        chosenProgram,
        setChosenProgram,
        selectableCohorts,
        chosenCohort,
        setChosenCohort,
        programs,
        cohortAlert,
        setCohortAlert,
        setAlert,
        setAlertType,
        coacheeDetailsLoading,
        cohortsLoading,
        programsLoading,
    } = props;

    const [photo, setPhoto] = useState([]);
    const [photosLoading, setPhotosLoading] = useState(true);

    const context = useContext(Context);

    const fetchPhotos = useCallback(async () => {
        try {
            setPhotosLoading(true);
            const photoResponse = await Promise.all(
                personalDetails.mediaFiles
                    .filter((mediaFile) => ["png", "jpg", "jpeg"].includes(mediaFile.fileType))
                    .map(async (photo) => {
                        return { ...photo, content: (await getUserMediaFile(photo.identifier)).content };
                    })
            );

            if (photoResponse.length !== 0) {
                setPhoto(photoResponse[0]);
            }

            setPhotosLoading(false);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
    }, [personalDetails.mediaFiles, setAlert, setAlertType]);

    const updateProfileDetails = (key) => (value) => {
        setPersonalDetails((currProfileDetails) => {
            return { ...currProfileDetails, [key]: value };
        });
    };

    useEffect(() => {
        if (personalDetails.mediaFiles && personalDetails.mediaFiles.length > 0) {
            fetchPhotos();
        } else {
            setPhotosLoading(false);
        }
    }, [fetchPhotos, personalDetails.mediaFiles]);

    return (
        <>
            <div className="xl:grid xl:grid-cols-5 gap-4">
                <div className={"col-span-1 mb-5 "}>
                    <div className={"relative inline-block"}>
                        {photosLoading ? (
                            <BasicMotion>
                                <CircleLoadingPlaceholder />
                            </BasicMotion>
                        ) : photo.length === 0 ? (
                            <BasicMotion>
                                <div
                                    className={
                                        "flex items-center justify-center text-center w-40 h-40 xl:w-[12vw] " +
                                        "xl:h-[12vw] rounded-full bg-clip-border shadow-sm bg-slate-50"
                                    }
                                >
                                    <span className="text-xs text-slate-400 italic ">No display picture available</span>
                                </div>
                            </BasicMotion>
                        ) : (
                            <BasicMotion>
                                <img
                                    key={photo.identifier}
                                    className={
                                        "w-40 h-40 xl:w-[12vw] xl:h-[12vw] rounded-full bg-clip-border shadow-sm " +
                                        "bg-slate-50"
                                    }
                                    src={"data:image/png;base64," + photo.content}
                                    alt={photo.name}
                                />
                            </BasicMotion>
                        )}
                        {canEdit && (
                            <Button
                                colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                icon={<PencilIcon className="block h-7 w-7" />}
                                extraStyling={
                                    "absolute bottom-0 right-5 text-xs h-8 w-8 text-white rounded-full shadow-sm p-2"
                                }
                                iconPositionLeft
                                onClick={uploadProfilePhoto}
                            />
                        )}
                    </div>
                </div>
                <div className="items-center lg:gap-8 col-span-4">
                    <div className="flex-grow grid grid-flow-row-dense gap-6 w-full">
                        <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-6 lg:mt-0">
                            <Input
                                id="name"
                                type="text"
                                label="First name"
                                placeholder={coacheeDetailsLoading ? "Loading.." : "First name"}
                                icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                value={personalDetails.firstName ? personalDetails.firstName : ""}
                                onChange={canEdit && updateProfileDetails("firstName")}
                                backgroundColour="bg-white"
                            />

                            <Input
                                id="name"
                                type="text"
                                label="Middle and last name"
                                placeholder={coacheeDetailsLoading ? "Loading.." : "Last name"}
                                icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                value={personalDetails.lastName ? personalDetails.lastName : ""}
                                onChange={canEdit && updateProfileDetails("lastName")}
                                backgroundColour="bg-white"
                            />
                        </div>
                        <div className="grid grid-flow-row lg:grid-cols-2 gap-6">
                            <Input
                                id="email"
                                type="email"
                                label="Email address"
                                placeholder={coacheeDetailsLoading ? "Loading.." : "Email address"}
                                icon={<AtSymbolIcon className="h-4 w-4 text-slate-500" />}
                                value={personalDetails.emailAddress ? personalDetails.emailAddress : ""}
                                onChange={canEdit && updateProfileDetails("emailAddress")}
                                extraStyling="col-span-1"
                                backgroundColour="bg-white"
                            />

                            <Input
                                id="dob"
                                type="date"
                                label="Date of birth"
                                placeholder={coacheeDetailsLoading ? "Loading.." : "Date of birth"}
                                icon={<CakeIcon className="h-4 w-4 text-slate-500" />}
                                value={personalDetails.dateOfBirth ? personalDetails.dateOfBirth.substring(0, 10) : ""}
                                onChange={canEdit && updateProfileDetails("dateOfBirth")}
                                backgroundColour="bg-white"
                            />
                        </div>
                        <div className="grid grid-flow-row lg:grid-cols-2 gap-6">
                            <Input
                                id="contact-number"
                                type="number"
                                label="Contact number"
                                placeholder={coacheeDetailsLoading ? "Loading.." : "Contact number"}
                                icon={<DevicePhoneMobileIcon className="h-4 w-4 text-slate-500" />}
                                value={personalDetails.mobileNumber ? personalDetails.mobileNumber : ""}
                                onChange={canEdit && updateProfileDetails("mobileNumber")}
                                backgroundColour="bg-white"
                            />

                            {context.defaultApp === EMPLOYMENT_SERVICES && (
                                <Input
                                    id="jobSeekerId"
                                    type="text"
                                    label="Job Seeker ID"
                                    placeholder={coacheeDetailsLoading ? "Loading.." : "Job Seeker ID"}
                                    icon={<BriefcaseIcon className="h-4 w-4 text-slate-500" />}
                                    value={personalDetails.jobSeekerId ? personalDetails.jobSeekerId : ""}
                                    onChange={canEdit && updateProfileDetails("jobSeekerId")}
                                    backgroundColour="bg-white"
                                />
                            )}
                        </div>
                        <Alert
                            text={cohortAlert}
                            type={ALERT_TYPE.WARNING}
                            active={cohortAlert}
                            close={() => setCohortAlert("")}
                            className="-my-3"
                        />
                        <div className="grid grid-flow-row lg:grid-cols-2 gap-6">
                            <MultiSelect
                                items={selectablePrograms}
                                labelText={"Program"}
                                setChosenItems={setChosenProgram}
                                loading={programsLoading || coacheeDetailsLoading}
                                extraStyling={"col-span-1"}
                                icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                placeholder="Program"
                                backgroundColour="#FFFFFF"
                                value={chosenProgram}
                            />
                            <MultiSelect
                                items={selectableCohorts}
                                labelText={"Cohort"}
                                setChosenItems={setChosenCohort}
                                loading={cohortsLoading || coacheeDetailsLoading}
                                extraStyling={"col-span-1"}
                                icon={<TableCellsIcon className="h-4 w-4 text-slate-500" />}
                                backgroundColour="#FFFFFF"
                                value={chosenCohort}
                                placeholder={
                                    chosenProgram && chosenProgram.length > 0
                                        ? programs.find((program) => program.name === chosenProgram)?.cohorts.length ===
                                          0
                                            ? "This program has no cohorts"
                                            : "Choose a cohort"
                                        : "Choose a program to select a cohort"
                                }
                                disabled={!chosenProgram || (chosenProgram && chosenProgram.length < 1)}
                            />
                        </div>
                    </div>
                    <div className="col-span-full grid grid-flow-row gap-6 mt-6 w-full">
                        <MultiSelect
                            isMulti
                            isCreatable
                            items={coacheeInterests}
                            labelText={"Interests"}
                            setChosenItems={setCoacheeInterests}
                            chosenItems={coacheeInterests}
                            loading={coacheeDetailsLoading}
                            icon={<SparklesIcon className="h-4 w-4 text-slate-500" />}
                            backgroundColour="#FFFFFF"
                        />
                    </div>
                </div>
                <div className={"col-span-5"}>
                    <div className="text-sm font-medium text-primary-600 w-full mt-6 mb-4">Education</div>
                    <div>
                        <AddAchievement
                            achievementName={"education"}
                            achievements={educations ? educations : []}
                            setAchievements={setEducations}
                        />
                    </div>
                </div>
                <div className={"col-span-5"}>
                    <div className="text-sm font-medium text-primary-600 w-full mt-6 mb-4">Qualifications</div>
                    <div>
                        <AddAchievement
                            achievementName={"qualification"}
                            achievements={qualifications ? qualifications : []}
                            setAchievements={setQualifications}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
