import { useEffect, useState } from "react";

import AddRemoveButton from "../../../../SharedComponents/Form/AddRemoveButton";
import BasicTransitionHeadlessUi from "../../../../SharedComponents/Loading/Transitions/BasicTransitionHeadlessUi";

export default function ProgramAccessRow(props) {
    const {
        item: program,
        itemRowToggleOnChange,
        noPreviousAccessUser,
        accessDefaultToggleState,
        latestItemInteracted: latestCohortInteracted,
    } = props;

    const [showTransition, setShowTransition] = useState(true);

    useEffect(() => {
        if (latestCohortInteracted) {
            if (program === latestCohortInteracted) {
                setShowTransition(false);
                setTimeout(() => {
                    setShowTransition(true);
                }, 200);
            }
        }
    }, [latestCohortInteracted, program]);

    return (
        <tr>
            <td className="w-1/2 px-4 py-3 whitespace-nowrap">
                <BasicTransitionHeadlessUi show={showTransition}>
                    <div className="text-sm font-medium text-slate-600">{program.name ? program.name : program}</div>
                </BasicTransitionHeadlessUi>
            </td>
            <td className="w-1/2 px-4 py-3">
                <BasicTransitionHeadlessUi show={showTransition}>
                    <div className="flex space-between">
                        <AddRemoveButton
                            identifier={program.name ? program.name : program}
                            name={"toggle-" + (program.name ? program.name : program)}
                            setValue={itemRowToggleOnChange}
                            noPreviousAccessUser={noPreviousAccessUser}
                            accessDefaultToggleState={accessDefaultToggleState}
                            addButtonText={"Grant access"}
                            removeButtonText={"Revoke access"}
                        />
                    </div>
                </BasicTransitionHeadlessUi>
            </td>
        </tr>
    );
}
