import { useNavigate } from "react-router-dom";

import { BUTTON_VARIANT_TYPES } from "../../../utils/constants";
import { ReactComponent as ErrorIllustration } from "../../../assets/404.svg";

import Button from "../../SharedComponents/Button";
import ErrorPage from "../../SharedComponents/ErrorPage";

function NotFound() {
    const navigate = useNavigate();

    const goToHome = async () => {
        navigate("/");
    };

    const goToPreviousPage = async () => {
        navigate(-1);
    };

    return (
        <ErrorPage
            title={"We couldn't find the page you wanted to get to."}
            subtitle={
                <div>
                    If you've typed this URL/website address please check for errors or
                    <a className={"text-primary-600 font-medium"} href="mailto:techsupport@psychpress.com.au">
                        {" "}
                        contact us{" "}
                    </a>{" "}
                    if you think this was our mistake.
                </div>
            }
            buttons={
                <>
                    <Button text="Previous page" onClick={goToPreviousPage} colour={BUTTON_VARIANT_TYPES.GRAY} />
                    <Button text="Home" onClick={goToHome} colour={BUTTON_VARIANT_TYPES.PRIMARY} />
                </>
            }
            illustration={<ErrorIllustration />}
        />
    );
}

export default NotFound;
