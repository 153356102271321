import React, { useState } from "react";

import { COACH_PLATFORM_PERMISSIONS, NO_ACCESS_TYPES } from "../../../../../utils/constants";

import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import ReportVersionRow from "./ReportVersionRow";
import { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import Alert from "../../../../SharedComponents/Alert";
import AccessControl from "../../../../SharedComponents/AccessControl";

export default function ReportVersions(props) {
    const { report } = props;

    const [alert, setAlert] = useState("");

    const sanitizedDocumentVersions = report?.documentVersions.map((documentVersion) => ({
        ...documentVersion,
        documentIdentifier: report?.identifier,
    }));

    return (
        <>
            <AccessControl
                requiredPermissions={[COACH_PLATFORM_PERMISSIONS.DOWNLOAD_REPORTS]}
                noAccessType={NO_ACCESS_TYPES.REDIRECT}
            >
                <Alert text={alert} type={ALERT_TYPE.ERROR} active={alert} close={() => setAlert("")} />
                <PaginatedSearch
                    items={sanitizedDocumentVersions}
                    itemName={"report versions"}
                    searchAttributes={["versionNumber", "reportDate", "fileFormat"]}
                    columnNames={["Version Number", "Report Date", "File Type", "Download", "Additional Files"]}
                    ItemRow={ReportVersionRow}
                    setAlert={setAlert}
                />
            </AccessControl>
        </>
    );
}
