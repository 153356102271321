import { useEffect, useState } from "react";

import AddRemoveButton from "../../../../SharedComponents/Form/AddRemoveButton";
import BasicTransitionHeadlessUi from "../../../../SharedComponents/Loading/Transitions/BasicTransitionHeadlessUi";

export default function ProgramCohortRow(props) {
    const {
        item: cohort,
        itemRowToggleOnChange,
        noPreviousAccessUser,
        accessDefaultToggleState,
        latestItemInteracted: latestCohortInteracted,
    } = props;

    const [showTransition, setShowTransition] = useState(true);

    useEffect(() => {
        if (latestCohortInteracted) {
            if (cohort === latestCohortInteracted) {
                setShowTransition(false);
                setTimeout(() => {
                    setShowTransition(true);
                }, 200);
            }
        }
    }, [latestCohortInteracted, cohort]);

    return (
        <tr>
            <td className="px-4 py-3">
                <BasicTransitionHeadlessUi show={showTransition}>
                    <div className={"text-sm font-medium text-slate-600"}>{cohort}</div>
                </BasicTransitionHeadlessUi>
            </td>

            {accessDefaultToggleState && (
                <td className="px-4 py-3 w-48">
                    <BasicTransitionHeadlessUi show={showTransition}>
                        <div className="flex space-between">
                            <AddRemoveButton
                                identifier={cohort}
                                name={"toggle-" + cohort}
                                setValue={itemRowToggleOnChange}
                                noPreviousAccessUser={noPreviousAccessUser}
                                accessDefaultToggleState={accessDefaultToggleState}
                            />
                        </div>
                    </BasicTransitionHeadlessUi>
                </td>
            )}
        </tr>
    );
}
