import { useState } from "react";

import {
    AXIS_LABELS,
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID_REVERSE_ORDER,
} from "../../../../../../../../utils/constants";

import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QuestionsDataChart from "../../ChartCards/QuestionsDataChart";
import SingleWithBenchmark from "../../ChartCards/SingleWithBenchmark";

export default function IndividualFieldManagerSatisfaction(props) {
    const { data, displayOnlyContent } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const tabList = ["Business capability", "Relationship capability"];

    const tabContent = [
        {
            content: (
                <div className="flex flex-col gap-4 print:gap-8 print:break-before-page">
                    <SingleWithBenchmark
                        withBenchmarks
                        detailed
                        data={data.business.data}
                        title={data.name + " - Business Capability"}
                        reversePalette
                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                        infoBubble={!displayOnlyContent}
                        onlyBubbleOnFirstBarOnPrint
                        showResponses
                        extraStyling={"print:-mt-4"}
                    />
                    <QuestionsDataChart
                        data={data.business.items}
                        title={data.name + " - Business Capability by Item Level"}
                        colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID_REVERSE_ORDER}
                        noItemTitles
                        topXAxis
                        questions
                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                        infoBubble={!displayOnlyContent}
                        onlyBubbleOnFirstBarOnPrint
                        noInfoBubbleOnPrint
                        showResponses
                        extraStyling={"break-before-page print:-mt-4"}
                    />
                </div>
            ),
            readonly: true,
        },
        {
            content: (
                <div className="flex flex-col gap-4 print:gap-8 print:break-before-page ">
                    <SingleWithBenchmark
                        withBenchmarks
                        detailed
                        data={data.relationship.data}
                        title={data.name + " - Relationship Capability"}
                        reversePalette
                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                        infoBubble={!displayOnlyContent}
                        onlyBubbleOnFirstBarOnPrint
                        showResponses
                        extraStyling={"print:-mt-4"}
                    />
                    <QuestionsDataChart
                        data={data.relationship.items}
                        title={data.name + " - Relationship Capability by Item Level"}
                        colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID_REVERSE_ORDER}
                        noItemTitles
                        topXAxis
                        questions
                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                        noInfoBubbleOnPrint
                        infoBubble={!displayOnlyContent}
                        showResponses
                        extraStyling={"break-before-page print:-mt-4"}
                    />
                </div>
            ),
            readonly: true,
        },
    ];

    return (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            tabContent={tabContent}
            tabsRowOnly
            secondaryTabs
            gapLessTabsAndContent
            noContainer
            displayOnlyContent={displayOnlyContent}
        />
    );
}
