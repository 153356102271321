import { useCallback, useContext, useEffect, useState } from "react";

import { getRUThereUserInterviewsForUser } from "../../../../utils/api";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import PracticeInterviewCard from "./PracticeInterviewCard";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";

export default function PracticeInterviews() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [practiceInterviews, setPracticeInterviews] = useState([]);
    const [userInterviewsLoading, setUserInterviewsLoading] = useState(false);

    const context = useContext(Context);

    const fetchUserInterviews = useCallback(async () => {
        setUserInterviewsLoading(true);
        try {
            const rUThereUserInterviewsResponse = await getRUThereUserInterviewsForUser(
                context.profileDetails.userIdentifier
            );
            setPracticeInterviews(rUThereUserInterviewsResponse);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setUserInterviewsLoading(false);
    }, [context.profileDetails.userIdentifier]);

    useEffect(() => {
        fetchUserInterviews();
    }, [fetchUserInterviews]);

    return (
        <DashboardLayout
            title={"Practice interviews"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_PRACTICE_INTERVIEWS]}
        >
            <div className="flex flex-col gap-4">
                <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                <div
                    className={
                        "bg-primary-50 p-3 rounded-xl text-primary-600 text-sm shadow-sm border border-primary-100 border-1"
                    }
                >
                    Practice interviews recommended by your {context.terminology.coach.singular.toLowerCase()}, manager
                    or supervisor will appear here.
                </div>
                <PaginatedSearch
                    loading={userInterviewsLoading}
                    items={practiceInterviews}
                    itemName="practice interviews"
                    disableSearch={!practiceInterviews || practiceInterviews.length === 1}
                    searchAttributes={["campaignName", "firstName", "lastName", "emailAddress", "deadline"]}
                    ItemRow={PracticeInterviewCard}
                    fetchItems={fetchUserInterviews}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    cards
                    customCardGrid={
                        "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 " +
                        "2xl:grid-cols-4 gap-4"
                    }
                    customPlaceholder="Search practice interviews by interview type/name"
                />
            </div>
        </DashboardLayout>
    );
}
