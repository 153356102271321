import { useRef } from "react";
import { useLocation } from "react-router";
import {
    AcademicCapIcon,
    BookOpenIcon,
    BriefcaseIcon,
    BuildingOffice2Icon,
    BuildingOfficeIcon,
    IdentificationIcon,
    MagnifyingGlassCircleIcon,
    UserIcon,
} from "@heroicons/react/24/solid";

import DetailCard from "./DetailCard";
import JumpToLink from "../../SharedChartComponents/JumpToLink";
import SideSection from "../../SharedChartComponents/SideSection";
import HeadFirst from "../HeadFirst/HeadFirst";
import GraphicalSummary from "./GraphicalSummary";
import ReportHeader from "../../SharedChartComponents/ReportHeader";

export default function InitialProgramQuestionnaire(props) {
    const { score, testId } = props;

    const location = useLocation();

    const introduction = useRef(null);
    const personalDetails = useRef(null);
    const LLN = useRef(null);
    const personalCircumstances = useRef(null);
    const education = useRef(null);
    const employment = useRef(null);
    const recentJobs = useRef(null);
    const jobHabits = useRef(null);
    const employmentCommencement = useRef(null);
    const summary = useRef(null);
    const detailedSummary = useRef(null);
    const recommendations = useRef(null);
    const headFirst = useRef(null);

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop - (window.innerWidth < 1280 ? 170 : 20),
            behavior: "smooth",
        });
    };

    return (
        <div className="flex flex-col gap-6" data-testid={testId && testId}>
            <ReportHeader reportName={score.reportName} userName={location.state?.userName} />
            <div
                className={
                    "flex flex-row gap-2 print:hidden sticky top-4 left-4 bg-white p-3 rounded-xl " +
                    "border border-slate-100 shadow-lg z-50 relative xl:hidden print:hidden"
                }
            >
                <div className="flex flex-row flex-wrap gap-3">
                    <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                    <JumpToLink text="Introduction" colour={"blue"} scrollTo={() => scrollToSection(introduction)} />
                    <JumpToLink
                        text="Personal details"
                        colour={"purple"}
                        scrollTo={() => scrollToSection(personalDetails)}
                    />
                    <JumpToLink
                        text="Language, Literacy, Numeracy"
                        colour={"indigo"}
                        scrollTo={() => scrollToSection(LLN)}
                    />
                    <JumpToLink
                        text="Personal Circumstances"
                        colour={"sky"}
                        scrollTo={() => scrollToSection(personalCircumstances)}
                    />
                    <JumpToLink text="Job Search Habits" colour={"teal"} scrollTo={() => scrollToSection(jobHabits)} />
                    <JumpToLink text="Employment" colour={"green"} scrollTo={() => scrollToSection(employment)} />
                    <JumpToLink
                        text="Most Recent Job Details"
                        colour={"lime"}
                        scrollTo={() => scrollToSection(recentJobs)}
                    />
                    <JumpToLink
                        text="Employment Commencement"
                        colour={"yellow"}
                        scrollTo={() => scrollToSection(employmentCommencement)}
                    />
                    <JumpToLink text="Education" colour={"pink"} scrollTo={() => scrollToSection(education)} />
                    <JumpToLink text="Summary" colour={"blue"} scrollTo={() => scrollToSection(summary)} />
                    <JumpToLink
                        text="Detailed report"
                        colour={"blue"}
                        scrollTo={() => scrollToSection(detailedSummary)}
                    />
                    <JumpToLink
                        text="Recommendations"
                        colour={"blue"}
                        scrollTo={() => scrollToSection(recommendations)}
                    />
                    <JumpToLink text="HeadFirst" colour={"blue"} scrollTo={() => scrollToSection(headFirst)} />
                </div>
            </div>
            <div className={"grid grid-cols-1 xl:grid-cols-3 gap-6"}>
                <div className="col-span-2 flex flex-col gap-8">
                    <div className="col-span-2 flex flex-col gap-8">
                        <div ref={introduction} className="flex flex-col gap-4">
                            <div className="text-primary-600 font-medium">Introduction</div>
                            <div className="bg-white rounded-xl p-4 shadow-sm border border-slate-100">
                                <div className="flex flex-col gap-2">
                                    <p className="text-slate-500 text-sm">{score.reportDescription}</p>
                                </div>
                            </div>
                            <div className="bg-white rounded-xl p-4 shadow-sm border border-slate-100">
                                <div className="flex flex-col gap-2">
                                    <p className="text-slate-500 text-sm">{score.jobReadiness.applicant}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="text-primary-600 font-medium">Responses</div>
                            <div
                                className={
                                    "p-4 print:p-0 rounded-xl bg-white shadow-sm border " +
                                    "border-slate-100 col-span-2 flex flex-col gap-8"
                                }
                            >
                                <div
                                    ref={personalDetails}
                                    data-testid="personalDetailsSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-purple-600 font-medium"}>
                                        <IdentificationIcon className="h-4 w-4" />
                                        Personal details
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                            <DetailCard
                                                label={"Name"}
                                                content={
                                                    score.jobReadiness.reportFields.firstName +
                                                    " " +
                                                    score.jobReadiness.reportFields.surname
                                                }
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Email address"}
                                                content={score.jobReadiness.reportFields.emailAddress}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Date of birth"}
                                                content={score.jobReadiness.reportFields.dob}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Age"}
                                                content={score.jobReadiness.reportFields.age}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Job Seeker ID"}
                                                content={score.jobReadiness.reportFields.jobSeekerID}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Phone number"}
                                                content={score.jobReadiness.reportFields.activePhoneNumber}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have an appropriate message bank?"}
                                                content={score.jobReadiness.reportFields.appropriateMessageBank}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Which State do you live in?"}
                                                content={score.jobReadiness.reportFields.state}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Are you registered on MyGov and linked to your jobsearch dashboard?"
                                                }
                                                content={score.jobReadiness.reportFields.mygovRegistration}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div ref={LLN} data-testid="lLNSection" className="flex flex-col gap-4">
                                    <div className={"flex flex-row items-center gap-2 text-indigo-600 font-medium"}>
                                        <BookOpenIcon className="h-4 w-4" />
                                        Language, Literacy, Numeracy
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                            <DetailCard
                                                label={"Is English your native language?"}
                                                content={score.jobReadiness.reportFields.englishNativeLanguage}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={"What is your native language?"}
                                                content={score.jobReadiness.reportFields.nativeLanguage}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={"How do you rate your ability to read English?"}
                                                content={score.jobReadiness.reportFields.englishAbilityRead}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={"How do you rate your ability to write English?"}
                                                content={score.jobReadiness.reportFields.englishAbilityWrite}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={"How do you rate your ability to speak English?"}
                                                content={score.jobReadiness.reportFields.englishAbilitySpeak}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have any concerns with your vision or wear glasses currently?"
                                                }
                                                content={score.jobReadiness.reportFields.visionOrGlasses}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ref={personalCircumstances}
                                    data-testid="personalCircumstancesSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-sky-600 font-medium"}>
                                        <UserIcon className="h-4 w-4" />
                                        Personal Circumstances
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"Do you have a driver's license?"}
                                                content={score.jobReadiness.reportFields.driverLicense}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have access to a vehicle?"}
                                                content={score.jobReadiness.reportFields.accessToVehicle}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have access to public transport?"}
                                                content={score.jobReadiness.reportFields.accessToPublicTransport}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />

                                            <DetailCard
                                                label={"Do you have access to a computer and internet?"}
                                                content={score.jobReadiness.reportFields.accessToComputer}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Is there anything we should know or consider when supporting you to " +
                                                    "find employment?"
                                                }
                                                content={score.jobReadiness.reportFields.findingWork}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            {/** Default value of the currentHealthStatus property is '-' */}
                                            {score.jobReadiness.reportFields.currentHealthStatus !== "-" && (
                                                <DetailCard
                                                    label={
                                                        "What is the current status of this medical/health condition?"
                                                    }
                                                    content={score.jobReadiness.reportFields.currentHealthStatus}
                                                    backgroundColor={"bg-sky-50"}
                                                    textColour={"text-sky-600"}
                                                />
                                            )}
                                            {/** Default value of the treatmentAppointments property is '-' */}
                                            {score.jobReadiness.reportFields.treatmentAppointments !== "-" && (
                                                <DetailCard
                                                    label={
                                                        "Do you currently need to travel to appointments to seek treatment?"
                                                    }
                                                    content={score.jobReadiness.reportFields.treatmentAppointments}
                                                    backgroundColor={"bg-sky-50"}
                                                    textColour={"text-sky-600"}
                                                />
                                            )}
                                            <DetailCard
                                                label={
                                                    "What supports do you feel you may need in order to successfully obtain " +
                                                    "and maintain employment?"
                                                }
                                                content={score.jobReadiness.reportFields.maintainingEmployment}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"Would you pass a pre-employment medical?"}
                                                content={score.jobReadiness.reportFields.preEmploymentMedical}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Is there anything else we should know that could impact you capacity " +
                                                    "to find and maintain employment or the hours you could work?"
                                                }
                                                content={score.jobReadiness.reportFields.anythingElseMaintainEmployment}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div ref={jobHabits} data-testid="jobHabitsSection" className="flex flex-col gap-4">
                                    <div className={"flex flex-row items-center gap-2 text-teal-600 font-medium"}>
                                        <MagnifyingGlassCircleIcon className="h-4 w-4" />
                                        Job Search Habits, Knowledge, Resources, Confidence, Motivation
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"How do you look for work? Nominate what is applicable."}
                                                content={score.jobReadiness.reportFields.howDoYouLookForWork}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have suitable networks who can help you find work?"}
                                                content={score.jobReadiness.reportFields.suitableNetworkToFindWork}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel comfortable discussing your skills and achievements in an interview?"
                                                }
                                                content={score.jobReadiness.reportFields.comfortableDiscussingSkills}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "How confident do you feel looking for employment & job searching?"
                                                }
                                                content={score.jobReadiness.reportFields.lookingForEmploymentHow}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have an up to date resume, including your most recent work history?"
                                                }
                                                content={score.jobReadiness.reportFields.upToDateResume}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have 2 current/recent references that you can include on your resume?"
                                                }
                                                content={score.jobReadiness.reportFields.appropriateReferences}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"How optimistic are you about getting a job that suits you?"}
                                                content={score.jobReadiness.reportFields.optimisticAboutGettingJob}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "How confident are you that you have the current skills and experience required to get the type of jobs you want?"
                                                }
                                                content={score.jobReadiness.reportFields.confidentGettingJobsYouWant}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"How motivated are you to look for employment?"}
                                                content={score.jobReadiness.reportFields.lookingForEmploymentMotivation}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Currently, how actively are you searching for work?"}
                                                content={
                                                    score.jobReadiness.reportFields.activelySearchingForEmploymentHow
                                                }
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"On average how many jobs do you apply for a month?"}
                                                content={
                                                    score.jobReadiness.reportFields
                                                        .lookingForEmploymentHowManyJobsDetails
                                                }
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel confident networking or making initial contact with employers to seek work?"
                                                }
                                                content={score.jobReadiness.reportFields.confidentNetworking}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel confident creating tailored resumes and cover letters suited to the jobs you want to apply for?"
                                                }
                                                content={score.jobReadiness.reportFields.confidentCreatingResumes}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"When was your last job interview?"}
                                                content={score.jobReadiness.reportFields.lastInterview}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have suitable clothing to wear to a job interview?"}
                                                content={score.jobReadiness.reportFields.clothing}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have people supporting you in your job search efforts?"}
                                                content={score.jobReadiness.reportFields.supportingPeopleInJobSearch}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel confident using a computer to assist with your job searching?"
                                                }
                                                content={score.jobReadiness.reportFields.confidentUsingComputer}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel confident searching the internet to research employers and job opportunities?"
                                                }
                                                content={score.jobReadiness.reportFields.confidentSearchingInternet}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do you feel confident using internet job boards?"}
                                                content={
                                                    score.jobReadiness.reportFields.confidentUsingInternetJobBoards
                                                }
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel confident using social media i.e. LinkedIn to look for work?"
                                                }
                                                content={
                                                    score.jobReadiness.reportFields.confidentUsingSocialMediaForWork
                                                }
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel confident uploading your resume and completing online applications?"
                                                }
                                                content={
                                                    score.jobReadiness.reportFields
                                                        .confidentCompletingOnlineApplications
                                                }
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do you feel confident creating and sending emails?"}
                                                content={score.jobReadiness.reportFields.confidentSendingEmail}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you feel confident using different devices such as smartphones, tablets and apps?"
                                                }
                                                content={score.jobReadiness.reportFields.confidentUsingSmartDevices}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div ref={employment} data-testid="employmentSection" className="flex flex-col gap-4">
                                    <div className={"flex flex-row items-center gap-2 text-teal-600 font-medium"}>
                                        <BriefcaseIcon className="h-4 w-4" />
                                        Employment
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"What is your current employment status?"}
                                                content={score.jobReadiness.reportFields.employmentStatus}
                                                backgroundColor={"bg-green-50"}
                                                textColour={"text-green-600"}
                                            />
                                            <DetailCard
                                                label={"If unemployed, what is the main reason for unemployment?"}
                                                content={score.jobReadiness.reportFields.reasonForUnemployment}
                                                backgroundColor={"bg-green-50"}
                                                textColour={"text-green-600"}
                                            />
                                            <DetailCard
                                                label={"What has been your main activity over the last two years?"}
                                                content={score.jobReadiness.reportFields.mainActivityOverLastTwoYears}
                                                backgroundColor={"bg-green-50"}
                                                textColour={"text-green-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div ref={recentJobs} data-testid="recentJobsSection" className="flex flex-col gap-4">
                                    <div className={"flex flex-row items-center gap-2 text-lime-600 font-medium"}>
                                        <BuildingOfficeIcon className="h-4 w-4" />
                                        Most Recent/Current Job Details
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"Position/Job Title"}
                                                content={score.jobReadiness.reportFields.recentEmploymentTitle}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Briefly describe your primary responsibilities"}
                                                content={
                                                    score.jobReadiness.reportFields.recentEmploymentResponsibilities
                                                }
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Type of employment"}
                                                content={score.jobReadiness.reportFields.recentEmploymentType}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Length of employment in months"}
                                                content={score.jobReadiness.reportFields.recentEmploymentLength}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"When did you finish in this role (if not currently employed)"}
                                                content={score.jobReadiness.reportFields.roleTermDate}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "How well suited do you feel you are to the following industries?"
                                                }
                                                content={
                                                    score.jobReadiness.reportFields.industries &&
                                                    score.jobReadiness.reportFields.industries.includes("|")
                                                        ? score.jobReadiness.reportFields.industries
                                                              .split("|")
                                                              .join(", ")
                                                        : score.jobReadiness.reportFields.industries
                                                }
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"What are your current job role preferences?"}
                                                content={score.jobReadiness.reportFields.recentJobRolePreference}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Please list your top 5 skills, knowledge and/or experience?"}
                                                content={score.jobReadiness.reportFields.topSkillsKnowledge}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Are you potentially interested in an apprenticeship or traineeship?"
                                                }
                                                content={score.jobReadiness.reportFields.interestInAppreticeships}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Are you potentially interested in starting your own business?"}
                                                content={score.jobReadiness.reportFields.interestInStartingBusiness}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Availability to work"}
                                                content={score.jobReadiness.reportFields.availability}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"What employment goals do you currently have for yourself?"}
                                                content={score.jobReadiness.reportFields.employmentGoals}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ref={employmentCommencement}
                                    data-testid="employmentCommencementSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-yellow-600 font-medium"}>
                                        <BuildingOffice2Icon className="h-4 w-4" />
                                        Employment Commencement & Retention
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={
                                                    "Do you have appropriate work related identity documents? " +
                                                    "(Example: Proof of Age, Driver’s License, Birth Certificate)"
                                                }
                                                content={score.jobReadiness.reportFields.idDocuments}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have your Pay (bank account), Superannuation, Tax & Employment information available to provide to an employer?"
                                                }
                                                content={score.jobReadiness.reportFields.paySuperTax}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "If you were to obtain employment tomorrow, how confident would you feel about starting and staying in that role?"
                                                }
                                                content={score.jobReadiness.reportFields.confidenceMaintainingRole}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={"Why do you feel that way?"}
                                                content={
                                                    score.jobReadiness.reportFields.confidenceMaintainingRoleDetails
                                                }
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "If you find a job, how long do you typically remain employed on average?"
                                                }
                                                content={score.jobReadiness.reportFields.employmentRetention}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have access to necessary work-related clothes, equipment and/or uniforms needed to commence work?"
                                                }
                                                content={score.jobReadiness.reportFields.accessToWorkEquipments}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div ref={education} data-testid="educationSection" className="flex flex-col gap-4">
                                    <div className={"flex flex-row items-center gap-2 text-pink-600 font-medium"}>
                                        <AcademicCapIcon className="h-4 w-4" />
                                        Education
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"What is the highest qualification you have completed?"}
                                                content={score.jobReadiness.reportFields.highestQualification}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"Details of the qualifications obtained"}
                                                content={score.jobReadiness.reportFields.qualificationsDetails}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Are you currently or have you recently undertaken any education or training?"
                                                }
                                                content={score.jobReadiness.reportFields.ongoingEducationTraining}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"If yes, what are you interested in undertaking studies in?"}
                                                content={
                                                    score.jobReadiness.reportFields.ongoingEducationTrainingDetails
                                                }
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"Do you hold any of the below Licenses/Tickets?"}
                                                content={score.jobReadiness.reportFields.licenses}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"Please choose the type of truck license you hold"}
                                                content={score.jobReadiness.reportFields.truckLicenses}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have copies of all of your current Tickets/Licences/Certificates?"
                                                }
                                                content={score.jobReadiness.reportFields.licenseCopies}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do any of your licences/tickets/training certificates require updating?"
                                                }
                                                content={score.jobReadiness.reportFields.licenseCopiesUpdating}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"What is needed to be able to update licenses/tickets/training?"}
                                                content={score.jobReadiness.reportFields.licenseCopiesUpdatingNeeds}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you currently have or could you obtain a Working With Children Check " +
                                                    "and/or a Police Check?"
                                                }
                                                content={score.jobReadiness.reportFields.wwccPoliceCheck}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Are there any tickets, licenses you would like to undertake or think would help you obtain employment?"
                                                }
                                                content={
                                                    score.jobReadiness.reportFields.ticketsLicensesToObtainEmployment
                                                }
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"List tickets or licenses that would help obtain employment"}
                                                content={score.jobReadiness.reportFields.listTicketsOrLicenses}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have any other education/training goals?"}
                                                content={score.jobReadiness.reportFields.hasEducationTrainingGoals}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={"List education/training goals"}
                                                content={score.jobReadiness.reportFields.educationTrainingGoals}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you regularly commit time to improving your skills and/or learning new skills?"
                                                }
                                                content={score.jobReadiness.reportFields.commitTimeForSkills}
                                                backgroundColor={"bg-pink-50"}
                                                textColour={"text-pink-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GraphicalSummary
                            score={score}
                            summaryRef={summary}
                            detailedReportRef={detailedSummary}
                            recommendationsRef={recommendations}
                        />
                        {score.headfirst && (
                            <div
                                ref={headFirst}
                                data-testid="headFirstSection"
                                className="col-span-2 flex flex-col gap-6"
                            >
                                <HeadFirst score={score.headfirst} onlyContent />
                            </div>
                        )}
                    </div>
                </div>
                <SideSection
                    extraContent={
                        <div
                            className={
                                "flex flex-col gap-4 print:hidden sticky top-4 left-4 bg-white p-4 rounded-xl " +
                                "shadow-lg z-50 hidden w-auto xl:flex 2xl:w-max print:hidden mt-9"
                            }
                        >
                            <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                            <div className="flex flex-col gap-3">
                                <JumpToLink
                                    text="Introduction"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(introduction)}
                                />
                                <JumpToLink
                                    text="Personal details"
                                    icon={<IdentificationIcon className="h-4 w-4" />}
                                    colour={"purple"}
                                    scrollTo={() => scrollToSection(personalDetails)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Language, Literacy, Numeracy"
                                    icon={<BookOpenIcon className="h-4 w-4" />}
                                    colour={"indigo"}
                                    scrollTo={() => scrollToSection(LLN)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Personal Circumstances"
                                    icon={<UserIcon className="h-4 w-4" />}
                                    colour={"sky"}
                                    scrollTo={() => scrollToSection(personalCircumstances)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Job Search Habits"
                                    icon={<MagnifyingGlassCircleIcon className="h-4 w-4" />}
                                    colour={"teal"}
                                    scrollTo={() => scrollToSection(jobHabits)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Employment"
                                    icon={<BriefcaseIcon className="h-4 w-4" />}
                                    colour={"green"}
                                    scrollTo={() => scrollToSection(employment)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Most Recent Job Details"
                                    icon={<BuildingOfficeIcon className="h-4 w-4" />}
                                    colour={"lime"}
                                    scrollTo={() => scrollToSection(recentJobs)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Employment Commencement"
                                    icon={<BuildingOffice2Icon className="h-4 w-4" />}
                                    colour={"yellow"}
                                    scrollTo={() => scrollToSection(employmentCommencement)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Education"
                                    icon={<AcademicCapIcon className="h-4 w-4" />}
                                    colour={"pink"}
                                    scrollTo={() => scrollToSection(education)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Summary"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(summary)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Detailed report"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(detailedSummary)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Recommendations"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(recommendations)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="HeadFirst"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(headFirst)}
                                    topPlacement
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
}
