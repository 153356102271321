import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import ResourceDetails from "./ResourceDetails";
import UserAccess from "./UserAccess";
import CohortAccess from "./CohortAccess";
import ProgramAccess from "./ProgramAccess";
import {
    assignResourceToUsers,
    assignResourceToCohorts,
    assignResourceToPrograms,
    updateResource,
    getAllResources,
    getAllResourceRatings,
    updateResourceRating,
    createResourceRating,
} from "../../../../../utils/api";
import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import { COACH_PLATFORM_PERMISSIONS, RESOURCE_TYPES } from "../../../../../utils/constants";
import { Context } from "../../../../../context/Context";

export default function Resource() {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(Context);
    const resourceData = location?.state?.resource;

    const [resource, setResource] = useState(resourceData);
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [userIdentifiersNewAccess, setUserIdentifiersNewAccess] = useState([]);
    const [cohortIdentifiersNewAccess, setCohortIdentifiersNewAccess] = useState([]);
    const [programIdentifiersNewAccess, setProgramIdentifiersNewAccess] = useState([]);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(resourceData?.title || "");
    const [subtitle, setSubtitle] = useState(resourceData?.subtitle || "");
    const [description, setDescription] = useState(resourceData?.description || "");
    const [resourceType, setResourceType] = useState(resourceData?.resourceType || "");
    const [startTime, setStartTime] = useState(resourceData?.startTime || "");
    const [durationMins, setDurationMins] = useState(resourceData?.durationMins || "");
    const [resourceLocation, setResourceLocation] = useState(resourceData?.location || "");
    const [isOrganisationResource, setIsOrganisationResource] = useState(resourceData?.isOrgResource || false);
    const [mediaFiles, setMediaFiles] = useState(resourceData?.mediaFiles || []);
    const [resourceLinks, setResourceLinks] = useState(resourceData?.resourceLinks || []);
    const [files, setFiles] = useState([]);
    const [ownRating, setOwnRating] = useState(location?.state?.ownRating);
    const [allResourceRatings, setAllResourceRatings] = useState(location?.state?.allResourceRatings || []);

    const handleUpdateResource = async () => {
        setLoading(true);

        if (title === "" || !title) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter resource name.");
            setLoading(false);
            return;
        }

        if (durationMins && durationMins < 0) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter a positive integer.");
            setLoading(false);
            return;
        }

        // null can't be passed in multipart-formdata, therefore '' are being used.
        const sanitizedResourceLinks = resourceLinks.map(resourceLink => ({
            name: resourceLink?.name ?? '',
            url: resourceLink?.url ?? '',
        }));

        try {
            if (resourceType === RESOURCE_TYPES.READING) {
                // null can't be passed in multipart-formdata, therefore '' are being used.
                // backend will render '' to null automatically.
                await updateResource(
                    resource.identifier,
                    title,
                    subtitle ? subtitle : "",
                    description ? description : "",
                    resourceType,
                    "",
                    durationMins ? durationMins : "",
                    "",
                    isOrganisationResource,
                    sanitizedResourceLinks,
                    files
                );

                location?.state?.ownRating === 0
                    ? ownRating > 0 && (await createResourceRating(ownRating, resource.identifier))
                    : ownRating > 0 && (await updateResourceRating(ownRating, resource.identifier));
            } else {
                await updateResource(
                    resource.identifier,
                    title,
                    subtitle ? subtitle : "",
                    description ? description : "",
                    resourceType,
                    startTime ? startTime : "",
                    durationMins ? durationMins : "",
                    resourceLocation ? resourceLocation : "",
                    isOrganisationResource,
                    sanitizedResourceLinks,
                    files
                );

                location?.state?.ownRating === 0
                    ? ownRating > 0 && (await createResourceRating(ownRating, resource.identifier))
                    : ownRating > 0 && (await updateResourceRating(ownRating, resource.identifier));
            }

            fetchResource();
            fetchAllResourceRatings();

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully updated resource details!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    const handleSaveUserAccess = async () => {
        setLoading(true);

        try {
            await assignResourceToUsers(userIdentifiersNewAccess, location.state.resource.identifier);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited resource user access!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }

        setLoading(false);
    };

    const handleSaveCohortAccess = async () => {
        setLoading(true);

        try {
            await assignResourceToCohorts(cohortIdentifiersNewAccess, location.state.resource.identifier);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited resource cohort access!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }

        setLoading(false);
    };

    const handleSaveProgramAccess = async () => {
        setLoading(true);

        try {
            await assignResourceToPrograms(programIdentifiersNewAccess, location.state.resource.identifier);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited resource program access!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (location.state === null) {
            navigate("/resources");
        } else if (location.state.tabIndex) {
            setTabIndex(location.state.tabIndex);
        }
    }, [location.state, navigate]);

    const fetchAllResourceRatings = useCallback(async () => {
        setLoading(true);

        try {
            const response = await getAllResourceRatings(location?.state?.resource?.identifier);
            setAllResourceRatings(response);
        } catch (err) {
            setAlertType(ALERT_TYPE.ERROR);

            context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_RATINGS) && setAlert(err.message);

            setLoading(false);
        }

        setLoading(false);
    }, [context.userPermissions, location?.state?.resource?.identifier]);

    const fetchResource = useCallback(async () => {
        try {
            const resources = await getAllResources();

            const currentResource = resources.find(
                (resource) => resource.identifier === location.state.resource.identifier
            );

            if (currentResource) {
                setResource(currentResource);
                setTitle(currentResource.title);
                setSubtitle(currentResource.subtitle);
                setDescription(currentResource.description);
                setResourceType(currentResource.resourceType);
                setStartTime(currentResource.startTime);
                setDurationMins(currentResource.durationMins);
                setResourceLocation(currentResource.location);
                setIsOrganisationResource(currentResource.isOrgResource);
                setMediaFiles(currentResource.mediaFiles);
            }

            navigate(".", {
                replace: true,
                state: {
                    resource: currentResource,
                    ownRating: ownRating,
                    allResourceRatings: allResourceRatings,
                },
            });
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
    }, [allResourceRatings, location.state.resource.identifier, navigate, ownRating]);

    useEffect(() => {
        fetchAllResourceRatings();
    }, [fetchAllResourceRatings]);

    useEffect(() => {
        const myRating = allResourceRatings.filter(
            (rating) => rating.userIdentifier === context.profileDetails.userIdentifier
        );

        const numberOfStars = myRating.length > 0 ? myRating[0].stars : 0;
        setOwnRating(numberOfStars);
    }, [allResourceRatings, context.profileDetails.userIdentifier]);

    const permissionToSaveChanges = () => {
        if (resource.isAssigned) {
            return false;
        }

        if (
            (location?.state?.resource.isOrgResource &&
                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.UPDATE_ORG_RESOURCE)) ||
            (!location?.state?.resource.isOrgResource &&
                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.UPDATE_OWN_RESOURCE))
        ) {
            return handleUpdateResource;
        }

        return false;
    };

    const includesAssignOwnResourcesOrOrgResourcesPermission = context.userPermissions.includes(
        COACH_PLATFORM_PERMISSIONS.ASSIGN_OWN_RESOURCES || COACH_PLATFORM_PERMISSIONS.ASSIGN_ORG_RESOURCES
    );

    const tabContentList = [
        {
            title: "Details",
            content: (
                <ResourceDetails
                    resource={resource}
                    ownRating={ownRating}
                    setOwnRating={setOwnRating}
                    allResourceRatings={allResourceRatings}
                    title={title}
                    setTitle={setTitle}
                    subtitle={subtitle}
                    setSubtitle={setSubtitle}
                    description={description}
                    setDescription={setDescription}
                    resourceType={resourceType}
                    setResourceType={setResourceType}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    durationMins={durationMins}
                    setDurationMins={setDurationMins}
                    resourceLocation={resourceLocation}
                    setResourceLocation={setResourceLocation}
                    resourceLinks={resourceLinks}
                    setResourceLinks={setResourceLinks}
                    initialIsOrganisationResource={location?.state?.resource.isOrgResource}
                    isOrganisationResource={isOrganisationResource}
                    setIsOrganisationResource={setIsOrganisationResource}
                    mediaFiles={mediaFiles}
                    setMediaFiles={setMediaFiles}
                    files={files}
                    setFiles={setFiles}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    fetchAllResourceRatings={fetchAllResourceRatings}
                    fetchResource={fetchResource}
                />
            ),
            onSaveChanges: permissionToSaveChanges(),
        },
        {
            title: "User access",
            content: (
                <UserAccess
                    resource={resource}
                    setUserIdentifiersNewAccess={setUserIdentifiersNewAccess}
                    userIdentifiersNewAccess={userIdentifiersNewAccess}
                />
            ),
            onSaveChanges: handleSaveUserAccess,
        },
        {
            title: "Cohort access",
            content: (
                <CohortAccess
                    resource={resource}
                    setCohortIdentifiersNewAccess={setCohortIdentifiersNewAccess}
                    cohortIdentifiersNewAccess={cohortIdentifiersNewAccess}
                />
            ),
            onSaveChanges: handleSaveCohortAccess,
        },
        {
            title: "Program access",
            content: (
                <ProgramAccess
                    resource={resource}
                    setProgramIdentifiersNewAccess={setProgramIdentifiersNewAccess}
                    programIdentifiersNewAccess={programIdentifiersNewAccess}
                />
            ),
            onSaveChanges: handleSaveProgramAccess,
        },
    ];

    const tabList = ["Details", "User access", "Cohort access", "Program access"];

    const filteredTabContent = tabContentList.filter((tab) => {
        if (!includesAssignOwnResourcesOrOrgResourcesPermission) {
            return tab.title === "Details";
        }
        return tabContentList;
    });

    const filteredTabList = tabList.filter((tab) => {
        if (!includesAssignOwnResourcesOrOrgResourcesPermission) {
            return tab === "Details";
        }
        return tabList;
    });

    return (
        <>
            {location.state !== null && (
                <DashboardLayout title={"Resource: " + resource.title} requiredPermissions={[]}>
                    <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                    <TabbedNavigationLayout
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabList={filteredTabList}
                        backgroundColour={"bg-slate-100"}
                        loading={loading}
                        tabContent={filteredTabContent}
                    />
                </DashboardLayout>
            )}
        </>
    );
}
