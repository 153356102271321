export default function LowMediumHighBubble(props) {
    const { percentage, small, testId, customStyling } = props;

    return (
        <div
            data-testid={testId && testId}
            className={
                "flex flex-row items-center gap-2 rounded-xl w-max border " +
                (small ? "py-1 px-1.5 " : "py-1.5 px-2 ") +
                (customStyling
                    ? customStyling
                    : percentage > 0 && percentage < 34
                    ? "bg-red-100 border border-red-400 text-red-600"
                    : percentage >= 34 && percentage < 67
                    ? "bg-orange-100 border border-orange-400 text-orange-600"
                    : "bg-emerald-100 border border-emerald-400 text-emerald-600")
            }
        >
            <span className={"font-medium " + (small ? " text-xs" : "text-sm")}>{percentage && percentage + "% "}</span>
        </div>
    );
}
