import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";

import { getAllResources, getAssignedResources } from "../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import ResourceCardSearch from "./ResourceCardSearch";
import { useLocation } from "react-router-dom";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";

export default function Resources() {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [allResources, setAllResources] = useState([]);
    const [organisationResources, setOrganisationResources] = useState([]);
    const [ownResources, setOwnResources] = useState([]);
    const [assignedResources, setAssignedResources] = useState([]);
    const [mergedResources, setMergedResources] = useState([]);
    const [loadingAllResources, setLoadingAllResources] = useState(true);
    const [loadingAssignedResources, setLoadingAssignedResources] = useState(true);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const location = useLocation();

    const fetchAllResources = useCallback(async () => {
        try {
            const response = await getAllResources();
            setAllResources(response);
            const organisationResources = response.filter((resource) => resource.isOrgResource === true);
            const ownResources = response.filter((resource) => resource.isOrgResource === false);
            setOrganisationResources(organisationResources);
            setOwnResources(ownResources);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoadingAllResources(false);
    }, []);

    const fetchAssignedResources = useCallback(async () => {
        try {
            const response = await getAssignedResources();
            setAssignedResources(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoadingAssignedResources(false);
    }, []);

    const fetchBothAssignedAndAllResources = useCallback(async () => {
        fetchAllResources();
        fetchAssignedResources();
    }, [fetchAllResources, fetchAssignedResources]);

    const mergeAllAndAssignedResources = useCallback(() => {
        let newResourcesList = [];

        const allResourcesIdentifiers = allResources.map((resource) => resource.identifier);
        if (
            !loadingAllResources &&
            !loadingAssignedResources &&
            allResources &&
            assignedResources &&
            allResourcesIdentifiers
        ) {
            assignedResources.forEach((resource) => {
                if (!allResourcesIdentifiers.includes(resource.identifier)) {
                    resource.isAssigned = true;
                    newResourcesList.push(resource);
                }
            });

            setMergedResources([...allResources, ...newResourcesList]);
            setLoading(false);
        }
    }, [allResources, assignedResources, loadingAllResources, loadingAssignedResources]);

    useEffect(() => {
        fetchBothAssignedAndAllResources();
    }, [fetchBothAssignedAndAllResources]);

    useEffect(() => {
        if (loadingAllResources === false && loadingAssignedResources === false) {
            mergeAllAndAssignedResources();
        }
    }, [loadingAllResources, loadingAssignedResources, mergeAllAndAssignedResources]);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout
            title={"SkillBits™"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_RESOURCES_COACHEE]}
            actionButton
            actionButtonText={"Upload resource"}
            actionButtonIcon={<ArrowUpTrayIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
            actionButtonRequiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.UPLOAD_OWN_RESOURCES,
                COACH_PLATFORM_PERMISSIONS.UPLOAD_ORG_RESOURCES,
            ]}
            actionButtonOnClick={() => navigate("/upload-resource")}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <TabbedNavigationLayout
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabList={["All resources", "Organisation resources", "My resources", "Assigned resources"]}
                backgroundColour={"bg-slate-100"}
                tabsRowOnly
                tabContent={[
                    {
                        title: "All resources",
                        content: (
                            <ResourceCardSearch
                                resources={mergedResources.slice(0).reverse()}
                                loading={loading}
                                fetchAllResources={fetchBothAssignedAndAllResources}
                                setAlert={setAlert}
                                setAlertType={setAlertType}
                            />
                        ),
                        readonly: true,
                    },
                    {
                        title: "Organisation resources",
                        content: (
                            <ResourceCardSearch
                                resources={organisationResources.slice(0).reverse()}
                                loading={loading}
                                fetchAllResources={fetchBothAssignedAndAllResources}
                                setAlert={setAlert}
                                setAlertType={setAlertType}
                            />
                        ),
                        readonly: true,
                    },
                    {
                        title: "My resources",
                        content: (
                            <ResourceCardSearch
                                resources={ownResources.slice(0).reverse()}
                                loading={loading}
                                fetchAllResources={fetchBothAssignedAndAllResources}
                                setAlert={setAlert}
                                setAlertType={setAlertType}
                            />
                        ),
                        readonly: true,
                    },
                    {
                        title: "Assigned resources",
                        content: (
                            <ResourceCardSearch
                                resources={assignedResources.slice(0).reverse()}
                                loading={loading}
                                fetchAllResources={fetchBothAssignedAndAllResources}
                                setAlert={setAlert}
                                setAlertType={setAlertType}
                            />
                        ),
                        readonly: true,
                    },
                ]}
                noContainer
                secondaryTabs
            />
        </DashboardLayout>
    );
}
