import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserPlusIcon } from "@heroicons/react/24/solid";

import { getCoaches, getCoachesForCoachee, getCohorts } from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import CoachRow from "./CoachRow";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";

export default function Coaches() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coaches, setCoaches] = useState([]);
    const [sanitizedCoaches, setSanitizedCoaches] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [allCoaches, setAllCoaches] = useState(false);
    const [allCoachesLoading, setAllCoachesLoading] = useState(true);
    const [coachesLoading, setCoachesLoading] = useState(true);
    const [cohortLoading, setCohortLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const location = useLocation();

    const context = useContext(Context);

    const fetchCoaches = useCallback(async () => {
        if (!allCoachesLoading) {
            setCoachesLoading(true);
            try {
                if (allCoaches) {
                    const response = await getCoaches();
                    setCoaches(response);
                } else {
                    const response = await getCoachesForCoachee();
                    setCoaches(response);
                }
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setCoachesLoading(false);
        }
    }, [allCoaches, allCoachesLoading]);

    useEffect(() => {
        fetchCoaches();
    }, [allCoaches, fetchCoaches]);

    useEffect(() => {
        const fetchCohorts = async () => {
            setCohortLoading(true);
            try {
                const response = await getCohorts();
                setCohorts(response);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setCohortLoading(false);
        };
        fetchCohorts();
    }, []);

    useEffect(() => {
        if (cohortLoading === false && coachesLoading === false) {
            const userCohort = (userIdentifier) => {
                let userCohorts = [];
                cohorts.forEach((cohort) => {
                    if (cohort.users.some((e) => e.userIdentifier === userIdentifier)) {
                        userCohorts.push(cohort.name);
                    }
                });

                return userCohorts;
            };

            const sanitizedCoaches = coaches?.map((coach) => ({
                ...coach,
                cohorts: userCohort(coach.userIdentifier),
            }));

            setSanitizedCoaches(sanitizedCoaches);
            setLoading(false);
        }
    }, [coaches, coachesLoading, cohortLoading, cohorts]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllCoaches(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHES));
            setAllCoachesLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout
            title={
                allCoaches
                    ? context.terminology.coach
                        ? context.terminology.coach.plural
                        : "Coaches"
                    : "Your " +
                      (coaches.length > 1
                          ? context.terminology.coach
                              ? context.terminology.coach.plural
                              : "Coaches"
                          : context.terminology.coach
                          ? context.terminology.coach.singular
                          : "Coach")
            }
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.VIEW_COACHES_LIST,
                CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST,
            ]}
            actionButton
            actionButtonRequiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.SETUP_COACH,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
            actionButtonText={"Setup " + (context.terminology.coach ? context.terminology.coach.singular : "Coach")}
            actionButtonIcon={<UserPlusIcon className="block h-3.5 w-3.5 mr-2" />}
            actionButtonOnClick={() => navigate(`/setup-user/${context.terminology.coach.singular.toLowerCase()}`)}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                loading={loading}
                items={sanitizedCoaches.slice(0).reverse()}
                itemName={context.terminology.coach ? context.terminology.coach.plural : "Coaches"}
                disableSearch={!coaches || coaches.length === 1}
                searchAttributes={["firstName", "lastName", "emailAddress"]}
                columnNames={[
                    "Details",
                    "Cohort(s)",
                    "Current " + (context.terminology.coachee ? context.terminology.coachee.plural : "Coachees"),
                ].concat(
                    !context.permissionsLoading &&
                        context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ADDITIONAL_COACH_DETAILS)
                        ? [
                              "All " + (context.terminology.coachee ? context.terminology.coachee.plural : "Coachees"),
                              "Manage",
                          ]
                        : ["Message", "Manage"]
                )}
                ItemRow={CoachRow}
                customPlaceholder={
                    "Search " +
                    (context.terminology.coach ? context.terminology.coach.plural.toLowerCase() : "coaches") +
                    " by first name, last name or email address"
                }
            />
        </DashboardLayout>
    );
}
