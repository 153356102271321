import { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import {
    CheckIcon,
    ClockIcon,
    VideoCameraIcon,
    MapPinIcon,
    XMarkIcon,
    ArrowPathIcon,
    ArrowTopRightOnSquareIcon,
    DocumentDuplicateIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

import { BASIC_ANIMATION_VARIANTS, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";
import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import { updateSession } from "../../../../utils/api";

import Button from "../../../SharedComponents/Button";
import Tag from "../../../SharedComponents/Tag";
import Tippy from "@tippyjs/react";
import TippyComponent from "../../../SharedComponents/TippyComponent";

export default function SessionCard(props) {
    const { item: session, setAlert } = props;

    const context = useContext(Context);
    const navigate = useNavigate();

    const [expired, setExpired] = useState(false);
    const [expandParticipants, setExpandParticipants] = useState(false);
    const [timeDifference, setTimeDifference] = useState("");
    const [copySuccess, setCopySuccess] = useState(false);
    const [sessionProgress, setSessionProgress] = useState(false);

    const handleExpandParticipants = () => {
        setExpandParticipants((expandParticipants) => !expandParticipants);
    };

    const handleUpdateSessionAsCompleted = async () => {
        let sessionCopy = { ...session, isCompleted: true };
        sessionCopy.participants = sessionCopy.participants.map((participant) => participant.userIdentifier);

        try {
            await updateSession(
                sessionCopy.meetingIdentifier,
                sessionCopy.name,
                sessionCopy.startTime,
                sessionCopy.durationMins,
                sessionCopy.isCompleted,
                sessionCopy.meetingUrl,
                sessionCopy.meetingLocation,
                sessionCopy.participants
            );
            context.fetchSessions();
        } catch {
            setAlert("Session update failed.");
        }
    };

    const calculateTimeDifference = (dateFuture, dateNow) => {
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow);
        let diffInSeconds = diffInMilliSeconds / 1000;

        const days = Math.floor(diffInSeconds / 86400);
        diffInSeconds -= days * 86400;

        const hours = Math.floor(diffInSeconds / 3600) % 24;
        diffInSeconds -= hours * 3600;

        //Used Math.ceil() here, since seconds are not used
        const minutes = Math.ceil(diffInSeconds / 60) % 60;
        diffInSeconds -= minutes * 60;

        let difference = "";
        if (days > 0) {
            difference += days === 1 ? `${days} day, ` : `${days} days, `;
        }
        if (hours > 0) {
            difference += hours === 1 ? `${hours} hour, ` : `${hours} hours, `;
        }
        difference += minutes === 0 || minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;

        return difference;
    };

    const copyToClipboard = () => {
        const result = navigator.clipboard.writeText(session.meetingUrl);
        result
            .then(() => {
                setCopySuccess(true);
                return true;
            })
            .catch((err) => {
                setCopySuccess(false);
                return false;
            });
    };

    const resetCopyToClipboard = () => {
        setCopySuccess(false);
    };

    useEffect(() => {
        const isSessionInProgress = (startTime, durationMins) => {
            let sessionDateTime = new Date(startTime);

            return (
                Date.now() >= sessionDateTime.getTime() &&
                Date.now() < new Date(sessionDateTime.getTime() + durationMins * 60000)
            );
        };

        setExpired(session.startTime !== null && Date.now() >= new Date(session.startTime));
        setTimeDifference(calculateTimeDifference(new Date(session.startTime), Date.now()));
        setSessionProgress(isSessionInProgress(session.startTime, session.durationMins));

        let interval = setInterval(() => {
            setTimeDifference(calculateTimeDifference(new Date(session.startTime), Date.now()));
            setSessionProgress(isSessionInProgress(session.startTime, session.durationMins));
        }, 1000);

        return () => clearInterval(interval);
    }, [session.durationMins, session.startTime, timeDifference]);

    return (
        <div
            className={
                "bg-white hover:shadow-md transition-shadow shadow-sm overflow-hidden rounded-xl group " +
                "grid grid-flow-row content-between grow-0 border border-slate-100 border-1"
            }
        >
            <div
                className="bg-white p-4 flex justify-between items-center gap-6 text-slate-600 cursor-pointer"
                onClick={() => {
                    navigate("/session", {
                        state: { session: session },
                    });
                }}
            >
                <div>
                    <h3
                        className={
                            "text-base font-title font-medium tracking-tight cursor-pointer transition-colors " +
                            "text-primary-600 group-hover:text-primary-700"
                        }
                    >
                        {session.name}
                    </h3>
                </div>
                {session.meetingUrl && session.meetingLocation ? (
                    <div className="flex gap-2">
                        <VideoCameraIcon
                            className={
                                "flex-shrink-0 h-6 w-6 text-secondary-600 group-hover:text-secondary-700 transition " +
                                "transition-colors"
                            }
                        />
                        <MapPinIcon
                            className={
                                "flex-shrink-0 h-6 w-6 text-secondary-600 group-hover:text-secondary-700 transition " +
                                "transition-colors"
                            }
                        />
                    </div>
                ) : session.meetingUrl && !session.meetingLocation ? (
                    <VideoCameraIcon
                        className={
                            "flex-shrink-0 h-6 w-6 text-secondary-600 group-hover:text-secondary-700 transition " +
                            "transition-colors"
                        }
                    />
                ) : (
                    <MapPinIcon
                        className={
                            "flex-shrink-0 h-6 w-6 text-secondary-600 group-hover:text-secondary-700 transition " +
                            "transition-colors"
                        }
                    />
                )}
            </div>
            <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 ">Date</dt>
                <dd className="mt-1 text-sm text-primary-600 sm:mt-0 sm:col-span-2 font-medium">
                    {session.startTime == null ? "-" : new Date(session.startTime).toLocaleString([], { hour12: true })}
                </dd>
            </div>
            <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center ">Duration</dt>
                <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2">{session.durationMins} minutes</dd>
            </div>
            <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center ">Participants</dt>
                <dd className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col gap-3">
                    <div className="grid grid-flow-row gap-4">
                        {session.participants == null || session.participants.length === 0
                            ? "-"
                            : session.participants.map((participant, index) => {
                                  if (index < 2) {
                                      const capitalizedFirstName =
                                          participant.firstName.charAt(0).toUpperCase() +
                                          participant.firstName.slice(1);
                                      const capitalizedLastName =
                                          participant.lastName.charAt(0).toUpperCase() + participant.lastName.slice(1);
                                      const fullName = capitalizedFirstName + " " + capitalizedLastName;
                                      return (
                                          <div key={index}>
                                              <div className="text-sm text-primary-600 font-medium">
                                                  {fullName}
                                                  {context?.profileDetails?.userIdentifier ===
                                                      participant.userIdentifier && (
                                                      <span className="text-xs text-slate-500 font-medium"> (you)</span>
                                                  )}
                                              </div>
                                              <div className="text-sm mt-1 text-slate-500 break-all">
                                                  {participant.emailAddress}
                                              </div>
                                          </div>
                                      );
                                  }
                                  return null;
                              })}
                        <div
                            className={
                                expandParticipants
                                    ? "absolute z-10 mt-0 bg-white shadow-xl p-4 shadow-sm rounded-xl overflow-y-scroll max-h-80"
                                    : "hidden"
                            }
                        >
                            <motion.div
                                initial={"inactive"}
                                animate={expandParticipants ? "active" : "inactive"}
                                exit={"inactive"}
                                transition={{ duration: 0.4 }}
                                variants={BASIC_ANIMATION_VARIANTS}
                                className="grid grid-flow-row gap-4"
                            >
                                <div className="flex justify-between items-center rounded-t pb-2 border-b">
                                    <p className="text-sm text-secondary-600 font-medium">All participants</p>
                                    <button
                                        type="button"
                                        className="text-secondary-600 hover:text-secondary-700 stroke-current stroke-1"
                                        onClick={() => setExpandParticipants(false)}
                                    >
                                        <XMarkIcon className={"block h-4 w-4 ml-1"} />
                                    </button>
                                </div>
                                {session.participants == null || session.participants.length === 0
                                    ? "-"
                                    : session.participants.map((participant, index) => {
                                          const capitalizedFirstName =
                                              participant.firstName.charAt(0).toUpperCase() +
                                              participant.firstName.slice(1);
                                          const capitalizedLastName =
                                              participant.lastName.charAt(0).toUpperCase() +
                                              participant.lastName.slice(1);
                                          const fullName = capitalizedFirstName + " " + capitalizedLastName;
                                          return (
                                              <div key={index}>
                                                  <div className="text-sm text-primary-600 font-medium">
                                                      {fullName}
                                                      {context?.profileDetails?.userIdentifier ===
                                                          participant.userIdentifier && (
                                                          <span className="text-xs text-slate-500 font-medium">
                                                              {" "}
                                                              (you)
                                                          </span>
                                                      )}
                                                  </div>
                                                  <div className="text-sm mt-1 text-slate-500">
                                                      {participant.emailAddress}
                                                  </div>
                                              </div>
                                          );
                                      })}
                            </motion.div>
                        </div>
                        {session.participants.length > 2 && (
                            <div
                                className={
                                    "text-xs font-medium underline text-secondary-600 hover:text-secondary-600 " +
                                    "cursor-pointer"
                                }
                                onClick={handleExpandParticipants}
                            >
                                {expandParticipants ? "Show less" : "Show all participants"}
                            </div>
                        )}
                    </div>
                </dd>
            </div>
            {session.meetingUrl && session.meetingLocation ? (
                <>
                    <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center">Session link</dt>
                        <div className="flex items-center mt-1 text-sm text-secondary-600 sm:mt-0 sm:col-span-2">
                            <div className="w-full flex-1 flex items-center">
                                <Tippy
                                    content={"Open external link"}
                                    className={
                                        "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-secondary-600 text-white"
                                    }
                                    arrow={false}
                                    animation={"shift-away-subtle"}
                                    placement={"top-start"}
                                    hideOnClick={false}
                                >
                                    <a
                                        href={session.meetingUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="truncate hover:text-secondary-700 underline bg-secondary-50 p-1 px-1.5 rounded-lg"
                                    >
                                        <div className="flex items-center space-x-1">
                                            <span className="truncate">{session.meetingUrl}</span>
                                            <ArrowTopRightOnSquareIcon className="shrink-0 h-3.5 w-3.5 self-start stroke-current stroke-.5" />
                                        </div>
                                    </a>
                                </Tippy>
                                <Tippy
                                    content={copySuccess === true ? "Copied!" : "Click to copy URL"}
                                    className={
                                        "py-1.5 px-2 shadow rounded-2xl text-xs font-medium text-white " +
                                        (copySuccess ? "bg-green-500" : "bg-secondary-600")
                                    }
                                    arrow={false}
                                    animation={"shift-away-subtle"}
                                    placement={"top-start"}
                                    hideOnClick={false}
                                >
                                    <DocumentDuplicateIcon
                                        className={
                                            "shrink-0 block h-4 w-4 ml-1 cursor-pointer hover:text-secondary-700"
                                        }
                                        onClick={copyToClipboard}
                                        onMouseOut={resetCopyToClipboard}
                                    />
                                </Tippy>
                            </div>
                        </div>
                    </div>
                    <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center ">Location</dt>

                        <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2">{session.meetingLocation}</dd>
                    </div>
                </>
            ) : session.meetingUrl && !session.meetingLocation ? (
                <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center">Session link</dt>
                    <div className="flex items-center block mt-1 text-sm text-secondary-600 sm:mt-0 sm:col-span-2">
                        <div className="w-full flex-1 flex items-center">
                            <Tippy
                                content={"Open external link"}
                                className={
                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-secondary-600 text-white"
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"top-start"}
                                hideOnClick={false}
                            >
                                <a
                                    href={session.meetingUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="truncate hover:text-secondary-700 underline bg-secondary-50 p-1 px-1.5 rounded-lg"
                                >
                                    <div className="flex items-center space-x-1">
                                        <span className="truncate">{session.meetingUrl}</span>
                                        <ArrowTopRightOnSquareIcon className="shrink-0 h-3.5 w-3.5 self-start stroke-current stroke-.5 " />
                                    </div>
                                </a>
                            </Tippy>

                            <Tippy
                                content={copySuccess === true ? "Copied!" : "Click to copy URL"}
                                className={
                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium text-white " +
                                    (copySuccess ? "bg-green-500" : "bg-secondary-600")
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"top-start"}
                                hideOnClick={false}
                            >
                                <DocumentDuplicateIcon
                                    className={"shrink-0 block h-4 w-4 ml-1.5 cursor-pointer hover:text-secondary-700"}
                                    onClick={copyToClipboard}
                                    onMouseOut={resetCopyToClipboard}
                                />
                            </Tippy>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center ">Location</dt>

                    <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2">{session.meetingLocation}</dd>
                </div>
            )}

            <div
                className={`p-4 sm:grid sm:grid-cols-3 sm:gap-4 ${
                    session.meetingUrl && session.meetingLocation ? `bg-white` : `bg-slate-50`
                }`}
            >
                <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center ">Status</dt>
                <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2">
                    {session.isCompleted ? (
                        <Tag status={"Completed"} />
                    ) : expired && !sessionProgress ? (
                        <TippyComponent
                            text={"No permission"}
                            icon={<ExclamationTriangleIcon className="h-3 w-3" />}
                            backgroundColour="bg-red-600"
                            disabled={context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.EDIT_SESSION)}
                        >
                            <Button
                                colour={BUTTON_VARIANT_TYPES.GREEN}
                                icon={<CheckIcon className="flex-shrink-0 stroke-current stroke-1 h-3 w-3 mr-2" />}
                                text={"Mark as completed"}
                                extraStyling={"text-xs text-white grow-0 pr-2.5"}
                                iconPositionLeft
                                smallButton
                                onClick={handleUpdateSessionAsCompleted}
                                disabled={!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.EDIT_SESSION)}
                            />
                        </TippyComponent>
                    ) : sessionProgress ? (
                        <div className={"flex items-center text-sm text-amber-600 font-medium"}>
                            <ArrowPathIcon className={"block h-4 w-4 mr-2 animate-reverse-spin"} />
                            Session in progress
                        </div>
                    ) : (
                        <div className={"flex items-center text-sm text-primary-600 font-medium"}>
                            <ClockIcon className={"block h-4 w-4 mr-2"} />
                            Starts in {timeDifference}
                        </div>
                    )}
                </dd>
            </div>
        </div>
    );
}
