import { useContext } from "react";
import { Context } from "../../context/Context";

function LoginNavbar() {
    const context = useContext(Context);

    return (
        <div className="flex items-center justify-between py-4 px-6 fixed min-w-full z-50">
            <img alt="logo" className="h-9" src={context.getImage("shortLogo")} />
        </div>
    );
}

export default LoginNavbar;
