import { HandThumbUpIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import StackedBarChart from "../../../Charts/CustomCharts/StackedBarChart";
import TrendBubble from "../../../SharedChartComponents/TrendBubble";
import StackedBarsInfoBubble from "../../../Charts/CustomCharts/StackedBarsInfoBubble";
import { useEffect, useState } from "react";

export default function AchievementItem(props) {
    const { data, noTrendBubble, noBenchmarkAgree, sevenPointScale, noContainer, noInfoBubbles } = props;

    const [dataItem, setDataItem] = useState();

    /**
     * Shallow copy will directly modify the data object as shallow copy retains references to the nested objects.
     * Deep copy is used to truly create a new copy of the nested objects and avoid direct modification.
     * Hence, it won't cause the data to be flipped each time when switching tabs.
     */
    useEffect(() => {
        if (data.itemFlipped) {
            // Deep copy
            const flippedData = {
                ...data,
                data: {
                    ...data.data,
                    current: {
                        ...data.data.current,
                        percentScores: {
                            ...data.data.current.percentScores,
                            overallAgreePercentage: data.data.current.percentScores.overallDisagreePercentage,
                            overallDisagreePercentage: data.data.current.percentScores.overallAgreePercentage,
                            agreePercentage: data.data.current.percentScores.disagreePercentage,
                            disagreePercentage: data.data.current.percentScores.agreePercentage,
                            stronglyAgreePercentage: data.data.current.percentScores.stronglyDisagreePercentage,
                            stronglyDisagreePercentage: data.data.current.percentScores.stronglyAgreePercentage,
                        },
                    },
                },
            };

            setDataItem(flippedData);
        } else if (!data.itemFlipped) {
            setDataItem(data);
        }
    }, [data]);

    return (
        dataItem && (
            <div
                className={
                    "flex flex-col gap-4 w-full " +
                    "print:p-0 print:shadow-none print:border-none " +
                    (noContainer ? " " : "bg-white p-3 rounded-xl border border-slate-100 shadow-sm ")
                }
            >
                <div className="flex flex-row justify-between items-center gap-2">
                    <h2 className="text-base text-primary-600 font-medium font-title tracking-tight">
                        {dataItem.itemTitle}
                    </h2>
                    <div className="flex flex-row gap-3">
                        {!noBenchmarkAgree && dataItem.data.sectorBenchmark && (
                            <div
                                className={
                                    "flex flex-row items-center gap-2 py-1.5 px-2 bg-slate-50 border border-slate-200 " +
                                    "rounded-xl"
                                }
                            >
                                <HandThumbUpIcon className="h-4 w-4 text-emerald-600" />
                                <span className="text-sm text-slate-500">
                                    Benchmark agree{" "}
                                    <span className={"font-medium"}>
                                        {dataItem.data.sectorBenchmark.percentScores.overallAgreePercentage}%
                                    </span>
                                </span>
                            </div>
                        )}
                        {!noTrendBubble && dataItem.data.sectorBenchmark && (
                            <TrendBubble
                                percentage={
                                    dataItem.data.current.percentScores.overallAgreePercentage -
                                    dataItem.data.sectorBenchmark.percentScores.overallAgreePercentage
                                }
                                text="vs benchmark agree"
                            />
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div
                        className={
                            "text-sm text-secondary-600 font-medium bg-secondary-50 border border-secondary-100 " +
                            "rounded-xl w-fit p-2"
                        }
                    >
                        {dataItem.itemText && dataItem.itemText}
                    </div>

                    {!noInfoBubbles && (
                        <StackedBarsInfoBubble data={dataItem} showWhenPrinting noInfoBubbles={noInfoBubbles} />
                    )}

                    <Tippy
                        content={<StackedBarsInfoBubble data={dataItem} />}
                        className={"group"}
                        arrow={false}
                        animation={"shift-away-subtle"}
                        interactive={true}
                        placement={"left"}
                    >
                        <span
                            className={
                                "flex flex-col gap-1 font-title font-semibold rounded-xl transition-all hover:bg-slate-200 " +
                                "hover:px-1 hover:py-0.5 hover:shadow-sm cursor-pointer "
                            }
                        >
                            <StackedBarChart
                                data={dataItem}
                                flipped={dataItem.itemFlipped}
                                sevenPointScale={sevenPointScale}
                            />
                        </span>
                    </Tippy>
                </div>
            </div>
        )
    );
}
