import { motion } from "framer-motion";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { BASIC_ANIMATION_VARIANTS } from "../../../../utils/constants";
import { ReactComponent as CircleIcon } from "../../../../assets/circle.svg";
import Tag from "../../../SharedComponents/Tag";

export default function PickableUserRow(props) {
    const { item: coachee, updateItemStatus } = props;

    const selected = coachee.selected || coachee.individuallySelected;

    return (
        <tr
            className={
                "transition-colors cursor-pointer border-y " +
                (selected ? "bg-emerald-50 border-y border-slate-100" : "border-transparent")
            }
            onClick={() => updateItemStatus(coachee, !selected)}
        >
            <td className={"transition-colors w-[32px] " + (selected && "bg-emerald-50")}>
                <div className={"flex items-centered justify-center"}>
                    {selected && (
                        <motion.div
                            initial={"inactive"}
                            animate={"active"}
                            exit={"inactive"}
                            transition={{ duration: 0.2 }}
                            variants={BASIC_ANIMATION_VARIANTS}
                        >
                            <CheckCircleIcon className={"block h-6 w-6 text-emerald-600"} />
                        </motion.div>
                    )}

                    {!selected && (
                        <motion.div
                            initial={"inactive"}
                            animate={"active"}
                            exit={"inactive"}
                            transition={{ duration: 0.2 }}
                            variants={BASIC_ANIMATION_VARIANTS}
                        >
                            <CircleIcon className={"block h-6 w-6 text-slate-300"} />
                        </motion.div>
                    )}
                </div>
            </td>
            <td className={"w-56 px-4 py-3 whitespace-nowrap transition-colors " + (selected && "bg-emerald-50")}>
                <div
                    className={
                        "text-sm font-medium transition-colors " + (selected ? "text-emerald-600" : "text-slate-500")
                    }
                >
                    {coachee.firstName + " " + coachee.lastName}
                </div>
            </td>
            <td className={"w-56 px-4 py-3 whitespace-nowrap transition-colors " + (selected && "bg-emerald-50")}>
                <div className={"text-sm mt-1 transition-colors " + (selected ? "text-emerald-600" : "text-slate-500")}>
                    {coachee.emailAddress}
                </div>
            </td>
            <td className={"px-4 py-3 whitespace-nowrap transition-colors " + (selected && "bg-emerald-50")}>
                <div className="flex flex-wrap gap-2">
                    {coachee?.programs &&
                        coachee.programs.length > 0 &&
                        coachee.programs.map((program, index) => (
                            <Tag
                                key={index}
                                status={program}
                                backgroundColour={selected ? "bg-emerald-100" : "bg-slate-200"}
                                textColour={selected ? "text-emerald-600" : "text-slate-500"}
                            />
                        ))}
                </div>
            </td>
            <td className={"px-4 py-3 whitespace-nowrap transition-colors w- " + (selected && "bg-emerald-50")}>
                <div className="flex flex-wrap gap-2">
                    {coachee?.cohorts &&
                        coachee.cohorts.length > 0 &&
                        coachee.cohorts.map((cohort, index) => (
                            <Tag
                                key={index}
                                status={cohort}
                                backgroundColour={selected ? "bg-emerald-100" : "bg-slate-100"}
                                textColour={selected ? "text-emerald-600" : "text-slate-500"}
                            />
                        ))}
                </div>
            </td>
        </tr>
    );
}
