import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import BarChart from "../../Charts/CustomCharts/BarChart";

export default function GraphicalSummary(props) {
    const { score, testId, summaryRef, detailedReportRef, recommendationsRef } = props;

    return (
        <div className="flex flex-col gap-8" data-testid={testId && testId}>
            <div ref={summaryRef} className="flex flex-col gap-4">
                <div data-testid="summarySection" className="text-primary-600 font-medium">
                    Summary
                </div>
                {!testId && (
                    <div className="flex flex-col gap-8">
                        <div
                            className={"flex flex-col gap-6 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                        >
                            <div className="flex flex-col gap-4">
                                <div className="text-secondary-600 font-medium ">Overall score</div>
                            </div>
                            <BarChart
                                data={[
                                    {
                                        name: "Overall",
                                        category: "Overall",
                                        percentScore: score.jobReadiness.reportFields.overallScore,
                                    },
                                    {
                                        name: "LLN",
                                        category: "LLN",
                                        percentScore:
                                            score.jobReadiness.reportFields.languageLiteracyNumeracyPercentage,
                                    },
                                    {
                                        name: "Personal Circumstances",
                                        category: "Personal Circ.",
                                        percentScore: score.jobReadiness.reportFields.personalCircumstancesPercentage,
                                    },
                                    {
                                        name: "Education & employment",
                                        category: "Education & empl.",
                                        percentScore: score.jobReadiness.reportFields.educationAndEmploymentPercentage,
                                    },
                                    {
                                        name: "Job Search Habits, Knowledge, Resources, Confidence, Motivation",
                                        category: "Job Search Habits",
                                        percentScore: score.jobReadiness.reportFields.jobSearchHabitsPercentage,
                                    },
                                ]}
                                percentageAxisLabels={{
                                    first: "0%",
                                    second: "25%",
                                    third: "50%",
                                    fourth: "75%",
                                    fifth: "100%",
                                }}
                                colourPalette={[
                                    {
                                        name: "purple",
                                        backgroundColour: "bg-purple-400",
                                        textColour: "text-purple-950",
                                    },
                                    {
                                        name: "indigo",
                                        backgroundColour: "bg-indigo-400",
                                        textColour: "text-indigo-950",
                                    },
                                    {
                                        name: "sky",
                                        backgroundColour: "bg-sky-400",
                                        textColour: "text-sky-950",
                                    },
                                    {
                                        name: "teal",
                                        backgroundColour: "bg-teal-400",
                                        textColour: "text-teal-950",
                                    },
                                    {
                                        name: "lime",
                                        backgroundColour: "bg-lime-400",
                                        textColour: "text-lime-950",
                                    },
                                ]}
                                percentage
                                percentageAxisMargin
                                noTrendLegend
                                // alwaysDisplayFilterDropdown
                            />
                            <div
                                className={
                                    "flex flex-row gap-2 p-2 rounded-xl bg-slate-50 border border-slate-100 shadow-sm " +
                                    "text-slate-500 print:hidden"
                                }
                            >
                                <span className={"font-title text-sm"}>
                                    {score.jobReadiness.reportFields.overallDescription}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div ref={detailedReportRef} className="flex flex-col gap-4">
                <div data-testid="detailedSummarySection" className="text-secondary-600 font-medium">
                    Detailed report
                </div>
                {!testId && (
                    <div className="flex flex-col gap-4">
                        <div
                            className={"flex flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                        >
                            <div className="flex flex-col gap-4">
                                <div className="text-indigo-600 font-medium ">Language, Literacy and Numeracy</div>
                            </div>
                            <BarChart
                                data={[
                                    {
                                        name: "Language, Literacy and Numeracy",
                                        category: "LLN",
                                        percentScore:
                                            score.jobReadiness.reportFields.languageLiteracyNumeracyPercentage,
                                    },
                                ]}
                                percentageAxisLabels={{
                                    first: "0%",
                                    second: "25%",
                                    third: "50%",
                                    fourth: "75%",
                                    fifth: "100%",
                                }}
                                colourPalette={[
                                    {
                                        name: "indigo",
                                        backgroundColour: "bg-indigo-400",
                                        textColour: "text-indigo-950",
                                    },
                                ]}
                                percentage
                                percentageAxisMargin
                                noTrendLegend
                                alwaysDisplayFilterDropdown
                                subtitle={
                                    "This scale looks at people’s confidence in their levels of literacy and numeracy and ability to engage effectively in the workplace using these skills."
                                }
                            />
                            <div
                                className={
                                    "flex flex-row gap-2 p-2 rounded-xl bg-slate-50 border border-slate-100 shadow-sm " +
                                    "text-slate-500 print:hidden"
                                }
                            >
                                <span className={"font-title text-sm"}>
                                    {score.jobReadiness.reportFields.languageLiteracyNumeracyDescription}
                                </span>
                            </div>
                        </div>
                        <div
                            className={"flex flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                        >
                            <div className="flex flex-col gap-4">
                                <div className="text-sky-600 font-medium ">Personal Circumstances</div>
                            </div>
                            <BarChart
                                data={[
                                    {
                                        name: "Personal Circumstances",
                                        category: "Personal Circumstances",
                                        percentScore: score.jobReadiness.reportFields.personalCircumstancesPercentage,
                                    },
                                ]}
                                percentageAxisLabels={{
                                    first: "0%",
                                    second: "25%",
                                    third: "50%",
                                    fourth: "75%",
                                    fifth: "100%",
                                }}
                                colourPalette={[
                                    {
                                        name: "sky",
                                        backgroundColour: "bg-sky-400",
                                        textColour: "text-sky-950",
                                    },
                                ]}
                                percentage
                                percentageAxisMargin
                                noTrendLegend
                                alwaysDisplayFilterDropdown
                                subtitle="This scale examines what might be barriers or concerns that people have in terms of engagement with their unique personal circumstances that might hinder their ability to find and maintain employment."
                            />
                            <div
                                className={
                                    "flex flex-row gap-2 p-2 rounded-xl bg-slate-50 border border-slate-100 shadow-sm " +
                                    "text-slate-500 print:hidden"
                                }
                            >
                                <span className={"font-title text-sm"}>
                                    {score.jobReadiness.reportFields.personalCircumstancesDescription}
                                </span>
                            </div>
                        </div>
                        <div
                            className={"flex flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                        >
                            <div className="flex flex-col gap-4">
                                <div className="text-teal-600 font-medium ">Education & employment</div>
                            </div>
                            <BarChart
                                data={[
                                    {
                                        name: "Education & employment",
                                        category: "Education & employment",
                                        percentScore: score.jobReadiness.reportFields.educationAndEmploymentPercentage,
                                    },
                                ]}
                                percentageAxisLabels={{
                                    first: "0%",
                                    second: "25%",
                                    third: "50%",
                                    fourth: "75%",
                                    fifth: "100%",
                                }}
                                colourPalette={[
                                    {
                                        name: "teal",
                                        backgroundColour: "bg-teal-400",
                                        textColour: "text-teal-950",
                                    },
                                ]}
                                percentage
                                percentageAxisMargin
                                noTrendLegend
                                alwaysDisplayFilterDropdown
                                subtitle="This scale reflects the individual's knowledge, education levels and level of motivation to find and sustain employment as well as to develop the skills needed to be a valued employee."
                            />
                            <div
                                className={
                                    "flex flex-row gap-2 p-2 rounded-xl bg-slate-50 border border-slate-100 shadow-sm " +
                                    "text-slate-500 print:hidden"
                                }
                            >
                                <span className={"font-title text-sm"}>
                                    {score.jobReadiness.reportFields.educationAndEmploymentDescription}
                                </span>
                            </div>
                        </div>
                        <div
                            className={"flex flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border border-slate-100"}
                        >
                            <div className="flex flex-col gap-4">
                                <div className="text-lime-600 font-medium ">
                                    Job Search Habits, Knowledge, Resources, Confidence, Motivation
                                </div>
                            </div>
                            <BarChart
                                data={[
                                    {
                                        name: "Job Search Habits, Knowledge, Resources, Confidence, Motivation",
                                        category: "Job Search Habits, Knowledge, Resources, Confidence, Motivation",
                                        percentScore: score.jobReadiness.reportFields.jobSearchHabitsPercentage,
                                    },
                                ]}
                                percentageAxisLabels={{
                                    first: "0%",
                                    second: "25%",
                                    third: "50%",
                                    fourth: "75%",
                                    fifth: "100%",
                                }}
                                colourPalette={[
                                    {
                                        name: "lime",
                                        backgroundColour: "bg-lime-400",
                                        textColour: "text-lime-950",
                                    },
                                ]}
                                percentage
                                percentageAxisMargin
                                noTrendLegend
                                alwaysDisplayFilterDropdown
                                subtitle="This scale examines an individual’s ability to effectively and efficiently search for jobs that suit their capability."
                            />
                            <div
                                className={
                                    "flex flex-row gap-2 p-2 rounded-xl bg-slate-50 border border-slate-100 shadow-sm " +
                                    "text-slate-500 print:hidden"
                                }
                            >
                                <span className={"font-title text-sm"}>
                                    {score.jobReadiness.reportFields.jobSearchHabitsDescription}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div ref={recommendationsRef} className="flex flex-col gap-4">
                <div
                    className={
                        "p-4 print:p-0 rounded-xl bg-emerald-50 shadow-sm shadow-emerald-300/50 " +
                        " border border-emerald-100 col-span-2 flex flex-col gap-4 text-emerald-600"
                    }
                >
                    <div className="flex flex-col gap-2">
                        <div data-testid="recommendationsSection" className=" font-medium">
                            Recommendations
                        </div>
                        {score.jobReadiness.reportFields.recommendations ? (
                            <div className="grid grid-cols-2  gap-4">
                                {score.jobReadiness.reportFields.recommendations.split("|").map((paragraph, index) => (
                                    <span key={index} className="flex flex-row items-center gap-2 text-sm">
                                        <CheckBadgeIcon className="h-4 w-4 shrink-0" /> {paragraph}
                                    </span>
                                ))}
                            </div>
                        ) : (
                            <span className="italic text-sm ">No recommendations have been added</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
