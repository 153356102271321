import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../SharedComponents/Button";

import { BUTTON_VARIANT_TYPES } from "../../../utils/constants";
import { ReactComponent as ErrorIllustration } from "../../../assets/error.svg";

import { Context } from "../../../context/Context";
import ErrorPage from "../../SharedComponents/ErrorPage";

export default function SomethingHasGoneWrong() {
    const context = useContext(Context);
    const navigate = useNavigate();

    const goToHome = async () => {
        navigate("/");
    };

    const goToPreviousPage = async () => {
        navigate(-1);
    };

    const renew = async () => {
        navigate("/renew");
    };

    return (
        <ErrorPage
            title={"Something has gone wrong on our end"}
            subtitle={
                <div>
                    Please refresh the page or{" "}
                    <a className="text-primary-600 font-medium" href="mailto:techsupport@psychpress.com.au">
                        contact us{" "}
                    </a>
                    if this issue persists.
                </div>
            }
            buttons={
                <>
                    <Button text="Previous page" onClick={goToPreviousPage} colour={BUTTON_VARIANT_TYPES.GRAY} />
                    {context.loggedIn ? (
                        <Button text="Home" onClick={goToHome} colour={BUTTON_VARIANT_TYPES.PRIMARY} />
                    ) : (
                        <Button
                            text="Go back to Psych Press Portal"
                            onClick={renew}
                            colour={BUTTON_VARIANT_TYPES.SECONDARY}
                        />
                    )}
                </>
            }
            illustration={<ErrorIllustration />}
        />
    );
}
