import { useEffect, useState } from "react";
import { createResourceRating, updateResourceRating } from "../../utils/api";
import { ALERT_TYPE } from "./Alert";

import InteractiveStar from "./InteractiveStar";

export default function StarRating(props) {
    const {
        readonly,
        starsRating,
        setOwnRating,
        resourceIdentifier,
        loadingStars,
        setAlert,
        setAlertType,
        updateItemStatus,
        handleRatingExternally,
        fetchAllResourceRatings,
    } = props;
    
    const [rating, setRating] = useState(starsRating);
    const [starHoverRating, setStarHoverRating] = useState(0);

    const handleStarRatingClick = async (ratingValue) => {
        if (!readonly) {
            try {
                !handleRatingExternally &&
                    (starsRating === 0
                        ? await createResourceRating(ratingValue, resourceIdentifier)
                        : await updateResourceRating(ratingValue, resourceIdentifier));

                setRating(ratingValue);
                updateItemStatus && updateItemStatus();
                setOwnRating(ratingValue);
                fetchAllResourceRatings && fetchAllResourceRatings();

                !handleRatingExternally && setAlertType(ALERT_TYPE.SUCCESS);
                !handleRatingExternally && setAlert("Successfully updated resource rating");
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
        }
    };

    const handleStarHoverRating = async (hover, ratingValue) => {
        if (!readonly) {
            if (hover && !readonly) {
                setStarHoverRating(ratingValue);
            } else {
                setStarHoverRating(0);
            }
        }
    };

    useEffect(() => {
        setRating(starsRating);
    }, [starsRating]);

    return (
        <>
            {!loadingStars ? (
                <div className={"flex"}>
                    <InteractiveStar
                        readonly={readonly}
                        handleStarRatingClick={handleStarRatingClick}
                        handleStarHoverRating={handleStarHoverRating}
                        updateItemStatus={updateItemStatus}
                        number={1}
                        rating={rating}
                        starHoverRating={starHoverRating}
                    />
                    <InteractiveStar
                        readonly={readonly}
                        handleStarRatingClick={handleStarRatingClick}
                        handleStarHoverRating={handleStarHoverRating}
                        updateItemStatus={updateItemStatus}
                        number={2}
                        rating={rating}
                        starHoverRating={starHoverRating}
                    />
                    <InteractiveStar
                        readonly={readonly}
                        handleStarRatingClick={handleStarRatingClick}
                        handleStarHoverRating={handleStarHoverRating}
                        updateItemStatus={updateItemStatus}
                        number={3}
                        rating={rating}
                        starHoverRating={starHoverRating}
                    />
                    <InteractiveStar
                        readonly={readonly}
                        handleStarRatingClick={handleStarRatingClick}
                        handleStarHoverRating={handleStarHoverRating}
                        updateItemStatus={updateItemStatus}
                        number={4}
                        rating={rating}
                        starHoverRating={starHoverRating}
                    />
                    <InteractiveStar
                        readonly={readonly}
                        handleStarRatingClick={handleStarRatingClick}
                        handleStarHoverRating={handleStarHoverRating}
                        updateItemStatus={updateItemStatus}
                        number={5}
                        rating={rating}
                        starHoverRating={starHoverRating}
                    />
                </div>
            ) : (
                <div className="flex animate-pulse">
                    <InteractiveStar />
                    <InteractiveStar />
                    <InteractiveStar />
                    <InteractiveStar />
                    <InteractiveStar />
                </div>
            )}
        </>
    );
}
