export const sampleCapabilityProfileSocialFuturesScore = {
    reportName: "Capability Profile",
    reportLevelNames: ["Intermediate", "Adept", "Advanced", "Highly Advanced"],
    reportDescription: "",
    userFirstName: "Fabio",
    userLastName: "Monsalve",
    snapshot: [
        "Every individual has a different combination of Human capabilities, it's what makes you, you! Comparing your self-assessed current capability and mindset will help to explain why some activities you find easy and enjoyable while others are more challenging and require more effort and focus to complete.",
    ],
    understandingYourCapabilityProfile: {
        description: [
            "Social Futures aspires to enhance the experience of its staff and communities by fostering an environment where people thrive, create deeper connections with each other and meaning in their work.",
            "The Human Capabilities are the markers of a person’s enduring attributes we know, from 30 years of research, make each of us unique. Your Capability Profile helps you understand your current capability and mindset against the Human Capability Framework providing you with rich insights to support your development and growth.",
            "There is no “ideal” profile, results are intended to provide deeper understanding and insights to allow you to harness your innate strengths and potential - understanding what you can do today and what could be possible for you in the future.",
            "The Capability Profile by design does not, consider your experience, training and qualifications, and other environmental factors that will impact your development and career. In the context of work, your profile results should be considered in the context of other available and relevant information.",
            "Once you have reviewed your results the Capability Profile will allow for natural strengths to be surfaced and directed towards activities and career opportunities that best harness potential. It will create opportunity to have more robust career discussions in Support Sessions and provide a richer experience for staff to undertake such an activity which is usually reserved for senior leaders.",
        ],
    },
    harnessingYourResults: {
        title: "Harnessing your results",
        description: [
            "As well as providing a deeper understanding of yourself, your Capability Profile provides tangible actions and suggestions for development to activate your capabilities as part of your day to day work.",
            "**Mindset:** refers to an individual's natural disposition, encompassing the traits and abilities that influence attitudes, motivations, and thought processes. It shapes how a person engages, acts, or leads. Mindsets are foundational to both personality and behaviour.",
            "**Self-assessed Current Capability:** refers to a person's own belief of their current capability – where they believe they are performing now through demonstrated skills, knowledge and action.",
        ],
    },
    capabilityLevels: {
        title: "The Capability Levels",
        description:
            "Capability levels outline the expected degree of autonomy, responsibility, and complexity and individual would demonstrate at each stage of development.",
        tableHeaders: ["Level", "Individuals who display behaviours at the following levels are likely to:"],
        tableRows: [
            {
                title: "Intermediate",
                points: [
                    "Understand and explain to others, rules, procedures, and the theory behind why something is important",
                    "Plan work so that you can successfully complete what you have been asked to do",
                    "Exercise personal responsibility",
                    "Work with others to find ways to overcome challenges",
                ],
            },
            {
                title: "Adept",
                points: [
                    "Understand and advise others how to improve existing rules and processes",
                    "Take accountability for meeting planned objectives",
                    "Work in a flexible manner, collaborating with others when appropriate, to identify ways to overcome barriers to success",
                ],
            },
            {
                title: "Advanced",
                points: [
                    "Work beyond your own area of expertise to extend existing rules, procedures and theoretical knowledge",
                    "Hold accountability for outcomes that have a wider impact than your immediate sphere of influence, for example for the entire organisation or in the community",
                    "Operate in a highly effective way, in both familiar situations as well as those that are new, complex, or challenging",
                ],
            },
            {
                title: "Highly Advanced",
                points: [
                    "Make significant contribution to challenge or advance how existing rules and processes will meet future needs; Have defined accountability for outcomes that have long-term impact in the organisation or community",
                    "Use your knowledge to help people make decisions about things that will arise in the future",
                    "Use your experience and deep understanding to help others navigate unfamiliar and challenging situations",
                ],
            },
        ],
    },
    capabilities: [
        {
            name: "Adaptive Mindset (Display Resilience and Courage, Manage Self)",
            title: "Adaptive Mindset",
            version: "",
            description: [
                "**Definition:** Able to be open and honest, prepared to express your views and willing to accept and commit to change.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Display Resilience and Courage is a Capability that looks forward to the needs of the future workforce. Rather than just taking a traditional focus on what a person does, it examines how they think. This is because modern organisations need employees and contractors who are more self-aware, curious, resilient, persistent, and who have the 'grit' to keep going when things get tough or when continual change creates uncertainty. This is at the heart of developing an adaptive mindset.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Flexibility",
                    title: "Flexibility",
                    description: "",
                    category: "Adaptive Mindset",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Growth Focus",
                    title: "Growth Focus",
                    description: "",
                    category: "Adaptive Mindset",
                    rawScore: 24,
                    componentItemCount: 5,
                    mean: 4.8,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Positive Outlook",
                    title: "Positive Outlook",
                    description: "",
                    category: "Adaptive Mindset",
                    rawScore: 24,
                    componentItemCount: 5,
                    mean: 4.8,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 14.2,
            mean: 4.733333333333333,
            mindsetScore: 99,
            selfAssessmentScore: 79,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Advanced level believes they are able to: ",
                points: [
                    "Adjust their style and approach to promote team optimism even when dealing with setbacks",
                    "Identify ways to grow team mindsets and learning to confront challenges",
                    "Work with others to adapt new ideas, practices or technologies to their context",
                    "Identify strategies to ensure team performance is maintained when confronted with changing goals or priorities",
                    "Monitor priorities and rebalance resources to continually achieve team outcomes",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who display resilience, courage and are able to manage self tend to:",
                points: [
                    "Be a systems thinker",
                    "Be able to engage with others to exchange ideas, information or thoughts",
                    "Deal well with ambiguity and complexity",
                    "Embrace challenges and new situations",
                    "Not procrastinate",
                    "Actively seek new experiences and learning opportunities",
                    "Envision what is possible and seek strategic opportunities",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take the lead on transformative change processes and projects at the organisational level, focussing on inspiring others to embrace change.",
                            "Implement a program within your organisation focused on adaptive leadership and resilience.",
                            "Engage in research projects or think-tank teams focusing on innovative technological advancements and emerging practices.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek coaching from a seasoned leader who has successfully led transformative change initiatives at the organisational level.",
                            "Join a peer coaching network of seasoned leaders to exchange insights, best practices, and strategies for overcoming obstacles and inspiring teams to embrace change.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue a certification or advanced coursework in change leadership, organisational transformation or agile practices.",
                            "Enrol in a masterclass on building resilience and inspiring positive mindsets within organisations.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Agile & Innovative (additional to SF)",
            title: "Agile and Innovative",
            version: "",
            description: [
                "**Definition:** Able to respond quickly to challenges and opportunities. This includes being curious, suspending criticism as a default, asking questions to understand, examining assumptions, taking calculated risks, and experimenting.",
                "**Your results indicate:** this is a priority capability for growth. You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "The Agile & Innovative Capability is very much a Capability required in the future workforce. It supports individuals who will be their own ‘business’, who are increasingly undertaking work in a gig economy, who work on contract for multiple employers, or who undertake freelance or project roles. It is also a Capability that many successful entrepreneurs display.",
                "A person obtaining the Agile & Innovative Capability may not necessarily be the most creative thinker. However, they can challenge current thinking and practice. They can work with others to champion innovative ideas or adaptive solutions. They can embrace new ways of thinking, listen well, imagine, and envisage opportunities, provide the energy to explore new ideas, and display entrepreneurial behaviours that others admire.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Curiosity",
                    title: "Curiosity",
                    description: "",
                    category: "Agile and Innovative",
                    rawScore: 25,
                    componentItemCount: 6,
                    mean: 4.166666666666667,
                    percentile: 84,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Exploration",
                    title: "Exploration",
                    description: "",
                    category: "Agile and Innovative",
                    rawScore: 22,
                    componentItemCount: 5,
                    mean: 4.4,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 8.566666666666666,
            mean: 4.283333333333333,
            mindsetScore: 97,
            selfAssessmentScore: 25,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Intermediate level believes they are able to: ",
                points: [
                    "Take a strategic, end-to-end view of where and how the organisation creates value",
                    "Monitor the external environment to diagnose where external factors will impact major innovations or strategies",
                    "Encourage and lead the search for disruptive innovations and business models that enhance organisational success",
                    "Sponsor effort to seize opportunities to adapt, improve, or innovate",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Agile & Innovative mindset tend to:",
                points: [
                    "Seek to challenge traditional ideas or thinking",
                    "See things in a new or different perspective",
                    "Imagine and envisage opportunities",
                    "Take the initiative and energy to explore new ideas",
                    "Passionately explore what is possible",
                    "Show the ability to look over the horizon for him/herself and the industry",
                    "Appreciate trends or major factors impacting the organisation",
                    "Deal with ambiguity and complexity",
                    "Continuously seek improvement without prompting",
                    "Self-believe and motivate",
                    "Acknowledge and reward ideas and effort of others",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Join cross-functional teams working on projects that involve complex problem-solving and innovation.",
                            "Participate in job rotation or shadowing to understand different roles and their challenges, within your organisation.",
                            "Identify processes or workflows within your current role that could be simplified or improved and work with your team to implement these improvements.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek a mentor who is known for their innovative thinking to develop your questioning and creative thinking abilities.",
                            "Request feedback from colleagues or mentors on your ability to ask challenging questions and seek opportunities for innovation.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Attend workshops or training sessions that focus on innovation, creative problem-solving, and questioning techniques.",
                            "Enrol in foundational courses that emphasise a customer-centric approach to innovation and improving the customer experience, or adaptive & agile thinking.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Collaboration (Work Collaboratively)",
            title: "Collaboration",
            version: "",
            description: [
                "**Definition:** Able to collaborate with other and value their contribution. Able to work with all types of people, contribute to teamwork and build relationships and networks across a range of people or groups. Collaboration emphasises more than just working with others or teamwork. It encompasses cooperation, building relationships between and across groups and building formal and informal networks.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Collaboration encompasses the interaction between the members within a team or across teams or informal groups. The individuals involved may have diverse values, purposes, locations, or cultures but agree to work together to achieve a shared goal or produce a mutually beneficial output.",
                "At the centre of modern society lies the need to encourage the rapid flow of ideas, diffuse innovations, and interact in globalised markets. This means collaboration increasingly involves the use of technology to link people and organisations. Groups of highly talented people can build relationships across time zones and locations. Moreover, powered by a new array of video, chat, smart and mobile devices, group messaging apps, or secure cloud-based file sharing, project management and other collaborative applications, everyone can contribute in shared spaces.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Considerate",
                    title: "Considerate",
                    description: "",
                    category: "Collaboration",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Cooperation",
                    title: "Cooperation",
                    description: "",
                    category: "Collaboration",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 89,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Respect",
                    title: "Respect",
                    description: "",
                    category: "Collaboration",
                    rawScore: 24,
                    componentItemCount: 5,
                    mean: 4.8,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 14,
            mean: 4.666666666666667,
            mindsetScore: 99,
            selfAssessmentScore: 71,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Advanced level believes they are able to: ",
                points: [
                    "Use personal and professional credibility and trustworthiness to form important relationships",
                    "Manage stakeholder relationships and risk",
                    "Navigate organisational politics",
                    "Promote collaboration and teamwork where everyone treats each other with respect",
                    "Work effectively with professional peers and stakeholders to optimise operational performance",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who work collaboratively tend to:",
                points: [
                    "Be interested in people",
                    "Feel comfortable engaging with others and building purposeful relationships",
                    "Make others feel at ease",
                    "Employ a range of different influencing styles effectively",
                    "Value having long-term close relationships with people",
                    "Seek to ensure mutual benefit from relationships",
                    "Initiate and reach out to people",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take on roles where building purposeful, cross-functional collaborative relationships is essential for enhancing the organisation's strategic capacity and intelligence.",
                            "Lead initiatives involving strong influencing and negotiation with senior/ Executive/ Board decision-makers and stakeholders.",
                            "Lead and participate in projects and cross-functional teams focused on generating innovative ideas and diverse perspectives on strategic challenges.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Collaborate with peers who are aspiring to Mastery level of collaboration skills. Establish a peer coaching group to share advanced insights, provide constructive feedback, and collectively enhance your strategic collaboration abilities.",
                            "Seek mentorship from a colleague known for their excellence in strategic collaboration, forming inclusive relationships, adapting communication to diverse audiences, or mastering difficult negotiations.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Undertake post-graduate coursework focused on advanced leadership, organisational theory and negotiation that include strategies and techniques for mastering difficult negotiations, influencing senior decision-makers, and forming strategic alliances.",
                            "Enrol in workshops or seminars that focus on advanced communication strategies for influencing and role-modelling diverse or strategic collaboration.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Communication (Communicate Effectively and Influence and Negotiation)",
            title: "Communication",
            version: "",
            description: [
                "**Definition:** Able to communicate clearly, actively list to other and respond with respect.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Communicate effectively is about working beyond the interpersonal scale to manage the vast amounts of information conveyed to you daily. It is about engaging meaningfully with others in a physical and virtual setting and ensuring that how, what and when we communicate is sensitive to the needs of the audience.",
                "The emphasis of this Capability is on clarity of meaning and impact. It is about being purposeful: solving problems, collaborating, creating a dialogue to better understand another person’s feelings, learning, influencing others, or exposing yourself to others’ beliefs.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Perspective Taking",
                    title: "Perspective Taking",
                    description: "",
                    category: "Communication",
                    rawScore: 18,
                    componentItemCount: 4,
                    mean: 4.5,
                    percentile: 91,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Persuasion",
                    title: "Persuasion",
                    description: "",
                    category: "Communication",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Social",
                    title: "Social",
                    description: "",
                    category: "Communication",
                    rawScore: 18,
                    componentItemCount: 4,
                    mean: 4.5,
                    percentile: 98,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 13.6,
            mean: 4.533333333333333,
            mindsetScore: 99,
            selfAssessmentScore: 75,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Advanced level believes they are able to: ",
                points: [
                    "Play an active role in facilitating productive interactions and removing barriers that limit input by others",
                    "Prepare compelling presentations to influence others",
                    "Coordinate the management and sharing of information and knowledge across groups",
                    "Establish mechanisms to analyse, evaluate and report information",
                    "Use a variety of techniques and methods to effectively conduct difficult conversations",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who communicate effectively and are able to influence and negotiate tend to:",
                points: [
                    "Find others and actively seek their opinion and ideas",
                    "Have a natural ability to engage with others and influence their thinking and practice",
                    "Appreciate and seek the opinions and viewpoints of others",
                    "Encourage others to present their views and feedback",
                    "Listen and seek to understand what other people mean",
                    "Craft communication to the needs of the audience",
                    "Feel confident dealing with complex issues or topics",
                    "Be an engaging public speaker",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take a lead role in designing and implementing systems that facilitate knowledge transfer among teams and departments.",
                            "Represent your organisation with authority and credibility by participating in high-profile conferences, industry events, or public speaking engagements.",
                            "Collaborate with your organisation's communication department or leaders to develop communication plans aimed at engaging both internal and external audiences.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in mentorship or coaching programs led by seasoned communication experts, particularly those skilled in executive-level communication and public representation.",
                            "Establish a peer review group to evaluate your strategic communication plans and strategies for engaging audiences.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Enrol in advanced courses or workshops that delve into communication strategy development, creating comprehensive communication plans and reinforcing organisational values through effective messaging.",
                            "Attend workshops dedicated to public speaking, authority building, credibility enhancement or mastering complex negotiations.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Creativity (additional to SF)",
            title: "Creativity",
            version: "",
            description: [
                "**Definition:** Able to actively contribute to creative works, ideas, or novel solutions.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Creativity was always thought to be intensely personal and embedded in a combination of individual insights or a unique set of artistic or intellectual traits. But in a world where innovation is transforming how we work and our business success, creativity is now understood as a powerful Capability that most organisations and communities need.",
                "Creativity places emphasis on the ability to perceive and act in a way that helps individuals make connections, generate insights, and imagine the world in new ways. This is heavily cognitive in emphasis. While the demonstration of Capability may be a novel solution, design or idea, the ability to engage in the experience and adopt a mindset of looking ‘outside the box’ underpins the attainment of this Human Capability.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Imagination",
                    title: "Imagination",
                    description: "",
                    category: "Creativity",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Inquisitive",
                    title: "Inquisitive",
                    description: "",
                    category: "Creativity",
                    rawScore: 22,
                    componentItemCount: 5,
                    mean: 4.4,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 9,
            mean: 4.5,
            mindsetScore: 99,
            selfAssessmentScore: 75,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Advanced level believes they are able to: ",
                points: [
                    "Identify and sponsor innovative effort where it can generate benefit within a professional, project or operational context",
                    "Implement systematic approaches to the conduct of creativity",
                    "Establish mechanisms for managing and reviewing creative ideas or designs",
                    "Coordinate and cultivate creative process to connect or bring together diverse perspectives or ideas",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Creativity mindset tend to:",
                points: [
                    "Use imagination to see things differently",
                    "Show flexibility in thinking and approach",
                    "Be an original thinker and can think ‘outside the box’",
                    "Embrace new ideas or practices",
                    "Harness personal insights and intuition",
                    "Imagine what can be and seek to explore outside the existing or known space",
                    "Encourage others to share creative inspirations and ideas",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Lead and oversee transformative projects that involve thinking and research into new designs, concepts, or innovative ways of thinking.",
                            "Establish and manage a workplace culture that encourages and rewards creativity and original thinking.",
                            "Assemble cross-functional teams for thinking and research into new designs, concepts or ways of thinking.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek coaching from a seasoned creative leader or innovation expert to refine your strategies for championing a culture of creativity.",
                            "Receive mentoring from someone with experience in acknowledging and rewarding initiative, creativity, and original thinking.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue postgraduate coursework or executive programs on fostering innovation and creative leadership within organisations and the broader system.",
                            "Complete a program focused on systems and strategies for acknowledging and rewarding creativity, fostering a culture of innovation within your organisation.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Critical Thinking (additional to SF)",
            title: "Critical Thinking",
            version: "",
            description: [
                "**Definition:** Able to use a range of tools or methods to critically examine and assess existing information, thinking, assumptions and issues to present well-reasoned insights or to make judgements.",
                "**Your results indicate:** this is a priority capability for growth. You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Critical thinking is a unique human skill. It is mostly defined to include concepts such as analysing information, applying a logical inquiry process, and appraising evidence to make sound judgments. However, Critical Thinking is more than analytical thinking or problem solving. Critical Thinking is about a person’s intellectual horsepower. It involves their ability to engage the brain to not only solve a problem or make a decision, but to question alternatives, see opportunities, understand the context and emotions involved, and reach conclusions that draw on experience or knowledge. In a dynamic, increasingly volatile world of work, it is easy to appreciate why employers value people with this Capability. Critical thinking has applications in a variety of settings in life, learning, or work. It may occur during interpersonal or social conversations, reading, study, research, or when we make choices based on an intuitive ‘gut feel’.",
                "Research investigations on the capabilities for the future workforce consistently rate Critical Thinking in the top three human capabilities. It is valued highly because it allows an individual to continually make informed judgements and evaluate knowledge, systematically analyse alternatives, make informed decisions, and critically review if their actions have achieved the required outcomes.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Objectivity",
                    title: "Objectivity",
                    description: "",
                    category: "Critical Thinking",
                    rawScore: 18,
                    componentItemCount: 4,
                    mean: 4.5,
                    percentile: 98,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Reasoning",
                    title: "Reasoning",
                    description: "",
                    category: "Critical Thinking",
                    rawScore: 26,
                    componentItemCount: 6,
                    mean: 4.333333333333333,
                    percentile: 94,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 8.833333333333332,
            mean: 4.416666666666666,
            mindsetScore: 98,
            selfAssessmentScore: 60,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Adept level believes they are able to: ",
                points: [
                    "Draw on experience and expertise to exercise professional judgment and make sound decisions",
                    "Make evaluative judgments about people, events, organisations, and processes",
                    "Appreciate the strategic environment and the emotional drivers influencing how key decision-makers will respond to new or challenging situations",
                    "Defend and advocate for evidence-based judgments in a logical and reasoned manner",
                    "Evaluate breakthrough thinking or insights for wider adoption",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Critical Thinking mindset tend to:",
                points: [
                    "Apply logic and reasoning to make judgments",
                    "Suggest solutions or actions that are context appropriate and achievable",
                    "Move from decisions to action",
                    "Make decisions sensitive to consequences",
                    "Like to consider all perspectives",
                    "Draw on experience to improve judgments",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take on leadership roles in projects or teams where making sound decisions and evaluative judgments are critical.",
                            "Collaborate with colleagues on cross-functional projects that involve assessing innovative approaches and deciding on their wider adoption.",
                            "Engage in real-world scenarios that require you to appreciate the strategic environment and emotional drivers influencing key decision makers.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Find a mentor or coach with extensive experience in exercising professional judgment, defending evidence-based judgments, and making reasoned decisions.",
                            "Participate in peer reviews or peer coaching circles where you and your colleagues provide constructive feedback on each other's evaluative judgments and the use of evidence-based reasoning.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Explore advanced courses or workshops on critical thinking and decision-making to deepen your understanding of evaluative judgment and evidence-based reasoning.",
                            "Attend seminars, conferences, or webinars on topics related to strategic decision-making.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Customer Focused (Commit to Customer Service)",
            title: "Customer Focus",
            version: "",
            description: [
                "**Definition:** Able to provide customer centric services in line with organisational objectives to focus on customer requirements and act proactively to raise the customer experience.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Commit to customer service is a Capability that must be nurtured constantly. It requires an awareness of why it is always important to provide excellent service in a direct or indirect manner. At the higher levels of proficiency, it requires leaders to ensure customer focus occurs across the entire organisation. This will ensure staff have a sense of quality, standards will be based on customer perceptions, and Customer Experience becomes the number one driving force for how the business operates.",
                "The Commit to customer services Capability, by its very nature, involves active awareness of a customer’s needs. It requires a person to place themselves in the customer’s shoes. If you took the customer’s point of view, what would you want? How do we open a dialogue with customers to ensure we know what they want or where their expectations need to be shaped? What does a satisfied or dissatisfied customer look like? More importantly, how do we anticipate customer needs and continually argue for improving what we do in ways that make customers come back?",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Service Orientation",
                    title: "Service Orientation",
                    description: "",
                    category: "Customer Focus",
                    rawScore: 24,
                    componentItemCount: 5,
                    mean: 4.8,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Solutions Orientation",
                    title: "Solutions Orientation",
                    description: "",
                    category: "Customer Focus",
                    rawScore: 22,
                    componentItemCount: 5,
                    mean: 4.4,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Support Orientation",
                    title: "Support Orientation",
                    description: "",
                    category: "Customer Focus",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 94,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 13.799999999999999,
            mean: 4.6,
            mindsetScore: 99,
            selfAssessmentScore: 85,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Advanced level believes they are able to: ",
                points: [
                    "Manage operations to provide an exceptional customer experience",
                    "Design and deploy robust service strategy with key performance indicators for measuring the customer experience",
                    "Create a compelling value proposition",
                    "Design and develop products, services, and solutions that build enduring customer relationships",
                    "Advocate for customer needs and overcome internal obstacles to improving service and the customer experience",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who commit to customer service tend to:",
                points: [
                    "Have a strong and persistent focus on people/customers",
                    "Always seek to put the customer’s interests first",
                    "Be genuinely interested in a customer’s experience",
                    "Look for opportunities to help people",
                    "Care about others and satisfying their needs",
                    "Be friendly and easy to approach",
                    "Be personally pleased and happy when a person’s needs are satisfied",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take on leadership roles where you are accountable for improving the customer experience across the organisation.",
                            "Initiate strategic actions and lead organisational transformative efforts to improve the customer journey and experience.",
                            "Spend time coaching or mentoring emerging leaders on appropriate ways of monitoring and undertaking anticipatory action to enhance the customer experience.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in coaching with seasoned customer experience leader to help you explore how to think strategically, initiate actions to improve the customer experience, and design systems for enduring customer value.",
                            "Act as a mentor to aspiring leaders within your organisation, guiding them on how to hold others accountable for improving the customer experience and cultivating a culture that reinforces customer-centric values.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue advanced courses or executive education programs focused on customer experience leadership and strategy.",
                            "Collaborate with experts or academic institutions to participate in customer-centric research projects or case studies, to gain a comprehensive understanding of customer research, fostering innovation and in-depth knowledge of customer experience management.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Direction and Purpose (Deliver Results and Plan and Prioritise)",
            title: "Direction & Purpose",
            version: "",
            description: [
                "**Definition:** Able to plan, achieve priority outcomes and respond flexibly to changing circumstances to achieve results through efficient use of resources and a commitment to quality outcomes.",
                "**Your results indicate:** this is a priority capability for growth. You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Deliver results, Plan and Prioritise centres on translating plans that deliver an agreed direction into action. This involves providing inspiration, clarity and direction through inspiring commitment to a meaningful vision. It also requires focusing the team or teams on priorities, supporting change processes, formulating plans, and attaining organisational goals.",
                "Whether working for yourself or an employer, a person’s future workplace success will require an ability to be resourceful and to translate plans into action. Employers increasingly seek individuals able to initiate action and coordinate effort, especially where they can generate commitment from others to the organisation’s strategic direction and purpose.",
                "This requires not only personal commitment and initiative, it also necessitates the ability to overcome problems that affect goal attainment or the orientation of the workforce to agreed outcomes.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Accountable",
                    title: "Accountable",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 97,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Drive",
                    title: "Drive",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 18,
                    componentItemCount: 4,
                    mean: 4.5,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Execution",
                    title: "Execution",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 19,
                    componentItemCount: 4,
                    mean: 4.75,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Purposeful",
                    title: "Purposeful",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 18,
                    componentItemCount: 4,
                    mean: 4.5,
                    percentile: 97,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 18.35,
            mean: 4.5875,
            mindsetScore: 99,
            selfAssessmentScore: 50,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Adept level believes they are able to: ",
                points: [
                    "Develop strategic reporting and evaluation methodologies",
                    "Manage strategy and report on strategy execution and progress",
                    "Display a deep appreciation for the strategic opportunities and challenges facing the organisation",
                    "Convey the organisational direction and values positively to high-level decision-makers (e.g., Board, shareholders, government)",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who plan, prioritise and deliver results tend to:",
                points: [
                    "Have a good capacity to research and source quality strategic business intelligence",
                    "Be respected by peers and decision makers in other organisations as a conscientiousness and hard-working leader",
                    "Take a thorough approach to tasks even when faced with multiple competing demands",
                    "Be able to renegotiate outcomes or requirements that impinge upon quality/standard of the final result",
                    "Persevere when others quit",
                    "Be a strategic thinker",
                    "Secure executive level sponsorships and engagement",
                    "Redesign business practices/processes to deliver improved results",
                    "Have a strong work ethic and responsiveness to new demands",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Lead and manage strategic projects focused on the organisation's vision and purpose, focussing on conveying the organisational direction and values to executive level stakeholders.",
                            "Actively engage with key stakeholders, demonstrating a deep appreciation of the strategic opportunities and challenges.",
                            "Collaborate with other organisations or industry experts to explore innovative ways to promote a shared vision.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage with mentors who are seasoned strategic leaders and seek guidance on strategic reporting and evaluation methodologies and conveying the organisation's direction and values to high-level decision makers.",
                            "Participate in feedback sessions focused on your strategic communication and how you engage with high-level decision makers.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Participate in advanced strategic leadership courses to refine your skills in managing strategy, strategic reporting, and communicating direction and values to high-level decision makers.",
                            "Enrol in workshops on strategic thinking and evaluation methodologies.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Empathy (additional to SF)",
            title: "Empathy",
            version: "",
            description: [
                "**Definition:** Able to recognise and regulate their own emotions in any situation and is good at identifying and respecting the needs and feelings of other people.",
                "**Your results indicate:** this is a priority capability for growth. You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "The Human Capability of Empathy centres on a person’s ability to recognise and regulate their own emotions in any situation. Rather than the broader umbrella concepts of Emotional Intelligence (EQ) or Social Intelligence (SQ), an empathetic person is good at identifying and respecting the needs and feelings of other people. They are self-aware, can share their own feelings sensitively and can comprehend why other people or organisations act in a particular way.",
                "Empathy is a Human Capability highly valued in some job clusters or career pathways. Because it is based on contextual feelings and emotions, the Capability is almost robot-proof and difficult to automate. This is particularly so where human interactions are highest, for instance in service industries, health care, counselling and related work.",
                "Because the Empathy Capability tightly focuses on how individuals feel and interact with others, it will often be clustered with capabilities such as Communication, Collaboration, and Customer Focus.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Care",
                    title: "Care",
                    description: "",
                    category: "Empathy",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 97,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Sensitivity To Others",
                    title: "Sensitivity To Others",
                    description: "",
                    category: "Empathy",
                    rawScore: 27,
                    componentItemCount: 6,
                    mean: 4.5,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 9.1,
            mean: 4.55,
            mindsetScore: 98,
            selfAssessmentScore: 62,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Adept level believes they are able to: ",
                points: [
                    "Demonstrate commitment, sensitivity and responsiveness to the emotional needs of different cultures and people",
                    "Research and work with others to identify and understand the emotional dimensions when designing or providing products and services",
                    "Model and reinforce the organisation’s culture and values",
                    "Identify opportunities for engaging positively with different groups in the community",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who demonstrate empathy tend to:",
                points: [
                    "Be sensitive to the needs of others and know how to comfort them",
                    "Respond appropriately in emotionally charged or difficult situations",
                    "Seek to understand how they personally respond to people and situations",
                    "Be aware of own emotional responses and feelings",
                    "Have a strong sense of how conflict may accentuate certain emotions and behaviours",
                    "Be non-judgmental and respectful",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Demonstrate commitment, sensitivity, and responsiveness to the emotional needs of people from different cultures and backgrounds, actively seeking to understand their perspectives.",
                            "Collaborate with others to research and identify the emotional dimensions that influence product and service design to better meet the needs of diverse customers.",
                            "Model and reinforce your organisation's culture and values, consistently reflecting them in your actions and interactions.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek mentorship from leaders known for their strong cultural sensitivity and empathy to develop advanced emotional intelligence.",
                            "Establish a mentorship program in your organisation to help emerging leaders understand and navigate diverse emotional dimensions in the workplace.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue advanced courses in emotional intelligence and cultural sensitivity.",
                            "Attend workshops on customer and user experience design with a focus on understanding and addressing emotional needs from diverse groups.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Engagement and Coaching (Value Diversity)",
            title: "Engagement and Coaching",
            version: "",
            description: [
                "**Definition:** Able to show respect for diverse backgrounds, experiences and perspective and work in teams and reinforce a culture of engagement underpinning the shared purpose and values.  This includes teamwork, coaching others, promoting inclusion, building trust and empowering others to act.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Engagement & Coaching centres on engaging with others in culturally diverse settings with sensitivity and regard for a range of different perspectives, values, beliefs, and behaviours. This requires empathy, self-awareness, and an ability to share their own feelings sensitively and can comprehend why other people or organisations act in a particular way. This understanding is gained through positive interactions with people from diverse cultural backgrounds and perspectives, with different attitudes, beliefs, and values.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Empowerment",
                    title: "Empowerment",
                    description: "",
                    category: "Engagement and Coaching",
                    rawScore: 20,
                    componentItemCount: 4,
                    mean: 5,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Inclusive",
                    title: "Inclusive",
                    description: "",
                    category: "Engagement and Coaching",
                    rawScore: 27,
                    componentItemCount: 6,
                    mean: 4.5,
                    percentile: 94,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 9.5,
            mean: 4.75,
            mindsetScore: 99,
            selfAssessmentScore: 68,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Advanced level believes they are able to: ",
                points: [
                    "Establish systems and mechanisms to promote workforce participation",
                    "Vest the authority and resources necessary for employees to be empowered to act",
                    "Acknowledge and reward contributions from others",
                    "Mentor and develop others",
                    "Facilitate individual development planning and prioritisation",
                    "Engage with community and professional groups to enhance understanding of diverse cultural and social perspectives",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who value diversity tend to:",
                points: [
                    "Employ a range of different influencing styles effectively",
                    "Have strong negotiation and influencing skills",
                    "Embrace learning",
                    "Have a strong ability to empathise",
                    "Be an effective role model",
                    "Have a strong ability to appreciate and communicate sensitive to emotional and cultural factors",
                    "Appreciate and celebrates everyone’s contribution",
                    "Be an astute navigator of organisational politics",
                    "Be willing to coach and mentor others",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take the lead in addressing complex issues that affect high performance, demonstrating your ability to navigate challenging situations effectively.",
                            "Actively recruit, assemble and promote teams with diverse skillsets and backgrounds.",
                            "Engage directly with these groups to ensure all barriers are removed by establishing practices and processes to enable genuine belonging.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor and provide expert guidance to emerging leaders and team members, sharing your knowledge and experience to support their growth.",
                            "Implement 360-degree feedback processes throughout your organisation to ensure ongoing self-awareness and development.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue advanced leadership programs and certifications that focus on advanced coaching, mentoring, and team leadership. Ensure allyship is included.",
                            "Develop custom training programs for your organisation that set fulfilling development goals, fostering team capability and individual talent.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Ethics (Act with Integrity)",
            title: "Ethics",
            version: "",
            description: [
                "**Definition:** Able to be ethical and professional and in conformance with social and professional standards of ethical conduct.",
                "**Your results indicate:** this is a priority capability for growth. You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "In an increasingly global world of information, work, and social interaction, it is vitally important for people in work, learning, or life, to identify and encourage diverse perspectives, to respect different cultures and values, and to act responsibly and ethically.",
                "In professional and business situations ethics tends to be about applied ethics and moral judgments. This is distinct from the general field of ethics, which is concerned with studying its theoretical basis and how different cultures, societies, and groups delineate right from wrong.",
                "Acting with integrity is about who you are, how you behave, and your awareness of how decisions affect others.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Integrity",
                    title: "Integrity",
                    description: "",
                    category: "Ethics",
                    rawScore: 23,
                    componentItemCount: 5,
                    mean: 4.6,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Morality",
                    title: "Morality",
                    description: "",
                    category: "Ethics",
                    rawScore: 14,
                    componentItemCount: 6,
                    mean: 2.3333333333333335,
                    percentile: 33,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Responsibility",
                    title: "Responsibility",
                    description: "",
                    category: "Ethics",
                    rawScore: 30,
                    componentItemCount: 6,
                    mean: 5,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 11.933333333333334,
            mean: 3.977777777777778,
            mindsetScore: 89,
            selfAssessmentScore: 60,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Adept level believes they are able to: ",
                points: [
                    "Promote a culture of professionalism and integrity across the organisation",
                    "Build and embed a culture of compliance throughout the organisation",
                    "Sponsor the development and implementation of a Code of Conduct with specific guidance on professional and ethical standards and required behaviours",
                    "Establish and maintain ethical relationships with key external stakeholders including regulatory bodies",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who act with integrity tend to:",
                points: [
                    "Act according to their own ethics and conscience",
                    "Make commitments and stick to them",
                    "Be willing to follow behavioural rules and social expectations",
                    "Follow the rules and agreed standards of practice or behaviour",
                    "Seek to learn from mistakes of others and how they deal with ethical dilemmas",
                    "Conscientiously adhere to personal commitments or loyalty",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take a leadership role in promoting a culture of professionalism and integrity by planning, communicating and engaging support from teams for specific ethical practices.",
                            "Lead initiatives to build and embed a culture of compliance throughout the organisation.",
                            "Establish and maintain ethical relationships with key external stakeholders, including regulatory bodies, to demonstrate your commitment to ethical practices.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor junior employees and emerging leaders on how to promote a culture of integrity and compliance.",
                            "Engage in peer coaching with colleagues to share experiences and insights into building an ethical culture.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue advanced courses or certifications in ethics and corporate compliance.",
                            "Attend advanced workshops on stakeholder relationship management.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Leadership (Demonstrate Accountability)",
            title: "Leadership",
            version: "",
            description: [
                "**Definition:** Able to be responsible for own actions and adhere to legislation and policy and be proactive to address risk as well as lead people and processes, motivate others and build an environment of mutual trust and commitment to influence the outcomes required now and into the future.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Future leaders must have the capacity to communicate a vision that reaches further over the horizon, inspire, and grow their workforce, enhance the speed of transformation and, ultimately, advance the mutual interests of their organisation and the people they employ. Investing in development of leadership capabilities, therefore, ensures we can harness the human and technical capabilities that assure our collective capacity to successfully transform, grow, and meet future challenges.",
                "The capability recognises the positive benefits of leaders working with others in a collaborative, engaged way. Effective leadership can engage people to both perform better, and emotionally invest in a rapid transformation process. The Leadership Capability deliberately moves away from the 'top down' model of leadership to reinforce the participative, ‘side-by-side’ nature of leadership and the leader’s ability to deliver transformational practices at all levels of work and life.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Determination",
                    title: "Determination",
                    description: "",
                    category: "Leadership",
                    rawScore: 19,
                    componentItemCount: 4,
                    mean: 4.75,
                    percentile: 99,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Influential",
                    title: "Influential",
                    description: "",
                    category: "Leadership",
                    rawScore: 22,
                    componentItemCount: 5,
                    mean: 4.4,
                    percentile: 79,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Leadership",
                    title: "Leadership",
                    description: "",
                    category: "Leadership",
                    rawScore: 20,
                    componentItemCount: 5,
                    mean: 4,
                    percentile: 87,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 13.15,
            mean: 4.383333333333334,
            mindsetScore: 95,
            selfAssessmentScore: 75,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Advanced level believes they are able to: ",
                points: [
                    "Build a climate that encourages individuals to share ideas and question existing practices",
                    "Set a clear vision and sense of shared direction",
                    "Understand what motivates others to learn and share knowledge",
                    "Create an open, committed and trusting work environment",
                    "Identify and foster individual growth at all levels",
                    "Consistently set appropriate behaviour for themselves and their team",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who demonstrate accountability tend to:",
                points: [
                    "Show the ability to look over the horizon and identify compelling futures",
                    "Be highly self-aware",
                    "Have a strong career plan",
                    "Appreciate trends or major factors shaping technology, markets and strategic direction",
                    "Have a strong ability to inspire others",
                    "Show a high level of motivation towards achieving future goals",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Motivate and inspire others through a shared vision that has deep meaning and resonates with the organisation's mission and values.",
                            "Harness the full capability of your team to deliver on strategic goals and objectives, optimising their talents and resources.",
                            "Lead with a focus on long-term value creation and shareholder growth, prioritising sustainability over short-term gains.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor emerging leaders and senior executives on advanced leadership strategies for shaping strategy, driving growth, and building a shared vision.",
                            "Establish a peer coaching network of senior leaders to exchange insights and experiences in driving long-term value and sustainable growth.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue advanced executive education programs in strategic leadership, innovation, and sustainable growth.",
                            "Attend industry-specific conferences and seminars on leading for long-term value creation and shareholder growth.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Problem Solving & Data (Think and Solve Problems)",
            title: "Problem Solving and Data",
            version: "",
            description: [
                "**Definition:** Able to think, analyse and consider broader context to develop practical solutions, generate optimal alternatives, and provide recommendations.",
                "**Your results indicate:** this is a priority capability for growth. You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [
                "Think and Solve problems is consistently one of the top five ranked Human Capabilities required for future work. It integrates data fluency and the focus on data-driven decision making. Data-driven decision making is a process involving the use of data to remove individual subjectivity and opinion so as to weigh options and determine the most appropriate course of action.",
                "The Capability that spans all professions and types of work roles. It is highly valued by employers because it shows an individual can resolve issues or barriers to success. In a volatile, uncertain, complex, and ambiguous world of work, it is vital that teams have members who are strong at systematically identifying, analysing, and resolving real world problems.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Analysis",
                    title: "Analysis",
                    description: "",
                    category: "Problem Solving and Data",
                    rawScore: 22,
                    componentItemCount: 5,
                    mean: 4.4,
                    percentile: 97,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Approach",
                    title: "Approach",
                    description: "",
                    category: "Problem Solving and Data",
                    rawScore: 22,
                    componentItemCount: 5,
                    mean: 4.4,
                    percentile: 97,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 8.8,
            mean: 4.4,
            mindsetScore: 97,
            selfAssessmentScore: 62,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Adept level believes they are able to: ",
                points: [
                    "Identify multiple chains of complex cause and effect",
                    "Map out complex processes with multiple inputs and multiple outputs",
                    "Use data to predict problems and uncertainties in the environment that may affect current operations",
                    "Use data to classify and sort multidimensional problems into component parts",
                    "Harness data analytical techniques to successfully identify solutions and make recommendations",
                    "Use data to improve the organisation’s ability to predict or anticipate future problems or emerging risks",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People who think and solve problems tend to:",
                points: [
                    "Be able to focus on essential elements of a problem",
                    "Love to investigate and resolve problems",
                    "Evaluate and understand complex interrelationships impacting a problem",
                    "Reason logically and formulate clear options",
                    "Use data to objectively and scientifically judge and select a solution",
                    "Make linkages between seemingly unrelated but important issues/information",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Identify and consider risks in a calculated manner to guide decision-making, considering both short-term and medium-term impacts.",
                            "Rotate into a different area/team to learn ways of collecting, visualising, and analysing data and then identifying approaches that can be used in your area/next project.",
                            "Monitor internal or external data trends to proactively identify likely causes or consequences of decisions and anticipate potential problems.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Collaborate with experienced data analysts or data scientists to improve your data visualisation and analysis skills.",
                            "Seek mentorship from leaders who excel in risk assessment and problem anticipation in decision-making processes.",
                        ],
                    },
                    {
                        description: "10% - Education and Training – off the job:",
                        points: [
                            "Pursue advanced data analysis and visualisation courses or certifications.",
                            "Attend seminars on strategic decision-making and risk management.",
                        ],
                    },
                ],
            },
        },
    ],
};
