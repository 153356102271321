import { COACH_PLATFORM_PERMISSIONS } from "../../../../../../../utils/constants";
import sentimentMeasuresGraphic from "../../../../../../../assets/sentiment-measures-graphic.png";

import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import AceReportPicker from "./AceReportPicker";

export default function ReportContext() {
    return (
        <DashboardLayout
            title="Report context"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <ReportContextElements />
        </DashboardLayout>
    );
}

export function ReportContextElements() {
    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-1 gap-6">
                <div className={"flex flex-col gap-3 break-inside-avoid-page"}>
                    <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                        Introductory comments
                    </h2>
                    <div className="flex flex-col p-4 gap-4 bg-white rounded-xl border border-1 border-slate-100 shadow-sm h-max print:p-0 print:shadow-none print:border-none">
                        <div className="flex flex-col gap-2">
                            <h2 className="text-base text-primary-600 font-medium font-title tracking-tight">
                                Purpose of the ACE Satisfaction Survey
                            </h2>
                            <p className="text-slate-500">
                                A healthy partnership is characterised by high levels of advocacy, commitment and
                                engagement, or what is referred to in this report as the ACE Mindset.
                            </p>
                            <p className="text-slate-500">
                                The ACE Mindset is most likely to be higher when social and business needs are being
                                met, as measured by the ACE Survey. The questions in the survey come from years of
                                research and are carefully worded to reduce ambiguity and accurately measure the factors
                                that drive positive sentiment.
                            </p>
                            <p className="text-slate-500">
                                Armed with this feedback, the company can build on what is working and create action
                                plans to improve areas needing attention.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h2 className="text-base text-primary-600 font-medium font-title tracking-tight">
                                Survey distribution and processing
                            </h2>
                            <p className="text-slate-500">
                                An invitation to complete the ACE Survey was sent by an email from Psych Press
                                explaining the purpose of the survey, its confidential nature, and Psych Press’s
                                independent role in managing the data and reporting on the findings.
                            </p>
                            <p className="text-slate-500">
                                Completed surveys were analysed by a team of researchers, who have removed information
                                that could identify respondents, and combined relevant data into charts providing
                                insights into specific areas of satisfaction.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col gap-3"}>
                    <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                        Report structure
                    </h2>
                    <div className="flex flex-col p-4 gap-4 bg-white rounded-xl border border-1 border-slate-100 shadow-sm h-max print:p-0 print:shadow-none print:border-none">
                        <div className="flex flex-col gap-2">
                            <p className="text-slate-500">
                                <span className={"font-medium text-primary-600"}>
                                    The Demographic Profile of Respondents{" "}
                                </span>
                                provides information on when the survey was distributed, response rates, and the average
                                demographic profile of respondents.
                            </p>
                            <p className="text-slate-500">
                                <span className={"font-medium text-primary-600"}>The Sentiment section </span>
                                measures the many factors that contribute to a healthy partnership. Feedback is given on
                                six dimensions of satisfaction compared to Sector Benchmarks. These are then divided
                                into 23 areas, enabling specific strengths and areas for development to be identified.
                                Scores are presented graphically and numerically. The Rating is a weighted score out of
                                100 for this measure and is also compared to your company’s last survey findings. The
                                Benchmark Quintile places the Rating in one of five categories compared to the Benchmark
                                – High; Above Average; Average; Below Average; or Low.
                            </p>
                            <p className="text-slate-500">
                                <span className={"font-medium text-primary-600"}>
                                    The ACE Mindset and Associated KPIs{" "}
                                </span>
                                section shares important measures that reflect how respondents feel about their
                                involvement in the company. This includes an ACE Mindset Score, a Net Promoter Score,
                                their willingness to invest in additional units, and their intention to leave the
                                network.
                            </p>
                            <p className="text-slate-500">
                                <span className={"font-medium text-primary-600"}>Feedback on Field Managers </span>
                                measures satisfaction with field managers on specific capabilities. These are also
                                compared with previous survey findings.
                            </p>
                            <p className="text-slate-500 break-inside-avoid-page">
                                <span className={"font-medium text-primary-600"}>The Services Feedback </span>
                                section provides feedback on the effectiveness of specific support services delivered by
                                the company and, where applicable, are compared to the most recent survey findings.
                            </p>
                            <p className="text-slate-500">
                                <span className={"font-medium text-primary-600"}>The Comments sections </span>
                                provide themes from responses to various open-ended questions, along with sample
                                comments. We recommend reading the separate Open-ended Comments Report to see the
                                original comments from which the themes were derived.
                            </p>
                            <p className="text-slate-500">
                                <span className={"font-medium text-primary-600"}>The Appendix </span>
                                section provides the Ratings and Percentile rankings for all sentiment dimensions and
                                areas, along with comparisons from the most recent survey findings.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col gap-3"}>
                    <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                        Getting the most from the report
                    </h2>
                    <div className="flex flex-col p-4 gap-6 bg-white rounded-xl border border-1 border-slate-100 shadow-sm h-max print:p-0 print:shadow-none print:border-none">
                        <div className="flex flex-col gap-2 break-inside-avoid-page">
                            <p className="text-slate-500">
                                Responses to satisfaction surveys do not measure actual performance or objective
                                reality. Rather they measure the beliefs and perceptions of respondents. These are
                                largely influenced by their expectations, their personal experiences while operating the
                                business, and the dealings they have had with members of the state team.
                            </p>
                            <p className="text-slate-500">
                                While this report accurately presents the beliefs and perceptions of respondents at a
                                particular point in time, these may differ from beliefs and perceptions of the company's
                                management.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className={"grid grid-cols-2 gap-4 print:grid-cols-1"}>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}>Be curious. </span>
                                    Maintain a sense of curiosity as to why respondents may feel as they do in specific
                                    areas. Think about what might be driving certain attitudes or beliefs.
                                </p>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}> Don’t get defensive. </span>
                                    Stay open to the feedback - you asked for it. Don’t get defensive or rationalise the
                                    findings, for instance by saying “they clearly don’t understand what they are
                                    talking about.”
                                </p>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}>Focus on the positives. </span>
                                    Focus on the positive feedback as much as any criticism, as it is important you
                                    continue to build on what is working. Don’t become obsessed with specific criticisms
                                    and allow these to overshadow the broader findings.
                                </p>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}>Respect confidentiality </span>
                                    Respect the confidentiality and anonymity promised to respondents. Don’t try to
                                    guess how individuals responded or who said what, and definitely don’t make comments
                                    such as “I read what you said in the survey!”
                                </p>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}>Embrace SARA. </span>
                                    Accept that it’s normal to initially feel shocked, angry or disappointed by extreme
                                    criticism or negative findings. This will pass if you accept the findings as
                                    representing how people were feeling. (This is called the SARA response – Shock,
                                    Anger, Reflection and Acceptance.)
                                </p>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}>Read the whole report. </span>
                                    Take the time to read the whole report. Look for common themes as well as specific
                                    opportunities.
                                </p>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}>
                                        Provide feedback on the findings.{" "}
                                    </span>
                                    Provide feedback to respondents and your team on the findings. This will build
                                    trust. Don’t try to suppress the findings or treat the survey as though it never
                                    happened.
                                </p>
                                <p className="text-slate-500 break-inside-avoid-page">
                                    <span className={"font-medium text-primary-600"}>Conduct action planning. </span>
                                    Organise a separate debriefing and action planning session for the team to ensure
                                    everyone understands the findings, specific goals are set for improvement, and
                                    actions recorded. Don’t assume that people will know how to process the findings or
                                    know what to do to fix areas needing attention.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col gap-3 break-inside-avoid-page"}>
                    <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                        Sentiment measures
                    </h2>
                    <div className="p-4 gap-4 bg-white rounded-xl border border-1 border-slate-100 shadow-sm h-max print:p-0 print:shadow-none print:border-none">
                        <div className="flex flex-col gap-2">
                            <p className="text-slate-500">
                                This section measures sentiment in the six dimensions shown below which ultimately
                                enhance or detract from the ACE Mindset. To complete this section, respondents rated
                                whether they Strongly Agreed, Agreed, Disagreed or Strongly Disagreed with 24
                                statements. Their ratings have then been combined, weighted and mapped onto a series of
                                charts showing how positive or negative they feel in these six dimensions and 23 areas
                                that fit within these dimensions.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center p-4 bg-white w-full rounded-xl shadow-sm border border-1 border-slate-100 print:shadow-none print:border-none">
                        <img
                            alt="Sentiment measures graphic"
                            className={"w-96 rounded-xl"}
                            src={sentimentMeasuresGraphic}
                        />
                    </div>
                </div>
                <div className={"flex flex-col gap-3"}>
                    <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                        Interpreting findings
                    </h2>
                    <div className="p-4 gap-4 bg-white rounded-xl border border-1 border-slate-100 shadow-sm h-max print:p-0 print:shadow-none print:border-none ">
                        <div className="flex flex-col gap-2">
                            <p className="text-slate-500">
                                The coloured bars represent satisfaction for the current year. The red or green vertical
                                line associated with each coloured bar indicates the previous year's result (if
                                applicable).
                            </p>
                            <ul className="text-slate-500 flex flex-col gap-2">
                                <li>
                                    If the current year result is higher than the previous year, the line representing
                                    previous year will be coloured
                                    <span className={"text-green-600 font-medium"}> green</span> , reflecting a
                                    <span className={"font-medium"}> positive</span> trend.
                                </li>
                                <li>
                                    If the current year result is lower than previous year, the line will be
                                    <span className={"text-red-600 font-medium"}> red</span>, reflecting a
                                    <span className={"font-medium"}> negative</span> trend.
                                </li>
                            </ul>
                            <p className="text-slate-500">
                                The <span className={"text-amber-600 font-medium"}>yellow bar</span> represents a
                                comparative benchmark from a combined company database, which is referred to throughout
                                as the Sector Benchmark.
                            </p>
                            <p className="text-slate-500">
                                In 2023, the number of questions in the sentiment section was reduced. While this may
                                result in slight changes in some of the Area or Dimension scores from previous reports,
                                your scores this year are compared with identical questions from last year.
                            </p>
                            <div className="text-slate-500 flex flex-col gap-1" id="effectSizes">
                                <span className={"text-primary-600 font-medium"}>Effect sizes</span>
                                <p>
                                    An effect size is a comparison between average scores to see how substantially
                                    different they are from each other that is not influenced by sample size. For each
                                    bar, there will be an effect size that reflects the size of the difference between
                                    the current year result and the Sector Benchmark, as well as between the current
                                    year result and the previous year's result (if applicable). Effect sizes can be
                                    understood as:
                                </p>
                            </div>
                            <ul className="text-slate-500 flex flex-col gap-2">
                                <li>
                                    <span className={"text-primary-600 font-medium"}>Negligible</span>: the difference
                                    between the scores is insignificant.
                                </li>
                                <li>
                                    <span className={"text-primary-600 font-medium"}>Small</span>: the difference
                                    between the scores is minor.
                                </li>
                                <li>
                                    <span className={"text-primary-600 font-medium"}>Moderate</span>: the difference
                                    between the scores is noteworthy.
                                </li>

                                <li>
                                    <span className={"text-primary-600 font-medium"}>Large</span>: the difference
                                    between the scores is significant and meaningful.
                                </li>
                            </ul>
                            <p className="text-slate-500">
                                For example, if the current year result for General Satisfaction was higher than the
                                Sector Benchmark and a “Small” effect size was specified, the difference between these
                                two scores can be seen as minor.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
