import React, { useState } from "react";

import { COACH_PLATFORM_PERMISSIONS } from "../../../../../utils/constants";

import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import AllDepartments from "./AllDepartments";

export default function GroupReports() {
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <DashboardLayout title={"Group reports"} requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}>
            <TabbedNavigationLayout
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabList={["All departments", "Department 1", "Department 2"]}
                backgroundColour={"bg-slate-100"}
                tabsRowOnly
                tabContent={[
                    {
                        title: "All departments",
                        content: <AllDepartments />,
                    },
                    {
                        title: "Department 1",
                        content: <div>Hi there!</div>,
                    },
                    {
                        title: "Department 2",
                        content: <div>Hey there!</div>,
                    },
                ]}
            />
        </DashboardLayout>
    );
}
