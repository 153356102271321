import React, { useCallback, useEffect, useState } from "react";

import { getCohorts } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import ProgramCohortRow from "./ProgramCohortRow";

export default function ProgramCohorts(props) {
    const { program, setCohortList, cohortList } = props;

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [organisationCohorts, setOrganisationCohorts] = useState([]);
    const [currentCohorts, setCurrentCohorts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [latestCohortInteractedCurrent, setLatestCohortInteractedCurrent] = useState(true);
    const [latestCohortInteractedNonCurrent, setLatestCohortInteractedNonCurrent] = useState(true);

    const handleAddCohortToggle = (cohortName, toggle, nonCurrentCohort) => {
        let cohort;

        if (nonCurrentCohort) {
            cohort = organisationCohorts.find((organisationCohort) => organisationCohort === cohortName);
        } else {
            cohort = currentCohorts.find((currentCohort) => currentCohort === cohortName);
        }

        if (toggle) {
            setCurrentCohorts((usersWithAccess) => [cohort, ...usersWithAccess]);
            setOrganisationCohorts(
                organisationCohorts.filter((organisationCohort) => organisationCohort !== cohortName)
            );

            setCohortList((usersWithAccess) => [...usersWithAccess, cohort]);

            setLatestCohortInteractedCurrent(cohort);
        } else {
            setOrganisationCohorts((organisationCohorts) => [cohort, ...organisationCohorts]);
            setCurrentCohorts(currentCohorts.filter((currentCohort) => currentCohort !== cohortName));
            setCohortList(
                cohortList.filter((latestCohortInteractedCurrent) => latestCohortInteractedCurrent !== cohortName)
            );
            setLatestCohortInteractedNonCurrent(cohort);
        }
    };

    const fetchCohorts = useCallback(async () => {
        setLoading(true);
        try {
            const organisationCohortsResponse = await getCohorts();
            setCurrentCohorts(program.cohorts);

            let cohortNamesList = [];

            organisationCohortsResponse.map((cohort) => cohortNamesList.push(cohort.name));

            setOrganisationCohorts(
                cohortNamesList.filter(
                    (organisationCohort) =>
                        !program.cohorts.find((currentCohort) => currentCohort === organisationCohort)
                )
            );

            program.cohorts.map((cohort) => setCohortList((cohortList) => [...cohortList, cohort]));
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, [program.cohorts, setCohortList]);

    useEffect(() => {
        fetchCohorts();
    }, [fetchCohorts]);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"flex flex-col gap-6"}>
                <div
                    className={
                        "bg-primary-50 p-3 rounded-xl text-primary-600 text-sm shadow-sm border border-primary-100 border-1"
                    }
                >
                    Cohorts must always be linked to a program. To remove a cohort from its current program add it to
                    another program.
                </div>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-2">Cohorts</div>
                    <PaginatedSearch
                        loading={loading}
                        items={currentCohorts}
                        itemName={"cohorts"}
                        searchAttributes={["Name"]}
                        columnNames={["Cohort name"]}
                        ItemRow={ProgramCohortRow}
                        itemRowToggleOnChange={handleAddCohortToggle}
                        customItemsPerPage={4}
                        accessDefaultToggleState={false}
                        latestItemInteracted={latestCohortInteractedCurrent}
                    />
                </div>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-4">Add more cohorts</div>
                    <PaginatedSearch
                        loading={loading}
                        items={organisationCohorts}
                        itemName={"cohorts"}
                        searchAttributes={["Name"]}
                        columnNames={["Cohort name", "Program"]}
                        ItemRow={ProgramCohortRow}
                        itemRowToggleOnChange={handleAddCohortToggle}
                        customItemsPerPage={8}
                        noPreviousAccessUser={true}
                        accessDefaultToggleState={true}
                        noTableHead
                        latestItemInteracted={latestCohortInteractedNonCurrent}
                    />
                </div>
            </div>
        </>
    );
}
