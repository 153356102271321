import { useState } from "react";

import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import IndividualFieldManagerSelectSection from "./IndividualFieldManagerSelectSection";

export default function IndividualFieldManager(props) {
    const { data, displayOnlyContent } = props;

    const [fieldManagerTypeTabIndex, setFieldManagerTypeTabIndex] = useState(0);

    const fieldManagerTypeTabList = [];

    data.map(
        (fieldManagerItem) =>
            !fieldManagerTypeTabList.includes(fieldManagerItem.type) &&
            fieldManagerItem.type !== undefined &&
            fieldManagerTypeTabList.push(fieldManagerItem.type)
    );

    const fieldManagerTypeContent = [];

    fieldManagerTypeTabList.map((tabListItem) =>
        fieldManagerTypeContent.push({
            content: (
                <IndividualFieldManagerSelectSection data={data.filter((dataItem) => dataItem.type === tabListItem)} />
            ),
            readonly: true,
        })
    );

    return fieldManagerTypeTabList.length === 0 ? (
        <IndividualFieldManagerSelectSection data={data} displayOnlyContent={displayOnlyContent} />
    ) : (
        <TabbedNavigationLayout
            tabIndex={fieldManagerTypeTabIndex}
            setTabIndex={setFieldManagerTypeTabIndex}
            tabList={fieldManagerTypeTabList}
            backgroundColour={"bg-white"}
            tabContent={fieldManagerTypeContent}
            tabsRowOnly
            secondaryTabs
            noContainer
            displayOnlyContent={displayOnlyContent}
        />
    );
}
