import { UserIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

import { generateLevelNumber, generateProportionalPercentage } from "../../../../../../../utils/utils";

import ScaleAxis from "../../../Charts/CustomCharts/ScaleAxis";
import Alert, { ALERT_TYPE } from "../../../../../../SharedComponents/Alert";

export default function Scale(props) {
    const {
        item: scale,
        backgroundColour,
        bubbleBackgroundColour,
        testId,
        chartTestId,
        paddingless,
        fourPartsScale,
        barAdjectives,
        shadowLess,
        darkPercentageScoreBubble,
        customBottomAxis,
        noPrintBreak,
        extraStyling,
        displayCategoryInName,
        hidePercentScoreNumber,
        hideBottomAxis,
        highlightLevel,
        mindsetBands,
        evenLevels,
        capabilityProfile,
        old,
    } = props;

    const [position, setPosition] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (mindsetBands) {
                old
                    ? setPosition(generateProportionalPercentage(scale.percentage, "mindset"))
                    : setPosition(scale.percentage);
            } else {
                old ? setPosition(generateProportionalPercentage(scale.percentage)) : setPosition(scale.percentage);
            }
        }, 100);
    }, [mindsetBands, old, scale.percentage]);

    let selfAssessmentLevelToHighlight = 0;
    let mindsetLevelToHighlight = 0;
    let defaultLevelToHighLight = 0;

    if (mindsetBands) {
        mindsetLevelToHighlight = generateLevelNumber(scale.percentage, "mindset");
        defaultLevelToHighLight = generateLevelNumber(scale.percentage, "mindset");
    } else {
        selfAssessmentLevelToHighlight = generateLevelNumber(scale.percentage, "self-assessed");
        defaultLevelToHighLight = generateLevelNumber(scale.percentage, "self-assessed");
    }

    const UserBubble = () => {
        return (
            !old && (
                <div
                    className={
                        "absolute flex flex-col z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-6 "
                    }
                >
                    <div
                        className={
                            "flex gap-1 justify-center items-center text-amber-700 bg-amber-200 " +
                            (position === 0 ? "opacity-0 " : "opacity-1 ") +
                            "w-max p-1 print:p-1.5 rounded-full transition-opacity duration-4000 z-0 " +
                            (capabilityProfile ? " " : "rounded-br-none ") +
                            "flex-row"
                        }
                    >
                        <UserIcon className={"block h-3.5 w-3.5 print:w-3.5 print:h-3.5 "} />
                        <span className={"text-xs font-medium "}>{scale.coacheeName}</span>
                    </div>
                </div>
            )
        );
    };

    return (
        <div
            data-testid={testId && testId}
            data-item={scale.name}
            className={
                scale.textColour +
                " " +
                (backgroundColour ? backgroundColour : "bg-white border-slate-100") +
                " " +
                (paddingless ? "p-0 " : "p-4 ") +
                (mindsetBands ? "pb-4 " : "pb-10 ") +
                "flex flex-col gap-6 w-full rounded-xl border " +
                "print:p-0 print:shadow-none print:border-none print:bg-transparent " +
                (noPrintBreak ? "" : "") +
                (shadowLess ? " shadow-none " : " shadow-sm ") +
                extraStyling
            }
        >
            <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-4 w-full">
                    <div
                        className={
                            "flex flex-row justify-between items-center " +
                            (scale.percentage < 33
                                ? "text-green-500"
                                : scale.percentage > 33 && scale.percentage < 66
                                ? "text-amber-500"
                                : "text-red-500")
                        }
                    >
                        <p className={"font-medium text-base " + scale.textColour}>
                            {scale.name.toLowerCase() === "headfirst total score"
                                ? "Overall Wellbeing"
                                : scale.category
                                ? displayCategoryInName ||
                                  ["Managing Tasks", "Managing Self", "Managing Others", "Managing Risk"].includes(
                                      scale.category
                                  )
                                    ? scale.name + " (" + scale.category + ")"
                                    : scale.name
                                : scale.name}
                        </p>
                        {scale.percentage && !hidePercentScoreNumber && (
                            <div className="flex flex-row items-center gap-2">
                                <span
                                    className={
                                        "p-1 rounded-xl px-1.5 text-lg font-semibold shadow-lg bg-" +
                                        (darkPercentageScoreBubble
                                            ? scale.colour +
                                              "-600 text-white border border-" +
                                              scale.colour +
                                              "-400 shadow-" +
                                              scale.colour +
                                              "-300"
                                            : scale.colour +
                                              "-100 text-" +
                                              scale.colour +
                                              "-600 border-2 border-" +
                                              scale.colour +
                                              "-600 shadow-" +
                                              scale.colour +
                                              "-600/30")
                                    }
                                >
                                    {scale.percentage}%<span className="text-sm font-normal">ile</span>
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col gap-6 w-full">
                        {scale.scaleDescription && (
                            <div
                                className={
                                    "flex flex-row gap-2 p-2 rounded-xl bg-slate-50 border shadow-sm " +
                                    "text-slate-500 print:hidden " +
                                    (bubbleBackgroundColour ? bubbleBackgroundColour : "bg-slate-50 border-slate-100")
                                }
                            >
                                <span className={"font-title text-sm"}>{scale.scaleDescription}</span>
                            </div>
                        )}
                        {scale.percentage ? (
                            scale.customChart ? (
                                scale.customChart
                            ) : (
                                <div data-testid={chartTestId && chartTestId} className="flex flex-col gap-4 ">
                                    <div className="flex items-center justify-between text-sm font-medium text-slate-500">
                                        <span className={"text-" + (scale.colour + "-600")}>{scale.lowAdjective}</span>
                                        <span className={"text-" + (scale.colour + "-800")}>{scale.highAdjective}</span>
                                    </div>
                                    <div className={"flex flex-row h-8 " + (old ? "gap-1" : "gap-0")}>
                                        {fourPartsScale ? (
                                            <>
                                                {evenLevels ? (
                                                    mindsetBands ? (
                                                        <>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center bg-" +
                                                                    (highlightLevel
                                                                        ? mindsetLevelToHighlight === 1
                                                                            ? scale.colour + "-400 text-white "
                                                                            : "slate-100 text-slate-500 "
                                                                        : scale.colour + "-400 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-l-xl")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[0] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[0]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel && mindsetLevelToHighlight === 1 && (
                                                                    <UserBubble />
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center bg-" +
                                                                    (highlightLevel
                                                                        ? mindsetLevelToHighlight === 2
                                                                            ? scale.colour + "-500 text-white "
                                                                            : "slate-200 text-slate-500 "
                                                                        : scale.colour + "-500 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-none")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[1] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[1]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel && mindsetLevelToHighlight === 2 && (
                                                                    <UserBubble />
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center  bg-" +
                                                                    (highlightLevel
                                                                        ? mindsetLevelToHighlight === 3
                                                                            ? scale.colour + "-600 text-white "
                                                                            : "slate-300 text-slate-500 "
                                                                        : scale.colour + "-600 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-none")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[2] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[2]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel && mindsetLevelToHighlight === 3 && (
                                                                    <UserBubble />
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center rounded-r-xl bg-" +
                                                                    (highlightLevel
                                                                        ? mindsetLevelToHighlight === 4
                                                                            ? scale.colour + "-700 text-white "
                                                                            : "slate-400 text-white "
                                                                        : scale.colour + "-700 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-r-xl")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[3] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[3]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel && mindsetLevelToHighlight === 4 && (
                                                                    <UserBubble />
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center  bg-" +
                                                                    (highlightLevel
                                                                        ? selfAssessmentLevelToHighlight === 1
                                                                            ? scale.colour + "-400 text-white "
                                                                            : "slate-100 text-slate-500 "
                                                                        : scale.colour + "-400 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-l-xl")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[0] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[0]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel &&
                                                                    selfAssessmentLevelToHighlight === 1 && (
                                                                        <UserBubble />
                                                                    )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center bg-" +
                                                                    (highlightLevel
                                                                        ? selfAssessmentLevelToHighlight === 2
                                                                            ? scale.colour + "-500 text-white "
                                                                            : "slate-200 text-slate-500 "
                                                                        : scale.colour + "-500 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-none")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[1] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[1]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel &&
                                                                    selfAssessmentLevelToHighlight === 2 && (
                                                                        <UserBubble />
                                                                    )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center  bg-" +
                                                                    (highlightLevel
                                                                        ? selfAssessmentLevelToHighlight === 3
                                                                            ? scale.colour + "-600 text-white "
                                                                            : "slate-300 text-slate-500 "
                                                                        : scale.colour + "-600 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-none")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[2] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[2]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel &&
                                                                    selfAssessmentLevelToHighlight === 3 && (
                                                                        <UserBubble />
                                                                    )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    "relative w-full shadow-sm flex items-center justify-center bg-" +
                                                                    (highlightLevel
                                                                        ? selfAssessmentLevelToHighlight === 4
                                                                            ? scale.colour + "-700 text-white "
                                                                            : "slate-400 text-white "
                                                                        : scale.colour + "-700 text-white ") +
                                                                    (old ? "rounded-xl" : "rounded-r-xl")
                                                                }
                                                            >
                                                                {barAdjectives && barAdjectives[3] && (
                                                                    <span className={"text-sm font-semibold"}>
                                                                        {barAdjectives[3]}
                                                                    </span>
                                                                )}
                                                                {!highlightLevel &&
                                                                    selfAssessmentLevelToHighlight === 4 && (
                                                                        <UserBubble />
                                                                    )}
                                                            </div>
                                                        </>
                                                    )
                                                ) : mindsetBands ? (
                                                    <>
                                                        <div
                                                            className={
                                                                "relative shadow-sm flex items-center justify-center rounded-l-xl bg-" +
                                                                (highlightLevel
                                                                    ? mindsetLevelToHighlight === 1
                                                                        ? scale.colour + "-400 text-purple-600"
                                                                        : "slate-100 text-slate-500"
                                                                    : scale.colour + "-400 text-purple-600")
                                                            }
                                                            style={{ width: `${(6 / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[0] && (
                                                                <span
                                                                    className={
                                                                        "absolute top-8 left-0 flex flex-col gap-0 text-sm font-semibold w-max"
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            "w-1 h-2  rounded-b-md ml-6 " +
                                                                            (highlightLevel
                                                                                ? mindsetLevelToHighlight === 1
                                                                                    ? "bg-purple-400"
                                                                                    : "bg-slate-400"
                                                                                : "bg-purple-600")
                                                                        }
                                                                    />
                                                                    {barAdjectives[0]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                "shadow-sm flex items-center justify-center bg-" +
                                                                (highlightLevel
                                                                    ? mindsetLevelToHighlight === 2
                                                                        ? scale.colour + "-500 text-white"
                                                                        : "slate-200 text-slate-500"
                                                                    : scale.colour + "-500 text-white")
                                                            }
                                                            style={{ width: `${((36 - 6) / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[1] && (
                                                                <span className={"text-sm font-semibold"}>
                                                                    {barAdjectives[1]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                "shadow-sm flex items-center justify-center  bg-" +
                                                                (highlightLevel
                                                                    ? mindsetLevelToHighlight === 3
                                                                        ? scale.colour + "-600 text-white"
                                                                        : "slate-300 text-slate-500"
                                                                    : scale.colour + "-600 text-white")
                                                            }
                                                            style={{ width: `${((66 - 36) / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[2] && (
                                                                <span className={"text-sm font-semibold"}>
                                                                    {barAdjectives[2]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                "shadow-sm flex items-center justify-center rounded-r-xl bg-" +
                                                                (highlightLevel
                                                                    ? mindsetLevelToHighlight === 4
                                                                        ? scale.colour + "-700 text-white"
                                                                        : "slate-400 text-white"
                                                                    : scale.colour + "-700 text-white")
                                                            }
                                                            style={{ width: `${((100 - 66) / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[3] && (
                                                                <span className={"text-sm font-semibold"}>
                                                                    {barAdjectives[3]}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={
                                                                "shadow-sm relative flex items-center justify-center rounded-l-xl bg-" +
                                                                (highlightLevel
                                                                    ? selfAssessmentLevelToHighlight === 1
                                                                        ? scale.colour + "-400 text-white"
                                                                        : "slate-100 text-slate-500"
                                                                    : scale.colour + "-400 text-white")
                                                            }
                                                            style={{ width: `${(38 / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[0] && (
                                                                <span className={"text-sm font-semibold"}>
                                                                    {barAdjectives[0]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                "shadow-sm flex items-center justify-center bg-" +
                                                                (highlightLevel
                                                                    ? selfAssessmentLevelToHighlight === 2
                                                                        ? scale.colour + "-500 text-white"
                                                                        : "slate-200 text-slate-500"
                                                                    : scale.colour + "-500 text-white")
                                                            }
                                                            style={{ width: `${((63 - 38) / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[1] && (
                                                                <span className={"text-sm font-semibold"}>
                                                                    {barAdjectives[1]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                "shadow-sm flex items-center justify-center  bg-" +
                                                                (highlightLevel
                                                                    ? selfAssessmentLevelToHighlight === 3
                                                                        ? scale.colour + "-600 text-white"
                                                                        : "slate-300 text-slate-500"
                                                                    : scale.colour + "-600 text-white")
                                                            }
                                                            style={{ width: `${((88 - 63) / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[2] && (
                                                                <span className={"text-sm font-semibold"}>
                                                                    {barAdjectives[2]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                "shadow-sm relative flex items-center justify-center rounded-r-xl  bg-" +
                                                                (highlightLevel
                                                                    ? selfAssessmentLevelToHighlight === 4
                                                                        ? scale.colour + "-700 text-white "
                                                                        : "slate-400 text-slate-500 "
                                                                    : scale.colour + "-700 text-teal-600 ")
                                                            }
                                                            style={{ width: `${((100 - 88) / 100) * 100}%` }}
                                                        >
                                                            {barAdjectives && barAdjectives[3] && (
                                                                <span
                                                                    className={
                                                                        "absolute top-8 right-2 flex flex-col items-center gap-0 text-sm font-semibold w-max"
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            "w-1 h-2 rounded-b-md ml-6 " +
                                                                            (highlightLevel
                                                                                ? mindsetLevelToHighlight === 1
                                                                                    ? "bg-teal-400"
                                                                                    : "bg-slate-400"
                                                                                : "bg-teal-600")
                                                                        }
                                                                    />
                                                                    {barAdjectives[3]}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className={"w-full rounded-l-xl bg-" + (scale.colour + "-400")} />
                                                <div className={"w-full  bg-" + (scale.colour + "-600")} />
                                                <div className={"w-full rounded-r-xl bg-" + (scale.colour + "-800")} />
                                            </>
                                        )}
                                    </div>
                                    {customBottomAxis
                                        ? !hideBottomAxis && customBottomAxis
                                        : !hideBottomAxis && (
                                              <ScaleAxis
                                                  percentage
                                                  percentageLabels={{
                                                      first: "0%",
                                                      second: "25%",
                                                      third: "50%",
                                                      fourth: "75%",
                                                      fifth: "99%",
                                                  }}
                                              />
                                          )}
                                    <div
                                        className={
                                            "relative transition-width duration-4000 -mb-4  w-" +
                                            (old
                                                ? position + "-percentage "
                                                : !highlightLevel
                                                ? defaultLevelToHighLight === 1
                                                    ? "12-percentage"
                                                    : defaultLevelToHighLight === 2
                                                    ? "38-percentage"
                                                    : defaultLevelToHighLight === 3
                                                    ? "62-percentage"
                                                    : "87-percentage"
                                                : position + "-percentage ")
                                        }
                                    >
                                        {!capabilityProfile && !old && (
                                            <>
                                                <div
                                                    className={
                                                        "absolute -top-[119px] xl:-top-[123px] -right-0 flex flex-col z-20"
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            "flex flex-row gap-1 justify-center items-center text-amber-700 bg-amber-200 " +
                                                            (position === 0 ? "opacity-0 " : "opacity-1 ") +
                                                            "w-max p-1 rounded-full rounded-br-none transition-opacity duration-4000 z-0"
                                                        }
                                                    >
                                                        <UserIcon className="block h-3.5 w-3.5" />
                                                        <span className={"text-xs font-medium"}>
                                                            {scale.coacheeName}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        "absolute -top-[40px] xl:-top-[104px] -right-0 flex flex-col -z-1"
                                                    }
                                                >
                                                    <div className={"h-8 w-0.5 bg-white opacity-70"}></div>
                                                </div>
                                            </>
                                        )}
                                        {old && (
                                            <div
                                                className={
                                                    "absolute -top-[48px] print:-top-[48px] -right-1 flex flex-col -z-1"
                                                }
                                            >
                                                <div
                                                    className={"h-8 w-1 bg-amber-400 opacity-100 print:opacity-80"}
                                                ></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        ) : (
                            <Alert
                                data-testid={testId && testId}
                                text={"Data set for this chart is empty."}
                                type={ALERT_TYPE.WARNING}
                                active
                                noMargins
                            />
                        )}
                        {scale.scoreDescription && (
                            <div className={"text-slate-500 text-sm"}>{scale.scoreDescription}</div>
                        )}
                    </div>
                </div>
                {scale.extraContent && scale.extraContent}
            </div>
        </div>
    );
}
