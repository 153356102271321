import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import {
    BAR_CHART_MORE_DISTINCT_COLOUR_PALETTE,
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../../../../../utils/constants";

import DashboardLayout from "../../../../../../../SharedComponents/DashboardLayout";
import QuestionsDataChart from "../../ChartCards/QuestionsDataChart";
import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QualitativeItemSection from "../Qualitative/QualitativeItemSection";
import SingleWithBenchmark from "../../ChartCards/SingleWithBenchmark";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import AceReportPicker from "../AceReportPicker";

export default function MemberQuestions() {
    return (
        <DashboardLayout
            title="Member Questions"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <MemberQuestionsElements />
        </DashboardLayout>
    );
}

export function MemberQuestionsElements(props) {
    const { displayOnlyContent, noInfoBubbles } = props;

    const context = useContext(Context);

    const [tabIndex, setTabIndex] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [tabContent, setTabContent] = useState([]);

    useEffect(() => {
        let updatedTabList = [];
        let updatedTabContent = [];

        if (
            context.aceSurveyReportData.memberQuestions.length !== 0 &&
            context.aceSurveyReportData.memberQuestions[0].regionalData
        ) {
            updatedTabList.push("Member Questions Overall by Region");
            updatedTabContent.push({
                content: (
                    <SingleWithBenchmark
                        title={"Member Questions Overall by Region"}
                        noInfoBubbles={noInfoBubbles}
                        onlyBubbleOnFirstBarOnPrint
                        infoBubble
                        withBenchmarks
                        detailed
                        data={context.aceSurveyReportData.memberQuestions[0].data.concat(
                            context.aceSurveyReportData.memberQuestions[0].regionalData
                        )}
                        noTrendLegend
                    />
                ),
                readonly: true,
            });
        }

        if (
            context.aceSurveyReportData.memberQuestionsWithMemberTypes.length !== 0 &&
            context.aceSurveyReportData.memberQuestionsWithMemberTypes[0].regionalData
        ) {
            updatedTabList.push("Member Questions Overall by Member Type");
            updatedTabContent.push({
                content: (
                    <SingleWithBenchmark
                        title={"Member Questions Overall by Member Type"}
                        noInfoBubbles={noInfoBubbles}
                        onlyBubbleOnFirstBarOnPrint
                        infoBubble
                        withBenchmarks
                        detailed
                        data={context.aceSurveyReportData.memberQuestionsWithMemberTypes[0].data.concat(
                            context.aceSurveyReportData.memberQuestionsWithMemberTypes[0].regionalData
                        )}
                        noTrendLegend
                    />
                ),
                readonly: true,
            });
        }

        context.aceSurveyReportData.memberQuestions.forEach((memberQuestionsElement) => {
            memberQuestionsElement.name !== "Conditions for continuing terms with supplier" &&
            memberQuestionsElement.name !== "Continuing terms with Supplier"
                ? updatedTabList.push(memberQuestionsElement.name + " by Region") &&
                  updatedTabContent.push({
                      content: (
                          <QuestionsDataChart
                              data={memberQuestionsElement.items}
                              title={"Member Questions by Region"}
                              colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                              noItemTitles
                              questions
                              infoBubble
                              noInfoBubbleOnPrint
                              topXAxis
                              noBottomAxis={displayOnlyContent}
                              extraStyling="print:break-before-page"
                              noTrendLegend
                              noInfoBubbles={noInfoBubbles}
                          />
                      ),
                      readonly: true,
                  })
                : memberQuestionsElement.name === "Member Questions - Regional" &&
                  updatedTabList.push(memberQuestionsElement.name) &&
                  updatedTabContent.push({
                      content: (
                          <QuestionsDataChart
                              data={memberQuestionsElement.items}
                              title={memberQuestionsElement.name}
                              colourPalette={BAR_CHART_MORE_DISTINCT_COLOUR_PALETTE}
                              noItemTitles
                              questions
                              infoBubble
                              noInfoBubbleOnPrint
                              topXAxis
                              noBottomAxis={displayOnlyContent}
                              extraStyling="print:break-before-page"
                              noTrendLegend
                              noInfoBubbles={noInfoBubbles}
                          />
                      ),
                      readonly: true,
                  });
        });

        context.aceSurveyReportData.memberQuestionsWithMemberTypes.forEach((memberQuestionsElement) => {
            memberQuestionsElement.name !== "Average Services Ratings"
                ? updatedTabList.push("Member Questions By Member Type") &&
                  updatedTabContent.push({
                      content: (
                          <QuestionsDataChart
                              data={memberQuestionsElement.items}
                              title={"Member Questions By Member Type"}
                              colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                              noItemTitles
                              questions
                              infoBubble
                              noInfoBubbleOnPrint
                              topXAxis
                              noBottomAxis={displayOnlyContent}
                              extraStyling="print:break-before-page"
                              noTrendLegend
                              noInfoBubbles={noInfoBubbles}
                          />
                      ),
                      readonly: true,
                  })
                : updatedTabList.unshift(memberQuestionsElement.name) &&
                  updatedTabContent.unshift({
                      content: (
                          <SingleWithBenchmark
                              withBenchmarks
                              detailed
                              data={memberQuestionsElement.data}
                              title={"Member Questions by Region"}
                              infoBubble
                              noTrendLegend
                              noInfoBubbles={noInfoBubbles}
                          />
                      ),
                      readonly: true,
                  });
        });

        context.aceSurveyReportData.memberQuestions.forEach((memberQuestionsElement) => {
            memberQuestionsElement.name === "Continuing terms with Supplier" &&
                updatedTabList.push(memberQuestionsElement.name) &&
                updatedTabContent.push({
                    content: (
                        <QuestionsDataChart
                            data={memberQuestionsElement.items.map((memberQuestionsItem) => ({
                                itemText: memberQuestionsItem.itemText,
                                data: [
                                    {
                                        name: "Yes",
                                        percentScore: memberQuestionsItem.score.Yes,
                                        category: "Yes",
                                    },
                                    {
                                        name: "No",
                                        percentScore: memberQuestionsItem.score.No,
                                        category: "No",
                                    },
                                ],
                            }))}
                            title={memberQuestionsElement.name}
                            colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                            questions
                            infoBubble
                            noInfoBubbleOnPrint
                            noInfoBubbles={noInfoBubbles}
                            extraStyling="print:break-before-page"
                            percentageAxisLabels={{
                                first: "0%",
                                second: "25%",
                                third: "50%",
                                fourth: "75%",
                                fifth: "100%",
                            }}
                            percentage
                            noTrendLegend
                            percentageAxisMargin
                            extraElements={
                                context.aceSurveyReportData.memberQuestions.find(
                                    (memberQuestionsElement) =>
                                        memberQuestionsElement.name === "Conditions for continuing terms with supplier"
                                ) && (
                                    <div className={"flex flex-col gap-12"}>
                                        <QuestionsDataChart
                                            data={context.aceSurveyReportData.memberQuestions
                                                .find(
                                                    (memberQuestionsElement) =>
                                                        memberQuestionsElement.name ===
                                                        "Conditions for continuing terms with supplier"
                                                )
                                                .items.map((memberQuestionsItem) => ({
                                                    itemText: memberQuestionsItem.itemText,
                                                    data: Object.entries(memberQuestionsItem.score).map(
                                                        ([name, percentScore]) => ({
                                                            name: name,
                                                            percentScore: percentScore,
                                                            category: name,
                                                        })
                                                    ),
                                                }))}
                                            title={"Conditions for continuing terms with supplier"}
                                            colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                                            questions
                                            infoBubble
                                            noInfoBubbleOnPrint
                                            extraStyling="print:break-before-page"
                                            axisLabels={{
                                                first: "0%",
                                                second: "25%",
                                                third: "50%",
                                                fourth: "75%",
                                                fifth: "99%",
                                            }}
                                            percentageAxisLabels={{
                                                first: "0%",
                                                second: "25%",
                                                third: "50%",
                                                fourth: "75%",
                                                fifth: "100%",
                                            }}
                                            percentage
                                            noContainer
                                            noTrendLegend
                                            percentageAxisMargin
                                        />

                                        {context.aceSurveyReportData.memberQuestions
                                            .find(
                                                (memberQuestionsElement) =>
                                                    memberQuestionsElement.name ===
                                                    "Conditions for continuing terms with supplier"
                                            )
                                            .items.map((memberQuestionsItem, index) => (
                                                <QualitativeItemSection
                                                    key={index}
                                                    data={{ responseTexts: memberQuestionsItem.otherTexts }}
                                                    title='"Other" responses'
                                                    noContainer
                                                />
                                            ))}
                                    </div>
                                )
                            }
                        />
                    ),
                    readonly: true,
                });
        });

        setTabList(updatedTabList);
        setTabContent(updatedTabContent);
    }, [
        context.aceSurveyReportData.currentSurveyLabel,
        context.aceSurveyReportData.memberQuestions,
        context.aceSurveyReportData.memberQuestionsWithMemberTypes,
        displayOnlyContent,
        noInfoBubbles,
    ]);

    return (
        <div className="flex flex-col gap-4">
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <TabbedNavigationLayout
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabList={tabList}
                    backgroundColour={"bg-white"}
                    tabContent={tabContent}
                    noContainer
                    displayOnlyContent={displayOnlyContent}
                    printGap="print:gap-8 print:-mt-6"
                    secondaryTabs
                />
            )}
        </div>
    );
}
