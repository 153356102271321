import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ArrowUturnLeftIcon, AtSymbolIcon, DevicePhoneMobileIcon, UserIcon } from "@heroicons/react/24/solid";

import {
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    CUSTOMER_PORTAL_PERMISSIONS,
    USER_GROUPS,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Input from "../../../SharedComponents/Form/Input";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Button from "../../../SharedComponents/Button";

export default function OrganisationUserProfile() {
    const [organisationUserDetails, setOrganisationUserDetails] = useState([]);

    const location = useLocation();

    const context = useContext(Context);

    const navigate = useNavigate();

    useEffect(() => {
        if (location.state.organisationUser) {
            setOrganisationUserDetails(location.state.organisationUser);
        }
    }, [location.state.organisationUser]);

    return (
        <DashboardLayout
            title={
                organisationUserDetails
                    ? organisationUserDetails.firstName + " " + organisationUserDetails.lastName
                    : ""
            }
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.VIEW_USER_PROFILES,
                CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST,
            ]}
        >
            <p className="mb-4 text-sm text-slate-500">Organisation user profile</p>
            <div className={"focus:outline-none shadow-sm border-0 rounded-t-xl w-full bg-white outline-none"}>
                <div
                    className={"p-4 flex justify-between flex-col gap-6 border border-slate-100 border-1 rounded-t-xl"}
                >
                    <div className="flex flex-row items-center gap-8">
                        <div className="flex-grow grid grid-rows-2 gap-6">
                            <div className="flex gap-6">
                                <Input
                                    id="name"
                                    type="text"
                                    label="First name"
                                    placeholder={organisationUserDetails.firstName === null && "Unavailable"}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    value={organisationUserDetails.firstName && organisationUserDetails.firstName}
                                    disabled
                                />
                                <Input
                                    id="name"
                                    type="text"
                                    label="Last name"
                                    placeholder={organisationUserDetails.lastName === null && "Unavailable"}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    value={organisationUserDetails.lastName && organisationUserDetails.lastName}
                                    disabled
                                />
                            </div>
                            <div className="flex gap-6">
                                <Input
                                    id="email"
                                    type="email"
                                    label="Email address"
                                    placeholder={organisationUserDetails.emailAddress === null && "Unavailable"}
                                    icon={<AtSymbolIcon className="h-4 w-4 text-slate-500" />}
                                    value={organisationUserDetails.emailAddress && organisationUserDetails.emailAddress}
                                    disabled
                                />
                                <Input
                                    id="contact-number"
                                    type="number"
                                    label="Contact number"
                                    placeholder={organisationUserDetails.mobileNumber === null && "Unavailable"}
                                    icon={<DevicePhoneMobileIcon className="h-4 w-4 text-slate-500" />}
                                    value={organisationUserDetails.mobileNumber && organisationUserDetails.mobileNumber}
                                    disabled
                                />
                            </div>
                            <div className="flex gap-6">
                                <Input
                                    id="userType"
                                    type="text"
                                    label="User type"
                                    placeholder={"Unassigned"}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    value={
                                        organisationUserDetails.userGroups
                                            ? organisationUserDetails.userGroups.includes(USER_GROUPS.COACH) &&
                                              organisationUserDetails.userGroups.includes(USER_GROUPS.COACHEE)
                                                ? `${context.terminology.coach.singular} and ${context.terminology.coachee.singular}`
                                                : organisationUserDetails.userGroups.includes(USER_GROUPS.COACH)
                                                ? `${context.terminology.coach.singular}`
                                                : organisationUserDetails.userGroups.includes(USER_GROUPS.COACHEE)
                                                ? `${context.terminology.coachee.singular}`
                                                : ""
                                            : ""
                                    }
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={
                    "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end " +
                    "text-right rounded-b-xl w-full"
                }
            >
                <div className="flex flex-row gap-3">
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GRAY}
                        text={"Back"}
                        iconPositionLeft
                        icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                        onClick={() => navigate(-1)}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}
