import React, { useEffect } from "react";
import { setSessionToken } from "../../utils/api";
import { REDIRECT_TYPES } from "../../utils/constants";
import customerPortalLogo from "../../assets/customer-portal-logo.png";
import SessionExpired from "../Errors/ErrorPageSections/SessionExpired";

const RedirectToCP = ({ redirectType }) => {
    useEffect(() => {
        switch (redirectType) {
            case REDIRECT_TYPES.RENEW_SESSION:
                window.location.replace(`${process.env.REACT_APP_RENEW_SESSION_REDIRECT_URL}`);
                break;
            case REDIRECT_TYPES.LOG_OUT:
                setSessionToken(null);
                window.location.replace(`${process.env.REACT_APP_LOG_OUT_REDIRECT_URL}`);
                break;
            default:
                throw new Error("Invalid redirect type");
        }
    }, [redirectType]);

    const rendeRenewSessionMessage = () => <SessionExpired />;

    const renderLogoutMessage = () => (
        <div className={"grid grid-flow-col grid-cols-2"}>
            <div>
                <h1 className={"font-serif text-2xl text-slate-600 mb-6"}>Logging out ...</h1>
            </div>
            <img className={"p-16 w-12"} alt="" src={customerPortalLogo} />
        </div>
    );

    return redirectType === REDIRECT_TYPES.RENEW_SESSION ? rendeRenewSessionMessage() : renderLogoutMessage();
};

export default RedirectToCP;
