import { useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { Context } from "../../../context/Context";
import ShortLogoPlaceholder from "./ShortLogoPlaceholder";
import BasicMotion from "../Motion/BasicMotion";

function DashboardPlaceholder() {
    const context = useContext(Context);

    const [takingLongerThanExpected, setTakingLongerThanExpected] = useState(false);

    const variants = {
        teal: {
            x: [50, 0, -50],
            y: [0, 0],
        },
        blue: {
            x: [-20, 40],
            y: [0, 0],
        },
        blue2: {
            x: [20, -40],
            y: [0, 0],
        },
    };

    const transition = {
        x: {
            duration: 0.8,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
        },
        y: {
            duration: 0.8,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
        },
    };

    const transitionTeal = {
        x: {
            duration: 1.6,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
        },
        y: {
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
        },
    };

    useEffect(() => {
        setTimeout(() => {
            setTakingLongerThanExpected(true);
        }, 20000);
    }, []);

    return (
        <div className={"flex flex-col gap-2 items-center justify-center h-screen -mt-24"}>
            <div className="flex items-center justify-center overflow-visible w-full h-48">
                <AnimatePresence>
                    <motion.svg
                        className={"overflow-visible"}
                        width={47}
                        height={41}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <motion.rect
                            width={48}
                            height={48}
                            rx={64}
                            variants={variants}
                            transition={transitionTeal}
                            animate="teal"
                            className={"fill-primary-800"}
                        />
                        <motion.rect
                            width={48}
                            height={48}
                            rx={64}
                            variants={variants}
                            transition={transition}
                            animate="blue"
                            className={"fill-primary-600"}
                        />
                        <motion.rect
                            width={48}
                            height={48}
                            rx={64}
                            variants={variants}
                            transition={transition}
                            animate="blue2"
                            className={"fill-primary-600"}
                        />
                    </motion.svg>
                </AnimatePresence>
            </div>
            {takingLongerThanExpected && (
                <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                    <BasicMotion>
                        <div className="flex flex-col gap-3 w-full text-center">
                            <span className="font-title text-primary-800">
                                Sorry! It seems to be taking a little longer than expected to load your content. Please
                                check your internet connection or{" "}
                                <a className={"font-semibold underline"} href="mailto:techsupport@psychpress.com.au">
                                    <span>contact us</span>
                                </a>{" "}
                                if this persists.
                            </span>
                        </div>
                    </BasicMotion>
                </div>
            )}
            <div className={"fixed bottom-0 w-full"}>
                <div className={"w-full p-8 flex items-center justify-center"}>
                    <div className="flex items-center p-6 bg-transparent">
                        {context.getImage("shortLogo") === "" ? (
                            <ShortLogoPlaceholder />
                        ) : (
                            <BasicMotion>
                                <img alt="logo" className="max-h-9" src={context.getImage("shortLogo")} />
                            </BasicMotion>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardPlaceholder;
