import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/solid";
import ReactPaginate from "react-paginate";

export default function Pagination(props) {
    const { handlePageClick, pageCount } = props;

    return (
        <ReactPaginate
            containerClassName={"pagination-container"}
            breakLabel="..."
            breakClassName="pagination-item-break"
            nextLabel={<ArrowLongRightIcon className="block h-4 w-4" />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={<ArrowLongLeftIcon className="block h-4 w-4" />}
            renderOnZeroPageCount={null}
            pageClassName="pagination-item"
            pageLinkClassName="pagination-link"
            previousClassName="pagination-item-previous"
            previousLinkClassName="pagination-link"
            nextClassName="pagination-item-next"
            nextLinkClassName="pagination-link"
            activeClassName="pagination-item-active"
        />
    );
}
