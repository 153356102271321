import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";

import { Context } from "../../../../context/Context";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";
import { BASIC_ANIMATION_VARIANTS } from "../../../../utils/constants";
import { NO_ACCESS_TYPES } from "../../../../utils/constants";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import SessionCard from "./SessionCard";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import CardsPlaceholder from "../../../SharedComponents/Loading/CardsPlaceholder";
import TabbedNavigationLayout from "../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import AccessControl from "../../../SharedComponents/AccessControl";
import { useLocation } from "react-router-dom";

export default function Sessions() {
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");

    const context = useContext(Context);

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <>
            {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_SESSIONS) ? (
                <DashboardLayout
                    title={"Sessions"}
                    requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_ALL_SESSIONS]}
                    actionButtonRequiredPermissions={[COACH_PLATFORM_PERMISSIONS.CREATE_SESSION]}
                    actionButton
                    actionButtonText={"Create Session"}
                    actionButtonIcon={<PlusIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                    actionButtonOnClick={() => navigate("/create-session")}
                >
                    <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />

                    {context.sessionsLoading ? (
                        <CardsPlaceholder active={context.sessionsLoading} />
                    ) : context?.organisationSessions && context?.userSessions ? (
                        <motion.div
                            initial={"inactive"}
                            animate={"active"}
                            exit={"inactive"}
                            transition={{ duration: 0.4 }}
                            variants={BASIC_ANIMATION_VARIANTS}
                        >
                            <AccessControl
                                requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_ALL_SESSIONS]}
                                noAccessType={NO_ACCESS_TYPES.REDIRECT}
                            >
                                <TabbedNavigationLayout
                                    tabIndex={tabIndex}
                                    setTabIndex={setTabIndex}
                                    tabList={["My sessions", "All sessions"]}
                                    backgroundColour={"bg-slate-100"}
                                    tabsRowOnly
                                    readonly
                                    tabContent={[
                                        {
                                            title: "My sessions",
                                            content: (
                                                <motion.div
                                                    initial={"inactive"}
                                                    animate={"active"}
                                                    exit={"inactive"}
                                                    transition={{ duration: 0.4 }}
                                                    variants={BASIC_ANIMATION_VARIANTS}
                                                >
                                                    <PaginatedSearch
                                                        items={context.userSessions ? context.userSessions : []}
                                                        itemName={"sessions"}
                                                        searchAttributes={[
                                                            "name",
                                                            "startTime",
                                                            "meetingUrl",
                                                            "meetingLocation",
                                                        ]}
                                                        ItemRow={SessionCard}
                                                        customItemsPerPage={9}
                                                        setAlert={setAlert}
                                                        cards
                                                    />
                                                </motion.div>
                                            ),
                                            readonly: true,
                                        },
                                        {
                                            title: "All sessions",
                                            content: (
                                                <motion.div
                                                    initial={"inactive"}
                                                    animate={"active"}
                                                    exit={"inactive"}
                                                    transition={{ duration: 0.4 }}
                                                    variants={BASIC_ANIMATION_VARIANTS}
                                                >
                                                    <PaginatedSearch
                                                        items={
                                                            context.organisationSessions
                                                                ? context.organisationSessions
                                                                : []
                                                        }
                                                        itemName={"sessions"}
                                                        searchAttributes={[
                                                            "name",
                                                            "startTime",
                                                            "meetingUrl",
                                                            "meetingLocation",
                                                        ]}
                                                        ItemRow={SessionCard}
                                                        customItemsPerPage={9}
                                                        setAlert={setAlert}
                                                        cards
                                                    />
                                                </motion.div>
                                            ),
                                            readonly: true,
                                        },
                                    ]}
                                    noContainer
                                    secondaryTabs
                                />
                            </AccessControl>
                        </motion.div>
                    ) : (
                        context?.userSessions &&
                        context?.userSessions.length === 0 &&
                        context?.organisationSessions &&
                        context?.organisationSessions.length === 0 && (
                            <motion.div
                                initial={"inactive"}
                                animate={"active"}
                                exit={"inactive"}
                                transition={{ duration: 0.4 }}
                                variants={BASIC_ANIMATION_VARIANTS}
                            >
                                <div className="text-sm text-slate-500">
                                    No sessions found. If you think this is incorrect, please get in touch with your
                                    relevant organisational contact to help set this up.
                                </div>
                            </motion.div>
                        )
                    )}

                    <Alert
                        type={ALERT_TYPE.WARNING}
                        active={context.userSessions == null || context.organisationSessions == null}
                    >
                        <div className="text-sm">
                            Oops! It looks like we are having some trouble retrieving your sessions. We are working on
                            it. If you require immediate assistance, please contact us at{" "}
                            <a
                                className="link text-primary-600 hover:text-primary-700"
                                href="mailto:techsupport@psychpress.com.au?subject=The assessments server is down."
                            >
                                techsupport@psychpress.com.au
                            </a>{" "}
                            or call us at{" "}
                            <a className="link text-primary-600 hover:text-primary-700" href="tel:+61396700590">
                                +61 (0)3 9670 0590
                            </a>
                        </div>
                    </Alert>
                </DashboardLayout>
            ) : (
                <DashboardLayout
                    title={"Sessions"}
                    requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_SESSIONS]}
                    actionButtonRequiredPermissions={[COACH_PLATFORM_PERMISSIONS.CREATE_SESSION]}
                    actionButton
                    actionButtonText={"Create Session"}
                    actionButtonIcon={<PlusIcon className="block h-3.5 w-3.5 mr-2" />}
                    actionButtonOnClick={() => navigate("/create-session")}
                >
                    {context.sessionsLoading ? (
                        <CardsPlaceholder active={context.sessionsLoading} />
                    ) : context?.userSessions && context?.userSessions.length > 0 ? (
                        <motion.div
                            initial={"inactive"}
                            animate={"active"}
                            exit={"inactive"}
                            transition={{ duration: 0.4 }}
                            variants={BASIC_ANIMATION_VARIANTS}
                        >
                            <PaginatedSearch
                                items={context.userSessions}
                                itemName={"sessions"}
                                searchAttributes={["name", "startTime", "meetingUrl", "meetingLocation"]}
                                ItemRow={SessionCard}
                                customItemsPerPage={9}
                                cards
                            />
                        </motion.div>
                    ) : (
                        context?.userSessions &&
                        context.userSessions.length === 0 && (
                            <motion.div
                                initial={"inactive"}
                                animate={"active"}
                                exit={"inactive"}
                                transition={{ duration: 0.4 }}
                                variants={BASIC_ANIMATION_VARIANTS}
                            >
                                <div className="text-sm text-slate-500">
                                    You haven't been assigned any sessions. If you think this is incorrect, please get
                                    in touch with your relevant organisational contact to help set this up.
                                </div>
                            </motion.div>
                        )
                    )}

                    <Alert type={ALERT_TYPE.WARNING} active={context.userSessions == null}>
                        <div className="text-sm">
                            Oops! It looks like we are having some trouble retrieving your sessions. We are working on
                            it. If you require immediate assistance, please contact us at{" "}
                            <a
                                className="link text-primary-600 hover:text-primary-700"
                                href="mailto:techsupport@psychpress.com.au?subject=The assessments server is down."
                            >
                                techsupport@psychpress.com.au
                            </a>{" "}
                            or call us at{" "}
                            <a className="link text-primary-600 hover:text-primary-700" href="tel:+61396700590">
                                +61 (0)3 9670 0590
                            </a>
                        </div>
                    </Alert>
                </DashboardLayout>
            )}
        </>
    );
}
