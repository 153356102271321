export default function DetailCard(props) {
    const { label, content, backgroundColor, textColour, testId } = props;

    return (
        <div
            data-testid={testId && testId}
            className={
                "flex flex-col gap-2 w-full p-3 rounded-xl shadow-sm " +
                (backgroundColor ? backgroundColor : "bg-slate-50")
            }
        >
            <div className={"flex justify-between w-full"}>
                <label className={"tracking-tight text-sm w-full text-slate-400"}>{label}</label>
            </div>
            <div
                className={
                    "flex flex-row gap-2 items-center font-medium text-sm " +
                    (textColour ? textColour : "text-primary-600")
                }
            >
                {content ? content : <span className="text-slate-300 italic font-normal">No answer</span>}
            </div>
        </div>
    );
}
