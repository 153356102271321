import { useCallback, useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { getRUThereUserCompletedInterviewRecordings } from "../../../../utils/api";
import {
    BASIC_ANIMATION_VARIANTS,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    STANDARD_TIME_FORMAT,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import RecordingRow from "./RecordingRow";
import BouncingBalls from "../../../SharedComponents/Loading/BouncingBalls";
import Tag from "../../../SharedComponents/Tag";

export default function InterviewRow(props) {
    const { item: interview, lastItem, setAlertType, setAlert } = props;

    const [recordingAlert, setRecordingAlert] = useState("");
    const [recordingAlertType, setRecordingAlertType] = useState(ALERT_TYPE.ERROR);
    const [userInterviewRecordings, setUserInterviewRecordings] = useState([]);
    const [userInterviewRecordingsLoading, setUserInterviewRecordingsLoading] = useState(false);
    const [showRecordings, setShowRecordings] = useState(false);

    const context = useContext(Context);

    const fetchUserInterviewRecordings = useCallback(async () => {
        setUserInterviewRecordingsLoading(true);
        try {
            const recordings = await getRUThereUserCompletedInterviewRecordings(
                interview.interviewId,
                interview.campaignId
            );

            recordings.forEach((recording) => {
                recording.interviewInfo = interview;

                recording?.reviews.find(
                    (review) =>
                        review.reviewerId === context.profileDetails.userIdentifier &&
                        (review.feedback !== null || review.score !== null)
                )
                    ? (recording.isEditable = true)
                    : (recording.isEditable = false);
            });

            setUserInterviewRecordings(recordings);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setUserInterviewRecordingsLoading(false);
    }, [context.profileDetails.userIdentifier, interview, setAlert, setAlertType]);

    const handleShowRecordings = () => {
        setShowRecordings((showRecordings) => !showRecordings);
        if (!showRecordings) {
            fetchUserInterviewRecordings();
        }
    };

    return (
        <>
            <tr className="group overflow-visible">
                <td className={"px-4 py-3 " + (lastItem && "rounded-bl-xl")}>
                    <div className={"text-sm font-medium text-primary-600 whitespace-pre-wrap"}>
                        {interview.campaignName}
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex flex-col gap-1">
                        <div className={"text-sm font-medium text-primary-600"}>
                            {interview.firstName + " " + interview.lastName}
                        </div>
                        <div className="text-sm text-slate-500">{interview.emailAddress}</div>
                        {interview.jobSeekerId && (
                            <div className="text-xs text-secondary-600 ">
                                JSID: <span className="font-medium">{interview.jobSeekerId}</span>
                            </div>
                        )}
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"text-sm text-slate-500"}>
                        {interview.deadline
                            ? new Date(interview.deadline).toLocaleString("en-AU", STANDARD_TIME_FORMAT)
                            : "-"}
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex items-center">
                        <Tag
                            status={
                                interview.completed
                                    ? "Completed"
                                    : interview.deadline !== null && Date.now() >= new Date(interview.deadline)
                                    ? "Expired"
                                    : "Assigned"
                            }
                        />
                    </div>
                </td>
                {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COMPLETED_INTERVIEW_RECORDINGS) && (
                    <td className={"px-4 py-3 " + (lastItem && "rounded-br-xl")}>
                        <div className={"flex flex-row items-center"}>
                            <Button
                                colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                icon={
                                    <ChevronRightIcon
                                        className={
                                            "h-4 w-4 stroke-current stroke-1 transition-transform transform " +
                                            (showRecordings && "rotate-90")
                                        }
                                    />
                                }
                                extraStyling={"text-xs h-8 w-8 rounded-full p-0 items-center flex justify-center"}
                                iconPositionLeft
                                onClick={handleShowRecordings}
                                disabled={!interview.completed}
                            />
                        </div>
                    </td>
                )}
            </tr>
            <AnimatePresence>
                {showRecordings && (
                    <tr className="transition-all">
                        <td className={"bg-slate-100 " + (lastItem && "rounded-b-xl")} colSpan={6}>
                            <motion.div
                                initial={"inactiveHeight"}
                                animate={"activeHeight"}
                                exit={"inactiveHeight"}
                                transition={{ duration: 0.4 }}
                                variants={BASIC_ANIMATION_VARIANTS}
                            >
                                {userInterviewRecordingsLoading ? (
                                    <div className={"flex items-center justify-center h-96"}>
                                        <BouncingBalls />
                                    </div>
                                ) : (
                                    <motion.section
                                        initial="collapsed"
                                        animate="expand"
                                        exit="collapsed"
                                        variants={{
                                            expand: { opacity: 1 },
                                            collapsed: { opacity: 0 },
                                        }}
                                        transition={{ duration: 0.4 }}
                                        className={"p-4 " + (lastItem && "rounded-b-xl")}
                                    >
                                        <Alert
                                            text={recordingAlert}
                                            type={recordingAlertType}
                                            active={recordingAlert}
                                            close={() => setRecordingAlert("")}
                                        />
                                        <PaginatedSearch
                                            items={userInterviewRecordings}
                                            itemName={"recordings"}
                                            searchAttributes={["campaignName", "question"]}
                                            columnNames={
                                                !context.userPermissions.includes(
                                                    COACH_PLATFORM_PERMISSIONS.SAVE_INTERVIEW_RECORDING_SCORE
                                                )
                                                    ? ["Question", "View recording", "View feedback", "Overall rating"]
                                                    : [
                                                          "Question",
                                                          "View recording",
                                                          "View feedback",
                                                          "Overall rating",
                                                          "Review",
                                                      ]
                                            }
                                            ItemRow={RecordingRow}
                                            customItemsPerPage={4}
                                            overflowVisible
                                            updateItemStatus={fetchUserInterviewRecordings}
                                            customPlaceholder="Search recordings e.g. Question content"
                                            setAlert={setRecordingAlert}
                                            setAlertType={setRecordingAlertType}
                                        />
                                    </motion.section>
                                )}
                            </motion.div>
                        </td>
                    </tr>
                )}
            </AnimatePresence>
        </>
    );
}
