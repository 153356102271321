import { COACH_PLATFORM_PERMISSIONS } from "../../../../../utils/constants";

import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import CareerToDate from "./CareerToDate";
import CareerToDatePieCharts from "./CareerToDatePieCharts";
import CurrentPosition from "./CurrentPosition";
import Experience from "./Experience";
import Education from "./Education";
import Preview from "./Preview";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";

export default function CareerProfile() {
    return (
        <DashboardLayout
            title={"Career Profile"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_CAREER_PROFILE]}
        >
            <TabbedNavigationLayout
                tabList={["Preview", "Current position", "Career-to-date", "Experience", "Education"]}
                backgroundColour={"bg-white"}
                tabContent={[
                    {
                        title: "Preview",
                        content: <Preview />,
                    },
                    {
                        title: "Current position",
                        content: <CurrentPosition />,
                    },
                    {
                        title: "Career to-date",
                        content: (
                            <>
                                <CareerToDate />
                                <CareerToDatePieCharts />
                            </>
                        ),
                    },
                    {
                        title: "Experience",
                        content: <Experience />,
                    },
                    {
                        title: "Education",
                        content: <Education />,
                    },
                ]}
            />
        </DashboardLayout>
    );
}
