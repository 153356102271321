import { useContext, useState } from "react";

import { Context } from "../../../../../../../../context/Context";

import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import AchievementItem from "../../ChartCards/AchievementItem";
import OverallAgreeanceLegend from "../../../../SharedChartComponents/OverallAgreeanceLegend";

export default function ResponseProportions(props) {
    const { displayOnlyContent } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const context = useContext(Context);

    const tabList = ["Achievement", "Lifestyle", "Leadership", "Connection", "Partnership", "Support"];

    // Needs refactoring, this can be a fraction of the size
    const tabContent = [
        {
            content: (
                <div className="flex flex-col gap-4">
                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">
                        Achievement
                    </h2>
                    <div className="flex flex-col gap-4 print:gap-8">
                        <OverallAgreeanceLegend />
                        {context.aceSurveyReportData.satisfactionProportions.map((satisfactionProportion) =>
                            satisfactionProportion.items.map(
                                (satisfactionProportionItem, index) =>
                                    satisfactionProportionItem.itemDimension === "Achievement" && (
                                        <AchievementItem
                                            key={index + "achievementItem" + satisfactionProportionItem.itemDimension}
                                            data={satisfactionProportionItem}
                                            noLegend
                                        />
                                    )
                            )
                        )}
                    </div>
                </div>
            ),
            readonly: true,
        },
        {
            content: (
                <div className="flex flex-col gap-4">
                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">
                        Lifestyle
                    </h2>
                    <div className="flex flex-col gap-4 print:gap-8">
                        <OverallAgreeanceLegend />
                        {context.aceSurveyReportData.satisfactionProportions.map((satisfactionProportion) =>
                            satisfactionProportion.items.map(
                                (satisfactionProportionItem, index) =>
                                    satisfactionProportionItem.itemDimension === "Lifestyle" && (
                                        <AchievementItem
                                            key={index + "achievementItem" + satisfactionProportionItem.itemDimension}
                                            data={satisfactionProportionItem}
                                            noLegend
                                        />
                                    )
                            )
                        )}
                    </div>
                </div>
            ),
            readonly: true,
        },
        {
            content: (
                <div className="flex flex-col gap-4">
                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">
                        Leadership
                    </h2>
                    <div className="flex flex-col gap-4 print:gap-8">
                        <OverallAgreeanceLegend />
                        {context.aceSurveyReportData.satisfactionProportions.map((satisfactionProportion) =>
                            satisfactionProportion.items.map(
                                (satisfactionProportionItem, index) =>
                                    satisfactionProportionItem.itemDimension === "Leadership" && (
                                        <AchievementItem
                                            key={index + "achievementItem" + satisfactionProportionItem.itemDimension}
                                            data={satisfactionProportionItem}
                                            noLegend
                                        />
                                    )
                            )
                        )}
                    </div>
                </div>
            ),
            readonly: true,
        },
        {
            content: (
                <div className="flex flex-col gap-4">
                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">
                        Connection
                    </h2>
                    <div className="flex flex-col gap-4 print:gap-8">
                        <OverallAgreeanceLegend />
                        {context.aceSurveyReportData.satisfactionProportions.map((satisfactionProportion) =>
                            satisfactionProportion.items.map(
                                (satisfactionProportionItem, index) =>
                                    satisfactionProportionItem.itemDimension === "Connection" && (
                                        <AchievementItem
                                            key={index + "achievementItem" + satisfactionProportionItem.itemDimension}
                                            data={satisfactionProportionItem}
                                            noLegend
                                        />
                                    )
                            )
                        )}
                    </div>
                </div>
            ),
            readonly: true,
        },
        {
            content: (
                <div className="flex flex-col gap-4">
                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">
                        Partnership
                    </h2>
                    <div className="flex flex-col gap-4 print:gap-8">
                        <OverallAgreeanceLegend />
                        {context.aceSurveyReportData.satisfactionProportions.map((satisfactionProportion) =>
                            satisfactionProportion.items.map(
                                (satisfactionProportionItem, index) =>
                                    satisfactionProportionItem.itemDimension === "Partnership" && (
                                        <AchievementItem
                                            key={index + "achievementItem" + satisfactionProportionItem.itemDimension}
                                            data={satisfactionProportionItem}
                                            noLegend
                                        />
                                    )
                            )
                        )}
                    </div>
                </div>
            ),
            readonly: true,
        },
        {
            content: (
                <div className="flex flex-col gap-4">
                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">
                        Support
                    </h2>
                    <div className="flex flex-col gap-4 print:gap-8">
                        <OverallAgreeanceLegend />
                        {context.aceSurveyReportData.satisfactionProportions.map((satisfactionProportion) =>
                            satisfactionProportion.items.map(
                                (satisfactionProportionItem, index) =>
                                    satisfactionProportionItem.itemDimension === "Support" && (
                                        <AchievementItem
                                            key={index + "achievementItem" + satisfactionProportionItem.itemDimension}
                                            data={satisfactionProportionItem}
                                            noLegend
                                        />
                                    )
                            )
                        )}
                    </div>
                </div>
            ),
            readonly: true,
        },
    ];

    return (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            tabContent={tabContent}
            tabsRowOnly
            secondaryTabs
            gapLessTabsAndContent
            noContainer
            displayOnlyContent={displayOnlyContent}
        />
    );
}
