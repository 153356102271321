export default function SausageLegendItem(props) {
    const {
        label,
        icon,
        backgroundColour,
        large,
        horizontal,
        hideOnPrint,
        textColour,
        onClick,
        clickable,
        line,
        chosen,
        isChoosable,
    } = props;

    return (
        <div
            onClick={onClick}
            className={
                "flex flex-row gap-2 items-center w-max font-medium " +
                (hideOnPrint ? "print:hidden " : "") +
                (clickable ? " cursor-pointer" : " cursor-auto")
            }
        >
            {icon ? (
                icon
            ) : line ? (
                <div className={"flex flex-row items-center transition-colors group "}>
                    <div
                        className={
                            "rounded-full w-2.5 h-2.5 transition-colors group-hover:shadow-md " +
                            (isChoosable ? (chosen ? backgroundColour : "bg-slate-300") : backgroundColour)
                        }
                    ></div>
                    <div
                        className={
                            "rounded-full w-4 h-1 -ml-1 transition-colors  group-hover:shadow-md " +
                            (isChoosable ? (chosen ? backgroundColour : "bg-slate-300") : backgroundColour)
                        }
                    ></div>
                    <div
                        className={
                            "rounded-full w-2.5 h-2.5 transition-colors -ml-1 group-hover:shadow-md " +
                            (isChoosable ? (chosen ? backgroundColour : "bg-slate-300") : backgroundColour)
                        }
                    ></div>
                </div>
            ) : (
                <div
                    className={
                        "rounded-xl transition-colors " +
                        backgroundColour +
                        " " +
                        (horizontal ? "w-2 h-4 " : "w-6 h-3 ")
                    }
                />
            )}
            {label && (
                <span
                    className={
                        "transition-colors w-32 truncate sm:w-full " +
                        (large ? "text-sm " : "text-xs ") +
                        (isChoosable
                            ? chosen
                                ? textColour
                                    ? textColour
                                    : "text-slate-400"
                                : "text-slate-600"
                            : "text-slate-600")
                    }
                >
                    {label}
                </span>
            )}
        </div>
    );
}
