import { useContext } from "react";

import { CUSTOMER_PORTAL_PERMISSIONS, COACH_PLATFORM_PERMISSIONS } from "../../utils/constants";
import { Context } from "../../context/Context";

import DashboardLayout from "./DashboardLayout";

import SetupCoacheeForm from "../Dashboard/Content/Coachees/SetupCoacheeForm";

export default function SetupCoachee() {
    const context = useContext(Context);

    return (
        <DashboardLayout
            title={"Setup " + context.terminology.coachee ? context.terminology.coachee.singular : "Coachee"}
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.SETUP_COACHEE,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
        >
            <p className="mb-4 text-sm text-slate-500">
                Please complete the form below to add a new{" "}
                {context.terminology.coachee ? context.terminology.coachee.singular : "Coachee"}
            </p>
            <SetupCoacheeForm />
        </DashboardLayout>
    );
}
