import { CalendarIcon, BuildingOffice2Icon, QuestionMarkCircleIcon, UserIcon } from "@heroicons/react/24/solid";

import Textarea from "../../../../SharedComponents/Form/Textarea";
import Input from "../../../../SharedComponents/Form/Input";

export default function Experience() {
    return (
        <>
            <div>
                <label className="text-sm font-medium text-slate-500 w-full ">Position 1</label>
                <div className="grid grid-cols-2 gap-6 mt-4">
                    <Input
                        id="roleTitle"
                        type="text"
                        label="Role title"
                        placeholder={"Role title"}
                        icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="organisation"
                        type="text"
                        label="Organisation"
                        placeholder={"Organisation"}
                        icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="startDate"
                        type="date"
                        label="Start date"
                        placeholder={"Start date"}
                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="endDate"
                        type="date"
                        label="End date"
                        placeholder={"End date"}
                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="location"
                        type="text"
                        label="Location"
                        placeholder={"Location"}
                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                    />
                </div>
                <div className={"mt-6"}>
                    <Textarea
                        id="description"
                        type="textarea"
                        label="Description"
                        placeholder={"Description"}
                        icon={<QuestionMarkCircleIcon className="h-4 w-4 text-slate-500" />}
                    />
                </div>
            </div>
            <div className={"mt-8"}>
                <label className="text-sm font-medium text-slate-500 w-full ">Position 2</label>
                <div className="grid grid-cols-2 gap-6 mt-4">
                    <Input
                        id="roleTitle"
                        type="text"
                        label="Role title"
                        placeholder={"Role title"}
                        icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="organisation"
                        type="text"
                        label="Organisation"
                        placeholder={"Organisation"}
                        icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="startDate"
                        type="date"
                        label="Start date"
                        placeholder={"Start date"}
                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="endDate"
                        type="date"
                        label="End date"
                        placeholder={"End date"}
                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="location"
                        type="text"
                        label="Location"
                        placeholder={"Location"}
                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                    />
                </div>
                <div className={"mt-6"}>
                    <Textarea
                        id="description"
                        type="textarea"
                        label="Description"
                        placeholder={"Description"}
                        icon={<QuestionMarkCircleIcon className="h-4 w-4 text-slate-500" />}
                    />
                </div>
            </div>
        </>
    );
}
