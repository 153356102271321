import { useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

import { getOneTimeUrl } from "../../../../utils/api";

import Button from "../../../SharedComponents/Button";
import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";

function AppCard(props) {
    const { name, description, colour, logoUrl, launchUrl, isStatic } = props;

    const [launching, setLaunching] = useState(false);

    const launchApp = async () => {
        setLaunching(true);
        try {
            if (isStatic) {
                window.open(launchUrl);
            } else {
                const oneTimeUrl = await getOneTimeUrl(name);
                window.open(oneTimeUrl);
            }
        } catch (err) {
            alert("Authentication failed, please try again or contact support.");
        }
        setLaunching(false);
    };

    return (
        <div
            className={
                "shadow-sm w-full h-full rounded-xl grid grid-flow-row bg-white border border-slate-100 border-1"
            }
        >
            <div className="p-4 grid grid-flow-row gap-5">
                <img className="h-6 justify-self-start" src={logoUrl} alt="logo" />
                <div className={"font-title font-medium text-base tracking-tight text-" + (colour + "-800")}>
                    {name}
                </div>
            </div>
            <div className="grid grid-flow-row gap-4 px-4 pb-4 rounded-b-xl">
                <div className="text-sm text-slate-500">{description}</div>
                <Button
                    onClick={launchApp}
                    colour={BUTTON_VARIANT_TYPES.UNCOLOURED_BUTTON}
                    text="Launch"
                    extraStyling={
                        "w-full grow-0 shadow-sm flex items-center justify-center self-end bg-" + (colour + "-600")
                    }
                    icon={<ArrowTopRightOnSquareIcon className={"block stroke-current stroke-1  h-3.5 w-3.5 ml-1"} />}
                    loading={launching}
                />
            </div>
        </div>
    );
}

export default AppCard;
