import JumpToLink from "../../../SharedChartComponents/JumpToLink";
import { ReactComponent as SubMenuItemArrow } from "../../../../../../../assets/return-down-forward-outline.svg";
import PrintToPDF from "../../../../../../SharedComponents/PrintToPDF";

export function JumpToLinksCapabilityProfile(props) {
    const {
        singles,
        shortReport,
        scrollToSection,
        organisationName,
        capabilityNames,
        reportRefs,
        setPrintToPDFMode,
        setNavigatingToPrint,
        navigatingToPrint,
        reportName,
    } = props;

    return singles ? (
        <div className={"flex gap-3 flex-wrap " + (singles ? "flex-row" : "flex-col")}>
            <JumpToLink
                text="Introduction"
                colour={"primary"}
                scrollTo={() => scrollToSection(reportRefs.introduction)}
                hidden={shortReport}
            />
            <JumpToLink
                text="Capability profile"
                colour={"primary"}
                scrollTo={() => scrollToSection(reportRefs.capabilityProfile)}
            />
            <JumpToLink
                text="Snapshot"
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.snapshot)}
            />
            <JumpToLink
                text="Capabilities summary"
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.capabilitiesSummary)}
            />
            <JumpToLink
                text="Detailed capability profile"
                colour={"primary"}
                scrollTo={() => scrollToSection(reportRefs.detailedCapabilityProfile)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Adaptive Mindset"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Adaptive Mindset"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.adaptiveMindset)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Collaboration"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Collaboration"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.collaboration)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Communication"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Communication"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.communication)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Creativity"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Creativity"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.creativity)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Critical Thinking"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Critical Thinking"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.criticalThinking)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Customer Focus"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Customer Focus"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.customerFocus)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Empathy"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Empathy"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.empathy)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Ethics"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Ethics"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.ethics)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Problem Solving & Data"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Problem Solving & Data"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.problemSolvingAndData)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Leadership"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Leadership"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.leadership)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Agile & Innovative"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Agile & Innovative"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.agileAndInnovative)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Direction & Purpose"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Direction & Purpose"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.directionAndPurpose)}
            />
            <JumpToLink
                text={
                    organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                        ? capabilityNames["Engagement & Coaching"].replace(/\s*\([^)]*\)/, "")
                        : capabilityNames["Engagement & Coaching"]
                }
                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                colour={"secondary"}
                scrollTo={() => scrollToSection(reportRefs.engagingAndCoaching)}
            />
            <PrintToPDF
                onlyButton
                setPrintToPDFMode={setPrintToPDFMode}
                setNavigatingToPrint={setNavigatingToPrint}
                navigatingToPrint={navigatingToPrint}
            />
        </div>
    ) : (
        <>
            <div className="flex flex-row gap-2 items-center">
                <div className="text-slate-500 font-medium text-sm">Jump to:</div>
            </div>
            <div className={"flex gap-3 flex-wrap " + (singles ? "flex-row" : "flex-col")}>
                <JumpToLink
                    text="Introduction"
                    colour={"primary"}
                    scrollTo={() => scrollToSection(reportRefs.introduction)}
                    hidden={shortReport}
                />
                <div className={"flex gap-2 flex-wrap " + (singles ? "flex-row" : "flex-col")}>
                    <JumpToLink
                        text="Capability profile"
                        colour={"primary"}
                        scrollTo={() => scrollToSection(reportRefs.capabilityProfile)}
                    />
                    <div className={"flex gap-2 flex-wrap " + (singles ? "flex-row" : "flex-col ml-2")}>
                        <JumpToLink
                            text="Snapshot"
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.snapshot)}
                        />
                        <JumpToLink
                            text="Capabilities summary"
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.capabilitiesSummary)}
                        />
                    </div>
                </div>
                <div className={"flex gap-2 flex-wrap " + (singles ? "flex-row" : "flex-col")}>
                    <JumpToLink
                        text="Detailed capability profile"
                        colour={"primary"}
                        scrollTo={() => scrollToSection(reportRefs.detailedCapabilityProfile)}
                    />
                    <div className={"flex gap-2 flex-wrap " + (singles ? "flex-row" : "flex-col ml-2")}>
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Adaptive Mindset"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Adaptive Mindset"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.adaptiveMindset)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Collaboration"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Collaboration"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.collaboration)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Communication"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Communication"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.communication)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Creativity"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Creativity"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.creativity)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Critical Thinking"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Critical Thinking"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.criticalThinking)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Customer Focus"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Customer Focus"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.customerFocus)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Empathy"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Empathy"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.empathy)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Ethics"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Ethics"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.ethics)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Problem Solving & Data"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Problem Solving & Data"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.problemSolvingAndData)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Leadership"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Leadership"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.leadership)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Agile & Innovative"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Agile & Innovative"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.agileAndInnovative)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Direction & Purpose"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Direction & Purpose"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.directionAndPurpose)}
                        />
                        <JumpToLink
                            text={
                                organisationName === "Social Futures" && reportName !== "LEAD Capability Profile"
                                    ? capabilityNames["Engagement & Coaching"].replace(/\s*\([^)]*\)/, "")
                                    : capabilityNames["Engagement & Coaching"]
                            }
                            icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                            colour={"secondary"}
                            scrollTo={() => scrollToSection(reportRefs.engagingAndCoaching)}
                        />
                    </div>
                    <div className={"block xl:hidden"}>
                        <PrintToPDF
                            onlyButton
                            setPrintToPDFMode={setPrintToPDFMode}
                            setNavigatingToPrint={setNavigatingToPrint}
                            navigatingToPrint={navigatingToPrint}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
