import { useContext, useState } from "react";
import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Modal from "../../../SharedComponents/Modal";
import SetupCoacheeForm from "./SetupCoacheeForm";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import Button from "../../../SharedComponents/Button";
import { ArrowUpTrayIcon, PaperAirplaneIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import SetupMultipleCoacheeRow from "./SetupMultipleCoacheeRow";
import { AnimatePresence } from "framer-motion";

export default function SetupMultipleCoachees() {
    const [alert, setAlert] = useState("");
    const [subAlert, setSubAlert] = useState("");
    const [alertType] = useState(ALERT_TYPE.ERROR);
    const [submitLoading] = useState(false);
    const [coacheeList, setCoacheeList] = useState([
        {
            firstName: "Gerardo",
            lastName: "Montoya",
            emailAddress: "gerardo@montoya.com",
            role: "Engineer",
            regionalManager: "Fabio Monsalve",
            region: "Victoria",
            emailSent: false,
        },
        {
            firstName: "Tomas",
            lastName: "Bonilla",
            emailAddress: "tomas@bonilla.com",
            role: "Tester",
            regionalManager: "Fabio Monsalve",
            region: "Victoria",
            emailSent: true,
        },
    ]);
    const [showAddCoacheeModal, setShowAddCoacheeModal] = useState(true);

    const context = useContext(Context);

    return (
        <DashboardLayout
            title={
                "Setup multiple " +
                (context.terminology.coachee ? context.terminology.coachee.plural.toLowerCase() : "Coachee")
            }
            requiredPermissions={[]}
            otherElements={
                <>
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GREEN}
                        text={"Upload .csv "}
                        icon={<ArrowUpTrayIcon className={"h-4 w-4 text-white ml-2 stroke-current stroke-1"} />}
                    />
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text={
                            "Setup " +
                            (context.terminology.coachee
                                ? context.terminology.coachee.singular.toLowerCase()
                                : "Coachee")
                        }
                        icon={<UserPlusIcon className={"h-4 w-4 text-white mr-2"} />}
                        iconPositionLeft
                        onClick={() => setShowAddCoacheeModal(!showAddCoacheeModal)}
                    />
                </>
            }
        >
            <p className="my-4 text-sm text-slate-500">
                Setup {context.terminology.coachee ? context.terminology.coachee.plural : "Coachees"} by clicking the
                "Setup {context.terminology.coachee ? context.terminology.coachee.singular : "Coachees"}" or bulk setup
                by uploading a .csv file via buttons on the top-right. Newly added{" "}
                {context.terminology.coachee ? context.terminology.coachee.plural : "Coachees"} will appear in the list
                below. Once you've reviewd details, you may send off email invites in bulk by clicking "Send all email
                invitations" or by sending them individually by clicking the "Send invitation" button at the end of each{" "}
                {context.terminology.coachee ? context.terminology.coachee.singular : "Coachees"} row.
            </p>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <Alert
                text={<div className="flex flex-col gap-2">{subAlert}</div>}
                type={ALERT_TYPE.WARNING}
                active={subAlert}
                close={() => setSubAlert("")}
            />
            <div className="flex flex-col gap-4">
                <div className="flex flex-row justify-between items-center">
                    <h2 className="text-lg text-primary-600 font-medium tracking-tight">
                        Newly setup {context.terminology.coachee ? context.terminology.coachee.plural : "Coachees"}
                    </h2>
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text={"Send all email invitations"}
                        icon={<PaperAirplaneIcon className={"h-4 w-4 text-white ml-2"} />}
                    />
                </div>
                <PaginatedSearch
                    loading={submitLoading}
                    items={coacheeList}
                    itemName={context.terminology.coachee ? context.terminology.coachee.plural : "Coachees"}
                    searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                    columnNames={["Details", "Role", "Region", "Regional manager", "Edit", "Email"]}
                    ItemRow={SetupMultipleCoacheeRow}
                    customPlaceholder={
                        "Search " +
                        (context.terminology.coachee ? context.terminology.coachee.plural.toLowerCase() : "coachees") +
                        " by first name, last name or email address"
                    }
                />
            </div>
            <AnimatePresence>
                <Modal
                    active={showAddCoacheeModal}
                    closeModal={() => setShowAddCoacheeModal(!showAddCoacheeModal)}
                    content={
                        <div className="w-[400px] md:w-[800px]">
                            <SetupCoacheeForm
                                multipleCoacheeSetup
                                setCoacheeList={setCoacheeList}
                                closeParentModal={() => setShowAddCoacheeModal(!showAddCoacheeModal)}
                            />
                        </div>
                    }
                />
            </AnimatePresence>
        </DashboardLayout>
    );
}
