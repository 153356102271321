import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUturnLeftIcon, UserIcon, VideoCameraIcon } from "@heroicons/react/24/solid";

import { getCoaches } from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, NO_ACCESS_TYPES } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import AccessControl from "../../../SharedComponents/AccessControl";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";

export default function AddPracticeInterviewReviewer() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coachesLoading, setCoachesLoading] = useState(false);
    const [selectableCoaches, setSelectableCoaches] = useState([]);

    const context = useContext(Context);

    const navigate = useNavigate();

    const fetchCoaches = async () => {
        setCoachesLoading(true);
        try {
            const response = await getCoaches();
            response.forEach((coach) => {
                setSelectableCoaches((coachees) => [
                    {
                        value: coach.userIdentifier,
                        label: coach.firstName + " " + coach.lastName + " (" + coach.emailAddress + ")",
                    },
                    ...coachees,
                ]);
            });
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setCoachesLoading(false);
    };

    useEffect(() => {
        fetchCoaches();
    }, []);

    return (
        <DashboardLayout
            title="Add reviewer to practice interview"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH]}
        >
            <p className="mb-4 text-sm text-slate-500">
                Please complete the form below to add a reviewer to a practice interview
            </p>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4"}>
                        <div className={"mb-6"}>
                            <div className={"grid grid-flow-row xl:grid-cols-2 gap-6"}>
                                <div className={"flex flex-col gap-3"}>
                                    <MultiSelect
                                        items={selectableCoaches}
                                        labelText={
                                            context.terminology.coach ? context.terminology.coach.singular : "Coach"
                                        }
                                        loading={coachesLoading}
                                        icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <MultiSelect
                                        items={[
                                            {
                                                value: "Hospitality, Interviewee: Fabio Monsalve (fm@psychpress.com.au)",
                                                label: "Hospitality, Interviewee: Fabio Monsalve (fm@psychpress.com.au)",
                                            },
                                        ]}
                                        labelText={"Practice interview"}
                                        loading={coachesLoading}
                                        icon={<VideoCameraIcon className="h-4 w-4 text-slate-500" />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end " +
                            "text-right rounded-b-xl w-full"
                        }
                    >
                        <div className="flex flex-row gap-3">
                            <Button
                                colour={BUTTON_VARIANT_TYPES.GRAY}
                                text={"Back"}
                                iconPositionLeft
                                icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                                onClick={() => navigate(-1)}
                            />
                            <AccessControl requiredPermissions={[]} NO_ACCESS_TYPES={NO_ACCESS_TYPES.BLANK}>
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                    text="Add reviewer to practice interview"
                                    iconPositionLeft
                                />
                            </AccessControl>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
