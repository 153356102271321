import { motion } from "framer-motion";
import { BASIC_ANIMATION_VARIANTS } from "../../utils/constants";

function Modal(props) {
    const { active, content, closeModal } = props;

    return (
        <>
            {active ? (
                <>
                    <motion.div
                        initial={"inactive"}
                        animate={["active", { zIndex: 30 }]}
                        exit={"inactive"}
                        transition={{ duration: 0.4 }}
                        variants={BASIC_ANIMATION_VARIANTS}
                        className={"fixed m-auto inset-x-0 inset-y-0 w-max h-max"}
                    >
                        <div
                            className={
                                "relative w-auto mx-auto max-w-3xl " +
                                "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed " +
                                "inset-0 outline-none focus:outline-none"
                            }
                        >
                            {content}
                        </div>
                    </motion.div>
                    <div onClick={closeModal} className="opacity-40 fixed inset-0 z-20 bg-black" />
                </>
            ) : null}
        </>
    );
}

export default Modal;
