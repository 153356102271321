import { RectangleStackIcon, TableCellsIcon } from "@heroicons/react/24/solid";

import Input from "../../../../SharedComponents/Form/Input";

export default function CohortName(props) {
    const { cohort, setCohortNewName } = props;

    return (
        <div className={"flex flex-col gap-6"}>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-6">
                <Input
                    id="cohortName"
                    type="text"
                    label="Cohort name"
                    placeholder={"Cohort name"}
                    icon={<TableCellsIcon className="h-4 w-4 text-slate-500" />}
                    value={cohort.name}
                    onChange={setCohortNewName}
                />
                <Input
                    id="program"
                    type="text"
                    label="Program"
                    placeholder={"Program"}
                    icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                    value={cohort.program}
                    disabled
                />
            </div>
        </div>
    );
}
