import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUturnLeftIcon, BriefcaseIcon, ClockIcon, UserIcon } from "@heroicons/react/24/solid";

import {
    recommendRUThereInterview,
    getCoachees,
    getCoacheesForCoach,
    getRUThereCampaigns,
} from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, NO_ACCESS_TYPES } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import AccessControl from "../../../SharedComponents/AccessControl";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import Input from "../../../SharedComponents/Form/Input";

export default function RecommendPracticeInterview() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coacheesLoading, setCoacheesLoading] = useState(false);
    const [createInterviewLoading, setCreateInterviewLoading] = useState(false);
    const [selectableCoachees, setSelectableCoachees] = useState([]);
    const [selectableInterviewCampaigns, setSelectableInterviewCampaigns] = useState([]);
    const [campaignId, setCampaignId] = useState();
    const [candidateId, setCandidateId] = useState();
    const [deadline, setDeadline] = useState();
    const [allCoachees, setAllCoachees] = useState(false);
    const [allCoacheesLoading, setAllCoacheesLoading] = useState(true);

    const context = useContext(Context);

    const navigate = useNavigate();

    const handleRecommendInterview = async () => {
        setCreateInterviewLoading(true);

        if (!candidateId) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Invalid user selected. Recommend practice interview is unsuccessful");
            setCreateInterviewLoading(false);
            return;
        }

        if (!campaignId) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Invalid job type selected. Recommend practice interview is unsuccessful");
            setCreateInterviewLoading(false);
            return;
        }

        try {
            await recommendRUThereInterview(campaignId, candidateId, deadline);

            const recommendedCampaign = selectableInterviewCampaigns.find((campaign) => campaign.value === campaignId);
            const coachee = selectableCoachees.find((campaign) => campaign.value === candidateId);

            navigate(`/${context.terminology.coachee.singular.toLowerCase()}-interviews`, {
                state: {
                    alertType: ALERT_TYPE.SUCCESS,
                    alertMessage:
                        'Successfully recommended "' + recommendedCampaign.label + '" interview to ' + coachee.label,
                },
            });
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setCreateInterviewLoading(false);
    };

    const fetchCoachees = useCallback(async () => {
        if (!allCoacheesLoading) {
            setCoacheesLoading(true);

            let coachees = [];
            try {
                if (allCoachees) {
                    coachees = await getCoachees();
                } else {
                    coachees = await getCoacheesForCoach();
                }

                coachees.forEach((coachee) => {
                    setSelectableCoachees((coachees) => [
                        {
                            value: coachee.userIdentifier,
                            label: coachee.firstName + " " + coachee.lastName + " (" + coachee.emailAddress + ")",
                        },
                        ...coachees,
                    ]);
                });
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setCoacheesLoading(false);
        }
        setCoacheesLoading(false);
    }, [allCoachees, allCoacheesLoading]);

    const fetchRUThereCampaigns = async () => {
        setCoacheesLoading(true);

        try {
            const rUTHereCampaignsResponse = await getRUThereCampaigns();

            rUTHereCampaignsResponse.forEach((campaign) => {
                setSelectableInterviewCampaigns((selectableCampaigns) => [
                    {
                        value: campaign.id,
                        label: campaign.name,
                    },
                    ...selectableCampaigns,
                ]);
            });
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setCoacheesLoading(false);
    };

    useEffect(() => {
        fetchCoachees();
    }, [fetchCoachees]);

    useEffect(() => {
        fetchRUThereCampaigns();
    }, []);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllCoachees(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES));
            setAllCoacheesLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    return (
        <DashboardLayout
            title="Recommend practice interview"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.CREATE_PRACTICE_INTERVIEW]}
        >
            <p className="mb-4 text-sm text-slate-500">
                <span>Please complete the form below to send a practice interview recommendation to a </span>
                {context.terminology.coachee ? context.terminology.coachee.singular : "Coachee"}
            </p>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4 border border-slate-100 border-1 rounded-t-xl"}>
                        <div className={"mb-6"}>
                            <div className={"grid grid-flow-row xl:grid-cols-2 gap-6"}>
                                <div className={"flex flex-col gap-3"}>
                                    <MultiSelect
                                        items={selectableCoachees}
                                        labelText={
                                            context.terminology.coachee
                                                ? context.terminology.coachee.singular
                                                : "Coachee"
                                        }
                                        loading={coacheesLoading}
                                        icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                        setChosenItems={setCandidateId}
                                    />
                                </div>
                                <MultiSelect
                                    items={selectableInterviewCampaigns}
                                    labelText="Job types"
                                    loading={coacheesLoading}
                                    icon={<BriefcaseIcon className="h-4 w-4 text-slate-500" />}
                                    setChosenItems={setCampaignId}
                                />
                                <Input
                                    id="dob"
                                    type="datetime-local"
                                    label="Deadline"
                                    placeholder="Deadline"
                                    icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setDeadline}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end text-right " +
                            "rounded-b-xl w-full"
                        }
                    >
                        <div className="flex flex-row gap-3">
                            <Button
                                colour={BUTTON_VARIANT_TYPES.GRAY}
                                text={"Back"}
                                iconPositionLeft
                                icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                                onClick={() => navigate(-1)}
                            />
                            <AccessControl requiredPermissions={[]} NO_ACCESS_TYPES={NO_ACCESS_TYPES.BLANK}>
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                    text="Recommend practice interview"
                                    iconPositionLeft
                                    loading={createInterviewLoading}
                                    onClick={handleRecommendInterview}
                                />
                            </AccessControl>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
