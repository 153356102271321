import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ArrowUturnLeftIcon,
    UserIcon,
    DocumentCheckIcon,
    CalendarDaysIcon,
    InformationCircleIcon,
    BriefcaseIcon,
    RectangleStackIcon,
    ArrowsUpDownIcon,
    BarsArrowUpIcon,
    BarsArrowDownIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as WhistleIcon } from "../../../../assets/whistle.svg";
import Tippy from "@tippyjs/react";

import {
    assignAssessment,
    assignAssessmentInBulk,
    assignAssessmentInBulkOnBehalf,
    getActiveBatchDetails,
    getCoachees,
    getCoacheesForCoach,
    getCoaches,
    getOrganisationWorkflows,
    getPrograms,
    getUserCohortsCoachees,
} from "../../../../utils/api";
import {
    ACE_2_SURVEY_PLATFORM,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    CUSTOMER_PORTAL_PERMISSIONS,
    ON_BEHALF_OF_PRODUCT_NAMES,
    SORT_TYPES,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";
import { sortAlphabetically } from "../../../../utils/sorting";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import Input from "../../../SharedComponents/Form/Input";
import CheckBox from "../../../SharedComponents/Form/CheckBox";
import FatDropdown from "../../../SharedComponents/FatDropdown";
import FancyRadio from "../../../SharedComponents/Form/FancyRadio";
import AssignAssessmentBulkForm from "./AssignAssessmentBulkForm";

export default function AssignAssessment(props) {
    const { bulk } = props;

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [workflowIdentifier, setWorkflowIdentifier] = useState();
    const [coachee, setCoachee] = useState(null);
    const [coach, setCoach] = useState(null);
    const [activeBatch, setActiveBatch] = useState(null);
    const [workflowDueDate, setWorkflowDueDate] = useState();
    const [loading, setLoading] = useState(false);
    const [coacheesLoading, setCoacheesLoading] = useState(false);
    const [coachesLoading, setCoachesLoading] = useState(false);
    const [workflowsLoading, setWorkflowsLoading] = useState(false);
    const [activeBatchLoading, setActiveBatchLoading] = useState(false);
    const [selectableCoachees, setSelectableCoachees] = useState([]);
    const [selectableCoaches, setSelectableCoaches] = useState([]);
    const [selectableWorkflows, setSelectableWorkflows] = useState([]);
    const [allCoachees, setAllCoachees] = useState(false);
    const [allCoacheesLoading, setAllCoacheesLoading] = useState(true);
    const [userCohortsCoachees, setUserCohortsCoachees] = useState(false);
    const [isOnBehalfOf, setIsOnBehalfOf] = useState(false);
    const [workflows, setWorkflows] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState({});
    const [sortByType, setSortByType] = useState("alphabeticallyAsc");
    const [keepAssessmentsOpen, setKeepAssessmentsOpen] = useState(false);
    const [showSortsReference, setShowSortsReference] = useState(false);
    const [coachees, setCoachees] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [chosenPrograms, setChosenPrograms] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [chosenCohorts, setChosenCohorts] = useState([]);
    const [programsLoading, setProgramsLoading] = useState(false);

    const location = useLocation();

    const navigate = useNavigate();

    const context = useContext(Context);

    const userProfile = context.profileDetails;

    const assessmentSelectRef = useRef();

    const sortButtonRef = useRef();

    const handleAssignAssessment = async () => {
        setLoading(true);

        if (!workflowIdentifier) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert("Please select an assessment to assign");
            setLoading(false);
            return;
        }

        if (isOnBehalfOf && coach && coachee && coach.userIdentifier === coachee.userIdentifier) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert("Unable to assign an assessment to the same user to complete on behalf of");
            setLoading(false);
            return;
        }

        if (activeBatchLoading) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert("Loading assessment batch in progress, please wait and try again");
            setLoading(false);
            return;
        }

        let selectedUserIdentifiers = [];

        if (bulk) {
            coachees.forEach((coachee) => {
                if (coachee.selected || coachee.individuallySelected) {
                    selectedUserIdentifiers.push(coachee.userIdentifier);
                }
            });
        }

        try {
            if (isOnBehalfOf) {
                const workflow = workflows.find((workflow) => workflow.workflowIdentifier === workflowIdentifier);

                if (!ON_BEHALF_OF_PRODUCT_NAMES.includes(workflow.productName)) {
                    setAlertType(ALERT_TYPE.WARNING);
                    setAlert("Sorry, the selected assessment is not applicable to be completed on behalf of");
                    setLoading(false);
                    return;
                }

                if (coach && context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHES)) {
                    if (bulk) {
                        await assignAssessmentInBulkOnBehalf(
                            workflowIdentifier,
                            coach.userIdentifier,
                            selectedUserIdentifiers,
                            workflowDueDate,
                            activeBatch?.identifier
                        );
                    } else {
                        await assignAssessment(
                            coach.userIdentifier,
                            coachee.userIdentifier,
                            coachee.emailAddress,
                            workflowIdentifier,
                            coach.emailAddress,
                            coach.firstName,
                            coach.lastName,
                            workflowDueDate,
                            activeBatch?.identifier
                        );
                    }
                } else {
                    if (bulk) {
                        await assignAssessmentInBulkOnBehalf(
                            workflowIdentifier,
                            userProfile.userIdentifier,
                            selectedUserIdentifiers,
                            workflowDueDate,
                            activeBatch?.identifier
                        );
                    } else {
                        await assignAssessment(
                            userProfile.userIdentifier,
                            coachee.userIdentifier,
                            coachee.emailAddress,
                            workflowIdentifier,
                            userProfile.emailAddress,
                            userProfile.firstName,
                            userProfile.lastName,
                            workflowDueDate,
                            activeBatch?.identifier
                        );
                    }
                }
            } else {
                if (bulk) {
                    await assignAssessmentInBulk(
                        selectedUserIdentifiers,
                        null,
                        workflowIdentifier,
                        workflowDueDate,
                        activeBatch?.identifier
                    );
                } else {
                    await assignAssessment(
                        coachee.userIdentifier,
                        null,
                        null,
                        workflowIdentifier,
                        coachee.emailAddress,
                        coachee.firstName,
                        coachee.lastName,
                        workflowDueDate,
                        activeBatch?.identifier
                    );
                }
            }

            setAlert("Successfully assigned assessment");
            setAlertType(ALERT_TYPE.SUCCESS);
        } catch (error) {
            if (
                error.message ===
                "The participant already completed this assessment within last 30 days. Get in touch with the program manager to get access to the report."
            ) {
                setWorkflowIdentifier(null);
                setAlertType(ALERT_TYPE.WARNING);
                setAlert(error.message);
            } else {
                setAlertType(ALERT_TYPE.ERROR);
                setAlert(error.message);
            }
        }

        setLoading(false);
    };

    const fetchAssessments = useCallback(async () => {
        setWorkflowsLoading(true);

        try {
            const response = await getOrganisationWorkflows();
            setWorkflows(response);

            const sortedAssessments = sortAlphabetically(response, true, true, "productName");

            let sanitizedWorkflows = sanitiseWorkflows(sortedAssessments);
            setSelectableWorkflows(sanitizedWorkflows);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setWorkflowsLoading(false);
    }, []);

    function sanitiseWorkflows(workflows) {
        let sanitizedWorkflows = [];

        workflows.reverse().forEach((workflow) => {
            sanitizedWorkflows = [
                {
                    value: workflow.workflowIdentifier,
                    label: workflow.productName,
                    subLabel:
                        ON_BEHALF_OF_PRODUCT_NAMES.includes(workflow.productName) &&
                        "(Applicable to complete on behalf of)",
                },
                ...sanitizedWorkflows,
            ];
        });

        return sanitizedWorkflows;
    }

    const fetchCoachees = useCallback(async () => {
        setCoacheesLoading(true);

        if (!allCoacheesLoading) {
            try {
                let coacheesResponse = [];

                let cohortsList = [];

                if (allCoachees) {
                    coacheesResponse = await getCoachees();
                } else if (userCohortsCoachees) {
                    coacheesResponse = await getUserCohortsCoachees();
                } else {
                    coacheesResponse = await getCoacheesForCoach();
                }

                let programObjectsList = [];

                if (bulk) {
                    setProgramsLoading(true);
                    const programs = await getPrograms();

                    programObjectsList = programs;

                    const programsList = [];

                    programs.forEach((program) => {
                        programsList.push(program.name);
                    });

                    setPrograms(programsList);
                }

                coacheesResponse.forEach((coachee) => {
                    setSelectableCoachees((coachees) => [
                        {
                            value: coachee,
                            label: coachee.firstName + " " + coachee.lastName + " (" + coachee.emailAddress + ")",
                        },
                        ...coachees,
                    ]);

                    if (bulk) {
                        coachee.selected = false;
                        coachee.individuallySelected = false;

                        coachee.programs = [];

                        if (coachee.cohorts !== null && coachee.cohorts.length !== 0) {
                            coachee.cohorts.forEach((cohort) => {
                                if (!cohortsList.includes(cohort)) {
                                    cohortsList.push(cohort);
                                }

                                programObjectsList.forEach((program) => {
                                    if (program.cohorts.includes(cohort)) {
                                        if (!coachee.programs.includes(program.name)) {
                                            coachee.programs.push(program.name);
                                        }
                                    }
                                });
                            });
                        }
                    }
                });

                coacheesResponse = sortAlphabetically(coacheesResponse, true, false, "firstName");
                setCoachees(coacheesResponse);

                if (bulk) {
                    setCohorts(cohortsList);
                }
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
        }

        setProgramsLoading(false);
        setCoacheesLoading(false);
    }, [allCoachees, allCoacheesLoading, bulk, userCohortsCoachees]);

    const fetchCoaches = useCallback(async () => {
        setCoachesLoading(true);

        try {
            let response = "";

            response = await getCoaches();

            response.forEach((coach) => {
                setSelectableCoaches((coaches) => [
                    {
                        value: coach,
                        label: coach.firstName + " " + coach.lastName + " (" + coach.emailAddress + ")",
                    },
                    ...coaches,
                ]);
            });
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setCoachesLoading(false);
    }, []);

    const fetchActiveBatch = useCallback(async (productName) => {
        setActiveBatchLoading(true);

        try {
            const response = await getActiveBatchDetails(productName);
            setActiveBatch(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setActiveBatchLoading(false);
    }, []);

    function handleSortByType(sortByType) {
        setKeepAssessmentsOpen(true);

        let sortedAssessments = [];

        switch (sortByType) {
            case "alphabeticallyAsc":
                sortedAssessments = sortAlphabetically(workflows, true, false, "productName");
                break;
            case "alphabeticallyDesc":
                sortedAssessments = sortAlphabetically(workflows, false, false, "productName");
                break;
            case "assessmentType":
                sortedAssessments = sortAlphabetically(workflows, true, true, "type");
                break;
            default:
                break;
        }

        const sanitizedWorkflows = sanitiseWorkflows(sortedAssessments);
        setSelectableWorkflows(sanitizedWorkflows);
    }

    function handleClickListener(event) {
        if (!assessmentSelectRef.current.contains(event.target)) {
            setKeepAssessmentsOpen(false);
        }

        if (sortButtonRef.current.contains(event.target)) {
            setKeepAssessmentsOpen(true);
        }
    }

    if (location.state?.coacheeUserIdentifier) {
        setCoachee(location.state.coacheeUserIdentifier);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickListener);

        return () => {
            document.removeEventListener("mousedown", handleClickListener);
        };
    }, []);

    useEffect(() => {
        fetchCoachees();
    }, [fetchCoachees]);

    useEffect(() => {
        if (context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHES)) {
            fetchCoaches();
        }
    }, [context.userPermissions, fetchCoaches]);

    useEffect(() => {
        fetchAssessments();
    }, [fetchAssessments]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllCoachees(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES));
            setUserCohortsCoachees(
                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_USER_COHORTS_COACHEES)
            );
            setAllCoacheesLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    useEffect(() => {
        if (workflowIdentifier && workflows && context.defaultApp === ACE_2_SURVEY_PLATFORM) {
            const workflow = workflows.find((workflow) => workflow.workflowIdentifier === workflowIdentifier);
            fetchActiveBatch(workflow.productName);
        }
    }, [context.defaultApp, fetchActiveBatch, workflowIdentifier, workflows]);

    useEffect(() => {
        setKeepAssessmentsOpen(false);

        if (workflowIdentifier) {
            const selectedWorkflow = workflows.find((workflow) => workflow.workflowIdentifier === workflowIdentifier);
            setSelectedWorkflow(selectedWorkflow);
        }
    }, [workflowIdentifier, workflows]);

    const Sorts = () => {
        return (
            <div
                className={
                    "flex flex-col text-sm font-title text-primary-800 justify-start print:hidden " +
                    "bg-white shadow-xl rounded-xl border-slate-100 border overflow-hidden"
                }
            >
                <FancyRadio
                    testId={"alphabetically-toggle-test-id"}
                    labelText={
                        <div className="flex flex-row gap-2 items-center">
                            <span className="font-mono font-medium">A-Z</span>
                            <BarsArrowUpIcon className="block stroke-current stroke-1 h-3 w-3 mr-2" />
                        </div>
                    }
                    value={"alphabeticallyAsc"}
                    name={"sortBy"}
                    colour={"secondary"}
                    setValue={() => setSortByType("alphabeticallyAsc")}
                    minimal
                    defaultChecked={"alphabeticallyAsc" === sortByType}
                    handleSort={handleSortByType}
                />
                <FancyRadio
                    testId={"alphabetically-toggle-test-id"}
                    labelText={
                        <div className="flex flex-row gap-2 items-center">
                            <span className="font-mono font-medium">Z-A</span>
                            <BarsArrowDownIcon className="block stroke-current stroke-1 h-3 w-3 mr-2" />
                        </div>
                    }
                    value={"alphabeticallyDesc"}
                    name={"sortBy"}
                    colour={"secondary"}
                    setValue={() => setSortByType("alphabeticallyDesc")}
                    minimal
                    defaultChecked={"alphabeticallyDesc" === sortByType}
                    handleSort={handleSortByType}
                    noBorder
                />
            </div>
        );
    };

    return (
        <DashboardLayout
            title={bulk ? "Assign assessment in bulk" : "Assign assessment"}
            requiredPermissions={
                bulk
                    ? [
                          CUSTOMER_PORTAL_PERMISSIONS.BULK_ASSIGN_ASSESSMENT,
                          COACH_PLATFORM_PERMISSIONS.BULK_ASSIGN_ASSESSMENT,
                      ]
                    : [CUSTOMER_PORTAL_PERMISSIONS.ASSIGN_ASSESSMENTS]
            }
        >
            <p className="mb-4 text-sm text-slate-500">Please complete the form below to assign an assessment</p>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className="focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none">
                <div className="p-4 border border-slate-100 border-1 rounded-t-xl pb-6">
                    <div className="grid grid-flow-row xl:grid-cols-2 gap-6">
                        {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHES) && (
                            <MultiSelect
                                items={selectableCoaches}
                                labelText={context.terminology.coach ? context.terminology.coach.singular : "Coach"}
                                loading={coachesLoading}
                                setChosenItems={setCoach}
                                value={coach}
                                icon={<WhistleIcon className="h-4 w-4 fill-slate-500" />}
                            />
                        )}
                        {!bulk && (
                            <MultiSelect
                                items={selectableCoachees}
                                labelText={
                                    context.terminology.coachee ? context.terminology.coachee.singular : "Coachee"
                                }
                                loading={coacheesLoading}
                                setChosenItems={setCoachee}
                                value={coachee}
                                icon={
                                    context.defaultApp === "Employment Services" ? (
                                        <BriefcaseIcon className="h-4 w-4 text-slate-500" />
                                    ) : (
                                        <UserIcon className="h-4 w-4 text-slate-500" />
                                    )
                                }
                            />
                        )}
                        <Input
                            id="workflowDueDate"
                            type="datetime-local"
                            label="Due date"
                            icon={<CalendarDaysIcon className="h-4 w-4 text-slate-500" />}
                            placeholder={"Assessment due date"}
                            onChange={setWorkflowDueDate}
                            optional
                        />
                        <div className="flex flex-col gap-2 w-full">
                            <Tippy
                                content={`Having this checked, you will act upon the ${context.terminology.coachee.singular.toLowerCase()} to complete the assessment, else the assessment will be assigned to the ${context.terminology.coachee.singular.toLowerCase()}'s account`}
                                className={
                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 text-white mb-8"
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"right"}
                                hideOnClick={false}
                            >
                                <div className={"flex flex-row gap-2 items-center w-fit"}>
                                    <label className="tracking-tight text-sm font-medium text-primary-600 ">
                                        Completion type
                                    </label>
                                    <InformationCircleIcon className="h-4 w-4 text-primary-600" />
                                </div>
                            </Tippy>
                            <div className="mt-2 flex flex-row gap-6">
                                <CheckBox
                                    id="onBehalfOf"
                                    name="completionType"
                                    value={isOnBehalfOf}
                                    labelText={`Complete assessment on behalf of the ${context.terminology.coachee.singular.toLowerCase()}`}
                                    setValue={setIsOnBehalfOf}
                                />
                            </div>
                            <div className={"text-xs text-slate-400 w-full text-right mt-3"}>Optional</div>
                        </div>
                        {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                            <Input
                                id="assessmentBatch"
                                type="text"
                                label="Assessment Batch"
                                icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                placeholder={"Applicable only for ACE Survey"}
                                currentValue={activeBatch ? activeBatch.name : ""}
                                valueOnly
                                optional
                                disabled
                            />
                        )}
                        <div className="col-span-full flex flex-col gap-3" ref={assessmentSelectRef}>
                            <MultiSelect
                                selectRef={assessmentSelectRef}
                                items={selectableWorkflows}
                                labelText="Assessment"
                                loading={workflowsLoading}
                                setChosenItems={setWorkflowIdentifier}
                                value={workflowIdentifier}
                                icon={<DocumentCheckIcon className="h-4 w-4 text-slate-500" />}
                                subLabel={true}
                                extraStyling={"col-span-full"}
                                extraSelectStyling={showSortsReference ? "md:mr-[120px] md:!w-auto" : "mr-0"}
                                borderRadius="0rem"
                                menuIsOpen={keepAssessmentsOpen}
                                extraElements={
                                    <FatDropdown
                                        fatDropdownRef={sortButtonRef}
                                        text={SORT_TYPES[sortByType]}
                                        position="md:top-[37px] md:right-0 right-0 bottom-10"
                                        icon={
                                            <ArrowsUpDownIcon className="block stroke-current stroke-1 h-3 w-3 mr-2" />
                                        }
                                        noIcon
                                        backgroundColour="bg-white"
                                        buttonVariant={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                                        onClose={() => setKeepAssessmentsOpen(false)}
                                        closeFilters={keepAssessmentsOpen}
                                        setOpenStateRef={setShowSortsReference}
                                        content={<Sorts />}
                                        smallButton
                                    />
                                }
                            />
                            {selectedWorkflow.productOverview && (
                                <div className="flex flex-col gap-2 col-span-full bg-blue-50 border border-blue-100 rounded-xl p-2 shadow-sm">
                                    <div className="flex items-center justify-between">
                                        <div className="text-blue-600 font-medium text-base ">
                                            {selectedWorkflow.productName}
                                        </div>
                                        <InformationCircleIcon
                                            className={"block h-5 w-5 text-blue-600 fill-blue-600"}
                                        />
                                    </div>
                                    <p className="text-blue-600 text-sm h-40 md:h-full overflow-y-scroll">
                                        {selectedWorkflow.productOverview}
                                    </p>
                                </div>
                            )}
                        </div>

                        {bulk && (
                            <AssignAssessmentBulkForm
                                coachees={coachees}
                                setCoachees={setCoachees}
                                coacheesLoading={coacheesLoading}
                                programs={programs}
                                cohorts={cohorts}
                                chosenPrograms={chosenPrograms}
                                setChosenPrograms={setChosenPrograms}
                                chosenCohorts={chosenCohorts}
                                setChosenCohorts={setChosenCohorts}
                                programsLoading={programsLoading}
                            />
                        )}
                    </div>
                </div>
                <div
                    className={
                        "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end " +
                        "text-right rounded-b-xl w-full"
                    }
                >
                    <div className="flex flex-row gap-3">
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Assign assessment"
                            icon={<DocumentCheckIcon className="block h-3.5 w-3.5 mr-2" />}
                            iconPositionLeft
                            onClick={handleAssignAssessment}
                            loading={loading}
                            disabled={!context.userPermissions.includes(CUSTOMER_PORTAL_PERMISSIONS.ASSIGN_ASSESSMENTS)}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
