import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import JobActivityCard from "./JobActivityCard";

export default function JobActivity() {
    const jobActivity = [
        {
            jobTitle: "UI/UX Designer",
            company: "Psych Press",
            companyLogo: "https://www.psychpress.com.au/html/assets/img/Logo_PP.png",
            applied: true,
            interviewRating: "Very well",
            interviewComments:
                "Sugar aroma, filter black milk crema, brewed roast a et dark. Aged filter seasonal, " +
                "foam wings, ristretto rich roast robusta mug carajillo. Roast, iced single shot aroma dripper " +
                "decaffeinated, cinnamon café au lait at qui cappuccino",
            dateLiked: "13/4/2022",
            companyRating: 4,
        },
        {
            jobTitle: "Graphic Designer",
            company: "Menu Driven",
            companyLogo: "https://pychpress-general.s3.ap-southeast-2.amazonaws.com/menu_driven_logo_variant_2.svg",
            applied: true,
            interviewRating: "Ok",
            interviewComments:
                "Single shot, breve, fair trade con panna qui acerbic rich. Grinder id, decaffeinated in fair trade " +
                "froth mocha kopi-luwak americano decaffeinated. Coffee, fair trade iced flavour aroma foam siphon " +
                "aged milk froth extra. Grounds acerbic black, milk black aromatic half and half body crema.",
            dateLiked: "5/6/2022",
            companyRating: 2,
        },
        {
            jobTitle: "UI/UX Developer",
            company: "Sports Notes",
            companyLogo: "https://pychpress-general.s3.ap-southeast-2.amazonaws.com/sports-notes-logo.svg",
            applied: false,
            interviewRating: null,
            interviewComments: null,
            dateLiked: "5/6/2022",
            companyRating: null,
        },
        {
            jobTitle: "UI Developer",
            company: "Resume Bot",
            companyLogo: "https://pychpress-general.s3.ap-southeast-2.amazonaws.com/resume_builder_logo.svg",
            applied: true,
            interviewRating: "Very badly",
            interviewComments:
                "Coffee whipped frappuccino carajillo café au lait mug flavour affogato carajillo acerbic grinder. " +
                "Viennese ristretto grinder viennese foam saucer cappuccino.",
            dateLiked: "5/6/2022",
            companyRating: 5,
        },
        {
            jobTitle: "Frontend Developer",
            company: "Menu Driven",
            companyLogo: "https://pychpress-general.s3.ap-southeast-2.amazonaws.com/menu_driven_logo_variant_2.svg",
            applied: true,
            interviewRating: "Very well",
            interviewComments:
                "Coffee whipped frappuccino carajillo café au lait mug flavour affogato carajillo acerbic grinder. " +
                "Viennese ristretto grinder viennese foam saucer cappuccino.",
            dateLiked: "5/6/2022",
            companyRating: 5,
        },
    ];

    return (
        <PaginatedSearch
            items={jobActivity}
            itemName={"jobs"}
            searchAttributes={["jobTitle", "company"]}
            ItemRow={JobActivityCard}
            cards
        />
    );
}
