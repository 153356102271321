import { useRef } from "react";
import { useLocation } from "react-router";
import {
    AcademicCapIcon,
    BookOpenIcon,
    BuildingOffice2Icon,
    IdentificationIcon,
    MagnifyingGlassCircleIcon,
    TrophyIcon,
    UserIcon,
} from "@heroicons/react/24/solid";

import DetailCard from "./DetailCard";
import JumpToLink from "../../SharedChartComponents/JumpToLink";
import SideSection from "../../SharedChartComponents/SideSection";
import HeadFirst from "../HeadFirst/HeadFirst";
import GraphicalSummary from "./GraphicalSummary";
import ReportHeader from "../../SharedChartComponents/ReportHeader";

export default function JobReadiness(props) {
    const { score, testId } = props;

    const location = useLocation();

    const introduction = useRef(null);
    const personalDetails = useRef(null);
    const LLN = useRef(null);
    const personalCircumstances = useRef(null);
    const educationAndEmployment = useRef(null);
    const goalsAndAspirations = useRef(null);
    const jobHabits = useRef(null);
    const employmentCommencement = useRef(null);
    const summary = useRef(null);
    const detailedSummary = useRef(null);
    const recommendations = useRef(null);
    const headFirst = useRef(null);

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop - (window.innerWidth < 1280 ? 170 : 20),
            behavior: "smooth",
        });
    };

    return (
        <div className="flex flex-col gap-6" data-testid={testId && testId}>
            <ReportHeader reportName={score.reportName} userName={location.state?.userName} />
            <div
                className={
                    "flex flex-row gap-2 print:hidden sticky top-4 left-4 bg-white p-3 rounded-xl " +
                    "border border-slate-100 shadow-lg z-50 relative xl:hidden print:hidden"
                }
            >
                <div className="flex flex-row flex-wrap gap-3">
                    <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                    <JumpToLink text="Introduction" colour={"blue"} scrollTo={() => scrollToSection(introduction)} />
                    <JumpToLink
                        text="Personal details"
                        colour={"purple"}
                        scrollTo={() => scrollToSection(personalDetails)}
                    />
                    <JumpToLink
                        text="Language, Literacy, Numeracy"
                        colour={"indigo"}
                        scrollTo={() => scrollToSection(LLN)}
                    />
                    <JumpToLink
                        text="Personal Circumstances"
                        colour={"sky"}
                        scrollTo={() => scrollToSection(personalCircumstances)}
                    />
                    <JumpToLink
                        text="Education & employment"
                        colour={"teal"}
                        scrollTo={() => scrollToSection(educationAndEmployment)}
                    />
                    <JumpToLink
                        text="Goals & Aspirations"
                        colour={"emerald"}
                        scrollTo={() => scrollToSection(goalsAndAspirations)}
                    />
                    <JumpToLink text="Job Search Habits" colour={"lime"} scrollTo={() => scrollToSection(jobHabits)} />
                    <JumpToLink
                        text="Employment Commencement"
                        colour={"yellow"}
                        scrollTo={() => scrollToSection(employmentCommencement)}
                    />
                    <JumpToLink text="Summary" colour={"blue"} scrollTo={() => scrollToSection(summary)} />
                    <JumpToLink
                        text="Detailed report"
                        colour={"blue"}
                        scrollTo={() => scrollToSection(detailedSummary)}
                    />
                    <JumpToLink
                        text="Recommendations"
                        colour={"blue"}
                        scrollTo={() => scrollToSection(recommendations)}
                    />
                    <JumpToLink text="HeadFirst" colour={"blue"} scrollTo={() => scrollToSection(headFirst)} />
                </div>
            </div>
            <div className={"grid grid-cols-1 xl:grid-cols-3 gap-6"}>
                <div className="col-span-2 flex flex-col gap-8">
                    <div className="col-span-2 flex flex-col gap-8">
                        <div ref={introduction} className="flex flex-col gap-4">
                            <div className="text-primary-600 font-medium">Introduction</div>
                            <div className="bg-white rounded-xl p-4 shadow-sm border border-slate-100">
                                <div className="flex flex-col gap-2">
                                    <p className="text-slate-500 text-sm">{score.reportDescription}</p>
                                </div>
                            </div>
                            <div className="bg-white rounded-xl p-4 shadow-sm border border-slate-100">
                                <div className="flex flex-col gap-2">
                                    <p className="text-slate-500 text-sm">{score.jobReadiness.applicant}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="text-primary-600 font-medium">Responses</div>
                            <div
                                className={
                                    "p-4 print:p-0 rounded-xl bg-white shadow-sm border " +
                                    "border-slate-100 col-span-2 flex flex-col gap-8"
                                }
                            >
                                <div
                                    ref={personalDetails}
                                    data-testid="personalDetailsSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-purple-600 font-medium"}>
                                        <IdentificationIcon className="h-4 w-4" />
                                        Personal details
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                            <DetailCard
                                                label={"Name"}
                                                content={
                                                    score.jobReadiness.reportFields.title +
                                                    " " +
                                                    score.jobReadiness.reportFields.firstName +
                                                    " " +
                                                    score.jobReadiness.reportFields.surname
                                                }
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Email address"}
                                                content={score.jobReadiness.reportFields.emailAddress}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Date of birth"}
                                                content={score.jobReadiness.reportFields.dob}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Age"}
                                                content={score.jobReadiness.reportFields.age}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Job Seeker ID"}
                                                content={score.jobReadiness.reportFields.jobSeekerID}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Phone number"}
                                                content={score.jobReadiness.reportFields.activePhoneNumber}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have an appropriate message bank?"}
                                                content={score.jobReadiness.reportFields.appropriateMessageBank}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"State"}
                                                content={score.jobReadiness.reportFields.state}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                            <DetailCard
                                                label={"Current Employment Service Program"}
                                                content={score.jobReadiness.reportFields.employmentServiceProgram}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Office location"}
                                                content={score.jobReadiness.reportFields.officeLocation}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                            <DetailCard
                                                label={"Referring consultant name"}
                                                content={score.jobReadiness.reportFields.consultantName}
                                                backgroundColor={"bg-purple-50"}
                                                textColour={"text-purple-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div ref={LLN} data-testid="lLNSection" className="flex flex-col gap-4">
                                    <div className={"flex flex-row items-center gap-2 text-indigo-600 font-medium"}>
                                        <BookOpenIcon className="h-4 w-4" />
                                        Language, Literacy, Numeracy
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                            <DetailCard
                                                label={"Is English your native language?"}
                                                content={score.jobReadiness.reportFields.englishNativeLanguage}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={"What is your native language?"}
                                                content={score.jobReadiness.reportFields.nativeLanguage}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={"Do you need an interpreter?"}
                                                content={score.jobReadiness.reportFields.interpreter}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={"Rate your ability to Read/Write/Speak English"}
                                                content={score.jobReadiness.reportFields.englishAbility}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have any concerns with your vision or wear glasses currently?"
                                                }
                                                content={score.jobReadiness.reportFields.visionOrGlasses}
                                                backgroundColor={"bg-indigo-50"}
                                                textColour={"text-indigo-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ref={personalCircumstances}
                                    data-testid="personalCircumstancesSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-sky-600 font-medium"}>
                                        <UserIcon className="h-4 w-4" />
                                        Personal Circumstances
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={
                                                    "Is there anything we should know or consider when supporting you to " +
                                                    "find employment?"
                                                }
                                                content={score.jobReadiness.reportFields.findingWork}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "What supports do you feel you may need in order to successfully obtain " +
                                                    "and maintain employment?"
                                                }
                                                content={score.jobReadiness.reportFields.maintainingEmployment}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"What is your current employment status?"}
                                                content={score.jobReadiness.reportFields.employmentStatus}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"Where are you currently employed?"}
                                                content={score.jobReadiness.reportFields.employerName}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"How many hours on average are your working?"}
                                                content={score.jobReadiness.reportFields.averageHoursOfWork}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Is there anything that could assist in you getting additional hours?"
                                                }
                                                content={score.jobReadiness.reportFields.additionalHoursAssist}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"What has been your main activity over the last two years?"}
                                                content={score.jobReadiness.reportFields.mainActivityOverLastTwoYears}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"How long have you been on a Centrelink benefit for?"}
                                                content={score.jobReadiness.reportFields.centrelink}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={"Would you pass a pre-employment medical?"}
                                                content={score.jobReadiness.reportFields.preEmploymentMedical}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Is there anything else we should know that could impact you capacity " +
                                                    "to find and maintain employment or the hours you could work?"
                                                }
                                                content={score.jobReadiness.reportFields.anythingElseMaintainEmployment}
                                                backgroundColor={"bg-sky-50"}
                                                textColour={"text-sky-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ref={educationAndEmployment}
                                    data-testid="educationAndEmploymentSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-teal-600 font-medium"}>
                                        <AcademicCapIcon className="h-4 w-4" />
                                        Education & employment
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"What is the highest qualification you have completed?"}
                                                content={score.jobReadiness.reportFields.highestQualification}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Can these qualifications be used to gain employment?"}
                                                content={score.jobReadiness.reportFields.qualificationsAndEmployment}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Why/Why Not?"}
                                                content={score.jobReadiness.reportFields.qualificationsAndEmploymentWhy}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Please include details of the qualifications obtained"}
                                                content={score.jobReadiness.reportFields.qualificationsDetails}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do you hold any of the below Licenses/Tickets"}
                                                content={score.jobReadiness.reportFields.licenses}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Please choose the type of truck license you hold"}
                                                content={score.jobReadiness.reportFields.truckLicenses}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have copies of all of your Tickets/Licences?"}
                                                content={score.jobReadiness.reportFields.licenseCopies}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Do any of your licences/tickets require updating?"}
                                                content={score.jobReadiness.reportFields.licenseCopiesUpdating}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"What is needed to be able to update licenses/tickets?"}
                                                content={score.jobReadiness.reportFields.licenseCopiesUpdatingNeeds}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you currently have or could you obtain a Working With Children Check " +
                                                    "and/or a Police Check?"
                                                }
                                                content={score.jobReadiness.reportFields.wwccPoliceCheck}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "A skill is having the ability to do something or complete a task, We all " +
                                                    "have skills and some can be obtained even while you’re not at work. Please tell us about some of your skills"
                                                }
                                                content={score.jobReadiness.reportFields.skills}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                            <DetailCard
                                                label={"Availability to work"}
                                                content={score.jobReadiness.reportFields.availability}
                                                backgroundColor={"bg-teal-50"}
                                                textColour={"text-teal-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ref={goalsAndAspirations}
                                    data-testid="goalsAndAspirationsSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-emerald-600 font-medium"}>
                                        <TrophyIcon className="h-4 w-4" />
                                        Goals & Aspirations
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"Do you have any short-term goals?"}
                                                content={score.jobReadiness.reportFields.shortTermGoals}
                                                backgroundColor={"bg-emerald-50"}
                                                textColour={"text-emerald-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have any long-term goals?"}
                                                content={score.jobReadiness.reportFields.longTermGoals}
                                                backgroundColor={"bg-emerald-50"}
                                                textColour={"text-emerald-600"}
                                            />
                                            <DetailCard
                                                label={"What industries are you interested in working in?"}
                                                content={
                                                    score.jobReadiness.reportFields.industries &&
                                                    score.jobReadiness.reportFields.industries.includes("|")
                                                        ? score.jobReadiness.reportFields.industries
                                                              .split("|")
                                                              .join(", ")
                                                        : score.jobReadiness.reportFields.industries
                                                }
                                                backgroundColor={"bg-emerald-50"}
                                                textColour={"text-emerald-600"}
                                            />
                                            <DetailCard
                                                label={"Are you interested in an apprenticeships or traineeships?"}
                                                content={score.jobReadiness.reportFields.interestInAppreticeships}
                                                backgroundColor={"bg-emerald-50"}
                                                textColour={"text-emerald-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Are there any tickets, licenses or qualifications you would like to " +
                                                    "undertake or think would help you obtain employment?"
                                                }
                                                content={
                                                    score.jobReadiness.reportFields.ticketsLicensesToObtainEmployment
                                                }
                                                backgroundColor={"bg-emerald-50"}
                                                textColour={"text-emerald-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div ref={jobHabits} data-testid="jobHabitsSection" className="flex flex-col gap-4">
                                    <div className={"flex flex-row items-center gap-2 text-lime-600 font-medium"}>
                                        <MagnifyingGlassCircleIcon className="h-4 w-4" />
                                        Job Search Habits, Knowledge, Resources, Confidence, Motivation
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={"How motivated are you to find work?"}
                                                content={score.jobReadiness.reportFields.lookingForEmploymentMotivation}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"How do you look for work? Nominate what is applicable."}
                                                content={score.jobReadiness.reportFields.howDoYouLookForWork}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "How confident do you feel looking for employment & job searching?"
                                                }
                                                content={score.jobReadiness.reportFields.lookingForEmploymentHow}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Can you provide more detail about your choice above ('How confident do you feel looking for employment & job searching?')?"
                                                }
                                                content={
                                                    score.jobReadiness.reportFields
                                                        .lookingForEmploymentConfidenceDetails
                                                }
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Currently, how actively are you searching for work?"}
                                                content={
                                                    score.jobReadiness.reportFields.activelySearchingForEmploymentHow
                                                }
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"On average how many jobs do you apply for a month?"}
                                                content={
                                                    score.jobReadiness.reportFields
                                                        .lookingForEmploymentHowManyJobsDetails
                                                }
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have an up to date resume?"}
                                                content={score.jobReadiness.reportFields.upToDateResume}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"Do you have 2 current/recent references on your resume?"}
                                                content={score.jobReadiness.reportFields.appropriateReferences}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Select some tasks that you currently utilise technology (internet, phones, laptops, tablets) for"
                                                }
                                                content={score.jobReadiness.reportFields.technology}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={"How confident do you feel to attend job interviews?"}
                                                content={score.jobReadiness.reportFields.confidenceJobInterviews}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Are you registered on MyGov and linked to your jobsearch dashboard?"
                                                }
                                                content={score.jobReadiness.reportFields.mygovRegistration}
                                                backgroundColor={"bg-lime-50"}
                                                textColour={"text-lime-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ref={employmentCommencement}
                                    data-testid="employmentCommencementSection"
                                    className="flex flex-col gap-4"
                                >
                                    <div className={"flex flex-row items-center gap-2 text-yellow-600 font-medium"}>
                                        <BuildingOffice2Icon className="h-4 w-4" />
                                        Employment Commencement & Retention
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2 lg:grid lg:grid-cols-1 2xl:grid-cols-2 gap-4">
                                            <DetailCard
                                                label={
                                                    "If you were to obtain employment tomorrow – How confident would you feel about commencing and remaining in that role?"
                                                }
                                                content={score.jobReadiness.reportFields.confidenceMaintainingRole}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={"Why do you feel that way?"}
                                                content={
                                                    score.jobReadiness.reportFields.confidenceMaintainingRoleDetails
                                                }
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "On average how long do you normally retain employment if you find employment?"
                                                }
                                                content={score.jobReadiness.reportFields.employmentRetention}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={"Why did you leave your last role?"}
                                                content={score.jobReadiness.reportFields.whyLeaveLastRole}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have appropriate work related identity documents " +
                                                    "(Example: Proof of Age, Driver’s License, Birth Certificate)"
                                                }
                                                content={score.jobReadiness.reportFields.idDocuments}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "If you were to obtain employment – how would you get to and from the " +
                                                    "workplace?"
                                                }
                                                content={score.jobReadiness.reportFields.transport}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                            <DetailCard
                                                label={
                                                    "Do you have your Pay, Super Tax & Employment information available to " +
                                                    "provide to an employer"
                                                }
                                                content={score.jobReadiness.reportFields.paySuperTax}
                                                backgroundColor={"bg-yellow-50"}
                                                textColour={"text-yellow-600"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GraphicalSummary
                            score={score}
                            summaryRef={summary}
                            detailedReportRef={detailedSummary}
                            recommendationsRef={recommendations}
                        />
                        {score.headfirst && (
                            <div
                                ref={headFirst}
                                data-testid="headFirstSection"
                                className="col-span-2 flex flex-col gap-6"
                            >
                                <HeadFirst score={score.headfirst} onlyContent />
                            </div>
                        )}
                    </div>
                </div>
                <SideSection
                    extraContent={
                        <div
                            className={
                                "flex flex-col gap-4 print:hidden sticky top-4 left-4 bg-white p-4 rounded-xl " +
                                "shadow-lg z-50 hidden w-auto xl:flex 2xl:w-max print:hidden mt-9"
                            }
                        >
                            <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                            <div className="flex flex-col gap-3">
                                <JumpToLink
                                    text="Introduction"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(introduction)}
                                />
                                <JumpToLink
                                    text="Personal details"
                                    icon={<IdentificationIcon className="h-4 w-4" />}
                                    colour={"purple"}
                                    scrollTo={() => scrollToSection(personalDetails)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Language, Literacy, Numeracy"
                                    icon={<BookOpenIcon className="h-4 w-4" />}
                                    colour={"indigo"}
                                    scrollTo={() => scrollToSection(LLN)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Personal Circumstances"
                                    icon={<UserIcon className="h-4 w-4" />}
                                    colour={"sky"}
                                    scrollTo={() => scrollToSection(personalCircumstances)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Education & employment"
                                    icon={<AcademicCapIcon className="h-4 w-4" />}
                                    colour={"teal"}
                                    scrollTo={() => scrollToSection(educationAndEmployment)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Goals & Aspirations"
                                    icon={<TrophyIcon className="h-4 w-4" />}
                                    colour={"emerald"}
                                    scrollTo={() => scrollToSection(goalsAndAspirations)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Job Search Habits"
                                    icon={<MagnifyingGlassCircleIcon className="h-4 w-4" />}
                                    colour={"lime"}
                                    scrollTo={() => scrollToSection(jobHabits)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text=" Employment Commencement"
                                    icon={<BuildingOffice2Icon className="h-4 w-4" />}
                                    colour={"yellow"}
                                    scrollTo={() => scrollToSection(employmentCommencement)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Summary"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(summary)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Detailed report"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(detailedSummary)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="Recommendations"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(recommendations)}
                                    topPlacement
                                />
                                <JumpToLink
                                    text="HeadFirst"
                                    colour={"blue"}
                                    scrollTo={() => scrollToSection(headFirst)}
                                    topPlacement
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
}
