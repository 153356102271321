export default function JumpToLink(props) {
    const { text, icon, colour, scrollTo, topPlacement, testId, smallText, hidden } = props;

    return (
        text && (
            <span
                data-testid={
                    testId
                        ? testId
                        : topPlacement
                        ? text.replace(/[ ,.]/g, "").toLowerCase() + "Top"
                        : text.replace(/[ ,.]/g, "").toLowerCase() + "RightSide"
                }
                className={"w-fit text-sm " + (hidden ? "hidden" : "block")}
                onClick={scrollTo}
            >
                <div
                    className={
                        "flex flex-row items-center gap-2 text-sm text-" +
                        (colour + "-600 hover:text-") +
                        (colour + "-800 ") +
                        (smallText ? "text-xs " : "text-sm ") +
                        "transition-colors font-medium underline cursor-pointer"
                    }
                >
                    {icon && icon}
                    {text}
                </div>
            </span>
        )
    );
}
