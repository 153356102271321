import { ResponsiveRadar } from "@nivo/radar";

import { CAPABILITIES_VGCCC_COMMON, CAPABILITIES_VGCCC_CORE } from "../../../../../../../utils/constants";
import outerLegend from "../../../../../../../assets/outerllegend.svg";

import SausageLegendItem from "../../../Charts/CustomCharts/CustomLegends/SausageLegendItem";

export function SnapshotRadarChart(props) {
    const {
        organisationName,
        chosenCategoriesBigRadar,
        handleFilteredRadarFieldsChange,
        filteredRadarFields,
        maxValue,
    } = props;

    function gridLabel(labelProps) {
        return (
            <g transform={`translate(${labelProps.x}, ${labelProps.y})`}>
                <text
                    fontSize={11}
                    fill={
                        organisationName === "sampleOrgThatNeedsThisAtSomePoint"
                            ? CAPABILITIES_VGCCC_CORE.includes(labelProps.id)
                                ? "#059669"
                                : CAPABILITIES_VGCCC_COMMON.includes(labelProps.id)
                                ? "#3B82F6"
                                : "#64748b"
                            : "#64748b"
                    }
                    dominantBaseline="central"
                    textAnchor={labelProps.anchor}
                >
                    {labelProps.id}
                </text>
            </g>
        );
    }

    return (
        <>
            <div className={"flex flex-col gap-6"}>
                <div
                    className={
                        organisationName === "sampleOrgThatNeedsThisAtSomePoint"
                            ? "flex flex-col gap-32 mb-16 print:gap-20"
                            : "flex flex-col"
                    }
                >
                    <div
                        className={
                            "flex flex-row justify-center items-center gap-2 " +
                            "px-2 py-1 rounded-xl w-fit place-self-center"
                        }
                    >
                        <div className={"flex flex-row justify-center items-center gap-4"}>
                            <SausageLegendItem
                                label="Self-assessed Current Capability"
                                backgroundColour={
                                    chosenCategoriesBigRadar.includes("selfAssessmentScore")
                                        ? "bg-teal-500 hover:bg-teal-700 font-medium"
                                        : "bg-slate-400 hover:bg-slate-600 font-medium"
                                }
                                textColour={
                                    chosenCategoriesBigRadar.includes("selfAssessmentScore")
                                        ? "text-teal-700 hover:text-teal-950 font-medium"
                                        : "text-slate-600 hover:text-slate-800 font-medium"
                                }
                                large
                                clickable
                                onClick={() => handleFilteredRadarFieldsChange("selfAssessmentScore")}
                            />
                            <SausageLegendItem
                                label={"Mindset"}
                                backgroundColour={
                                    chosenCategoriesBigRadar.includes("mindsetScore")
                                        ? "bg-purple-500 hover:bg-purple-700 font-medium"
                                        : "bg-slate-400 hover:bg-slate-600 font-medium"
                                }
                                textColour={
                                    chosenCategoriesBigRadar.includes("mindsetScore")
                                        ? "text-purple-700 hover:text-purple-950 font-medium"
                                        : "text-slate-600 hover:text-slate-800 font-medium"
                                }
                                large
                                clickable
                                onClick={() => handleFilteredRadarFieldsChange("mindsetScore")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center print:justify-start print:ml-[0px]">
                        <div
                            className={
                                organisationName === "sampleOrgThatNeedsThisAtSomePoint"
                                    ? "relative print:scale-90 h-[350px] w-[250px] md:w-[550px] md:h-[450px] xl:w-[600px] xl:h-[500px] 2xl:w-[700px] 2xl:h-[600px] print:-mt-12 -mt-8 font-medium p-2 print:ml-8"
                                    : "h-[350px] w-[250px] md:w-[550px] md:h-[450px] xl:w-[600px] xl:h-[500px] 2xl:w-[700px] 2xl:h-[600px] print:w-[200px] print:h-[200px] print:-mt-12 -mt-8 font-medium"
                            }
                        >
                            {organisationName === "sampleOrgThatNeedsThisAtSomePoint" && (
                                <>
                                    <div
                                        className={
                                            "absolute -top-2 -left-4 print:w-[680px] print:h-96 hidden print:block"
                                        }
                                    >
                                        <img src={outerLegend} alt={"Outer legend for print"} />
                                    </div>
                                    <img
                                        className={"absolute -top-8 -left-6 print:hidden"}
                                        src={outerLegend}
                                        alt={"Outer legend"}
                                    />
                                </>
                            )}
                            <ResponsiveRadar
                                data={filteredRadarFields}
                                colors={["#0d9488", "#9333ea"]}
                                keys={["selfAssessmentScore", "mindsetScore"]}
                                gridLabel={(labelProps) => gridLabel(labelProps)}
                                indexBy="name"
                                maxValue={maxValue}
                                valueFormat=">-.2f"
                                margin={{ top: 0, right: 150, bottom: 0, left: 150 }}
                                gridLabelOffset={10}
                                gridShape="linear"
                                gridLevels={4}
                                dotSize={1}
                                dotColor={{ theme: "background" }}
                                dotBorderWidth={4}
                                blendMode="multiply"
                                motionConfig="wobbly"
                                theme={{
                                    axis: {
                                        fontSize: "16px",
                                        tickColor: "#eee",
                                        ticks: {
                                            line: {
                                                stroke: "#555555",
                                            },
                                            text: {
                                                fill: "#64748b",
                                                fontSize: "11px",
                                                fontWeight: "400",
                                                fontFamily: "Inter",
                                                wordBreak: "break-all",
                                            },
                                        },
                                    },
                                }}
                                isInteractive={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
