import React, { useState } from "react";
import { useCycle } from "framer-motion";
import { useLocation } from "react-router";

import SideSection from "../../../SharedChartComponents/SideSection";
import ReportHeader from "../../../SharedChartComponents/ReportHeader";
import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import Toggle from "../../../../../../SharedComponents/Form/Toggle";
import TabbedNavigationLayout from "../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import GroupContent from "./GroupContent";
import PrintToPDF from "../../../../../../SharedComponents/PrintToPDF";

export default function CapabilityMap(props) {
    const { score, testId } = props;

    const [shortReport, setShortReport] = useCycle(false, true);
    const [contentInteracted, setContentInteracted] = useState(false);
    const [summaryTabIndex, setSummaryTabIndex] = useState(0);
    const [printToPDFMode, setPrintToPDFMode] = useState(false);
    const [navigatingToPrint, setNavigatingToPrint] = useState(false);

    const location = useLocation();

    const summaryList = [];

    score.groups.map((group) => summaryList.push(group.name));

    const summaryContent = summaryList.map((category) => {
        return {
            content: (
                <GroupContent
                    group={score.groups.find((group) => group.name === category)}
                    reportLevelNames={score.reportLevelNames}
                    printToPDFMode={printToPDFMode}
                />
            ),
            readonly: true,
            backgroundColour: "bg-slate-50",
        };
    });

    function handleReportInteraction() {
        setPrintToPDFMode(false);
    }

    return (
        <DashboardLayout requiredPermissions={[]}>
            <div className="flex flex-col gap-6" data-testid={testId && testId}>
                <ReportHeader
                    noNegativeTopMargin
                    noReportWordInTitle
                    largeTitle
                    reportName={score.reportName}
                    userName={location.state?.userName}
                    preparedBy={"Prepared by Capability Co"}
                    organisationLogo={score.organisationLogo}
                />
                <div
                    className={
                        "flex flex-row gap-2 print:hidden sticky top-4 left-4 bg-white p-3 rounded-xl " +
                        "border border-slate-100 shadow-lg z-50 relative xl:hidden print:hidden"
                    }
                >
                    <PrintToPDF
                        onlyButton
                        setPrintToPDFMode={setPrintToPDFMode}
                        setNavigatingToPrint={setNavigatingToPrint}
                        navigatingToPrint={navigatingToPrint}
                    />
                </div>
                <div
                    className={
                        "xl:hidden bg-white flex flex-row gap-4 p-2 px-2.5 rounded-xl w-fit border border-slate-100 " +
                        "shadow-sm print:hidden"
                    }
                >
                    <div className="w-max" onClick={() => setShortReport()}>
                        <Toggle text={"Executive mode"} triggered={shortReport} />
                    </div>
                </div>
                <div className={"grid grid-cols-1 2xl:grid-cols-4 gap-6"}>
                    <div className="col-span-3 flex flex-col gap-8">
                        <div className="col-span-2 flex flex-col gap-8">
                            <div className="flex flex-col gap-4">
                                <div className={"flex flex-col gap-4"}>
                                    {score.understandYourTeam && (
                                        <div className={"flex flex-col gap-2"}>
                                            {score.understandYourTeam.title && (
                                                <div
                                                    className={
                                                        "flex flex-row gap-2 items-center font-medium text-base " +
                                                        "w-max text-secondary-600"
                                                    }
                                                >
                                                    {score.understandYourTeam.title}
                                                </div>
                                            )}
                                            {score.understandYourTeam.description &&
                                                score.understandYourTeam.description.map((paragraph, index) => (
                                                    <p
                                                        key={index + "harnessingYourResults"}
                                                        className="text-slate-500 text-sm"
                                                    >
                                                        {paragraph}
                                                    </p>
                                                ))}
                                        </div>
                                    )}
                                    <div className={"flex flex-col gap-2"}>
                                        <div
                                            data-item="scrollback-content"
                                            className="text-secondary-600 font-medium text-base"
                                        >
                                            Snapshot
                                        </div>
                                        <div className={"flex flex-col gap-8 print:gap-4"}>
                                            <div className={"flex flex-col gap-4"}>
                                                <div className={"flex-col gap-3 " + (shortReport ? "hidden" : "flex")}>
                                                    {score.snapshot.map((paragraph, index) => (
                                                        <p key={index + "snapshot"} className="text-slate-500 text-sm">
                                                            {paragraph}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                            <TabbedNavigationLayout
                                                tabIndex={summaryTabIndex}
                                                setTabIndex={setSummaryTabIndex}
                                                tabList={summaryList}
                                                tabContent={summaryContent}
                                                tabsRowOnly
                                                noTitle
                                                printGap={16}
                                                noContainer
                                                secondaryTabs
                                                displayOnlyContent={printToPDFMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SideSection
                        orgName={"Capability Co"}
                        setContentInteracted={setContentInteracted}
                        noDefaultContent
                        extraContent={
                            <div
                                className={
                                    "flex flex-col gap-4 print:hidden sticky top-4 left-4 p-4 rounded-xl " +
                                    "shadow-xl border border-slate-100 z-50 hidden w-auto xl:flex 2xl:w-max " +
                                    "print:hidden bg-white transition-opacity print:p-0 print:border-0 " +
                                    "print:shadow-none " +
                                    (contentInteracted ? "opacity-5" : "opacity-100") +
                                    (navigatingToPrint ? "pointer-events-none" : "pointer-events-auto")
                                }
                                {...(!navigatingToPrint && { onMouseLeave: handleReportInteraction })}
                            >
                                <PrintToPDF
                                    setPrintToPDFMode={setPrintToPDFMode}
                                    setNavigatingToPrint={setNavigatingToPrint}
                                    navigatingToPrint={navigatingToPrint}
                                />
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-row gap-2 items-center">
                                        <div className="text-slate-500 font-medium text-sm">Options:</div>
                                    </div>
                                    <div className="flex flex-col gap-4 print:hidden">
                                        <div className="w-max" onClick={() => setShortReport()}>
                                            <Toggle text={"Executive mode"} triggered={shortReport} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>
            <div className="bottom-0 hidden print:flex print:fixed w-full pt-1 justify-end">
                <div className="text-slate-500 font-medium text-sm w-max">© 2024 Capability Co</div>
            </div>
        </DashboardLayout>
    );
}
