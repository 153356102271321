export const sortAlphabetically = (list, ascending, firstWord, objectProperty, arrayPosition) => {
    let alphabeticallySortedList = [];

    alphabeticallySortedList = [...list].sort((a, b) => {
        let charA;
        let charB;

        if (firstWord) {
            // If we have to access a property inside the object
            if (objectProperty) {
                if (a[objectProperty] && a[objectProperty].length !== 0) {
                    // Pick object position if array
                    if (typeof arrayPosition == "number") {
                        charA = a[objectProperty][arrayPosition].split(" ")[0].toLowerCase();
                    } else {
                        charA = a[objectProperty].split(" ")[0].toLowerCase();
                    }
                }

                if (b[objectProperty] && b[objectProperty].length !== 0) {
                    if (typeof arrayPosition == "number") {
                        charB = b[objectProperty][arrayPosition].split(" ")[0].toLowerCase();
                    } else {
                        charB = b[objectProperty].split(" ")[0].toLowerCase();
                    }
                }
            } else {
                // If it's the object itself
                charA = a.split(" ")[0].toLowerCase();
                charB = b.split(" ")[0].toLowerCase();
            }
        } else {
            if (objectProperty) {
                if (a[objectProperty] && a[objectProperty].length !== 0) {
                    if (typeof arrayPosition == "number") {
                        charA = a[objectProperty][arrayPosition].charAt(0).split(" ")[0].toLowerCase();
                    } else {
                        charA = a[objectProperty].charAt(0).toLowerCase();
                    }
                }

                if (b[objectProperty] && b[objectProperty].length !== 0) {
                    if (typeof arrayPosition == "number") {
                        charB = b[objectProperty][arrayPosition].charAt(0).split(" ")[0].toLowerCase();
                    } else {
                        charB = b[objectProperty].charAt(0).toLowerCase();
                    }
                }
            } else {
                charA = a.charAt(0).toLowerCase();
                charB = b.charAt(0).toLowerCase();
            }
        }

        // If object fails conditions i.e undefined/empty put at the back of list
        if (!charA) return 1;
        if (!charB) return -1;

        if (ascending) {
            if (charA < charB) return -1;
            if (charA > charB) return 1;
        } else {
            if (charA > charB) return -1;
            if (charA < charB) return 1;
        }
        return 0;
    });

    return alphabeticallySortedList;
};

export const sortByBoolean = (list, ascending, objectProperty, secondObjectProperty) => {
    let booleanSortedList = [];

    if (ascending) {
        if (objectProperty) {
            if (secondObjectProperty) {
                booleanSortedList = [...list].sort(
                    (a, b) =>
                        (a[objectProperty] || a[secondObjectProperty]) - (b[objectProperty] || b[secondObjectProperty])
                );
            } else {
                booleanSortedList = [...list].sort((a, b) => a[objectProperty] - b[objectProperty]);
            }
        } else {
            booleanSortedList = [...list].sort((a, b) => a - b);
        }
    } else {
        if (objectProperty) {
            if (secondObjectProperty) {
                booleanSortedList = [...list].sort(
                    (a, b) =>
                        (b[objectProperty] || b[secondObjectProperty]) - (a[objectProperty] || a[secondObjectProperty])
                );
            } else {
                booleanSortedList = [...list].sort((a, b) => b[objectProperty] - a[objectProperty]);
            }
        } else {
            booleanSortedList = [...list].sort((a, b) => b - a);
        }
    }

    return booleanSortedList;
};
