import { AnimatePresence, useCycle, motion } from "framer-motion";
import { ChevronRightIcon, ExclamationCircleIcon, UsersIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";

import Button from "../../../../../SharedComponents/Button";

export default function CoachingGuidelineCard(props) {
    const { showCoachingGuideline, reportField } = props;

    const [showContent, setShowContent] = useCycle(false, true);

    const Content = () => {
        return (
            <div className="flex flex-col gap-4">
                {reportField.considerationsWithinTheWorkplace && (
                    <div className={"flex flex-col gap-2"}>
                        <span className={"font-medium text-emerald-800"}>Considerations within the workplace:</span>
                        <p className={"font-medium"}>{reportField.considerationsWithinTheWorkplace.heading}</p>
                        <ul className={"list-disc ml-6 flex flex-col gap-1.5"}>
                            {reportField.considerationsWithinTheWorkplace.points.map((point) => (
                                <li>{point}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {reportField.coachingTips && (
                    <div className={"flex flex-col gap-2"}>
                        <span className={"font-medium text-emerald-800"}>Coaching tips:</span>
                        <ul className={"list-disc ml-6 flex flex-col gap-1.5"}>
                            {reportField.coachingTips.points.map((point) => (
                                <li>{point}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {reportField.interactiveEffects && (
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex flex-row gap-1.5 items-center"}>
                            <ExclamationCircleIcon className={"shrink-0 h-4 w-4 text-amber-600"} />
                            <span className={"font-medium text-amber-600"}>Interactive effects:</span>
                        </div>
                        <ul className={"list-disc ml-6 flex flex-col gap-1.5"}>
                            {reportField.interactiveEffects.map((point) => (
                                <li>{point}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div
            className={
                "flex-col gap-4 p-2.5 rounded-xl border shadow-sm print:shadow-none " +
                "text-slate-500 bg-emerald-50 border-emerald-100 print:bg-white print:border-none print:p-0 " +
                (showCoachingGuideline ? "flex" : "hidden")
            }
        >
            <div className="w-full flex justify-between">
                <div className="flex flex-row gap-2 items-center justify-between w-full">
                    <div className="flex flex-row gap-2 items-center font-medium text-base w-max text-emerald-600">
                        <UsersIcon className={"h-5 w-5 print:hidden"} />
                        {reportField.interactiveEffects ? (
                            <span>
                                Coaching tips, considerations and
                                <span className={"text-amber-600"}> interactive effects</span>
                            </span>
                        ) : (
                            "Coaching tips and considerations"
                        )}
                    </div>
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GREEN}
                        icon={
                            <ChevronRightIcon
                                className={
                                    "h-3 w-3 stroke-current stroke-2 transition-transform transform print:rotate-90 " +
                                    (showContent && "rotate-90")
                                }
                            />
                        }
                        extraStyling={
                            "shrink-0 text-xs h-6 w-6 rounded-full p-0 items-center flex justify-center print:hidden"
                        }
                        iconPositionLeft
                        onClick={() => setShowContent()}
                    />
                </div>
            </div>
            <AnimatePresence>
                {showContent && (
                    <motion.section
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                            open: {
                                opacity: 1,
                                height: "auto",
                            },
                            closed: { opacity: 0, height: 0 },
                        }}
                        transition={{ type: "tween", duration: 0.4, stiffness: 100 }}
                    >
                        <Content />
                    </motion.section>
                )}
            </AnimatePresence>
            <div className={"hidden " + (showCoachingGuideline ? "print:block" : "print:hidden")}>{<Content />}</div>
        </div>
    );
}
