import React, { useCallback, useEffect, useState } from "react";
import { Buffer } from "buffer";
import { useCycle } from "framer-motion";

import {
    downloadLatestReportForAssessment,
    downloadLatestReportForSubsequentFile,
    getActiveBatchDetails,
    getApplications,
    getAssessmentsForUser,
    getBranding,
    getCountryStats,
    getFieldManagerStats,
    getGroupStats,
    getOrganisationWorkflows,
    getProfileDetails,
    getRegionalStats,
    getReports,
    getSessionsForOrganisation,
    getSessionsForUser,
    getShowWelcome,
    getTerminology,
    getUserMediaFile,
    getUserPermissions,
} from "../utils/api";
import {
    ACE_2_SURVEY_PLATFORM,
    ACE_PRODUCT_NAMES,
    ACE_SURVEY_REPORT_DATA_TEMPLATE,
    CAPABILITY_CO_PLATFORM,
    COACH_PLATFORM,
    COACH_PLATFORM_PERMISSIONS,
    DEFAULT_TERMINOLOGY,
    EMPLOYMENT_SERVICES,
    NATIVE_APPS,
    USER_GROUPS,
} from "../utils/constants";
import { processAceSurveyReportData } from "../utils/aceSurveyDataProcessing";

import coachPlatformLogo from "../assets/coach_platform_logo.png";
import employmentServicesLogo from "../assets/employment_services_logo.png";
import FRILogo from "../assets/FRI_logo.svg";
import FRIIcon from "../assets/FRI_icon.svg";
import CapCoLogo from "../assets/CapCo_Logo.svg";

// To test with mockData ACE data
// import { sampleACEScore, sampleMemberTypesACEScore, sampleProvinceReport } from "../utils/mockData";

export const Context = React.createContext("coach-platform");

function ContextProvider(props) {
    const { children } = props;

    const [permissionsLoading, setPermissionsLoading] = useState(true);
    const [userPermissions, setUserPermissions] = useState([]);
    const [showWelcomeLoading, setShowWelcomeLoading] = useState(false);
    const [applicationsLoading, setApplicationsLoading] = useState(true);
    const [terminologyLoading, setTerminologyLoading] = useState(true);
    const [profileDetailsLoading, setProfileDetailsLoading] = useState(true);
    const [showOrgWelcome, setShowOrgWelcome] = useState(false);
    const [showAppWelcome, setShowAppWelcome] = useState(false);
    const [profileDetails, setProfileDetails] = useState({});
    const [userType, setUserType] = useState(null);
    const [loggedIn, setLoggedIn] = useState(null);
    const [branding, setBranding] = useState({});
    const [brandingLoading, setBrandingLoading] = useState(true);
    const [assessmentsLoading, setAssessmentsLoading] = useState(true);
    const [sessionsLoading, setSessionsLoading] = useState(true);
    const [userSessions, setUserSessions] = useState([]);
    const [organisationSessions, setOrganisationSessions] = useState([]);
    const [organisationWorkflowACEProducts, setOrganisationWorkflowACEProducts] = useState([]);
    const [userApplications, setUserApplications] = useState([]);
    const [allApplications, setAllApplications] = useState([]);
    const [userAssessments, setUserAssessments] = useState([]);
    const [reports, setReports] = useState([]);
    const [reportsLoading, setReportsLoading] = useState(true);
    const [defaultApp, setDefaultApp] = useState("coachPlatform");
    const [terminology, setTerminology] = useState([]);
    const [photo, setPhoto] = useState([]);
    const [photosLoading, setPhotosLoading] = useState(true);
    const [aceSurveyReportData, setAceSurveyReportData] = useState(
        JSON.parse(JSON.stringify(ACE_SURVEY_REPORT_DATA_TEMPLATE))
    );
    const [aceSurveyReportDataLoading, setAceSurveyReportDataLoading] = useState(true);
    const [aceSurveyRegionalCompletionData, setAceSurveyRegionalCompletionData] = useState([]);
    const [aceSurveyRegionalCompletionDataLoading, setAceSurveyRegionalCompletionDataLoading] = useState(true);
    const [aceSurveyFieldManagerCompletionData, setAceSurveyFieldManagerCompletionData] = useState([]);
    const [aceSurveyViewCountryCompletions, setAceSurveyViewCountryPermissions] = useState(false);
    const [aceSurveyGroupCompletionData, setAceSurveyGroupCompletionData] = useState([]);
    const [aceSurveyCompletionsProductName, setAceSurveyCompletionsProductName] = useState("");
    const [aceSurveyReports, setAceSurveyReports] = useState([]);
    const [chosenAceReport, setChosenAceReport] = useState(0);
    const [errorLoadingReports, setErrorLoadingReports] = useState(false);

    const [menuHidden, setMenuHidden] = useCycle(false, true);

    const fetchUserPermissions = async () => {
        setPermissionsLoading(true);

        try {
            const userPermissionsResponse = await getUserPermissions();

            setUserPermissions(userPermissionsResponse);
        } catch {
            setUserPermissions(null);
        }

        setPermissionsLoading(false);
    };

    // Disabled Until we properly implement a way for customers to update their content and build endpoints
    /* eslint-disable */
    const fetchShowWelcome = async () => {
        setShowWelcomeLoading(true);

        try {
            const showWelcomeResponse = await getShowWelcome();

            setShowOrgWelcome(showWelcomeResponse.showOrgWelcome);
            setShowAppWelcome(showWelcomeResponse.showAppWelcome);
        } catch {
            setShowOrgWelcome(null);
            setShowAppWelcome(null);
        }

        setShowWelcomeLoading(false);
    };

    const fetchProfileDetails = async () => {
        setProfileDetailsLoading(true);

        try {
            const profileDetailsResponse = await getProfileDetails();
            setProfileDetails(profileDetailsResponse);
        } catch {
            setProfileDetails(null);
        }

        setProfileDetailsLoading(false);
    };

    const fetchApps = async () => {
        setApplicationsLoading(true);

        try {
            let userApplicationsResponse = await getApplications();
            setAllApplications(userApplicationsResponse);

            if (process.env.REACT_APP_DEFAULT_APP === EMPLOYMENT_SERVICES) {
                userApplicationsResponse = userApplicationsResponse.filter(
                    (app) => app.name !== "Employment Services Platform"
                );
            } else if (process.env.REACT_APP_DEFAULT_APP === COACH_PLATFORM) {
                userApplicationsResponse = userApplicationsResponse.filter((app) => app.name !== COACH_PLATFORM);
            } else if (process.env.REACT_APP_DEFAULT_APP === CAPABILITY_CO_PLATFORM) {
                userApplicationsResponse = userApplicationsResponse.filter(
                    (app) => app.name !== CAPABILITY_CO_PLATFORM
                );
            }

            setUserApplications(userApplicationsResponse);
        } catch {
            setUserApplications(null);
        }

        setApplicationsLoading(false);
    };

    const fetchAssessments = async () => {
        setAssessmentsLoading(true);

        try {
            const userAssessmentsResponse = await getAssessmentsForUser();
            setUserAssessments(userAssessmentsResponse);
        } catch {
            setUserAssessments(null);
        }

        setAssessmentsLoading(false);
    };

    const fetchSessionsForUser = async () => {
        setSessionsLoading(true);

        try {
            const sessionsUserResponse = await getSessionsForUser();
            setUserSessions(sessionsUserResponse);
        } catch {
            setUserSessions(null);
        }

        setSessionsLoading(false);
    };

    const fetchSessionsForOrganisation = async () => {
        setSessionsLoading(true);

        try {
            const sessionsOrganisationResponse = await getSessionsForOrganisation();
            setOrganisationSessions(sessionsOrganisationResponse);
        } catch {
            setOrganisationSessions(null);
        }

        setSessionsLoading(false);
    };

    const fetchSessions = async () => {
        // Loading is avoided to preserve smooth transition
        try {
            const [sessionsOrganisationResponse, sessionsUserResponse] = await Promise.all([
                getSessionsForOrganisation(),
                getSessionsForUser(),
            ]);
            setOrganisationSessions(sessionsOrganisationResponse);
            setUserSessions(sessionsUserResponse);
        } catch {
            setOrganisationSessions(null);
            setUserSessions(null);
        }
    };

    const fetchReports = async () => {
        setReportsLoading(true);
        try {
            const reportsData = await getReports();
            setReports(reportsData);
        } catch {
            setReports(null);
        }
        setReportsLoading(false);
    };

    const fetchTerminology = useCallback(async () => {
        setTerminologyLoading(true);

        let appIdentifier = "";
        let organisationIdentifier = "";

        if (profileDetailsLoading === false && profileDetails) {
            organisationIdentifier = profileDetails.organisationIdentifier;
        }

        if (applicationsLoading === false && userApplications) {
            allApplications.forEach((application) => {
                const appName =
                    application.name === "Employment Services Platform" ? EMPLOYMENT_SERVICES : application.name;

                if (appName === process.env.REACT_APP_DEFAULT_APP) {
                    appIdentifier = application.identifier;
                }
            });
        }

        try {
            //No endpoint to retrieve organisation identifier and language currently.
            const terminologyData = await getTerminology(organisationIdentifier, appIdentifier, "");

            const coachSingular = terminologyData.coachSingular
                ? terminologyData.coachSingular
                : process.env.REACT_APP_COACH_SINGULAR;

            const coachPlural = terminologyData.coachPlural
                ? terminologyData.coachPlural
                : process.env.REACT_APP_COACH_PLURAL;

            const coacheeSingular = terminologyData.coacheeSingular
                ? terminologyData.coacheeSingular
                : process.env.REACT_APP_COACHEE_SINGULAR;

            const coacheePlural = terminologyData.coacheePlural
                ? terminologyData.coacheePlural
                : process.env.REACT_APP_COACHEE_PLURAL;

            const sanitizedTerminologyData = {
                coach: { singular: coachSingular, plural: coachPlural },
                coachee: { singular: coacheeSingular, plural: coacheePlural },
            };

            setTerminology(sanitizedTerminologyData);
        } catch {
            setTerminology(DEFAULT_TERMINOLOGY);
        }

        setTerminologyLoading(false);
    }, [applicationsLoading, profileDetails, profileDetailsLoading, userApplications]);

    const fetchBranding = async () => {
        setBrandingLoading(true);

        const brandingResponse = await getBranding(profileDetails.organisationIdentifier);

        const brandingDict = brandingResponse.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        setBranding(brandingDict);

        if (brandingDict.clientTheme != null) {
            const style = document.createElement("style");
            style.innerHTML = brandingDict.clientTheme;
            document.head.appendChild(style);
        }

        setBrandingLoading(false);
    };

    const getImage = (key) => {
        let logo = "";

        if (brandingLoading || !branding[key]) {
            if (process.env.REACT_APP_DEFAULT_APP === EMPLOYMENT_SERVICES) {
                logo = employmentServicesLogo;
            } else if (process.env.REACT_APP_DEFAULT_APP === ACE_2_SURVEY_PLATFORM) {
                logo = FRILogo;
            } else if (process.env.REACT_APP_DEFAULT_APP === CAPABILITY_CO_PLATFORM) {
                logo = CapCoLogo;
            } else {
                logo = coachPlatformLogo;
            }
        } else {
            logo = `data:image/png;base64,${branding[key]}`;
        }

        return logo;
    };

    const fetchPhotos = useCallback(async () => {
        try {
            setPhotosLoading(true);
            const photoResponse = await Promise.all(
                profileDetails.mediaFiles
                    .filter((mediaFile) => ["png", "jpg", "jpeg"].includes(mediaFile.fileType))
                    .map(async (photo) => {
                        return { ...photo, content: (await getUserMediaFile(photo.identifier)).content };
                    })
            );

            if (photoResponse.length !== 0) {
                setPhoto(photoResponse[0]);
            }

            setPhotosLoading(false);
        } catch (err) {
            setPhoto(null);
        }
    }, [profileDetails.mediaFiles]);

    const isNativeApps = NATIVE_APPS.includes(defaultApp);

    useEffect(() => {
        if (profileDetails.mediaFiles && profileDetails.mediaFiles.length > 0) {
            fetchPhotos();
        } else {
            setPhotosLoading(false);
        }
    }, [fetchPhotos, profileDetails.mediaFiles]);

    useEffect(() => {
        if (profileDetails.userGroups) {
            if (
                profileDetails.userGroups.includes(USER_GROUPS.COACHEE) &&
                profileDetails.userGroups.includes(USER_GROUPS.COACH)
            ) {
                setUserType(USER_GROUPS.COACH);
            } else if (profileDetails.userGroups.includes(USER_GROUPS.COACHEE)) {
                setUserType(USER_GROUPS.COACHEE);
            } else if (profileDetails.userGroups.includes(USER_GROUPS.COACH)) {
                setUserType(USER_GROUPS.COACH);
            } else {
                setUserType(USER_GROUPS.NONE);
            }
        }
    }, [profileDetails]);

    useEffect(() => {
        if (loggedIn) {
            fetchUserPermissions();
            fetchProfileDetails();
            fetchApps();
            fetchReports();
        }
    }, [loggedIn]);

    useEffect(() => {
        if (loggedIn && !permissionsLoading) {
            if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_SESSIONS)) {
                fetchSessionsForUser();
            }

            if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_SESSIONS)) {
                fetchSessionsForOrganisation();
            }
        }
    }, [loggedIn, permissionsLoading]);

    // Load ACE 2.0 Survey Data
    const fetchACEReportData = useCallback(async () => {
        setAceSurveyReportDataLoading(true);

        if (reports.length !== 0) {
            const aceSurveyReport = reports.find((report) => report.name === "ACE 2.0 Score");

            let unprocessedReportList = [];
            let processedReportList = [];

            // Get related reports i.e Country/Region
            if (aceSurveyReport.documentVersions[0].subsequentFiles.length > 0) {
                for (const subsequentFile of aceSurveyReport.documentVersions[0].subsequentFiles) {
                    try {
                        const subsequentFileDocumentResponse = await downloadLatestReportForSubsequentFile(
                            aceSurveyReport?.identifier,
                            subsequentFile?.identifier
                        );

                        // Check if we get a response and if the report contains reportFields
                        if (subsequentFileDocumentResponse) {
                            subsequentFileDocumentResponse.name = subsequentFile.name;

                            const decodedReport = JSON.parse(
                                Buffer.from(subsequentFileDocumentResponse.content, "base64").toString()
                            );

                            if (decodedReport.surveyScore.reportFields.length > 0) {
                                unprocessedReportList.push(subsequentFileDocumentResponse);
                            }
                        }
                    } catch (error) {
                        console.error("API call failed:", error);
                    }
                }
            }

            // Get Overall/Main report
            try {
                const documentResponse = await downloadLatestReportForAssessment(aceSurveyReport?.identifier);

                // Check if we get a response and that the report contains reportFields
                if (documentResponse) {
                    const decodedReport = JSON.parse(Buffer.from(documentResponse.content, "base64").toString());

                    if (decodedReport.surveyScore.reportFields.length > 0) {
                        unprocessedReportList.unshift(documentResponse);
                    }
                }
            } catch (error) {
                console.error("API call failed:", error);
            }

            unprocessedReportList.forEach((unprocessedReport) => {
                const processedReport = JSON.parse(Buffer.from(unprocessedReport.content, "base64").toString());
                processedReport.name = unprocessedReport.name;
                processedReportList.push(processedReport);
            });

            // To test with mockData ACE data
            // const aceSurveyScore = sampleACEScore;
            // processedReportList = [sampleACEScore]

            let initialAceSurveyReportsTemplates = [];

            processedReportList.forEach(() => {
                initialAceSurveyReportsTemplates.push(JSON.parse(JSON.stringify(ACE_SURVEY_REPORT_DATA_TEMPLATE)));
            });

            processedReportList.forEach((report, index) => {
                processAceSurveyReportData(
                    report,
                    initialAceSurveyReportsTemplates[index],
                    profileDetails.organisationName
                );
            });

            setAceSurveyReports(initialAceSurveyReportsTemplates);

            if (initialAceSurveyReportsTemplates.length > 0) {
                setAceSurveyReportData(initialAceSurveyReportsTemplates[0]);
            } else {
                setErrorLoadingReports(true);
            }

            setAceSurveyReportDataLoading(false);
        }
    }, [profileDetailsLoading, reports]);

    const fetchRegionalCompletionsOfACEReportData = useCallback(async () => {
        setAceSurveyRegionalCompletionDataLoading(true);

        try {
            let ACEProductName = null;
            const organisationWorkflowACEProducts = [];
            const ACEProductNames = Object.values(ACE_PRODUCT_NAMES);

            const organisationWorkflows = await getOrganisationWorkflows();

            organisationWorkflows.forEach((organisationWorkflow) => {
                if (ACEProductNames.includes(organisationWorkflow.productName)) {
                    organisationWorkflowACEProducts.push(organisationWorkflow);
                    if (ACEProductName === null) {
                        ACEProductName = organisationWorkflow.productName;
                    }
                }
            });

            setOrganisationWorkflowACEProducts(organisationWorkflowACEProducts);

            const batchDetails = await getActiveBatchDetails(ACEProductName);

            if (batchDetails) {
                let completions;

                if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COUNTRY_COMPLETIONS)) {
                    setAceSurveyViewCountryPermissions(true);

                    completions = await getCountryStats(batchDetails.identifier, profileDetails.userIdentifier);
                } else {
                    setAceSurveyViewCountryPermissions(false);

                    completions = await getRegionalStats(batchDetails.identifier);
                    setAceSurveyFieldManagerCompletionData(completions.managerAssessmentsStat);
                }

                setAceSurveyCompletionsProductName(completions.productName);
                setAceSurveyRegionalCompletionData(completions.regionAssessmentsStat);

                if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_FIELD_MANAGER_COMPLETIONS)) {
                    const ACEFieldManagerCompletions = await getFieldManagerStats(batchDetails.identifier);
                    setAceSurveyFieldManagerCompletionData(ACEFieldManagerCompletions.managerAssessmentsStat);
                }

                if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_COMPLETIONS)) {
                    const ACEGroupCompletions = await getGroupStats(batchDetails.identifier);
                    setAceSurveyGroupCompletionData(ACEGroupCompletions.assessmentStatBreakdown);
                }

                setAceSurveyRegionalCompletionDataLoading(false);
            }
        } catch {
            setAceSurveyRegionalCompletionData([]);
            setAceSurveyFieldManagerCompletionData([]);
        }
    }, [profileDetailsLoading]);

    const handleACEDataCompletions = async (productName) => {
        setAceSurveyRegionalCompletionDataLoading(true);

        try {
            const batchDetails = await getActiveBatchDetails(productName);

            if (batchDetails) {
                let completions;

                if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COUNTRY_COMPLETIONS)) {
                    setAceSurveyViewCountryPermissions(true);

                    completions = await getCountryStats(batchDetails.identifier, profileDetails.userIdentifier);
                } else {
                    setAceSurveyViewCountryPermissions(false);

                    completions = await getRegionalStats(batchDetails.identifier);
                    setAceSurveyFieldManagerCompletionData(completions.managerAssessmentsStat);
                }

                setAceSurveyCompletionsProductName(completions.productName);
                setAceSurveyRegionalCompletionData(completions.regionAssessmentsStat);

                if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_FIELD_MANAGER_COMPLETIONS)) {
                    const ACEFieldManagerCompletions = await getFieldManagerStats(batchDetails.identifier);
                    setAceSurveyFieldManagerCompletionData(ACEFieldManagerCompletions.managerAssessmentsStat);
                }

                if (userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_COMPLETIONS)) {
                    const ACEGroupCompletions = await getGroupStats(batchDetails.identifier);
                    setAceSurveyGroupCompletionData(ACEGroupCompletions.assessmentStatBreakdown);
                }

                setAceSurveyRegionalCompletionDataLoading(false);
            }
        } catch (error) {
            setAceSurveyRegionalCompletionData([]);
            setAceSurveyFieldManagerCompletionData([]);
        }
    };

    useEffect(() => {
        if (!reportsLoading && !profileDetailsLoading && process.env.REACT_APP_DEFAULT_APP === ACE_2_SURVEY_PLATFORM) {
            fetchACEReportData();
            fetchRegionalCompletionsOfACEReportData();
        }
    }, [profileDetailsLoading, reportsLoading]);

    useEffect(() => {
        if (profileDetailsLoading === false && applicationsLoading === false) {
            fetchBranding();
            fetchTerminology();
        }
    }, [applicationsLoading, profileDetailsLoading, fetchTerminology]);

    useEffect(() => {
        document.title = process.env.REACT_APP_DEFAULT_APP;

        if (process.env.REACT_APP_DEFAULT_APP === ACE_2_SURVEY_PLATFORM) {
            const linkElement = document.querySelector("link[rel=icon]");
            linkElement.href = FRIIcon;
        }

        setDefaultApp(process.env.REACT_APP_DEFAULT_APP);
    }, []);

    return (
        <Context.Provider
            value={{
                loggedIn: loggedIn,
                setLoggedIn: setLoggedIn,
                permissionsLoading: permissionsLoading,
                userPermissions: userPermissions,
                applicationsLoading: applicationsLoading,
                userApplications: userApplications,
                assessmentsLoading: assessmentsLoading,
                userAssessments: userAssessments,
                fetchAssessments: fetchAssessments,
                sessionsLoading: sessionsLoading,
                terminologyLoading: terminologyLoading,
                fetchSessionsForUser: fetchSessionsForUser,
                fetchSessionsForOrganisation: fetchSessionsForOrganisation,
                fetchSessions: fetchSessions,
                fetchReports: fetchReports,
                reports: reports,
                reportsLoading: reportsLoading,
                userSessions: userSessions.slice(0).reverse(),
                organisationSessions: organisationSessions.slice(0).reverse(),
                showOrgWelcome: showOrgWelcome,
                showAppWelcome: showAppWelcome,
                showWelcomeLoading: showWelcomeLoading,
                setShowAppWelcome: setShowAppWelcome,
                profileDetails: profileDetails,
                userType: userType,
                branding: branding,
                brandingLoading: brandingLoading,
                terminology: terminology,
                getImage: getImage,
                defaultApp: defaultApp,
                setDefaultApp: setDefaultApp,
                photo: photo,
                photosLoading: photosLoading,
                isNativeApps: isNativeApps,
                aceSurveyReportData: aceSurveyReportData,
                aceSurveyRegionalCompletionData: aceSurveyRegionalCompletionData,
                aceSurveyRegionalCompletionDataLoading: aceSurveyRegionalCompletionDataLoading,
                aceSurveyReportDataLoading: aceSurveyReportDataLoading,
                menuHidden: menuHidden,
                setMenuHidden: setMenuHidden,
                aceSurveyViewCountryCompletions: aceSurveyViewCountryCompletions,
                aceSurveyFieldManagerCompletionData: aceSurveyFieldManagerCompletionData,
                aceSurveyGroupCompletionData: aceSurveyGroupCompletionData,
                aceSurveyCompletionsProductName: aceSurveyCompletionsProductName,
                aceSurveyReports: aceSurveyReports,
                setAceSurveyReportData: setAceSurveyReportData,
                setChosenAceReport: setChosenAceReport,
                chosenAceReport: chosenAceReport,
                errorLoadingReports: errorLoadingReports,
                organisationWorkflowACEProducts: organisationWorkflowACEProducts,
                handleACEDataCompletions: handleACEDataCompletions,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export default ContextProvider;
