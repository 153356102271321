import { useState } from "react";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";

import AssessmentCard from "./AssessmentCard";
import { useContext } from "react";
import { Context } from "../../../../context/Context";
import { ACE_2_SURVEY_PLATFORM } from "../../../../utils/constants";

export default function AssessmentCardSearch(props) {
    const { assessments } = props;

    const context = useContext(Context);

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                items={assessments}
                itemName={"assessments"}
                searchAttributes={[
                    "name",
                    "dueDate",
                    "onBehalfOfUserFirstName",
                    "onBehalfOfUserLastName",
                    "onBehalfOfUserEmailAddress",
                ]}
                ItemRow={AssessmentCard}
                cards
                customItemsPerPage={9}
                setAlert={setAlert}
                setAlertType={setAlertType}
                customPlaceholder={
                    context.defaultApp === ACE_2_SURVEY_PLATFORM
                        ? "Search surveys"
                        : "Search assessments by assessment name, user name or user email address"
                }
            />
        </>
    );
}
