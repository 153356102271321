import { AcademicCapIcon, BuildingOffice2Icon, CalendarDaysIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";
import Button from "../../../../../SharedComponents/Button";
import Input from "../../../../../SharedComponents/Form/Input";

function AddAchievement(props) {
    const { achievementName, achievements, setAchievements } = props;

    function handleEditAchievements(key, value, index) {
        const newAchievements = [...achievements];

        newAchievements[index][key] = value;

        setAchievements(newAchievements);
    }

    const handleAddAchievement = () =>
        setAchievements([
            ...achievements,
            {
                name: "",
                organisation: "",
                startYear: 0,
                endYear: 0,
                id: achievements.length + 1,
            },
        ]);

    const handleDeleteAchievement = (item) =>
        setAchievements(achievements.filter((achievement) => achievement !== item));

    return (
        <>
            <div className={"grid grid-flow-row gap-6"}>
                {achievements.length === 0 ? (
                    <div className={"p-3.5 bg-white rounded-xl"}>
                        <div className={"text-slate-500 text-sm"}>No {achievementName} has been added</div>
                    </div>
                ) : (
                    achievements.map((achievement, index) => (
                        <div key={index} className={"flex flex-col gap-2 p-3.5 bg-slate-50 rounded-xl"}>
                            <div className={"grid grid-flow-row lg:grid-cols-2 gap-6 "}>
                                <Input
                                    id="name"
                                    type="text"
                                    label="Name"
                                    placeholder={`${
                                        achievementName.charAt(0).toUpperCase() + achievementName.slice(1)
                                    } name`}
                                    icon={<AcademicCapIcon className="h-4 w-4 text-slate-500" />}
                                    backgroundColour={"bg-white"}
                                    currentValue={achievement.name}
                                    valueOnly
                                    customOnChange={(e) => handleEditAchievements("name", e.target.value, index)}
                                />
                                <Input
                                    id="organisation"
                                    type="text"
                                    label="Organisation"
                                    placeholder={"Organisation"}
                                    icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
                                    backgroundColour={"bg-white"}
                                    currentValue={achievement.organisation}
                                    valueOnly
                                    customOnChange={(e) =>
                                        handleEditAchievements("organisation", e.target.value, index)
                                    }
                                />
                                <Input
                                    id="startYear"
                                    type="number"
                                    label="Start year"
                                    placeholder={"Start year"}
                                    backgroundColour={"bg-white"}
                                    currentValue={achievement.startYear}
                                    valueOnly
                                    customOnChange={(e) => handleEditAchievements("startYear", e.target.value, index)}
                                    icon={<CalendarDaysIcon className="h-4 w-4 text-slate-500" />}
                                />
                                <Input
                                    id="endYear"
                                    type="number"
                                    label="End year"
                                    placeholder={"End year"}
                                    backgroundColour={"bg-white"}
                                    currentValue={achievement.endYear}
                                    valueOnly
                                    customOnChange={(e) => handleEditAchievements("endYear", e.target.value, index)}
                                    icon={<CalendarDaysIcon className="h-4 w-4 text-slate-500" />}
                                />
                            </div>

                            <div className="flex flex-row items-center gap-5 self-end pb-1 pt-2">
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.RED}
                                    text={"Delete " + achievementName.toLowerCase()}
                                    icon={<TrashIcon className="h-3 w-3 mr-1" />}
                                    loadingIconSize="h-3 w-3 mr-1"
                                    iconPositionLeft
                                    onClick={() => handleDeleteAchievement(achievement)}
                                    disabled={false}
                                    smallButton
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>
            <div className={"w-full text-right col-span-2 mt-6"}>
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    text={"Add " + achievementName}
                    icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    smallButton
                    onClick={handleAddAchievement}
                />
            </div>
        </>
    );
}

export default AddAchievement;
