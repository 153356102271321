import { BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../../utils/constants";

import BarChart from "../../../Charts/CustomCharts/BarChart";
import TrendBubble from "../../../SharedChartComponents/TrendBubble";

export default function QuestionsDataChart(props) {
    const {
        data,
        title,
        demo,
        colourPalette,
        noItemTitles,
        topXAxis,
        questions,
        emphasiseOrganisationCategory,
        axisLabels,
        infoBubble,
        noQuestionBubble,
        onlyBubbleOnFirstBarOnPrint,
        noInfoBubbleOnPrint,
        noInfoBubbles,
        noBottomAxis,
        noTrendLegend,
        extraElements,
        extraElementsTop,
        extraStyling,
        noContainer,
        showResponses,
        percentage,
        percentageAxisLabels,
        percentageAxisMargin,
    } = props;

    return (
        <div
            className={
                "flex flex-col gap-10 print:gap-0 w-full print:p-0 print:border-0 print:shadow-none " +
                (noContainer ? "p-0 " : "bg-white p-4 rounded-xl border border-slate-100 shadow-sm ") +
                (extraStyling ? extraStyling : "")
                // +
                // " break-before-page"
            }
        >
            {extraElementsTop && extraElementsTop}
            <BarChart
                demo={demo}
                title={title}
                questions={questions}
                colourPalette={colourPalette ? colourPalette : BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                trendBubble={demo && <TrendBubble percentage={-15} text="vs 2022" />}
                emphasiseSectorBenchmark
                noItemTitle={noItemTitles}
                topXAxis={topXAxis}
                axisLabels={axisLabels}
                infoBubble={infoBubble}
                noInfoBubbles={noInfoBubbles}
                noQuestionBubble={noQuestionBubble}
                onlyBubbleOnFirstBarOnPrint={onlyBubbleOnFirstBarOnPrint}
                noInfoBubbleOnPrint={noInfoBubbleOnPrint}
                percentage={percentage}
                percentageAxisLabels={percentageAxisLabels}
                percentageAxisMargin={percentageAxisMargin}
                data={
                    demo
                        ? title === "Areas"
                            ? [
                                  {
                                      itemTitle: "Financial Performance",
                                      itemText: "My business is on track to be a financial success.",
                                      data: [
                                          {
                                              name: "VIC",
                                              percentScore: 54,
                                              category: "VIC",
                                          },
                                          {
                                              name: "NSW",
                                              percentScore: 34,
                                              category: "NSW",
                                          },
                                          {
                                              name: "QLD",
                                              percentScore: 67,
                                              category: "QLD",
                                          },
                                          {
                                              name: "SA",
                                              percentScore: 89,
                                              category: "SA",
                                          },
                                          {
                                              name: "WA",
                                              percentScore: 58,
                                              category: "WA",
                                          },
                                          {
                                              name: "NT",
                                              percentScore: 45,
                                              category: "NT",
                                          },
                                          {
                                              name: "Sector benchmark",
                                              percentScore: 72,
                                              category: "Sector benchmark",
                                          },
                                      ],
                                  },
                                  {
                                      itemTitle: "Feeling Optimistic",
                                      itemText: "I feel optimistic about my future in this franchise.",
                                      data: [
                                          {
                                              name: "VIC",
                                              percentScore: 70,
                                              category: "VIC",
                                          },
                                          {
                                              name: "NSW",
                                              percentScore: 78,
                                              category: "NSW",
                                          },
                                          {
                                              name: "QLD",
                                              percentScore: 58,
                                              category: "QLD",
                                          },
                                          {
                                              name: "SA",
                                              percentScore: 68,
                                              category: "SA",
                                          },
                                          {
                                              name: "WA",
                                              percentScore: 43,
                                              category: "WA",
                                          },
                                          {
                                              name: "NT",
                                              percentScore: 67,
                                              category: "NT",
                                          },
                                          {
                                              name: "Sector benchmark",
                                              percentScore: 67,
                                              category: "Sector benchmark",
                                          },
                                      ],
                                  },
                              ]
                            : [
                                  {
                                      itemText: "“Is always positive and approachable”",
                                      data: [
                                          {
                                              name: "Psych Press",
                                              percentScore: 36,
                                              category: "Psych Press",
                                          },
                                          {
                                              name: "Sector benchmark",
                                              percentScore: 86,
                                              category: "Sector benchmark",
                                          },
                                      ],
                                  },
                                  {
                                      itemText: "“Is responsive and reliable in keeping to their commitments”",
                                      data: [
                                          {
                                              name: "Psych Press",
                                              percentScore: 67,
                                              category: "Psych Press",
                                          },
                                          {
                                              name: "Sector benchmark",
                                              percentScore: 78,
                                              category: "Sector benchmark",
                                          },
                                      ],
                                  },
                                  {
                                      itemText: "“Listens to us respectfully”",
                                      data: [
                                          {
                                              name: "Psych Press",
                                              percentScore: 45,
                                              category: "Psych Press",
                                          },
                                          {
                                              name: "Sector benchmark",
                                              percentScore: 67,
                                              category: "Sector benchmark",
                                          },
                                      ],
                                  },
                                  {
                                      itemText: "“Is straightforward and clear when communicating with us”",
                                      data: [
                                          {
                                              name: "Psych Press",
                                              percentScore: 34,
                                              category: "Psych Press",
                                          },
                                          {
                                              name: "Sector benchmark",
                                              percentScore: 78,
                                              category: "Sector benchmark",
                                          },
                                      ],
                                  },
                              ]
                        : data
                }
                emphasiseOrganisationCategory={emphasiseOrganisationCategory}
                noBottomAxis={noBottomAxis}
                noTrendLegend={noTrendLegend}
                showResponses={showResponses}
            />
            {extraElements}
        </div>
    );
}
