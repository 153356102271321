export default function NavigationDot(props) {
    const { goToPage, currentPage, page } = props;

    return (
        <span
            className={
                "h-3 w-3 rounded-full cursor-pointer transition transition-color  " +
                (currentPage === page ? "bg-primary-600" : "bg-slate-300 hover:bg-slate-400")
            }
            onClick={goToPage}
        />
    );
}
