import { useContext } from "react";

import { Context } from "../../../../../../../context/Context";

import BasicMotion from "../../../../../../SharedComponents/Motion/BasicMotion";

export default function AceReportPicker(props) {
    const { small } = props;

    const context = useContext(Context);

    function handleChangeAceReport(indexLocationOfReport) {
        context.setAceSurveyReportData(context.aceSurveyReports[indexLocationOfReport]);
        context.setChosenAceReport(indexLocationOfReport);
    }

    return (
        context.aceSurveyReports.length > 1 && (
            <BasicMotion>
                <div className={"flex flex-col gap-2 print:hidden"}>
                    {!small && <div className={"font-medium text-primary-600"}>ACE Report</div>}
                    <select
                        id="aceSurveyReports"
                        name="aceSurveyReports"
                        className={
                            "text-primary-600 cursor-pointer hover:text-primary-700 bg-primary-50 " +
                            "rounded-xl shadow-sm flex font-medium " +
                            "bg-primary-100 rounded-xl shadow-sm pr-10 text-left " +
                            "focus:outline-none outline-none ring-0 " +
                            "sm:text-sm border-2 border-primary-100 focus:border-primary-300 " +
                            (small ? "p-1.5 w-max" : "p-2 w-full")
                        }
                        onChange={(e) => handleChangeAceReport(e.target.value)}
                        value={context.chosenAceReport}
                    >
                        {context.aceSurveyReports.map((aceSurveyReport, index) => (
                            <option className={"text-ellipsis overflow-hidden"} key={index} value={index}>
                                {aceSurveyReport.currentSurveyLabel}
                            </option>
                        ))}
                    </select>
                </div>
            </BasicMotion>
        )
    );
}
