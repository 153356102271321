import { useState } from "react";

import { MENU_DRIVEN_CATEGORY_COLOURS } from "../../../../../../utils/constants";

import PaginatedSearch from "../../../../../SharedComponents/Pagination/PaginatedSearch";
import Scale from "./ChartCards/Scale";
import BarChart from "../../Charts/CustomCharts/BarChart";
import TabbedNavigationLayout from "../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import CoachingGuidelineCard from "./CoachingGuidelineCard";

export default function Recruitment(props) {
    const {
        score,
        jumpToRefs,
        noTitle,
        coacheeName,
        onlyCharts,
        hideExtraContent,
        showCoachingGuideline,
        printToPDFMode,
    } = props;

    const [detailedTabIndex, setDetailedTabIndex] = useState(0);
    const [snapshotTabIndex, setSnapshotTabIndex] = useState(0);

    let currentCoacheeName = coacheeName ? coacheeName : "Psych Press";

    const snapshotTabList = ["Managing Tasks", "Managing Self", "Managing Others", "Managing Risk"];

    const snapshotTabContent = snapshotTabList.map((category) => {
        const filteredFields = score.reportFields
            .filter((reportField) => reportField.category === category)
            .map((reportField) => ({
                percentScore: reportField.percentile,
                name: reportField.name,
                category: reportField.category,
            }))
            .sort((a, b) => a.category.localeCompare(b.category));

        return {
            title: category,
            content: (
                <div
                    data-item="scrollback-content"
                    className={
                        "flex flex-col gap-6 bg-slate-50 rounded-xl p-4 print:bg-white " +
                        "print:p-0 print:border-0 print:mt-4 print:shadow-none print:break-inside-avoid-page  "
                    }
                >
                    <div className="flex justify-between items-center">
                        <div className={"text-base font-medium " + MENU_DRIVEN_CATEGORY_COLOURS[category].textColour}>
                            {category}
                        </div>
                        {MENU_DRIVEN_CATEGORY_COLOURS[category].icon}
                    </div>
                    <BarChart
                        colourPalette={MENU_DRIVEN_CATEGORY_COLOURS[category].barChartColourPalette}
                        data={filteredFields}
                        percentageAxisLabels={{
                            first: "0%",
                            second: "25%",
                            third: "50%",
                            fourth: "75%",
                            fifth: "99%",
                        }}
                        percentage
                        labelAddOn="ile"
                        percentageAxisMargin
                        noTrendLegend
                        filterText="Scales"
                        alwaysDisplayFilterDropdown
                        noInfoBubbleOnPrint
                        barTransitionDuration={printToPDFMode ? "0" : "4"}
                    />
                </div>
            ),
            readonly: true,
            tabSelectedClassName: MENU_DRIVEN_CATEGORY_COLOURS[category].tabColourClasses,
        };
    });

    const detailedTabList = ["Managing Tasks", "Managing Self", "Managing Others", "Managing Risk"];

    const detailedTabContent = detailedTabList.map((category) => {
        const filteredFields = score.reportFields
            .filter((reportField) => reportField.category === category)
            .map((reportField) => ({
                percentScore: reportField.percentile,
                name: reportField.name,
                category: reportField.category,
                percentage: reportField.percentile,
                scaleDescription: reportField.description,
                scoreDescription: reportField.bandDescription,
                lowAdjective: "Incompetent",
                highAdjective: "Competent",
                cardBackgroundColour: MENU_DRIVEN_CATEGORY_COLOURS[category].cardBackgroundColour,
                borderColour: MENU_DRIVEN_CATEGORY_COLOURS[category].borderColour,
                textColour: MENU_DRIVEN_CATEGORY_COLOURS[category].textColour,
                icon: MENU_DRIVEN_CATEGORY_COLOURS[category].icon,
                colour: MENU_DRIVEN_CATEGORY_COLOURS[category].colour,
                coacheeName: currentCoacheeName,
                extraContent: (reportField.considerationsWithinTheWorkplace || reportField.coachingTips) && (
                    <CoachingGuidelineCard reportField={reportField} showCoachingGuideline={showCoachingGuideline} />
                ),
            }))
            .sort((a, b) => a.category.localeCompare(b.category));

        return {
            title: category,
            content: (
                <div
                    data-item="scrollback-content"
                    className={
                        "bg-slate-50 rounded-xl p-4 flex flex-col gap-4 " +
                        "print:p-0 print:border-0 print:mt-4 print:shadow-none print:bg-white"
                    }
                >
                    <div className="flex justify-between items-center print:hidden">
                        <div className={"text-base font-medium " + MENU_DRIVEN_CATEGORY_COLOURS[category].textColour}>
                            {category}
                        </div>
                        {MENU_DRIVEN_CATEGORY_COLOURS[category].icon}
                    </div>
                    <PaginatedSearch
                        items={filteredFields}
                        itemName={"scales"}
                        searchAttributes={["name", "category", "scaleDescription", "scoreDescription"]}
                        ItemRow={Scale}
                        cards
                        customItemsPerPage={printToPDFMode ? 99 : 20}
                        customCardGrid="flex flex-col gap-4"
                        printMode
                    />
                </div>
            ),
            readonly: true,
            tabSelectedClassName: MENU_DRIVEN_CATEGORY_COLOURS[category].tabColourClasses,
        };
    });

    return (
        <div ref={jumpToRefs.recruitmentReport} className="flex flex-col gap-4">
            {!noTitle && <div className="text-primary-600 font-medium">Recruitment report</div>}
            <div className="flex flex-col gap-4">
                <div
                    ref={jumpToRefs.snapshot}
                    className={
                        "flex flex-col gap-8 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                        "print:p-0 print:border-0 print:mt-4 print:shadow-none print:bg-white"
                    }
                >
                    <div className="flex flex-col gap-4">
                        <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                            Snapshot
                        </div>
                        <TabbedNavigationLayout
                            tabIndex={snapshotTabIndex}
                            setTabIndex={setSnapshotTabIndex}
                            tabList={snapshotTabList}
                            tabContent={snapshotTabContent}
                            tabsRowOnly
                            noContainer
                            noTitle
                            displayOnlyContent={printToPDFMode === undefined ? true : printToPDFMode}
                        />
                    </div>
                </div>
                <div
                    ref={jumpToRefs.detailedSummary}
                    className={
                        "flex flex-col gap-8 bg-white rounded-xl p-4 shadow-sm border border-slate-100" +
                        "print:p-0 print:border-0 print:mt-4 print:shadow-none"
                    }
                >
                    <div className="flex flex-col gap-4">
                        <div
                            data-item="scrollback-content"
                            className="text-secondary-600 font-medium text-base print:hidden"
                        >
                            Detailed summary
                        </div>
                        <TabbedNavigationLayout
                            tabIndex={detailedTabIndex}
                            setTabIndex={setDetailedTabIndex}
                            tabList={detailedTabList}
                            tabContent={detailedTabContent}
                            tabsRowOnly
                            noContainer
                            noTitle
                            displayOnlyContent={printToPDFMode === undefined ? true : printToPDFMode}
                        />
                    </div>
                </div>
                <div
                    ref={jumpToRefs.recruitmentIntroduction}
                    className={
                        "flex-col gap-2 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                        "print:p-0 print:border-0 print:mt-4 print:shadow-none print:break-before-page " +
                        (onlyCharts || hideExtraContent ? "hidden" : "flex")
                    }
                >
                    <div className="text-secondary-600 font-medium text-base">Overview</div>
                    <div className="flex flex-col gap-2">
                        {score.introduction.description.map((paragraph) => (
                            <p className="text-slate-500 text-sm">{paragraph}</p>
                        ))}
                    </div>
                </div>
                <div
                    ref={jumpToRefs.recruitmentBackground}
                    className={
                        "flex-col gap-2 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                        "print:p-0 print:border-0 print:mt-4 print:shadow-none print:break-before-page " +
                        (onlyCharts || hideExtraContent ? "hidden" : "flex")
                    }
                >
                    <div className="text-secondary-600 font-medium text-base">{score.backGroundInformation.title}</div>
                    {score.backGroundInformation.subHeadings.map((subHeading) => (
                        <div className={"flex flex-col gap-2"}>
                            <div className="text-secondary-600 font-medium text-sm">{subHeading.title}</div>
                            {subHeading.description.map((paragraph) => (
                                <p className="text-slate-500 text-sm">{paragraph}</p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
