import React from "react";
import RadioButton from "../../../SharedComponents/Form/RadioButton";

export default function LikertScale(props) {
    const { scaleOptions } = props;

    /** Customize likert scale label (hidden for now)
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleAddScaleOption = () => {
        setScaleOptions([...scaleOptions, { label: "Option name", value: "" }]);
    };

    const handleRemoveScaleOption = () => {
        setScaleOptions(scaleOptions.slice(0, -1));
    };
    
    const handleLabelChange = (event, index) => {
        const newLabel = event.target.textContent;

        const newValue = newLabel
            .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
            .replace(/[^a-zA-Z0-9]/g, "")
            .replace(/^[A-Z]/, (m) => m.toLowerCase());

        setScaleOptions((prevOptions) =>
            prevOptions.map((option, optionIndex) =>
                optionIndex === index
                    ? {
                          ...option,
                          label: newLabel,
                          value: newValue,
                      }
                    : option
            )
        );
    };
    */

    return (
        <fieldset>
            <div className="flex flex-row flex-wrap gap-6">
                {scaleOptions.map((scaleOption, index) => (
                    <RadioButton
                        key={index}
                        index={index}
                        id={scaleOption.label}
                        name="rating"
                        value={scaleOption.label}
                        labelText={scaleOption.label}
                        defaultChecked={true}
                        disabled={false}
                        editableLabel={false}
                    />
                ))}

                {/** Hide custom likert scale options
                <div className="justify-end self-end flex flex-row gap-3 ml-3">
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GREEN_OUTLINED}
                        text="Add scale option"
                        icon={<PlusCircleIcon className="h-3 w-3 mr-1" />}
                        loadingIconSize="h-3 w-3 mr-1"
                        iconPositionLeft
                        onClick={handleAddScaleOption}
                        disabled={false}
                        smallButton
                    />
                    <Button
                        colour={BUTTON_VARIANT_TYPES.RED_OUTLINED}
                        text="Remove scale option"
                        icon={<MinusCircleIcon className="h-3 w-3 mr-1" />}
                        loadingIconSize="h-3 w-3 mr-1"
                        iconPositionLeft
                        onClick={handleRemoveScaleOption}
                        disabled={scaleOptions && scaleOptions.length <= 2}
                        smallButton
                    />
                </div>
                */}
            </div>
        </fieldset>
    );
}
