import React from "react";

export const CustomTopAxisCompactBars = (props) => {
    const { hideScoreNumbers, score } = props;

    return (
        <div className={"grid grid-cols-5"}>
            <div className={"col-span-1"} />
            <div className={"col-span-4 flex flex-col gap-1.5 " + (hideScoreNumbers ? "mr-0" : "mr-14")}>
                <div className={"flex flex-row w-full font-title text-xs xl:text-sm text-slate-400 font-medium"}>
                    <div className="w-25-percentage text-right">
                        {score.reportLevelNames ? score.reportLevelNames[0] : "Entry"}
                    </div>
                    <div className="w-25-percentage text-right">
                        <span className="-ml-2">
                            {score.reportLevelNames ? score.reportLevelNames[1] : "Foundation"}
                        </span>
                    </div>
                    <div className="w-25-percentage text-right">
                        {score.reportLevelNames ? score.reportLevelNames[2] : "Advanced"}{" "}
                    </div>
                    <div className="w-25-percentage text-right">
                        <span className="-ml-2">{score.reportLevelNames ? score.reportLevelNames[3] : "Mastery"}</span>
                    </div>
                </div>
                <div className="flex flex-row w-full">
                    <div
                        className={
                            "w-25-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5"
                        }
                    />
                    <div
                        className={
                            "w-25-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5"
                        }
                    />
                    <div
                        className={
                            "w-25-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5"
                        }
                    />
                    <div className={"w-25-percentage border-slate-400 border-dashed border-2 border-b-0 h-3.5"} />
                </div>
            </div>
        </div>
    );
};
