import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserPlusIcon } from "@heroicons/react/24/solid";

import {
    getCoachees,
    getCoacheesForCoach,
    getCoacheesWithOverallScore,
    getCoacheesForCoachWithOverallScore,
} from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import CoacheeRow from "./CoacheeRow";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import CoacheeRowWithOverall from "./CoacheeRowWithOverall";

export default function Coaches() {
    const context = useContext(Context);

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coachees, setCoachees] = useState([]);
    const [allCoachees, setAllCoachees] = useState(false);
    const [allCoacheesLoading, setAllCoacheesLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const columnNames = ["Details", context.terminology.coach ? context.terminology.coach.singular : "Coach", "Edit"];

    const navigate = useNavigate();

    const location = useLocation();

    const userHasPermissionToSeeOverallScore =
        context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COACHEES_OVERALL_SCORE) ||
        context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_USER_COACHEES_OVERALL_SCORE);

    if (userHasPermissionToSeeOverallScore) {
        columnNames.unshift("Overall");
    }

    const fetchCoachees = useCallback(async () => {
        if (!allCoacheesLoading) {
            setLoading(true);
            try {
                if (allCoachees) {
                    let response;

                    if (userHasPermissionToSeeOverallScore) {
                        response = await getCoacheesWithOverallScore();
                    } else {
                        response = await getCoachees();
                    }

                    setCoachees(response);
                } else {
                    let response;

                    if (userHasPermissionToSeeOverallScore) {
                        response = await getCoacheesForCoachWithOverallScore();
                    } else {
                        response = await getCoacheesForCoach();
                    }

                    response.map((coachee) => {
                        coachee.coachIdentifier = context.profileDetails.userIdentifier;
                        coachee.coachName = `${context.profileDetails.firstName} ${context.profileDetails.lastName}`;
                        return response;
                    });

                    setCoachees(response);
                }
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoading(false);
        }
    }, [
        allCoachees,
        allCoacheesLoading,
        context.profileDetails.firstName,
        context.profileDetails.lastName,
        context.profileDetails.userIdentifier,
        userHasPermissionToSeeOverallScore,
    ]);

    useEffect(() => {
        fetchCoachees();
    }, [context.permissionsLoading, fetchCoachees]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllCoachees(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES));
            setAllCoacheesLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout
            title={
                allCoachees
                    ? context.terminology.coachee
                        ? context.terminology.coachee.plural
                        : "Coachees"
                    : "Your " + (context.terminology.coachee ? context.terminology.coachee.plural : "Coachees")
            }
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.VIEW_COACHEES_LIST,
                CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST,
            ]}
            actionButton
            actionButtonRequiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.SETUP_COACHEE,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
            actionButtonText={
                "Setup " + (context.terminology.coachee ? context.terminology.coachee.singular : "Coachee")
            }
            actionButtonIcon={<UserPlusIcon className="block h-3.5 w-3.5 mr-2" />}
            actionButtonOnClick={() => navigate(`/setup-user/${context.terminology.coachee.singular.toLowerCase()}`)}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                loading={loading}
                items={coachees}
                itemName={context.terminology.coachee ? context.terminology.coachee.plural : "Coachees"}
                searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                columnNames={columnNames}
                ItemRow={userHasPermissionToSeeOverallScore ? CoacheeRowWithOverall : CoacheeRow}
                customPlaceholder={
                    "Search " +
                    (context.terminology.coachee ? context.terminology.coachee.plural.toLowerCase() : "coachees") +
                    " by first name, last name or email address"
                }
            />
        </DashboardLayout>
    );
}
