import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    AtSymbolIcon,
    UserIcon,
    DevicePhoneMobileIcon,
    UsersIcon,
    ArrowUturnLeftIcon,
    RectangleStackIcon,
    TableCellsIcon,
    BriefcaseIcon,
    ArrowsRightLeftIcon,
    ClipboardDocumentCheckIcon,
    CalendarDaysIcon,
    InformationCircleIcon,
} from "@heroicons/react/24/solid";

import {
    getOrganisationUsersLite,
    getOrganisationWorkflows,
    getPrograms,
    setupCoachee,
    setupNewCoachee,
} from "../../../../utils/api";
import {
    BUTTON_VARIANT_TYPES,
    USER_GROUPS,
    ACE_2_SURVEY_PLATFORM,
    EMPLOYMENT_SERVICES,
    ON_BEHALF_OF_PRODUCT_NAMES,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import Input from "../../../SharedComponents/Form/Input";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import Tag from "../../../SharedComponents/Tag";
import Tippy from "@tippyjs/react";
import CheckBox from "../../../SharedComponents/Form/CheckBox";

export default function SetupCoacheeForm(props) {
    const { multipleCoacheeSetup, setCoacheeList, closeParentModal } = props;

    const [alert, setAlert] = useState("");
    const [subAlert, setSubAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [existingUserSetup, setExistingUserSetup] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [chosenUserIdentifier, setChosenUserIdentifier] = useState();
    const [mobileNumber, setMobileNumber] = useState("");
    const [cohorts, setCohorts] = useState([]);
    const [chosenCohort, setChosenCohort] = useState(null);
    const [selectableCohorts, setSelectableCohorts] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [chosenProgram, setChosenProgram] = useState(null);
    const [selectablePrograms, setSelectablePrograms] = useState([]);
    const [jobSeekerId, setJobSeekerId] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectableOrganisationUsers, setSelectableOrganisationUsers] = useState([]);
    const [organisationUsers, setOrganisationUsers] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [selectableWorkflows, setSelectableWorkflows] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [workflowIdentifier, setWorkflowIdentifier] = useState(null);
    const [workflowDueDate, setWorkflowDueDate] = useState(null);
    const [isOnBehalfOf, setIsOnBehalfOf] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [chosenRole, setChosenRole] = useState(null);
    const [selectableRoles] = useState([{ label: "Valued role", value: "Valued role" }]);
    const [chosenRegionalManager, setChosenRegionalManager] = useState(null);
    const [selectableRegionalManagers] = useState([{ label: "Fabio Monsalve", value: "Fabio Monsalve" }]);
    const [chosenRegion, setChosenRegion] = useState(null);
    const [selectableRegions] = useState([{ label: "Victoria", value: "Victoria" }]);

    const navigate = useNavigate();

    const context = useContext(Context);

    const handleExistingUserSetupChange = async () => {
        if (existingUserSetup) {
            setExistingUserSetup(false);
        } else {
            setExistingUserSetup(true);
        }
    };

    const handleChosenUserIdentifierPick = useCallback(async () => {
        if (chosenUserIdentifier) {
            const selectedUser = organisationUsers.find((user) => user.userIdentifier === chosenUserIdentifier);
            setSelectedUser(selectedUser);
            setEmailAddress(selectedUser.emailAddress);
        }
    }, [chosenUserIdentifier, organisationUsers]);

    const handleSetupUser = async () => {
        setSubmitLoading(true);

        if (chosenProgram && !chosenCohort) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert("You are required to choose a cohort to be assigned to a program");
            setSubmitLoading(false);
            return;
        }

        const coacheeCohorts = [];

        // Backend requires cohort to be in array due to Coach accepting multiple cohorts and sharing of endpoint.
        if (chosenCohort && chosenCohort.length >= 1) {
            coacheeCohorts.push(chosenCohort);
        }

        if (!workflowIdentifier && workflowDueDate) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert("You are required to choose an assessment if the due date was entered");
            setSubmitLoading(false);
            return;
        }

        if (!workflowIdentifier && isOnBehalfOf) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert(
                `You are required to choose an assessment if you are willing to complete the assessment on behalf ` +
                    ` of the ${context.terminology.coachee.singular.toLowerCase()}`
            );
            setSubmitLoading(false);
            return;
        }

        const workflow = workflows.find((workflow) => workflow.workflowIdentifier === workflowIdentifier);

        if (isOnBehalfOf && !ON_BEHALF_OF_PRODUCT_NAMES.includes(workflow.productName)) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert("Sorry, the selected assessment is not applicable to be completed on behalf of");
            setSubmitLoading(false);
            return;
        }

        const assignWorkflowOnSetup = {
            workflowIdentifier: workflowIdentifier,
            dueDate: workflowDueDate,
            isAssessmentCompletedOnBehalf: isOnBehalfOf,
        };

        try {
            if (multipleCoacheeSetup) {
                setCoacheeList((prevState) => [
                    ...prevState,
                    {
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress,
                        role: chosenRole,
                        regionalManager: chosenRegionalManager,
                        region: chosenRegion,
                    },
                ]);
                closeParentModal();
            } else {
                if (existingUserSetup) {
                    await setupCoachee(chosenUserIdentifier, coacheeCohorts, jobSeekerId, assignWorkflowOnSetup);
                } else {
                    if (!emailAddress || emailAddress.length === 0) {
                        setAlertType(ALERT_TYPE.ERROR);
                        setAlert("Please enter a valid email address");
                        setSubmitLoading(false);
                        return;
                    }

                    await setupNewCoachee(
                        firstName,
                        lastName,
                        emailAddress,
                        mobileNumber,
                        coacheeCohorts,
                        jobSeekerId,
                        assignWorkflowOnSetup
                    );
                }

                navigate(`/${context.terminology.coachee.plural.toLowerCase()}`, {
                    state: {
                        alertType: ALERT_TYPE.SUCCESS,
                        alertMessage: existingUserSetup
                            ? "Successfully setup " +
                              selectedUser.firstName +
                              " " +
                              selectedUser.lastName +
                              " as a " +
                              context.terminology.coachee.singular.toLowerCase()
                            : "Successfully setup " +
                              firstName +
                              " " +
                              lastName +
                              " as a " +
                              context.terminology.coachee.singular.toLowerCase(),
                    },
                });
            }

            setSubmitLoading(false);
        } catch (err) {
            setSubmitLoading(false);

            if (err.message.includes("|")) {
                const splitErrorMessages = err.message.split("|").map((message) => (
                    <ul className={"list-disc pl-3"}>
                        <li>{message}</li>
                    </ul>
                ));

                setSubAlert(splitErrorMessages);
            }

            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry, there's been an error setting up your " +
                    context.terminology.coachee.singular +
                    ". Check that all details are correct and that the email address isn't already being used by " +
                    "another user"
            );
        }
        setSubmitLoading(false);
    };

    const fetchOrganisationUsers = useCallback(async () => {
        setLoading(true);

        try {
            const response = await getOrganisationUsersLite(true);

            let sanitizedUsers = [];

            for (const user of response) {
                if (!user.userGroups.includes(USER_GROUPS.COACHEE)) {
                    sanitizedUsers.push({
                        value: user.userIdentifier,
                        label: `${user.firstName} ${user.lastName}`,
                    });
                }
            }

            setSelectableOrganisationUsers(sanitizedUsers);
            setOrganisationUsers(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setLoading(false);
    }, []);

    const fetchPrograms = async () => {
        setLoading(true);

        try {
            const response = await getPrograms();

            let sanitizedPrograms = [];
            let sanitizedCohorts = [];

            for (const { name, cohorts } of response) {
                sanitizedPrograms.push({
                    value: name,
                    label: name,
                });

                cohorts.forEach((cohort) => {
                    sanitizedCohorts.push({
                        value: cohort,
                        label: cohort,
                        program: name,
                    });
                });
            }
            setSelectablePrograms(sanitizedPrograms);
            setCohorts(sanitizedCohorts);
            setPrograms(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setLoading(false);
    };

    const fetchAssessments = async () => {
        setLoading(true);

        try {
            const response = await getOrganisationWorkflows();

            let sanitizedWorkflows = [];

            response.forEach((workflow) => {
                sanitizedWorkflows = [
                    {
                        value: workflow.workflowIdentifier,
                        label: workflow.productName,
                        subLabel:
                            ON_BEHALF_OF_PRODUCT_NAMES.includes(workflow.productName) &&
                            "*Applicable to complete on behalf of*",
                    },
                    ...sanitizedWorkflows,
                ];
            });

            setWorkflows(response);
            setSelectableWorkflows(sanitizedWorkflows);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchPrograms();
        fetchAssessments();
    }, []);

    useEffect(() => {
        fetchOrganisationUsers();
    }, [fetchOrganisationUsers]);

    useEffect(() => {
        handleChosenUserIdentifierPick();
    }, [handleChosenUserIdentifierPick]);

    useEffect(() => {
        if (chosenProgram) {
            const filteredCohorts = cohorts.filter((cohort) => cohort.program === chosenProgram);
            setSelectableCohorts(filteredCohorts);
        }
    }, [chosenProgram, cohorts]);

    useEffect(() => {
        programs.forEach((program) => {
            if (program.name === chosenProgram && !program.cohorts.includes(chosenCohort)) {
                setChosenCohort(null);
            }
        });
    }, [chosenCohort, chosenProgram, programs]);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <Alert
                text={<div className="flex flex-col gap-2">{subAlert}</div>}
                type={ALERT_TYPE.WARNING}
                active={subAlert}
                close={() => setSubAlert("")}
            />
            <div className={"focus:outline-none shadow-sm rounded-xl w-full bg-white outline-none"}>
                <div
                    className={
                        "flex justify-between flex-col " +
                        (multipleCoacheeSetup && " h-[600px] overflow-x-scroll lg:h-auto lg:overflow-x-hidden")
                    }
                >
                    <div className={"p-4 border border-slate-100 border-1 rounded-t-xl"}>
                        <div className={"flex flex-col lg:grid lg:grid-cols-2 gap-6"}>
                            <div className="col-span-2 justify-between flex items-center">
                                <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                    {!existingUserSetup
                                        ? `Set up new ${context.terminology.coachee.singular}`
                                        : "Set up existing user"}
                                </h2>
                                <div
                                    onClick={handleExistingUserSetupChange}
                                    className={"text-sm text-left font-medium"}
                                >
                                    <div
                                        className={
                                            "text-primary-600 cursor-pointer hover:text-primary-700 bg-primary-50 " +
                                            "p-2 rounded-xl shadow-sm flex w-fit"
                                        }
                                    >
                                        <ArrowsRightLeftIcon
                                            className={
                                                "h-4 w-4 text-primary-600 hover:text-primary-700 self-center mr-2"
                                            }
                                        />
                                        {existingUserSetup
                                            ? `Set up new ${context.terminology.coachee.singular}`
                                            : "Set up existing user"}
                                    </div>
                                </div>
                            </div>
                            {existingUserSetup && (
                                <MultiSelect
                                    items={selectableOrganisationUsers}
                                    labelText={"User"}
                                    loading={loading}
                                    setChosenItems={setChosenUserIdentifier}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                />
                            )}
                            <Input
                                id="email"
                                type="email"
                                label="Email"
                                placeholder="Enter email"
                                onChange={setEmailAddress}
                                icon={<AtSymbolIcon className="h-4 w-4 text-slate-500" />}
                                disabled={existingUserSetup ? "disabled" : ""}
                                currentValue={emailAddress}
                            />
                            {!existingUserSetup && (
                                <Input
                                    optional
                                    id="mobile"
                                    type="number"
                                    label="Mobile"
                                    placeholder="Enter mobile number"
                                    onChange={setMobileNumber}
                                    icon={<DevicePhoneMobileIcon className="h-4 w-4 text-slate-500" />}
                                    disabled={existingUserSetup ? "disabled" : ""}
                                    value={mobileNumber}
                                />
                            )}
                            {!existingUserSetup && (
                                <Input
                                    optional
                                    type="text"
                                    label="First name"
                                    id="firstname"
                                    placeholder={"First name"}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setFirstName}
                                    value={firstName}
                                />
                            )}
                            {!existingUserSetup && (
                                <Input
                                    optional
                                    type="text"
                                    label="Last name"
                                    id="lastname"
                                    placeholder={"Last name"}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setLastName}
                                    value={lastName}
                                />
                            )}
                            {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                <MultiSelect
                                    items={selectableRegionalManagers}
                                    chosenItems={chosenRegionalManager}
                                    labelText={"Regional manager"}
                                    setChosenItems={setChosenRegionalManager}
                                    icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                    placeholder="Choose a regional manager"
                                />
                            )}
                            {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                <MultiSelect
                                    items={selectableRegions}
                                    chosenItems={chosenRegion}
                                    labelText={"Region"}
                                    setChosenItems={setChosenRegion}
                                    icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                    placeholder="Choose a region"
                                />
                            )}
                            {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                <MultiSelect
                                    items={selectableRoles}
                                    chosenItems={chosenRole}
                                    labelText={"Role"}
                                    setChosenItems={setChosenRole}
                                    icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                    placeholder="Choose a role"
                                />
                            )}
                            <div className={"col-span-full flex flex-col lg:flex-row gap-6 w-full"}>
                                <MultiSelect
                                    items={selectablePrograms}
                                    chosenItems={chosenProgram}
                                    labelText={"Program"}
                                    setChosenItems={setChosenProgram}
                                    loading={loading}
                                    icon={<RectangleStackIcon className="h-4 w-4 text-slate-500" />}
                                    placeholder="Choose a program"
                                    extraStyling="w-full"
                                    optional
                                />
                                <MultiSelect
                                    optional
                                    value={chosenCohort}
                                    items={selectableCohorts}
                                    chosenItems={chosenCohort}
                                    labelText={"Cohort"}
                                    setChosenItems={setChosenCohort}
                                    loading={loading}
                                    icon={<TableCellsIcon className="h-4 w-4 text-slate-500" />}
                                    disabled={!chosenProgram}
                                    placeholder={
                                        chosenProgram
                                            ? programs.find((program) => program.name === chosenProgram).cohorts
                                                  .length === 0
                                                ? "This program has no cohorts"
                                                : "Choose a cohort"
                                            : "You must choose a program"
                                    }
                                    extraStyling="w-full"
                                />
                            </div>
                            {context.defaultApp === EMPLOYMENT_SERVICES && (
                                <>
                                    <Input
                                        optional
                                        type="text"
                                        label="Job Seeker ID"
                                        id="jobSeekerId"
                                        placeholder={"Job Seeker ID"}
                                        icon={<BriefcaseIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setJobSeekerId}
                                        value={jobSeekerId}
                                    />
                                    <div className="col-span-2 flex items-end gap-4">
                                        <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                            Assign assessment
                                        </h2>
                                        <Tag status={"Optional"} />
                                    </div>
                                    <MultiSelect
                                        items={selectableWorkflows}
                                        labelText={"Assessment"}
                                        placeholder={"Choose an assessment to assign"}
                                        loading={loading}
                                        setChosenItems={setWorkflowIdentifier}
                                        value={workflowIdentifier}
                                        icon={<ClipboardDocumentCheckIcon className="h-4 w-4 text-slate-500" />}
                                        subLabel={true}
                                        optional
                                    />
                                    <Input
                                        id="workflowDueDate"
                                        type="datetime-local"
                                        label="Due date"
                                        icon={<CalendarDaysIcon className="h-4 w-4 text-slate-500" />}
                                        placeholder={"Assessment due date"}
                                        onChange={setWorkflowDueDate}
                                        optional
                                    />
                                    <div className="flex flex-col gap-2 w-full">
                                        <Tippy
                                            content={
                                                `Having this checked, you will act upon the ` +
                                                `${context.terminology.coachee.singular} to complete ` +
                                                `the assessment, else the assessment will be assigned to the ` +
                                                `${context.terminology.coachee.singular}'s account`
                                            }
                                            className={
                                                "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                                "bg-primary-600 text-white"
                                            }
                                            arrow={false}
                                            animation={"shift-away-subtle"}
                                            placement={"right"}
                                            hideOnClick={false}
                                        >
                                            <div className={"flex flex-row items-center gap-2 w-fit"}>
                                                <label
                                                    className={"tracking-tight text-sm font-medium text-primary-600 "}
                                                >
                                                    Completion type
                                                </label>
                                                <InformationCircleIcon className="h-4 w-4 text-primary-600 cursor-help" />
                                            </div>
                                        </Tippy>

                                        <div className="mt-2 flex flex-row gap-6">
                                            <CheckBox
                                                id="onBehalfOf"
                                                name="completionType"
                                                value={isOnBehalfOf}
                                                labelText={
                                                    `Complete assessment on behalf of the ` +
                                                    `${context.terminology.coachee.singular}`
                                                }
                                                setValue={setIsOnBehalfOf}
                                            />
                                        </div>

                                        <div className={"text-xs text-slate-400 w-full text-right mt-3"}>Optional</div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 " +
                        "text-right rounded-b-xl w-full"
                    }
                >
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GRAY}
                        text={multipleCoacheeSetup ? "Cancel" : "Back"}
                        iconPositionLeft
                        icon={!multipleCoacheeSetup && <ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                        onClick={
                            multipleCoacheeSetup
                                ? () => closeParentModal()
                                : () => navigate(`/${context.terminology.coachee.plural.toLowerCase()}`)
                        }
                    />
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text={`Setup ${context.terminology.coachee.singular}`}
                        icon={<UsersIcon className="block h-3.5 w-3.5  mr-2" />}
                        iconPositionLeft
                        onClick={handleSetupUser}
                        loading={submitLoading}
                    />
                </div>
            </div>
        </>
    );
}
