export default function CheckBox(props) {
    const { labelText, value, id, name, setValue } = props;

    return (
        <div className="flex gap-2 items-center">
            <input
                id={id}
                name={name}
                type="checkbox"
                className={
                    "focus:outline-none focus:ring-0 h-4 w-4 text-primary-500 border-slate-200 shadow-sm rounded-full"
                }
                checked={value}
                onChange={(e) => setValue(e.target.checked)}
            />

            <label htmlFor={id} className="block text-sm font-medium text-slate-500">
                {labelText}
            </label>
        </div>
    );
}
