import { motion } from "framer-motion";
import { useContext } from "react";

import { Context } from "../../../../../../context/Context";

export default function BarVertical(props) {
    const { label, score, backgroundColour, textColour, separationGap, noLabel, smallerBars } = props;

    const context = useContext(Context);

    return (
        <div
            className={
                "flex gap-2 flex-col items-center bg-slate-100 rounded-xl font-medium h-full rotate-180 " +
                separationGap +
                " " +
                (smallerBars ? "text-xs" : "text-sm")
            }
        >
            <motion.div initial={{ height: 0 }} animate={{ height: `${score}%` }} transition={{ duration: 4 }}>
                <div
                    className={
                        "transition ease-in-out duration-300 rounded-xl p-2 h-full  " +
                        backgroundColour +
                        " " +
                        (smallerBars ? "w-[34px]" : "w-[42px]")
                    }
                    style={{ whiteSpace: "nowrap" }}
                >
                    {!noLabel && (
                        <span className={"text-left rounded-lg py-0.5 px-1 [writing-mode:vertical-rl] " + textColour}>
                            {label ? label : context.aceSurveyReportData.currentSurveyLabel}
                        </span>
                    )}
                </div>
            </motion.div>
            <div className={"transition ease-in-out duration-300 -rotate-180 pt-1.5"}>
                <span className={"rounded-lg py-0.5 px-1 font-semibold " + textColour}>{score}%</span>
            </div>
        </div>
    );
}
