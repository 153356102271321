import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useCycle } from "framer-motion";
import ReactMarkdown from "react-markdown";

import {
    BUTTON_VARIANT_TYPES,
    CAPABILITIES_NAMES_CASEY,
    CAPABILITIES_NAMES_GENERIC,
    CAPABILITIES_NAMES_LEAD,
    CAPABILITIES_NAMES_SOCIAL_FUTURES,
    CAPABILITIES_NAMES_VGCCC,
    CAPABILITIES_ORDER_CASEY,
    CAPABILITIES_ORDER_GENERIC,
    CAPABILITIES_ORDER_VGCCC,
    CAPABILITIES_RADAR_ORDER_CITY_OF_CASEY,
    CAPABILITIES_RADAR_ORDER_VGCCC,
} from "../../../../../../../utils/constants";
import { Context } from "../../../../../../../context/Context";
import {
    generateBarLevelPercentageBasedOnLevel,
    generateLevelNumber,
    generateProportionalPercentage,
} from "../../../../../../../utils/utils";

import SideSection from "../../../SharedChartComponents/SideSection";
import ReportHeader from "../../../SharedChartComponents/ReportHeader";
import CapabilityCard from "../CapabilityCard";
import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import Toggle from "../../../../../../SharedComponents/Form/Toggle";
import CollapsibleCard from "../../../../../../SharedComponents/CollapsibleCard";
import PrintToPDF from "../../../../../../SharedComponents/PrintToPDF";
import { JumpToLinksCapabilityProfile } from "./JumpToLinksCapabilityProfile";
import { SnapshotRadarChart } from "./SnapshotRadarChart";
import { CapabilitiesSummaryBarChart } from "./CapabilitiesSummaryBarChart";

export default function CapabilityProfile(props) {
    return (
        <DashboardLayout requiredPermissions={[]}>
            <CapabilityProfileMeat {...props} />
        </DashboardLayout>
    );
}

export function CapabilityProfileMeat(props) {
    const { score, testId, hideScoreNumbers, old } = props;

    const [shortReport, setShortReport] = useCycle(false, true);
    const [contentInteracted, setContentInteracted] = useState(false);
    const [filteredRadarFields, setFilteredRadarFields] = useState([]);
    const [filteredBarChartFields, setFilteredBarChartFields] = useState([]);
    const [defaultFilteredRadarFields, setDefaultFilteredRadarFields] = useState([]);
    const [chosenCategoriesBigRadar, setChosenCategoriesBigRadar] = useState(["selfAssessmentScore", "mindsetScore"]);
    const [printToPDFMode, setPrintToPDFMode] = useState(false);
    const [navigatingToPrint, setNavigatingToPrint] = useState(false);

    const context = useContext(Context);

    const location = useLocation();

    const introduction = useRef(null);
    const capabilityProfile = useRef(null);
    const detailedCapabilityProfile = useRef(null);
    const snapshot = useRef(null);
    const capabilitiesSummary = useRef(null);
    const adaptiveMindset = useRef(null);
    const collaboration = useRef(null);
    const communication = useRef(null);
    const creativity = useRef(null);
    const criticalThinking = useRef(null);
    const customerFocus = useRef(null);
    const empathy = useRef(null);
    const ethics = useRef(null);
    const problemSolvingAndData = useRef(null);
    const leadership = useRef(null);
    const agileAndInnovative = useRef(null);
    const directionAndPurpose = useRef(null);
    const engagingAndCoaching = useRef(null);

    const reportRefs = {
        introduction: introduction,
        capabilityProfile: capabilityProfile,
        detailedCapabilityProfile: detailedCapabilityProfile,
        snapshot: snapshot,
        capabilitiesSummary: capabilitiesSummary,
        adaptiveMindset: adaptiveMindset,
        collaboration: collaboration,
        communication: communication,
        creativity: creativity,
        criticalThinking: criticalThinking,
        customerFocus: customerFocus,
        empathy: empathy,
        ethics: ethics,
        problemSolvingAndData: problemSolvingAndData,
        leadership: leadership,
        agileAndInnovative: agileAndInnovative,
        directionAndPurpose: directionAndPurpose,
        engagingAndCoaching: engagingAndCoaching,
    };

    const CAPABILITIES_AND_REFS_MAPPING = {
        "Adaptive Mindset": adaptiveMindset,
        Collaboration: collaboration,
        Communication: communication,
        Creativity: creativity,
        "Critical Thinking": criticalThinking,
        "Customer Focus": customerFocus,
        Empathy: empathy,
        Ethics: ethics,
        "Problem Solving & Data": problemSolvingAndData,
        Leadership: leadership,
        "Agile & Innovative": agileAndInnovative,
        "Direction & Purpose": directionAndPurpose,
        "Engagement & Coaching": engagingAndCoaching,
    };

    const CAPABILITIES_AND_REFS_MAPPING_SOCIAL_FUTURES = {
        "Adaptive Mindset (Display Resilience and Courage, Manage Self)": adaptiveMindset,
        "Agile & Innovative (additional to SF)": collaboration,
        "Collaboration (Work Collaboratively)": communication,
        "Communication (Communicate Effectively and Influence and Negotiation)": creativity,
        "Creativity (additional to SF)": criticalThinking,
        "Critical Thinking (additional to SF)": customerFocus,
        "Customer Focused (Commit to Customer Service)": empathy,
        "Direction and Purpose (Deliver Results and Plan and Prioritise)": ethics,
        "Empathy (additional to SF)": problemSolvingAndData,
        "Engagement and Coaching (Value Diversity)": leadership,
        "Ethics (Act with Integrity)": agileAndInnovative,
        "Leadership (Demonstrate Accountability)": directionAndPurpose,
        "Problem Solving & Data (Think and Solve Problems)": engagingAndCoaching,
    };

    const CAPABILITIES_AND_REFS_MAPPING_CASEY = {
        "Adaptive Mindset": adaptiveMindset,
        Collaboration: collaboration,
        "Customer Focus": customerFocus,
        "Ethics & Integrity": ethics,
        "Shared Leadership": leadership,
        "Improve & Innovate": agileAndInnovative,
        "Strategic Direction & Purpose": directionAndPurpose,
        "Build High Performing Teams": engagingAndCoaching,
    };

    const CAPABILITIES_AND_REFS_MAPPING_LEAD = {
        "Agile & Innovative": agileAndInnovative,
        "Direction & Purpose": directionAndPurpose,
        "Engagement & Coaching": engagingAndCoaching,
        Leadership: leadership,
    };

    const CAPABILITIES_AND_REFS_MAPPING_VGCCC = {
        "Work Together": adaptiveMindset,
        "Adaptive Mindset": collaboration,
        "Accountability & Outcomes": communication,
        "Stakeholder Relationships & Influence": creativity,
        "Decision Making & Judgment": criticalThinking,
        "Digital & Data": customerFocus,
        "Systems Thinking": empathy,
        "Social Responsibility & Harm Minimisation": ethics,
        Leadership: problemSolvingAndData,
        "Performance & Coaching": leadership,
        "Innovate & Improve": agileAndInnovative,
        "Make it Happen": directionAndPurpose,
    };

    const organisationName = context.profileDetails.organisationName;

    const capabilitiesOrder =
        score.reportName === "LEAD Capability Profile"
            ? CAPABILITIES_ORDER_GENERIC
            : organisationName === "City of Casey"
            ? CAPABILITIES_ORDER_CASEY
            : organisationName === "VGCCC"
            ? CAPABILITIES_ORDER_VGCCC
            : CAPABILITIES_ORDER_GENERIC;

    let capabilityNames =
        score.reportName === "LEAD Capability Profile"
            ? CAPABILITIES_NAMES_LEAD
            : organisationName === "City of Casey"
            ? CAPABILITIES_NAMES_CASEY
            : organisationName === "VGCCC"
            ? CAPABILITIES_NAMES_VGCCC
            : organisationName === "Social Futures"
            ? CAPABILITIES_NAMES_SOCIAL_FUTURES
            : CAPABILITIES_NAMES_GENERIC;

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop - (window.innerWidth < 1280 ? 170 : 16),
            behavior: "smooth",
        });
    };

    function handleFilteredRadarFieldsChange(fieldName) {
        if (chosenCategoriesBigRadar.includes(fieldName)) {
            let currentFilteredRadarFields = [...filteredRadarFields];

            currentFilteredRadarFields.forEach((currentField) => {
                delete currentField[fieldName];
            });

            setFilteredRadarFields(currentFilteredRadarFields);
            setChosenCategoriesBigRadar(chosenCategoriesBigRadar.filter((category) => category !== fieldName));
        } else {
            let currentFilteredRadarFields = [...filteredRadarFields];
            let currentDefaultFilteredRadarFields = [...defaultFilteredRadarFields];

            currentDefaultFilteredRadarFields.forEach((defaultField) => {
                currentFilteredRadarFields.forEach((filteredRadarField) => {
                    if (defaultField.name === filteredRadarField.name) {
                        if (fieldName === "mindsetScore") {
                            filteredRadarField.mindsetScore = defaultField.mindsetScore;
                        } else {
                            filteredRadarField.selfAssessmentScore = defaultField.selfAssessmentScore;
                        }
                    }
                });
            });

            setChosenCategoriesBigRadar([...chosenCategoriesBigRadar, fieldName]);
        }
    }

    function handleReportInteraction() {
        setPrintToPDFMode(false);
    }

    let capabilities = useMemo(() => JSON.parse(JSON.stringify(score?.capabilities)), [score?.capabilities]);

    const reportCompactMode = organisationName !== "City of Casey" && organisationName !== "VGCCC";

    useEffect(() => {
        let initialFilteredFields = [];
        let initialFilteredRadarFields = [];
        let initialDefaultFilteredRadarFields = [];

        // Necessary rearrangement of order for radar chart for VGCCC
        if (organisationName === "VGCCC") {
            capabilities = sortCapabilitiesBasedOnClient(capabilities, CAPABILITIES_RADAR_ORDER_VGCCC);
        } else if (organisationName === "City of Casey") {
            capabilities = sortCapabilitiesBasedOnClient(capabilities, CAPABILITIES_RADAR_ORDER_CITY_OF_CASEY);
        }

        capabilities.map((reportField) => {
            return initialFilteredFields.push({
                itemTitle: reportField.name,
                data: [
                    {
                        percentScore: old
                            ? generateProportionalPercentage(reportField["selfAssessmentScore"])
                            : generateBarLevelPercentageBasedOnLevel(
                                  generateLevelNumber(reportField["selfAssessmentScore"], "self-assessed")
                              ),
                        name: "Self-assessed Current Capability",
                        category: reportField.name.replace(/\s*\([^)]*\)/, ""),
                    },
                    {
                        percentScore: old
                            ? generateProportionalPercentage(reportField["mindsetScore"])
                            : generateBarLevelPercentageBasedOnLevel(
                                  generateLevelNumber(reportField["mindsetScore"], "mindset")
                              ),
                        name: "Mindset",
                        category: reportField.name.replace(/\s*\([^)]*\)/, ""),
                    },
                ],
            });
        });

        capabilities.map((reportField) => {
            return initialFilteredRadarFields.push({
                name: reportField.name.replace(/\s*\([^)]*\)/, ""),
                selfAssessmentScore: old
                    ? reportField["selfAssessmentScore"]
                    : generateLevelNumber(reportField["selfAssessmentScore"], "self-assessed"),
                mindsetScore: old
                    ? reportField["mindsetScore"]
                    : generateLevelNumber(reportField["mindsetScore"], "mindset"),
            });
        });

        capabilities.map((reportField) => {
            return initialDefaultFilteredRadarFields.push({
                name: reportField.name,
                selfAssessmentScore: old
                    ? reportField["selfAssessmentScore"]
                    : generateLevelNumber(reportField["selfAssessmentScore"], "self-assessed"),
                mindsetScore: old
                    ? reportField["mindsetScore"]
                    : generateLevelNumber(reportField["mindsetScore"], "mindset"),
            });
        });

        function sortCapabilitiesBasedOnClient(capabilities, order) {
            return capabilities.sort((a, b) => {
                return order.indexOf(a.name) - order.indexOf(b.name);
            });
        }

        setFilteredRadarFields([...initialFilteredRadarFields]);
        setDefaultFilteredRadarFields([...initialDefaultFilteredRadarFields]);

        initialFilteredFields.sort((a, b) => {
            const mindsetScoreA = a.data.find((item) => item.name === "Mindset").percentScore;
            const mindsetScoreB = b.data.find((item) => item.name === "Mindset").percentScore;
            const selfAssessedScoreA = a.data.find(
                (item) => item.name === "Self-assessed Current Capability"
            ).percentScore;
            const selfAssessedScoreB = b.data.find(
                (item) => item.name === "Self-assessed Current Capability"
            ).percentScore;

            if (mindsetScoreA !== mindsetScoreB) {
                return mindsetScoreB - mindsetScoreA;
            }

            return selfAssessedScoreA - selfAssessedScoreB;
        });

        setFilteredBarChartFields(initialFilteredFields);
    }, [score.capabilities, organisationName]);

    return (
        <>
            <div className="flex flex-col gap-6" data-testid={testId && testId}>
                <ReportHeader
                    noNegativeTopMargin
                    noReportWordInTitle
                    largeTitle
                    reportName={score.reportName}
                    preparedBy={"Prepared by Capability Co"}
                    organisationLogo
                    userName={location.state?.userName ? location.state.userName : ""}
                />
                <div
                    className={
                        " flex flex-row gap-2 print:hidden sticky top-4 left-4 bg-white p-3 rounded-xl " +
                        "border border-slate-100 shadow-lg z-50 relative print:hidden 2xl:hidden"
                    }
                >
                    <JumpToLinksCapabilityProfile
                        singles
                        shortReport={shortReport}
                        scrollToSection={scrollToSection}
                        organisationName={organisationName}
                        capabilityNames={capabilityNames}
                        reportRefs={reportRefs}
                        setPrintToPDFMode={setPrintToPDFMode}
                        setNavigatingToPrint={setNavigatingToPrint}
                        navigatingToPrint={navigatingToPrint}
                        reportName={score.reportName}
                    />
                </div>
                <div className="2xl:hidden bg-white flex flex-row gap-4 p-2 px-2.5 rounded-xl w-fit border border-slate-100 shadow-sm print:hidden">
                    <div className="w-max" onClick={() => setShortReport()}>
                        <Toggle text={"Executive mode"} triggered={shortReport} />
                    </div>
                </div>
                <div className={"grid grid-cols-1 2xl:grid-cols-4 gap-6"}>
                    <div className="col-span-3 flex flex-col gap-8">
                        <div className="col-span-2 flex flex-col gap-8">
                            <div ref={introduction} className={"flex-col gap-4 " + (shortReport ? "hidden" : "flex")}>
                                <div className="text-primary-600 font-medium">Introduction</div>
                                <div
                                    className={
                                        "flex flex-col gap-6 bg-white rounded-xl p-4 shadow-sm border " +
                                        "border-slate-100 print:p-0 print:border-0 print:shadow-none " +
                                        (shortReport ? "hidden" : "flex")
                                    }
                                >
                                    <CollapsibleCard
                                        title={score.understandingYourCapabilityProfile.title}
                                        backgroundColour={"bg-slate-50"}
                                        borderColour={"border-slate-100"}
                                        noContainer
                                        buttonVariant={BUTTON_VARIANT_TYPES.UNCOLOURED_BUTTON}
                                        titleTextColour={"text-secondary-600"}
                                        alwaysShow
                                        content={
                                            <div className="flex flex-col gap-3">
                                                {score.understandingYourCapabilityProfile.description.map(
                                                    (paragraph, index) => (
                                                        <p
                                                            key={index + "capabilityProfile"}
                                                            className="text-slate-500 text-sm"
                                                        >
                                                            <ReactMarkdown>{paragraph}</ReactMarkdown>
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        }
                                    />
                                    <div className={"flex flex-col gap-2 break-before-page"}>
                                        <div className="text-secondary-600 font-medium print:-mt-6">
                                            {score.capabilityLevels.title}
                                        </div>
                                        <div className={shortReport ? "hidden" : "flex"}>
                                            {score.capabilityLevels.description && (
                                                <p className="text-slate-500 text-sm">
                                                    {score.capabilityLevels.description}
                                                </p>
                                            )}
                                        </div>
                                        <table className={"w-full rounded-xl overflow-hidden shadow-sm mt-3"}>
                                            <thead className={"bg-slate-50"}>
                                                <tr>
                                                    {score.capabilityLevels.tableHeaders.map((columnName, index) => (
                                                        <th
                                                            key={index + "capabilityLevelColumn"}
                                                            scope={"col"}
                                                            className={
                                                                "px-4 py-3 text-left text-sm font-medium text-slate-500 " +
                                                                "overflow-hidden " +
                                                                (index === 0
                                                                    ? "rounded-tl-xl"
                                                                    : index ===
                                                                          score.capabilityLevels.tableHeaders.length -
                                                                              1 && "rounded-tr-xl")
                                                            }
                                                        >
                                                            {columnName}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {score.capabilityLevels.tableRows.map((row, index) => (
                                                    <tr
                                                        key={index + "capabilityLevelRow"}
                                                        className={"hover:bg-slate-100 transition-colors group"}
                                                    >
                                                        <td className="px-4 py-3 whitespace-nowrap">
                                                            <div className={"text-sm font-medium text-slate-500"}>
                                                                {row.title}
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3">
                                                            <div className={"text-sm text-slate-500"}>
                                                                <ul
                                                                    className={
                                                                        "list-disc ml-3 grid grid-flow-row gap-3"
                                                                    }
                                                                >
                                                                    {row.points.map((point, index) => (
                                                                        <li key={index + "rowPoint"}>{point}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div ref={capabilityProfile} className="flex flex-col gap-4 break-before-page print:-mt-8">
                                <div className="text-primary-600 font-medium">Your Capability Profile</div>
                                <div
                                    ref={snapshot}
                                    className={
                                        "flex flex-col gap-2 bg-white rounded-xl p-4 shadow-sm border " +
                                        "border-slate-100 print:p-0 print:border-0 print:shadow-none"
                                    }
                                >
                                    <div
                                        data-item="scrollback-content"
                                        className="text-secondary-600 font-medium text-base"
                                    >
                                        Snapshot
                                    </div>
                                    <div
                                        className={
                                            "flex flex-col gap-8 " +
                                            (organisationName === "sampleOrgThatNeedsThisAtSomePoint"
                                                ? "print:gap-8"
                                                : "print:gap-12")
                                        }
                                    >
                                        <div className={"flex flex-col gap-4"}>
                                            <div className={"flex-col gap-3 " + (shortReport ? "hidden" : "flex")}>
                                                {score.snapshot.map((paragraph, index) => (
                                                    <p key={index + "snapshot"} className="text-slate-500 text-sm">
                                                        {paragraph}
                                                    </p>
                                                ))}
                                            </div>
                                            {score.harnessingYourResults && (
                                                <div
                                                    className={
                                                        "flex flex-col gap-2 " + (shortReport ? "hidden" : "flex")
                                                    }
                                                >
                                                    {score.harnessingYourResults.title && (
                                                        <div
                                                            className={
                                                                "flex flex-row gap-2 items-center font-medium text-base w-max text-secondary-600"
                                                            }
                                                        >
                                                            {score.harnessingYourResults.title}
                                                        </div>
                                                    )}
                                                    {score.harnessingYourResults.description &&
                                                        score.harnessingYourResults.description.map(
                                                            (paragraph, index) => (
                                                                <div
                                                                    key={index + "harnessingYourResults"}
                                                                    className="text-slate-500 text-sm"
                                                                >
                                                                    <ReactMarkdown>{paragraph}</ReactMarkdown>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        <SnapshotRadarChart
                                            organisationName={organisationName}
                                            chosenCategoriesBigRadar={chosenCategoriesBigRadar}
                                            handleFilteredRadarFieldsChange={handleFilteredRadarFieldsChange}
                                            filteredRadarFields={filteredRadarFields}
                                            maxValue={old ? 100 : 4}
                                        />
                                    </div>
                                </div>
                                <div
                                    ref={capabilitiesSummary}
                                    className={
                                        "flex flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border print:-mt-4 " +
                                        "border-slate-100 print:p-0 print:border-0 print:shadow-none break-before-page"
                                    }
                                >
                                    <div className="flex flex-col gap-4 h-max">
                                        <div
                                            data-item="scrollback-content"
                                            className="text-secondary-600 font-medium text-base"
                                        >
                                            Capabilities Summary
                                        </div>
                                        <CapabilitiesSummaryBarChart
                                            score={score}
                                            chosenCategoriesBigRadar={chosenCategoriesBigRadar}
                                            handleFilteredRadarFieldsChange={handleFilteredRadarFieldsChange}
                                            hideScoreNumbers={hideScoreNumbers}
                                            filteredBarChartFields={filteredBarChartFields}
                                            reportCompactMode={reportCompactMode}
                                            old={old}
                                        />
                                    </div>
                                </div>
                            </div>
                            {((organisationName === "City of Casey" &&
                                score.reportName !== "LEAD Capability Profile") ||
                                organisationName === "Social Futures" ||
                                (score.reportName === "LEAD Capability Profile" &&
                                    organisationName !== "VGCCC" &&
                                    organisationName !== "City of Casey")) && (
                                <div className={"break-before-page hidden print:block"}></div>
                            )}
                            <div className="flex flex-col gap-8">
                                <div ref={detailedCapabilityProfile} className="flex flex-col gap-4">
                                    <div className="text-primary-600 font-medium print:hidden">
                                        Detailed Capability Profile Report
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        {capabilities
                                            .sort((a, b) => {
                                                return (
                                                    capabilitiesOrder.indexOf(a.name) -
                                                    capabilitiesOrder.indexOf(b.name)
                                                );
                                            })
                                            .map((capability, index) => (
                                                <CapabilityCard
                                                    key={index + "capabilityCard"}
                                                    capability={capability}
                                                    jumpToRef={
                                                        score.reportName === "LEAD Capability Profile"
                                                            ? CAPABILITIES_AND_REFS_MAPPING_LEAD[capability.name]
                                                            : organisationName === "City of Casey"
                                                            ? CAPABILITIES_AND_REFS_MAPPING_CASEY[capability.name]
                                                            : organisationName === "VGCCC"
                                                            ? CAPABILITIES_AND_REFS_MAPPING_VGCCC[capability.name]
                                                            : organisationName === "Social Futures"
                                                            ? CAPABILITIES_AND_REFS_MAPPING_SOCIAL_FUTURES[
                                                                  capability.name
                                                              ]
                                                            : CAPABILITIES_AND_REFS_MAPPING[capability.name]
                                                    }
                                                    onlyCharts={shortReport}
                                                    sectionTitle={"Detailed capability profile report"}
                                                    levelNames={score.reportLevelNames}
                                                    printToPDFMode={printToPDFMode}
                                                    userName={location.state?.userName ? location.state.userName : null}
                                                    hidePercentScoreNumbers={hideScoreNumbers && !old}
                                                    highlightLevel={reportCompactMode}
                                                    old={old}
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SideSection
                        orgName={"Capability Co"}
                        setContentInteracted={setContentInteracted}
                        noDefaultContent
                        extraContent={
                            <div
                                className={
                                    "flex flex-col gap-4 print:hidden sticky top-4 left-4 p-4 rounded-xl " +
                                    "shadow-xl border border-slate-100 z-50 hidden w-auto 2xl:flex " +
                                    "print:hidden bg-white mt-10 transition-opacity " +
                                    "print:p-0 print:border-0 print:shadow-none overflow-y-scroll h-[600px] " +
                                    (contentInteracted ? "opacity-5 " : "opacity-100 ") +
                                    (navigatingToPrint ? "pointer-events-none" : "pointer-events-auto")
                                }
                                {...(!navigatingToPrint && { onMouseLeave: handleReportInteraction })}
                            >
                                <PrintToPDF
                                    setPrintToPDFMode={setPrintToPDFMode}
                                    setNavigatingToPrint={setNavigatingToPrint}
                                    navigatingToPrint={navigatingToPrint}
                                />
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-row gap-2 items-center">
                                        <div className="text-slate-500 font-medium text-sm">Options:</div>
                                    </div>
                                    <div className="flex flex-col gap-4 print:hidden">
                                        <div className="" onClick={() => setShortReport()}>
                                            <Toggle text={"Executive mode"} triggered={shortReport} />
                                        </div>
                                    </div>
                                </div>
                                <JumpToLinksCapabilityProfile
                                    singles={false}
                                    shortReport={shortReport}
                                    scrollToSection={scrollToSection}
                                    organisationName={organisationName}
                                    capabilityNames={capabilityNames}
                                    reportRefs={reportRefs}
                                    setPrintToPDFMode={setPrintToPDFMode}
                                    setNavigatingToPrint={setNavigatingToPrint}
                                    navigatingToPrint={navigatingToPrint}
                                    reportName={score.reportName}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
            <div className="bottom-0 hidden print:flex print:fixed w-full pt-1 justify-end">
                <div className="text-slate-500 font-medium text-sm  w-max">
                    © {new Date().getFullYear()} Capability Co
                </div>
            </div>
        </>
    );
}
