import { useContext, useEffect, useState } from "react";
import { ArrowUturnLeftIcon, UsersIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate } from "react-router-dom";

import { getCoaches, getCoachees, assignCoach, reassignCoach } from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, NO_ACCESS_TYPES } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import AccessControl from "../../../SharedComponents/AccessControl";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";

export default function AssignCoach() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coachIdentifier, setCoachIdentifier] = useState();
    const [coacheeIdentifier, setCoacheeIdentifier] = useState();
    const [coaches, setCoaches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [coacheesLoading, setCoacheesLoading] = useState(false);
    const [coachesLoading, setCoachesLoading] = useState(false);
    const [selectableCoachees, setSelectableCoachees] = useState([]);
    const [selectableCoaches, setSelectableCoaches] = useState([]);
    const [reassign, setReassign] = useState(false);
    const [coacheeDropDownDisabled, setCoacheeDropDownDisabled] = useState(false);

    const context = useContext(Context);

    const navigate = useNavigate();

    const location = useLocation();

    const handleAssignCoach = async () => {
        const coach = coaches.find((event) => event.userIdentifier === coachIdentifier);

        setLoading(true);

        try {
            if (reassign) {
                await reassignCoach(coachIdentifier, coacheeIdentifier, coach.firstName, coach.lastName);
                setAlert(`Successfully reassigned ${context.terminology.coach.singular}`);
            } else {
                await assignCoach(coachIdentifier, coacheeIdentifier, coach.firstName, coach.lastName);
                setAlert(`Successfully assigned ${context.terminology.coach.singular}`);
            }
            setAlertType(ALERT_TYPE.SUCCESS);
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }

        setLoading(false);
    };

    const fetchCoaches = async () => {
        setCoachesLoading(true);

        try {
            const response = await getCoaches();

            response.forEach((coach) => {
                setSelectableCoaches((coaches) => [
                    {
                        value: coach.userIdentifier,
                        label: coach.firstName + " " + coach.lastName + " (" + coach.emailAddress + ")",
                    },
                    ...coaches,
                ]);
            });

            setCoaches(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setCoachesLoading(false);
    };

    const fetchCoachees = async () => {
        setCoacheesLoading(true);

        try {
            const response = await getCoachees();

            response.forEach((coachee) => {
                setSelectableCoachees((coachees) => [
                    {
                        value: coachee.userIdentifier,
                        label: coachee.firstName + " " + coachee.lastName + " (" + coachee.emailAddress + ")",
                    },
                    ...coachees,
                ]);
            });
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setCoacheesLoading(false);
    };

    useEffect(() => {
        fetchCoaches();

        if (location.state) {
            if (!location.state.coacheeUserIdentifier) {
                fetchCoachees();
            } else {
                setCoacheeDropDownDisabled(true);
                setSelectableCoachees([
                    {
                        value: location.state.coacheeUserIdentifier,
                        label:
                            location.state.coacheeFirstName +
                            " " +
                            location.state.coacheeLastName +
                            " (" +
                            location.state.coacheeEmailAddress +
                            ")",
                    },
                ]);
            }
            if (location.state.coacheeUserIdentifier) {
                setCoacheeIdentifier(location.state.coacheeUserIdentifier);
            }
            if (location.state.coachUserIdentifier) {
                setCoachIdentifier(location.state.coachUserIdentifier);
            }
            if (location.state.reassignCoach) {
                setReassign(location.state.reassignCoach);
            }
        } else {
            fetchCoachees();
        }
    }, [location]);

    return (
        <DashboardLayout
            title={
                (reassign ? "Reassign " : "Assign ") +
                (context.terminology.coach ? context.terminology.coach.singular : "Coach")
            }
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH]}
        >
            <p className="mb-4 text-sm text-slate-500">
                Please complete the form below to {reassign ? "reassign" : "assign"} a{" "}
                {context.terminology.coach ? context.terminology.coach.singular : "Coach"} to{" "}
                {context.terminology.coachee ? context.terminology.coachee.singular : "Coachee"}
            </p>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4 border border-slate-100 border-1 rounded-t-xl"}>
                        <div className={"mb-6"}>
                            <div className={"grid grid-flow-row xl:grid-cols-2 gap-6"}>
                                <div className={"flex flex-col gap-3"}>
                                    <MultiSelect
                                        items={selectableCoachees}
                                        labelText={
                                            context.terminology.coachee
                                                ? context.terminology.coachee.singular
                                                : "Coachee"
                                        }
                                        loading={coacheesLoading}
                                        setChosenItems={setCoacheeIdentifier}
                                        value={coacheeIdentifier}
                                        disabled={coacheeDropDownDisabled}
                                    />
                                </div>
                                <div className={"flex flex-col gap-3"}>
                                    <MultiSelect
                                        items={selectableCoaches}
                                        labelText={
                                            context.terminology.coach ? context.terminology.coach.singular : "Coach"
                                        }
                                        loading={coachesLoading}
                                        setChosenItems={setCoachIdentifier}
                                        value={coachIdentifier}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end text-right " +
                            "rounded-b-xl w-full"
                        }
                    >
                        <div className="flex flex-row gap-3">
                            <AccessControl
                                requiredPermissions={[COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH]}
                                NO_ACCESS_TYPES={NO_ACCESS_TYPES.BLANK}
                            >
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.GRAY}
                                    text={"Back"}
                                    iconPositionLeft
                                    icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                                    onClick={() => navigate(-1)}
                                />
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                    text={
                                        reassign
                                            ? "Reassign " +
                                              (context.terminology.coach ? context.terminology.coach.singular : "Coach")
                                            : "Assign " +
                                              (context.terminology.coach ? context.terminology.coach.singular : "Coach")
                                    }
                                    icon={<UsersIcon className="block h-3.5 w-3.5 mr-2" />}
                                    iconPositionLeft
                                    onClick={handleAssignCoach}
                                    loading={loading}
                                    disabled={
                                        !context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH) ||
                                        !context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH)
                                    }
                                />
                            </AccessControl>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
