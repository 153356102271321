import { useState } from "react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import { BUTTON_VARIANT_TYPES } from "../../utils/constants";

import Button from "../SharedComponents/Button";

export default function AlertError(props) {
    const { errorMessage } = props;

    const [copySuccess, setCopySuccess] = useState(false);

    const resetCopyToClipboard = () => {
        setCopySuccess(false);
    };

    const copyToClipboard = () => {
        const result = navigator.clipboard.writeText(errorMessage);
        result
            .then(() => {
                setCopySuccess(true);
                return true;
            })
            .catch((err) => {
                setCopySuccess(false);
                return false;
            });
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className={"flex flex-col gap-3 rounded-xl "}>
                <div className="flex flex-col gap-3">
                    <div className="font-medium">{errorMessage}</div>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row flex-col-reverse lg:items-center items-start gap-2 justify-between">
                <span className="text-sm text-slate-500">
                    If the error persists please refresh the page and/or{" "}
                    <a className={"font-medium underline"} href="mailto:techsupport@psychpress.com.au">
                        <span>contact us</span>
                    </a>{" "}
                    .
                </span>
                <Tippy
                    content={copySuccess === true ? "Copied!" : "Click/tap to copy"}
                    className={
                        "py-1.5 px-2 shadow rounded-2xl text-xs font-medium text-white " +
                        (copySuccess ? "bg-green-500" : "bg-slate-600")
                    }
                    arrow={false}
                    animation={"shift-away-subtle"}
                    placement={"top-start"}
                    hideOnClick={false}
                >
                    <div>
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Copy error message to clipboard"}
                            icon={
                                <DocumentDuplicateIcon
                                    className={"mr-1 shrink-0 block h-3.5 w-3.5 cursor-pointer hover:text-primary-700"}
                                    onClick={copyToClipboard}
                                    onMouseOut={resetCopyToClipboard}
                                />
                            }
                            iconPositionLeft
                            onClick={copyToClipboard}
                            onMouseOut={resetCopyToClipboard}
                            smallButton
                        />
                    </div>
                </Tippy>
            </div>
        </div>
    );
}
