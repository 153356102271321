export const sampleCapabilityProfileGenericScore = {
    reportName: "Capability Profile",
    reportLevelNames: ["Support & Action", "Guide", "Execute & Improve", "Shape"],
    reportDescription: "",
    userFirstName: "Han Chien",
    userLastName: "Leow",
    snapshot: [
        "Every individual has a different combination of Human capabilities, it's what makes you, you! Comparing your self-assessed current capability and mindset will help to explain why some activities you find easy and enjoyable while others are more challenging and require more effort and focus to complete.",
    ],
    understandingYourCapabilityProfile: {
        description: [
            "We see you. Now others can too.",
            "Welcome to your Discover Capability Report. Your personalised development map that can help you realise your true potential.",
            "Your profile shows where you're headed and where you can grow. The comparison between your self-assessment and assessed mindset results is where you can start gaining rich insights to support your development and growth.",
            "**To help you interpret your results**",
            "*Self-Assessment* refers to how you self-rated your current capability level",
            "*Mindset* refers to how the psychometric tool assessed your natural pre-disposition in each capability",
            "**Important to remember**",
            "There are no good or bad results. Every individual has a different combination of Human Capabilities. Embrace your uniqueness! Your journey is about uncovering what makes you awesome, not comparing yourself to others.",
            "Your Capability Profile is just one piece of the puzzle. Think of it like guidebook for making informed decisions, especially in your career. But it's not the whole story. Your experiences, training, and the world around you all play a part too.",
            "Give yourself the time to explore your profile and fully digest the information.",
            "Now it\u2019s time to **_Unleash Your Full Potential!_**",
        ],
    },
    harnessingYourResults: {
        title: "Harnessing your results",
        description: [
            "As well as providing a deeper understanding of yourself, your Capability Profile provides tangible actions and development material to Activate your capabilities as part of your day to day activities.",
            "**Mindset:** refers to an individual's natural disposition, encompassing the traits and abilities that influence attitudes, motivations, and thought processes. It shapes how a person engages, acts, or leads. Mindsets are foundational to both personality and behaviour.",
            "**Self-assessed Current Capability:** refers to a person's own belief of their current capability \u2013 where they believe they are performing now through demonstrated skills, knowledge and action.",
        ],
    },
    capabilityLevels: {
        title: "The Capability Levels",
        description:
            "Capability levels outline the expected levels of autonomy, responsibility, and complexity at each stage of development.",
        tableHeaders: ["Level", "Individuals who display behaviours at the following levels are likely to:"],
        tableRows: [
            {
                title: "Support & Action",
                points: [
                    "Understand and explain to others, rules, procedures, and the theory behind why something is important",
                    "Plan work so that you can successfully complete what you have been asked to do",
                    "Exercise personal responsibility",
                    "Work with others to find ways to overcome challenges",
                ],
            },
            {
                title: "Guide",
                points: [
                    "Understand and advise others how to improve existing rules and processes",
                    "Take accountability for meeting planned objectives",
                    "Work in a flexible manner, collaborating with others when appropriate, to identify ways to overcome barriers to success",
                ],
            },
            {
                title: "Execute & Improve",
                points: [
                    "Work beyond your own area of expertise to extend existing rules, procedures and theoretical knowledge",
                    "Hold accountability for outcomes that have a wider impact than your immediate sphere of influence, for example for the entire organisation or in the community",
                    "Operate in a highly effective way, in both familiar situations as well as those that are new, complex, or challenging",
                ],
            },
            {
                title: "Shape",
                points: [
                    "Make significant contribution to challenge or advance how existing rules and processes will meet future needs; Have defined accountability for outcomes that have long-term impact in the organisation or community",
                    "Use your knowledge to help people make decisions about things that will arise in the future",
                    "Use your experience and deep understanding to help others navigate unfamiliar and challenging situations",
                ],
            },
        ],
    },
    capabilities: [
        {
            name: "Adaptive Mindset",
            title: "Adaptive Mindset",
            version: "",
            description: [
                "**Definition:** Able to adjust to change, continually learn, and maintain curiosity while dealing with disruption, pressure, and setbacks, in a resilient, positive manner.",
                "**Your results indicate:** that you are applying your innate abilities and can work in a state of 'flow' - it is likely that you enjoy work and learning in this area. Consider how you can stretch yourself further and develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Flexibility",
                    title: "Flexibility",
                    description: "",
                    category: "Adaptive Mindset",
                    rawScore: 13,
                    componentItemCount: 5,
                    mean: 2.6,
                    percentile: 35,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Growth Focus",
                    title: "Growth Focus",
                    description: "",
                    category: "Adaptive Mindset",
                    rawScore: 20,
                    componentItemCount: 5,
                    mean: 4.0,
                    percentile: 94,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Positive Outlook",
                    title: "Positive Outlook",
                    description: "",
                    category: "Adaptive Mindset",
                    rawScore: 16,
                    componentItemCount: 5,
                    mean: 3.2,
                    percentile: 75,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 9.8,
            mean: 3.266666666666667,
            mindsetScore: 62,
            selfAssessmentScore: 71,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Adjust their style and approach to promote team optimism even when dealing with setbacks",
                    "Identify ways to grow team mindsets and learning to confront challenges",
                    "Work with others to adapt new ideas, practices or technologies to their context",
                    "Identify strategies to ensure team performance is maintained when confronted with changing goals or priorities",
                    "Monitor priorities and rebalance resources to continually achieve team outcomes",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Adaptive Mindset tend to:",
                points: [
                    "Be a systems thinker",
                    "Be able to engage with others to exchange ideas, information or thoughts",
                    "Deal well with ambiguity and complexity",
                    "Embrace challenges and new situations",
                    "Not procrastinate",
                    "Actively seek new experiences and learning opportunities",
                    "Envision what is possible and seek strategic opportunities",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take the lead on transformative change processes and projects at the organisational level, focussing on inspiring others to embrace change.",
                            "Implement a program within your organisation focused on adaptive leadership and resilience.",
                            "Engage in research projects or think-tank teams focusing on innovative technological advancements and emerging practices.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek coaching from a seasoned leader who has successfully led transformative change initiatives at the organisational level.",
                            "Join a peer coaching network of seasoned leaders to exchange insights, best practices, and strategies for overcoming obstacles and inspiring teams to embrace change.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue a certification or advanced coursework in change leadership, organisational transformation or agile practices.",
                            "Enrol in a masterclass on building resilience and inspiring positive mindsets within organisations.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Agile & Innovative",
            title: "Agile and Innovative",
            version: "",
            description: [
                "**Definition:** Able to respond quickly to challenges and opportunities. This includes being curious, suspending criticism as a default, asking questions to understand, examining assumptions, taking calculated risks, and experimenting.",
                "**Your results indicate:** that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Curiosity",
                    title: "Curiosity",
                    description: "",
                    category: "Agile and Innovative",
                    rawScore: 19,
                    componentItemCount: 6,
                    mean: 3.1666666666666665,
                    percentile: 80,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Exploration",
                    title: "Exploration",
                    description: "",
                    category: "Agile and Innovative",
                    rawScore: 15,
                    componentItemCount: 5,
                    mean: 3.0,
                    percentile: 70,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 6.166666666666666,
            mean: 3.083333333333333,
            mindsetScore: 75,
            selfAssessmentScore: 75,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Address adaptive challenges and opportunities that limit current products or practices",
                    "Initiate action quickly to seize opportunities or overcome challenges",
                    "Act with courage and confidence to challenge or confront existing thinking or practices",
                    "Lead experimentation, testing and development of a product or idea",
                    "Identify opportunities for new programs, products, or services",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Agile & Innovative mindset tend to:",
                points: [
                    "Seek to challenge traditional ideas or thinking",
                    "See things in a new or different perspective",
                    "Imagine and envisage opportunities",
                    "Take the initiative and energy to explore new ideas",
                    "Passionately explore what is possible",
                    "Show the ability to look over the horizon for him/herself and the industry",
                    "Appreciate trends or major factors impacting the organisation",
                    "Deal with ambiguity and complexity",
                    "Continuously seek improvement without prompting",
                    "Self-believe and motivate",
                    "Acknowledge and reward ideas and effort of others",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take the lead in designing and implementing innovative solutions that revolve around customer needs or that require a strategic, end-to-end view of customer value.",
                            "Lead cross-functional teams to create innovative strategies that enhance the overall customer experience.",
                            "Act as a change champion within your organisation. Mobilise support by role-modelling enthusiasm for innovative and improvement initiatives.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek a mentor who excels in designing innovative solutions around customer needs.",
                            "Engage in executive-level coaching explore your own mindset and skills in responding to challenges, risks and opportunities \u2013 this will better enable you to authentically mobilise support and create enthusiasm for change and innovation.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Consider enrolling in executive-level or post-graduate innovation, Lean Startup, or rapid design accredited courses.",
                            "Invest in advanced training related to Human-Centered Design (HCD) principles and tools. HCD is a powerful approach for improving practices and the customer experience.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Collaboration",
            title: "Collaboration",
            version: "",
            description: [
                "**Definition:** Able to work collaboratively with all types of people, contribute to teamwork and build relationships and networks across a range of people or groups. Collaboration emphasises more than just working with others or teamwork. It encompasses cooperation, building relationships between and across groups and building formal and informal networks.",
                "**Your results indicate:** that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Considerate",
                    title: "Considerate",
                    description: "",
                    category: "Collaboration",
                    rawScore: 14,
                    componentItemCount: 5,
                    mean: 2.8,
                    percentile: 80,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Cooperation",
                    title: "Cooperation",
                    description: "",
                    category: "Collaboration",
                    rawScore: 16,
                    componentItemCount: 5,
                    mean: 3.2,
                    percentile: 80,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Respect",
                    title: "Respect",
                    description: "",
                    category: "Collaboration",
                    rawScore: 17,
                    componentItemCount: 5,
                    mean: 3.4,
                    percentile: 85,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 9.4,
            mean: 3.1333333333333333,
            mindsetScore: 83,
            selfAssessmentScore: 61,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Guide level believes they are able to: ",
                points: [
                    "Influence and gain commitment from senior decision-makers and stakeholders",
                    "Nurture high-level relationships through the open exchange of ideas and information",
                    "Manage behaviours or practices that fail to align with organisational or community expectations",
                    "Form collaborative relationships to generate new ideas, intelligence, or perspectives on strategic challenges",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Collaboration mindset tend to:",
                points: [
                    "Be interested in people",
                    "Feel comfortable engaging with others and building purposeful relationships",
                    "Make others feel at ease",
                    "Employ a range of different influencing styles effectively",
                    "Value having long-term close relationships with people",
                    "Seek to ensure mutual benefit from relationships",
                    "Initiate and reach out to people",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take on leadership roles in cross-functional projects that require collaboration across different teams and functions.",
                            "Lead external stakeholder engagement initiatives to deliver projects or services to build strong relationships with external partners.",
                            "Initiate or participate in knowledge-sharing programs within your organisation to establish yourself as a trustworthy source of advice and knowledge among peers.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Form a peer coaching group with colleagues who share your focus on advanced collaboration.",
                            "Engage in mentorship from a senior colleague known for their advanced collaboration abilities and seek to build a trustworthy profile, effective communication, and respectful negotiation and influence.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Attend off-the-job workshops or courses focused on advanced leadership and influencing techniques.",
                            "Enrol in seminars or courses that specifically address authentic relationship building, both internally and with external stakeholders.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Communication",
            title: "Communication",
            version: "",
            description: [
                "**Definition:** Able to communicate with clarity and impact, to facilitate individual and collective understanding, action, or information exchange.",
                "**Your results indicate:** that you are applying your innate abilities and can work in a state of 'flow' - it is likely that you enjoy work and learning in this area. Consider how you can stretch yourself further and develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Perspective Taking",
                    title: "Perspective Taking",
                    description: "",
                    category: "Communication",
                    rawScore: 16,
                    componentItemCount: 4,
                    mean: 4.0,
                    percentile: 59,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Persuasion",
                    title: "Persuasion",
                    description: "",
                    category: "Communication",
                    rawScore: 19,
                    componentItemCount: 5,
                    mean: 3.8,
                    percentile: 75,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Social",
                    title: "Social",
                    description: "",
                    category: "Communication",
                    rawScore: 13,
                    componentItemCount: 4,
                    mean: 3.25,
                    percentile: 72,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 11.05,
            mean: 3.6833333333333336,
            mindsetScore: 64,
            selfAssessmentScore: 71,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Play an active role in facilitating productive interactions and removing barriers that limit input by others",
                    "Prepare compelling presentations to influence others",
                    "Coordinate the management and sharing of information and knowledge across groups",
                    "Establish mechanisms to analyse, evaluate and report information",
                    "Use a variety of techniques and methods to effectively conduct difficult conversations",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Communication mindset tend to:",
                points: [
                    "Find others and actively seek their opinion and ideas",
                    "Have a natural ability to engage with others and influence their thinking and practice",
                    "Appreciate and seek the opinions and viewpoints of others",
                    "Encourage others to present their views and feedback",
                    "Listen and seek to understand what other people mean",
                    "Craft communication to the needs of the audience",
                    "Feel confident dealing with complex issues or topics",
                    "Be an engaging public speaker",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take a lead role in designing and implementing systems that facilitate knowledge transfer among teams and departments.",
                            "Represent your organisation with authority and credibility by participating in high-profile conferences, industry events, or public speaking engagements.",
                            "Collaborate with your organisation's communication department or leaders to develop communication plans aimed at engaging both internal and external audiences.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in mentorship or coaching programs led by seasoned communication experts, particularly those skilled in executive-level communication and public representation.",
                            "Establish a peer review group to evaluate your strategic communication plans and strategies for engaging audiences.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Enrol in advanced courses or workshops that delve into communication strategy development, creating comprehensive communication plans and reinforcing organisational values through effective messaging.",
                            "Attend workshops dedicated to public speaking, authority building, credibility enhancement or mastering complex negotiations.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Creativity",
            title: "Creativity",
            version: "",
            description: [
                "**Definition:** Able to actively contribute to creative works, ideas, or novel solutions.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Imagination",
                    title: "Imagination",
                    description: "",
                    category: "Creativity",
                    rawScore: 15,
                    componentItemCount: 5,
                    mean: 3.0,
                    percentile: 53,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Inquisitive",
                    title: "Inquisitive",
                    description: "",
                    category: "Creativity",
                    rawScore: 15,
                    componentItemCount: 5,
                    mean: 3.0,
                    percentile: 60,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 6.0,
            mean: 3.0,
            mindsetScore: 48,
            selfAssessmentScore: 62,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Guide level believes they are able to: ",
                points: [
                    "Challenge existing paradigms and practices",
                    "Stimulate and sponsor creative endeavours",
                    "Take calculated risks when trialling new ideas or designs",
                    "Establish formal processes to trial, evaluate and assess new ideas or designs",
                    "Engage experts to support or stimulate creativity",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Creativity mindset tend to:",
                points: [
                    "Use imagination to see things differently",
                    "Show flexibility in thinking and approach",
                    "Be an original thinker and can think \u2018outside the box\u2019",
                    "Embrace new ideas or practices",
                    "Harness personal insights and intuition",
                    "Imagine what can be and seek to explore outside the existing or known space",
                    "Encourage others to share creative inspirations and ideas",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Lead cross-functional teams or special projects focused on challenging existing paradigms and promoting creativity.",
                            "Coordinate and cultivate creative processes to bring together diverse perspectives and ideas within a project or operational context.",
                            "Drive calculated risk-taking by trialling new ideas or designs in real projects.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in coaching sessions that help you further develop your role in stimulating and sponsoring creative endeavours.",
                            "Seek feedback from mentors or colleagues experienced in innovation management to enhance your creative leadership skills.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue advanced courses or workshops that focus on creative leadership, innovative thinking, and managing creativity within organisations.",
                            "Enrol in a program that provides in-depth knowledge and practical skills for establishing formal processes to evaluate new ideas and designs.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Critical Thinking",
            title: "Critical Thinking",
            version: "",
            description: [
                "**Definition:** Able to use a range of tools or methods to critically examine and assess existing information, thinking, assumptions and issues to present well-reasoned insights or to make judgements.",
                "**Your results indicate:** doing work that calls on this capability may not come naturally to you. You may choose to develop this capability further however are likely to find this challenging. Reflect on how this capability presents itself in your daily work.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Objectivity",
                    title: "Objectivity",
                    description: "",
                    category: "Critical Thinking",
                    rawScore: 13,
                    componentItemCount: 4,
                    mean: 3.25,
                    percentile: 79,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Reasoning",
                    title: "Reasoning",
                    description: "",
                    category: "Critical Thinking",
                    rawScore: 15,
                    componentItemCount: 6,
                    mean: 2.5,
                    percentile: 81,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 5.75,
            mean: 2.875,
            mindsetScore: 90,
            selfAssessmentScore: 85,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Use inductive or deductive reasoning to test new thinking and assumptions",
                    "Analyse and prioritise complex interests or issues",
                    "Present sound logic, data, and reasons to support professional judgments",
                    "Make decisive judgments based on experience and expertise",
                    "Modify and refine basic investigative processes, techniques, and tools, to improve insights and results",
                    "Work with others to challenge current thinking and practice",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Critical Thinking mindset tend to:",
                points: [
                    "Apply logic and reasoning to make judgments",
                    "Suggest solutions or actions that are context appropriate and achievable",
                    "Move from decisions to action",
                    "Make decisions sensitive to consequences",
                    "Like to consider all perspectives",
                    "Draw on experience to improve judgments",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take a leadership role in projects that require you to consider the broader context, analyse complex interdependencies, and make strategic judgments that have a far-reaching impact for the organisation and more broadly.",
                            "Engage in international research partnerships or interdisciplinary projects to gain insights from diverse, global perspectives.",
                            "Seek opportunities to lead initiatives that actively exchange ideas and research between international experts or institutions.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in mentorship or coaching with a seasoned expert known for making complex, strategic judgments in a decisive manner.",
                            "Join a peer group or professional network that fosters the exchange of ideas and research across international experts.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue advanced education programs, such as executive courses or specialised master's degrees, that focus on deepening your understanding of complex, strategic judgments and international collaboration.",
                            "Attend international conferences, symposiums, or forums that bring together experts from various fields and institutions.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Customer Focus",
            title: "Customer Focus",
            version: "",
            description: [
                "**Definition:** Able to focus on customer requirements and act proactively to raise the customer experience.",
                "**Your results indicate:** that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Service Orientation",
                    title: "Service Orientation",
                    description: "",
                    category: "Customer Focus",
                    rawScore: 17,
                    componentItemCount: 5,
                    mean: 3.4,
                    percentile: 98,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Solutions Orientation",
                    title: "Solutions Orientation",
                    description: "",
                    category: "Customer Focus",
                    rawScore: 19,
                    componentItemCount: 5,
                    mean: 3.8,
                    percentile: 98,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Support Orientation",
                    title: "Support Orientation",
                    description: "",
                    category: "Customer Focus",
                    rawScore: 14,
                    componentItemCount: 5,
                    mean: 2.8,
                    percentile: 95,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 10.0,
            mean: 3.3333333333333335,
            mindsetScore: 98,
            selfAssessmentScore: 70,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Manage operations to provide an exceptional customer experience",
                    "Design and deploy robust service strategy with key performance indicators for measuring the customer experience",
                    "Create a compelling value proposition",
                    "Design and develop products, services, and solutions that build enduring customer relationships",
                    "Advocate for customer needs and overcome internal obstacles to improving service and the customer experience",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Customer Focus mindset tend to:",
                points: [
                    "Have a strong and persistent focus on people/customers",
                    "Always seek to put the customer\u2019s interests first",
                    "Be genuinely interested in a customer\u2019s experience",
                    "Look for opportunities to help people",
                    "Care about others and satisfying their needs",
                    "Be friendly and easy to approach",
                    "Be personally pleased and happy when a person\u2019s needs are satisfied",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take on leadership roles where you are accountable for improving the customer experience across the organisation.",
                            "Initiate strategic actions and lead organisational transformative efforts to improve the customer journey and experience.",
                            "Spend time coaching or mentoring emerging leaders on appropriate ways of monitoring and undertaking anticipatory action to enhance the customer experience.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in coaching with seasoned customer experience leader to help you explore how to think strategically, initiate actions to improve the customer experience, and design systems for enduring customer value.",
                            "Act as a mentor to aspiring leaders within your organisation, guiding them on how to hold others accountable for improving the customer experience and cultivating a culture that reinforces customer-centric values.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue advanced courses or executive education programs focused on customer experience leadership and strategy.",
                            "Collaborate with experts or academic institutions to participate in customer-centric research projects or case studies, to gain a comprehensive understanding of customer research, fostering innovation and in-depth knowledge of customer experience management.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Direction & Purpose",
            title: "Direction and Purpose",
            version: "",
            description: [
                "**Definition:** Able to plan and establish a compelling future vision and sense of shared purpose that inspires and influences commitment to collective action.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Accountable",
                    title: "Accountable",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 18,
                    componentItemCount: 5,
                    mean: 3.6,
                    percentile: 45,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Drive",
                    title: "Drive",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 12,
                    componentItemCount: 4,
                    mean: 3.0,
                    percentile: 68,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Execution",
                    title: "Execution",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 15,
                    componentItemCount: 4,
                    mean: 3.75,
                    percentile: 66,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Purposeful",
                    title: "Purposeful",
                    description: "",
                    category: "Direction and Purpose",
                    rawScore: 12,
                    componentItemCount: 4,
                    mean: 3.0,
                    percentile: 34,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 13.35,
            mean: 3.3375,
            mindsetScore: 42,
            selfAssessmentScore: 50,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Guide level believes they are able to: ",
                points: [
                    "Develop strategic reporting and evaluation methodologies",
                    "Manage strategy and report on strategy execution and progress",
                    "Display a deep appreciation for the strategic opportunities and challenges facing the organisation",
                    "Convey the organisational direction and values positively to high-level decision-makers (e.g., Board, shareholders, government)",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Direction & Purpose mindset tend to:",
                points: [
                    "Have a good capacity to research and source quality strategic business intelligence",
                    "Be respected by peers and decision makers in other organisations as a conscientiousness and hard-working leader",
                    "Take a thorough approach to tasks even when faced with multiple competing demands",
                    "Be able to renegotiate outcomes or requirements that impinge upon quality/standard of the final result",
                    "Persevere when others quit",
                    "Be a strategic thinker",
                    "Secure executive level sponsorships and engagement",
                    "Redesign business practices/processes to deliver improved results",
                    "Have a strong work ethic and responsiveness to new demands",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Lead and manage strategic projects focused on the organisation's vision and purpose, focussing on conveying the organisational direction and values to executive level stakeholders.",
                            "Actively engage with key stakeholders, demonstrating a deep appreciation of the strategic opportunities and challenges.",
                            "Collaborate with other organisations or industry experts to explore innovative ways to promote a shared vision.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage with mentors who are seasoned strategic leaders and seek guidance on strategic reporting and evaluation methodologies and conveying the organisation's direction and values to high-level decision makers.",
                            "Participate in feedback sessions focused on your strategic communication and how you engage with high-level decision makers.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Participate in advanced strategic leadership courses to refine your skills in managing strategy, strategic reporting, and communicating direction and values to high-level decision makers.",
                            "Enrol in workshops on strategic thinking and evaluation methodologies.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Empathy",
            title: "Empathy",
            version: "",
            description: [
                "**Definition:** Able to recognise and regulate their own emotions in any situation and is good at identifying and respecting the needs and feelings of other people.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Care",
                    title: "Care",
                    description: "",
                    category: "Empathy",
                    rawScore: 19,
                    componentItemCount: 5,
                    mean: 3.8,
                    percentile: 43,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Sensitivity To Others",
                    title: "Sensitivity To Others",
                    description: "",
                    category: "Empathy",
                    rawScore: 21,
                    componentItemCount: 6,
                    mean: 3.5,
                    percentile: 61,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 7.3,
            mean: 3.65,
            mindsetScore: 45,
            selfAssessmentScore: 50,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Guide level believes they are able to: ",
                points: [
                    "Demonstrate commitment, sensitivity and responsiveness to the emotional needs of different cultures and people",
                    "Research and work with others to identify and understand the emotional dimensions when designing or providing products and services",
                    "Model and reinforce the organisation\u2019s culture and values",
                    "Identify opportunities for engaging positively with different groups in the community",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Empathy mindset tend to:",
                points: [
                    "Be sensitive to the needs of others and know how to comfort them",
                    "Respond appropriately in emotionally charged or difficult situations",
                    "Seek to understand how they personally respond to people and situations",
                    "Be aware of own emotional responses and feelings",
                    "Have a strong sense of how conflict may accentuate certain emotions and behaviours",
                    "Be non-judgmental and respectful",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Demonstrate commitment, sensitivity, and responsiveness to the emotional needs of people from different cultures and backgrounds, actively seeking to understand their perspectives.",
                            "Collaborate with others to research and identify the emotional dimensions that influence product and service design to better meet the needs of diverse customers.",
                            "Model and reinforce your organisation's culture and values, consistently reflecting them in your actions and interactions.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek mentorship from leaders known for their strong cultural sensitivity and empathy to develop advanced emotional intelligence.",
                            "Establish a mentorship program in your organisation to help emerging leaders understand and navigate diverse emotional dimensions in the workplace.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue advanced courses in emotional intelligence and cultural sensitivity.",
                            "Attend workshops on customer and user experience design with a focus on understanding and addressing emotional needs from diverse groups.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Engagement & Coaching",
            title: "Engagement and Coaching",
            version: "",
            description: [
                "**Definition:** Able to work in teams and reinforce a culture of engagement underpinning the shared purpose and values. This includes teamwork, coaching others, promoting inclusion, building trust and empowering others to act.",
                "**Your results indicate:** doing work that calls on this capability may not come naturally to you. You may choose to develop this capability further however are likely to find this challenging. Reflect on how this capability presents itself in your daily work.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Empowerment",
                    title: "Empowerment",
                    description: "",
                    category: "Engagement and Coaching",
                    rawScore: 13,
                    componentItemCount: 4,
                    mean: 3.25,
                    percentile: 87,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Inclusive",
                    title: "Inclusive",
                    description: "",
                    category: "Engagement and Coaching",
                    rawScore: 18,
                    componentItemCount: 6,
                    mean: 3.0,
                    percentile: 89,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 6.25,
            mean: 3.125,
            mindsetScore: 88,
            selfAssessmentScore: 71,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Establish systems and mechanisms to promote workforce participation",
                    "Vest the authority and resources necessary for employees to be empowered to act",
                    "Acknowledge and reward contributions from others",
                    "Mentor and develop others",
                    "Facilitate individual development planning and prioritisation",
                    "Engage with community and professional groups to enhance understanding of diverse cultural and social perspectives",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Engagement & Coaching mindset tend to:",
                points: [
                    "Employ a range of different influencing styles effectively",
                    "Have strong negotiation and influencing skills",
                    "Embrace learning",
                    "Have a strong ability to empathise",
                    "Be an effective role model",
                    "Have a strong ability to appreciate and communicate sensitive to emotional and cultural factors",
                    "Appreciate and celebrates everyone\u2019s contribution",
                    "Be an astute navigator of organisational politics",
                    "Be willing to coach and mentor others",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take the lead in addressing complex issues that affect high performance, demonstrating your ability to navigate challenging situations effectively.",
                            "Actively recruit, assemble and promote teams with diverse skillsets and backgrounds.",
                            "Engage directly with these groups to ensure all barriers are removed by establishing practices and processes to enable genuine belonging.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor and provide expert guidance to emerging leaders and team members, sharing your knowledge and experience to support their growth.",
                            "Implement 360-degree feedback processes throughout your organisation to ensure ongoing self-awareness and development.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue advanced leadership programs and certifications that focus on advanced coaching, mentoring, and team leadership. Ensure allyship is included.",
                            "Develop custom training programs for your organisation that set fulfilling development goals, fostering team capability and individual talent.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Ethics",
            title: "Ethics",
            version: "",
            description: [
                "**Definition:** Able to act with integrity and in conformance with social and professional standards of ethical conduct.",
                "**Your results indicate:** doing work that calls on this capability may not come naturally to you. You may choose to develop this capability further however are likely to find this challenging. Reflect on how this capability presents itself in your daily work.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Integrity",
                    title: "Integrity",
                    description: "",
                    category: "Ethics",
                    rawScore: 13,
                    componentItemCount: 5,
                    mean: 2.6,
                    percentile: 94,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Morality",
                    title: "Morality",
                    description: "",
                    category: "Ethics",
                    rawScore: 10,
                    componentItemCount: 5,
                    mean: 2.0,
                    percentile: 96,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Responsibility",
                    title: "Responsibility",
                    description: "",
                    category: "Ethics",
                    rawScore: 23,
                    componentItemCount: 6,
                    mean: 3.8333333333333335,
                    percentile: 95,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 8.433333333333334,
            mean: 2.8111111111111113,
            mindsetScore: 95,
            selfAssessmentScore: 70,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Understand the relationship between ethics, morals and beliefs",
                    "Apply in-depth knowledge of current regulatory requirements to develop and maintain up-to-date compliance policies and procedures",
                    "Model uncompromising integrity and commitment to professional and ethical standards",
                    "Investigate and resolve compliance issues",
                    "Assist in forming and communicating a Code of Conduct to guide the behaviour of others",
                    "Continually monitor business/operational compliance",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Ethics mindset tend to:",
                points: [
                    "Act according to their own ethics and conscience",
                    "Make commitments and stick to them",
                    "Be willing to follow behavioural rules and social expectations",
                    "Follow the rules and agreed standards of practice or behaviour",
                    "Seek to learn from mistakes of others and how they deal with ethical dilemmas",
                    "Conscientiously adhere to personal commitments or loyalty",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Take a lead role in promoting effective organisational governance and compliance management systems, actively identifying and addressing gaps and areas for improvement.",
                            "Seek out and respond to external reviews of organisational governance and compliance management audits, ensuring full compliance with external standards and regulations.",
                            "Promote compatible ethical practices and professional standards across the industry and with strategic partners, collaborating with other organisations to set industry-wide ethical benchmarks.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor emerging leaders and compliance professionals on advanced governance and compliance strategies and best practices.",
                            "Establish a mentoring program within your organisation to help employees develop their ethical leadership skills and maintain industry-leading ethical practices.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue postgraduate studies or executive education programs in governance, ethics, and compliance.",
                            "Attend advanced seminars on industry-wide ethics and professional standards.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Leadership",
            title: "Leadership",
            version: "",
            description: [
                "**Definition:** Able to lead people and processes, motivate others and build an environment of mutual trust and commitment to influence the outcomes required now and into the future.",
                "**Your results indicate:** that you are applying your innate abilities and can work in a state of 'flow' - it is likely that you enjoy work and learning in this area. Consider how you can stretch yourself further and develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Determination",
                    title: "Determination",
                    description: "",
                    category: "Leadership",
                    rawScore: 15,
                    componentItemCount: 4,
                    mean: 3.75,
                    percentile: 64,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Influential",
                    title: "Influential",
                    description: "",
                    category: "Leadership",
                    rawScore: 18,
                    componentItemCount: 5,
                    mean: 3.6,
                    percentile: 10,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Leadership",
                    title: "Leadership",
                    description: "",
                    category: "Leadership",
                    rawScore: 20,
                    componentItemCount: 5,
                    mean: 4.0,
                    percentile: 87,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 11.35,
            mean: 3.783333333333333,
            mindsetScore: 52,
            selfAssessmentScore: 70,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Build a climate that encourages individuals to share ideas and question existing practices",
                    "Set a clear vision and sense of shared direction",
                    "Understand what motivates others to learn and share knowledge",
                    "Create an open, committed and trusting work environment",
                    "Identify and foster individual growth at all levels",
                    "Consistently set appropriate behaviour for themselves and their team",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Leadership mindset tend to:",
                points: [
                    "Show the ability to look over the horizon and identify compelling futures",
                    "Be highly self-aware",
                    "Have a strong career plan",
                    "Appreciate trends or major factors shaping technology, markets and strategic direction",
                    "Have a strong ability to inspire others",
                    "Show a high level of motivation towards achieving future goals",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Motivate and inspire others through a shared vision that has deep meaning and resonates with the organisation's mission and values.",
                            "Harness the full capability of your team to deliver on strategic goals and objectives, optimising their talents and resources.",
                            "Lead with a focus on long-term value creation and shareholder growth, prioritising sustainability over short-term gains.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor emerging leaders and senior executives on advanced leadership strategies for shaping strategy, driving growth, and building a shared vision.",
                            "Establish a peer coaching network of senior leaders to exchange insights and experiences in driving long-term value and sustainable growth.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue advanced executive education programs in strategic leadership, innovation, and sustainable growth.",
                            "Attend industry-specific conferences and seminars on leading for long-term value creation and shareholder growth.",
                        ],
                    },
                ],
            },
        },
        {
            name: "Problem Solving & Data",
            title: "Problem Solving and Data",
            version: "",
            description: [
                "**Definition:** Able to use data to define and analyse problems, generate optimal alternatives, and provide recommendations.",
                "**Your results indicate:** you can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level.",
            ],
            longerDescription: [],
            icon: "",
            subCategories: [
                {
                    name: "Analysis",
                    title: "Analysis",
                    description: "",
                    category: "Problem Solving and Data",
                    rawScore: 20,
                    componentItemCount: 5,
                    mean: 4.0,
                    percentile: 88,
                    band_description: null,
                    strategies: null,
                },
                {
                    name: "Approach",
                    title: "Approach",
                    description: "",
                    category: "Problem Solving and Data",
                    rawScore: 16,
                    componentItemCount: 5,
                    mean: 3.2,
                    percentile: 40,
                    band_description: null,
                    strategies: null,
                },
            ],
            rawScore: 7.2,
            mean: 3.6,
            mindsetScore: 67,
            selfAssessmentScore: 79,
            currentCapability: {
                title: "Current capability",
                description:
                    "A person who has self-assessed their current capability at the Execute & Improve level believes they are able to: ",
                points: [
                    "Anticipate potential problems and obstacles and think ahead to next steps",
                    "Identify causal factors including steps that led up to the situation or patterns and similarities between different situations",
                    "Monitor data to anticipate and resolve problems before they adversely impact operations",
                    "Integrate a large amount of information from diverse sources, recommending solutions that span locations, disciplines or problems",
                    "Identify and remove root causes to problems",
                    "Monitor and evaluate implemented solutions",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Problem Solving & Data mindset tend to:",
                points: [
                    "Be able to focus on essential elements of a problem",
                    "Love to investigate and resolve problems",
                    "Evaluate and understand complex interrelationships impacting a problem",
                    "Reason logically and formulate clear options",
                    "Use data to objectively and scientifically judge and select a solution",
                    "Make linkages between seemingly unrelated but important issues/information",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "70% - Work-based learning or Experience:",
                        points: [
                            "Lead an organisational wide initiative, which requires the collection, visualisation and analysis of a wide range of data points to help guide a commercial decision.",
                            "Make judgements in a decisive manner, considering the longer-term impact across the whole system, including shareholders, ESG and commercial aspects.",
                            "Examine critical strategic planning or business intelligence data to predict or anticipate future problems and emerging risks and communicate your findings with senior stakeholders.",
                        ],
                    },
                    {
                        description: "20% - Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in peer coaching with other senior leaders to share insights into strategic decision-making and long-term planning.",
                            "Mentor emerging leaders on using data for strategic thinking and decision-making in complex situations.",
                        ],
                    },
                    {
                        description: "10% - Education and Training \u2013 off the job:",
                        points: [
                            "Pursue executive education programs that focus on strategic leadership and decision-making in data-intensive environments.",
                            "Attend industry-specific conferences and seminars exploring trends in data-driven decision-making and its impact on organisations and industries.",
                        ],
                    },
                ],
            },
        },
    ],
};
