import { useCallback, useContext, useEffect, useState } from "react";
import { ArrowPathIcon, ArrowsUpDownIcon, BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";

import { Context } from "../../../../context/Context";
import {
    ACE_2_SURVEY_PLATFORM,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    SORT_TYPES,
} from "../../../../utils/constants";
import { getAssessmentsForUser } from "../../../../utils/api";
import { sortAlphabetically } from "../../../../utils/sorting";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import TabbedNavigationLayout from "../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import AssessmentCardSearch from "./AssessmentCardSearch";
import BouncingBalls from "../../../SharedComponents/Loading/BouncingBalls";
import FatDropdown from "../../../SharedComponents/FatDropdown";
import FancyRadio from "../../../SharedComponents/Form/FancyRadio";
import Button from "../../../SharedComponents/Button";

export default function Assessments() {
    const [assessments, setAssessments] = useState([]);
    const [availableAssessments, setAvailableAssessments] = useState([]);
    const [completedAssessments, setCompletedAssessments] = useState([]);
    const [expiredAssessments, setExpiredAssessments] = useState([]);
    const [assessmentsLoading, setAssessmentsLoading] = useState(true);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [sortByTypeAvailable, setSortByTypeAvailable] = useState("mostRecent");
    const [sortByTypeCompleted, setSortByTypeCompleted] = useState("mostRecent");
    const [sortByTypeExpired, setSortByTypeExpired] = useState("mostRecent");

    const context = useContext(Context);

    const isAce2SurveyPlatform = context.defaultApp === ACE_2_SURVEY_PLATFORM;

    const [tabIndex, setTabIndex] = useState(0);

    const tabList = ["Available", "Completed", "Expired"];

    const fetchAssessments = useCallback(async () => {
        setAssessmentsLoading(true);

        try {
            const userAssessmentsResponse = await getAssessmentsForUser();
            setAssessments(userAssessmentsResponse);

            const availableAssessments = userAssessmentsResponse.filter(
                (assessment) =>
                    assessment.isCompleted === false &&
                    Date.now() < new Date(assessment.dueDate === null ? Date.now() + 1 : assessment.dueDate)
            );

            // Initial sort
            sortByRecent(availableAssessments, setAvailableAssessments, "lastModifiedAt", true);

            const completedAssessments = userAssessmentsResponse.filter(
                (assessment) => assessment.isCompleted === true
            );

            // Initial sort
            sortByRecent(completedAssessments, setCompletedAssessments, "lastModifiedAt", true);

            const expiredAssessments = userAssessmentsResponse.filter(
                (assessment) =>
                    assessment.isCompleted === false &&
                    Date.now() >= new Date(assessment.dueDate === null ? Date.now() + 1 : assessment.dueDate)
            );

            // Initial sort
            sortByRecent(expiredAssessments, setExpiredAssessments, "dueDate", true);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setAssessmentsLoading(false);

        // Reason for ignoring dependencies: Will only run on load and then when fetchAssessments() is called with
        // "Refresh Assessments" button. This function does not need to run again if availableSort and expiredSort
        // dependencies have changed as we handle interactive sorting with the sortAssessments() function.
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchAssessments(true);
    }, [fetchAssessments]);

    const sortByRecent = (list, setList, dateType, mostRecent) => {
        if (dateType === "dueDate" || dateType === "lastModifiedAt") {
            let recentSortedAssessments = [];

            if (mostRecent) {
                recentSortedAssessments = [...list].sort((a, b) => new Date(b[dateType]) - new Date(a[dateType]));
            } else {
                recentSortedAssessments = [...list].sort((a, b) => new Date(a[dateType]) - new Date(b[dateType]));
            }
            setList(recentSortedAssessments);
        }
    };

    function handleSortByType(sortByType) {
        let assessments =
            tabIndex === 0 ? availableAssessments : tabIndex === 1 ? completedAssessments : expiredAssessments;
        let setAssessments =
            tabIndex === 0 ? setAvailableAssessments : tabIndex === 1 ? setCompletedAssessments : setExpiredAssessments;

        let sortedAssessments = [];

        switch (sortByType) {
            case "mostRecent":
                sortByRecent(assessments, setAssessments, "lastModifiedAt", true);
                break;
            case "leastRecent":
                sortByRecent(assessments, setAssessments, "lastModifiedAt", false);
                break;
            case "assessmentType":
                sortedAssessments = sortAlphabetically(assessments, true, true, "type");
                setAssessments(sortedAssessments);
                break;
            case "alphabeticallyAsc":
                sortedAssessments = sortAlphabetically(assessments, true, false, "name");

                setAssessments(sortedAssessments);
                break;
            case "alphabeticallyDesc":
                sortedAssessments = sortAlphabetically(assessments, false, false, "name");
                setAssessments(sortedAssessments);
                break;
            default:
                break;
        }
    }

    const Sorts = ({ assessmentType }) => {
        return (
            <div
                className={
                    "flex flex-col text-sm font-title text-primary-800 justify-start print:hidden " +
                    "bg-white shadow-xl rounded-xl border-slate-100 border overflow-hidden"
                }
            >
                <FancyRadio
                    testId={"most-recent-toggle-test-id"}
                    labelText={"Most recent"}
                    value={"mostRecent"}
                    name={"sortBy"}
                    colour={"secondary"}
                    setValue={
                        assessmentType === "available"
                            ? () => setSortByTypeAvailable("mostRecent")
                            : assessmentType === "completed"
                            ? () => setSortByTypeCompleted("mostRecent")
                            : () => setSortByTypeExpired("mostRecent")
                    }
                    minimal
                    defaultChecked={
                        "mostRecent" ===
                        (assessmentType === "available"
                            ? sortByTypeAvailable
                            : assessmentType === "completed"
                            ? sortByTypeCompleted
                            : sortByTypeExpired)
                    }
                    handleSort={handleSortByType}
                />
                <FancyRadio
                    testId={"most-recent-toggle-test-id"}
                    labelText={"Least recent"}
                    value={"leastRecent"}
                    name={"sortBy"}
                    colour={"secondary"}
                    setValue={
                        assessmentType === "available"
                            ? () => setSortByTypeAvailable("leastRecent")
                            : assessmentType === "completed"
                            ? () => setSortByTypeCompleted("leastRecent")
                            : () => setSortByTypeExpired("leastRecent")
                    }
                    minimal
                    defaultChecked={
                        "leastRecent" ===
                        (assessmentType === "available"
                            ? sortByTypeAvailable
                            : assessmentType === "completed"
                            ? sortByTypeCompleted
                            : sortByTypeExpired)
                    }
                    handleSort={handleSortByType}
                />
                <FancyRadio
                    testId={"alphabetically-toggle-test-id"}
                    labelText={
                        <div className="flex flex-row gap-2 items-center">
                            <span className="font-mono font-medium">A-Z</span>
                            <BarsArrowUpIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />
                        </div>
                    }
                    value={"alphabeticallyAsc"}
                    name={"sortBy"}
                    colour={"secondary"}
                    setValue={
                        assessmentType === "available"
                            ? () => setSortByTypeAvailable("alphabeticallyAsc")
                            : assessmentType === "completed"
                            ? () => setSortByTypeCompleted("alphabeticallyAsc")
                            : () => setSortByTypeExpired("alphabeticallyAsc")
                    }
                    minimal
                    defaultChecked={
                        "alphabeticallyAsc" ===
                        (assessmentType === "available"
                            ? sortByTypeAvailable
                            : assessmentType === "completed"
                            ? sortByTypeCompleted
                            : sortByTypeExpired)
                    }
                    handleSort={handleSortByType}
                />
                <FancyRadio
                    testId={"alphabetically-toggle-test-id"}
                    labelText={
                        <div className="flex flex-row gap-2 items-center">
                            <span className="font-mono font-medium">Z-A</span>
                            <BarsArrowDownIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />
                        </div>
                    }
                    value={"alphabeticallyDesc"}
                    name={"sortBy"}
                    colour={"secondary"}
                    setValue={
                        assessmentType === "available"
                            ? () => setSortByTypeAvailable("alphabeticallyDesc")
                            : assessmentType === "completed"
                            ? () => setSortByTypeCompleted("alphabeticallyDesc")
                            : () => setSortByTypeExpired("alphabeticallyDesc")
                    }
                    minimal
                    defaultChecked={
                        "alphabeticallyDesc" ===
                        (assessmentType === "available"
                            ? sortByTypeAvailable
                            : assessmentType === "completed"
                            ? sortByTypeCompleted
                            : sortByTypeExpired)
                    }
                    handleSort={handleSortByType}
                    noBorder
                />
                <FancyRadio
                    testId={"assessment-type-toggle-test-id"}
                    labelText={"Assessment type"}
                    value={"assessmentType"}
                    name={"sortBy"}
                    colour={"secondary"}
                    setValue={
                        assessmentType === "available"
                            ? () => setSortByTypeAvailable("assessmentType")
                            : assessmentType === "completed"
                            ? () => setSortByTypeCompleted("assessmentType")
                            : () => setSortByTypeExpired("assessmentType")
                    }
                    minimal
                    defaultChecked={
                        "assessmentType" ===
                        (assessmentType === "available"
                            ? sortByTypeAvailable
                            : assessmentType === "completed"
                            ? sortByTypeCompleted
                            : sortByTypeExpired)
                    }
                    handleSort={handleSortByType}
                />
            </div>
        );
    };

    return (
        <DashboardLayout
            title={isAce2SurveyPlatform ? "Surveys" : "Assessments"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENTS]}
            otherElements={
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    text={isAce2SurveyPlatform ? "Refresh surveys" : "Refresh assessments"}
                    icon={<ArrowPathIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                    onClick={() => fetchAssessments()}
                    iconPositionLeft
                    loading={assessmentsLoading}
                />
            }
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            {assessmentsLoading ? (
                <div className={"col-span-full flex items-center justify-center pt-20 pb-24 w-full"}>
                    <BouncingBalls />
                </div>
            ) : assessments.length > 0 ? (
                <>
                    <Alert
                        text={`
                            Please note that it might take a few minutes for a newly assigned ${
                                isAce2SurveyPlatform ? "surveys" : "assessments"
                            } to appear.
                            You may use the button above to refresh this list.`}
                        type={ALERT_TYPE.INFO}
                        active={true}
                    />
                    <TabbedNavigationLayout
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabList={tabList}
                        backgroundColour={"bg-slate-100"}
                        loading={false}
                        tabsRowOnly
                        noContainer
                        tabContent={[
                            {
                                title: tabList[0],
                                headerElements: !isAce2SurveyPlatform && (
                                    <FatDropdown
                                        text={SORT_TYPES[sortByTypeAvailable]}
                                        position="top-11 right-0"
                                        icon={
                                            <ArrowsUpDownIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />
                                        }
                                        backgroundColour="bg-white"
                                        buttonVariant={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                                        content={<Sorts assessmentType="available" />}
                                    />
                                ),
                                content: <AssessmentCardSearch assessments={availableAssessments} />,
                                readonly: true,
                            },
                            {
                                title: tabList[1],
                                headerElements: !isAce2SurveyPlatform && (
                                    <FatDropdown
                                        text={SORT_TYPES[sortByTypeCompleted]}
                                        position="top-11 right-0"
                                        icon={
                                            <ArrowsUpDownIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />
                                        }
                                        backgroundColour="bg-white"
                                        buttonVariant={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                                        content={<Sorts assessmentType="completed" />}
                                    />
                                ),
                                content: <AssessmentCardSearch assessments={completedAssessments} />,
                                readonly: true,
                            },
                            {
                                title: tabList[2],
                                headerElements: !isAce2SurveyPlatform && (
                                    <FatDropdown
                                        text={SORT_TYPES[sortByTypeExpired]}
                                        position="top-11 right-0"
                                        icon={
                                            <ArrowsUpDownIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />
                                        }
                                        backgroundColour="bg-white"
                                        buttonVariant={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                                        content={<Sorts assessmentType="expired" />}
                                    />
                                ),
                                content: <AssessmentCardSearch assessments={expiredAssessments} />,
                                readonly: true,
                            },
                        ]}
                        secondaryTabs
                    />
                </>
            ) : (
                assessments.length === 0 && (
                    <Alert
                        text={`You haven't been assigned any ${
                            isAce2SurveyPlatform ? "surveys" : "assessments"
                        }. If you think this is incorrect, 
                        please get in touch with your relevant organisational contact to help set this up.`}
                        type={ALERT_TYPE.WARNING}
                        active={true}
                    />
                )
            )}
            <Alert
                type={ALERT_TYPE.WARNING}
                active={context.userAssessments == null}
                text={
                    <div className="text-sm">
                        Oops! It looks like we are having some trouble retrieving your{" "}
                        {isAce2SurveyPlatform ? "surveys" : "assessments"}. We are working on it. If you require
                        immediate assistance, please contact us at{" "}
                        <a
                            className="link text-primary-600 hover:text-primary-700"
                            href="mailto:techsupport@psychpress.com.au?subject=The assessments server is down."
                        >
                            techsupport@psychpress.com.au
                        </a>{" "}
                        or call us at{" "}
                        <a className="link text-primary-600 hover:text-primary-700" href="tel:+61396700590">
                            +61 (0)3 9670 0590
                        </a>
                    </div>
                }
            ></Alert>
        </DashboardLayout>
    );
}
