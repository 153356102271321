import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { getOrganisationWelcome, updateShowWelcome } from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";

import logo from "../../../../assets/customer-portal-logo.png";
import coachPlatformLogo from "../../../../assets/coach-platform-logo.svg";

import Button from "../../../SharedComponents/Button";
import PageSlide from "../CoachPlatform/PageSlide";
import Slider from "../CoachPlatform/Slider";

export default function OrganisationWelcomePage() {
    const navigate = useNavigate();

    const [welcomePages, setWelcomePages] = useState([]);

    const proceedToCoachPlatform = useCallback(async () => {
        await updateShowWelcome(false, null);
        navigate("/");
    }, [navigate]);

    const fetchOrganisationWelcomePages = useCallback(async () => {
        const welcomePagesResponse = await getOrganisationWelcome();
        if (welcomePagesResponse.length === 0) {
            proceedToCoachPlatform();
        } else {
            setWelcomePages(welcomePagesResponse);
        }
    }, [proceedToCoachPlatform]);

    useEffect(() => {
        fetchOrganisationWelcomePages();
    }, [fetchOrganisationWelcomePages]);

    return (
        <div className={"bg-gradient-to-r from-secondary-50 via-blue-100 to-teal-50 z-50 shadow-sm h-screen"}>
            <div className="w-40 absolute top-8 left-8">
                <img src={coachPlatformLogo} alt="Psych Press logo short version" />
            </div>
            <div className={"max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:py-20 lg:px-8 lg:flex gap-24"}>
                <div className="w-full mt-8 lg:mt-0">
                    <Slider
                        fullScreen
                        slides={welcomePages.map((page, index) => (
                            <PageSlide
                                fullScreen
                                description={
                                    <div>
                                        <div
                                            className={
                                                "max-w-4xl mx-auto py-10 px-4 sm:px-6 lg:py-20 lg:px-8 lg:flex " +
                                                "gap-24"
                                            }
                                        >
                                            <div>
                                                <div className={"flex flex-col gap-4"}>
                                                    <div className="w-40">
                                                        <img src={logo} alt="Psych Press logo short version" />
                                                    </div>
                                                </div>
                                                <h2 className="flex-none text-2xl font-medium tracking-tight mt-6 ">
                                                    <span className="block text-slate-500">{page.title}</span>
                                                </h2>
                                                <p className={"mt-3 text-slate-500"}>{page.text}</p>

                                                {index === welcomePages.length - 1 && (
                                                    <div className={"text-right mt-6"}>
                                                        <Button
                                                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                                            text={"Proceed to Coach Platform"}
                                                            icon={<ChevronRightIcon className="block h-4 w-4  ml-2" />}
                                                            onClick={proceedToCoachPlatform}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    />
                </div>
            </div>
        </div>
    );
}
