import MultiSelect from "../../../../SharedComponents/Form/MultiSelect";

export default function CareerToDate() {
    return (
        <div className="grid grid-flow-row gap-6">
            <MultiSelect isMulti labelText={"Breadth of experience"} />
            <MultiSelect isMulti labelText={"Industry sector experience"} />
            <MultiSelect isMulti labelText={"Career movements"} />
        </div>
    );
}
