import { UsersIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Context } from "../../../../../context/Context";

import { BUTTON_VARIANT_TYPES, USER_GROUPS } from "../../../../../utils/constants";
import Button from "../../../../SharedComponents/Button";

import AddRemoveButton from "../../../../SharedComponents/Form/AddRemoveButton";
import BasicTransitionHeadlessUi from "../../../../SharedComponents/Loading/Transitions/BasicTransitionHeadlessUi";

export default function CohortUserRow(props) {
    const {
        item: user,
        itemRowToggleOnChange,
        noPreviousAccessUser,
        accessDefaultToggleState,
        latestItemInteracted: latestUserInteracted,
        updateItemStatus,
    } = props;

    const context = useContext(Context);

    const [showTransition, setShowTransition] = useState(true);

    const navigate = useNavigate();

    let isCoachee;

    if (user.userGroups) {
        isCoachee = user.userGroups.includes(USER_GROUPS.COACHEE);
    }

    useEffect(() => {
        if (latestUserInteracted) {
            if (user.userIdentifier === latestUserInteracted.userIdentifier) {
                setShowTransition(false);
                setTimeout(() => {
                    setShowTransition(true);
                }, 200);
            }
        }
    }, [latestUserInteracted, user]);

    return (
        <tr>
            <td className="px-4 py-3">
                <BasicTransitionHeadlessUi show={showTransition}>
                    <div
                        className={isCoachee ? "cursor-pointer" : ""}
                        onClick={() => {
                            if (isCoachee)
                                navigate(`/${context.terminology.coachee.singular.toLowerCase()}-profile`, {
                                    state: { userIdentifier: user.userIdentifier },
                                });
                        }}
                    >
                        <div
                            className={
                                "text-sm font-medium text-slate-600 " + (isCoachee && "hover:text-secondary-500")
                            }
                        >
                            {user.firstName + " " + user.lastName}
                        </div>
                        <div className={"text-sm text-slate-500 mt-1 " + (isCoachee && "hover:text-secondary-500")}>
                            {user.emailAddress}
                        </div>
                    </div>
                </BasicTransitionHeadlessUi>
            </td>

            {updateItemStatus &&
                (user?.coachIdentifier ? (
                    <td className="px-4 py-3">
                        <BasicTransitionHeadlessUi show={showTransition}>
                            <select
                                id="default"
                                name="default"
                                className={
                                    "bg-slate-100 rounded-xl shadow-sm pl-2 pr-10 py-1 text-left " +
                                    "focus:outline-none focus:ring-2 focus:ring-slate-100 focus:border-transparent " +
                                    "sm:text-sm text-slate-500 border border-slate-100"
                                }
                                onChange={(e) => updateItemStatus(e.target.value, user.userIdentifier)}
                                defaultValue={user.statusName ? user.statusName : ""}
                            >
                                <option value={""}>
                                    {"Select " + context.terminology.coachee.singular + " status"}
                                </option>
                                <option value={"Started"}>Started</option>
                                <option value={"Not Started"}>Not started</option>
                                <option value={"Completed"}>Completed</option>
                            </select>
                        </BasicTransitionHeadlessUi>
                    </td>
                ) : (
                    <td className="px-4 py-3">
                        <BasicTransitionHeadlessUi show={showTransition}>
                            <Button
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                text={`Assign ${context.terminology.coach.singular.toLowerCase()}`}
                                icon={<UsersIcon className="block h-3.5 w-3.5 mr-2" />}
                                extraStyling={"text-xs text-white"}
                                iconPositionLeft
                                onClick={() =>
                                    navigate(`/assign-${context.terminology.coach.singular.toLowerCase()}`, {
                                        state: {
                                            coacheeUserIdentifier: user.userIdentifier,
                                            coacheeFirstName: user.firstName,
                                            coacheeLastName: user.lastName,
                                            coacheeEmailAddress: user.emailAddress,
                                        },
                                    })
                                }
                                smallButton
                            />
                        </BasicTransitionHeadlessUi>
                    </td>
                ))}

            {!updateItemStatus && (
                <td className="px-4 py-3 w-48">
                    <BasicTransitionHeadlessUi show={showTransition}>
                        <div className="flex space-between">
                            <AddRemoveButton
                                identifier={user.userIdentifier}
                                name={"toggle-" + user.emailAddress}
                                setValue={itemRowToggleOnChange}
                                noPreviousAccessUser={noPreviousAccessUser}
                                accessDefaultToggleState={accessDefaultToggleState}
                            />
                        </div>
                    </BasicTransitionHeadlessUi>
                </td>
            )}
        </tr>
    );
}
