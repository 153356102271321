import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowRightIcon, ChevronRightIcon, InformationCircleIcon, UserIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, HEADFIRST_ILLUSTRATIONS } from "../../../../../../utils/constants";

import Button from "../../../../../SharedComponents/Button";

export default function DetailedSummaryCard(props) {
    const { scale, coacheeName } = props;

    const [position, setPosition] = useState(0);
    const [showSubScales, setShowSubScales] = useState(false);

    // low = 0 - 34, mid = 35 - 73, high = 74 - 100
    const min = 34.1;
    const max = 100;
    const midUpper = 73.9;

    // Formula to normalize data between 0-100
    const getBarWidth = (value) => {
        return ((value - min) / (max - min)) * 100;
    };

    useEffect(() => {
        setTimeout(() => {
            setPosition(Math.round(getBarWidth(scale.percentage)));
        }, 100);
    }, [scale.percentage]);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleShowSubScales = () => {
        setShowSubScales((showRecordings) => !showRecordings);
    };

    return (
        <div className={"bg-slate-50 shadow-sm p-4 rounded-xl"}>
            <div className="flex flex-col space-y-3">
                <div className="flex flex-col gap-4">
                    <div
                        className={
                            "flex flex-row justify-between items-center w-full " +
                            (scale.percentage <= 34
                                ? "text-green-500"
                                : scale.percentage > 34 && scale.percentage < 74
                                ? "text-amber-500"
                                : "text-red-500")
                        }
                    >
                        <div className="flex flex-row items-center gap-2">
                            <img
                                alt="scale illustration"
                                src={HEADFIRST_ILLUSTRATIONS[scale.name.toUpperCase()]}
                                className="w-6"
                            />
                            <p className={"text-sm font-medium"}>{scale.name}</p>
                        </div>
                        <div
                            className={
                                "px-2 py-1 rounded-full leading-3 " +
                                (scale.percentage <= 34
                                    ? "bg-green-500"
                                    : scale.percentage > 34 && scale.percentage < 74
                                    ? "bg-amber-500"
                                    : "bg-red-500")
                            }
                        >
                            <span className="text-white text-xs font-medium ">
                                {scale.percentage <= 34
                                    ? "Low"
                                    : scale.percentage > 34 && scale.percentage < 74
                                    ? "Moderate"
                                    : "High"}
                            </span>
                        </div>
                    </div>
                    <div
                        className={
                            "flex flex-col gap-2 transition transition-color duration-4000 rounded-xl text-slate-500"
                        }
                    >
                        <span className="font-base text-sm">
                            Strategies available for an employer to support the individual are:
                        </span>
                        {scale.strategies && (
                            <ul className={"text-sm list-disc pl-8"}>
                                {scale.strategies.split("; ").map((description, index) => (
                                    <li key={index}>{capitalizeFirstLetter(description)}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="flex flex-col gap-3">
                        <div className="flex items-center justify-between text-sm font-medium text-slate-500 mt-2">
                            <span className={"text-amber-500"}>Moderate</span>
                            <span className={"text-red-500"}>High</span>
                        </div>
                        <div className="flex flex-row w-full">
                            <div
                                className="h-7 border-2 border-slate-50 rounded-l-xl bg-amber-500"
                                style={{ width: `${getBarWidth(midUpper)}%` }}
                            />
                            <div
                                className="h-7 border-2 border-slate-50 rounded-r-xl bg-red-500"
                                style={{ width: `${getBarWidth(max) - getBarWidth(midUpper)}%` }}
                            />
                        </div>
                        <div className={"relative transition-width duration-4000 w-" + position + "-percentage "}>
                            <div className={"absolute -top-[49px] -right-0"}>
                                <div
                                    className={
                                        "flex flex-row gap-1 justify-center items-center text-amber-700 bg-amber-200 " +
                                        "w-max p-1 rounded-full rounded-br-none transition-opacity duration-4000 z-0"
                                    }
                                >
                                    <UserIcon className="block h-3.5 w-3.5" />
                                    <span className={"text-xs font-medium"}>{coacheeName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {scale.subCategories && (
                        <div className="flex flex-col gap-4 p-3 bg-slate-100 rounded-xl">
                            <div className="flex justify-between gap-2 items-center">
                                <div className="flex flex-row flex-wrap gap-2">
                                    {scale.subCategories.map(
                                        (subCategory, index) =>
                                            subCategory.name !== "PreQuestion" &&
                                            subCategory.strategies && (
                                                <div
                                                    className={
                                                        "h-max text-xs font-medium rounded-full px-2 py-1.5 " +
                                                        (scale.percentage <= 34
                                                            ? "bg-green-50"
                                                            : scale.percentage > 34 && scale.percentage < 74
                                                            ? "bg-amber-50"
                                                            : "bg-red-50") +
                                                        " " +
                                                        (scale.percentage < 34
                                                            ? "text-green-500"
                                                            : scale.percentage > 34 && scale.percentage < 74
                                                            ? "text-amber-500"
                                                            : "text-red-500")
                                                    }
                                                    key={index}
                                                >
                                                    {subCategory.name}
                                                </div>
                                            )
                                    )}
                                </div>
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="flex flex-row gap-2 items-center font-medium text-xs text-primary-600 w-max">
                                        <InformationCircleIcon className={"h-4 w-4"} /> More info{" "}
                                        <ArrowRightIcon className={"h-3 w-3 stroke-current stroke-1"} />
                                    </div>
                                    <Button
                                        colour={BUTTON_VARIANT_TYPES.GRAY_DARKER}
                                        icon={
                                            <ChevronRightIcon
                                                className={
                                                    "h-4 w-4 stroke-current stroke-1 transition-transform transform " +
                                                    (showSubScales && "rotate-90")
                                                }
                                            />
                                        }
                                        extraStyling={
                                            "shrink-0 text-xs h-8 w-8 rounded-full p-0 items-center flex justify-center"
                                        }
                                        iconPositionLeft
                                        onClick={handleShowSubScales}
                                    />
                                </div>
                            </div>
                            <AnimatePresence>
                                {showSubScales && (
                                    <motion.section
                                        initial="closed"
                                        animate="open"
                                        exit="closed"
                                        variants={{
                                            open: {
                                                opacity: 1,
                                                height: "auto",
                                            },
                                            closed: { opacity: 0, height: 0 },
                                        }}
                                        transition={{ type: "tween", duration: 0.4, stiffness: 100 }}
                                    >
                                        <div
                                            className={
                                                "flex flex-col gap-4  " +
                                                (scale.percentage <= 34
                                                    ? "text-green-500"
                                                    : scale.percentage > 34 && scale.percentage < 74
                                                    ? "text-amber-500"
                                                    : "text-red-500")
                                            }
                                        >
                                            {scale.subCategories.map(
                                                (subCategory, index) =>
                                                    subCategory.name !== "PreQuestion" &&
                                                    subCategory.strategies && (
                                                        <div className="flex flex-col gap-2" key={index}>
                                                            <div className={"text-sm font-medium"}>
                                                                {subCategory.name}
                                                            </div>
                                                            {subCategory.band_description && (
                                                                <div className={"text-sm text-slate-500"}>
                                                                    {capitalizeFirstLetter(
                                                                        subCategory.band_description
                                                                    )}
                                                                </div>
                                                            )}
                                                            {subCategory.strategies && (
                                                                <ul className={"text-sm list-disc pl-8 text-slate-500"}>
                                                                    {subCategory.strategies
                                                                        .split("; ")
                                                                        .map((description, index) => (
                                                                            <li key={index}>
                                                                                {capitalizeFirstLetter(description)}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </motion.section>
                                )}
                            </AnimatePresence>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
