import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    UsersIcon,
    UserIcon,
    UserGroupIcon,
    CogIcon,
    ArrowLeftOnRectangleIcon,
    ClipboardDocumentCheckIcon,
    RectangleStackIcon,
    PresentationChartLineIcon,
    DocumentTextIcon,
    BuildingOffice2Icon,
    TableCellsIcon,
    ArchiveBoxIcon,
    BriefcaseIcon,
    CubeIcon,
    AcademicCapIcon,
    VideoCameraIcon,
    DocumentPlusIcon,
    ArrowTopRightOnSquareIcon,
    ChatBubbleLeftRightIcon,
    UserCircleIcon,
    BuildingStorefrontIcon,
    BuildingOfficeIcon,
    QueueListIcon,
    CameraIcon,
    ChatBubbleBottomCenterTextIcon,
    HeartIcon,
    ClipboardDocumentIcon,
    RectangleGroupIcon,
    XMarkIcon,
    DocumentChartBarIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import { ReactComponent as WhistleIcon } from "../../../assets/whistle.svg";
import resumeBuilderIconLight from "../../../assets/resume-builder-icon-light.png";
import capabilityProfileSampleReport from "../../../assets/Capability Profile Sample Report.pdf";

import {
    ACE_2_SURVEY_PLATFORM,
    BUTTON_VARIANT_TYPES,
    CAPABILITY_CO_PLATFORM,
    COACH_PLATFORM,
    COACH_PLATFORM_PERMISSIONS,
    COACH_PLATFORM_TAB_PERMISSIONS,
    CUSTOMER_PORTAL_PERMISSIONS,
    EMPLOYMENT_SERVICES,
} from "../../../utils/constants";
import { getOneTimeUrl } from "../../../utils/api";
import { Context } from "../../../context/Context";
import { checkIfArrayIncludesContentsOfArray } from "../../../utils/utils";

import Button from "../../SharedComponents/Button";
import MenuSection from "./MenuSection";
import Modal from "../../SharedComponents/Modal";
import { MobileMenuSection } from "./MobileMenuSection";
import AceReportPicker from "../Content/Reports/ReportContent/ACESurvey/Pages/AceReportPicker";

export default function Menu() {
    const [launching, setLaunching] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const context = useContext(Context);

    const location = useLocation();

    const logout = async () => {
        navigate("/logout");
    };

    const resumeBuilderApp = context.userApplications.find(
        (userApplication) => userApplication.name === "Resume Builder"
    );

    const launchResumeBuilder = async () => {
        if (resumeBuilderApp) {
            setLaunching(true);

            try {
                if (resumeBuilderApp.isStatic) {
                    window.open(resumeBuilderApp.launchUrl);
                } else {
                    const oneTimeUrl = await getOneTimeUrl(resumeBuilderApp.name);
                    window.open(oneTimeUrl);
                }
            } catch (err) {
                alert("Authentication failed, please try again or contact support.");
            }
            setLaunching(false);
        } else {
            alert("Resume Builder not found");
        }
    };

    const managingUsersNavigation = {
        name: "Managing users",
        links: [
            {
                name: "Organisation users",
                href: "/organisation-users",
                icon: <BuildingOffice2Icon className="block h-4 w-4" />,
                permissions: [
                    COACH_PLATFORM_PERMISSIONS.SEARCH_USERS,
                    CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST,
                    COACH_PLATFORM_PERMISSIONS.VIEW_GROUPS,
                    COACH_PLATFORM_TAB_PERMISSIONS.VIEW_ORGANISATION_USERS_TAB,
                ],
            },
            {
                name: "Groups",
                href: "/groups",
                icon: <UserGroupIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_GROUPS, CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST],
            },
            {
                name: "Organisation user profile",
                href: "/organisation-user-profile",
                permissions: [],
                shadowLink: true,
            },
        ],
    };

    let userAppNavigation = [];

    if (context.defaultApp === EMPLOYMENT_SERVICES) {
        userAppNavigation = {
            name: context.terminology.coachee ? context.terminology.coachee.plural : "Job seekers",
            href: `/${context.terminology.coachee.plural.toLowerCase()}`,
            icon: <BriefcaseIcon className="block h-4 w-4" />,
            permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_COACHEES_LIST, CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST],
        };
    } else if (context.defaultApp === ACE_2_SURVEY_PLATFORM) {
        userAppNavigation = {
            name: context.terminology.coachee ? context.terminology.coachee.plural : "Franchisees",
            href: `/${context.terminology.coachee.plural.toLowerCase()}`,
            icon: <BuildingStorefrontIcon className="block h-4 w-4" />,
            permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_COACHEES_LIST, CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST],
        };
    } else {
        userAppNavigation = {
            name: context.terminology.coachee ? context.terminology.coachee.plural : "Coachees",
            href: `/${context.terminology.coachee.plural.toLowerCase()}`,
            icon: <UsersIcon className="block h-4 w-4" />,
            permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_COACHEES_LIST, CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST],
        };
    }

    const managingCoachingProgramNavigation = {
        name: "Managing programs",
        links: [
            {
                name: "Programs",
                href: "/programs",
                icon: <RectangleStackIcon className="block h-4 w-4" />,
                permissions:
                    context.defaultApp === EMPLOYMENT_SERVICES || context.defaultApp === COACH_PLATFORM
                        ? [COACH_PLATFORM_TAB_PERMISSIONS.VIEW_PROGRAMS_TAB, COACH_PLATFORM_PERMISSIONS.VIEW_PROGRAMS]
                        : [COACH_PLATFORM_PERMISSIONS.VIEW_PROGRAMS],
            },
            {
                name: "Cohorts",
                href: "/cohorts",
                icon: <TableCellsIcon className="block h-4 w-4" />,
                permissions:
                    context.defaultApp === EMPLOYMENT_SERVICES || context.defaultApp === COACH_PLATFORM
                        ? [COACH_PLATFORM_TAB_PERMISSIONS.VIEW_COHORTS_TAB, COACH_PLATFORM_PERMISSIONS.VIEW_COHORTS]
                        : [COACH_PLATFORM_PERMISSIONS.VIEW_COHORTS],
            },
            {
                name: "Cohort",
                href: "/cohort",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Create cohort",
                href: "/create-cohort",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Program",
                href: "/program",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Create program",
                href: "/create-program",
                permissions: [],
                shadowLink: true,
            },
            {
                name: context.terminology.coach ? context.terminology.coach.plural : "Coaches",
                href: `/${context.terminology.coach.plural.toLowerCase()}`,
                icon: !context.isNativeApps ? (
                    <BuildingOfficeIcon className="block h-4 w-4" />
                ) : (
                    <WhistleIcon className="block h-4 w-4" />
                ),
                permissions:
                    context.defaultApp === EMPLOYMENT_SERVICES
                        ? [
                              COACH_PLATFORM_TAB_PERMISSIONS.VIEW_COACHES_TAB,
                              COACH_PLATFORM_PERMISSIONS.VIEW_COACHES_LIST,
                              CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST,
                          ]
                        : [COACH_PLATFORM_PERMISSIONS.VIEW_COACHES_LIST, CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST],
            },
            {
                name: context.terminology.coachee.singular.toLowerCase() + " Profile",
                href: `/${context.terminology.coachee.singular.toLowerCase()}-profile`,
                permissions: [],
                shadowLink: true,
            },
            {
                name: context.terminology.coach.singular.toLowerCase() + " Profile",
                href: `/${context.terminology.coach.singular.toLowerCase()}-profile`,
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Setup " + context.terminology.coachee.singular.toLowerCase(),
                href: "/setup-user/" + context.terminology.coachee.singular.toLowerCase(),
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Setup " + context.terminology.coach.singular.toLowerCase(),
                href: "/setup-user/" + context.terminology.coach.singular.toLowerCase(),
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Assign " + context.terminology.coach.singular.toLowerCase(),
                href: "/assign-" + context.terminology.coach.singular.toLowerCase(),
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Upload resume",
                href: "/upload-resume",
                permissions: [],
                shadowLink: true,
            },
            {
                name: context.terminology.coach.singular + " training",
                href: `/${context.terminology.coach.singular.toLowerCase()}-training`,
                icon: <AcademicCapIcon className="block h-4 w-4" />,
                permissions: [
                    COACH_PLATFORM_PERMISSIONS.VIEW_ALL_TRAINING_MODULES,
                    COACH_PLATFORM_PERMISSIONS.CREATE_TRAINING_MODULES,
                    COACH_PLATFORM_PERMISSIONS.EDIT_STATUS_TRAINING_MODULE,
                    COACH_PLATFORM_PERMISSIONS.VIEW_STATUS_TRAINING_MODULE,
                ],
            },
            userAppNavigation,
            {
                name: "Sessions",
                href: "/sessions",
                icon: <PresentationChartLineIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_SESSIONS],
            },
            {
                name: "Session",
                href: "/session",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Create session",
                href: "/create-session",
                permissions: [],
                shadowLink: true,
            },
        ],
    };

    const assessAndGrowNavigation = {
        name: "Assess and grow",
        links: [
            {
                name: "Apps",
                href: "/apps",
                icon: <CubeIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_TAB_PERMISSIONS.VIEW_APPS_TAB],
            },
            {
                name: "Assessments",
                href: "/assessments",
                icon: <ClipboardDocumentCheckIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENTS],
            },
            {
                name: "Assign assessment",
                href: "/assign-assessment",
                icon: <DocumentPlusIcon className="block h-4 w-4" />,
                permissions: [CUSTOMER_PORTAL_PERMISSIONS.ASSIGN_ASSESSMENTS],
            },
            {
                name: "Assign assessment in bulk",
                href: "/assign-assessment-in-bulk",
                icon: <DocumentPlusIcon className="block h-4 w-4" />,
                permissions: [
                    CUSTOMER_PORTAL_PERMISSIONS.BULK_ASSIGN_ASSESSMENT,
                    COACH_PLATFORM_PERMISSIONS.BULK_ASSIGN_ASSESSMENT,
                ],
            },
            {
                name: "SkillBits™",
                href: "/resources",
                icon: <ArchiveBoxIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_RESOURCES_COACHEE],
            },
            {
                name: "Upload resource",
                href: "/upload-resource",
                permissions: [],
                shadowLink: true,
            },
            {
                name: context.terminology.coachee.singular + " interviews",
                href: `/${context.terminology.coachee.singular.toLowerCase()}-interviews`,
                icon: <ChatBubbleLeftRightIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_COMPLETED_PRACTICE_INTERVIEWS],
            },
            {
                name: "Practice interviews",
                href: "/practice-interviews",
                icon: <VideoCameraIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_PRACTICE_INTERVIEWS],
            },
            {
                name: "Recommend practice interview",
                href: "/recommend-practice-interview",
                permissions: [COACH_PLATFORM_PERMISSIONS.CREATE_PRACTICE_INTERVIEW],
                shadowLink: true,
            },

            {
                name: "Practice interview feedback",
                href: "/practice-interview-feedback",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Add practice interview reviewer",
                href: "/add-practice-interview-reviewer",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Resource",
                href: "/resource",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Add custom questions",
                permissions: [],
                demo: true,
                href: "/add-custom-questions",
                icon: <QueueListIcon className="block h-4 w-4" />,
                hidden: context.defaultApp !== ACE_2_SURVEY_PLATFORM || process.env.REACT_APP_ENV === "PRD",
            },
        ],
    };

    const actionsNavigation = {
        name: "Actions",
        links: [
            {
                name: "Resume Builder",
                button: true,
                buttonElement: (
                    <Button
                        onClick={launchResumeBuilder}
                        colour={BUTTON_VARIANT_TYPES.UNCOLOURED_BUTTON}
                        text="Resume Builder"
                        extraStyling={"w-full grow-0 shadow-sm flex items-center justify-center self-end bg-blue-600"}
                        icon={<img src={resumeBuilderIconLight} alt="resume builder" className="w-4 mr-2" />}
                        secondIcon={<ArrowTopRightOnSquareIcon className="shrink-0 block h-4 w-4 ml-2" />}
                        loading={launching}
                        iconPositionLeft
                        disabled={!resumeBuilderApp}
                    />
                ),
                hidden: context.defaultApp !== EMPLOYMENT_SERVICES,
                permissions: [COACH_PLATFORM_TAB_PERMISSIONS.VIEW_RESUME_BUILDER_TAB],
            },
            {
                name: "Privacy policy",
                button: true,
                buttonElement: context.menuHidden ? (
                    <Button
                        onClick={() => setShowModal(true)}
                        colour={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                        extraStyling="justify-center items-center rounded-full h-8 w-8"
                        icon={<DocumentTextIcon className="shrink-0 block w-3.5 h-3.5" />}
                        iconPositionLeft
                    />
                ) : (
                    <Button
                        onClick={() => setShowModal(true)}
                        colour={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                        text="Privacy policy"
                        extraStyling="w-full justify-center items-center"
                        icon={<DocumentTextIcon className="block w-3.5 h-3.5 mr-2 self-center" />}
                        secondIcon={
                            <ArrowTopRightOnSquareIcon className="shrink-0 block h-3.5 w-3.5 ml-2 stroke-current stroke-.5" />
                        }
                        iconPositionLeft
                    />
                ),
                hidden: context.defaultApp !== ACE_2_SURVEY_PLATFORM,
                permissions: [],
            },
        ],
        naked: true,
    };

    const ACEReportNavigation = {
        name: "ACE Survey Insights",
        links: [
            {
                name: "Snapshot",
                href: "/snapshot",
                icon: <CameraIcon className={"block h-4 w-4"} />,
                // Allow Franchisor/Admin to view completion rate when reporting is not ready
                permissions: [
                    context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENT_STATS_REGION)
                        ? COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENT_STATS_REGION
                        : context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COUNTRY_COMPLETIONS) &&
                          COACH_PLATFORM_PERMISSIONS.VIEW_COUNTRY_COMPLETIONS,
                ],
            },
            {
                name: "Executive summary",
                href: "/executive-summary",
                icon: <DocumentTextIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.errorLoadingReports,
            },
            {
                name: "Sentiment",
                href: "/sentiment",
                icon: <HeartIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.errorLoadingReports,
            },
            {
                name: "Demographics",
                href: "/demographics",
                icon: <UserIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.errorLoadingReports,
            },
            {
                name: "ACE key themes",
                href: "/ace-key-themes",
                icon: <RectangleGroupIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden:
                    context.aceSurveyReportData.aceMindsetIndex.length === 0 &&
                    context.aceSurveyReportData.netPromoterScores.length === 0,
            },
            {
                name: "Field manager satisfaction",
                href: "/field-manager-satisfaction",
                icon: <UserGroupIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden:
                    context.aceSurveyReportData.fieldManagers.length === 0 &&
                    context.aceSurveyReportData.fieldManagersLevel.length === 0,
            },
            {
                name: "Services",
                href: "/services",
                icon: <CogIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.aceSurveyReportData.services.length === 0,
            },
            {
                name: "Qualitative",
                href: "/qualitative",
                icon: <ChatBubbleBottomCenterTextIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.errorLoadingReports,
            },

            {
                name: "Member questions",
                href: "/member-questions",
                icon: <ChatBubbleLeftRightIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.aceSurveyReportData.memberQuestions.length === 0,
            },
            {
                name: "Additional questions",
                href: "/additional-questions",
                icon: <ChatBubbleBottomCenterTextIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.aceSurveyReportData.additionalQuestions.length === 0,
            },
            {
                name: "Follow through",
                href: "/follow-through",
                icon: <DocumentTextIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                hidden: context.errorLoadingReports,
            },
            {
                name: "Report context",
                href: "/report-context",
                icon: <ClipboardDocumentIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
            },
            {
                name: "Additional resources",
                href: "/additional-resources",
                icon: <ArchiveBoxIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
                disabled: true,
                hidden: process.env.REACT_APP_ENV === "PRD",
            },
        ],
        naked: context.menuHidden,
    };

    const reportingNavigation = {
        name: "Reporting",
        links: [
            {
                name: "Reports",
                href: "/reports",
                icon: <DocumentTextIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS],
            },
            {
                name: "Report",
                href: "/report",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "View report",
                href: "/view-report",
                permissions: [],
                shadowLink: true,
            },
            {
                name: "Upload report",
                href: "/upload-report",
                permissions: [],
                shadowLink: true,
            },
        ],
    };

    const settingsNavigation = {
        name: "Settings",
        links: [
            {
                name: "Profile",
                href: "/profile",
                icon: <UserIcon className="block h-4 w-4" />,
                permissions: [],
            },
            {
                name: "Settings",
                href: "/settings",
                icon: <CogIcon className="block h-4 w-4" />,
                permissions: [
                    COACH_PLATFORM_PERMISSIONS.GRANT_RELEASE_REPORT,
                    COACH_PLATFORM_PERMISSIONS.EDIT_USER_PERMISSIONS,
                ],
            },
        ],
    };

    const ACEPlatformNavigationNakedMenuSection = {
        name: "Single item menu section",
        links: [
            {
                name: "Surveys",
                href: "/surveys",
                icon: <ClipboardDocumentCheckIcon className="block h-4 w-4" />,
                permissions: [COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENTS],
            },
            {
                name: "Create report PDF",
                href: "/create-report-pdf",
                icon: <DocumentChartBarIcon className="block h-4 w-4" />,
                permissions: [],
                hidden: process.env.REACT_APP_ENV !== "DEMO" && process.env.REACT_APP_ENV !== "NPD",
                demo: true,
            },
        ],
        naked: true,
    };

    const ACEPlatformReportsNakedMenuSection = {
        name: "Single item menu section",
        links: [],
        naked: true,
    };

    const navigation = [
        managingUsersNavigation,
        managingCoachingProgramNavigation,
        assessAndGrowNavigation,
        reportingNavigation,
        settingsNavigation,
    ];

    if (context.defaultApp === EMPLOYMENT_SERVICES) {
        reportingNavigation.links.push({
            name: "Apprenticeship Risk & Career Assessment Report",
            href: "/apprenticeship-risk-career-assessment-report-demo",
            icon: <DocumentChartBarIcon className="block h-4 w-4 shrink-0" />,
            permissions: [],
            demo: true,
            hidden: process.env.REACT_APP_ENV !== "DEMO" && process.env.REACT_APP_ENV !== "NPD",
        });
    }

    //Temporarily remove `Managing Users Navigation` and `Settings Navigation` category for ESP.
    const ESPNavigation = [
        {
            name: "Single item menu section",
            links: [
                ...managingCoachingProgramNavigation.links,
                ...assessAndGrowNavigation.links,
                ...reportingNavigation.links,
                ...actionsNavigation.links,
            ],
            naked: true,
        },
    ];

    if (context.defaultApp === COACH_PLATFORM) {
        reportingNavigation.links.push({
            name: "Menu Driven Report",
            href: "/menu-driven-report-demo",
            icon: <DocumentChartBarIcon className="block h-4 w-4 shrink-0" />,
            permissions: [],
            demo: true,
            hidden: process.env.REACT_APP_ENV !== "DEMO" && process.env.REACT_APP_ENV !== "NPD",
        });
    }

    if (context.defaultApp === COACH_PLATFORM) {
        reportingNavigation.links.push({
            name: "Saleable Report",
            href: "/saleable-report-demo",
            icon: <DocumentChartBarIcon className="block h-4 w-4 shrink-0" />,
            permissions: [],
            demo: true,
            hidden: process.env.REACT_APP_ENV !== "DEMO" && process.env.REACT_APP_ENV !== "NPD",
        });
    }

    if (context.defaultApp === CAPABILITY_CO_PLATFORM && context.profileDetails.organisationName === "Capability Co") {
        reportingNavigation.links.push({
            name: "Capability Profile",
            href: "/capability-profile-report-demo",
            icon: <DocumentChartBarIcon className="block h-4 w-4 shrink-0" />,
            permissions: [],
            hidden: process.env.REACT_APP_ENV !== "DEMO",
        });

        reportingNavigation.links.push({
            name: "Capability Map",
            href: "/capability-map-report-demo",
            icon: <DocumentChartBarIcon className="block h-4 w-4 shrink-0" />,
            permissions: [],
            hidden: process.env.REACT_APP_ENV !== "DEMO",
        });
    }

    const ACEPlatformNavigation = context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENTS)
        ? [ACEPlatformNavigationNakedMenuSection]
        : [];

    ACEPlatformNavigation.push(ACEPlatformReportsNakedMenuSection);

    if (context.aceSurveyReports.length > 1) {
        ACEPlatformReportsNakedMenuSection.links.push({
            name: "ACE 2.0 Reports",
            button: true,
            buttonElement: <AceReportPicker />,
            permissions: [],
            hidden: context.defaultApp !== ACE_2_SURVEY_PLATFORM && context.aceSurveyReports.length <= 1,
        });
    }

    if (
        context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS) ||
        context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENT_STATS_REGION) ||
        context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COUNTRY_COMPLETIONS)
    ) {
        ACEPlatformNavigation.push(ACEReportNavigation);
    }

    ACEPlatformNavigation.push(actionsNavigation);

    ACEPlatformNavigation.forEach(
        (navigationLinks) =>
            (navigationLinks.open = navigationLinks.links.some((link) => encodeURI(link.href) === location.pathname))
    );

    ESPNavigation.forEach(
        (navigationLinks) =>
            (navigationLinks.open = navigationLinks.links.some((link) => encodeURI(link.href) === location.pathname))
    );

    navigation.forEach(
        (navigationLinks) =>
            (navigationLinks.open = navigationLinks.links.some((link) => encodeURI(link.href) === location.pathname))
    );

    function handleMenuSizeToggle() {
        context.setMenuHidden();
    }

    let numberOfActiveCoachPlatformLinks = 0;

    navigation.forEach((menuSection) => {
        menuSection.links.forEach((menuItem) => {
            if (
                !menuItem.shadowLink &&
                !menuItem.hidden &&
                checkIfArrayIncludesContentsOfArray(context.userPermissions, menuItem.permissions)
            ) {
                numberOfActiveCoachPlatformLinks = numberOfActiveCoachPlatformLinks + 1;
            }
        });
    });

    return (
        <>
            <aside className={"hidden lg:block text-sm py-4 pl-4 " + (context.menuHidden ? "z-50" : "z-10")}>
                <div
                    className={
                        "flex flex-col p-6 bg-white rounded-2xl shadow-sm border border-slate-100 border-1 transition-width duration-300 " +
                        "items-center justify-center " +
                        (context.menuHidden ? "w-[83.2px]" : "w-[294px]")
                    }
                >
                    <div className="grid grid-flow-row gap-8 w-full">
                        <div className="grid grid-flow-row gap-4 w-full">
                            {process.env.REACT_APP_DEFAULT_APP === ACE_2_SURVEY_PLATFORM &&
                                (context.menuHidden ? (
                                    <Button
                                        colour={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                                        icon={
                                            <ChevronDoubleRightIcon className="shrink-0 block h-3.5 w-3.5 stroke-.5" />
                                        }
                                        extraStyling="justify-center rounded-full h-8 w-8"
                                        onClick={handleMenuSizeToggle}
                                    />
                                ) : (
                                    <Button
                                        text={"Hide menu"}
                                        colour={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                                        icon={
                                            <ChevronDoubleLeftIcon className="shrink-0 block h-3 w-3 stroke-current stroke-.5 mr-2" />
                                        }
                                        extraStyling="justify-center rounded-full place-self-end"
                                        onClick={handleMenuSizeToggle}
                                        iconPositionLeft
                                        smallButton
                                    />
                                ))}
                            {!context.menuHidden && (
                                <div className="flex flex-row items-center text-primary-600">
                                    <a
                                        className={"w-full"}
                                        href={"/"}
                                        title={`${process.env.REACT_APP_DEFAULT_APP} Home`}
                                        aria-label={`${process.env.REACT_APP_DEFAULT_APP} homepage`}
                                    >
                                        <img alt="logo" src={context.getImage("shortLogo")} />
                                    </a>
                                </div>
                            )}
                        </div>
                        {!context.menuHidden && (
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-row gap-2 items-center text-primary-600">
                                        <div
                                            className={
                                                "flex flex-row items-center gap-3 p-3 rounded-xl bg-slate-50 w-full " +
                                                "hover:bg-slate-100 transition-colors border border-slate-100"
                                            }
                                        >
                                            <Tippy
                                                content={"Go to your profile"}
                                                className={
                                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 " +
                                                    "text-white"
                                                }
                                                arrow={false}
                                                animation={"shift-away-subtle"}
                                                placement={"bottom-end"}
                                                hideOnClick={false}
                                            >
                                                <div
                                                    className={"relative inline-block cursor-pointer"}
                                                    onClick={() => navigate("/profile")}
                                                >
                                                    {context.photosLoading ? (
                                                        <div
                                                            className={
                                                                "animate-pulse bg-slate-200 rounded-full relative " +
                                                                (context.profileDetails.jobSeekerId
                                                                    ? "w-14 h-14"
                                                                    : "w-9 h-9")
                                                            }
                                                        />
                                                    ) : context.photo.length === 0 ? (
                                                        <UserCircleIcon className="shrink-0 flex h-10 w-10" />
                                                    ) : (
                                                        <img
                                                            key={context.photo.identifier}
                                                            className={
                                                                "shrink-0 flex rounded-full bg-clip-border shadow-sm bg-slate-50 " +
                                                                (context.profileDetails.jobSeekerId
                                                                    ? "w-14 h-14"
                                                                    : "w-9 h-9")
                                                            }
                                                            src={"data:image/png;base64," + context.photo.content}
                                                            alt={context.photo.name}
                                                        />
                                                    )}
                                                </div>
                                            </Tippy>
                                            <div className="flex flex-col gap-1 text-xs cursor-default">
                                                <div className="font-medium text-primary-600">
                                                    {context.profileDetails.firstName +
                                                        " " +
                                                        context.profileDetails.lastName}
                                                </div>
                                                <div
                                                    className={
                                                        "text-slate-500 group-hover:text-primary-600 transition-colors " +
                                                        "text-ellipsis overflow-hidden w-36"
                                                    }
                                                >
                                                    {context.profileDetails.emailAddress}
                                                </div>
                                                {context.profileDetails.jobSeekerId && (
                                                    <div className="text-secondary-600 ">
                                                        JSID:{" "}
                                                        <span className="font-medium">
                                                            {context.profileDetails.jobSeekerId}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="grid gap-8">
                            {context.defaultApp === ACE_2_SURVEY_PLATFORM
                                ? ACEPlatformNavigation.map((menuSection, index) => (
                                      <MenuSection menuSection={menuSection} key={index} />
                                  ))
                                : context.defaultApp === EMPLOYMENT_SERVICES
                                ? ESPNavigation.map((menuSection, index) => (
                                      <MenuSection menuSection={menuSection} key={index} />
                                  ))
                                : navigation.map((menuSection, index) => (
                                      <MenuSection menuSection={menuSection} key={index} />
                                  ))}
                        </div>
                        {context.menuHidden ? (
                            <Button
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                icon={
                                    <ArrowLeftOnRectangleIcon className="block h-3.5 w-3.5 shrink-0 stroke-current stroke-.5" />
                                }
                                extraStyling="rounded-full h-8 w-8"
                                onClick={logout}
                            />
                        ) : (
                            <Button
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                text="Logout"
                                icon={<ArrowLeftOnRectangleIcon className="block h-3.5 w-3.5 ml-2" />}
                                extraStyling="w-full justify-center"
                                onClick={logout}
                            />
                        )}
                    </div>
                </div>
            </aside>
            <Modal
                active={showModal}
                closeModal={() => setShowModal(!showModal)}
                content={
                    <div className="flex flex-col gap-4 min-w-[768px]">
                        <div className={"flex flex-col gap-2 justify-center overflow-hidden rounded-xl"}>
                            <div className={"flex justify-between flex-col p-2 gap-5 scale-75 -mx-[125px]"}>
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.GRAY}
                                    icon={<XMarkIcon className="h-4 w-4 stroke-current stroke-1 mr-2" />}
                                    text="Close"
                                    extraStyling={"self-end scale-125 mr-2"}
                                    iconPositionLeft
                                    onClick={() => setShowModal(!showModal)}
                                    smallButton
                                />
                                <iframe
                                    src={process.env.REACT_APP_ACE_PRIVACY_POLICY_URL}
                                    title="ACE Franchisee Survey – Privacy & Data Processing Policies"
                                    width="100%"
                                    className="overflow-y-scroll col-span-full bg-slate-50 h-screen rounded-xl p-5"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                }
            />
            <div className="print:hidden lg:hidden absolute top-0 mb-6 flex items-center justify-between bg-white shadow-sm w-full h-16 px-4">
                <MobileMenuSection
                    menuItems={
                        <div className="flex flex-col gap-4">
                            {context.defaultApp === ACE_2_SURVEY_PLATFORM
                                ? ACEPlatformNavigation.map((menuSection, index) => (
                                      <MenuSection mobile menuSection={menuSection} key={index} />
                                  ))
                                : context.defaultApp === EMPLOYMENT_SERVICES
                                ? ESPNavigation.map((menuSection, index) => (
                                      <MenuSection mobile menuSection={menuSection} key={index} />
                                  ))
                                : navigation.map((menuSection, index) => (
                                      <MenuSection mobile menuSection={menuSection} key={index} />
                                  ))}
                            <Button
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                text="Logout"
                                icon={<ArrowLeftOnRectangleIcon className="block h-3.5 w-3.5 ml-2" />}
                                extraStyling="w-full justify-center"
                                onClick={logout}
                            />
                        </div>
                    }
                />
                <img alt="logo" className="h-10" src={context.getImage("shortLogo")} />
            </div>
        </>
    );
}
