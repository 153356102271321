import Input from "../../../../SharedComponents/Form/Input";
import RadioButton from "../../../../SharedComponents/Form/RadioButton";
import Alert from "../../../../SharedComponents/Alert";
import MultiSelect from "../../../../SharedComponents/Form/MultiSelect";

export default function SessionDetails(props) {
    const {
        session,
        alert,
        alertType,
        setAlert,
        setSessionName,
        resetRadioFields,
        setSessionType,
        setSessionDateTime,
        setSessionDuration,
        sessionType,
        sessionLink,
        setSessionLink,
        sessionLocation,
        setSessionLocation,
        organisationUsers,
        loading,
        chosenUsers,
        setChosenUsers,
        setSessionIsCompleted,
        readOnly,
    } = props;

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-4">
                <div className={"flex flex-col gap-3 col-span-2 lg:col-span-1"}>
                    <Input
                        id="sessionName"
                        type="text"
                        label="Session name"
                        placeholder={"Session name"}
                        value={session.name && session.name}
                        backgroundColour={"bg-white"}
                        onChange={setSessionName}
                        disabled={readOnly}
                    />
                </div>

                <div className={"flex flex-col gap-3 col-span-2 lg:col-span-1"}>
                    <div className="flex justify-center">
                        <div className="flex flex-col gap-2 w-full">
                            <div className={"flex justify-between w-full"}>
                                <label className="text-sm font-medium text-primary-600 w-full ">Session type</label>
                            </div>
                            <fieldset
                                onChange={(event) => {
                                    resetRadioFields(event.target.value);
                                    setSessionType(event.target.value);
                                }}
                            >
                                <div className="mt-2 flex flex-row gap-6">
                                    <RadioButton
                                        id="onsite"
                                        name="sessionType"
                                        value="onsite"
                                        labelText="Onsite"
                                        defaultChecked={session.meetingLocation && !session.meetingUrl ? true : false}
                                        disabled={readOnly}
                                    />
                                    <RadioButton
                                        id="remote"
                                        name="sessionType"
                                        value="remote"
                                        labelText="Remote"
                                        defaultChecked={session.meetingUrl && !session.meetingLocation ? true : false}
                                        disabled={readOnly}
                                    />
                                    <RadioButton
                                        id="hybrid"
                                        name="sessionType"
                                        value="hybrid"
                                        labelText="Hybrid"
                                        defaultChecked={session.meetingUrl && session.meetingLocation ? true : false}
                                        disabled={readOnly}
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>

                <div className={"flex flex-col gap-3 col-span-2 lg:col-span-1"}>
                    <Input
                        id="sessionDate"
                        type="datetime-local"
                        label="Session date time"
                        placeholder={"Date time"}
                        value={session.startTime && session.startTime.slice(0, 16)}
                        backgroundColour={"bg-white"}
                        onChange={setSessionDateTime}
                        disabled={readOnly}
                    />
                </div>

                <div className={"flex flex-col gap-3 col-span-2 lg:col-span-1"}>
                    <Input
                        id="sessionDuration"
                        type="number"
                        label="Session duration in minutes"
                        placeholder={"Session duration (minutes)"}
                        value={session.durationMins && session.durationMins}
                        backgroundColour={"bg-white"}
                        onChange={setSessionDuration}
                        disabled={readOnly}
                    />
                </div>
                {sessionType === "remote" && (
                    <div className={"flex gap-3 col-span-2"}>
                        <Input
                            id="sessionLink"
                            type="url"
                            label="Session link"
                            placeholder={"Session link"}
                            value={session.meetingUrl && sessionLink}
                            onChange={setSessionLink}
                            backgroundColour={"bg-white"}
                            disabled={readOnly}
                        />
                    </div>
                )}

                {sessionType === "onsite" && (
                    <div className={"flex gap-3 col-span-2"}>
                        <Input
                            id="sessionLocation"
                            type="text"
                            label="Session location"
                            placeholder={"Session location"}
                            value={session.meetingLocation && sessionLocation}
                            onChange={setSessionLocation}
                            backgroundColour={"bg-white"}
                            disabled={readOnly}
                        />
                    </div>
                )}

                {sessionType === "hybrid" && (
                    <>
                        <div className={"flex gap-3 col-span-2 lg:col-span-1"}>
                            <Input
                                id="sessionLink"
                                type="url"
                                label="Session link"
                                placeholder={"Session link"}
                                value={session.meetingUrl && sessionLink}
                                onChange={setSessionLink}
                                backgroundColour={"bg-white"}
                                disabled={readOnly}
                            />
                        </div>
                        <div className={"flex gap-3 col-span-2 lg:col-span-1"}>
                            <Input
                                id="sessionLocation"
                                type="text"
                                label="Session location"
                                placeholder={"Session location"}
                                value={session.meetingLocation && sessionLocation}
                                onChange={setSessionLocation}
                                backgroundColour={"bg-white"}
                                disabled={readOnly}
                            />
                        </div>
                    </>
                )}

                {!readOnly && (
                    <div className={"col-span-2 flex flex-col gap-3"}>
                        <MultiSelect
                            isMulti
                            items={organisationUsers}
                            labelText={"Session participants"}
                            loading={loading}
                            setChosenItems={setChosenUsers}
                            chosenItems={chosenUsers}
                        />
                    </div>
                )}

                <div className={"flex flex-col gap-3"}>
                    <div className="flex justify-center">
                        <div className="flex flex-col gap-2 w-full">
                            <div className={"flex justify-between w-full"}>
                                <label className="text-sm font-medium text-primary-600 w-full ">Session status</label>
                            </div>
                            <fieldset
                                onChange={(event) => {
                                    //directly having value as boolean will cause issue, hence string type is used.
                                    if (event.target.value === "True") {
                                        setSessionIsCompleted(true);
                                    } else {
                                        setSessionIsCompleted(false);
                                    }
                                }}
                            >
                                <div className="mt-2 flex flex-row gap-6">
                                    <RadioButton
                                        id="complete"
                                        name="isCompleted"
                                        value="True"
                                        labelText="Completed"
                                        defaultChecked={session?.isCompleted ? true : false}
                                        disabled={readOnly}
                                    />
                                    <RadioButton
                                        id="incomplete"
                                        name="isCompleted"
                                        value="False"
                                        labelText="Incomplete"
                                        defaultChecked={!session?.isCompleted ? true : false}
                                        disabled={readOnly}
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
