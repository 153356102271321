import { useRef } from "react";
import { useLocation } from "react-router";

import { ReactComponent as SubMenuItemArrow } from "../../../../../../assets/return-down-forward-outline.svg";

import JumpToLink from "../../SharedChartComponents/JumpToLink";
import DashboardLayout from "../../../../../SharedComponents/DashboardLayout";
import ReportHeader from "../../SharedChartComponents/ReportHeader";
import CoachingGuideline from "./CoachingGuideline";
import Recruitment from "./Recruitment";
import SideSection from "../../SharedChartComponents/SideSection";
import { useCycle } from "framer-motion";
import Toggle from "../../../../../SharedComponents/Form/Toggle";

export default function MenuDriven(props) {
    return (
        <DashboardLayout title={"Report"} requiredPermissions={[]}>
            <MenuDrivenMeat {...props} />
        </DashboardLayout>
    );
}

export function MenuDrivenMeat(props) {
    const { score, testId, demo, coacheeName } = props;

    const [onlyCharts, setOnlyCharts] = useCycle(false, true);

    const introduction = useRef(null);
    const recruitmentReport = useRef(null);
    const snapshot = useRef(null);
    const detailedSummary = useRef(null);
    const coachingGuideline = useRef(null);
    const leadership = useRef(null);
    const influencing = useRef(null);
    const subordinate = useRef(null);
    const teamRoles = useRef(null);
    const interpersonal = useRef(null);
    const thinking = useRef(null);
    const coping = useRef(null);
    const background = useRef(null);

    const location = useLocation();

    let currentCoacheeName = location.state?.userName
        ? location.state.userName
        : coacheeName
        ? coacheeName
        : "Psych Press";

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop - (window.innerWidth < 1280 ? 170 : 20),
            behavior: "smooth",
        });
    };

    return (
        <div className="flex flex-col gap-6" data-testid={testId && testId}>
            <ReportHeader reportName="Menu Driven Recruitment & Coaching Guideline" userName={currentCoacheeName} />
            <div
                className={
                    "flex flex-row gap-2 print:hidden sticky top-4 left-4 bg-white p-3 rounded-xl " +
                    "border border-slate-100 shadow-lg z-50 relative xl:hidden print:hidden"
                }
            >
                <div className="flex flex-row flex-wrap gap-3">
                    <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                    <JumpToLink
                        text="Recruitment report"
                        colour={"primary"}
                        scrollTo={() => scrollToSection(recruitmentReport)}
                    />
                    <JumpToLink
                        text="Introduction"
                        colour={"secondary"}
                        scrollTo={() => scrollToSection(introduction)}
                        hidden={onlyCharts}
                    />
                    <JumpToLink text="Snapshot" colour={"secondary"} scrollTo={() => scrollToSection(snapshot)} />
                    <JumpToLink
                        text="Detailed summary"
                        colour={"secondary"}
                        scrollTo={() => scrollToSection(detailedSummary)}
                    />
                    {demo && (
                        <>
                            <JumpToLink
                                text="Coaching guideline"
                                colour={"primary"}
                                scrollTo={() => scrollToSection(coachingGuideline)}
                            />
                            <JumpToLink
                                text="Leadership styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(leadership)}
                            />
                            <JumpToLink
                                text="Influencing styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(influencing)}
                            />
                            <JumpToLink
                                text="Subordinate Styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(subordinate)}
                            />
                            <JumpToLink
                                text="Team roles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(teamRoles)}
                            />
                            <JumpToLink
                                text="Interpersonal and relationships building styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(interpersonal)}
                                hidden={onlyCharts}
                            />
                            <JumpToLink
                                text="Thinking and decision‐making styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(thinking)}
                                hidden={onlyCharts}
                            />
                            <JumpToLink
                                text="Coping with pressure and stress"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(coping)}
                                hidden={onlyCharts}
                            />
                        </>
                    )}
                    <JumpToLink
                        text="Background"
                        colour={"primary"}
                        scrollTo={() => scrollToSection(background)}
                        hidden={onlyCharts}
                    />
                </div>
            </div>
            <div onClick={() => setOnlyCharts()}>
                <Toggle text={"Executive mode"} triggered={onlyCharts} />
            </div>
            <div className={"grid grid-cols-1 xl:grid-cols-3 gap-6 print:p-0"}>
                <div className="col-span-2 flex flex-col gap-6">
                    <div className="flex flex-col gap-4">
                        <div className="text-primary-600 font-medium text-base">{score.introduction.title}</div>
                        <div
                            ref={introduction}
                            className={
                                "flex-col gap-2 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                                (onlyCharts ? "hidden" : "flex")
                            }
                        >
                            <div className="flex flex-col gap-2">
                                {score.introduction.description.map((paragraph) => (
                                    <p className="text-slate-500 text-sm">{paragraph}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Recruitment
                        score={score}
                        onlyCharts={onlyCharts}
                        jumpToRefs={{
                            recruitmentReport: recruitmentReport,
                            leadership: leadership,
                            snapshot: snapshot,
                            detailedSummary: detailedSummary,
                        }}
                        hideExtraContent
                    />
                    {demo && (
                        <CoachingGuideline
                            onlyCharts={onlyCharts}
                            jumpToRefs={{
                                coachingGuideline: coachingGuideline,
                                leadership: leadership,
                                influencing: influencing,
                                subordinate: subordinate,
                                teamRoles: teamRoles,
                                interpersonal: interpersonal,
                                thinking: thinking,
                                coping: coping,
                                background: background,
                            }}
                        />
                    )}
                    <div
                        ref={background}
                        className={
                            "flex-col gap-4 bg-white rounded-xl p-4 shadow-sm border border-slate-100 " +
                            (onlyCharts ? "hidden" : "flex")
                        }
                    >
                        <div className="text-secondary-600 font-medium text-base">
                            {score.backGroundInformation.title}
                        </div>
                        {score.backGroundInformation.subHeadings.map((subHeading) => (
                            <div className={"flex flex-col gap-2"}>
                                <div className="text-secondary-600 font-medium text-sm">{subHeading.title}</div>
                                {subHeading.description.map((paragraph) => (
                                    <p className="text-slate-500 text-sm">{paragraph}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <SideSection
                    extraContent={
                        <div
                            className={
                                "flex flex-col gap-4 print:hidden sticky top-4 left-4 p-4 rounded-xl " +
                                "shadow-xl border border-slate-100 z-50 hidden w-auto xl:flex 2xl:w-max " +
                                "print:hidden bg-white " +
                                (onlyCharts ? "mt-10" : "mt-0")
                            }
                        >
                            <div className="flex flex-row gap-2 items-center">
                                <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                            </div>
                            <div className="flex flex-col gap-3">
                                <JumpToLink
                                    text="Introduction"
                                    colour={"primary"}
                                    scrollTo={() => scrollToSection(introduction)}
                                    hidden={onlyCharts}
                                />
                                <div className="flex flex-col gap-2">
                                    <JumpToLink
                                        text="Recruitment report"
                                        colour={"primary"}
                                        scrollTo={() => scrollToSection(recruitmentReport)}
                                    />
                                    <div className="flex flex-col gap-2 ml-2">
                                        <JumpToLink
                                            text="Snapshot"
                                            icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                            colour={"secondary"}
                                            scrollTo={() => scrollToSection(snapshot)}
                                        />
                                        <JumpToLink
                                            text="Detailed Summary"
                                            icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                            colour={"secondary"}
                                            scrollTo={() => scrollToSection(detailedSummary)}
                                        />
                                    </div>
                                </div>
                                {demo && (
                                    <div className="flex flex-col gap-2">
                                        <JumpToLink
                                            text="Coaching guideline"
                                            colour={"primary"}
                                            scrollTo={() => scrollToSection(coachingGuideline)}
                                        />
                                        <div className="flex flex-col gap-2 ml-2">
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text={"Leadership styles"}
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(leadership)}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Influencing styles"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(influencing)}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Subordinate Styles"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(subordinate)}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Team roles"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(teamRoles)}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Interpersonal and relationships building styles"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(interpersonal)}
                                                hidden={onlyCharts}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Thinking and decision‐making styles"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(thinking)}
                                                hidden={onlyCharts}
                                            />
                                            <JumpToLink
                                                icon={<SubMenuItemArrow className="block h-4 w-4" />}
                                                text="Coping with pressure and stress"
                                                colour={"secondary"}
                                                scrollTo={() => scrollToSection(coping)}
                                                hidden={onlyCharts}
                                            />
                                        </div>
                                    </div>
                                )}
                                <JumpToLink
                                    text="Background"
                                    colour={"primary"}
                                    scrollTo={() => scrollToSection(background)}
                                    hidden={onlyCharts}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
}
