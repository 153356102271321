import CareerToDatePieCharts from "./CareerToDatePieCharts";

export default function Preview() {
    return (
        <>
            <label className="text-sm font-medium text-primary-600 w-full ">Current position</label>
            <div className={"bg-slate-50 p-4 rounded-xl mt-4"}>
                <div className={"grid grid-cols-2 gap-4"}>
                    <div>
                        <div className={"font-medium text-slate-500"}>Chieftain</div>
                        <div className={"text-slate-400 mt-2"}>A Tribe Called Quest</div>
                        <div className={"font-medium text-secondary-500 mt-2"}>f.frankston@atcq.com.au</div>
                    </div>
                    <div>
                        <div className={"font-medium text-slate-500"}>War department</div>
                        <div className={"text-slate-400 mt-2"}>Entertainment division</div>
                    </div>
                </div>
            </div>
            <div className={"bg-gradient-to-l from-primary-50 to-transparent p-4 rounded-xl mt-6"}>
                <label className="text-sm font-medium text-primary-600 w-full ">Career to-date</label>
                <CareerToDatePieCharts />
            </div>
            <div className={"mt-8"}>
                <label className="text-sm font-medium text-primary-600 w-full ">Experience</label>
                <div className={"grid grid-cols-2 gap-2 mt-4"}>
                    <div className={"bg-slate-50 p-4 rounded-xl"}>
                        <label className="text-sm font-medium text-slate-500 w-full ">Position 1</label>
                        <div className={"grid grid-cols-2 gap-2 mt-4"}>
                            <div>
                                <div className={"font-medium text-slate-500"}>Chieftain</div>
                                <div className={"text-slate-400 mt-2"}>A Tribe Called Quest</div>
                                <div className={"font-medium text-secondary-500 mt-2"}>f.frankston@atcq.com.au</div>
                            </div>
                            <div>
                                <div className={"font-medium text-slate-500"}>War department</div>
                                <div className={"text-slate-400 mt-2"}>Entertainment division</div>
                            </div>
                        </div>
                    </div>
                    <div className={"bg-slate-50 p-4 rounded-xl"}>
                        <label className="text-sm font-medium text-slate-500 w-full ">Position 2</label>
                        <div className={"grid grid-cols-2 gap-2  mt-4"}>
                            <div>
                                <div className={"font-medium text-slate-500"}>Chieftain</div>
                                <div className={"text-slate-400 mt-2"}>A Tribe Called Quest</div>
                                <div className={"font-medium text-secondary-500 mt-2"}>f.frankston@atcq.com.au</div>
                            </div>
                            <div>
                                <div className={"font-medium text-slate-500"}>War department</div>
                                <div className={"text-slate-400 mt-2"}>Entertainment division</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"mt-8"}>
                <label className="text-sm font-medium text-primary-600 w-full ">Education</label>
                <div className={"grid grid-cols-2 gap-2 mt-4"}>
                    <div className={"bg-slate-50 p-4 rounded-xl"}>
                        <div>
                            <div className={"font-medium text-slate-500"}>Master's Degree of Trigonometry</div>
                            <div className={"text-slate-400 mt-2"}>Oxfred University</div>
                        </div>
                    </div>
                    <div className={"bg-slate-50 p-4 rounded-xl"}>
                        <div>
                            <div className={"font-medium text-slate-500"}>Bachelor Degree of Applied Mathematics</div>
                            <div className={"text-slate-400 mt-2"}>Camembert University</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
