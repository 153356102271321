import StarRating from "../../../../SharedComponents/StarRating";

export default function JobActivityCard(props) {
    const { item: jobActivity } = props;

    function renderInterviewSwitch(rating) {
        switch (rating) {
            case "Very well":
                return (
                    <div className={"text-xs text-green-600 bg-green-100 p-1 px-2 rounded-full w-max font-medium"}>
                        Very well
                    </div>
                );
            case "Well":
                return (
                    <div className={"text-xs text-blue-600 bg-blue-100 p-1 px-2 rounded-full w-max font-medium"}>
                        Well
                    </div>
                );
            case "Ok":
                return (
                    <div className={"text-xs text-slate-600 bg-slate-200 p-1 px-2 rounded-full w-max font-medium"}>
                        Ok
                    </div>
                );
            case "Badly":
                return (
                    <div className={"text-xs text-amber-600 bg-amber-100 p-1 px-2 rounded-full w-max font-medium"}>
                        Badly
                    </div>
                );
            case "Very badly":
                return (
                    <div className={"text-xs text-red-600 bg-red-100 p-1 px-2 rounded-full w-max font-medium"}>
                        Very badly
                    </div>
                );
            default:
                return <div className={"text-xs text-slate-400 italic "}>Unavailable</div>;
        }
    }

    return (
        <div className={"bg-white shadow-sm overflow-hidden rounded-xl group"}>
            <div className="p-4 flex justify-between items-center gap-6 text-slate-600 cursor-pointer">
                <h3
                    className={
                        "flex-1 text-base font-title transition-colors text-primary-600 group-hover:text-primary-700"
                    }
                >
                    {jobActivity.jobTitle}
                </h3>
                <img src={jobActivity.companyLogo} className="h-7" alt="company logo" />
            </div>
            <div>
                <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-slate-500 ">Company</dt>
                    <dd className="mt-1 text-sm text-slate-600 sm:mt-0 sm:col-span-2">{jobActivity.company}</dd>
                </div>
                <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-slate-500 ">Applied</dt>
                    <dd className="text-primary-600 font-medium">
                        {jobActivity.applied ? (
                            <div
                                className={
                                    "text-xs text-green-600 bg-green-100 p-1 px-2 rounded-full w-max font-medium"
                                }
                            >
                                Yes
                            </div>
                        ) : (
                            <div className={"text-xs text-red-600 bg-red-100 p-1 px-2 rounded-full w-max font-medium"}>
                                No
                            </div>
                        )}
                    </dd>
                </div>
                <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-slate-500 ">Interview rating</dt>
                    <dd>
                        {jobActivity.interviewRating ? (
                            <div>{renderInterviewSwitch(jobActivity.interviewRating)}</div>
                        ) : (
                            <div className="italic text-slate-500">Not submitted yet</div>
                        )}
                    </dd>
                </div>
                <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 flex flex-col gap-2">
                    <dt className="text-sm font-medium text-slate-500 ">Interview comments</dt>
                    <dd className="text-slate-500 h-16 scrollbar overflow-y-scroll col-span-2">
                        {jobActivity.interviewComments ? (
                            <div>{jobActivity.interviewComments}</div>
                        ) : (
                            <div className="italic text-slate-500">Not submitted yet</div>
                        )}
                    </dd>
                </div>
                <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 flex flex-col gap-2">
                    <dt className="text-sm font-medium text-slate-500 ">Company rating</dt>
                    <dd className="text-slate-500 h-16 scrollbar overflow-y-scroll col-span-2">
                        {jobActivity.companyRating ? (
                            <StarRating starsRating={jobActivity.companyRating} readonly={true} />
                        ) : (
                            <div className="italic text-slate-500">Not submitted yet</div>
                        )}
                    </dd>
                </div>
            </div>
        </div>
    );
}
