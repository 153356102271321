import { useContext, useState } from "react";
import { motion, useCycle } from "framer-motion";
import { ArrowsUpDownIcon, CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

import { BASIC_ANIMATION_VARIANTS, BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";
import { sortAlphabetically } from "../../../../utils/sorting";
import { sortByBoolean } from "../../../../utils/sorting";
import { ReactComponent as CircleIcon } from "../../../../assets/circle.svg";

import Button from "../../../SharedComponents/Button";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import FatDropdown from "../../../SharedComponents/FatDropdown";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import PickableUserRow from "../AssignAppsAndAssessments/PickableUserRow";
import FancyCheckBox from "../../../SharedComponents/Form/FancyCheckBox";
import BouncingBalls from "../../../SharedComponents/Loading/BouncingBalls";

export default function AssignAssessmentBulkForm(props) {
    const {
        coachees,
        setCoachees,
        coacheesLoading,
        programs,
        cohorts,
        chosenPrograms,
        setChosenPrograms,
        chosenCohorts,
        setChosenCohorts,
        programsLoading,
    } = props;

    const [selectAll, setSelectAll] = useState(false);
    const [sortName, setSortName] = useCycle(false, true);
    const [sortEmail, setSortEmail] = useCycle(false, true);
    const [sortCohort, setSortCohort] = useCycle(false, true);
    const [sortProgram, setSortProgram] = useCycle(false, true);
    const [sortSelected, setSortSelected] = useCycle(false, true);
    const [customItemsPerPage, setCustomItemsPerPage] = useState(16);

    const context = useContext(Context);

    function processChosenItems(item, chosenItems, setChosenItems, type) {
        let updatedChosenItems = [];

        if (chosenItems.some((chosenItem) => chosenItem === item)) {
            updatedChosenItems = chosenItems.filter((chosenItem) => item !== chosenItem);
            setChosenItems(updatedChosenItems);
        } else {
            setChosenItems((oldChosenItems) => [...oldChosenItems, item]);
            updatedChosenItems = [...chosenItems, item];
        }

        processListChanges(type, updatedChosenItems);
    }

    function processListChanges(type, newChosenItems) {
        let updatedCoachees = [...coachees];

        updatedCoachees.forEach((coachee) => {
            let chosenInCohorts = false;
            let chosenInPrograms = false;

            if (type === "program") {
                if (coachee.programs) {
                    newChosenItems.forEach((chosenProgram) => {
                        if (coachee.programs.includes(chosenProgram)) {
                            chosenInPrograms = true;
                        }
                    });

                    if (coachee.cohorts) {
                        chosenCohorts.forEach((chosenCohort) => {
                            if (coachee.cohorts.includes(chosenCohort)) {
                                chosenInCohorts = true;
                            }
                        });
                    }
                }
            } else if (type === "cohort") {
                if (coachee.cohorts) {
                    newChosenItems.forEach((chosenCohort) => {
                        if (coachee.cohorts.includes(chosenCohort)) {
                            chosenInCohorts = true;
                        }
                    });
                    if (coachee.programs) {
                        chosenPrograms.forEach((chosenProgram) => {
                            if (coachee.programs.includes(chosenProgram)) {
                                chosenInPrograms = true;
                            }
                        });
                    }
                }
            }

            coachee.selected = chosenInPrograms || chosenInCohorts;
        });

        setCoachees(updatedCoachees);
    }

    function handleIndividualCoacheePick(selectedCoachee, selected) {
        let updatedCoachees = [...coachees];

        let coachee = updatedCoachees.find((coachee) => coachee.userIdentifier === selectedCoachee.userIdentifier);
        coachee.individuallySelected = selected;
        coachee.selected = selected;

        setCoachees(updatedCoachees);
    }

    function handleSortByTypeTableColumnHeadings(ascending, items, setItems, objectProperty) {
        let sortedItems;

        if (ascending) {
            if (objectProperty === "cohorts" || objectProperty === "programs") {
                sortedItems = sortAlphabetically(items, true, false, objectProperty, 0);
            } else {
                if (objectProperty === "selected") {
                    sortedItems = sortByBoolean(items, true, objectProperty, "individuallySelected");
                } else {
                    sortedItems = sortAlphabetically(items, true, false, objectProperty);
                }
            }
        } else {
            if (objectProperty === "cohorts" || objectProperty === "programs") {
                sortedItems = sortAlphabetically(items, false, false, objectProperty, 0);
            } else {
                if (objectProperty === "selected") {
                    sortedItems = sortByBoolean(items, false, objectProperty, "individuallySelected");
                } else {
                    sortedItems = sortAlphabetically(items, false, false, objectProperty);
                }
            }
        }
        setItems(sortedItems);
    }

    function handleResetSelections() {
        let updatedCoachees = [...coachees];

        updatedCoachees.forEach((coachee) => {
            coachee.selected = false;
            coachee.individuallySelected = false;
        });

        setCoachees(updatedCoachees);
        setSelectAll(false);

        setChosenPrograms([]);
        setChosenCohorts([]);
    }

    function handleSelectAll() {
        let updatedCoachees = [...coachees];

        updatedCoachees.forEach((coachee) => {
            if (!selectAll) {
                coachee.individuallySelected = true;
                coachee.selected = true;
            } else {
                coachee.individuallySelected = false;
                coachee.selected = false;
            }
        });

        setSelectAll(!selectAll);
        setCoachees(updatedCoachees);

        setChosenPrograms([]);
        setChosenCohorts([]);
    }

    return coacheesLoading || programsLoading ? (
        <div className={"col-span-full flex items-center justify-center pt-20 pb-24 w-full"}>
            <BouncingBalls />
        </div>
    ) : (
        <>
            <div className="border border-slate-100 col-span-full" />
            <div className="flex flex-col gap-6 col-span-full">
                <div className="flex flex-row gap-4 justify-between items-center col-span-full">
                    <label className={"text-sm font-medium w-full text-primary-600"}>
                        Select {context.terminology.coachee.singular.toLowerCase()}(s)
                    </label>
                </div>
                <div className="flex flex-col gap-4 col-span-full">
                    <div
                        className={
                            "flex gap-6 col-span-full " +
                            (programs.length <= 8 || cohorts.length <= 8 ? "flex-col" : "flex-row")
                        }
                    >
                        <div className="flex flex-col gap-3 col-span-full">
                            <label className={"text-sm font-medium w-full text-secondary-600"}>Include programs:</label>
                            {programs.length <= 8 ? (
                                <div
                                    className={
                                        "flex flex-row flex-wrap gap-2 text-sm font-title text-primary-800 items-center justify-start " +
                                        "pb-1 print:hidden " +
                                        (programs.length >= 8 ? "h-80" : "h-full")
                                    }
                                >
                                    {programs.map((program, index) => (
                                        <FancyCheckBox
                                            testId={program.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"}
                                            key={index}
                                            labelText={program}
                                            name={program}
                                            colour={"secondary"}
                                            value={chosenPrograms.some((chosenProgram) => chosenProgram === program)}
                                            setValue={() =>
                                                processChosenItems(
                                                    program,
                                                    chosenPrograms,
                                                    setChosenPrograms,
                                                    "program"
                                                )
                                            }
                                        />
                                    ))}
                                </div>
                            ) : (
                                <FatDropdown
                                    text={"Programs"}
                                    content={
                                        <div
                                            className={
                                                "flex flex-col text-sm font-title text-primary-800 justify-start " +
                                                "overflow-y-scroll print:hidden bg-white shadow-lg rounded-xl " +
                                                "border-slate-100 border"
                                            }
                                        >
                                            {programs.map((program, index) => (
                                                <FancyCheckBox
                                                    testId={
                                                        program.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"
                                                    }
                                                    key={index}
                                                    labelText={program}
                                                    value={chosenPrograms.some(
                                                        (chosenProgram) => chosenProgram === program
                                                    )}
                                                    name={program}
                                                    colour={"primary"}
                                                    setValue={() =>
                                                        processChosenItems(
                                                            program,
                                                            chosenPrograms,
                                                            setChosenPrograms,
                                                            "program"
                                                        )
                                                    }
                                                    minimal
                                                />
                                            ))}
                                        </div>
                                    }
                                />
                            )}
                        </div>
                        <div className="flex flex-col gap-3 col-span-full">
                            <label className={"text-sm font-medium w-full text-secondary-600"}>Include cohorts:</label>
                            {cohorts.length <= 8 ? (
                                <div
                                    className={
                                        "flex flex-row flex-wrap gap-2 text-sm font-title text-primary-800 items-center justify-start " +
                                        "pb-1 print:hidden"
                                    }
                                >
                                    {cohorts.map((cohort, index) => (
                                        <FancyCheckBox
                                            testId={cohort.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"}
                                            key={index}
                                            labelText={cohort}
                                            name={cohort}
                                            colour={"secondary"}
                                            value={chosenCohorts.some((chosenCohort) => chosenCohort === cohort)}
                                            setValue={() =>
                                                processChosenItems(cohort, chosenCohorts, setChosenCohorts, "cohort")
                                            }
                                        />
                                    ))}
                                </div>
                            ) : (
                                <FatDropdown
                                    text={"Cohorts"}
                                    content={
                                        <div
                                            className={
                                                "flex flex-col text-sm font-title text-primary-800 justify-start " +
                                                "overflow-y-scroll print:hidden bg-white shadow-lg rounded-xl " +
                                                "border-slate-100 border h-80 "
                                            }
                                        >
                                            {cohorts.map((cohort, index) => (
                                                <FancyCheckBox
                                                    testId={
                                                        cohort.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"
                                                    }
                                                    key={index}
                                                    labelText={cohort}
                                                    value={chosenCohorts.some(
                                                        (chosenCohort) => chosenCohort === cohort
                                                    )}
                                                    name={cohort}
                                                    colour={"secondary"}
                                                    setValue={() =>
                                                        processChosenItems(
                                                            cohort,
                                                            chosenCohorts,
                                                            setChosenCohorts,
                                                            "cohort"
                                                        )
                                                    }
                                                    minimal
                                                />
                                            ))}
                                        </div>
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 col-span-full">
                        <div className="flex flex-row gap-4 justify-between items-center">
                            <label className={"text-sm font-medium w-full text-secondary-600"}>
                                Include individual {context.terminology.coachee.singular.toLowerCase()}
                                (s):
                            </label>
                            <div className="flex flex-row gap-3 items-center">
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.RED}
                                    text={<span className="w-max">Deselect all</span>}
                                    icon={<XMarkIcon className="block h-3 w-3 mr-1.5 stroke-current stroke-1" />}
                                    iconPositionLeft
                                    onClick={() => handleResetSelections()}
                                    smallButton
                                    disabled={
                                        !coachees.some((coachee) => coachee.selected || coachee.individuallySelected)
                                    }
                                />
                                <div className="flex flex-row gap-3 items-center">
                                    <div className={"text-sm font-medium w-max text-secondary-600"}>
                                        Items per page:
                                    </div>
                                    <MultiSelect
                                        items={[
                                            { label: 4, value: 4 },
                                            { label: 8, value: 8 },
                                            { label: 16, value: 16 },
                                            { label: 24, value: 24 },
                                            { label: 32, value: 32 },
                                            { label: 64, value: 64 },
                                        ]}
                                        setChosenItems={setCustomItemsPerPage}
                                        value={customItemsPerPage}
                                        extraStyling="w-max"
                                    />
                                </div>
                            </div>
                        </div>
                        <PaginatedSearch
                            loading={coacheesLoading}
                            items={coachees}
                            itemName={context.terminology.coachee.plural.toLowerCase()}
                            searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                            columnNames={[
                                <div className="relative flex flex-row gap-4 items-center justify-center text-slate-500 cursor-pointer transition-colors select-none w-10">
                                    <div
                                        onClick={() => {
                                            handleSelectAll();
                                        }}
                                    >
                                        {selectAll && (
                                            <motion.div
                                                initial={"inactive"}
                                                animate={"active"}
                                                exit={"inactive"}
                                                transition={{ duration: 0.2 }}
                                                variants={BASIC_ANIMATION_VARIANTS}
                                            >
                                                <CheckCircleIcon className={"block h-6 w-6 text-emerald-600"} />
                                            </motion.div>
                                        )}

                                        {!selectAll && (
                                            <motion.div
                                                initial={"inactive"}
                                                animate={"active"}
                                                exit={"inactive"}
                                                transition={{ duration: 0.2 }}
                                                variants={BASIC_ANIMATION_VARIANTS}
                                            >
                                                <CircleIcon className={"block h-6 w-6 text-slate-300"} />
                                            </motion.div>
                                        )}
                                    </div>
                                    <ArrowsUpDownIcon
                                        onClick={() => {
                                            handleSortByTypeTableColumnHeadings(
                                                sortSelected,
                                                coachees,
                                                setCoachees,
                                                "selected"
                                            );
                                            setSortSelected();
                                        }}
                                        className="absolute top-[6px] left-10 h-3 w-3 stroke-current stroke-1 hover:text-primary-600"
                                    />
                                </div>,
                                <div
                                    className="flex flex-row gap-2 items-center text-slate-500 cursor-pointer hover:text-primary-600 transition-colors select-none"
                                    onClick={() => {
                                        handleSortByTypeTableColumnHeadings(
                                            sortName,
                                            coachees,
                                            setCoachees,
                                            "firstName"
                                        );
                                        setSortName();
                                    }}
                                >
                                    Name <ArrowsUpDownIcon className="h-3 w-3 stroke-current stroke-1" />
                                </div>,
                                <div
                                    className="flex flex-row gap-2 items-center text-slate-500 cursor-pointer hover:text-primary-600 transition-colors select-none"
                                    onClick={() => {
                                        handleSortByTypeTableColumnHeadings(
                                            sortEmail,
                                            coachees,
                                            setCoachees,
                                            "emailAddress"
                                        );
                                        setSortEmail();
                                    }}
                                >
                                    Email <ArrowsUpDownIcon className="h-3 w-3 stroke-current stroke-1" />
                                </div>,
                                <div
                                    className="flex flex-row gap-2 items-center text-slate-500 cursor-pointer hover:text-primary-600 transition-colors select-none"
                                    onClick={() => {
                                        handleSortByTypeTableColumnHeadings(
                                            sortProgram,
                                            coachees,
                                            setCoachees,
                                            "programs"
                                        );
                                        setSortProgram();
                                    }}
                                >
                                    Program <ArrowsUpDownIcon className="h-3 w-3 stroke-current stroke-1" />
                                </div>,
                                <div
                                    className="flex flex-row gap-2 items-center text-slate-500 cursor-pointer hover:text-primary-600 transition-colors select-none"
                                    onClick={() => {
                                        handleSortByTypeTableColumnHeadings(
                                            sortCohort,
                                            coachees,
                                            setCoachees,
                                            "cohorts"
                                        );
                                        setSortCohort();
                                    }}
                                >
                                    Cohort <ArrowsUpDownIcon className={"h-3 w-3 stroke-current stroke-1"} />
                                </div>,
                            ]}
                            ItemRow={PickableUserRow}
                            updateItemStatus={handleIndividualCoacheePick}
                            noContainer
                            inputBackgroundColour="bg-slate-50"
                            customItemsPerPage={customItemsPerPage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
