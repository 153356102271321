import { useNavigate } from "react-router-dom";

import { BUTTON_VARIANT_TYPES } from "../../../utils/constants";
import { ReactComponent as ErrorIllustration } from "../../../assets/stop.svg";

import Button from "../../SharedComponents/Button";
import ErrorPage from "../../SharedComponents/ErrorPage";

export default function NoPermission() {
    const navigate = useNavigate();

    const goToHome = async () => {
        navigate("/");
    };

    const goToPreviousPage = async () => {
        navigate(-1);
    };

    return (
        <ErrorPage
            title={"It seems you don't have the permission(s) required to access this page"}
            subtitle={<div>Please contact your organisation if you think this is wrong.</div>}
            buttons={
                <>
                    <Button text="Previous page" onClick={goToPreviousPage} colour={BUTTON_VARIANT_TYPES.GRAY} />
                    <Button text="Home" onClick={goToHome} colour={BUTTON_VARIANT_TYPES.PRIMARY} />
                </>
            }
            illustration={<ErrorIllustration />}
        />
    );
}
