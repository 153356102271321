export default function FancyRadio(props) {
    const {
        labelText,
        value,
        id,
        setValue,
        colour,
        testId,
        minimal,
        defaultChecked,
        disabled,
        name,
        noBorder,
        handleSort,
    } = props;

    return (
        <div
            data-testid={testId && testId}
            className={
                "flex gap-1.5 items-center cursor-pointer border-2 h-max transition-colors " +
                (noBorder ? "border-b-0 " : " ") +
                (minimal
                    ? "border-t-0 border-x-0 border-b-slate-50 rounded-none w-full px-3 py-2.5 " +
                      (defaultChecked ? "bg-" + colour + "-50 " : " bg-white hover:bg-slate-100 ")
                    : " rounded-xl w-max px-2 py-1.5 ") +
                (defaultChecked
                    ? "bg-" + colour + "-100 border-" + colour + "-600 text-" + colour + "-700 "
                    : " border-slate-200 bg-slate-50 text-slate-500 ")
            }
            onClick={() => {
                setValue();
                handleSort(value);
            }}
        >
            <input
                id={id}
                name={name}
                type="radio"
                className={
                    "focus:outline-none cursor-pointer focus:ring-0 h-4 w-4 shadow-sm " +
                    (defaultChecked
                        ? "text-" + colour + "-500 border-" + colour + "-500"
                        : "text-slate-500 border-slate-500")
                }
                value={value}
                defaultChecked={defaultChecked}
                disabled={disabled}
            />
            <label htmlFor={id} className="cursor-pointer block text-sm font-medium select-none w-max">
                {labelText}
            </label>
        </div>
    );
}
