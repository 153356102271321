import { motion } from "framer-motion";

export default function Slide(props) {
    const { children, currentPage, page } = props;

    const variants = {
        enter: (direction) => {
            return {
                x: direction > 0 ? 100 : -100,
                opacity: 0,
            };
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1,
        },
        exit: (direction) => {
            return {
                zIndex: 0,
                x: direction < 0 ? 100 : -100,
                opacity: 0,
            };
        },
    };

    return (
        <>
            {currentPage === page && (
                <motion.div
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    className={"relative w-full "}
                >
                    {children}
                </motion.div>
            )}
        </>
    );
}
