import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronRightIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";

import {
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    BASIC_ANIMATION_VARIANTS,
    BUTTON_VARIANT_TYPES,
    REPORT_CATEGORIES,
} from "../../../../../../utils/constants";
import { Context } from "../../../../../../context/Context";

import Scale from "../Scale";
import SubScale from "../SubScale";
import Button from "../../../../../SharedComponents/Button";
import BarChart from "../../Charts/CustomCharts/BarChart";
import SideSection from "../../SharedChartComponents/SideSection";
import ReportHeader from "../../SharedChartComponents/ReportHeader";

export default function Interests(props) {
    const { score, onlyContent, graphicalSummaryRef, detailedInterpretationRef, recommendationsRef, coacheeName } =
        props;

    const [showMore, setShowMore] = useState(false);
    const [categories, setCategories] = useState(null);
    const [isNewFormat, setIsNewFormat] = useState(false);
    const [sanitisedScore, setSanitisedScore] = useState(false);

    const location = useLocation();

    const navigate = useNavigate();

    const context = useContext(Context);

    const getBandDescription = (percentileNumber, name) => {
        if (percentileNumber <= 33) {
            return "The individual is low in " + name;
        } else if (percentileNumber < 74) {
            return "The individual is average in " + name;
        } else {
            return "The individual is high in " + name;
        }
    };

    const getCategoryData = (category, i, field) => {
        const categories = REPORT_CATEGORIES;
        return categories[category] ? categories[category][field] : Object.values(categories)[i][field];
    };

    const handleShowMore = () => {
        setShowMore((expand) => !expand);
    };

    useEffect(() => {
        let categories = [];
        let newFormat = false;

        if (score[0] && score[0].category) {
            newFormat = false;
            setIsNewFormat(newFormat);
            categories = score.reduce(
                (prev, curr) => (!prev.includes(curr.category) ? [...prev, curr.category] : prev),
                []
            );
        } else if (score.reportFields[0] && score.reportFields[0].category) {
            newFormat = true;
            setIsNewFormat(newFormat);

            const scoreReportFields = [...score.reportFields];

            categories = scoreReportFields.reduce(
                (prev, curr) => (!prev.includes(curr.category) ? [...prev, curr.category] : prev),
                []
            );
        }
        let sanitizedScore = score;

        if (newFormat === true) {
            sanitizedScore.reportFields.map((field) => {
                field.categoryIndex = categories.indexOf(field.category);
                sanitizedScore.reportFields
                    .filter((score) => score.category === field.category)
                    .map((score, index) => (score.fieldIndex = index));

                return sanitizedScore;
            });
        } else {
            sanitizedScore.map((field) => {
                field.categoryIndex = categories.indexOf(field.category);
                sanitizedScore
                    .filter((score) => score.category === field.category)
                    .map((score, index) => (score.fieldIndex = index));

                return sanitizedScore;
            });
        }

        setCategories(categories);
        setSanitisedScore(sanitizedScore);
    }, [score]);

    return (
        <div className="flex flex-col gap-4">
            <div className="text-primary-600 font-medium">{sanitisedScore?.reportName}</div>
            <div className={onlyContent && "bg-white rounded-xl p-5 print:p-0 print:shadow-none shadow-sm"}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className={"flex flex-col gap-8 " + (onlyContent ? "col-span-full" : "md:col-span-2")}>
                        {!onlyContent && (
                            <ReportHeader
                                reportName={score.reportName ? score.reportName : "Careering Ahead Interests"}
                                userName={location.state?.userName}
                            />
                        )}
                        {sanitisedScore?.reportDescription &&
                            (!Array.isArray(sanitisedScore?.reportDescription) ? (
                                <div className="bg-gradient-to-r from-slate-50 to-primary-50 rounded-xl p-4 shadow-sm">
                                    <div className="bg-white rounded-xl p-4 shadow-sm">
                                        <div className="text-secondary-600 font-medium">Description</div>
                                        <p className="text-slate-500 text-sm">{sanitisedScore.reportDescription}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col gap-2">
                                    <div className="text-secondary-600 font-medium">Description</div>
                                    <div className="text-slate-500 text-sm flex flex-col space-y-4">
                                        {sanitisedScore?.reportDescription.map((description, index) => (
                                            <div key={index}>
                                                <p className="text-slate-500 text-sm">{description.description}</p>

                                                <ul className="list-disc text-sm">
                                                    {description.bullets &&
                                                        description.bullets.map((bullet, index) => (
                                                            <li className="text-slate-500 ml-4" key={index}>
                                                                {bullet}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        {sanitisedScore?.profile && (
                            <div className="bg-gradient-to-r from-slate-50 to-primary-50 rounded-xl p-4 shadow-sm">
                                <div className="text-secondary-600 font-medium flex justify-between">
                                    Your Profile
                                    <UserCircleIcon className="h-5 w-4 ml-1.5" />
                                </div>
                                <div className="bg-white rounded-xl p-4 shadow-sm">
                                    <div className="text-primary-600 text-sm font-medium">{sanitisedScore.profile}</div>
                                    <p className="text-slate-500 text-sm">
                                        {sanitisedScore?.profileInterpretation &&
                                            (!Array.isArray(sanitisedScore?.profileInterpretation)
                                                ? sanitisedScore?.profileInterpretation
                                                : sanitisedScore?.profileInterpretation.map((interpretation, index) => (
                                                      <ul className="list-disc" key={index}>
                                                          <li className="text-slate-500 ml-4">{interpretation}</li>
                                                      </ul>
                                                  )))}
                                    </p>
                                </div>
                                {sanitisedScore?.profileRecommendation && (
                                    <div className="bg-white rounded-xl p-4 shadow-sm">
                                        <div className="text-secondary-600 font-medium">Recommendations</div>
                                        <ul className="list-disc text-sm">
                                            {sanitisedScore?.profileRecommendation.map((recommendation, index) => {
                                                if (recommendation.includes("<br>")) {
                                                    const subsetsRecommendation = recommendation.split("<br>");

                                                    return subsetsRecommendation.map((sub, index) =>
                                                        index === 0 ? (
                                                            <li className="text-slate-500 ml-4" key={index}>
                                                                {sub}
                                                            </li>
                                                        ) : (
                                                            <p className="text-slate-500 ml-4" key={index}>
                                                                {sub}
                                                            </p>
                                                        )
                                                    );
                                                } else {
                                                    return (
                                                        <li className="text-slate-500 ml-4" key={index}>
                                                            {recommendation}
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                        <div ref={graphicalSummaryRef} className="flex flex-col gap-4">
                            <div className="text-secondary-600 font-medium flex flex-row items-center gap-2">
                                Graphical Summary
                            </div>
                            <div className="bg-slate-50 p-4 rounded-xl shadow-sm md:hidden">
                                <div className="space-y-3">
                                    <p className="text-sm font-medium text-secondary-600 ">Legend</p>
                                    <div className="grid grid-cols-2 gap-3">
                                        {categories &&
                                            categories.map((category, index) => (
                                                <div className="flex flex-row space-x-2 items-center" key={index}>
                                                    {getCategoryData(category, index, "icon")}
                                                    <p
                                                        className={
                                                            "font-medium text-xs " +
                                                            getCategoryData(category, index, "text")
                                                        }
                                                    >
                                                        {category}
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            {sanitisedScore &&
                                categories &&
                                categories.map((category, index) => (
                                    <div className={"bg-slate-50 shadow-sm p-4 rounded-xl"} key={index}>
                                        <div className="flex flex-col space-y-3">
                                            <div className="flex justify-between items-center">
                                                <p
                                                    className={
                                                        "font-medium " + getCategoryData(category, index, "text")
                                                    }
                                                >
                                                    {category}
                                                </p>

                                                {getCategoryData(category, index, "icon")}
                                            </div>

                                            {sanitisedScore?.categoriesDescriptions &&
                                                sanitisedScore?.categoriesDescriptions.map(
                                                    (categoryDescription, index) => {
                                                        if (
                                                            categoryDescription.name === category &&
                                                            categoryDescription.description1
                                                        ) {
                                                            return (
                                                                <p className="text-slate-500 pb-2 text-sm" key={index}>
                                                                    {categoryDescription.description1}
                                                                </p>
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                )}

                                            {isNewFormat === false ? (
                                                <BarChart
                                                    data={sanitisedScore
                                                        .filter((scale) => scale.category === category)
                                                        .map((scale) => {
                                                            return {
                                                                name: scale.name,
                                                                category: scale.name,
                                                                percentScore: scale.percentile,
                                                            };
                                                        })}
                                                    percentageAxisLabels={{
                                                        first: "0%",
                                                        second: "25%",
                                                        third: "50%",
                                                        fourth: "75%",
                                                        fifth: "100%",
                                                    }}
                                                    colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                                                    percentage
                                                    labelAddOn="ile"
                                                    percentageAxisMargin
                                                    noTrendLegend
                                                    alwaysDisplayFilterDropdown
                                                />
                                            ) : (
                                                <BarChart
                                                    data={sanitisedScore?.reportFields
                                                        .filter((scale) => scale.category === category)
                                                        .map((scale) => {
                                                            return {
                                                                name: scale.name,
                                                                category: scale.name,
                                                                percentScore: scale.percentile,
                                                            };
                                                        })}
                                                    percentageAxisLabels={{
                                                        first: "0%",
                                                        second: "25%",
                                                        third: "50%",
                                                        fourth: "75%",
                                                        fifth: "100%",
                                                    }}
                                                    colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                                                    percentage
                                                    labelAddOn="ile"
                                                    percentageAxisMargin
                                                    noTrendLegend
                                                    alwaysDisplayFilterDropdown
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div ref={detailedInterpretationRef} className="flex flex-col gap-4">
                            <div className="text-secondary-600 font-medium flex flex-row items-center gap-2">
                                Detailed Interpretation
                            </div>
                            <div className="grid grid-flow-row gap-8">
                                <div className="flex flex-col gap-3">
                                    {isNewFormat === false
                                        ? categories &&
                                          sanitisedScore &&
                                          categories
                                              .map((category) =>
                                                  sanitisedScore.filter((scale) => scale.category === category)
                                              )
                                              .map((scales, index) => {
                                                  const totalScale = scales.filter((scale) =>
                                                      scale.name.toLowerCase().includes("total sanitisedScore")
                                                  );
                                                  const total = totalScale.length === 1 && (
                                                      <div className={"bg-white shadow-sm p-4 rounded-xl"} key={index}>
                                                          <Scale
                                                              name={totalScale[0].name}
                                                              percentile={totalScale[0].percentile}
                                                              description={totalScale[0].description}
                                                              band_description={
                                                                  totalScale[0].band_description ? (
                                                                      <span>{totalScale[0].band_description}</span>
                                                                  ) : (
                                                                      <span>
                                                                          {getBandDescription(
                                                                              totalScale[0].percentile,
                                                                              totalScale[0].name
                                                                          )}
                                                                      </span>
                                                                  )
                                                              }
                                                              colour={getCategoryData(
                                                                  totalScale[0].category,
                                                                  totalScale[0].categoryIndex,
                                                                  "colour"
                                                              )}
                                                              colourPalette={getCategoryData(
                                                                  totalScale[0].category,
                                                                  totalScale[0].categoryIndex,
                                                                  "colourPalette"
                                                              )}
                                                              scaleIndex={totalScale[0].fieldIndex}
                                                          />
                                                      </div>
                                                  );
                                                  return (
                                                      <div
                                                          key={index}
                                                          className={
                                                              "flex flex-col gap-3 bg-slate-50 p-4 rounded-xl shadow-sm"
                                                          }
                                                      >
                                                          <div
                                                              className={
                                                                  "transition transition-color duration-4000 ease-in-out font-medium text-" +
                                                                  getCategoryData(
                                                                      scales[0].category,
                                                                      scales[0].categoryIndex,
                                                                      "colour"
                                                                  )
                                                              }
                                                          >
                                                              <h2>{scales[0].category}</h2>
                                                          </div>

                                                          {total}

                                                          {scales
                                                              .filter(
                                                                  (scale) =>
                                                                      !scale.name
                                                                          .toLowerCase()
                                                                          .includes("total sanitisedScore")
                                                              )
                                                              .map((scale, index) => (
                                                                  <div
                                                                      className={"bg-white shadow-sm p-4 rounded-xl"}
                                                                      key={index}
                                                                  >
                                                                      <SubScale
                                                                          userName={
                                                                              location.state?.userName
                                                                                  ? location.state?.userName
                                                                                  : coacheeName
                                                                          }
                                                                          name={scale.name}
                                                                          percentile={scale.percentile}
                                                                          lowAdjective={"Low"}
                                                                          highAdjective={"High"}
                                                                          description={scale.description}
                                                                          band_description={
                                                                              scale.band_description
                                                                                  ? scale.band_description
                                                                                  : getBandDescription(
                                                                                        scale.percentile,
                                                                                        scale.name
                                                                                    )
                                                                          }
                                                                          colour={getCategoryData(
                                                                              scale.category,
                                                                              scale.categoryIndex,
                                                                              "colour"
                                                                          )}
                                                                          colourPalette={getCategoryData(
                                                                              scale.category,
                                                                              scale.categoryIndex,
                                                                              "colourPalette"
                                                                          )}
                                                                          scaleIndex={scale.fieldIndex}
                                                                      />
                                                                  </div>
                                                              ))}
                                                      </div>
                                                  );
                                              })
                                        : categories &&
                                          sanitisedScore &&
                                          categories
                                              .map((category) =>
                                                  sanitisedScore?.reportFields.filter(
                                                      (scale) => scale.category === category
                                                  )
                                              )
                                              .map((scales, index) => {
                                                  const totalScale = scales.filter((scale) =>
                                                      scale.name.toLowerCase().includes("total sanitisedScore")
                                                  );
                                                  const total = totalScale.length === 1 && (
                                                      <div className={"bg-white shadow-sm p-4 rounded-xl"} key={index}>
                                                          <Scale
                                                              name={totalScale[0].name}
                                                              percentile={totalScale[0].percentile}
                                                              description={totalScale[0].description}
                                                              band_description={
                                                                  <span>{totalScale[0].band_description}</span>
                                                              }
                                                              colour={getCategoryData(
                                                                  totalScale[0].category,
                                                                  totalScale[0].categoryIndex,
                                                                  "colour"
                                                              )}
                                                              colourPalette={getCategoryData(
                                                                  totalScale[0].category,
                                                                  totalScale[0].categoryIndex,
                                                                  "colourPalette"
                                                              )}
                                                              scaleIndex={totalScale[0].fieldIndex}
                                                          />
                                                      </div>
                                                  );
                                                  return (
                                                      <div
                                                          key={index}
                                                          className={
                                                              "flex flex-col gap-3 bg-slate-50 p-4 rounded-xl shadow-sm"
                                                          }
                                                      >
                                                          <div
                                                              className={
                                                                  "transition transition-color duration-4000 ease-in-out font-medium text-" +
                                                                  getCategoryData(
                                                                      scales[0].category,
                                                                      scales[0].categoryIndex,
                                                                      "colour"
                                                                  )
                                                              }
                                                          >
                                                              <h2>{scales[0].category}</h2>
                                                          </div>

                                                          {sanitisedScore?.categoriesDescriptions &&
                                                              sanitisedScore?.categoriesDescriptions.map(
                                                                  (categoryDescription, index) => {
                                                                      if (
                                                                          categoryDescription.name ===
                                                                              scales[0].category &&
                                                                          categoryDescription.description2
                                                                      ) {
                                                                          return (
                                                                              <p
                                                                                  className="text-sm text-slate-500 pb-3"
                                                                                  key={index}
                                                                              >
                                                                                  {categoryDescription.description2}
                                                                              </p>
                                                                          );
                                                                      }
                                                                      return null;
                                                                  }
                                                              )}

                                                          {total}

                                                          {scales
                                                              .filter(
                                                                  (scale) =>
                                                                      !scale.name
                                                                          .toLowerCase()
                                                                          .includes("total sanitisedScore")
                                                              )
                                                              .map((scale, index) => (
                                                                  <div
                                                                      className={"bg-white shadow-sm p-4 rounded-xl"}
                                                                      key={index}
                                                                  >
                                                                      <SubScale
                                                                          userName={
                                                                              location.state?.userName
                                                                                  ? location.state?.userName
                                                                                  : coacheeName
                                                                          }
                                                                          name={scale.name}
                                                                          percentile={scale.percentile}
                                                                          lowAdjective={"Low"}
                                                                          highAdjective={"High"}
                                                                          description={scale.description}
                                                                          band_description={
                                                                              scale.band_description
                                                                                  ? scale.band_description
                                                                                  : getBandDescription(
                                                                                        scale.percentile,
                                                                                        scale.name
                                                                                    )
                                                                          }
                                                                          colour={getCategoryData(
                                                                              scale.category,
                                                                              scale.categoryIndex,
                                                                              "colour"
                                                                          )}
                                                                          colourPalette={getCategoryData(
                                                                              scale.category,
                                                                              scale.categoryIndex,
                                                                              "colourPalette"
                                                                          )}
                                                                          scaleIndex={scale.fieldIndex}
                                                                      />
                                                                  </div>
                                                              ))}
                                                      </div>
                                                  );
                                              })}
                                </div>
                            </div>
                        </div>
                        {sanitisedScore?.recommendation && (
                            <div ref={recommendationsRef} className="flex flex-col gap-4">
                                <div className="text-secondary-600 font-medium flex flex-row items-center gap-2">
                                    Recommendations
                                </div>
                                <div className="flex flex-col gap-6">
                                    <div className="flex flex-col gap-6">
                                        <p className="text-sm text-slate-500">{sanitisedScore?.recommendation?.text}</p>
                                        <div className="bg-white overflow-x-auto relative rounded-xl overflow-clip shadow-sm border border-slate-100 border-1 text-sm text-slate-500">
                                            <table className={"min-w-full"}>
                                                <tbody>
                                                    {sanitisedScore?.recommendation?.table.map((tableField, index) => (
                                                        <tr key={index}>
                                                            <td className="px-4 py-3 whitespace-nowrap">
                                                                <span className="text-xs uppercase text-primary-600 font-medium">
                                                                    {tableField.jobZone}
                                                                </span>
                                                            </td>
                                                            <td className="px-4 py-3">{tableField.description}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="bg-white overflow-x-auto relative rounded-xl overflow-clip shadow-sm border border-slate-100 border-1 text-sm text-slate-500">
                                        <table className={"min-w-full"}>
                                            <thead className={"bg-slate-50"}>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase"
                                                    >
                                                        Job Title
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase"
                                                    >
                                                        Job Interest Areas
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase"
                                                    >
                                                        Job Zone
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase"
                                                    >
                                                        Job Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {sanitisedScore?.recommendedJobs.map((recommendedJob, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            {recommendedJob?.jobCode ? (
                                                                <th
                                                                    scope="col"
                                                                    className="link text-left hover:text-primary-600 cursor-pointer px-4 py-3 underline"
                                                                    onClick={() => {
                                                                        navigate("/recommended-job", {
                                                                            state: {
                                                                                recommendedJobCode:
                                                                                    recommendedJob.jobCode,
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    {recommendedJob.jobTitle}
                                                                </th>
                                                            ) : (
                                                                <th scope="col" className="text-primary-500 px-4 py-3">
                                                                    {recommendedJob.jobTitle}
                                                                </th>
                                                            )}

                                                            <td className="px-4 py-3 text-slate-500">
                                                                {recommendedJob.firstJobInterestArea},{" "}
                                                                {recommendedJob.secondJobInterestArea}
                                                            </td>
                                                            <td className="px-4 py-3 text-primary-600 font-medium">
                                                                {recommendedJob.jobZone}
                                                            </td>
                                                            <td className="px-4 py-3 text-slate-500">
                                                                <div className={"flex flex-col gap-2"}>
                                                                    <Button
                                                                        colour={
                                                                            context.isNativeApps
                                                                                ? BUTTON_VARIANT_TYPES.SECONDARY
                                                                                : BUTTON_VARIANT_TYPES.DARK_SECONDARY
                                                                        }
                                                                        icon={
                                                                            <ChevronRightIcon
                                                                                className={
                                                                                    "h-4 w-4 stroke-current stroke-1 transition-transform transform " +
                                                                                    (showMore && "rotate-90")
                                                                                }
                                                                            />
                                                                        }
                                                                        extraStyling={
                                                                            "text-xs h-8 w-8 rounded-full p-0 items-center flex justify-center"
                                                                        }
                                                                        iconPositionLeft
                                                                        onClick={handleShowMore}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <AnimatePresence>
                                                            {showMore && (
                                                                <tr className={"transition-colors"}>
                                                                    <td
                                                                        className="bg-slate-100 px-4 py-3"
                                                                        colSpan={"100%"}
                                                                    >
                                                                        <motion.div
                                                                            initial={"inactiveHeight"}
                                                                            animate={"activeHeight"}
                                                                            exit={"inactiveHeight"}
                                                                            transition={{ duration: 0.4 }}
                                                                            variants={BASIC_ANIMATION_VARIANTS}
                                                                        >
                                                                            {recommendedJob.jobDetails}
                                                                        </motion.div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </AnimatePresence>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!onlyContent && (
                        <SideSection
                            extraContent={
                                <div className="bg-slate-50 p-4 rounded-xl shadow-sm hidden md:flex">
                                    <div className="flex flex-col gap-3">
                                        <p className="text-sm font-medium text-secondary-600">Legend</p>
                                        <div className="grid grid-cols-2 gap-3">
                                            {categories &&
                                                categories.map((category, index) => (
                                                    <div className="flex flex-row items-center space-x-2" key={index}>
                                                        {getCategoryData(category, index, "icon")}
                                                        <p
                                                            className={
                                                                "text-xs font-medium " +
                                                                getCategoryData(category, index, "text")
                                                            }
                                                        >
                                                            {category}
                                                        </p>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
