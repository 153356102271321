import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";

export default function CareerToDatePieCharts(props) {
    const data01 = [
        { name: "General management", value: 400 },
        { name: "Policy", value: 400 },
        { name: "Professional", value: 400 },
        { name: "Specialist", value: 400 },
        { name: "Chieftain", value: 400 },
        { name: "Bow and arrow", value: 400 },
    ];

    let renderLabel = function (entry) {
        return entry.name;
    };

    return (
        <div className="grid grid-cols-2 gap-6 items-center mt-8">
            <div className={"h-64 text-xs text-center"}>
                <label className="text-sm font-medium text-slate-500 w-full ">Breadth of experience</label>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            data={data01}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label={renderLabel}
                        />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className={"h-64 text-xs text-center"}>
                <label className="text-sm font-medium text-slate-500 w-full ">Industry sector experience</label>
                <ResponsiveContainer>
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            data={data01}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label={renderLabel}
                        />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className={"col-span-2 h-64 text-xs text-center"}>
                <label className="text-sm font-medium text-slate-500 w-full ">Career movements</label>
                <ResponsiveContainer>
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            data={data01}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label={renderLabel}
                        />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
