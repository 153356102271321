import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getUserDetails, updateUserDetails, uploadUserMedia } from "../../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../../utils/constants";
import { Context } from "../../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import UploadPhoto from "../../Profile/UploadPhoto";
import UploadVideo from "../../Profile/UploadVideo";
import CoachDetails from "./CoachDetails";
import CoachSessions from "./CoachSessions";
export default function CoachProfile() {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [mediaFile, setMediaFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [coachDetailsLoading, setCoachDetailsLoading] = useState(false);
    const [coachDetails, setCoachDetails] = useState({});
    const [coachAccreditations, setCoachAccreditations] = useState([]);
    const [coachLocations, setCoachLocations] = useState([]);
    const [organisationLocations, setOrganisationLocations] = useState([]);
    const [canEdit, setCanEdit] = useState(false);

    const context = useContext(Context);

    const location = useLocation();

    const navigate = useNavigate();

    const fetchCoachDetails = useCallback(
        async (userIdentifier) => {
            setCoachAccreditations([]);
            setCoachLocations([]);
            setCoachDetailsLoading(true);

            try {
                const response = await getUserDetails(userIdentifier);
                setCoachDetails(response);

                if (response.accreditations) {
                    response.accreditations.forEach((accreditation) => {
                        setCoachAccreditations((accreditations) => [
                            {
                                value: accreditation,
                                label: accreditation,
                            },
                            ...accreditations,
                        ]);
                    });
                }

                const sampleCoachLocations = ["Melbourne", "Geelong"];

                sampleCoachLocations.forEach((location) => {
                    setCoachLocations((locations) => [
                        {
                            value: location,
                            label: location,
                        },
                        ...locations,
                    ]);
                });
            } catch (error) {
                setAlertType(ALERT_TYPE.ERROR);
                setAlert(error.message);
            }
            setCoachDetailsLoading(false);
        },
        [setCoachDetails]
    );

    const fetchOrganisationLocations = useCallback(async () => {
        setLoading(true);
        try {
            const sampleOrganisationLocations = ["Footscray", "Melbourne", "Kew", "Balwyn North", "Coburg", "Kooyong"];

            sampleOrganisationLocations.forEach((location) => {
                setOrganisationLocations((locations) => [
                    {
                        value: location,
                        label: location,
                    },
                    ...locations,
                ]);
            });
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, []);

    const handleUploadFile = async () => {
        setLoading(true);
        try {
            await uploadUserMedia(null, mediaFile[0], coachDetails.userIdentifier);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully uploaded file");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setLoading(false);
    };

    const handleUpdateProfile = async () => {
        setLoading(true);

        const selectedAccreditations = [];
        const selectedLocations = [];

        coachAccreditations.map((accreditation) => selectedAccreditations.push(accreditation.value));
        coachLocations.map((location) => selectedLocations.push(location.value));

        try {
            await updateUserDetails(
                coachDetails.userIdentifier,
                coachDetails.emailAddress,
                coachDetails.firstName,
                coachDetails.lastName,
                coachDetails.mobileNumber,
                coachDetails.dateOfBirth,
                coachDetails.educations,
                coachDetails.qualifications,
                coachDetails.interests,
                selectedAccreditations,
                coachDetails.cohorts,
                coachDetails.programs,
                coachDetails.biography,
                coachDetails.experienceYears,
                coachDetails.userGroups
            );

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully updated " + coachDetails.firstName + " 's profile");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!context.permissionsLoading) {
            setCanEdit(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.EDIT_OWN_PROFILE));
        }
    }, [context.permissionsLoading, context.userPermissions]);

    useEffect(() => {
        if (location.state === null) {
            navigate(`/${context.terminology.coach.plural.toLowerCase()}`);
        } else {
            if (location.state.tabIndex) {
                setTabIndex(location.state.tabIndex);
            }

            if (location.state.userIdentifier) {
                fetchCoachDetails(location.state.userIdentifier);
            }

            fetchOrganisationLocations();
        }
    }, [context.terminology.coach.plural, fetchCoachDetails, location.state, navigate, fetchOrganisationLocations]);

    return (
        <>
            {location.state !== null && (
                <DashboardLayout
                    title={
                        (context.terminology.coach ? context.terminology.coach.singular : "Coach") +
                        " profile: " +
                        coachDetails.firstName +
                        " " +
                        coachDetails.lastName
                    }
                    requiredPermissions={[]}
                    loading={coachDetailsLoading}
                >
                    <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                    <TabbedNavigationLayout
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabList={[
                            (context.terminology.coach ? context.terminology.coach.singular : "Coach") + " details",
                            "Sessions",
                            "Upload profile photo",
                            "Upload profile video",
                        ]}
                        loading={loading}
                        backgroundColour={"bg-slate-100"}
                        tabsRowOnly
                        tabContent={[
                            {
                                title:
                                    (context.terminology.coach ? context.terminology.coach.singular : "Coach") +
                                    " details",
                                onSaveChanges: canEdit && handleUpdateProfile,
                                content: (
                                    <CoachDetails
                                        uploadProfilePhoto={() => setTabIndex(2)}
                                        coachDetails={coachDetails}
                                        setCoachDetails={setCoachDetails}
                                        canEdit={canEdit}
                                        userIdentifier={location.state.userIdentifier}
                                        coachAccreditations={coachAccreditations}
                                        setCoachAccreditations={setCoachAccreditations}
                                        coachLocations={coachLocations}
                                        setCoachLocations={setCoachLocations}
                                        organisationLocations={organisationLocations}
                                        coachDetailsLoading={coachDetailsLoading}
                                        setAlert={setAlert}
                                        setAlertType={setAlertType}
                                    />
                                ),
                            },
                            {
                                title: "Sessions",
                                content: <CoachSessions coachIdentifier={coachDetails.userIdentifier} />,
                                readonly: true,
                            },
                            {
                                title: "Upload profile photo",
                                onSaveChanges: canEdit && handleUploadFile,
                                content: <UploadPhoto mediaFile={mediaFile} setMediaFile={setMediaFile} />,
                                buttonText: "Upload photo",
                                upload: true,
                            },
                            {
                                title: "Upload profile video",
                                onSaveChanges: canEdit && handleUploadFile,
                                content: <UploadVideo mediaFile={mediaFile} setMediaFile={setMediaFile} />,
                                buttonText: "Upload video",
                                upload: true,
                            },
                        ]}
                    />
                </DashboardLayout>
            )}
        </>
    );
}
