import { InformationCircleIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

export default function Textarea(props) {
    const {
        id,
        label,
        placeholder,
        onChange,
        customOnChange,
        onKeyPress,
        value,
        defaultValue,
        optional,
        disabled,
        extraStyling,
        backgroundColour,
        hidden,
    } = props;

    return (
        <div className={(hidden ? "hidden " : "") + "relative flex flex-col gap-2 " + extraStyling}>
            <div className={"flex justify-between w-full"}>
                <label htmlFor={id} className="text-sm font-medium text-primary-600 w-full ">
                    {label}
                </label>
            </div>
            <Tippy
                content={
                    <div className="flex flex-row gap-1 items-center">
                        <InformationCircleIcon className="h-3 w-3" />
                        Read-only
                    </div>
                }
                className={"py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 text-white"}
                arrow={false}
                animation={"shift-away-subtle"}
                hideOnClick={false}
                disabled={!disabled}
                placement="top-end"
            >
                <span className="w-full">
                    <textarea
                        className={
                            "w-full px-3 py-2 focus:outline-none focus:ring-2 " +
                            "border border-slate-100 border-1 " +
                            "focus:ring-primary-300 focus:border-transparent sm:text-sm focus:ring-inset " +
                            "text-slate-500 shadow-sm rounded-xl " +
                            (backgroundColour ? backgroundColour + " " : "bg-slate-50 ") +
                            (disabled && "disabled:opacity-70")
                        }
                        id={id}
                        onChange={customOnChange ? customOnChange : onChange ? (e) => onChange(e.target.value) : null}
                        autoComplete={id}
                        placeholder={placeholder}
                        onKeyPress={onKeyPress ? (e) => onKeyPress(e.key) : null}
                        value={value}
                        defaultValue={defaultValue}
                        disabled={disabled && disabled}
                        rows="3"
                    />
                </span>
            </Tippy>
            {optional && <div className={"text-xs text-slate-400 w-full text-right right-0"}>Optional</div>}
        </div>
    );
}
