import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    CalendarIcon,
    ClockIcon,
    ArrowDownTrayIcon,
    KeyIcon,
    MapPinIcon,
    PaperClipIcon,
    ExclamationTriangleIcon,
    ArrowTopRightOnSquareIcon,
    DocumentDuplicateIcon,
} from "@heroicons/react/24/solid";
import mime from "mime";

import { downloadResourceFile, getAllResourceRatings } from "../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, RESOURCE_TYPES } from "../../../../utils/constants";

import Button from "../../../SharedComponents/Button";
import StarRating from "../../../SharedComponents/StarRating";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import BasicMotion from "../../../SharedComponents/Motion/BasicMotion";
import { Context } from "../../../../context/Context";
import Tag from "../../../SharedComponents/Tag";
import TippyComponent from "../../../SharedComponents/TippyComponent";
import Tippy from "@tippyjs/react";

export default function ResourceCard(props) {
    const { item: resource, updateItemStatus } = props;

    const context = useContext(Context);
    const navigate = useNavigate();

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [loading, setLoading] = useState(true);
    const [allResourceRatings, setAllResourceRatings] = useState([]);
    const [ownRating, setOwnRating] = useState(0);
    const [downloading, setDownloading] = useState([]);
    const [copySuccess, setCopySuccess] = useState(false);

    const fetchAllResourceRatings = useCallback(async () => {
        setLoading(true);

        try {
            const response = await getAllResourceRatings(resource.identifier);
            setAllResourceRatings(response);
        } catch (err) {
            setAlertType(ALERT_TYPE.ERROR);

            context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_RATINGS) && setAlert(err.message);

            setLoading(false);
        }

        setLoading(false);
    }, [context.userPermissions, resource.identifier]);

    const handleResourceFileDownload = async (mediaFileIdentifier) => {
        const isDownloading = (status) => {
            let downloadingStatus = downloading.slice();
            status === true
                ? (downloadingStatus[mediaFileIdentifier] = true)
                : (downloadingStatus[mediaFileIdentifier] = false);
            setDownloading(downloadingStatus);
        };

        isDownloading(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DOWNLOAD_MEDIA_FILES)) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("You do not have the necessary permission to download a resource attachment");
            isDownloading(false);
            return;
        }

        let mediaFileResponse = null;

        try {
            mediaFileResponse = await downloadResourceFile(resource.identifier, mediaFileIdentifier);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
            isDownloading(false);
        }

        const { fileType } = mediaFileResponse;

        if (!fileType) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry there was an error while processing media file response, please try again later or contact techsupport@psychpress.com.au"
            );
            isDownloading(false);
            return;
        }

        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry there was an error while processing media file type, please try again later or contact techsupport@psychpress.com.au"
            );
            isDownloading(false);
            return;
        }

        let blob = await (await fetch(`data:${mimeType};base64,${mediaFileResponse.content}`)).blob();

        if (!blob) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry there was an error downloading attachment file, please try again later or contact techsupport@psychpress.com.au"
            );
            isDownloading(false);
            return;
        }

        const downloadLink = document.createElement("a");

        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = mediaFileResponse.fileName.split(".").join("_");
        downloadLink.click();

        isDownloading(false);
    };

    const copyToClipboard = (resourceLink) => {
        const result = navigator.clipboard.writeText(resourceLink);

        result
            .then(() => {
                setCopySuccess(true);
                return true;
            })
            .catch((err) => {
                setCopySuccess(false);
                return false;
            });
    };

    const resetCopyToClipboard = () => {
        setCopySuccess(false);
    };

    useEffect(() => {
        fetchAllResourceRatings();
    }, [fetchAllResourceRatings]);

    useEffect(() => {
        const myRating = allResourceRatings.filter(
            (rating) => rating.userIdentifier === context.profileDetails.userIdentifier
        );
        const numberOfStars = myRating.length > 0 ? myRating[0].stars : 0;
        setOwnRating(numberOfStars);
    }, [allResourceRatings, context.profileDetails.userIdentifier]);

    return (
        <div
            className={
                "bg-white hover:shadow-lg transition-shadow shadow-sm overflow-hidden rounded-xl group border " +
                "border-slate-100 border-1"
            }
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} className="m-3" />
            <div className="p-4 flex flex-col gap-3 text-slate-500 cursor-pointer">
                <div className="flex justify-start items-center gap-4 w-full">
                    <div
                        className="flex justify-center items-center bg-secondary-50 rounded-xl shadow-sm border border-secondary-100 
                            text-secondary-600 group-hover:text-secondary-700 group-hover:border-secondary-200 font-medium text-lg w-20 h-20 shrink-0"
                    >
                        {resource.title.includes("-") && resource.title.split("-")[0].split(" ").length >= 2
                            ? resource.title
                                  .split("-")[0]
                                  .split(" ")
                                  .slice(0, 2)
                                  .map((word) => word.charAt(0))
                                  .join("")
                            : resource.title.charAt(0)}
                    </div>
                    <div className="flex flex-col space-y-0.5">
                        <h3
                            className={
                                "line-clamp-2 text-base font-medium font-title cursor-pointer transition " +
                                "transition-colors tracking-tight text-primary-600 group-hover:text-primary-700"
                            }
                            onClick={() =>
                                navigate("/resource", {
                                    state: {
                                        resource: resource,
                                        ownRating: ownRating,
                                        allResourceRatings: allResourceRatings,
                                    },
                                })
                            }
                        >
                            {resource.title}
                        </h3>

                        <div className="pt-1">
                            <Tag status={resource.resourceType} isResource={true} />
                        </div>
                    </div>
                </div>
                {resource?.subtitle && (
                    <p className={"text-sm text-slate-400 group-hover:text-slate-500 transition-colors line-clamp-2"}>
                        {resource.subtitle}
                    </p>
                )}
                {/** Might be used in the future
                {resource.resourceType === RESOURCE_TYPES.COURSE && (
                    <RectangleStackIcon
                        className={"flex-shrink-0 h-7 w-7 text-amber-600 group-hover:text-amber-700 transition-colors"}
                    />
                )}

                {resource.resourceType === RESOURCE_TYPES.READING && (
                    <BookOpenIcon
                        className={"flex-shrink-0 h-7 w-7 text-pink-600 group-hover:text-pink-700 transition-colors"}
                    />
                )}

                {resource.resourceType === RESOURCE_TYPES.WORKSHOP && (
                    <PuzzlePieceIcon
                        className={
                            "flex-shrink-0 h-7 w-7 text-purple-600 group-hover:text-purple-700 transition-colors"
                        }
                    />
                )}
                */}
            </div>

            <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 ">Uploaded by</dt>
                <dd className="mt-1 sm:mt-0 sm:col-span-2 flex gap-2 justify-between">
                    <div className={"flex gap-2"}>
                        {resource.isAssigned === true && resource.isOrgResource === false ? (
                            <Tag status={"Organisation member"} />
                        ) : (
                            <Tag status={resource.isOrgResource ? "Organisation" : "You"} />
                        )}
                    </div>
                </dd>
            </div>

            <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 flex gap-2 items-center ">Access</dt>
                <TippyComponent
                    text={"No permission"}
                    icon={<ExclamationTriangleIcon className="h-3 w-3" />}
                    backgroundColour="bg-red-600"
                    disabled={context.userPermissions.includes(
                        COACH_PLATFORM_PERMISSIONS.ASSIGN_OWN_RESOURCES ||
                            COACH_PLATFORM_PERMISSIONS.ASSIGN_ORG_RESOURCES
                    )}
                    placement="right"
                >
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        icon={<KeyIcon className="flex-shrink-0 h-3.5 w-3.5 mr-2" />}
                        text={"Edit access"}
                        extraStyling={"text-xs text-white px-2 py-1.5 z-50 mt-1 sm:mt-0"}
                        iconPositionLeft
                        smallButton
                        onClick={() =>
                            navigate("/resource", {
                                state: {
                                    tabIndex: 1,
                                    resource: resource,
                                    ownRating: ownRating,
                                    allResourceRatings: allResourceRatings,
                                },
                            })
                        }
                        disabled={
                            !context.userPermissions.includes(
                                COACH_PLATFORM_PERMISSIONS.ASSIGN_OWN_RESOURCES ||
                                    COACH_PLATFORM_PERMISSIONS.ASSIGN_ORG_RESOURCES
                            )
                        }
                    />
                </TippyComponent>
            </div>

            <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 ">Your rating</dt>
                <dd className="mt-1 sm:mt-0 sm:col-span-2 flex gap-2 justify-between items-center">
                    <BasicMotion>
                        <StarRating
                            setOwnRating={setOwnRating}
                            starsRating={ownRating}
                            updateItemStatus={updateItemStatus}
                            resourceIdentifier={resource.identifier}
                            setAlert={setAlert}
                            setAlertType={setAlertType}
                            fetchAllResourceRatings={fetchAllResourceRatings}
                        />
                    </BasicMotion>
                </dd>
            </div>

            <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 ">Overall rating</dt>

                <dd className="mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                    {!loading ? (
                        <BasicMotion>
                            {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_RATINGS) ? (
                                <div className="flex flex-row gap-2 items-center">
                                    <StarRating
                                        starsRating={
                                            context.userPermissions.includes(
                                                COACH_PLATFORM_PERMISSIONS.VIEW_ALL_RATINGS
                                            )
                                                ? resource.ratingStars
                                                : 0
                                        }
                                        readonly={true}
                                        resourceIdentifier={resource.identifier}
                                    />

                                    <span className={"text-sm font-medium text-slate-500"}>
                                        ({allResourceRatings.length})
                                    </span>
                                </div>
                            ) : (
                                <span className={"text-xs text-slate-400 italic "}>
                                    You do not have the necessary permission(s) to view ratings
                                </span>
                            )}
                        </BasicMotion>
                    ) : (
                        <span className={"text-xs text-slate-400 italic animate-pulse"}>Loading..</span>
                    )}
                </dd>
            </div>

            <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500">Description</dt>
                <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2 overflow-y-scroll max-h-32">
                    {!resource.description ? (
                        <span className={"text-xs text-slate-400 italic"}>Description is empty</span>
                    ) : (
                        resource.description
                    )}
                </dd>
            </div>

            {resource.resourceType === RESOURCE_TYPES.READING && resource.durationMins && (
                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-slate-500 ">Additional information</dt>
                    <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2 space-y-2">
                        <div className="text-sm font-medium text-pink-600">Estimated reading duration</div>
                        <li className="text-sm text-slate-500 flex items-center mt-2">
                            <ClockIcon className="h-4 w-4 text-slate-500 mr-2" />
                            {resource.durationMins} {resource.durationMins === 1 ? "minute" : "minutes"}
                        </li>
                    </dd>
                </div>
            )}

            {resource.resourceType === RESOURCE_TYPES.WORKSHOP &&
                (resource.startTime || resource.durationMins || resource.location) && (
                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-slate-500 ">Additional information</dt>
                        <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2 space-y-2">
                            {resource.startTime && (
                                <div>
                                    <div className="text-sm font-medium text-purple-600">Workshop date time</div>
                                    <li className="text-sm text-slate-500 flex items-center mt-2">
                                        <CalendarIcon className="h-4 w-4 text-slate-500 mr-2" />
                                        {new Date(resource.startTime).toLocaleString([], { hour12: true })}
                                    </li>
                                </div>
                            )}

                            {resource.durationMins && (
                                <div>
                                    <div className="text-sm font-medium text-purple-600">Workshop duration</div>
                                    <li className="text-sm text-slate-500 flex items-center mt-2">
                                        <ClockIcon className="h-4 w-4 text-slate-500 mr-2" />
                                        {resource.durationMins} {resource.durationMins === 1 ? "minute" : "minutes"}
                                    </li>
                                </div>
                            )}

                            {resource.location && (
                                <div>
                                    <div className="text-sm font-medium text-purple-600">Workshop location</div>
                                    <li className="text-sm text-slate-500 flex items-center mt-2">
                                        <MapPinIcon className="h-4 w-4 text-slate-500 mr-2" />
                                        {resource.location}
                                    </li>
                                </div>
                            )}
                        </dd>
                    </div>
                )}

            {resource.resourceType === RESOURCE_TYPES.COURSE &&
                (resource.startTime || resource.durationMins || resource.location) && (
                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-slate-500 ">Additional information</dt>
                        <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-2 space-y-2">
                            {resource.startTime && (
                                <div>
                                    <div className="text-sm font-medium text-amber-600">Course date time</div>
                                    <li className="text-sm text-slate-500 flex items-center mt-2">
                                        <CalendarIcon className="h-4 w-4 text-slate-500 mr-2" />
                                        {new Date(resource.startTime).toLocaleString([], { hour12: true })}
                                    </li>
                                </div>
                            )}

                            {resource.durationMins && (
                                <div>
                                    <div className="text-sm font-medium text-amber-600">Course duration</div>
                                    <li className="text-sm text-slate-500 flex items-center mt-2">
                                        <ClockIcon className="h-4 w-4 text-slate-500 mr-2" />
                                        {resource.durationMins} {resource.durationMins === 1 ? "minute" : "minutes"}
                                    </li>
                                </div>
                            )}

                            {resource.location && (
                                <div>
                                    <div className="text-sm font-medium text-amber-600">Course location</div>
                                    <li className="text-sm text-slate-500 flex items-center mt-2">
                                        <MapPinIcon className="h-4 w-4 text-slate-500 mr-2" />
                                        {resource.location}
                                    </li>
                                </div>
                            )}
                        </dd>
                    </div>
                )}

            <div
                className={
                    resource.startTime || resource.durationMins || resource.location
                        ? "bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4"
                        : "bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4"
                }
            >
                <dt className="text-sm font-medium text-slate-500">Links</dt>
                <dd className="mt-1 text-sm text-secondary-600 sm:mt-0 sm:col-span-2 max-h-36">
                    {resource.resourceLinks === null || resource.resourceLinks.length < 1 ? (
                        <span className={"text-xs text-slate-400 italic"}>This resources has no links</span>
                    ) : (
                        <ul className="space-y-2 overflow-y-scroll max-h-20">
                            {resource.resourceLinks.map((resourceLink, index) => (
                                <li key={index} className="flex text-sm justify-between items-center">
                                    <div className="w-full flex-1 flex items-center">
                                        <Tippy
                                            content={"Open external link"}
                                            className={
                                                "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-secondary-600 text-white"
                                            }
                                            arrow={false}
                                            animation={"shift-away-subtle"}
                                            placement={"top-start"}
                                            hideOnClick={false}
                                        >
                                            <a
                                                href={resourceLink.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="truncate hover:text-secondary-700 underline bg-secondary-50 p-1 px-1.5 rounded-lg"
                                            >
                                                <div className="flex items-center space-x-1">
                                                    <span className="truncate">
                                                        {resourceLink?.name ? resourceLink.name : resourceLink.url}
                                                    </span>
                                                    <ArrowTopRightOnSquareIcon className="shrink-0 h-3.5 w-3.5 self-start stroke-current stroke-.5" />
                                                </div>
                                            </a>
                                        </Tippy>

                                        <Tippy
                                            content={copySuccess === true ? "Copied!" : "Click to copy URL"}
                                            className={
                                                "py-1.5 px-2 shadow rounded-2xl text-xs font-medium text-white " +
                                                (copySuccess ? "bg-green-500" : "bg-secondary-600")
                                            }
                                            arrow={false}
                                            animation={"shift-away-subtle"}
                                            placement={"top-start"}
                                            hideOnClick={false}
                                        >
                                            <DocumentDuplicateIcon
                                                className={
                                                    "shrink-0 block h-4 w-4 ml-1 cursor-pointer hover:text-secondary-700"
                                                }
                                                onClick={() => copyToClipboard(resourceLink.url)}
                                                onMouseOut={resetCopyToClipboard}
                                            />
                                        </Tippy>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </dd>
            </div>

            <div
                className={
                    resource.startTime || resource.durationMins || resource.location
                        ? "bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 h-full"
                        : "bg-slate-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 h-full"
                }
            >
                <dt className="text-sm font-medium text-slate-500 ">Attachments</dt>

                <dd className="mt-1 text-sm text-slate-600 sm:mt-0 sm:col-span-2">
                    {resource.mediaFiles === null || resource.mediaFiles.length < 1 ? (
                        <span className={"text-xs text-slate-400 italic "}>This resource has no attachments</span>
                    ) : (
                        <ul className="space-y-3 overflow-y-scroll max-h-24">
                            {resource.mediaFiles &&
                                resource.mediaFiles.map((mediaFile, index) => (
                                    <li key={index} className="flex text-sm space-between items-center">
                                        <div className="w-0 flex-1 flex items-center">
                                            <PaperClipIcon className="flex-shrink-0 h-4 w-4 text-slate-500" />
                                            <span className="ml-1 truncate">{mediaFile.name}</span>
                                            <Tippy
                                                content={"Download attachment"}
                                                className={
                                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-secondary-600 text-white"
                                                }
                                                arrow={false}
                                                animation={"shift-away-subtle"}
                                                placement={"top-start"}
                                                hideOnClick={false}
                                            >
                                                <div className="flex items-center ml-2">
                                                    <Button
                                                        iconPositionLeft
                                                        icon={
                                                            <ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5" />
                                                        }
                                                        colour={BUTTON_VARIANT_TYPES.UNCOLOURED}
                                                        extraStyling="shadow-none text-primary-600 p-0 text-xs focus:ring-0 focus:transparent hover:text-primary-700"
                                                        onClick={() => handleResourceFileDownload(mediaFile.identifier)}
                                                        loadingIconColour="text-primary"
                                                        loading={downloading[mediaFile.identifier] || false}
                                                    />
                                                </div>
                                            </Tippy>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    )}
                </dd>
            </div>
        </div>
    );
}
