import { useState, useEffect, useCallback } from "react";
import Percentile from "./Percentile";
import Tippy from "@tippyjs/react";

import { UserIcon } from "@heroicons/react/24/solid";
import ScaleAxis from "../Charts/CustomCharts/ScaleAxis";

function SubScale(props) {
    const {
        userName,
        name,
        percentile,
        lowAdjective,
        highAdjective,
        description,
        band_description,
        colour,
        colourPalette,
        scaleIndex,
    } = props;

    const [position, setPosition] = useState(0);

    const [textColor, setTextColor] = useState("text-slate-50");

    const [lowPercentile, setLowPercentile] = useState(false);
    const [mediumPercentile, setMediumPercentile] = useState(false);
    const [highPercentile, setHighPercentile] = useState(false);

    const handleSetColour = useCallback(
        async (percentileNumber) => {
            if (percentileNumber <= 33) {
                setTimeout(() => {
                    setTextColor(
                        colourPalette[scaleIndex]
                            ? `text-[${colourPalette[scaleIndex]}]`
                            : `text-[${colourPalette[colourPalette.length - 1]}]`
                    );
                    setLowPercentile(true);
                }, 100);
            } else if (percentileNumber < 74) {
                setTimeout(() => {
                    setTextColor(
                        colourPalette[scaleIndex]
                            ? `text-[${colourPalette[scaleIndex]}]`
                            : `text-[${colourPalette[colourPalette.length - 1]}]`
                    );
                    setMediumPercentile(true);
                }, 100);
            } else {
                setTimeout(() => {
                    setTextColor(
                        colourPalette[scaleIndex]
                            ? `text-[${colourPalette[scaleIndex]}]`
                            : `text-[${colourPalette[colourPalette.length - 1]}]`
                    );
                    setHighPercentile(true);
                }, 100);
            }
        },
        [colourPalette, scaleIndex]
    );

    useEffect(() => {
        setTimeout(() => {
            setPosition(percentile);
        }, 100);
        handleSetColour(percentile);
    }, [handleSetColour, percentile]);

    return (
        <div>
            {description ? (
                <>
                    <Tippy
                        content={description}
                        className={"py-1.5 px-2 shadow rounded-2xl bg-primary-600 text-primary-50 text-sm"}
                        arrow={false}
                        animation={"shift-away-subtle"}
                    >
                        <h2
                            className={
                                "cursor-help transition transition-color duration-4000 ease-in-out font-medium " +
                                textColor
                            }
                        >
                            {name}
                        </h2>
                    </Tippy>

                    <p className="text-slate-500 my-3 text-sm">{description}</p>
                </>
            ) : (
                <h2 className={"transition transition-color duration-4000 ease-in-out font-medium " + textColor}>
                    {name}
                </h2>
            )}

            <div className="flex flex-col-reverse lg:flex-row gap-4 mt-3">
                <div className={"w-full"}>
                    <div
                        className={
                            "w-full bg-gradient-to-r from-slate-50 to-primary-50 p-4 rounded-xl border border-slate-100"
                        }
                    >
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center justify-between text-sm font-medium text-slate-500">
                                <span className={"text-slate-500"}>{lowAdjective}</span>
                                <span className={"text-slate-700"}>{highAdjective}</span>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-row h-8">
                                    <div
                                        className={`w-full border-slate-100 border-2 rounded-l-xl  border-r-0 ${
                                            highPercentile === true
                                                ? colourPalette[scaleIndex]
                                                    ? `bg-[${colourPalette[scaleIndex]}]`
                                                    : `bg-[${colourPalette[colourPalette.length - 1]}]`
                                                : `bg-slate-400`
                                        }`}
                                        style={{ width: "100%" }}
                                    />
                                    <div
                                        className={`w-full border-slate-100 border-2 ${
                                            mediumPercentile === true
                                                ? colourPalette[scaleIndex]
                                                    ? `bg-[${colourPalette[scaleIndex]}]`
                                                    : `bg-[${colourPalette[colourPalette.length - 1]}]`
                                                : `bg-slate-300`
                                        }`}
                                        style={{ width: "73%" }}
                                    />
                                    <div
                                        className={`w-full border-slate-100 border-2 border-l-0 rounded-r-xl ${
                                            lowPercentile === true
                                                ? colourPalette[scaleIndex]
                                                    ? `bg-[${colourPalette[scaleIndex]}]`
                                                    : `bg-[${colourPalette[colourPalette.length - 1]}]`
                                                : `bg-slate-200`
                                        }`}
                                        style={{ width: "33%" }}
                                    />
                                </div>
                                <ScaleAxis
                                    percentage
                                    percentageLabels={{
                                        first: "0%",
                                        second: "25%",
                                        third: "50%",
                                        fourth: "75%",
                                        fifth: "99%",
                                    }}
                                />
                            </div>
                            <div className={"relative transition-width duration-4000 w-" + position + "-percentage"}>
                                <div className={"absolute -top-[100px] -right-0"}>
                                    <div
                                        className={
                                            "flex flex-row gap-1 justify-center items-center text-amber-700 bg-amber-200 " +
                                            (position === 0 ? "opacity-0 " : "opacity-1 ") +
                                            "w-max p-1 rounded-full rounded-br-none transition-opacity duration-4000 z-0"
                                        }
                                    >
                                        <UserIcon className="block h-3.5 w-3.5" />
                                        <span className={"text-xs font-medium"}>{userName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {band_description && <p className={"text-slate-500 mt-2 text-sm"}>{band_description}</p>}
                    </div>
                </div>
                <div className={"flex-grow flex items-center justify-center"}>
                    <Percentile
                        size={"large"}
                        percentile={percentile}
                        colour={colour}
                        colourPalette={colourPalette}
                        scaleIndex={scaleIndex}
                    />
                </div>
            </div>
        </div>
    );
}

export default SubScale;
