import ButtonSpinner from "./Loading/ButtonSpinner";

function Button(props) {
    const {
        buttonRef,
        disabled,
        colour,
        text,
        icon,
        secondIcon,
        iconPositionLeft,
        extraStyling = "",
        onClick,
        loading,
        smallButton,
        extraSmallButton,
        loadingIconSize,
        loadingIconColour,
        onMouseOut,
    } = props;

    return (
        <button
            ref={buttonRef && buttonRef}
            type="button"
            className={
                (disabled ? "cursor-not-allowed " : "") +
                extraStyling +
                (smallButton ? " smallButton" : extraSmallButton ? " extraSmallButton" : " button") +
                " disabled:opacity-70 " +
                colour
            }
            onClick={disabled || loading ? null : () => onClick()}
            disabled={disabled}
            onMouseOut={onMouseOut}
        >
            {loading ? (
                <>
                    {loadingIconSize ? (
                        <ButtonSpinner loadingIconSize={loadingIconSize} />
                    ) : loadingIconColour ? (
                        <ButtonSpinner loadingIconColour={loadingIconColour} />
                    ) : (
                        <ButtonSpinner />
                    )}
                    {text}
                </>
            ) : iconPositionLeft ? (
                <>
                    {icon}
                    {text}
                    {secondIcon}
                </>
            ) : (
                <>
                    {text}
                    {icon}
                    {secondIcon}
                </>
            )}
        </button>
    );
}

export default Button;
