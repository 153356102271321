import ScatterGraph from "../Charts/RechartCharts/ScatterGraph";
import BubbleGraph from "../Charts/RechartCharts/BubbleGraph";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import BarGraph from "../Charts/RechartCharts/BarGraph";

export default function AllDepartments() {
    return (
        <div className={"grid grid-flow-row gap-8"}>
            <div>
                <div className={"grid grid-flow-row 2xl:grid-cols-2 gap-8"}>
                    <div className={"col-span-2 lg:col-span-1"}>
                        <h2 className="text-base font-medium text-primary-600 tracking-tight ">
                            Leadership styles trends
                        </h2>
                        <div className={"p-3 bg-white rounded-xl shadow-sm mt-4"}>
                            <div className={"h-96 text-sm"}>
                                <ScatterGraph />
                            </div>
                        </div>
                    </div>
                    <div className={"col-span-2 lg:col-span-1"}>
                        <h2 className="text-base font-medium text-primary-600 tracking-tight ">
                            Stewardship and People Strengths
                        </h2>
                        <div className={"p-3 bg-white rounded-xl shadow-sm mt-4"}>
                            <div className={"h-96 text-sm"}>
                                <BubbleGraph />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex"}>
                    <div className={"flex justify-center gap-4 mt-8 mb-4 rounded-xl w-max "}>
                        <div className={"p-2 rounded-xl bg-pink-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Department 1
                            </div>
                        </div>
                        <div className={"p-2 rounded-xl bg-purple-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Department 2
                            </div>
                        </div>
                        <div className={"p-2 rounded-xl bg-secondary-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Department 3
                            </div>
                        </div>
                        <div className={"p-2 rounded-xl bg-green-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Department 4
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"bg-white p-3 rounded-xl shadow-sm text-slate-500 mt-4 text-sm"}>
                    Insofar revaluation war horror gains free horror sea enlightenment insofar oneself abstract. Oneself
                    strong ultimate morality decrepit depths revaluation aversion overcome endless burying. Ultimate
                    prejudice snare oneself love hatred hope christianity will christian joy transvaluation decieve
                    faith. Faith merciful against faith free. Salvation derive endless law zarathustra transvaluation
                    victorious. Salvation god depths decrepit grandeur god faithful.
                </div>
            </div>
            <div>
                <h2 className="text-base font-medium text-primary-600 tracking-tight ">
                    Leadership Trends and Strengths
                </h2>
                <div className={"p-3 bg-white rounded-xl shadow-sm mt-4"}>
                    <div className={"h-96 text-sm"}>
                        <BarGraph />
                    </div>
                </div>
                <div className={"flex"}>
                    <div className={"flex justify-center gap-4 mt-8 mb-4 rounded-xl w-max "}>
                        <div className={"p-2 rounded-xl bg-cyan-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Scale 1
                            </div>
                        </div>
                        <div className={"p-2 rounded-xl bg-secondary-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Scale 2
                            </div>
                        </div>
                        <div className={"p-2 rounded-xl bg-primary-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Scale 3
                            </div>
                        </div>
                        <div className={"p-2 rounded-xl bg-purple-600 shadow-sm text-xs w-max shadow-sm"}>
                            <div className={"flex flex-row items-center justify-center gap-2 text-white"}>
                                <ChartBarIcon className="block h-3 w-3" />
                                Scale 4
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"bg-white p-3 rounded-xl shadow-sm text-slate-500 mt-4 text-sm"}>
                    <p>
                        Eternal-return abstract virtues faith god society. Sexuality contradict decieve play strong
                        against chaos of mountains. Holiest christian sexuality pinnacle suicide. Merciful aversion
                        truth sexuality reason law strong will. Snare philosophy passion faith revaluation ultimate.
                        Morality grandeur fearful pinnacle selfish.
                    </p>
                    <p className={"mt-2"}>
                        Selfish pinnacle pinnacle ultimate society transvaluation. Battle passion dead horror reason
                        faith horror overcome truth horror salvation play pinnacle. Fearful disgust abstract decieve
                        overcome right pious horror strong sea passion will self decrepit. Salvation value of suicide
                        fearful dead deceptions gains moral intentions good passion morality ultimate.
                    </p>
                </div>
            </div>
        </div>
    );
}
