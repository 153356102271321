import { InformationCircleIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

function Input(props) {
    const {
        id,
        type,
        label,
        placeholder,
        onChange,
        onKeyPress,
        icon,
        value,
        optional,
        disabled,
        extraStyling,
        textColour,
        hidden,
        backgroundColour,
        customOnChange,
        currentValue,
        subLabel,
        autoFocus,
        minDate,
        maxDate,
        endElements,
        testId,
        required,
        valueOnly,
        topSubLabel,
    } = props;

    return (
        <div className={(hidden ? "hidden " : "") + "flex flex-col gap-2 w-full " + extraStyling}>
            {label && (
                <div className={"flex justify-between w-full"}>
                    <label htmlFor={id} className="tracking-tight text-sm font-medium text-primary-600 w-full ">
                        {label}
                        {required && <span className="text-red-600 font-bold text-xs float-right">* Required</span>}
                    </label>
                </div>
            )}

            {topSubLabel && (
                <div className={"flex justify-between w-full"}>
                    <label htmlFor={id} className="text-xs text-slate-400 w-full">
                        {topSubLabel}
                    </label>
                </div>
            )}

            <div className="flex flex-row gap-2 items-center">
                <div className="flex rounded-xl shadow-sm w-full">
                    {icon && (
                        <span
                            className={"inline-flex items-center px-3 rounded-l-xl text-slate-500 text-sm bg-slate-200"}
                        >
                            {icon}
                        </span>
                    )}
                    <Tippy
                        content={
                            <div className="flex flex-row gap-1 items-center">
                                <InformationCircleIcon className="h-3 w-3" />
                                Read-only
                            </div>
                        }
                        className={"py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 text-white"}
                        arrow={false}
                        animation={"shift-away-subtle"}
                        hideOnClick={false}
                        disabled={!disabled}
                        placement="top-end"
                    >
                        <span className="flex flex-row gap-2 w-full">
                            {valueOnly ? (
                                <input
                                    className={
                                        "w-full px-3 py-2 focus:outline-none focus:ring-1 " +
                                        "border border-slate-100 border-1 shadow-sm " +
                                        "focus:ring-primary-400 focus:border-primary-400 text-sm focus:ring-inset " +
                                        (textColour ? textColour : "text-slate-500") +
                                        (icon ? " rounded-r-xl " : " rounded-xl ") +
                                        (backgroundColour ? backgroundColour + " " : "bg-slate-50 ") +
                                        (disabled ? " disabled:opacity-70" : "")
                                    }
                                    type={type}
                                    id={id}
                                    onChange={
                                        customOnChange
                                            ? customOnChange
                                            : onChange
                                            ? (e) => onChange(e.target.value)
                                            : null
                                    }
                                    autoComplete={id}
                                    placeholder={placeholder}
                                    onKeyPress={onKeyPress ? (e) => onKeyPress(e.key) : null}
                                    value={currentValue}
                                    disabled={disabled && disabled}
                                    autoFocus={autoFocus}
                                    min={minDate && minDate}
                                    max={maxDate && maxDate}
                                    data-testid={testId && testId}
                                />
                            ) : (
                                <input
                                    className={
                                        "w-full px-3 py-2 focus:outline-none focus:ring-1 " +
                                        "border border-slate-100 border-1 shadow-sm " +
                                        "focus:ring-primary-400 focus:border-primary-400 text-sm focus:ring-inset " +
                                        (textColour ? textColour : "text-slate-500") +
                                        (icon ? " rounded-r-xl " : " rounded-xl ") +
                                        (backgroundColour ? backgroundColour + " " : "bg-slate-50 ") +
                                        (disabled ? " disabled:opacity-70" : "")
                                    }
                                    type={type}
                                    id={id}
                                    onChange={
                                        customOnChange
                                            ? customOnChange
                                            : onChange
                                            ? (e) => onChange(e.target.value)
                                            : null
                                    }
                                    autoComplete={id}
                                    placeholder={placeholder}
                                    onKeyPress={onKeyPress ? (e) => onKeyPress(e.key) : null}
                                    defaultValue={type === "number" ? (isNaN(Number(value)) ? "" : value) : value}
                                    {...(currentValue ? { value: currentValue } : {})}
                                    disabled={disabled && disabled}
                                    autoFocus={autoFocus}
                                    min={minDate && minDate}
                                    max={maxDate && maxDate}
                                    data-testid={testId && testId}
                                />
                            )}
                        </span>
                    </Tippy>
                </div>
                {endElements && endElements}
            </div>
            {(optional || subLabel) && (
                <div className="flex flex-row items-center justify-between">
                    {optional && <div className={"text-xs text-slate-400 w-full text-right"}>Optional</div>}
                    {subLabel && <div className={"text-xs text-slate-400 w-full text-left"}>{subLabel}</div>}
                </div>
            )}
        </div>
    );
}

export default Input;
