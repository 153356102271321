import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUturnLeftIcon, ArrowsRightLeftIcon, DocumentTextIcon, PlusIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, LIKERT_SCALE_OPTIONS } from "../../../../utils/constants";

import Button from "../../../SharedComponents/Button";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import SampleQuestions from "./SampleQuestions";
import CustomQuestionPage from "./CustomQuestionPage";
import PaginatedTabs from "./PaginatedTabs";

export default function AddCustomQuestions() {
    const navigate = useNavigate();

    const [customQuestionPages, setCustomQuestionPages] = useState([
        {
            pageTitle: "",
            pageDescription: "",
            questions: [
                {
                    questionTitle: "",
                    questionDescription: "",
                    questionType: "",
                    likertScaleType: "",
                    isRequired: false,
                    isReversed: false,
                },
            ],
        },
    ]);

    const [showPreview, setShowPreview] = useState(false);
    const [showSampleQeustions, setShowSampleQuestions] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);

    const selectableLikertScaleTypes = LIKERT_SCALE_OPTIONS;

    const availableQuestionTypes = [
        { label: "Text", value: "text" },
        { label: "Likert scale", value: "likertScale" },
    ];

    const handleUpdateCustomQuestionPage = (pageIndex, updatedCustomQuestionPage) => {
        const newCustomQuestionPages = [...customQuestionPages];
        newCustomQuestionPages[pageIndex] = updatedCustomQuestionPage;
        setCustomQuestionPages(newCustomQuestionPages);
    };

    const handleAddCustomQuestionPage = () => {
        const newCustomQuestionPage = {
            pageTitle: "",
            pageDescription: "",
            questions: [
                {
                    questionTitle: "",
                    questionDescription: "",
                    questionType: "",
                    likertScaleType: "",
                    isRequired: false,
                    isReversed: false,
                },
            ],
        };
        setCustomQuestionPages([...customQuestionPages, newCustomQuestionPage]);
    };

    const handleRemoveCustomQuestionPage = (pageIndex) => {
        const newCustomQuestionPages = [...customQuestionPages];
        newCustomQuestionPages.splice(pageIndex, 1);
        setCustomQuestionPages(newCustomQuestionPages);
        setSelectedPageIndex(
            newCustomQuestionPages.length <= 1
                ? 0
                : selectedPageIndex === 0
                ? newCustomQuestionPages.length - 1
                : selectedPageIndex - 1
        );
    };

    return (
        <DashboardLayout title={"Add custom questions"} requiredPermissions={[]}>
            <p className="mb-4 text-sm text-slate-500">
                Please complete the form below to add custom questions to an assessment
            </p>

            <SampleQuestions
                showSampleQeustions={showSampleQeustions}
                setShowSampleQuestions={setShowSampleQuestions}
            />

            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex flex-col"}>
                    <div className={"p-4 mb-5"}>
                        <div className={"grid grid-flow-row w-full gap-3"}>
                            <div className="col-span-2 justify-between flex flex-wrap gap-3 items-center pb-2">
                                <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                    {!showPreview ? "Editor" : "Preview"}
                                </h2>

                                <div className="flex flex-row gap-3">
                                    <Button
                                        colour={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                                        text="Show sample questions"
                                        icon={
                                            <DocumentTextIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />
                                        }
                                        iconPositionLeft
                                        onClick={() => setShowSampleQuestions(!showSampleQeustions)}
                                        loading={false}
                                    />

                                    <div
                                        onClick={() => setShowPreview(!showPreview)}
                                        className={"text-sm text-left font-medium"}
                                    >
                                        <div
                                            className={
                                                "text-primary-600 cursor-pointer hover:text-primary-700 bg-primary-50 p-2 rounded-xl shadow-sm flex w-fit"
                                            }
                                        >
                                            <ArrowsRightLeftIcon className="h-4 w-4 text-primary-600 hover:text-primary-700 self-center mr-2" />
                                            {showPreview ? `Editor` : "Preview"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <PaginatedTabs
                                items={customQuestionPages}
                                handleAddItem={handleAddCustomQuestionPage}
                                handleRemoveItem={handleRemoveCustomQuestionPage}
                                setSelectedPageIndex={setSelectedPageIndex}
                                selectedPageIndex={selectedPageIndex}
                            />

                            {customQuestionPages.map((customQuestionPage, index) => {
                                if (selectedPageIndex === index) {
                                    return (
                                        <CustomQuestionPage
                                            selectedPageIndex={index}
                                            key={index}
                                            customQuestionPage={customQuestionPage}
                                            availableQuestionTypes={availableQuestionTypes}
                                            selectableLikertScaleTypes={selectableLikertScaleTypes}
                                            onUpdate={(updatedCustomQuestionPage) =>
                                                handleUpdateCustomQuestionPage(index, updatedCustomQuestionPage)
                                            }
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>

                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex flex-wrap items-center justify-end gap-4 text-right rounded-b-xl w-full "
                        }
                    >
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                            onClick={() => navigate(-1)}
                        />

                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Create custom questions"
                            icon={<PlusIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                            iconPositionLeft
                            onClick={[]}
                            loading={false}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
