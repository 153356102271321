import { useContext } from "react";

import { Context } from "../../../../../../../../context/Context";

import SimpleMiniDataCard from "../../ChartCards/SimpleMiniDataCard";
import Alert, { ALERT_TYPE } from "../../../../../../../SharedComponents/Alert";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import SampleFieldManagerCompletions from "./SampleFieldManagerCompletions";

export default function CompletionsByFieldManager(props) {
    const { completions } = props;

    const context = useContext(Context);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                    Completion rate by Field Manager
                </h2>
                <p className="text-sm text-slate-500">Percentage of survey completion by Field Manager</p>
            </div>
            <Alert
                text={
                    "If franchise owners are allowed to pick their field manager from a drop down menu, " +
                    "please note there is likely to be differences in overall percentages as they will be " +
                    "approximations of completion rates. This is due to a discrepancy between expected numbers " +
                    "provided to us vs. who the franchise owner identifies as their field manager from the drop down."
                }
                type={ALERT_TYPE.INFO}
                active
                noMargins
            />
            {context.aceSurveyRegionalCompletionDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : completions.length === 0 ? (
                process.env.REACT_APP_ENV === "PRD" ? (
                    <Alert text={"No completions have been recorded yet"} type={ALERT_TYPE.INFO} active noMargins />
                ) : (
                    <SampleFieldManagerCompletions />
                )
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 print:grid-cols-3 gap-2">
                    {completions.map((completionItem, index) => (
                        <SimpleMiniDataCard
                            key={index + "fieldManagerStat"}
                            label={completionItem.manager?.trim()}
                            percentage={(completionItem.submittedOrderCount / completionItem.totalOrderCount) * 100}
                            totalNumber={completionItem.totalOrderCount > 2 ? completionItem.totalOrderCount : null}
                            currentNumber={
                                completionItem.totalOrderCount > 2 ? completionItem.submittedOrderCount : null
                            }
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
