import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

import {
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    CUSTOMER_PORTAL_PERMISSIONS,
} from "../../../../utils/constants";
import { getCoachees, getCoacheesForCoach } from "../../../../utils/api";
import { Context } from "../../../../context/Context";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import PickableAppCard from "./PickableAppCard";
import PickableAssessmentCard from "./PickableAssessmentCard";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import PickableUserRow from "./PickableUserRow";
import Slider from "../../WelcomePages/CoachPlatform/Slider";
import PageSlide from "../../WelcomePages/CoachPlatform/PageSlide";
import Button from "../../../SharedComponents/Button";
import Toggle from "../../../SharedComponents/Form/Toggle";

export default function AssignAppsAndAssessments() {
    const context = useContext(Context);

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [coachees, setCoachees] = useState([]);
    const [allCoachees, setAllCoachees] = useState(false);
    const [allCoacheesLoading, setAllCoacheesLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [slidesView, setSlidesView] = useState(false);

    const handleSlidesViewClick = () => {
        setSlidesView((slidesView) => !slidesView);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const assessments = [
        {
            name: "Leadership Experience Survey",
            description: "Tell us about your experience of being a leader.",
            timed: true,
            dueDate: "12/12/2021",
            timeLimit: "20 minutes",
            questionTypes: "Multiple Choice",
            colour: "cyan",
            isCompleted: false,
        },
        {
            name: "Leadership Profile",
            description: "How do you understand yourself and others?",
            timed: false,
            dueDate: "12/12/2022",
            timeLimit: "",
            questionTypes: "Multiple Choice",
            colour: "blue",
            isCompleted: false,
        },
        {
            name: "Learning Agility",
            description: "What is learning agility?",
            timed: true,
            dueDate: "12/12/2022",
            timeLimit: "30 minutes",
            questionTypes: "Multiple Choice",
            colour: "indigo",
            isCompleted: false,
        },
        {
            name: "Pulse Survey",
            description: "Track engagement levels and drivers of engagement.",
            timed: true,
            dueDate: "12/12/2022",
            timeLimit: "30 minutes",
            questionTypes: "Multiple Choice",
            colour: "amber",
            isCompleted: true,
        },
    ];

    const slides = useMemo(
        () => [
            <PageSlide
                title={"Apps"}
                description={
                    <div>
                        <h2 className="text-base text-primary-600 font-medium ">
                            Choose the App(s) you wish to assign
                        </h2>
                        <div className={"grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 mt-4"}>
                            {context.userApplications.map((app, i) => (
                                <PickableAppCard
                                    key={i}
                                    name={app.name}
                                    description={app.description}
                                    activated={true}
                                    colour={app.themeColour}
                                    logoUrl={app.logoUrl}
                                />
                            ))}
                        </div>
                    </div>
                }
            />,
            <PageSlide
                title={"Assessments"}
                description={
                    <div>
                        <h2 className="text-base text-primary-600 font-medium ">
                            Choose the Assessment(s) you wish to assign
                        </h2>
                        <div className={"grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 mt-4"}>
                            {assessments.map((assessment, i) => (
                                <PickableAssessmentCard key={i} name={assessment.name} colour={assessment.colour} />
                            ))}
                        </div>
                    </div>
                }
            />,
            <PageSlide
                title={"Users"}
                description={
                    <div>
                        <h2 className="text-base text-primary-600 font-medium mb-4">
                            Choose the user(s) you wish to assign apps and assessments to
                        </h2>
                        <PaginatedSearch
                            loading={loading}
                            items={coachees}
                            itemName={"Coachees"}
                            searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                            columnNames={["", "Details", "Status", "Setup date", "Coach"]}
                            customItemsPerPage={6}
                            ItemRow={PickableUserRow}
                        />
                    </div>
                }
            />,
        ],
        [assessments, coachees, context.userApplications, loading]
    );

    const fetchCoachees = useCallback(async () => {
        if (!allCoacheesLoading) {
            setLoading(true);
            try {
                if (allCoachees) {
                    const response = await getCoachees();
                    setCoachees(response);
                } else {
                    const response = await getCoacheesForCoach();
                    setCoachees(response);
                }
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoading(false);
        }
    }, [allCoachees, allCoacheesLoading]);

    useEffect(() => {
        fetchCoachees();
    }, [context.permissionsLoading, fetchCoachees]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setAllCoachees(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES));
            setAllCoacheesLoading(false);
        }
    }, [context.permissionsLoading, context.userPermissions]);

    return (
        <DashboardLayout
            title={"Assign apps and assessments (demo)"}
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.VIEW_COACHES_LIST,
                CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST,
            ]}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"mb-8"}>
                <Toggle text={"Slide view (demo)"} onChange={handleSlidesViewClick} />
            </div>

            {slidesView ? (
                <>
                    <div className={"flex flex-col gap-6 bg-slate-100 rounded-t-xl p-4"}>
                        <Slider slides={slides} />
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end text-right " +
                            "rounded-b-xl w-full"
                        }
                    >
                        <div className="flex flex-row gap-3">
                            <Button
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                text={"Assign apps and assessments"}
                                icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />}
                                iconPositionLeft
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={"flex flex-col gap-6 bg-slate-100 rounded-xl"}>
                        <div className={"flex flex-col gap-6 rounded-xl bg-slate-100 p-4"}>
                            <div>
                                <h2 className="text-base text-primary-600 font-medium ">
                                    Choose the App(s) you wish to assign
                                </h2>
                                <div
                                    className={
                                        "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 mt-4"
                                    }
                                >
                                    {context.userApplications.map((app, i) => (
                                        <PickableAppCard
                                            key={i}
                                            name={app.name}
                                            description={app.description}
                                            activated={true}
                                            colour={app.themeColour}
                                            logoUrl={app.logoUrl}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-col gap-6 rounded-xl bg-slate-100 p-4"}>
                            <div>
                                <h2 className="text-base text-primary-600 font-medium ">
                                    Choose the Assessment(s) you wish to assign
                                </h2>
                                <div
                                    className={
                                        "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 mt-4"
                                    }
                                >
                                    {assessments.map((assessment, i) => (
                                        <PickableAssessmentCard
                                            key={i}
                                            name={assessment.name}
                                            colour={assessment.colour}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-col gap-6 rounded-xl bg-slate-100 p-4"}>
                            <div>
                                <h2 className="text-base text-primary-600 font-medium mb-4">
                                    Choose the user(s) you wish to assign apps and assessments to
                                </h2>
                                <PaginatedSearch
                                    loading={loading}
                                    items={coachees}
                                    itemName={"Coachees"}
                                    searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                                    columnNames={[
                                        "",
                                        "Details",
                                        "Status",
                                        "Setup date",
                                        context.terminology.coach ? context.terminology.coach.singular : "Coach",
                                    ]}
                                    ItemRow={PickableUserRow}
                                />
                            </div>
                        </div>
                        <div
                            className={
                                "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end text-right " +
                                "rounded-b-xl w-full"
                            }
                        >
                            <div className="flex flex-row gap-3">
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                    text={"Assign apps and assessments"}
                                    icon={
                                        <ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />
                                    }
                                    iconPositionLeft
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </DashboardLayout>
    );
}
