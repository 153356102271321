import SausageLegendItem from "../../../Charts/CustomCharts/CustomLegends/SausageLegendItem";
import { CAPABILITIES_BAR_CHART_PALETTE } from "../../../../../../../utils/constants";
import { CustomBottomAxisCompactBars } from "./CustomBottomAxisCompactBars";
import { CustomBottomAxisBars } from "./CustomBottomAxisBars";
import { CustomTopAxisBars } from "./CustomTopAxisBars";
import { CustomTopAxisCompactBars } from "./CustomTopAxisCompactBars";
import BarChart from "../../../Charts/CustomCharts/BarChart";

export function CapabilitiesSummaryBarChart(props) {
    const {
        score,
        chosenCategoriesBigRadar,
        handleFilteredRadarFieldsChange,
        hideScoreNumbers,
        filteredBarChartFields,
        reportCompactMode,
        old,
    } = props;

    return (
        <>
            <div className={"flex flex-row items-center gap-2 px-2 py-1 rounded-xl w-fit place-self-center"}>
                <div className={"flex flex-row justify-center items-center gap-4"}>
                    <SausageLegendItem
                        label="Self-assessed Current Capability"
                        backgroundColour={
                            chosenCategoriesBigRadar.includes("selfAssessmentScore")
                                ? "bg-teal-500 hover:bg-teal-700 font-medium"
                                : "bg-slate-400 hover:bg-slate-600 font-medium"
                        }
                        textColour={
                            chosenCategoriesBigRadar.includes("selfAssessmentScore")
                                ? "text-teal-700 hover:text-teal-950 font-medium"
                                : "text-slate-600 hover:text-slate-800 font-medium"
                        }
                        large
                        clickable
                        onClick={() => handleFilteredRadarFieldsChange("selfAssessmentScore")}
                    />
                    <SausageLegendItem
                        label={"Mindset"}
                        backgroundColour={
                            chosenCategoriesBigRadar.includes("mindsetScore")
                                ? "bg-purple-500 hover:bg-purple-700 font-medium"
                                : "bg-slate-400 hover:bg-slate-600 font-medium"
                        }
                        textColour={
                            chosenCategoriesBigRadar.includes("mindsetScore")
                                ? "text-purple-700 hover:text-purple-950 font-medium"
                                : "text-slate-600 hover:text-slate-800 font-medium"
                        }
                        large
                        clickable
                        onClick={() => handleFilteredRadarFieldsChange("mindsetScore")}
                    />
                </div>
            </div>
            <BarChart
                data={filteredBarChartFields}
                percentageAxisLabels={{
                    first: "0%",
                    second: "25%",
                    third: "50%",
                    fourth: "75%",
                    fifth: "99%",
                }}
                colourPalette={CAPABILITIES_BAR_CHART_PALETTE}
                percentage
                labelAddOn="ile"
                percentageAxisMargin
                noTrendLegend
                filterText={"Capability"}
                questions
                lowMediumHighBubble
                questionsTitleTextColour={"text-secondary-600"}
                noBottomAxis
                noInfoBubbleOnPrint
                noFlexAtTopOnPrint
                alwaysDisplayFilterDropdown
                hidePercentScoreNumbers={hideScoreNumbers}
                compactMode={reportCompactMode && !old}
                customBottomAxis={
                    reportCompactMode && !old ? (
                        <CustomBottomAxisCompactBars score={score} hideScoreNumbers={hideScoreNumbers} />
                    ) : (
                        <CustomBottomAxisBars score={score} hideScoreNumbers={hideScoreNumbers} centeredLabels={old} />
                    )
                }
                customTopAxis={
                    reportCompactMode && !old ? (
                        <CustomTopAxisCompactBars score={score} hideScoreNumbers={hideScoreNumbers} />
                    ) : (
                        <CustomTopAxisBars score={score} hideScoreNumbers={hideScoreNumbers} centeredLabels={old} />
                    )
                }
            />
        </>
    );
}
