export default function ServicesByRolesTable(props) {
    const { roleLevel, title } = props;

    return (
        <div className={"flex flex-col gap-3"}>
            <h3 className="text-base text-secondary-600 font-medium font-title tracking-tight">{title}</h3>
            <div className="overflow-hidden border border-slate-100 rounded-xl shadow-sm">
                <table className="table-fixed min-w-full">
                    <thead className="bg-slate-100">
                        <tr>
                            <th
                                scope="col"
                                className={
                                    "px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wide"
                                }
                            />
                            {roleLevel.map((roleLevel) => (
                                <th
                                    scope="col"
                                    className={
                                        "px-6 py-3 text-left text-xs font-medium " +
                                        "text-slate-500 uppercase tracking-wide"
                                    }
                                >
                                    {roleLevel.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        <tr>
                            <td className="px-6 py-4">
                                <div className="text-sm text-primary-600 font-medium">Number of services</div>
                            </td>
                            {roleLevel.map((roleLevel) => (
                                <td
                                    className={
                                        "px-6 py-3 text-left text-xs font-medium " +
                                        "text-slate-500 uppercase tracking-wide"
                                    }
                                >
                                    {roleLevel.numberOfServices}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className="px-6 py-4">
                                <div className="text-sm text-primary-600 font-medium">Average effectiveness rating</div>
                            </td>
                            {roleLevel.map((roleLevel) => (
                                <td
                                    className={
                                        "px-6 py-3 text-left text-xs font-medium " +
                                        "text-slate-500 uppercase tracking-wide"
                                    }
                                >
                                    {roleLevel.percentScore}%
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
