import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Context } from "../../../../../context/Context";

import { USER_GROUPS } from "../../../../../utils/constants";

import AddRemoveButton from "../../../../SharedComponents/Form/AddRemoveButton";
import BasicTransitionHeadlessUi from "../../../../SharedComponents/Loading/Transitions/BasicTransitionHeadlessUi";

export default function UserReleaseReportPermissionRow(props) {
    const {
        item: user,
        itemRowToggleOnChange,
        noPreviousAccessUser,
        accessDefaultToggleState,
        latestItemInteracted: latestUserInteracted,
    } = props;

    const context = useContext(Context);

    const [showTransition, setShowTransition] = useState(true);

    const navigate = useNavigate();

    let isCoachee;

    if (user.userGroups) {
        isCoachee = user.userGroups.includes(USER_GROUPS.COACHEE);
    }

    useEffect(() => {
        if (latestUserInteracted) {
            if (user.userIdentifier === latestUserInteracted.userIdentifier) {
                setShowTransition(false);
                setTimeout(() => {
                    setShowTransition(true);
                }, 200);
            }
        }
    }, [latestUserInteracted, user]);

    return (
        <tr>
            <td className="px-4 py-3">
                <BasicTransitionHeadlessUi show={showTransition}>
                    <div
                        className={isCoachee ? "cursor-pointer" : ""}
                        onClick={() => {
                            if (isCoachee)
                                navigate(`/${context.terminology.coachee.singular.toLowerCase()}-profile`, {
                                    state: { userIdentifier: user.userIdentifier },
                                });
                        }}
                    >
                        <div
                            className={
                                "text-sm font-medium text-slate-600 " + (isCoachee && "hover:text-secondary-500")
                            }
                        >
                            {user.firstName + " " + user.lastName}
                        </div>
                        <div className={"text-sm text-slate-500 mt-1 " + (isCoachee && "hover:text-secondary-500")}>
                            {user.emailAddress}
                        </div>
                    </div>
                </BasicTransitionHeadlessUi>
            </td>

            <td className="px-4 py-3">
                <BasicTransitionHeadlessUi show={showTransition}>
                    {user.userGroups ? (
                        <div className={"text-slate-500"}>
                            <span className={"text-sm"}>
                                {user.userGroups.includes(USER_GROUPS.COACH) && isCoachee ? (
                                    `${context.terminology.coach.singular} and ${context.terminology.coachee.singular}`
                                ) : user.userGroups.includes(USER_GROUPS.COACH) ? (
                                    `${context.terminology.coach.singular}`
                                ) : isCoachee ? (
                                    `${context.terminology.coachee.singular}`
                                ) : (
                                    <span className={"text-xs text-slate-400 italic "}>Not setup</span>
                                )}
                            </span>
                        </div>
                    ) : (
                        <div className={"text-xs text-slate-400 italic "}>Unavailable</div>
                    )}
                </BasicTransitionHeadlessUi>
            </td>

            <td className="px-4 py-3 w-48">
                <BasicTransitionHeadlessUi show={showTransition}>
                    <div className="flex space-between">
                        <AddRemoveButton
                            identifier={user.userIdentifier}
                            name={"toggle-" + user.emailAddress}
                            setValue={itemRowToggleOnChange}
                            noPreviousAccessUser={noPreviousAccessUser}
                            accessDefaultToggleState={accessDefaultToggleState}
                            addButtonText={"Grant permission"}
                            removeButtonText={"Revoke permission"}
                        />
                    </div>
                </BasicTransitionHeadlessUi>
            </td>
        </tr>
    );
}
