import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { AnimatePresence, motion } from "framer-motion";

import { BASIC_ANIMATION_VARIANTS } from "../../../utils/constants";

import TabbedNavigationContentSection from "./TabbedNavigationContentSection";
import BasicMotion from "../Loading/Transitions/BasicMotion";
import TabbedNavigationLoadingPlaceholder from "../Loading/TabbedNavigationLoadingPlaceholder";
import ButtonSpinner from "../Loading/ButtonSpinner";

export default function TabbedNavigationLayout(props) {
    const {
        tabList,
        tabContent,
        tabIndex,
        setTabIndex,
        contentLoading,
        loading,
        tabsRowOnly,
        handleShowNavigationConfirmation,
        noContainer,
        secondaryTabs,
        gapLessTabsAndContent,
        displayOnlyContent,
        printGap,
        noTitle,
    } = props;

    return (
        <>
            {contentLoading ? (
                <TabbedNavigationLoadingPlaceholder active={contentLoading} />
            ) : displayOnlyContent ? (
                <div className={"flex flex-col gap-4 " + (printGap ? printGap : "print:gap-8")}>
                    {tabContent.map((content, index) => (
                        <motion.div
                            key={index}
                            initial={"inactive"}
                            animate={"active"}
                            exit={"inactive"}
                            transition={{ duration: 0.2 }}
                            variants={BASIC_ANIMATION_VARIANTS}
                        >
                            <div className={"flex flex-col gap-4"}>
                                {!noTitle && content.title && (
                                    <div className="flex justify-between items-center print:h-32">
                                        <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                            {!noTitle && content.title}
                                        </h2>
                                        {content.icon && content.icon}
                                        {content.headerElements && content.headerElements}
                                    </div>
                                )}
                                {content.content}
                            </div>
                        </motion.div>
                    ))}
                </div>
            ) : (
                <BasicMotion>
                    <Tabs
                        selectedIndex={tabIndex}
                        onSelect={setTabIndex && ((index) => setTabIndex(index))}
                        className={
                            "grid grid-flow-row min-h-0 text-sm grid-cols-2 " +
                            (!tabsRowOnly && " xl:grid-cols-8") +
                            (gapLessTabsAndContent ? " gap-1" : " gap-3")
                        }
                    >
                        <TabList
                            className={
                                "flex flex-row gap-2 col-span-2 overflow-x-scroll z-10 pb-1 print:hidden " +
                                (!tabsRowOnly ? "xl:flex-col" : "xl:col-span-6")
                            }
                        >
                            {tabList.map((tab, index) => (
                                <Tab
                                    key={index + "tabListTab"}
                                    className={
                                        (secondaryTabs
                                            ? "tab-secondary "
                                            : Array.from(tabContent)[index].tabClassName
                                            ? Array.from(tabContent)[index].tabClassName
                                            : "tab ") +
                                        (Array.from(tabContent)[index].content.props.disabled
                                            ? "opacity-60 pointer-none cursor-not-allowed "
                                            : "opacity-100 ") +
                                        Array.from(tabContent)[index].tabClassName
                                    }
                                    selectedClassName={
                                        Array.from(tabContent)[index].tabSelectedClassName
                                            ? "tab-selected " + Array.from(tabContent)[index].tabSelectedClassName
                                            : "tab-selected"
                                    }
                                    disabled={Array.from(tabContent)[index].content.props.disabled}
                                >
                                    <div className="flex flex-row items-center gap-2">
                                        {Array.from(tabContent)[index]?.tabIcon &&
                                            Array.from(tabContent)[index]?.tabIcon}
                                        {tab}
                                        {Array.from(tabContent)[index].content.props.disabled &&
                                            Array.from(tabContent)[index].content.props.loading && (
                                                <ButtonSpinner
                                                    loadingIconColour="text-slate-500"
                                                    loadingIconSize="h-4 w-4"
                                                />
                                            )}
                                    </div>
                                </Tab>
                            ))}
                        </TabList>
                        <div
                            className={
                                "xl:col-span-6 col-span-2 rounded-xl " +
                                (noContainer
                                    ? "border-none"
                                    : " bg-none md:bg-slate-100 border-none md:border shadow-none md:shadow-sm")
                            }
                        >
                            {tabContent.map((content, index) => (
                                <TabPanel
                                    key={index + "tabPanel"}
                                    className={
                                        "focus:outline-none rounded-xl w-full outline-none " +
                                        (noContainer
                                            ? "bg-none shadow-none"
                                            : content.backgroundColour
                                            ? content.backgroundColour
                                            : "")
                                    }
                                >
                                    {noContainer ? (
                                        <AnimatePresence>
                                            <motion.div
                                                initial={"inactive"}
                                                animate={"active"}
                                                exit={"inactive"}
                                                transition={{ duration: 0.2 }}
                                                variants={BASIC_ANIMATION_VARIANTS}
                                            >
                                                <div className="flex flex-col gap-3">
                                                    {!noTitle && content.title && (
                                                        <div className="flex justify-between items-center">
                                                            <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                                                {!noTitle && content.title}
                                                            </h2>
                                                            {content.icon && content.icon}
                                                            {content.headerElements && content.headerElements}
                                                        </div>
                                                    )}
                                                    {content.content}
                                                </div>
                                            </motion.div>
                                        </AnimatePresence>
                                    ) : (
                                        <TabbedNavigationContentSection
                                            title={content.title}
                                            onSaveChanges={content.onSaveChanges}
                                            buttonText={content.buttonText}
                                            upload={content.upload}
                                            loading={loading}
                                            readonly={content.readonly}
                                            icon={content.icon}
                                            headerElements={content.headerElements}
                                            handleShowNavigationConfirmation={handleShowNavigationConfirmation}
                                        >
                                            <AnimatePresence>
                                                <motion.div
                                                    initial={"inactive"}
                                                    animate={"active"}
                                                    exit={"inactive"}
                                                    transition={{ duration: 0.2 }}
                                                    variants={BASIC_ANIMATION_VARIANTS}
                                                >
                                                    {content.content}
                                                </motion.div>
                                            </AnimatePresence>
                                        </TabbedNavigationContentSection>
                                    )}
                                </TabPanel>
                            ))}
                        </div>
                    </Tabs>
                </BasicMotion>
            )}
        </>
    );
}
