import SimpleMiniDataCard from "../../ChartCards/SimpleMiniDataCard";
import Alert, { ALERT_TYPE } from "../../../../../../../SharedComponents/Alert";

export default function SampleFieldManagerCompletions() {
    return (
        <>
            <Alert text={"Sample data"} type={ALERT_TYPE.INFO} active noMargins className={"col-span-4"} />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 print:grid-cols-3 gap-2">
                <SimpleMiniDataCard
                    label="Overall"
                    percentage={78}
                    trendPercentage={13}
                    accompanyingText="in 2023"
                    totalNumber={43}
                    currentNumber={12}
                />
                <SimpleMiniDataCard
                    label="Gerald Gerlaldton"
                    percentage={67}
                    trendPercentage={14}
                    accompanyingText="in 2023"
                    totalNumber={56}
                    currentNumber={34}
                />
                <SimpleMiniDataCard
                    label="Fred Fredston"
                    percentage={46}
                    trendPercentage={-13}
                    accompanyingText="in 2023"
                    totalNumber={32}
                    currentNumber={29}
                />
            </div>
        </>
    );
}
