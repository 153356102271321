import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getProfileDetails, updateProfileDetails, uploadAccountMedia } from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import UploadPhoto from "./UploadPhoto";
import UploadVideo from "./UploadVideo";
import PersonalDetails from "./PersonalDetails";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import { Context } from "../../../../context/Context";

export default function Profile() {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [mediaFile, setMediaFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [personalDetails, setPersonalDetails] = useState({});
    const [canEdit, setCanEdit] = useState(false);

    const context = useContext(Context);

    const navigate = useNavigate();

    const handleUploadFile = async () => {
        setLoading(true);
        try {
            await uploadAccountMedia(null, mediaFile[0]);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully uploaded file");
            navigate("/profile");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setLoading(false);
    };

    const handleUpdateProfile = async () => {
        setLoading(true);
        try {
            await updateProfileDetails(
                personalDetails.emailAddress,
                personalDetails.firstName,
                personalDetails.lastName,
                personalDetails.mobileNumber,
                personalDetails.dateOfBirth,
                personalDetails.userGroups
            );
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully updated profile");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setLoading(false);
    };

    const fetchProfileDetails = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getProfileDetails();
            setPersonalDetails(response);
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setLoading(false);
    }, [setPersonalDetails]);

    useEffect(() => {
        fetchProfileDetails();
    }, [fetchProfileDetails]);

    useEffect(() => {
        if (!context.permissionsLoading) {
            setCanEdit(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.EDIT_OWN_PROFILE));
        }
    }, [context.permissionsLoading, context.userPermissions]);

    return (
        <DashboardLayout title={"Profile"} requiredPermissions={[]}>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <TabbedNavigationLayout
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabList={["Personal details", "Upload profile photo", "Upload profile video"]}
                loading={loading}
                backgroundColour={"bg-white"}
                tabContent={[
                    {
                        title: "Personal details",
                        onSaveChanges: canEdit && handleUpdateProfile,
                        content: (
                            <PersonalDetails
                                uploadProfilePhoto={() => setTabIndex(1)}
                                personalDetails={personalDetails}
                                setPersonalDetails={setPersonalDetails}
                                canEdit={canEdit}
                            />
                        ),
                    },
                    {
                        title: "Upload profile photo",
                        onSaveChanges: canEdit && handleUploadFile,
                        content: <UploadPhoto mediaFile={mediaFile} setMediaFile={setMediaFile} />,
                        buttonText: "Upload photo",
                        upload: true,
                    },
                    {
                        title: "Upload profile video",
                        onSaveChanges: canEdit && handleUploadFile,
                        content: <UploadVideo mediaFile={mediaFile} setMediaFile={setMediaFile} />,
                        buttonText: "Upload video",
                        upload: true,
                    },
                ]}
            />
        </DashboardLayout>
    );
}
