import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import {
    AXIS_LABELS,
    BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../../../../../utils/constants";

import DashboardLayout from "../../../../../../../SharedComponents/DashboardLayout";
import QuestionsDataChart from "../../ChartCards/QuestionsDataChart";
import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QualitativeItemSection from "../Qualitative/QualitativeItemSection";
import SingleWithBenchmark from "../../ChartCards/SingleWithBenchmark";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import ServicesByRolesTable from "./ServicesByRolesTable";
import AceReportPicker from "../AceReportPicker";

export default function Services() {
    return (
        <DashboardLayout
            title="Services"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <ServicesElements />
        </DashboardLayout>
    );
}

export function ServicesElements(props) {
    const { displayOnlyContent, noQualitative } = props;

    const context = useContext(Context);

    const [tabIndexParent, setTabIndexParent] = useState(0);
    const [tabListParent, setTabListParent] = useState([]);
    const [tabContentParent, setTabContentParent] = useState([]);

    const [tabIndex, setTabIndex] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [tabContent, setTabContent] = useState([]);

    useEffect(() => {
        // For later use (when we have to split services into two groups)
        let updatedTabListParent = [];
        let updatedTabContentParent = [];

        let updatedTabList = [];
        let updatedTabContent = [];

        let services = [];

        if (context.aceSurveyReportData.services) {
            services.push(context.aceSurveyReportData.services);
        }

        context.aceSurveyReportData.services.forEach((service) => {
            service.name === "Text" && !noQualitative
                ? updatedTabList.push("Open-ended questions") &&
                  updatedTabContent.push({
                      content: (
                          <div className="flex flex-col gap-4 print:gap-0">
                              {service.items.map(
                                  (item, index) =>
                                      item.itemName !== "QUAL_BEST" &&
                                      item.itemName !== "QUAL_WORST" && (
                                          <QualitativeItemSection
                                              key={index + "openEndedItemSection"}
                                              data={item}
                                              title={"Open-ended question #" + (index + 1)}
                                          />
                                      )
                              )}
                          </div>
                      ),
                      readonly: true,
                  })
                : service.name === "Services By Roles"
                ? updatedTabList.push(service.name) &&
                  updatedTabContent.push({
                      content: (
                          <div
                              className={
                                  "flex flex-col gap-4 print:gap-0 w-full print:p-0 print:border-0 print:break-before-page " +
                                  "print:shadow-none bg-white p-4 rounded-xl border border-slate-100 shadow-sm"
                              }
                          >
                              <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                  {service.name}
                              </h2>
                              <div className={"flex flex-col gap-6"}>
                                  <ServicesByRolesTable
                                      title={context.aceSurveyReportData.currentSurveyLabel}
                                      roleLevel={service.roleLevel}
                                  />
                                  {service.historicalScore && (
                                      <ServicesByRolesTable
                                          title={context.aceSurveyReportData.previousSurveyLabel}
                                          roleLevel={service.historicalScore}
                                      />
                                  )}
                              </div>
                          </div>
                      ),
                      readonly: true,
                  })
                : service.name !== "Average Services Ratings" && service.name !== "Text"
                ? updatedTabList.push(service.name) &&
                  updatedTabContent.push({
                      content: (
                          <QuestionsDataChart
                              data={service.items}
                              title={service.name}
                              noItemTitles
                              questions
                              axisLabels={AXIS_LABELS.notAtAll}
                              infoBubble
                              noInfoBubbleOnPrint
                              //   topXAxis
                              extraStyling={
                                  service.items.length > 7 ? "break-inside-avoid-page" : "break-before-page print:-mt-5"
                              }
                          />
                      ),
                      readonly: true,
                  })
                : service.name !== "Text" &&
                  updatedTabList.unshift(service.name) &&
                  updatedTabContent.unshift({
                      content: (
                          <SingleWithBenchmark
                              withBenchmarks
                              detailed
                              data={service.data}
                              title={"Average Services Ratings"}
                              axisLabels={AXIS_LABELS.notAtAll}
                              infoBubble
                              noInfoBubbles={!service.data.historicalPercentScores && displayOnlyContent}
                          />
                      ),
                      readonly: true,
                  });
        });

        setTabList(updatedTabList);
        setTabContent(updatedTabContent);

        updatedTabListParent.push("1");
        updatedTabContentParent.push({
            content: (
                <TabbedNavigationLayout
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabList={tabList}
                    backgroundColour={"bg-white"}
                    tabContent={tabContent}
                    noContainer
                    displayOnlyContent={displayOnlyContent}
                    secondaryTabs
                />
            ),
            readonly: true,
        });

        setTabListParent(updatedTabList);
        setTabContentParent(updatedTabContent);
    }, [
        context.aceSurveyReportData.currentSurveyLabel,
        context.aceSurveyReportData.previousSurveyLabel,
        context.aceSurveyReportData.services,
        displayOnlyContent,
        noQualitative,
    ]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                    Service Perceptions
                </h2>
                <p className="text-sm text-slate-500">
                    Respondents rated how effective each service is being delivered. This shows the average
                    effectiveness rating for all services. Ratings have been converted to percentages.
                </p>
            </div>
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <TabbedNavigationLayout
                    tabIndex={tabIndexParent}
                    setTabIndex={setTabIndexParent}
                    tabList={tabListParent}
                    backgroundColour={"bg-white"}
                    tabContent={tabContentParent}
                    noContainer
                    displayOnlyContent={displayOnlyContent}
                    secondaryTabs
                />
            )}
        </div>
    );
}
