import { motion } from "framer-motion";
import {
    CHART_4_POINT_SCALE_COLOUR_PALETTE,
    CHART_7_POINT_SCALE_COLOUR_PALETTE,
} from "../../../../../../utils/constants";
import { useEffect, useState } from "react";

export default function StackedBarChart(props) {
    const { sevenPointScale, data, smallLegend, benchmark } = props;

    const [colourPalette, setColourPalette] = useState(CHART_7_POINT_SCALE_COLOUR_PALETTE);
    const [score, setScore] = useState([]);
    const [roundUpTo100, setRoundUpTo100] = useState(false);

    useEffect(() => {
        if (sevenPointScale) {
            setColourPalette(CHART_7_POINT_SCALE_COLOUR_PALETTE);
        } else {
            setColourPalette(CHART_4_POINT_SCALE_COLOUR_PALETTE);
        }
    }, [sevenPointScale]);

    useEffect(() => {
        let currentScore = [];
        let currentCountOfScore = 0;

        Object.entries(data.data.current.percentScores).forEach(([key, value]) => {
            if (key === "overallDisagreePercentage" || key === "overallAgreePercentage") {
                currentScore.push(data.data.current.percentScores[key]);
                currentCountOfScore = currentCountOfScore + value;
            }
        });

        if (currentCountOfScore === 99) {
            setRoundUpTo100(true);
        }

        setScore(currentScore);
    }, [benchmark, data, sevenPointScale]);

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-0 font-title font-semibold transition-all">
                <div className="flex flex-row w-full bg-slate-200 rounded-xl text-sm font-medium my-1 shadow-sm overflow-hidden">
                    {score.map((barChartScore, index) => (
                        <motion.div
                            key={index}
                            initial={{ width: 0 }}
                            animate={{
                                width: `${
                                    roundUpTo100
                                        ? index === score.length - 1
                                            ? barChartScore + 1
                                            : barChartScore
                                        : barChartScore
                                }%`,
                            }}
                            transition={{ duration: 4 }}
                        >
                            <div
                                className={
                                    "flex items-center justify-center transition ease-in-out duration-300 p-2 w-full z-10 " +
                                    colourPalette[index].backgroundColour +
                                    (index === 0
                                        ? " rounded-l-xl "
                                        : index === score.length - 1
                                        ? " rounded-r-xl "
                                        : " rounded-none ")
                                }
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {barChartScore !== undefined && (
                                    <span
                                        className={
                                            "rounded-lg py-0.5 px-0 z-20 relative " +
                                            (smallLegend ? "text-xs " : "text-sm ") +
                                            (barChartScore === 0 ? "opacity-0 " : "opacity-1 ") +
                                            colourPalette[index].textColour
                                        }
                                    >
                                        {barChartScore + "%"}
                                    </span>
                                )}
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
}
