import mime from "mime";
import { useState } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

import { downloadSubsequentFile } from "../../../../../utils/api";
import { NO_ACCESS_TYPES } from "../../../../../utils/constants";
import { BUTTON_VARIANT_TYPES, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../../utils/constants";

import AccessControl from "../../../../SharedComponents/AccessControl";
import Button from "../../../../SharedComponents/Button";

export default function SubsequentFileRow(props) {
    const { subsequentFile, setAlert } = props;
    const [downloading, setDownloading] = useState(false);

    const handleDownload = async (documentResponse, fileType) => {
        fileType = documentResponse.fileType ? documentResponse.fileType : fileType;
        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const blob = await (await fetch(`data:${mimeType};base64,${documentResponse.content}`)).blob();

        if (!blob) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = documentResponse.fileName.split(".").join("_");
        downloadLink.click();
    };

    const handleDownloadSubsequentFile = async (documentIdentifier, subsequentFileIdentifier, fileType) => {
        setDownloading(true);

        try {
            const documentResponse = await downloadSubsequentFile(documentIdentifier, subsequentFileIdentifier);
            handleDownload(documentResponse, fileType);
        } catch (err) {
            setAlert(err.message);
        }

        setDownloading(false);
    };

    return (
        <AccessControl
            requiredPermissions={[CUSTOMER_PORTAL_PERMISSIONS.DOWNLOAD_ADDITIONAL_FILES]}
            noAccessType={NO_ACCESS_TYPES.REDIRECT}
        >
            <tr>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex flex-row gap-4 items-center">
                        <div className="text-xs text-slate-500 ">{subsequentFile.name}</div>
                    </div>
                </td>

                <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex flex-row gap-4 items-center">
                        <div className="text-xs text-slate-500 ">{subsequentFile.fileFormat.toUpperCase()}</div>
                    </div>
                </td>

                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"flex flex-row gap-3 items-center"}>
                        <Button
                            iconPositionLeft
                            icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />}
                            colour={BUTTON_VARIANT_TYPES.UNCOLOURED}
                            text="Download"
                            extraStyling="shadow-none text-primary-600 p-0 text-xs focus:ring-0 focus:transparent hover:text-primary-700"
                            onClick={() =>
                                handleDownloadSubsequentFile(
                                    subsequentFile.documentIdentifier,
                                    subsequentFile.identifier,
                                    subsequentFile.fileFormat
                                )
                            }
                            loadingIconColour="text-primary"
                            loading={downloading}
                        />
                    </div>
                </td>
            </tr>
        </AccessControl>
    );
}
