import Input from "../../../../SharedComponents/Form/Input";
import { AtSymbolIcon, BuildingOffice2Icon, UserIcon } from "@heroicons/react/24/solid";

export default function CurrentPosition() {
    return (
        <div className="grid grid-cols-2 gap-6">
            <Input
                id="position"
                type="text"
                label="Position"
                placeholder={"Current position"}
                icon={<UserIcon className="h-4 w-4 text-slate-500" />}
            />
            <Input
                id="division"
                type="text"
                label="Division"
                placeholder={"Division"}
                icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
            />
            <Input
                id="department"
                type="text"
                label="Department"
                placeholder={"Department"}
                icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
            />
            <Input
                id="email"
                type="email"
                label="Email"
                placeholder={"Email"}
                icon={<AtSymbolIcon className="h-4 w-4 text-slate-500" />}
            />
        </div>
    );
}
