import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

import {
    downloadUserMetaData,
    getPrograms,
    getUserDetails,
    updateUserMetaData,
    updateUserDetails,
    uploadUserMedia,
    getUserMetaData,
} from "../../../../../utils/api";
import { useCallbackPrompt } from "../../../../../utils/hooks/useCallbackPrompt.ts";
import fileDownload from "../../../../../utils/js-file-download/file-download";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, EMPLOYMENT_SERVICES } from "../../../../../utils/constants";
import { Context } from "../../../../../context/Context";
import resumeBuilderIconLight from "../../../../../assets/resume-builder-icon-light.png";
import resumeBuilderIcon from "../../../../../assets/resume-builder-icon.png";
import resumeBuilderLogo from "../.././../../../assets/resume_builder_logo.svg";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import UploadPhoto from "../../Profile/UploadPhoto";
import UploadVideo from "../../Profile/UploadVideo";
import CoacheeDetails from "./CoacheeDetails";
import JobActivity from "./JobActivity";
import CareersActionPlan from "./CareersActionPlan";
import Button from "../../../../SharedComponents/Button";
import Modal from "../../../../SharedComponents/Modal";

export default function CoacheeProfile() {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [cohortAlert, setCohortAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [mediaFile, setMediaFile] = useState([]);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [coacheeDetailsLoading, setCoacheeDetailsLoading] = useState(true);
    const [cohortsLoading, setCohortsLoading] = useState(false);
    const [programsLoading, setProgramsLoading] = useState(false);
    const [coacheeDetails, setCoacheeDetails] = useState({});
    const [educations, setEducations] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [coacheeInterests, setCoacheeInterests] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [selectablePrograms, setSelectablePrograms] = useState([]);
    const [chosenProgram, setChosenProgram] = useState([]);
    const [selectableCohorts, setSelectableCohorts] = useState([]);
    const [chosenCohort, setChosenCohort] = useState([]);
    const [goals, setGoals] = useState([]);
    const [resources, setResources] = useState([]);
    const [recommendedJobs, setRecommendedJobs] = useState([]);
    const [recommendedCareers, setRecommendedCareers] = useState([]);
    const [capDate, setCapDate] = useState("");
    const [coacheeIdentifier, setCoacheeIdentifier] = useState("");
    const [blankCareerActionPlan, setBlankCareerActionPlan] = useState(true);
    const [showNavigationConfirmation, setShowNavigationConfirmation] = useState(false);
    const [downloadingCap, setDownloadingCap] = useState(false);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showNavigationConfirmation);

    const context = useContext(Context);

    const location = useLocation();

    const navigate = useNavigate();

    const handleShowNavigationConfirmation = (confirm) => {
        setShowNavigationConfirmation(confirm);
    };

    const fetchCoacheeDetails = useCallback(
        async (userIdentifier) => {
            setCoacheeDetailsLoading(true);
            try {
                const response = await getUserDetails(userIdentifier);

                setEducations(response.educations);
                setQualifications(response.qualifications);

                setCoacheeInterests([]);

                if (response.interests) {
                    const sanitizedInterestsResponse = response.interests.map((interest) => ({
                        value: interest,
                        label: interest,
                    }));
                    setCoacheeInterests(sanitizedInterestsResponse);
                }

                setCoacheeDetails(response);
            } catch (error) {
                setAlertType(ALERT_TYPE.ERROR);
                setAlert(error.message);
            }
            setCoacheeDetailsLoading(false);
        },
        [setCoacheeDetails]
    );

    const fetchPrograms = async () => {
        setProgramsLoading(true);
        setCohortsLoading(true);

        try {
            const response = await getPrograms();

            let sanitizedPrograms = [];
            let sanitizedCohorts = [];

            for (const { name, cohorts } of response) {
                sanitizedPrograms.push({
                    value: name,
                    label: name,
                });

                cohorts.forEach((cohort) => {
                    sanitizedCohorts.push({
                        value: cohort,
                        label: cohort,
                        program: name,
                    });
                });
            }
            setSelectablePrograms(sanitizedPrograms);
            setCohorts(sanitizedCohorts);
            setPrograms(response);
        } catch (error) {
            setAlert(error.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setCohortsLoading(false);
        setProgramsLoading(false);
    };

    const fetchCareerActionPlan = async (coacheeIdentifier) => {
        try {
            const capResponse = await getUserMetaData(coacheeIdentifier, "Career Action Plan");

            if (capResponse.value) {
                const capValues = capResponse.value[0];

                setBlankCareerActionPlan(false);

                setCapDate(capValues.date ? capValues.date : "");
                setGoals(capValues.goals ? capValues.goals : []);
                setResources(capValues.resources ? capValues.resources : []);
                setRecommendedCareers(capValues.recommendedCareers ? capValues.recommendedCareers : []);
                setRecommendedJobs(capValues.recommendedJobs ? capValues.recommendedJobs : []);
            } else {
                setBlankCareerActionPlan(true);
            }
        } catch (error) {
            if (error.message !== "UserMetadata record not found for given ID") {
                setAlert(error.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
        }
    };

    const handleUploadFile = async () => {
        setUpdateLoading(true);
        try {
            await uploadUserMedia(null, mediaFile[0], coacheeDetails.userIdentifier);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully uploaded file");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setUpdateLoading(false);
    };

    const handleUpdateProfile = async () => {
        setUpdateLoading(true);

        let selectedInterests = [];
        let coacheeCohorts = [];

        // Backend requires cohort to be in array due to Coach accepting multiple cohorts and sharing of endpoint.
        if (chosenCohort && chosenCohort.length >= 1) {
            coacheeCohorts.push(chosenCohort);
        }

        if (coacheeInterests && coacheeInterests.length >= 1) {
            coacheeInterests.map((interest) => selectedInterests.push(interest.value));
        }

        try {
            await updateUserDetails(
                coacheeDetails.userIdentifier,
                coacheeDetails.emailAddress,
                coacheeDetails.firstName,
                coacheeDetails.lastName,
                coacheeDetails.mobileNumber,
                coacheeDetails.dateOfBirth,
                educations,
                qualifications,
                selectedInterests,
                coacheeDetails.accreditations,
                coacheeCohorts,
                [],
                null,
                null,
                coacheeDetails.userGroups,
                coacheeDetails.jobSeekerId
            );
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully updated " + coacheeDetails.firstName + " 's profile");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setUpdateLoading(false);
    };

    const handleUpdateCareerActionPlan = async () => {
        setBlankCareerActionPlan(false);

        handleShowNavigationConfirmation(false);

        setUpdateLoading(true);

        try {
            await updateUserMetaData(coacheeDetails.userIdentifier, "Career Action Plan", [
                {
                    coacheeName: coacheeDetails.firstName + " " + coacheeDetails.lastName,
                    date: capDate,
                    goals: goals,
                    resources: resources,
                    recommendedCareers: recommendedCareers,
                    recommendedJobs: recommendedJobs,
                },
            ]);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully updated Career Action Plan");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setUpdateLoading(false);
    };

    const handleDownloadCareerActionPlan = async (type) => {
        setDownloadingCap(true);
        try {
            await handleUpdateCareerActionPlan();

            if (type === "pdf") {
                const documentResponse = await downloadUserMetaData(coacheeIdentifier, "Career Action Plan");
                fileDownload(documentResponse, "career-action-plan.pdf");
            }

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully updated Career Action Plan");
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setDownloadingCap(false);
    };

    // Determine if user has the edit own profile permission and enable editing if true
    useEffect(() => {
        if (!context.permissionsLoading) {
            setCanEdit(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.EDIT_OWN_PROFILE));
        }
    }, [context.permissionsLoading, context.userPermissions]);

    useEffect(() => {
        if (location.state === null) {
            navigate(`/${context.terminology.coachee.plural.toLowerCase()}`);
        } else {
            if (location.state.tabIndex) {
                setTabIndex(location.state.tabIndex);
            }
            setCoacheeIdentifier(location.state.userIdentifier);
        }
    }, [context.terminology.coachee.plural, location.state, navigate]);

    useEffect(() => {
        if (coacheeIdentifier !== "") {
            fetchCoacheeDetails(coacheeIdentifier);
            fetchPrograms();

            if (process.env.REACT_APP_ENV !== "PRD") {
                fetchCareerActionPlan(coacheeIdentifier);
            }
        }
    }, [coacheeIdentifier, fetchCoacheeDetails]);

    useEffect(() => {
        if (!coacheeDetailsLoading) {
            // Coachee accepts only one Program/Cohort.
            if (
                coacheeDetails &&
                coacheeDetails?.cohorts &&
                coacheeDetails?.cohorts.length > 0 &&
                coacheeDetails?.programs &&
                coacheeDetails?.programs.length > 0
            ) {
                setChosenProgram(coacheeDetails.programs[0]);

                programs.forEach((program) => {
                    if (program.name === coacheeDetails.programs[0]) {
                        coacheeDetails.cohorts.forEach((cohort) => {
                            if (program.cohorts.includes(cohort)) {
                                setChosenCohort(cohort);
                            }
                        });
                    }
                });
            }
        }
    }, [coacheeDetails, coacheeDetailsLoading, programs]);

    useEffect(() => {
        if (chosenProgram) {
            const filteredCohorts = cohorts.filter((cohort) => cohort.program === chosenProgram);
            setSelectableCohorts(filteredCohorts);
        }
    }, [chosenProgram, cohorts]);

    useEffect(() => {
        if (!cohortsLoading && !programsLoading) {
            programs.forEach((program) => {
                if (program.name === chosenProgram && !program.cohorts.includes(chosenCohort)) {
                    setChosenCohort(null);
                    setCohortAlert("You are required to choose a cohort to be assigned to a program.");
                }
            });
        }
    }, [chosenCohort, chosenProgram, programs, cohortsLoading, programsLoading]);

    const tabContent = [
        {
            title: (context.terminology.coachee ? context.terminology.coachee.singular : "Coachee") + " details",
            onSaveChanges: canEdit && handleUpdateProfile,
            content: (
                <CoacheeDetails
                    uploadProfilePhoto={() => setTabIndex(1)}
                    personalDetails={coacheeDetails}
                    setPersonalDetails={setCoacheeDetails}
                    canEdit={canEdit}
                    userIdentifier={location.state.userIdentifier}
                    educations={educations}
                    setEducations={setEducations}
                    qualifications={qualifications}
                    setQualifications={setQualifications}
                    coacheeInterests={coacheeInterests}
                    setCoacheeInterests={setCoacheeInterests}
                    selectablePrograms={selectablePrograms}
                    selectableCohorts={selectableCohorts}
                    chosenProgram={chosenProgram}
                    setChosenProgram={setChosenProgram}
                    chosenCohort={chosenCohort}
                    setChosenCohort={setChosenCohort}
                    programs={programs}
                    cohortAlert={cohortAlert}
                    setCohortAlert={setCohortAlert}
                    coacheeDetailsLoading={coacheeDetailsLoading}
                    cohortsLoading={cohortsLoading}
                    programsLoading={programsLoading}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                />
            ),
        },
        {
            title: "Upload profile photo",
            onSaveChanges: canEdit && handleUploadFile,
            content: <UploadPhoto mediaFile={mediaFile} setMediaFile={setMediaFile} />,
            buttonText: "Upload photo",
            upload: true,
        },
        {
            title: "Upload profile video",
            onSaveChanges: canEdit && handleUploadFile,
            content: <UploadVideo mediaFile={mediaFile} setMediaFile={setMediaFile} />,
            buttonText: "Upload video",
            upload: true,
        },
    ];

    const tabList = [
        (context.terminology.coachee ? context.terminology.coachee.singular : "Coachees") + " details",
        "Upload profile photo",
        "Upload profile video",
    ];

    if (context.defaultApp === EMPLOYMENT_SERVICES && process.env.REACT_APP_ENV !== "PRD") {
        tabContent.push({
            title: "Job activity",
            content: <JobActivity />,
            readonly: true,
            tabIcon: <img src={resumeBuilderIcon} className="w-5" alt="tab icon" />,
            icon: (
                <div className="flex flex-row gap-8">
                    <Button
                        colour={BUTTON_VARIANT_TYPES.SECONDARY}
                        text={"Recommend jobs"}
                        icon={<img src={resumeBuilderIconLight} alt="resume builder" className="w-4 mr-2" />}
                        secondIcon={<ArrowTopRightOnSquareIcon className="shrink-0 block h-4 w-4 ml-2" />}
                        extraStyling="flex justify-center break-normal items-center min-w-max"
                        iconPositionLeft
                    />
                    <img src={resumeBuilderLogo} className="w-48" alt="tab icon" />
                </div>
            ),
            hidden: process.env.REACT_APP_ENV === "PRD" || context.defaultApp !== EMPLOYMENT_SERVICES,
        });
        tabList.push("Job activity");
    }

    if (
        context.defaultApp === EMPLOYMENT_SERVICES &&
        process.env.REACT_APP_ENV !== "PRD" &&
        context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_USER_METADATA)
    ) {
        tabContent.push({
            title: "Career Action Plan",
            content: (
                <CareersActionPlan
                    goals={goals}
                    setGoals={setGoals}
                    resources={resources}
                    setResources={setResources}
                    recommendedJobs={recommendedJobs}
                    setRecommendedJobs={setRecommendedJobs}
                    recommendedCareers={recommendedCareers}
                    setRecommendedCareers={setRecommendedCareers}
                    capDate={capDate}
                    setCapDate={setCapDate}
                    coacheeName={
                        coacheeDetails.firstName === undefined
                            ? ""
                            : coacheeDetails.firstName + " " + coacheeDetails.lastName
                    }
                    loading={coacheeDetailsLoading}
                    saveLoading={updateLoading}
                    disabled={coacheeDetailsLoading}
                />
            ),
            hidden: process.env.REACT_APP_ENV === "PRD" || context.defaultApp !== EMPLOYMENT_SERVICES,
            headerElements: (
                <div className="flex flex-row gap-2">
                    {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DOWNLOAD_PDF_REPORT) && (
                        <>
                            <Button
                                onClick={() => handleDownloadCareerActionPlan("pdf")}
                                text={"Download PDF"}
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3 w-3 mr-1" />}
                                iconPositionLeft
                                loading={downloadingCap}
                                loadingIconSize={"h-3 w-3 mr-1"}
                                disabled={
                                    (resources.length === 0 &&
                                        goals.length === 0 &&
                                        recommendedJobs.length === 0 &&
                                        recommendedCareers.length === 0 &&
                                        capDate === "") ||
                                    blankCareerActionPlan
                                }
                                smallButton
                            />
                            <Button
                                onClick={() => handleDownloadCareerActionPlan("word")}
                                text={"Download Word"}
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3 w-3 mr-1" />}
                                iconPositionLeft
                                loadingIconSize={"h-3 w-3 mr-1"}
                                disabled
                                smallButton
                            />
                        </>
                    )}
                </div>
            ),
            onSaveChanges:
                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.CREATE_USER_METADATA) &&
                handleUpdateCareerActionPlan,
            backgroundColour: "bg-white",
        });
        tabList.push("Career Action Plan");
    }

    return (
        <>
            {
                <DashboardLayout
                    title={
                        (context.terminology.coachee ? context.terminology.coachee.singular : "Coachee") +
                        " profile: " +
                        coacheeDetails.firstName +
                        " " +
                        coacheeDetails.lastName
                    }
                    requiredPermissions={[]}
                    loading={coacheeDetailsLoading}
                >
                    <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                    <TabbedNavigationLayout
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabList={tabList}
                        backgroundColour={"bg-slate-100"}
                        loading={updateLoading}
                        tabsRowOnly
                        tabContent={tabContent}
                        handleShowNavigationConfirmation={handleShowNavigationConfirmation}
                    />
                    <Modal
                        active={showPrompt}
                        content={
                            <div className="flex flex-col gap-4">
                                <div
                                    className={
                                        "border-0 rounded-xl relative flex flex-col gap-6 w-full bg-white outline-none " +
                                        "focus:outline-none"
                                    }
                                >
                                    <div className="flex flex-col gap-3 pt-5 px-5 rounded-t-xl">
                                        <h2 className="text-primary-600 font-medium font-title tracking-tight">
                                            Are you sure you want to leave this page?
                                        </h2>
                                        <p className="text-slate-500 text-sm">You may have unsaved changes</p>
                                    </div>
                                    <div
                                        className={
                                            "flex items-center gap-3 justify-end px-5 py-4 rounded-b-xl bg-gradient-to-l " +
                                            "from-primary-50 to-primary-100"
                                        }
                                    >
                                        <Button
                                            text="Leave this page"
                                            onClick={confirmNavigation}
                                            colour={BUTTON_VARIANT_TYPES.GRAY}
                                            smallButton
                                        />
                                        <Button
                                            text="Stay on this page"
                                            onClick={cancelNavigation}
                                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                            smallButton
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </DashboardLayout>
            }
        </>
    );
}
