import { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";

function Percentile(props) {
    const { size, percentile, colourPalette, scaleIndex } = props;

    const [percentileBackgroundColour, setPercentileBackgroundColour] = useState("bg-slate-300");
    const [textColor, setTextColor] = useState("text-slate-50");

    const handleSetColour = useCallback(async () => {
        setTimeout(() => {
            setPercentileBackgroundColour(
                colourPalette[scaleIndex]
                    ? `lg:bg-[${colourPalette[scaleIndex]}]`
                    : `lg:bg-[${colourPalette[colourPalette.length - 1]}]`
            );
            setTextColor(
                colourPalette[scaleIndex]
                    ? `text-[${colourPalette[scaleIndex]}]`
                    : `text-[${colourPalette[colourPalette.length - 1]}]`
            );
        }, 100);
    }, [colourPalette, scaleIndex]);

    useEffect(() => {
        handleSetColour(percentile);
    }, [handleSetColour, percentile]);

    return (
        <div
            className={
                "h-full py-4 lg:py-0 lg:px-8 flex items-center justify-start lg:justify-center lg:text-slate-50 lg:shadow-sm " +
                textColor +
                " transition transition-color duration-4000 ease-in-out bg-transparent " +
                percentileBackgroundColour +
                (size === " large" ? " h-full w-full rounded-xl " : " w-full rounded-xl")
            }
        >
            <span className={(size === "large" ? "lg:text-5xl text-4xl  " : "text-xl  ") + "font-medium"}>
                <CountUp end={percentile} duration={4} />
            </span>
            <span className={(size === "large" ? "text-xl  " : "text-md  ") + "text-2xl"}>%ile</span>
        </div>
    );
}

export default Percentile;
