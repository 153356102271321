import { useCallback, useEffect, useState } from "react";

import Pagination from "./Pagination";
import Cards from "./Cards";
import Table from "./Table";
import { ALERT_TYPE } from "../Alert";
import Input from "../Form/Input";

export default function PaginatedSearchPageFetched(props) {
    const {
        itemName,
        ItemRow,
        columnNames,
        noTableHead,
        cards,
        filters,
        itemRowToggleOnChange,
        noPreviousAccessUser,
        accessDefaultToggleState,
        latestItemInteracted,
        updateItemStatus,
        setAlert,
        setAlertType,
        fetchItems,
        overflowVisible,
        inputBackgroundColour,
        fetchList,
        customPlaceholder,
        customCardGrid,
        customCurrentPage,
        customPageSize,
        customItemsProcessor,
    } = props;

    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [itemsArray, setItemsArray] = useState(true);
    const [paginationLoading, setPaginationLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const fetchPage = useCallback(
        async (selectedPage, searchTerm) => {
            setPaginationLoading(true);
            try {
                let response = await fetchList(selectedPage.selected, pageSize, searchTerm);

                if (customItemsProcessor) {
                    response = customItemsProcessor(response);
                }

                setPageCount(response.totalPages);
                setItems(response.data);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setPaginationLoading(false);
        },
        [customItemsProcessor, fetchList, pageSize, setAlert, setAlertType]
    );

    useEffect(() => {
        fetchPage({ selected: 0 }, searchTerm);
    }, [fetchPage, searchTerm]);

    useEffect(() => {
        if (Array.isArray(items)) {
            setItemsArray(true);
        } else {
            setItemsArray(false);
        }
    }, [items]);

    useEffect(() => {
        if (customPageSize) {
            setPageSize(customPageSize);
        }

        if (customCurrentPage) {
            fetchPage(customCurrentPage);
        } else {
            fetchPage({ selected: 0 });
        }
    }, [customCurrentPage, customPageSize, fetchPage]);

    return (
        <div className="flex flex-col gap-4">
            <Input
                value={searchTerm}
                onChange={setSearchTerm}
                type="text"
                name="search"
                id="search"
                placeholder={customPlaceholder ? customPlaceholder : "Search " + itemName}
                backgroundColour={inputBackgroundColour ? inputBackgroundColour : "bg-white"}
            />
            {filters && <div className={"flex gap-2"}>{filters}</div>}
            {cards ? (
                <Cards
                    customCardGrid={customCardGrid}
                    loading={paginationLoading}
                    paginationLoading={paginationLoading}
                    itemsArray={itemsArray}
                    items={items}
                    ItemRow={ItemRow}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    updateItemStatus={updateItemStatus}
                />
            ) : (
                <Table
                    customCardGrid={customCardGrid}
                    loading={paginationLoading}
                    paginationLoading={paginationLoading}
                    itemsArray={itemsArray}
                    items={items}
                    ItemRow={ItemRow}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    updateItemStatus={updateItemStatus}
                    overflowVisible={overflowVisible}
                    noTableHead={noTableHead}
                    columnNames={columnNames}
                    itemRowToggleOnChange={itemRowToggleOnChange}
                    noPreviousAccessUser={noPreviousAccessUser}
                    accessDefaultToggleState={accessDefaultToggleState}
                    latestItemInteracted={latestItemInteracted}
                    fetchItems={fetchItems}
                />
            )}
            {items.length !== 0 && (
                <div className={"flex items-center justify-center w-full"}>
                    <Pagination handlePageClick={fetchPage} pageCount={pageCount} />
                </div>
            )}
        </div>
    );
}
