import ReactSelect, { components } from "react-select";
import Creatable from "react-select/creatable";

export default function MultiSelect(props) {
    const {
        items,
        labelText,
        customOnChange,
        setChosenItems,
        chosenItems,
        loading,
        isMulti,
        value,
        extraStyling,
        isCreatable,
        placeholder,
        isSubLabel,
        backgroundColour,
        iconBackgroundColour,
        textColour,
        disabled,
        icon,
        hidden,
        optional,
        subLabel,
        inputId,
        name,
        fontWeight,
        extraElements,
        menuIsOpen,
        extraSelectStyling,
    } = props;

    const customReactSelectStyles = {
        control: () => ({
            display: "flex",
            alignItems: "center",
            width: "100%",
            backgroundColor: backgroundColour ? backgroundColour : "#F8FAFC",
            borderRadius: icon ? "0rem 0.75rem 0.75rem 0rem " : "0.75rem",
            minHeight: "2.25rem",
            borderTopWidth: "1px",
            borderBottomWidth: "1px",
            borderRightWidth: "1px",
            borderLeftWidth: "1px",
            borderColor: "#f1f5f9",
            borderStyle: "solid",
        }),

        multiValue: () => ({
            display: "flex",
            alignItems: "center",
            borderRadius: "0.75rem",
            backgroundColor: "#e2e8f0",
            marginRight: "8px",
            color: "#64748b",
            marginTop: "2px",
            marginBottom: "2px",
        }),

        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? undefined : isSelected ? "#3b82f6" : isFocused ? "#dbeafe" : undefined,
            };
        },

        multiValueLabel: (base) => ({
            ...base,
            color: "#475569",
        }),

        menuList: () => ({
            paddingBottom: "0px",
            paddingTop: "0px",
            marginBottom: "0px",
            marginTop: "2px",
            maxHeight: "224px",
            color: "#475569",
            overflowY: "auto",
            borderRadius: "0.75rem",
            borderWidth: "1px",
            borderColor: "#f1f5f9",
            boxShadow: "0 10px 15px -3px rgb(0, 0, 0, 0.1);",
            backgroundColor: backgroundColour ? backgroundColour : "#F1F5F9",
        }),

        menu: () => ({
            marginTop: "0px",
            position: "absolute",
            width: "100%",
            zIndex: "101",
            paddingBottom: "0px",
            paddingTop: "0px",
        }),

        singleValue: (base) => ({
            ...base,
            color: "#475569",
        }),

        container: () => ({
            width: "100%",
            position: "relative",
        }),
    };

    const SingleValue = (props) => {
        const { label, subLabel } = props.data;

        return (
            <components.SingleValue {...props}>
                {label} <span className="">{subLabel}</span>
            </components.SingleValue>
        );
    };

    const Option = (props) => {
        const { label, subLabel } = props.data;

        return (
            <components.Option {...props}>
                <div className="flex flex-col gap-1">
                    <div>{label}</div>
                    <span className=" text-xs">{subLabel}</span>
                </div>
            </components.Option>
        );
    };

    const Placeholder = () => {
        return (
            <div className="text-slate-400">
                {loading
                    ? "Loading  " + (labelText ? labelText.toLowerCase() : " ") + " list .."
                    : placeholder
                    ? placeholder
                    : "Search and select " + (labelText ? labelText.toLowerCase() : "")}
            </div>
        );
    };

    return (
        <div
            className={
                (hidden ? "hidden " : "") +
                "text-sm flex flex-col gap-2 " +
                extraStyling +
                (disabled ? " opacity-70 " : "") +
                " " +
                (textColour && textColour)
            }
        >
            {labelText && (
                <div className="flex flex-row justify-between gap-2 items-center">
                    <label
                        htmlFor={labelText}
                        className={
                            "text-sm font-medium w-full " + (isSubLabel ? "text-secondary-500" : "text-primary-600")
                        }
                    >
                        {labelText}
                    </label>
                    {extraElements}
                </div>
            )}

            {!isMulti && !isCreatable && (
                <div
                    className={
                        (fontWeight ? fontWeight : "font-normal ") +
                        extraSelectStyling +
                        " flex rounded-xl shadow-sm w-full "
                    }
                >
                    {icon && (
                        <span
                            className={
                                "inline-flex items-center px-3 rounded-l-xl " +
                                "text-sm " +
                                (textColour ? textColour : "text-slate-500 ") +
                                " " +
                                (iconBackgroundColour
                                    ? iconBackgroundColour
                                    : backgroundColour === "bg-white"
                                    ? "bg-slate-50"
                                    : "bg-slate-200")
                            }
                        >
                            {icon}
                        </span>
                    )}
                    <ReactSelect
                        {...(menuIsOpen ? { menuIsOpen: menuIsOpen } : {})}
                        onChange={
                            customOnChange
                                ? customOnChange
                                : setChosenItems
                                ? (e) => (e.value === undefined ? setChosenItems(e) : setChosenItems(e.value))
                                : null
                        }
                        classNamePrefix={"react-select"}
                        options={items && items}
                        styles={customReactSelectStyles}
                        placeholder={<Placeholder />}
                        isLoading={loading && loading}
                        value={value && items.filter((option) => option.value === value)}
                        isDisabled={disabled && disabled}
                        components={subLabel && { SingleValue, Option }}
                        getOptionLabel={
                            subLabel &&
                            ((options) => {
                                return `${options.label} ${options.subLabel}`;
                            })
                        }
                        inputId={inputId}
                        name={name && name}
                    />
                </div>
            )}

            {!isMulti && isCreatable && (
                <div className={"flex rounded-xl shadow-sm w-full"}>
                    {icon && (
                        <span
                            className={
                                "inline-flex items-center px-3 rounded-l-xl text-slate-500 " +
                                "text-sm " +
                                (backgroundColour === "bg-white" ? "bg-slate-50" : "bg-slate-200")
                            }
                        >
                            {icon}
                        </span>
                    )}
                    <Creatable
                        onChange={
                            setChosenItems
                                ? (e) => (e.value === undefined ? setChosenItems(e) : setChosenItems(e.value))
                                : null
                        }
                        classNamePrefix={"react-select"}
                        options={items && items}
                        styles={customReactSelectStyles}
                        placeholder={<Placeholder />}
                        isLoading={loading && loading}
                        value={value && items.filter((option) => option.value === value)}
                        isDisabled={disabled && disabled}
                        inputId={inputId}
                        name={name && name}
                    />
                </div>
            )}

            {isMulti && !isCreatable && (
                <div className={"flex rounded-xl shadow-sm w-full"}>
                    {icon && (
                        <span
                            className={
                                "inline-flex items-center px-3 rounded-l-xl text-slate-500 " +
                                "text-sm " +
                                (backgroundColour === "bg-white" ? "bg-slate-50" : "bg-slate-200")
                            }
                        >
                            {icon}
                        </span>
                    )}
                    <ReactSelect
                        isMulti={isMulti}
                        onChange={
                            setChosenItems
                                ? (e) => (e.value === undefined ? setChosenItems(e) : setChosenItems(e.value))
                                : null
                        }
                        classNamePrefix={"react-select"}
                        options={items && items}
                        styles={customReactSelectStyles}
                        placeholder={<Placeholder />}
                        isLoading={loading && loading}
                        value={chosenItems}
                        isDisabled={disabled && disabled}
                        inputId={inputId}
                        name={name && name}
                    />
                </div>
            )}

            {isMulti && isCreatable && (
                <div className={"flex rounded-xl shadow-sm w-full"}>
                    {icon && (
                        <span
                            className={
                                "inline-flex items-center px-3 rounded-l-xl text-slate-500 " +
                                "text-sm " +
                                (backgroundColour === "bg-white" ? "bg-slate-50" : "bg-slate-200")
                            }
                        >
                            {icon}
                        </span>
                    )}
                    <Creatable
                        isMulti={isMulti}
                        onChange={
                            setChosenItems
                                ? (e) => (e.value === undefined ? setChosenItems(e) : setChosenItems(e.value))
                                : null
                        }
                        classNamePrefix={"react-select"}
                        options={items && items}
                        styles={customReactSelectStyles}
                        placeholder={<Placeholder />}
                        isLoading={loading}
                        value={chosenItems}
                        isDisabled={disabled && disabled}
                        inputId={inputId}
                        name={name && name}
                    />
                </div>
            )}
            {optional && <div className={"text-xs text-slate-400 w-full text-right mt-0.5 right-0 pt-1"}>Optional</div>}
        </div>
    );
}
