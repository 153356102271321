import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { DocumentMagnifyingGlassIcon, CogIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import mime from "mime";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";
import { downloadLatestReportForAssessment } from "../../../../utils/api";
import { Context } from "../../../../context/Context";

import Button from "../../../SharedComponents/Button";
import Tag from "../../../SharedComponents/Tag";

export default function ReportRow(props) {
    const { item: report, setAlert } = props;

    const [downloading, setDownloading] = useState(false);

    const context = useContext(Context);

    const navigate = useNavigate();

    let latestReport = "";

    latestReport = report?.documentVersions?.reduce((prev, current) =>
        prev.versionNumber > current.versionNumber ? prev : current
    );

    const latestReportVersion = Math.max(...report?.documentVersions?.map((report) => report.versionNumber));

    const handleDownload = async (documentIdentifier) => {
        setDownloading(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DOWNLOAD_REPORTS)) {
            setAlert("You do not have the necessary permission to download a report");
            setDownloading(false);
            return;
        }

        const documentResponse = await downloadLatestReportForAssessment(documentIdentifier);

        const { fileType } = documentResponse;

        if (!fileType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const blob = await (await fetch(`data:${mimeType};base64,${documentResponse.content}`)).blob();

        if (!blob) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const downloadLink = document.createElement("a");

        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = documentResponse.fileName.split(".").join("_");
        downloadLink.click();

        setDownloading(false);
    };

    return (
        <tr>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex flex-col gap-1">
                    <div className="text-sm font-medium text-primary-600">{report.userName}</div>
                    <div className="text-sm text-slate-500 group-hover:text-primary-600 transition-colors">
                        {report.userEmail}
                    </div>
                    {report.jobSeekerId && (
                        <div className="text-xs text-secondary-600 ">
                            JSID: <span className="font-medium">{report.jobSeekerId}</span>
                        </div>
                    )}
                </div>
            </td>
            <td className="px-4 py-3">
                <div className={"flex flex-row gap-3 items-center"}>
                    <div className={"text-sm text-primary-600 font-medium"}>{report.name}</div>
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap max-w-xs">
                <div className={"text-xs text-slate-500 flex flex-wrap gap-2"}>
                    {report.tags.length > 0
                        ? report.tags.map((tag, index) => (
                              <div className="text-center" key={index}>
                                  <div className="rounded-xl bg-slate-200 p-1 px-2 shadow-sm">{tag}</div>
                              </div>
                          ))
                        : "-"}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap max-w-xs">
                <div className={"text-xs text-slate-500 flex flex-wrap gap-2"}>
                    {report.cohorts.length > 0
                        ? report.cohorts.map((cohort, index) => <Tag key={index} status={cohort} />)
                        : "-"}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-xs text-slate-500"}>{latestReportVersion}</div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center">
                    <Tag status={"Released"} />
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                {latestReport?.fileFormat === "JSON" || latestReport?.fileFormat === "json" ? (
                    <Button
                        colour={
                            context.isNativeApps ? BUTTON_VARIANT_TYPES.SECONDARY : BUTTON_VARIANT_TYPES.DARK_SECONDARY
                        }
                        icon={<DocumentMagnifyingGlassIcon className="block h-5 w-5" />}
                        extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full"}
                        iconPositionLeft
                        smallButton
                        onClick={() =>
                            navigate("/view-report", {
                                state: {
                                    userName: report.userName,
                                    reportName: report.name,
                                    reportIdentifier: report.identifier,
                                },
                            })
                        }
                        loading={downloading}
                        loadingIconSize={"h-3.5 w-3.5"}
                    />
                ) : (
                    <Button
                        colour={
                            context.isNativeApps ? BUTTON_VARIANT_TYPES.SECONDARY : BUTTON_VARIANT_TYPES.DARK_SECONDARY
                        }
                        icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5" />}
                        extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full"}
                        iconPositionLeft
                        smallButton
                        onClick={() => handleDownload(report.identifier)}
                        loading={downloading}
                        loadingIconSize={"h-3.5 w-3.5"}
                    />
                )}
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <Button
                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                    icon={<CogIcon className="shrink-0 block h-5 w-5" />}
                    extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full"}
                    iconPositionLeft
                    smallButton
                    onClick={() =>
                        navigate("/report", {
                            state: { report: report },
                        })
                    }
                />
            </td>
        </tr>
    );
}
