import { ArrowTrendingUpIcon } from "@heroicons/react/24/solid";

import { BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../../utils/constants";

import BarChart from "../../../Charts/CustomCharts/BarChart";
import TrendBubble from "../../../SharedChartComponents/TrendBubble";

export default function SatisfactionOnSixMainDimensions(props) {
    const { data, detailed, demo, noInfoBubbles } = props;

    return (
        <div
            className={
                "flex flex-col gap-4 w-full bg-white p-4 rounded-xl border border-slate-100 shadow-sm " +
                "print:p-0 print:border-0 print:shadow-none"
            }
        >
            <div className="flex flex-col gap-3">
                <BarChart
                    noInfoBubbles={noInfoBubbles}
                    onlySectorBenchmarkToggle
                    emphasiseSectorBenchmark
                    multiple
                    infoBubble
                    colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                    title={"Satisfaction on six main dimensions"}
                    subtitle={
                        detailed && demo
                            ? "This shows how the group feels in the six dimensions that drive satisfaction, " +
                              "with comparisons to the Franchise Sector Benchmark."
                            : data && data.description
                    }
                    extraContent={
                        demo && (
                            <div
                                className={
                                    "flex flex-row items-center gap-2 py-1.5 px-2 rounded-xl bg-emerald-50 border " +
                                    "border-emerald-200 text-emerald-600"
                                }
                            >
                                <ArrowTrendingUpIcon className={"h-5 w-5"} />
                                <span className="text-sm font-medium">
                                    Noteworthy trend: Younger franchisees are significantly more satisfied in both
                                    Achievement and Lifestyle.
                                </span>
                            </div>
                        )
                    }
                    trendBubble={demo && <TrendBubble percentage={16} text="vs 2022" />}
                    topXAxis
                    noBottomAxis
                    data={
                        demo
                            ? [
                                  {
                                      category: "Dimensions",
                                      name: "Achievement",
                                      percentScore: 35,
                                  },
                                  {
                                      category: "Sector benchmark",
                                      name: "Sector benchmark",
                                      percentScore: 35,
                                  },
                                  {
                                      category: "Dimensions",
                                      name: "Lifestyle",
                                      percentScore: 56,
                                  },
                                  {
                                      category: "Sector benchmark",
                                      name: "Sector benchmark",
                                      percentScore: 46,
                                  },
                                  {
                                      category: "Dimensions",
                                      name: "Leadership",
                                      percentScore: 87,
                                  },
                                  {
                                      category: "Sector benchmark",
                                      name: "Sector benchmark",
                                      percentScore: 80,
                                  },
                                  {
                                      category: "Dimensions",
                                      name: "Connection",
                                      percentScore: 57,
                                  },
                                  {
                                      category: "Sector benchmark",
                                      name: "Sector benchmark",
                                      percentScore: 69,
                                  },
                                  {
                                      category: "Dimensions",
                                      name: "Partnership",
                                      percentScore: 78,
                                  },
                                  {
                                      category: "Sector benchmark",
                                      name: "Sector benchmark",
                                      percentScore: 69,
                                  },
                              ]
                            : data
                    }
                />
            </div>
        </div>
    );
}
