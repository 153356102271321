import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

import {
    assignCohortToUsers,
    updateCoacheeStatus,
    updateCohortName,
    updateCohortStatus,
} from "../../../../../utils/api";

import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import CohortName from "./CohortName";
import CohortStatus from "./CohortStatus";
import CohortCoachees from "./CohortCoachees";
import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import { Context } from "../../../../../context/Context";

export default function Cohort() {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [cohortNewName, setCohortNewName] = useState();
    const [coacheeIdentifierList, setCoacheeIdentifierList] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();

    const context = useContext(Context);

    const handleUpdateCoacheeStatus = async (coacheeStatus, userIdentifier) => {
        setLoading(true);
        try {
            await updateCoacheeStatus(coacheeStatus, userIdentifier);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully updated " + context.terminology.coachee.singular.toLowerCase() + "'s status");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    const handleUpdateCohortCoachees = async () => {
        setLoading(true);
        try {
            await assignCohortToUsers(coacheeIdentifierList, location.state.cohort.name);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited cohort members");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    const handleUpdateCohortStatus = async (status) => {
        setLoading(true);
        try {
            await updateCohortStatus(status, location.state.cohort.name);
            location.state.cohort.status = status;
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited cohort status!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    const handleUpdateCohortName = async () => {
        setLoading(true);
        try {
            await updateCohortName(location.state.cohort.name, cohortNewName);
            location.state.cohort.name = cohortNewName;
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited cohort name!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (location.state === null) {
            navigate("/cohorts");
        } else if (location.state.tabIndex) {
            setTabIndex(location.state.tabIndex);
        }
    }, [location.state, navigate]);

    return (
        <>
            {location.state !== null && (
                <DashboardLayout title={"Cohort: " + location.state.cohort.name} requiredPermissions={[]}>
                    <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                    <TabbedNavigationLayout
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabList={["Name & program"].concat([
                            "Cohort & " + context.terminology.coachee.singular + " status",
                            "Members",
                        ])}
                        backgroundColour={"bg-slate-100"}
                        loading={loading}
                        tabContent={[
                            {
                                title: "Name & program",
                                content: (
                                    <CohortName cohort={location.state.cohort} setCohortNewName={setCohortNewName} />
                                ),
                                onSaveChanges: handleUpdateCohortName,
                            },
                            {
                                title: "Cohort & " + context.terminology.coachee.singular + " status",
                                content: (
                                    <CohortStatus
                                        cohort={location.state.cohort}
                                        updateCohortStatus={handleUpdateCohortStatus}
                                        updateCoacheeStatus={handleUpdateCoacheeStatus}
                                        handleSaveCohortStatus
                                    />
                                ),
                                onSaveChanges: handleUpdateCohortStatus,
                            },
                            {
                                title: "Members",
                                content: (
                                    <CohortCoachees
                                        cohort={location.state.cohort}
                                        coacheeIdentifierList={coacheeIdentifierList}
                                        setCoacheeIdentifierList={setCoacheeIdentifierList}
                                        updateCoacheeStatus={handleUpdateCoacheeStatus}
                                    />
                                ),
                                onSaveChanges: handleUpdateCohortCoachees,
                            },
                        ]}
                    />
                </DashboardLayout>
            )}
        </>
    );
}
