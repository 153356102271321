import BouncingBalls from "../Loading/BouncingBalls";
import BasicMotion from "../Motion/BasicMotion";

export default function Table(props) {
    const {
        loading,
        itemsArray,
        items,
        ItemRow,
        setAlert,
        setAlertType,
        updateItemStatus,
        overflowVisible,
        noTableHead,
        columnNames,
        itemRowToggleOnChange,
        noPreviousAccessUser,
        accessDefaultToggleState,
        latestItemInteracted,
        fetchItems,
    } = props;

    return (
        <div
            className={
                " shadow-sm rounded-xl bg-white border border-slate-100 border-1 " +
                (overflowVisible ? "overflow-visible" : "overflow-hidden")
            }
        >
            <table className={"min-w-full"}>
                {!noTableHead && (
                    <thead className={"bg-slate-50"}>
                        <tr className={""}>
                            {columnNames.map((columnName, index) => (
                                <th
                                    key={index}
                                    scope={"col"}
                                    className={
                                        "px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase " +
                                        "overflow-hidden " +
                                        (index === 0
                                            ? "rounded-tl-xl"
                                            : index === columnNames.length - 1 && "rounded-tr-xl")
                                    }
                                >
                                    {columnName}
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={columnNames.length}>
                                <div className={"flex items-center justify-center pt-20 pb-24"}>
                                    <BouncingBalls />
                                </div>
                            </td>
                        </tr>
                    ) : items.length === 0 ? (
                        <tr>
                            <td colSpan={columnNames.length}>
                                {!itemsArray ? (
                                    <BasicMotion>
                                        <div
                                            className={
                                                "col-span-full flex flex-col gap-3 w-full p-4 bg-white rounded-xl"
                                            }
                                        >
                                            <span className={"text-base font-medium text-primary-600"}>
                                                Sorry, there was an issue retrieving items for this list.
                                            </span>
                                            <div className={"text-sm text-slate-500"}>
                                                Please refresh the page and{" "}
                                                <a
                                                    className={"font-semibold underline"}
                                                    href="mailto:techsupport@psychpress.com.au"
                                                >
                                                    contact us
                                                </a>{" "}
                                                if this error persists.
                                            </div>
                                        </div>
                                    </BasicMotion>
                                ) : (
                                    <BasicMotion>
                                        <div
                                            className={
                                                "col-span-full flex flex-col gap-3 w-full p-4 bg-white rounded-xl"
                                            }
                                        >
                                            <span className={"text-base font-medium text-primary-600"}>
                                                Sorry, this list is empty or there were no results for your search.
                                            </span>
                                            <div className={"text-sm text-slate-500"}>
                                                If searching, check your search term and try again or
                                                <a
                                                    className={"font-semibold underline"}
                                                    href="mailto:techsupport@psychpress.com.au"
                                                >
                                                    {" "}
                                                    contact us{" "}
                                                </a>{" "}
                                                if you think there's been an error.
                                            </div>
                                        </div>
                                    </BasicMotion>
                                )}
                            </td>
                        </tr>
                    ) : (
                        items.map((item, index) => (
                            <ItemRow
                                key={index}
                                item={item}
                                itemRowToggleOnChange={itemRowToggleOnChange}
                                noPreviousAccessUser={noPreviousAccessUser}
                                accessDefaultToggleState={accessDefaultToggleState}
                                latestItemInteracted={latestItemInteracted}
                                updateItemStatus={updateItemStatus}
                                setAlert={setAlert}
                                setAlertType={setAlertType}
                                fetchItems={fetchItems}
                                lastItem={items.length - 1 === index}
                            />
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
}
