import { CheckIcon, PaperAirplaneIcon, PencilIcon } from "@heroicons/react/24/solid";
import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";

import Button from "../../../SharedComponents/Button";

export default function SetupMultipleCoacheeRow(props) {
    const { item: coachee } = props;

    return (
        <tr className={"hover:bg-slate-100 transition-colors group"}>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm font-medium text-primary-600"}>
                    {coachee.firstName + " " + coachee.lastName}
                </div>
                <div className="text-sm text-slate-500 mt-1 group-hover:text-primary-600 transition-colors">
                    {coachee.emailAddress}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm text-slate-500 group-hover:text-primary-600 transition-colors"}>
                    {coachee.role}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm text-slate-500 group-hover:text-primary-600 transition-colors"}>
                    {coachee.region}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm text-slate-500 group-hover:text-primary-600 transition-colors"}>
                    {coachee.regionalManager}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    icon={<PencilIcon className="block h-3.5 w-3.5" />}
                    extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full p-2"}
                    iconPositionLeft
                />
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                {coachee.emailSent ? (
                    <div className="flex flex-row gap-2 items-center">
                        <CheckIcon className="block text-emerald-600 stroke-current stroke-1 h-3.5 w-3.5" />{" "}
                        <span className="text-xs text-emerald-600">Invitation sent!</span>
                    </div>
                ) : (
                    <Button
                        text={"Send invitation"}
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        icon={<PaperAirplaneIcon className="block h-3.5 w-3.5 ml-2" />}
                        smallButton
                    />
                )}
            </td>
        </tr>
    );
}
