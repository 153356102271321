import { useContext, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import { LINE_CHART_COLOUR_HEX_CODES } from "../../../../../../../../utils/constants";

import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import LineChartCard from "../../ChartCards/LineChartCard";

export default function SentimentOvertime(props) {
    const {
        displayOnlyContent,
        data,
        hasSixDimensionsRegionalHistoricalData,
        hasGeneralSatisfactionHistoricalData,
        hasSixDimensionsHistoricalData,
    } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const context = useContext(Context);

    let tabList = [];
    let tabContent = [];

    let currentPerDimensionData;

    if (data) {
        currentPerDimensionData = data;
    } else {
        currentPerDimensionData = context.aceSurveyReportData.sixDimensionsRegional;
    }

    let generalSatisfactionLineChartData = [];

    if (hasGeneralSatisfactionHistoricalData) {
        generalSatisfactionLineChartData.push({
            id: "Overall",
            color: LINE_CHART_COLOUR_HEX_CODES[0],
            data: context.aceSurveyReportData.generalSatisfaction[0].historicalScores
                .map((historicalScore) => ({
                    x: historicalScore.name,
                    y: historicalScore.percentScore,
                }))
                .sort((a, b) => {
                    return parseInt(a.x) - parseInt(b.x);
                }),
        });
    }

    if (hasSixDimensionsRegionalHistoricalData) {
        context.aceSurveyReportData.sixDimensionsRegional
            .find(
                (overallScoreStateLevelScore) => overallScoreStateLevelScore.name === "General Satisfaction - Regional"
            )
            .items.map((generalSatisfactionHistoricalItem, index) =>
                generalSatisfactionLineChartData.push({
                    id: generalSatisfactionHistoricalItem.name,
                    color: LINE_CHART_COLOUR_HEX_CODES[index + 1],
                    data: generalSatisfactionHistoricalItem.historicalScores
                        .map((historicalScore) => ({
                            x: historicalScore.name,
                            y: historicalScore.percentScore,
                        }))
                        .sort((a, b) => parseInt(a.x) - parseInt(b.x)),
                })
            );
    }

    tabList.push("General Satisfaction");
    tabContent.push({
        content: <LineChartCard title={"General Satisfaction"} data={generalSatisfactionLineChartData} />,
        readonly: true,
    });

    if (hasSixDimensionsRegionalHistoricalData && hasSixDimensionsHistoricalData) {
        currentPerDimensionData.map(
            (dimension) =>
                dimension.name !== "General Satisfaction - Regional" &&
                tabList.push(dimension.name.replace("- Regional", "")) &&
                tabContent.push({
                    content: (
                        <LineChartCard
                            title={dimension.name.replace("- Regional", "")}
                            data={[
                                {
                                    id: "Overall",
                                    color: LINE_CHART_COLOUR_HEX_CODES[0],
                                    data: context.aceSurveyReportData.sixDimensions
                                        .find((sixDimensionsDimension) =>
                                            dimension.name.includes(sixDimensionsDimension.name)
                                        )
                                        .historicalScores.map((historicalScore) => ({
                                            x: historicalScore.name,
                                            y: historicalScore.percentScore,
                                        }))
                                        .sort((a, b) => {
                                            return parseInt(a.x) - parseInt(b.x);
                                        }),
                                },
                            ].concat(
                                dimension.overallScoreStateLevel.map((overallScoreStateLevelScore, index) => ({
                                    id: overallScoreStateLevelScore.name,
                                    color: LINE_CHART_COLOUR_HEX_CODES[index + 1],
                                    data: overallScoreStateLevelScore.historicalScores
                                        .map((historicalScore) => ({
                                            x: historicalScore.name,
                                            y: historicalScore.percentScore,
                                        }))
                                        .sort((a, b) => {
                                            return parseInt(a.x) - parseInt(b.x);
                                        }),
                                }))
                            )}
                        />
                    ),
                    readonly: true,
                })
        );
    }

    if (!hasSixDimensionsRegionalHistoricalData && hasSixDimensionsHistoricalData) {
        context.aceSurveyReportData.sixDimensions.map(
            (dimension) =>
                tabList.push(dimension.name) &&
                tabContent.push({
                    content: (
                        <LineChartCard
                            title={dimension.name}
                            data={[
                                {
                                    id: "Overall",
                                    color: LINE_CHART_COLOUR_HEX_CODES[0],
                                    data: context.aceSurveyReportData.sixDimensions
                                        .find((sixDimensionsDimension) =>
                                            dimension.name.includes(sixDimensionsDimension.name)
                                        )
                                        .historicalScores.map((historicalScore) => ({
                                            x: historicalScore.name,
                                            y: historicalScore.percentScore,
                                        }))
                                        .sort((a, b) => {
                                            return parseInt(a.x) - parseInt(b.x);
                                        }),
                                },
                            ]}
                        />
                    ),
                    readonly: true,
                })
        );
    }

    return (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            tabContent={tabContent}
            tabsRowOnly
            secondaryTabs
            gapLessTabsAndContent
            noContainer
            displayOnlyContent={displayOnlyContent}
            noTitle
        />
    );
}
