import { useEffect, useState } from "react";
import { downloadLatestReportForAssessment } from "../../../../../utils/api";
import { useNavigate, useLocation } from "react-router-dom";
import { Buffer } from "buffer";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, REPORT_NAMES } from "../../../../../utils/constants";
import { ReactComponent as NoResultsIllustration } from "../../../../../assets/no-result-found.svg";

import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import Button from "../../../../SharedComponents/Button";
import HeadFirst from "./HeadFirst/HeadFirst";
import BouncingBalls from "../../../../SharedComponents/Loading/BouncingBalls";
import JobReadiness from "./JobReadiness/JobReadiness";
import Interests from "./CareeringAhead/Interests";
import InitialProgramQuestionnaire from "./JobReadiness/InitialProgramQuestionnaire";
import { SaleableMeat } from "./Saleable/Saleable";
import { MenuDrivenMeat } from "./MenuDriven/MenuDriven";
import { CapabilityProfileMeat } from "./CapabilityCo/CapabilityProfile/CapabilityProfile";
import CapabilityMap from "./CapabilityCo/CapabilityMap/CapabilityMap";

function ReportContent() {
    const [score, setScore] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");

    const location = useLocation();

    const navigate = useNavigate();

    const findScoreByReportName = (score, reportName) =>
        score.find((scoreItem) => scoreItem.reportName.toLowerCase() === reportName.toLowerCase());

    useEffect(() => {
        const fetchReportContent = async () => {
            setLoading(true);
            try {
                const documentResponse = await downloadLatestReportForAssessment(location.state.reportIdentifier);

                const score = JSON.parse(Buffer.from(documentResponse.content, "base64").toString());

                const headFirstScore = score.length >= 2 ? findScoreByReportName(score, "headfirst") || false : false;

                if (headFirstScore) {
                    setScore(headFirstScore);
                } else {
                    setScore(score);
                }
            } catch (error) {
                setScore(null);
                setAlert(error.message);
            }
            setLoading(false);
        };

        if (location.state?.reportIdentifier) {
            fetchReportContent();
        } else {
            navigate("/reports");
        }
    }, [location.state?.reportIdentifier, navigate]);

    return (
        <DashboardLayout
            {...(score?.reportName !== "Capability Profile" && { title: "Report" })}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS]}
        >
            {loading ? (
                <div className={"col-span-full flex items-center justify-center pt-20 pb-24 w-full"}>
                    <BouncingBalls />
                </div>
            ) : score?.reportName === REPORT_NAMES.INTOJOBS_CLIENT_ASSESSMENT_FORM ||
              score?.reportName === REPORT_NAMES.INTOJOBS_PARTICIPANT_ASSESSMENT_FORM ? (
                <JobReadiness score={score} />
            ) : score?.reportName === REPORT_NAMES.INTOJOBS_INITIAL_PROGRAM_QUESTIONNAIRE ? (
                <InitialProgramQuestionnaire score={score} />
            ) : score?.reportName === REPORT_NAMES.SALEABLE ? (
                <SaleableMeat score={score} coacheeName={score.coacheeName} />
            ) : score?.reportName === REPORT_NAMES.SALEABLE_ZOLL ? (
                <SaleableMeat score={score} coacheeName={score.coacheeName} />
            ) : score?.reportName === REPORT_NAMES.MENU_DRIVEN ? (
                <MenuDrivenMeat score={score} />
            ) : score?.reportName === "Capability Profile" || score?.reportName === "LEAD Capability Profile" ? (
                <CapabilityProfileMeat score={score} hideScoreNumbers />
            ) : score?.reportName === "Capability Map" ? (
                <CapabilityMap score={score} />
            ) : score?.reportName.toLowerCase() === "headfirst" ? (
                <HeadFirst score={score} />
            ) : score?.reportName ? (
                <Interests score={score} />
            ) : (
                <div className="flex items-center justify-center bg-white rounded-xl p-5 shadow-sm">
                    <div className="col-span-full flex flex-col gap-6 items-center justify-center w-auto lg:w-[550px] p-8">
                        <div className="w-80 p-4">
                            <NoResultsIllustration />
                        </div>
                        <div className="flex flex-col gap-2 justify-center">
                            <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                                Sorry, we couldn't find the report you requested.
                            </h1>
                            <div className={"text-sm md:text-base text-slate-500"}>
                                Please refresh the page and
                                <a
                                    className={"text-primary-600 font-medium"}
                                    href="mailto:techsupport@psychpress.com.au"
                                >
                                    {" "}
                                    contact us{" "}
                                </a>{" "}
                                if this error persists.
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2">
                            <span className="text-primary-600 font-title font-medium text-sm ">Error message:</span>
                            <div
                                className={
                                    "flex px-2 py-1 w-max text-sm bg-red-100 text-red-600 rounded-lg shadow-sm " +
                                    "font-medium md:w-fit"
                                }
                            >
                                {alert}
                            </div>
                        </div>
                        <div className={"flex flex-row justify-center lg:justify-start gap-4"}>
                            <Button
                                text="Previous page"
                                onClick={() => navigate(-1)}
                                colour={BUTTON_VARIANT_TYPES.GRAY}
                            />
                            <Button
                                text="Go to reports"
                                onClick={() => navigate("/reports")}
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            />
                        </div>
                    </div>
                </div>
            )}
        </DashboardLayout>
    );
}

export default ReportContent;
