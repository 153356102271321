import React, { useCallback, useEffect, useState } from "react";

import { getPrograms, getResourceAssignment } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import ProgramAccessRow from "./ProgramAccessRow";

export default function ProgramAccess(props) {
    const { resource, setProgramIdentifiersNewAccess, programIdentifiersNewAccess } = props;

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [programs, setPrograms] = useState([]);
    const [programsWithAccess, setProgramsWithAccess] = useState([]);
    const [loading, setLoading] = useState(true);
    const [latestProgramInteractedAccess, setLatestProgramInteractedAccess] = useState(true);
    const [latestProgramInteractedNonAccess, setLatestProgramInteractedNonAccess] = useState(true);

    const handleAddCohortToggle = (programName, toggle, noPreviousAccessCohort) => {
        let program;

        if (noPreviousAccessCohort) {
            program = programs.find((program) => program === programName);
        } else {
            program = programsWithAccess.find((programsWithAccess) => programsWithAccess === programName);
        }

        if (toggle) {
            setProgramsWithAccess((programsWithAccess) => [program, ...programsWithAccess]);
            setPrograms(programs.filter((program) => program !== programName));

            setProgramIdentifiersNewAccess((programsWithNewAccess) => [...programsWithNewAccess, program]);
            setLatestProgramInteractedAccess(program);
        } else {
            setPrograms((programs) => [program, ...programs]);
            setProgramsWithAccess(programsWithAccess.filter((programWithAccess) => programWithAccess !== programName));

            setProgramIdentifiersNewAccess(
                programIdentifiersNewAccess.filter((programWithNewAccess) => programWithNewAccess !== programName)
            );
            setLatestProgramInteractedNonAccess(program);
        }
    };

    const fetchPrograms = useCallback(async () => {
        setLoading(true);
        try {
            const programs = await getPrograms();

            const resourceAssignmentResponse = await getResourceAssignment(resource.identifier);

            setProgramsWithAccess(resourceAssignmentResponse.programs);

            const filteredPrograms = programs.filter(
                (program) =>
                    !resourceAssignmentResponse.programs.find((programWithAccess) => programWithAccess === program.name)
            );

            filteredPrograms.map((filteredProgram) => setPrograms((programs) => [...programs, filteredProgram.name]));

            resourceAssignmentResponse.programs.map((program) =>
                setProgramIdentifiersNewAccess((programIdentifiersNewAccess) => [
                    ...programIdentifiersNewAccess,
                    program,
                ])
            );
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, [resource.identifier, setProgramIdentifiersNewAccess]);

    useEffect(() => {
        fetchPrograms();
    }, [fetchPrograms]);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"flex flex-col gap-6"}>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-2">Programs</div>
                    <PaginatedSearch
                        loading={loading}
                        items={programsWithAccess}
                        itemName={"programs with access"}
                        searchAttributes={["name"]}
                        columnNames={["Name", "Access"]}
                        ItemRow={ProgramAccessRow}
                        itemRowToggleOnChange={handleAddCohortToggle}
                        customItemsPerPage={4}
                        accessDefaultToggleState={false}
                        latestItemInteracted={latestProgramInteractedAccess}
                    />
                </div>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-4">
                        Grant access to more programs
                    </div>
                    <PaginatedSearch
                        loading={loading}
                        items={programs}
                        itemName={"other programs"}
                        searchAttributes={["name"]}
                        columnNames={["Name", "Access"]}
                        ItemRow={ProgramAccessRow}
                        itemRowToggleOnChange={handleAddCohortToggle}
                        customItemsPerPage={8}
                        noPreviousAccessUser={true}
                        accessDefaultToggleState={true}
                        latestItemInteracted={latestProgramInteractedNonAccess}
                        noTableHead
                    />
                </div>
            </div>
        </>
    );
}
