import { DocumentIcon, BuildingOffice2Icon } from "@heroicons/react/24/solid";

import Input from "../../../../SharedComponents/Form/Input";

export default function Education() {
    return (
        <div className="grid grid-flow-row gap-6">
            <div>
                <label className="text-sm font-medium text-slate-500 w-full ">Education 1</label>
                <div className="grid grid-cols-2 gap-6 mt-4">
                    <Input
                        id="school"
                        type="text"
                        label="School"
                        placeholder={"School"}
                        icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="degree"
                        type="text"
                        label="Degree"
                        placeholder={"Degree"}
                        icon={<DocumentIcon className="h-4 w-4 text-slate-500" />}
                    />
                </div>
            </div>
            <div>
                <label className="text-sm font-medium text-slate-500 w-full ">Education 2</label>
                <div className="grid grid-cols-2 gap-6 mt-4">
                    <Input
                        id="school"
                        type="text"
                        label="School"
                        placeholder={"School"}
                        icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="degree"
                        type="text"
                        label="Degree"
                        placeholder={"Degree"}
                        icon={<DocumentIcon className="h-4 w-4 text-slate-500" />}
                    />
                </div>
            </div>
            <div>
                <label className="text-sm font-medium text-slate-500 w-full ">Education 3</label>
                <div className="grid grid-cols-2 gap-6 mt-4">
                    <Input
                        id="school"
                        type="text"
                        label="School"
                        placeholder={"School"}
                        icon={<BuildingOffice2Icon className="h-4 w-4 text-slate-500" />}
                    />
                    <Input
                        id="degree"
                        type="text"
                        label="Degree"
                        placeholder={"Degree"}
                        icon={<DocumentIcon className="h-4 w-4 text-slate-500" />}
                    />
                </div>
            </div>
        </div>
    );
}
