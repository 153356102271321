import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { assignProgramToCohorts, updateProgramName } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import ProgramName from "./ProgramName";
import ProgramCohorts from "./ProgramCohorts";

export default function Program() {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [programName, setProgramName] = useState();
    const [cohortList, setCohortList] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();

    const handleUpdateProgramCohorts = async () => {
        setLoading(true);
        try {
            await assignProgramToCohorts(cohortList, location.state.program.name);
            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited program cohorts!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    const handleUpdateProgramName = async () => {
        setLoading(true);
        try {
            await updateProgramName(location.state.program.name, programName);

            location.state.program.name = programName;

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully edited program name!");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (location.state === null) {
            navigate("/programs");
        } else if (location.state.tabIndex) {
            setTabIndex(location.state.tabIndex);
        }
    }, [location.state, navigate]);

    return (
        <>
            {location.state !== null && (
                <DashboardLayout title={"Program: " + location.state.program.name} requiredPermissions={[]}>
                    <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                    <TabbedNavigationLayout
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabList={["Name", "Cohorts"]}
                        backgroundColour={"bg-slate-100"}
                        loading={loading}
                        tabContent={[
                            {
                                title: "Name",
                                content: (
                                    <ProgramName program={location.state.program} setProgramNewName={setProgramName} />
                                ),
                                onSaveChanges: handleUpdateProgramName,
                            },
                            {
                                title: "Cohorts",
                                content: (
                                    <ProgramCohorts
                                        program={location.state.program}
                                        cohortList={cohortList}
                                        setCohortList={setCohortList}
                                    />
                                ),
                                onSaveChanges: handleUpdateProgramCohorts,
                            },
                        ]}
                    />
                </DashboardLayout>
            )}
        </>
    );
}
