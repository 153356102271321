import { AnimatePresence, motion } from "framer-motion";
import { BASIC_ANIMATION_VARIANTS } from "../../../utils/constants";

function BasicMotion(props) {
    const { children } = props;

    return (
        <AnimatePresence>
            <motion.div
                initial={"inactive"}
                animate={"active"}
                exit={"inactive"}
                transition={{ duration: 0.4 }}
                variants={BASIC_ANIMATION_VARIANTS}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
}

export default BasicMotion;
