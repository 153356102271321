import capCoLogo from "../../assets/CapCo_Lockup_RGB_Green.png";

export const sampleCapabilityMapGenericScore = {
    organisationLogo: capCoLogo,
    reportName: "Capability Map",
    snapshot: [
        "The Human Capabilities are the markers of a person’s enduring attributes we know, from 30 years of research, make each of us unique. Results are intended to provide deeper understanding and insights to a allow you to harness your teams innate strengths and potential.",
        "In the context of work, profile results should be considered in combination with all other available and relevant information. ",
    ],
    understandYourTeam: {
        title: "Understand your Team",
        description: [
            "Your team is made up of unique individuals, each with different combinations of Human capabilities. Understand how to harness their potential",
        ],
    },
    groups: [
        {
            name: "Overall",
            capabilityScores: [
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 63,
                    mindsetScore: 58,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 58,
                    mindsetScore: 62,
                },
                {
                    name: "Communication",
                    selfAssessmentScore: 52,
                    mindsetScore: 54,
                },
                {
                    name: "Creativity",
                    selfAssessmentScore: 51,
                    mindsetScore: 53,
                },
                {
                    name: "Critical Thinking",
                    selfAssessmentScore: 55,
                    mindsetScore: 52,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 59,
                    mindsetScore: 56,
                },
                {
                    name: "Empathy",
                    selfAssessmentScore: 53,
                    mindsetScore: 57,
                },
                {
                    name: "Ethics",
                    selfAssessmentScore: 57,
                    mindsetScore: 55,
                },
                {
                    name: "Problem Solving & Data",
                    selfAssessmentScore: 54,
                    mindsetScore: 58,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 55,
                    mindsetScore: 51,
                },
                {
                    name: "Agile & Innovative",
                    selfAssessmentScore: 56,
                    mindsetScore: 54,
                },
                {
                    name: "Direction & Purpose",
                    selfAssessmentScore: 54,
                    mindsetScore: 53,
                },
                {
                    name: "Engaging & Coaching",
                    selfAssessmentScore: 50,
                    mindsetScore: 49,
                },
            ],
            individualScores: [
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Communication",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Creativity",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Critical Thinking",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Empathy",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Ethics",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Problem Solving & Data",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Agile & Innovative",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Direction & Purpose",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Engaging & Coaching",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Customer Focus",
                    highlightType: "Most Prevalent",
                    percentScore: "55%",
                    description: "55% of the team are naturally strong in this area",
                },
                {
                    name: "Empathy",
                    highlightType: "Potential to Unlock",
                    percentScore: "46%",
                    description: "46% of the team have untapped potential in this area",
                },
                {
                    name: "Communication",
                    highlightType: "Blindspots",
                    percentScore: "32%",
                    description: "32% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Empathy", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Engaging & Coaching", percentScore: 49, category: "Blindspots" },
                        { name: "Agile & Innovative", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
        {
            name: "Human Resources",
            capabilityScores: [
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 60,
                    mindsetScore: 55,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 53,
                    mindsetScore: 58,
                },
                {
                    name: "Communication",
                    selfAssessmentScore: 48,
                    mindsetScore: 52,
                },
                {
                    name: "Creativity",
                    selfAssessmentScore: 45,
                    mindsetScore: 50,
                },
                {
                    name: "Critical Thinking",
                    selfAssessmentScore: 52,
                    mindsetScore: 49,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 57,
                    mindsetScore: 54,
                },
                {
                    name: "Empathy",
                    selfAssessmentScore: 50,
                    mindsetScore: 55,
                },
                {
                    name: "Ethics",
                    selfAssessmentScore: 55,
                    mindsetScore: 53,
                },
                {
                    name: "Problem Solving & Data",
                    selfAssessmentScore: 49,
                    mindsetScore: 56,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 51,
                    mindsetScore: 48,
                },
                {
                    name: "Agile & Innovative",
                    selfAssessmentScore: 54,
                    mindsetScore: 51,
                },
                {
                    name: "Direction & Purpose",
                    selfAssessmentScore: 53,
                    mindsetScore: 50,
                },
                {
                    name: "Engaging & Coaching",
                    selfAssessmentScore: 47,
                    mindsetScore: 46,
                },
            ],
            individualScores: [
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Communication",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Creativity",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Critical Thinking",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Empathy",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Ethics",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Problem Solving & Data",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Agile & Innovative",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Direction & Purpose",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Engaging & Coaching",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Leadership",
                    highlightType: "Most Prevalent",
                    percentScore: "67%",
                    description: "67% of the team are naturally strong in this area",
                },
                {
                    name: "Adaptive Mindset",
                    highlightType: "Potential to Unlock",
                    percentScore: "56%",
                    description: "56% of the team have untapped potential in this area",
                },
                {
                    name: "Creativity",
                    highlightType: "Blindspots",
                    percentScore: "23%",
                    description: "23% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Empathy", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Engaging & Coaching", percentScore: 49, category: "Blindspots" },
                        { name: "Agile & Innovative", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
        {
            name: "Operations/Delivery",
            capabilityScores: [
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 52,
                    mindsetScore: 48,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 61,
                    mindsetScore: 58,
                },
                {
                    name: "Communication",
                    selfAssessmentScore: 49,
                    mindsetScore: 43,
                },
                {
                    name: "Creativity",
                    selfAssessmentScore: 36,
                    mindsetScore: 40,
                },
                {
                    name: "Critical Thinking",
                    selfAssessmentScore: 54,
                    mindsetScore: 52,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 45,
                    mindsetScore: 51,
                },
                {
                    name: "Empathy",
                    selfAssessmentScore: 51,
                    mindsetScore: 53,
                },
                {
                    name: "Ethics",
                    selfAssessmentScore: 58,
                    mindsetScore: 50,
                },
                {
                    name: "Problem Solving & Data",
                    selfAssessmentScore: 48,
                    mindsetScore: 55,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 43,
                    mindsetScore: 47,
                },
                {
                    name: "Agile & Innovative",
                    selfAssessmentScore: 50,
                    mindsetScore: 45,
                },
                {
                    name: "Direction & Purpose",
                    selfAssessmentScore: 54,
                    mindsetScore: 52,
                },
                {
                    name: "Engaging & Coaching",
                    selfAssessmentScore: 39,
                    mindsetScore: 44,
                },
            ],
            individualScores: [
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Communication",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Creativity",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Critical Thinking",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Empathy",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Ethics",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Problem Solving & Data",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Agile & Innovative",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Direction & Purpose",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Engaging & Coaching",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Engaging & Coaching",
                    highlightType: "Most Prevalent",
                    percentScore: "89%",
                    description: "89% of the team are naturally strong in this area",
                },
                {
                    name: "Direction & Purpose",
                    highlightType: "Potential to Unlock",
                    percentScore: "67%",
                    description: "67% of the team have untapped potential in this area",
                },
                {
                    name: "Customer Focus",
                    highlightType: "Blindspots",
                    percentScore: "21%",
                    description: "21% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Empathy", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Engaging & Coaching", percentScore: 49, category: "Blindspots" },
                        { name: "Agile & Innovative", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
        {
            name: "Marketing",
            capabilityScores: [
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 56,
                    mindsetScore: 42,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 73,
                    mindsetScore: 67,
                },
                {
                    name: "Communication",
                    selfAssessmentScore: 45,
                    mindsetScore: 32,
                },
                {
                    name: "Creativity",
                    selfAssessmentScore: 24,
                    mindsetScore: 31,
                },
                {
                    name: "Critical Thinking",
                    selfAssessmentScore: 62,
                    mindsetScore: 53,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 38,
                    mindsetScore: 47,
                },
                {
                    name: "Empathy",
                    selfAssessmentScore: 53,
                    mindsetScore: 61,
                },
                {
                    name: "Ethics",
                    selfAssessmentScore: 70,
                    mindsetScore: 48,
                },
                {
                    name: "Problem Solving & Data",
                    selfAssessmentScore: 47,
                    mindsetScore: 71,
                },
                {
                    name: "Leadership",
                    selfAssessmentScore: 33,
                    mindsetScore: 38,
                },
                {
                    name: "Agile & Innovative",
                    selfAssessmentScore: 62,
                    mindsetScore: 44,
                },
                {
                    name: "Direction & Purpose",
                    selfAssessmentScore: 53,
                    mindsetScore: 55,
                },
                {
                    name: "Engaging & Coaching",
                    selfAssessmentScore: 20,
                    mindsetScore: 45,
                },
            ],
            individualScores: [
                {
                    name: "Felipe Rodrigo",
                    score: [
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 64,
                            mindsetScore: 45,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 75,
                            mindsetScore: 34,
                        },
                        {
                            name: "Communication",
                            selfAssessmentScore: 65,
                            mindsetScore: 45,
                        },
                        {
                            name: "Creativity",
                            selfAssessmentScore: 34,
                            mindsetScore: 78,
                        },
                        {
                            name: "Critical Thinking",
                            selfAssessmentScore: 43,
                            mindsetScore: 43,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 47,
                            mindsetScore: 67,
                        },
                        {
                            name: "Empathy",
                            selfAssessmentScore: 37,
                            mindsetScore: 47,
                        },
                        {
                            name: "Ethics",
                            selfAssessmentScore: 96,
                            mindsetScore: 85,
                        },
                        {
                            name: "Problem Solving & Data",
                            selfAssessmentScore: 75,
                            mindsetScore: 77,
                        },
                        {
                            name: "Leadership",
                            selfAssessmentScore: 55,
                            mindsetScore: 58,
                        },
                        {
                            name: "Agile & Innovative",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Direction & Purpose",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                        {
                            name: "Engaging & Coaching",
                            selfAssessmentScore: 95,
                            mindsetScore: 67,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Communication",
                    highlightType: "Most Prevalent",
                    percentScore: "78%",
                    description: "78% of the team are naturally strong in this area",
                },
                {
                    name: "Critical Thinking",
                    highlightType: "Potential to Unlock",
                    percentScore: "34%",
                    description: "34% of the team have untapped potential in this area",
                },
                {
                    name: "Collaboration",
                    highlightType: "Blindspots",
                    percentScore: "13%",
                    description: "13% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Empathy", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Engaging & Coaching", percentScore: 49, category: "Blindspots" },
                        { name: "Agile & Innovative", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
    ],
};
