import { useEffect, useState } from "react";
import { FlagIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";

import Button from "../../../../../SharedComponents/Button";
import Input from "../../../../../SharedComponents/Form/Input";

export default function AddGoalStep(props) {
    const { backgroundColour, customGrid, customGridItem, addItemsToParent, parentId, existingGoalSteps } = props;

    const [goalSteps, setGoalSteps] = useState(existingGoalSteps);

    function handleEditGoalSteps(key, value, id) {
        goalSteps.forEach(function (goalStep) {
            if (goalStep.id === id) {
                goalStep[key] = value;
            }
        });

        setGoalSteps([...goalSteps]);
    }

    const handleAddGoalStep = () => {
        setGoalSteps([
            ...goalSteps,
            {
                name: "",
                id: (Math.random() + 1).toString(36).substring(7),
            },
        ]);
    };

    const handleDeleteGoalStep = (id) => {
        setGoalSteps([...goalSteps.filter((goalStep) => goalStep.id !== id)]);
    };

    useEffect(() => {
        if (goalSteps !== existingGoalSteps) {
            addItemsToParent(goalSteps, parentId);
        }
    }, [addItemsToParent, existingGoalSteps, goalSteps, parentId]);

    return (
        <div className="col-span-full rounded-xl">
            <div className={"grid grid-flow-row gap-4"}>
                {goalSteps.length === 0 ? (
                    <div className={"p-3.5 bg-white rounded-xl"}>
                        <div className={"text-slate-500 text-sm"}>No step(s) has been added</div>
                    </div>
                ) : (
                    <div className={customGrid ? customGrid : "flex flex-col gap-4"}>
                        {goalSteps.map((step, index) => (
                            <div
                                key={index}
                                className={
                                    "gap-2 p-3 rounded-xl " +
                                    (backgroundColour ? backgroundColour : "bg-slate-100") +
                                    " " +
                                    (customGridItem ? customGridItem : " flex flex-col")
                                }
                            >
                                <div className={"flex flex-col gap-2"} key={index}>
                                    <div className={"grid grid-flow-row lg:grid-cols-2 gap-6 "}>
                                        <Input
                                            id="name"
                                            type="text"
                                            label={"Step " + (index + 1)}
                                            placeholder="Step"
                                            icon={<FlagIcon className="h-4 w-4 text-slate-500" />}
                                            backgroundColour="bg-white"
                                            currentValue={step.name}
                                            customOnChange={(e) => handleEditGoalSteps("name", e.target.value, step.id)}
                                            extraStyling="col-span-full"
                                            autoFocus
                                            endElements={
                                                <Tippy
                                                    content="Remove step"
                                                    className={
                                                        "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                                        "bg-red-600 text-red-50"
                                                    }
                                                    arrow={false}
                                                    animation={"shift-away-subtle"}
                                                    placement={"top-start"}
                                                >
                                                    <TrashIcon
                                                        onClick={() => handleDeleteGoalStep(step.id)}
                                                        className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-700 transition-colors"
                                                    />
                                                </Tippy>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={"w-full text-right col-span-full mt-4"}>
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    text="Add step"
                    icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    smallButton
                    onClick={handleAddGoalStep}
                />
            </div>
        </div>
    );
}
