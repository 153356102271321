import { TableCellsIcon } from "@heroicons/react/24/solid";

import Input from "../../../../SharedComponents/Form/Input";

export default function ProgramName(props) {
    const { program, setProgramNewName } = props;

    return (
        <div className={"flex flex-col gap-6"}>
            <div className="grid grid-flow-row gap-6">
                <Input
                    id="programName"
                    type="text"
                    label="Program name"
                    placeholder={"Program name"}
                    icon={<TableCellsIcon className="h-4 w-4 text-slate-500" />}
                    value={program.name}
                    onChange={setProgramNewName}
                    backgroundColour="bg-white"
                />
            </div>
        </div>
    );
}
