import { HEADFIRST_ILLUSTRATIONS } from "../../../../../../utils/constants";

export default function AtAGlanceCard(props) {
    const { scale } = props;

    return (
        <div className={"bg-slate-50 shadow-sm p-4 rounded-xl"}>
            <div className="flex flex-row gap-4">
                <img src={HEADFIRST_ILLUSTRATIONS[scale.name.toUpperCase()]} className="w-[100px]" alt="" />
                <div className="flex flex-col gap-2 w-full">
                    <div
                        className={
                            "flex flex-row justify-between items-center " +
                            (scale.percentage <= 34
                                ? "text-green-500"
                                : scale.percentage > 34 && scale.percentage < 74
                                ? "text-amber-500"
                                : "text-red-500")
                        }
                    >
                        <p className={"text-sm font-semibold"}>
                            {scale.name.toLowerCase() === "headfirst total score" ? "Overall Wellbeing" : scale.name}
                        </p>
                        <div
                            className={
                                "px-2 py-1 rounded-full leading-3 " +
                                (scale.percentage <= 34
                                    ? "bg-green-500"
                                    : scale.percentage > 34 && scale.percentage < 74
                                    ? "bg-amber-500"
                                    : "bg-red-500")
                            }
                        >
                            <span className="text-white text-xs font-semibold ">
                                {scale.percentage <= 34
                                    ? "Low"
                                    : scale.percentage > 34 && scale.percentage < 74
                                    ? "Moderate"
                                    : "High"}
                            </span>
                        </div>
                    </div>
                    <div className={"transition transition-color duration-4000 rounded-xl text-slate-500"}>
                        {scale.band_description ? (
                            <p className={"leading-relaxed text-sm"}>{scale.band_description}</p>
                        ) : scale.percentage <= 34 ? (
                            <p className={"leading-relaxed text-sm"}>
                                Overall, these results indicate that this individual has mild levels of{" "}
                                {scale.name.toLowerCase()}.
                            </p>
                        ) : scale.percentage > 34 && scale.percentage < 74 ? (
                            <p className={"leading-relaxed text-sm"}>
                                Overall, these results indicate that this individual has moderate levels of{" "}
                                {scale.name.toLowerCase()}.
                            </p>
                        ) : (
                            <p className={"leading-relaxed text-sm"}>
                                Overall, these results indicate that this individual has high levels of{" "}
                                {scale.name.toLowerCase()}.
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
