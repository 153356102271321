import { useEffect, useState } from "react";

// import { RESOURCE_TYPES } from "../../../../utils/constants";

import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import ResourceCard from "./ResourceCard";

export default function ResourceCardSearch(props) {
    const { resources, loading, fetchAllResources, setAlert, setAlertType } = props;

    const [filteredResources, setFilteredResources] = useState([]);

    useEffect(() => {
        setFilteredResources(resources);
    }, [resources]);

    return (
        <>
            <PaginatedSearch
                loading={loading}
                items={filteredResources}
                itemName={"resources"}
                searchAttributes={["title", "subtitle", "description", "resourceType", "startTime"]}
                ItemRow={ResourceCard}
                cards
                // Removed filters={filters}
                updateItemStatus={fetchAllResources}
                customItemsPerPage={9}
                setAlert={setAlert}
                setAlertType={setAlertType}
                customPlaceholder="Search resources by title, subtitle, description, resource type or start time"
            />
        </>
    );
}
