import { PlusIcon, TrashIcon, TrophyIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";

import Button from "../../../../../SharedComponents/Button";
import Input from "../../../../../SharedComponents/Form/Input";
import AddGoalStep from "./AddGoalStep";

export default function AddGoal(props) {
    const { goals, setGoals } = props;

    setGoals(goals);

    function handleEditGoals(key, value, id) {
        const newGoal = goals.find((goal) => goal.id === id);

        newGoal[key] = value;

        goals.forEach(function (oldGoal) {
            if (oldGoal.id === id) {
                oldGoal = newGoal;
            }
        });

        setGoals([...goals]);
    }

    const handleAddGoal = () => {
        setGoals([
            ...goals,
            {
                name: "",
                steps: [],
                id: (Math.random() + 1).toString(36).substring(7),
            },
        ]);
    };

    const handleDeleteGoal = (id) => {
        setGoals(goals.filter((goal) => goal.id !== id));
    };

    const handleAddStepsToGoal = (steps, parentId) => handleEditGoals("steps", steps, parentId);

    return (
        <div className="col-span-full">
            <div className={"grid grid-flow-row gap-6"}>
                {goals.length === 0 ? (
                    <div className={"p-3.5 bg-white rounded-xl"}>
                        <div className={"text-slate-500 text-sm"}>No goal(s) has been added</div>
                    </div>
                ) : (
                    goals.map((goal, index) => (
                        <div key={index} className="flex flex-col gap-2">
                            <div className={"flex flex-col gap-3 p-3.5 bg-slate-50 rounded-xl"} key={index}>
                                <div className={"grid grid-flow-row lg:grid-cols-2 gap-6 "}>
                                    <Input
                                        id="name"
                                        type="text"
                                        label="Goal name"
                                        placeholder="Goal name"
                                        icon={<TrophyIcon className="h-4 w-4 text-slate-500" />}
                                        backgroundColour={"bg-white"}
                                        currentValue={goal.name}
                                        customOnChange={(e) => handleEditGoals("name", e.target.value, goal.id)}
                                        extraStyling="col-span-full"
                                        autoFocus
                                    />
                                    <div className="flex flex-col gap-2 col-span-full">
                                        <label className="text-sm font-medium text-primary-600 w-full ">Steps</label>
                                        <AddGoalStep
                                            textItemName="Step"
                                            backgroundColour="bg-slate-100"
                                            parentId={goal.id}
                                            addItemsToParent={handleAddStepsToGoal}
                                            existingGoalSteps={goal.steps}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <Tippy
                                        content="Remove goal"
                                        className={
                                            "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                            "bg-red-600 text-red-50"
                                        }
                                        arrow={false}
                                        animation={"shift-away-subtle"}
                                        placement={"top-start"}
                                    >
                                        <TrashIcon
                                            onClick={() => handleDeleteGoal(goal.id)}
                                            className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-700 transition-colors"
                                        />
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <div className={"w-full text-right col-span-full mt-6"}>
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    text={"Add goal"}
                    icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    smallButton
                    onClick={handleAddGoal}
                />
            </div>
        </div>
    );
}
