import React, { useState } from "react";
import Input from "../../../SharedComponents/Form/Input";
import Textarea from "../../../SharedComponents/Form/Textarea";
import CustomQuestion from "./CustomQuestion";
import Button from "../../../SharedComponents/Button";
import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import { PlusIcon } from "@heroicons/react/24/solid";

export default function CustomQuestionPage(props) {
    const { customQuestionPage, availableQuestionTypes, selectableLikertScaleTypes, onUpdate, selectedPageIndex } =
        props;

    const [pageTitle, setPageTitle] = useState(customQuestionPage.pageTitle);
    const [pageDescription, setPageDescription] = useState(customQuestionPage.pageDescription);
    const [customQuestions, setCustomQuestions] = useState(customQuestionPage.questions);

    const handleCustomQuestion = (key, value, index) => {
        const newCustomQuestions = [...customQuestions];

        newCustomQuestions[index][key] = value;

        setCustomQuestions(newCustomQuestions);
        onUpdate({ pageTitle, pageDescription, questions: newCustomQuestions });
    };

    const handleAddCustomQuestion = () => {
        const newCustomQuestions = [
            ...customQuestions,
            {
                questionTitle: "",
                questionDescription: "",
                questionType: "",
                likertScaleType: "",
                isRequired: false,
                isReversed: false,
            },
        ];

        setCustomQuestions(newCustomQuestions);
        onUpdate({ pageTitle, pageDescription, questions: newCustomQuestions });
    };

    const handleDeleteCustomQuestion = (questionItem) => {
        const filteredCustomQuestions = customQuestions.filter((customQuestion) => customQuestion !== questionItem);

        setCustomQuestions(filteredCustomQuestions);
        onUpdate({ pageTitle, pageDescription, questions: filteredCustomQuestions });
    };

    const handleCustomQuestionPageTitle = (event) => {
        setPageTitle(event.target.value);
        onUpdate({ pageTitle: event.target.value, pageDescription, questions: customQuestions });
    };

    const handleCustomQuestionPageDescription = (event) => {
        setPageDescription(event.target.value);
        onUpdate({ pageTitle, pageDescription: event.target.value, questions: customQuestions });
    };

    return (
        <>
            <div className={"flex justify-between flex-col col-span-2 gap-6"}>
                <h1 className="font-medium text-primary-600 tracking-tight font-title">
                    {"Page " + (selectedPageIndex + 1) + (pageTitle ? `: ${pageTitle}` : "")}
                </h1>

                <div className={"flex flex-col col-span-2"}>
                    <Input
                        id="customQuestionPageTitle"
                        type="text"
                        label="Page title"
                        placeholder={"Page title"}
                        customOnChange={(event) => handleCustomQuestionPageTitle(event)}
                        currentValue={pageTitle}
                        required={false}
                        valueOnly
                    />
                </div>

                <Textarea
                    id="customQuestionPageDescription"
                    type="textarea"
                    extraStyling="col-span-2"
                    label="Page description"
                    placeholder={"Page description"}
                    customOnChange={(event) => handleCustomQuestionPageDescription(event)}
                    value={pageDescription}
                    optional
                />
            </div>

            {customQuestions.map((customQuestion, index) => (
                <CustomQuestion
                    customQuestion={customQuestion}
                    index={index}
                    key={index}
                    availableQuestionTypes={availableQuestionTypes}
                    selectableLikertScaleTypes={selectableLikertScaleTypes}
                    handleCustomQuestion={handleCustomQuestion}
                    handleDeleteCustomQuestion={handleDeleteCustomQuestion}
                />
            ))}

            <div className={"w-full text-right col-span-2 mt-2 mb-3"}>
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    text={"Add Question"}
                    icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    smallButton
                    onClick={handleAddCustomQuestion}
                />
            </div>
        </>
    );
}
