import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { isMobile } from "react-device-detect";

import { NO_ACCESS_TYPES } from "../../../utils/constants";
import { Context } from "../../../context/Context";

import AccessControl from "../../SharedComponents/AccessControl";
import Tag from "../../SharedComponents/Tag";

export default function MenuItem(props) {
    const { link, mobile } = props;

    const location = useLocation();

    const context = useContext(Context);

    const navigate = useNavigate();

    const handleClick = (href) => {
        navigate(href);
    };

    return (
        <AccessControl key={link.name} requiredPermissions={link.permissions} noAccessType={NO_ACCESS_TYPES.BLANK}>
            {link.button
                ? link.buttonElement
                : !link.shadowLink &&
                  (context.menuHidden ? (
                      <Tippy
                          content={link.name}
                          className={
                              "py-1.5 px-2 shadow-lg rounded-xl text-sm bg-primary-50 text-primary-600 border border-primary-100 font-medium"
                          }
                          arrow={false}
                          animation={"shift-away-subtle"}
                          placement={isMobile ? "bottom-start" : "right"}
                          interactive
                          trigger={isMobile ? "click" : "mouseenter focus"}
                          hideOnClick={true}
                      >
                          <div
                              onClick={() => handleClick(link.href)}
                              className={
                                  "flex items-center justify-between gap-2 p-2 rounded-full tracking-tight " +
                                  "transition-colors w-max " +
                                  (link.disabled
                                      ? "cursor-not-allowed pointer-events-none opacity-50 "
                                      : " cursor-pointer ") +
                                  (encodeURI(link.href) === location.pathname
                                      ? mobile
                                          ? "fill-current text-secondary-600 font-medium bg-secondary-50"
                                          : "fill-current text-primary-600 font-medium bg-primary-50 shadow-sm border border-primary-100"
                                      : mobile
                                      ? "fill-current text-slate-500"
                                      : "fill-current bg-slate-50 text-slate-600 hover:bg-slate-100 border border-slate-100")
                              }
                              aria-current={link.current ? "page" : undefined}
                          >
                              <div className={"flex items-center justify-center gap-2"}>{link.icon}</div>
                          </div>
                      </Tippy>
                  ) : (
                      <div
                          onClick={() => handleClick(link.href)}
                          className={
                              "flex items-center justify-between gap-2 px-3 py-2 rounded-xl tracking-tight " +
                              " transition-colors " +
                              (link.disabled
                                  ? "cursor-not-allowed pointer-events-none opacity-50 "
                                  : " cursor-pointer ") +
                              (encodeURI(link.href) === location.pathname
                                  ? mobile
                                      ? "fill-current text-secondary-600 font-medium bg-secondary-50"
                                      : "fill-current text-primary-600 font-medium bg-primary-50 shadow-sm border border-primary-100"
                                  : mobile
                                  ? "fill-current text-slate-500"
                                  : "fill-current bg-slate-50 text-slate-600 hover:bg-slate-100 border border-slate-100")
                          }
                          aria-current={link.current ? "page" : undefined}
                      >
                          <div className={"flex items-center justify-center gap-2"}>
                              {link.icon}
                              {link.name}
                          </div>
                          {link.demo && <Tag status={"Demo"} />}
                          {link.secondIcon && link.secondIcon}
                      </div>
                  ))}
        </AccessControl>
    );
}
