import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/solid";

import { getPrograms } from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../utils/constants";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import ProgramRow from "./ProgramRow";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";

export default function Programs() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const location = useLocation();

    const fetchPrograms = async () => {
        setLoading(true);
        try {
            const response = await getPrograms();
            setPrograms(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPrograms();
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout
            title={"Programs"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_PROGRAMS]}
            actionButton
            actionButtonRequiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.CREATE_PROGRAM,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
            actionButtonText={"Create Program"}
            actionButtonIcon={<PlusIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
            actionButtonOnClick={() => navigate("/create-program")}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                loading={loading}
                items={programs.slice(0).reverse()}
                itemName={"Programs"}
                searchAttributes={["name"]}
                columnNames={["Name", "Members", "Manage"]}
                ItemRow={ProgramRow}
            />
        </DashboardLayout>
    );
}
