import { ArrowDownCircleIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Button from "../../../SharedComponents/Button";
import RadioButton from "../../../SharedComponents/Form/RadioButton";

export default function Export() {
    const location = useLocation();
    const navigate = useNavigate();
    const report = location?.state?.report;

    useEffect(() => {
        if (location.state === null) {
            navigate("/reports");
        }
    }, [location.state, navigate]);

    return (
        <DashboardLayout
            title={"Exporting report: " + report?.name}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.DOWNLOAD_REPORTS]}
        >
            <p className="mb-4 text-sm text-slate-500">Please complete the form below to export report:</p>
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4"}>
                        <div className={"grid grid-flow-row gap-6"}>
                            <div className={"grid grid-flow-row xl:grid-flow-col gap-6"}>
                                <div>
                                    <div className="overflow-hidden border-b border-none rounded-xl shadow-sm">
                                        <table className="table-fixed min-w-full">
                                            <thead className="bg-slate-100">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className={
                                                            "w-1/2 px-6 py-3 text-left text-xs font-medium " +
                                                            "text-slate-500 uppercase tracking-wide"
                                                        }
                                                    >
                                                        Report
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className={
                                                            "w-1/2 px-6 py-3 text-left text-xs font-medium " +
                                                            "text-slate-500 uppercase "
                                                        }
                                                    >
                                                        Size
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                <tr>
                                                    <td className="px-6 py-4">
                                                        <div className="flex space-between">
                                                            <div>
                                                                <div className="mt-1 text-sm text-slate-600">
                                                                    {report?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className="mt-1 text-sm text-slate-600">5.5MB</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className={"flex flex-col gap-3"}>
                                    <label className="text-sm text-sm font-medium text-primary-600 w-full ">
                                        What kind of format do you want to export this report to?
                                    </label>
                                    <fieldset>
                                        <div className="mt-2 flex flex-row gap-6">
                                            <RadioButton id="xlsFormat" name="fileFormats" labelText=".xls" />
                                            <RadioButton id="xlsxFormat" name="fileFormats" labelText=".xlsx" />
                                            <RadioButton id="csvFormat" name="fileFormats" labelText=".csv" />
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 " +
                        "text-right rounded-b-xl w-full"
                    }
                >
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text="Export report"
                        icon={<ArrowDownCircleIcon className="block h-3.5 w-3.5  ml-2" />}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}
