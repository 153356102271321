import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../../utils/constants";
import { renameDocument, editDocumentTags } from "../../../../../utils/api";
import { Context } from "../../../../../context/Context";

import { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import DashboardLayout from "../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import ReportDetails from "./ReportDetails";
import ReportVersions from "./ReportVersions";
import Alert from "../../../../SharedComponents/Alert";

export default function Report() {
    const context = useContext(Context);
    const location = useLocation();
    const report = location?.state?.report;

    const [tabIndex, setTabIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [additionalAlert, setAdditionalAlert] = useState("");
    const [reportName, setReportName] = useState(report?.name);
    const [reportTags, setReportTags] = useState(
        report?.tags.map((tag) => ({
            value: tag,
            label: tag,
        }))
    );

    const handleEditReport = async () => {
        setLoading(true);

        if (reportName === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter a report name.");
            setLoading(false);
            return;
        }

        try {
            const sanitizedTags = reportTags.map((reportTag) => reportTag.value);

            //Backend requires Upload Reports permission to rename document
            const requiredPermissionToRenameReport = context.userPermissions.includes(
                CUSTOMER_PORTAL_PERMISSIONS.UPLOAD_REPORTS
            );

            if (requiredPermissionToRenameReport) {
                await Promise.all([
                    renameDocument(report.identifier, reportName),
                    editDocumentTags(report.identifier, sanitizedTags),
                ]);
            } else {
                await editDocumentTags(report.identifier, sanitizedTags);
            }

            context?.fetchReports();

            if (requiredPermissionToRenameReport) {
                setAlertType(ALERT_TYPE.SUCCESS);
                setAlert("Successfully edited report");
            } else {
                setAlertType(ALERT_TYPE.SUCCESS);
                setAlert("Successfully edited report");
                setAdditionalAlert("You do not have the necessary permission to edit a report's name");
            }
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }

        setLoading(false);
    };

    return (
        <>
            {report ? (
                <DashboardLayout title={"Report"} requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS]}>
                    <Alert
                        text={additionalAlert}
                        type={ALERT_TYPE.ERROR}
                        active={additionalAlert}
                        close={() => setAdditionalAlert("")}
                    />
                    {!context.userPermissions.includes(CUSTOMER_PORTAL_PERMISSIONS.EDIT_DOCUMENT_TAGS) ? (
                        <TabbedNavigationLayout
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            tabList={["Details", "Version history"]}
                            backgroundColour={"bg-slate-100"}
                            tabContent={[
                                {
                                    title: "Details",
                                    content: (
                                        <ReportDetails
                                            report={report}
                                            reportName={reportName}
                                            setReportName={setReportName}
                                            reportTags={reportTags}
                                            setReportTags={setReportTags}
                                            alert={alert}
                                            setAlert={setAlert}
                                            alertType={alertType}
                                        />
                                    ),
                                },
                                {
                                    title: "Version history",
                                    content: <ReportVersions report={report} />,
                                    readonly: true,
                                },
                            ]}
                        />
                    ) : (
                        <TabbedNavigationLayout
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            tabList={["Details", "Version history"]}
                            backgroundColour={"bg-slate-100"}
                            loading={loading}
                            tabContent={[
                                {
                                    title: "Details",
                                    content: (
                                        <ReportDetails
                                            report={report}
                                            reportName={reportName}
                                            setReportName={setReportName}
                                            reportTags={reportTags}
                                            setReportTags={setReportTags}
                                            alert={alert}
                                            setAlert={setAlert}
                                            alertType={alertType}
                                        />
                                    ),
                                    onSaveChanges: handleEditReport,
                                },
                                {
                                    title: "Version history",
                                    content: <ReportVersions report={report} />,
                                    readonly: true,
                                },
                            ]}
                        />
                    )}
                </DashboardLayout>
            ) : (
                <DashboardLayout title={"Report"} requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS]}>
                    <Alert type={ALERT_TYPE.WARNING} active={true}>
                        <div className="text-sm">
                            Oops! It looks like we are having some trouble retrieving your report details. Please
                            refresh the page or if you require immediate assistance, please contact us at{" "}
                            <a
                                className="link text-primary-600 hover:text-primary-700"
                                href="mailto:techsupport@psychpress.com.au?subject=The assessments server is down."
                            >
                                techsupport@psychpress.com.au
                            </a>{" "}
                            or call us at{" "}
                            <a className="link text-primary-600 hover:text-primary-700" href="tel:+61396700590">
                                +61 (0)3 9670 0590
                            </a>
                        </div>
                    </Alert>
                </DashboardLayout>
            )}
        </>
    );
}
