import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { MenuToggle } from "./MenuToggle";

export const MobileMenuSection = (props) => {
    const { menuItems } = props;
    const [isOpen, toggleOpen] = useCycle(false, true);

    const containerRef = useRef(null);

    const sidebar = {
        open: {
            pointerEvents: "auto",
            opacity: 1,
            width: "100%",
        },
        closed: {
            pointerEvents: "none",
            opacity: 0,
            width: "0px",
        },
    };
    return (
        <motion.nav className="h-max" initial={"closed"} animate={isOpen ? "open" : "closed"} ref={containerRef}>
            <motion.div
                className="h-screen bg-white absolute top-16 left-0 z-40 block px-2  shadow-lg rounded-b-xl"
                variants={sidebar}
            >
                {menuItems}
            </motion.div>
            <MenuToggle toggle={() => toggleOpen()} />
        </motion.nav>
    );
};
