import { ResponsiveRadar } from "@nivo/radar";

import { CAPABILITY_SUB_CATEGORIES_BAR_CHART_COLOUR_PALETTE } from "../../../../../utils/constants";
import {
    generateBarLevelPercentageBasedOnLevel,
    generateLevelNumber,
    generateProportionalPercentage,
} from "../../../../../utils/utils";

import BarChart from "../Charts/CustomCharts/BarChart";

export const RadarAndBarChart = (props) => {
    const { capability, levelNames, hidePercentScoreNumbers, old } = props;

    const categoriesColourPalette = CAPABILITY_SUB_CATEGORIES_BAR_CHART_COLOUR_PALETTE;

    const categories = capability?.subCategories.map((subCategory, index) => {
        if (categoriesColourPalette[index]) {
            const colour = categoriesColourPalette[index];

            return {
                name: subCategory.name,
                category: subCategory.name,
                percentScore: old
                    ? generateProportionalPercentage(subCategory.percentile, "mindset")
                    : generateBarLevelPercentageBasedOnLevel(generateLevelNumber(subCategory.percentile, "mindset")),

                description: subCategory.band_description,
                colour: colour.name,
            };
        } else {
            const colour = categoriesColourPalette[categoriesColourPalette.length - 1];

            return {
                name: subCategory.name,
                category: subCategory.name,
                percentScore: old
                    ? generateProportionalPercentage(subCategory.percentile, "mindset")
                    : generateBarLevelPercentageBasedOnLevel(generateLevelNumber(subCategory.percentile, "mindset")),
                description: subCategory.band_description,
                colour: colour.name,
            };
        }
    });

    const CustomBottomAxis = () => {
        return (
            <div className={"flex flex-col gap-1.5 " + (hidePercentScoreNumbers ? "mr-0" : "mr-14")}>
                <div className="flex flex-row w-full">
                    <div
                        className={
                            "w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0  h-3.5"
                        }
                    />
                    <div
                        className={
                            "w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5"
                        }
                    />
                    <div
                        className={
                            "w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5"
                        }
                    />
                    <div className={"w-25-percentage border-slate-400 border-dashed border-2 border-t-0 h-3.5"} />
                </div>
                <div
                    className={
                        "flex flex-row w-full font-title text-sm text-slate-400 font-medium " +
                        (old ? "text-center" : "text-right")
                    }
                >
                    <div className="w-25-percentage">{levelNames ? levelNames[0] : "Entry"}</div>
                    <div className="w-25-percentage">
                        <span className="-ml-2">{levelNames ? levelNames[1] : "Foundation"}</span>
                    </div>
                    <div className="w-25-percentage">
                        <span className="-ml-2">{levelNames ? levelNames[2] : "Advanced"}</span>
                    </div>
                    <div className="w-25-percentage">
                        <span className="-ml-2">{levelNames ? levelNames[3] : "Mastery"}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col print:block print:space-y-6 items-center print:justify-center gap-0 lg:gap-6 xl:gap-0 2xl:gap-6">
            {capability?.subCategories.length > 2 && (
                <div
                    className={
                        "w-[500px] font-medium print:ml-24 " +
                        (capability?.subCategories.length > 3 ? "h-[310px]" : "h-[280px] -mb-8")
                    }
                >
                    <ResponsiveRadar
                        data={capability?.subCategories.map((subCategory) => ({
                            capability: subCategory.name,
                            score: old
                                ? subCategory.percentile
                                : generateLevelNumber(subCategory.percentile, "mindset"),
                        }))}
                        colors="#a855f7"
                        keys={["score"]}
                        indexBy="capability"
                        maxValue={old ? 100 : 4}
                        valueFormat=">-.2f"
                        margin={{ top: 20, right: 120, bottom: 0, left: 120 }}
                        gridLabelOffset={10}
                        gridShape="linear"
                        gridLevels={4}
                        dotSize={4}
                        dotColor={{ theme: "background" }}
                        dotBorderWidth={2}
                        blendMode="multiply"
                        motionConfig="wobbly"
                        theme={{
                            axis: {
                                ticks: {
                                    width: "10px",
                                    text: {
                                        fill: "#94a3b8",
                                        whiteSpace: "pre-line",
                                        fontWeight: "500",
                                        fontFamily: "Inter",
                                        fontSize: "13px",
                                    },
                                },
                            },
                            grid: {
                                line: {
                                    stroke: "rgba(148,163,184,0.6)",
                                    strokeWidth: 1,
                                },
                            },
                        }}
                        isInteractive={false}
                    />
                </div>
            )}

            <div className={"w-full"}>
                <BarChart
                    data={categories}
                    percentageAxisLabels={{
                        first: "0%",
                        second: "25%",
                        third: "50%",
                        fourth: "75%",
                        fifth: "100%",
                    }}
                    colourPalette={categoriesColourPalette}
                    percentage
                    percentageAxisMargin
                    noTrendLegend
                    noSelectableCategories
                    lowMediumHighBubble
                    noBottomAxis
                    noTopAxis
                    customBottomAxis={<CustomBottomAxis />}
                    barEmptyBackgroundColour={"bg-slate-200"}
                    noInfoBubbleOnPrint
                    noFlexAtTopOnPrint
                    hidePercentScoreNumbers={hidePercentScoreNumbers}
                />
            </div>
        </div>
    );
};
