import { useContext, useEffect } from "react";
import { ArrowTrendingUpIcon, BuildingLibraryIcon, CalendarIcon, UserIcon } from "@heroicons/react/24/solid";

import { Context } from "../../../../../context/Context";

import AddGoal from "./InteractiveForms/AddGoal";
import AddTextItem from "./InteractiveForms/AddTextItem";
import AddRecommendedJob from "./InteractiveForms/AddRecommendedJob";
import Input from "../../../../SharedComponents/Form/Input";

export default function CareersActionPlan(props) {
    const {
        goals,
        loading,
        setGoals,
        capDate,
        setCapDate,
        resources,
        coacheeName,
        setResources,
        recommendedJobs,
        setRecommendedJobs,
        recommendedCareers,
        setRecommendedCareers,
    } = props;

    const context = useContext(Context);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (
        <div className="items-center lg:gap-8 col-span-4">
            <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-6 lg:mt-0">
                <Input
                    id="coachee"
                    type="text"
                    label={context.terminology.coachee.singular}
                    placeholder={context.terminology.coachee.singular + "'s name"}
                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                    currentValue={coacheeName}
                    backgroundColour="bg-white"
                    loading={loading}
                    disabled
                />
                <Input
                    id="date"
                    type="date"
                    label="Date"
                    placeholder={"Date"}
                    value={capDate}
                    onChange={setCapDate}
                    icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                    backgroundColour="bg-white"
                    minDate="2021-01-01"
                />
                <div className="col-span-full flex flex-col gap-3 bg-slate-100 rounded-xl p-4">
                    <label className="text-base font-medium text-primary-600 w-full ">Goals</label>
                    <div className="flex flex-col gap-2 col-span-full">
                        <AddGoal goals={goals} setGoals={setGoals} />
                    </div>
                </div>
                <div className="col-span-full flex flex-col gap-3 bg-slate-100 rounded-xl p-4">
                    <label className="text-base font-medium text-primary-600 w-full ">Resources</label>
                    <div className="flex flex-col gap-2 col-span-full">
                        <AddTextItem
                            textItemName="Resource"
                            textItems={resources}
                            setTextItems={setResources}
                            backgroundColour="bg-slate-50"
                            inputIcon={<BuildingLibraryIcon className="h-4 w-4 text-slate-500" />}
                        />
                    </div>
                </div>
                <div className="col-span-full flex flex-col gap-3 bg-slate-100 rounded-xl p-4">
                    <label className="text-base font-medium text-primary-600 w-full ">Recommended careers</label>
                    <div className="flex flex-col gap-2 col-span-full">
                        <AddTextItem
                            textItemName="Career"
                            textItems={recommendedCareers}
                            setTextItems={setRecommendedCareers}
                            backgroundColour="bg-slate-50"
                            inputIcon={<ArrowTrendingUpIcon className="h-4 w-4 text-slate-500" />}
                            customGrid="grid grid-cols-2 gap-4"
                            customGridItem="col-span-1"
                        />
                    </div>
                </div>
                <div className="col-span-full flex flex-col gap-3 bg-slate-100 rounded-xl p-4">
                    <label className="text-base font-medium text-primary-600 w-full ">Recommended jobs</label>
                    <div className="flex flex-col gap-2 col-span-full">
                        <AddRecommendedJob
                            textItemName="Recommended job"
                            recommendedJobs={recommendedJobs}
                            setRecommendedJobs={setRecommendedJobs}
                            backgroundColour="bg-slate-50"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
