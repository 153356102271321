import { useContext, useEffect, useState } from "react";

import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import SessionCard from "../../Sessions/SessionCard";
import { Context } from "../../../../../context/Context";

export default function CoachSessions(props) {
    const { coachIdentifier } = props;

    const [userSessions, setUserSessions] = useState([]);

    const context = useContext(Context);

    useEffect(() => {
        context.organisationSessions.forEach((session) => {
            session.participants.forEach((participant) => {
                if (participant.userIdentifier === coachIdentifier) {
                    setUserSessions((oldUserSessions) => [...oldUserSessions, session]);
                }
            });
        });
    }, [coachIdentifier, context.organisationSessions]);

    return (
        <PaginatedSearch
            items={userSessions}
            itemName={"sessions"}
            searchAttributes={["name", "participants"]}
            ItemRow={SessionCard}
            cards
        />
    );
}
