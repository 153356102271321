import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../utils/constants";

import DashboardLayout from "./DashboardLayout";
import Button from "./Button";
import Upload from "./Form/Upload";

export default function Import() {
    return (
        <DashboardLayout title={"Import"} requiredPermissions={[]}>
            <p className="mb-4 text-sm text-slate-500">Please complete the form below to export report:</p>
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4"}>
                        <div className={"grid grid-flow-row gap-6"}>
                            <div className={"grid grid-flow-row xl:grid-flow-col gap-6"}>
                                <div className={"flex flex-col gap-3"}>
                                    <label className="text-sm font-medium text-primary-600 w-full ">
                                        What are you importing?
                                    </label>
                                    <select
                                        id="defaultUserGroups"
                                        name="defaultUserGroups"
                                        className={
                                            "bg-slate-50 rounded-xl shadow-sm pl-3 pr-10 py-2 text-left " +
                                            "focus:outline-none focus:ring-2 focus:ring-primary-300 " +
                                            "focus:border-transparent sm:text-sm border border-slate-100 "
                                        }
                                    >
                                        <option value="coaches">Choose type of import</option>
                                        <option value="coaches">Coaches</option>
                                        <option value="coachees">Coachees</option>
                                    </select>
                                </div>
                                <div className={"flex flex-col gap-3"}>
                                    <label className="text-sm font-medium text-primary-600 w-full ">
                                        What kind of file is it?
                                    </label>
                                    <select
                                        id="defaultUserGroups"
                                        name="defaultUserGroups"
                                        className={
                                            "bg-slate-50 rounded-xl shadow-sm pl-3 pr-10 py-2 text-left " +
                                            "focus:outline-none focus:ring-2 focus:ring-primary-300 " +
                                            "focus:border-transparent sm:text-sm border border-slate-100"
                                        }
                                    >
                                        <option>Choose file type</option>
                                        <option value="excel">Excel</option>
                                        <option value="cvs">CVS</option>
                                        <option value="json">JSON</option>
                                    </select>
                                </div>
                            </div>
                            <Upload
                                infoTextClick={"Click to choose file"}
                                infoTextSecondary={"or drag and drop file to import"}
                                noFilesUploadedText={"You haven't imported any files"}
                                files={[]}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 " +
                            "text-right rounded-b-xl w-full"
                        }
                    >
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Import data"
                            icon={<ArrowUpTrayIcon className="block h-3.5 w-3.5 stroke-current stroke-1 ml-2" />}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
