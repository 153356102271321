import { useDropzone } from "react-dropzone";
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";

function Upload(props) {
    const { infoTextClick, infoTextSecondary, noFilesUploadedText, instructionText, formats, files, setFiles } = props;

    let { getRootProps, getInputProps } = useDropzone({
        accept: formats,
        uploadMultiple: true,
        onDrop: (acceptedFiles) => {
            setFiles((files) =>
                files.concat(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            );
        },
    });

    const removeFile = (index) => {
        setFiles((files) => files.filter((file, i) => i !== index));
    };

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <section className="container">
            <div
                {...getRootProps({
                    className:
                        "dropzone mt-1 flex justify-center p-6 border-2 border-gray-300 border-dashed " +
                        "rounded-xl bg-gradient-to-r from-primary-50 to-blue-50 cursor-pointer",
                })}
            >
                <input {...getInputProps()} />
                <div className="space-y-4 text-center">
                    <ArrowUpTrayIcon className="mx-auto stroke-current stroke-1 h-12 w-12 text-slate-400" />
                    <div className="flex text-sm text-slate-600">
                        <label htmlFor="file-upload" className="relative cursor-pointer rounded-md">
                            <span className={"font-medium text-secondary-600 hover:text-secondary-500"}>
                                {infoTextClick}
                            </span>
                        </label>
                        <p className="pl-1">{infoTextSecondary}</p>
                    </div>
                    <p className={"text-slate-400"}>{instructionText}</p>
                </div>
            </div>
            <div className="mt-4">
                <div className="overflow-hidden border-b border-none mt-4 rounded-xl shadow-sm">
                    <table className="table-fixed min-w-full">
                        <thead className="bg-slate-100">
                            <tr>
                                <th
                                    scope="col"
                                    className="w-1/2 px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase "
                                >
                                    File
                                </th>
                                <th
                                    scope="col"
                                    className="w-1/2 px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase "
                                >
                                    Size
                                </th>
                                <th
                                    scope="col"
                                    className="w-1/2 px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase "
                                >
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {files.length === 0 ? (
                                <tr>
                                    <td className="px-6 py-4" colSpan={3}>
                                        <div className="flex space-between">
                                            <div>
                                                <div className="text-sm text-slate-500">{noFilesUploadedText}</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                files.map((file, index) => (
                                    <>
                                        <tr key={index + 1}>
                                            <td className="px-6 py-4">
                                                <div className="flex space-between">
                                                    <div>
                                                        <div className="mt-1 text-sm text-slate-600">{file.path}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className="mt-1 text-sm text-slate-600">
                                                    {formatBytes(file.size)}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div
                                                    className="mt-1 text-sm text-red-600 cursor-pointer"
                                                    onClick={() => removeFile(index)}
                                                >
                                                    <TrashIcon className="block h-4 w-4 mr-2" />
                                                </div>
                                            </td>
                                        </tr>
                                        {!formats.includes("document") && (
                                            <tr key={index}>
                                                <td colSpan={3} className="px-6 py-4">
                                                    {formats.includes("image") ? (
                                                        <img src={file.preview} alt={"imported user pic"} />
                                                    ) : (
                                                        <video controls>
                                                            <source src={file.preview} type="video/mp4" />
                                                        </video>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}

export default Upload;
