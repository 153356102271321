import { HandThumbUpIcon } from "@heroicons/react/24/outline";

import { BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../../utils/constants";

import BarChartForScales from "../../../Charts/CustomCharts/BarChartForScales";

export default function SatisfactionScaleVerticalScores(props) {
    const { data, noExtraInfo } = props;

    return (
        <div className="flex flex-col gap-8">
            <div className={"flex flex-col gap-6"}>
                <div className="flex flex-col gap-3">
                    <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                        "{data.itemText}"
                    </h2>
                </div>
                {!noExtraInfo && (
                    <div className="flex flex-row flex-wrap gap-3">
                        <div
                            className={
                                "flex flex-row items-center gap-2 py-2 px-2.5 bg-blue-100 border-2 h-max " +
                                "border-blue-300 rounded-xl w-max"
                            }
                        >
                            <HandThumbUpIcon className={"h-5 w-5 text-blue-600 stroke-current stroke-2"} />
                            <span className="text-blue-600 font-medium">
                                {data.data.current.percentScores.stronglyAgreePercentage +
                                    data.data.current.percentScores.agreePercentage}
                                % agree
                            </span>
                        </div>
                        {data.data.sectorBenchmark && (
                            <div
                                className={
                                    "flex flex-row items-center gap-2 py-2 px-2.5 bg-amber-100 border-2 h-max " +
                                    "border-amber-300 rounded-xl"
                                }
                            >
                                <HandThumbUpIcon className={"h-5 w-5 text-amber-600 stroke-current stroke-2"} />
                                <span className="text-amber-600 font-medium">
                                    {data.data.sectorBenchmark.percentScores.stronglyAgreePercentage +
                                        data.data.sectorBenchmark.percentScores.agreePercentage}
                                    % from benchmark agree
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div
                className={
                    "shrink-0 bg-white p-4 rounded-xl border border-slate-100 shadow-sm w-max " +
                    "print:p-0 print:shadow-none print:border-none"
                }
            >
                <BarChartForScales colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID} data={data} />
            </div>
        </div>
    );
}
