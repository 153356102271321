import {
    Bars2Icon,
    BookOpenIcon,
    CalendarIcon,
    ClockIcon,
    RectangleStackIcon,
    ArrowDownTrayIcon,
    BuildingLibraryIcon,
    MapPinIcon,
    PaperClipIcon,
    PuzzlePieceIcon,
    TrashIcon,
    ArchiveBoxIcon,
} from "@heroicons/react/24/solid";
import { useContext, useState, useEffect } from "react";
import mime from "mime";

import { deleteResourceFile, downloadResourceFile, getAllResourceTypes } from "../../../../../utils/api";
import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, RESOURCE_TYPES } from "../../../../../utils/constants";
import { Context } from "../../../../../context/Context";

import { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import Button from "../../../../SharedComponents/Button";

import Input from "../../../../SharedComponents/Form/Input";
import MultiSelect from "../../../../SharedComponents/Form/MultiSelect";
import Textarea from "../../../../SharedComponents/Form/Textarea";
import Upload from "../../../../SharedComponents/Form/Upload";
import StarRating from "../../../../SharedComponents/StarRating";
import Tag from "../../../../SharedComponents/Tag";
import AddLinks from "../../Coachees/CoacheeProfile/InteractiveForms/AddLinks";
import DeleteConfirmation from "../../../../SharedComponents/DeleteConfirmation";

export default function ResourceDetails(props) {
    const {
        resource,
        ownRating,
        setOwnRating,
        allResourceRatings,
        title,
        setTitle,
        subtitle,
        setSubtitle,
        description,
        setDescription,
        resourceType,
        setResourceType,
        startTime,
        setStartTime,
        durationMins,
        setDurationMins,
        resourceLocation,
        setResourceLocation,
        initialIsOrganisationResource,
        isOrganisationResource,
        setIsOrganisationResource,
        resourceLinks,
        setResourceLinks,
        mediaFiles,
        files,
        setFiles,
        setAlert,
        setAlertType,
        fetchResource,
        fetchAllResourceRatings,
    } = props;

    const context = useContext(Context);

    const [downloading, setDownloading] = useState([]);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingResourceTypes, setLoadingResourceTypes] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(null);
    const [selectedMediaFileIdentifier, setSelectedMediaFileIdentifier] = useState(null);
    const [selectableResourceTypes, setSelectableResourceTypes] = useState([]);

    const containsUpdateResourcePermission = () => {
        let containsPermission = false;

        if (resource.isAssigned) {
            return false;
        }

        if (
            (initialIsOrganisationResource &&
                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.UPDATE_ORG_RESOURCE)) ||
            (!initialIsOrganisationResource &&
                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.UPDATE_OWN_RESOURCE))
        ) {
            containsPermission = true;
        }

        return containsPermission;
    };

    const handleDelete = async (mediaFileIdentifier) => {
        setLoadingDelete(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DELETE_MEDIA_FILES)) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("You do not have the necessary permission to delete a media file");
            setLoadingDelete(false);
            setShowDeleteConfirmation(false);
            return;
        }

        try {
            await deleteResourceFile(resource.identifier, mediaFileIdentifier);
            setShowDeleteConfirmation(false);

            fetchResource();
            fetchAllResourceRatings();

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully deleted the attachment file from resource");
        } catch (e) {
            setShowDeleteConfirmation(false);

            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });

        setLoadingDelete(false);
    };

    const handleDeleteConfirmation = (mediaFileIdentifier) => {
        setShowDeleteConfirmation(true);
        setSelectedMediaFileIdentifier(mediaFileIdentifier);
    };

    const handleResourceFileDownload = async (mediaFileIdentifier) => {
        const isDownloading = (status) => {
            let downloadingStatus = downloading.slice();
            status === true
                ? (downloadingStatus[mediaFileIdentifier] = true)
                : (downloadingStatus[mediaFileIdentifier] = false);
            setDownloading(downloadingStatus);
        };

        isDownloading(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DOWNLOAD_MEDIA_FILES)) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("You do not have the necessary permission to download a resource attachment");
            isDownloading(false);
            return;
        }

        let mediaFileResponse = null;

        try {
            mediaFileResponse = await downloadResourceFile(resource.identifier, mediaFileIdentifier);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
            isDownloading(false);
        }

        const { fileType } = mediaFileResponse;

        if (!fileType) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry there was an error while processing media file response, please try again later or contact techsupport@psychpress.com.au"
            );
            isDownloading(false);
            return;
        }

        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry there was an error while processing media file type, please try again later or contact techsupport@psychpress.com.au"
            );
            isDownloading(false);
            return;
        }

        let blob = await (await fetch(`data:${mimeType};base64,${mediaFileResponse.content}`)).blob();

        if (!blob) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry there was an error downloading attachment file, please try again later or contact techsupport@psychpress.com.au"
            );
            isDownloading(false);
            return;
        }

        const downloadLink = document.createElement("a");

        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = mediaFileResponse.fileName.split(".").join("_");
        downloadLink.click();

        isDownloading(false);
    };

    useEffect(() => {
        const fetchResourceTypes = async () => {
            setLoadingResourceTypes(true);

            try {
                const response = await getAllResourceTypes();

                const sanitizedResponse = response.map((resourceType) => ({
                    value: resourceType,
                    label: resourceType,
                }));

                setSelectableResourceTypes(sanitizedResponse);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }

            setLoadingResourceTypes(false);
        };

        fetchResourceTypes();
    }, [setAlert, setAlertType]);

    return (
        <>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-4 ">
                <Input
                    id="resourceName"
                    type="text"
                    label="Resource name"
                    icon={<BuildingLibraryIcon className="h-4 w-4 text-slate-500" />}
                    placeholder={"Resource name"}
                    currentValue={title && title}
                    backgroundColour="bg-white"
                    extraStyling={"col-span-2 lg:col-span-1"}
                    onChange={setTitle}
                    disabled={!containsUpdateResourcePermission()}
                />

                <Input
                    id="group"
                    type="text"
                    label="Subtitle"
                    icon={<Bars2Icon className="h-4 w-4 text-slate-500" />}
                    placeholder={"Resource subtitle"}
                    currentValue={subtitle && subtitle}
                    backgroundColour="bg-white"
                    extraStyling={"col-span-2 lg:col-span-1"}
                    onChange={setSubtitle}
                    disabled={!containsUpdateResourcePermission()}
                />

                <Textarea
                    id="description"
                    type="text"
                    label="Description"
                    placeholder={"Resource description"}
                    value={description && description}
                    extraStyling={"col-span-2"}
                    onChange={setDescription}
                    backgroundColour="bg-white"
                    disabled={!containsUpdateResourcePermission()}
                />

                {resourceType === RESOURCE_TYPES.READING && (
                    <Input
                        id="durationMinutes"
                        type="number"
                        label="Reading duration in minutes"
                        icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                        placeholder={"Estimated reading duration (minutes)"}
                        currentValue={durationMins && durationMins}
                        extraStyling={"col-span-2"}
                        backgroundColour="bg-white"
                        onChange={setDurationMins}
                        disabled={!containsUpdateResourcePermission()}
                    />
                )}

                {resourceType === RESOURCE_TYPES.WORKSHOP && (
                    <>
                        <Input
                            id="startTime"
                            type="datetime-local"
                            label="Workshop date time"
                            icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                            placeholder={"Date time"}
                            currentValue={startTime && startTime}
                            extraStyling={"col-span-2 lg:col-span-1"}
                            backgroundColour="bg-white"
                            onChange={setStartTime}
                            disabled={!containsUpdateResourcePermission()}
                        />

                        <Input
                            id="durationMinutes"
                            type="number"
                            label="Workshop duration in minutes"
                            icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                            placeholder={"Workshop duration (minutes)"}
                            currentValue={durationMins && durationMins}
                            extraStyling={"col-span-2 lg:col-span-1"}
                            backgroundColour="bg-white"
                            onChange={setDurationMins}
                            disabled={!containsUpdateResourcePermission()}
                        />

                        <Input
                            id="location"
                            type="text"
                            label="Workshop location"
                            icon={<MapPinIcon className="h-4 w-4 text-slate-500" />}
                            placeholder={"Workshop location"}
                            currentValue={resourceLocation && resourceLocation}
                            backgroundColour="bg-white"
                            extraStyling={"col-span-2"}
                            onChange={setResourceLocation}
                            disabled={!containsUpdateResourcePermission()}
                        />
                    </>
                )}

                {resourceType === RESOURCE_TYPES.COURSE && (
                    <>
                        <Input
                            id="startTime"
                            type="datetime-local"
                            label="Course date time"
                            icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                            placeholder={"Date time"}
                            currentValue={startTime && startTime}
                            backgroundColour="bg-white"
                            onChange={setStartTime}
                            disabled={!containsUpdateResourcePermission()}
                        />

                        <Input
                            id="durationMinutes"
                            type="number"
                            label="Course duration in minutes"
                            icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                            placeholder={"Course duration (minutes)"}
                            currentValue={durationMins && durationMins}
                            backgroundColour="bg-white"
                            onChange={setDurationMins}
                            disabled={!containsUpdateResourcePermission()}
                        />

                        <Input
                            id="location"
                            type="text"
                            label="Course location"
                            icon={<MapPinIcon className="h-4 w-4 text-slate-500" />}
                            placeholder={"Course location"}
                            currentValue={resourceLocation && resourceLocation}
                            extraStyling={"col-span-2"}
                            backgroundColour="bg-white"
                            onChange={setResourceLocation}
                            disabled={!containsUpdateResourcePermission()}
                        />
                    </>
                )}

                <div>
                    <label className="text-sm font-medium text-primary-600 w-full ">Type</label>
                    <div className={"flex gap-2 items-center mt-2 mb-3"}>
                        {resourceType === RESOURCE_TYPES.COURSE && (
                            <RectangleStackIcon
                                className={
                                    "flex-shrink-0 h-7 w-7 text-amber-600 group-hover:text-amber-700 " +
                                    "transition transition-colors"
                                }
                            />
                        )}

                        {resourceType === RESOURCE_TYPES.READING && (
                            <BookOpenIcon
                                className={
                                    "flex-shrink-0 h-7 w-7 text-pink-600 group-hover:text-pink-700 " +
                                    "transition transition-colors"
                                }
                            />
                        )}

                        {resourceType === RESOURCE_TYPES.WORKSHOP && (
                            <PuzzlePieceIcon
                                className={
                                    "flex-shrink-0 h-7 w-7 text-purple-600 group-hover:text-purple-700 " +
                                    "transition transition-colors"
                                }
                            />
                        )}

                        <div>
                            {resourceType && <Tag status={resourceType} isResource={true} />}

                            {/* Might be used in the future
                            {resourceType === RESOURCE_TYPES.COURSE && <Tag status="Course" />}

                            {resourceType === RESOURCE_TYPES.READING && <Tag status="Reading" />}

                            {resourceType === RESOURCE_TYPES.WORKSHOP && <Tag status="Workshop" />}
                            */}
                        </div>
                    </div>

                    {containsUpdateResourcePermission() && (
                        <MultiSelect
                            items={selectableResourceTypes}
                            setChosenItems={setResourceType}
                            placeholder={"Select resource type"}
                            value={resourceType}
                            extraStyling={"basis-1/2"}
                            backgroundColour={"#FFFFFF"}
                            disabled={!containsUpdateResourcePermission()}
                            icon={<ArchiveBoxIcon className="h-4 w-4 text-slate-500" />}
                            loading={loadingResourceTypes}
                        />
                    )}
                </div>

                <div>
                    <label className="text-sm font-medium text-primary-600 w-full ">Uploaded by</label>
                    <div className={"flex gap-2 items-center mt-2 mb-3"}>
                        {resource?.isAssigned === true && isOrganisationResource === false ? (
                            <Tag status={"Organisation member"} />
                        ) : (
                            <Tag status={isOrganisationResource ? "Organisation" : "You"} />
                        )}
                    </div>

                    {containsUpdateResourcePermission() && (
                        <div
                            className={
                                "flex mt-5 items-center " +
                                (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.UPLOAD_ORG_RESOURCES) &&
                                    "opacity-50")
                            }
                        >
                            <input
                                id="isOrganisationResource"
                                name="isOrganisationResource"
                                type="checkbox"
                                className="focus:ring- h-4 w-4 text-primary-600 border-gray-300 rounded-md"
                                checked={isOrganisationResource}
                                onChange={(e) => setIsOrganisationResource(e.target.checked)}
                                disabled={
                                    !context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.UPLOAD_ORG_RESOURCES)
                                }
                            />
                            <label htmlFor="push-nothing" className="ml-3 block text-sm text-slate-600">
                                Upload as organisation
                            </label>
                        </div>
                    )}
                </div>

                <div>
                    <label className="text-sm font-medium text-primary-600 w-full ">Your rating</label>
                    <div className="mt-2 flex gap-2 items-center">
                        <StarRating
                            setOwnRating={setOwnRating}
                            starsRating={ownRating}
                            resourceIdentifier={resource.identifier}
                            setAlert={setAlert}
                            setAlertType={setAlertType}
                            handleRatingExternally={true}
                            readonly={!containsUpdateResourcePermission()}
                        />
                    </div>
                </div>

                <div>
                    <label className="text-sm font-medium text-primary-600 w-full ">Overall rating</label>
                    {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_RATINGS) ? (
                        <div className="mt-2 flex gap-2 items-center">
                            <StarRating
                                starsRating={
                                    context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_RATINGS)
                                        ? resource.ratingStars
                                        : 0
                                }
                                readonly={true}
                            />
                            <span className={"text-sm font-medium text-slate-500"}>({allResourceRatings.length})</span>
                        </div>
                    ) : (
                        <div className="mt-2 flex gap-2 items-center">
                            <span className={"text-xs text-slate-400 italic "}>
                                You do not have the necessary permission(s) to view ratings
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <div>
                <div className="text-sm font-medium text-primary-600 w-full mt-6 mb-4">Links</div>
                <AddLinks
                    itemName={"link"}
                    links={resourceLinks ? resourceLinks : []}
                    setLinks={setResourceLinks}
                    readOnly={!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.EDIT_RESOURCE_LINK)}
                />
            </div>

            <div className={"mt-6"}>
                <label className="text-sm font-medium text-primary-600 w-full ">Attachments</label>
                <div className={"mt-2"}>
                    {mediaFiles ? (
                        mediaFiles.length === 0 ? (
                            <span className={"text-xs text-slate-400 italic "}>This resource has no attachments</span>
                        ) : (
                            <ul className="bg-white rounded-xl">
                                {mediaFiles.map((mediaFile, index) => (
                                    <>
                                        <li
                                            key={index}
                                            className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                        >
                                            <div className="w-0 flex-1 flex items-center">
                                                <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-slate-400" />

                                                <span className="ml-2 flex-1 w-0 truncate text-slate-600">
                                                    {mediaFile.name}
                                                </span>
                                            </div>

                                            <div className="ml-4 flex-shrink-0">
                                                <span className="font-medium text-primary-600 hover:text-primary-500">
                                                    <div className={"flex flex-row gap-3 items-center"}>
                                                        <Button
                                                            iconPositionLeft
                                                            icon={
                                                                <ArrowDownTrayIcon className="block h-3.5 w-3.5 mr-2" />
                                                            }
                                                            colour={BUTTON_VARIANT_TYPES.UNCOLOURED}
                                                            text="Download"
                                                            extraStyling="shadow-none text-primary-600 p-0 text-xs focus:ring-0 focus:transparent hover:text-primary-700"
                                                            onClick={() =>
                                                                handleResourceFileDownload(mediaFile.identifier)
                                                            }
                                                            loading={downloading[mediaFile.identifier] || false}
                                                        />
                                                    </div>
                                                </span>
                                            </div>

                                            {context.userPermissions.includes(
                                                COACH_PLATFORM_PERMISSIONS.DELETE_MEDIA_FILES
                                            ) && (
                                                <div className="ml-4 flex-shrink-0">
                                                    <span className="font-medium text-red-500 hover:text-red-600">
                                                        <div className={"flex flex-row gap-3 items-center"}>
                                                            <Button
                                                                iconPositionLeft
                                                                icon={<TrashIcon className="block h-3.5 w-3.5 mr-2" />}
                                                                colour={BUTTON_VARIANT_TYPES.UNCOLOURED}
                                                                text="Delete"
                                                                extraStyling="shadow-none text-red-600 p-0 text-xs focus:ring-0 focus:transparent hover:text-red-700"
                                                                loadingIconColour="text-primary"
                                                                onClick={() =>
                                                                    handleDeleteConfirmation(mediaFile.identifier)
                                                                }
                                                                loading={loadingDelete}
                                                                disabled={loadingDelete}
                                                            />
                                                        </div>
                                                    </span>
                                                </div>
                                            )}
                                        </li>

                                        {selectedMediaFileIdentifier === mediaFile.identifier && (
                                            <DeleteConfirmation
                                                showDeleteConfirmation={showDeleteConfirmation}
                                                setShowDeleteConfirmation={setShowDeleteConfirmation}
                                                text={"attachment file"}
                                                handleDelete={() => handleDelete(mediaFile.identifier)}
                                                loading={loadingDelete}
                                            />
                                        )}
                                    </>
                                ))}
                            </ul>
                        )
                    ) : (
                        <></>
                    )}
                </div>

                {containsUpdateResourcePermission() && (
                    <div className="mt-4">
                        <Upload
                            files={files}
                            setFiles={setFiles}
                            infoTextClick={"Click to choose file"}
                            infoTextSecondary={"or drag and drop file to upload"}
                            noFilesUploadedText={"You haven't uploaded any files"}
                            formats={
                                "image/jpeg, image/png, application/pdf, application/vnd.ms-powerpoint, " +
                                "application/vnd.ms-excel, application/msword, " +
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document, "
                            }
                        />
                    </div>
                )}
            </div>
        </>
    );
}
