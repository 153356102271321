export default function MemberCard(props) {
    const { member } = props;

    return (
        <div className={"flex flex-row items-center bg-white shadow-sm p-3 rounded-lg w-max"}>
            <div className={"flex flex-col gap-1"}>
                <div className={"text-primary-600 font-medium"}>
                    {member.firstName} {member.lastName}
                </div>
                <div className={"text-slate-400"}>{member.emailAddress}</div>
            </div>
        </div>
    );
}
