import Tippy from "@tippyjs/react";

export default function TippyComponent(props) {
    const { children, backgroundColour, icon, text, disabled, placement } = props;

    return (
        <Tippy
            content={
                <div className="flex flex-row gap-1 items-center">
                    {icon && icon}
                    {text}
                </div>
            }
            className={
                "py-1.5 px-2 shadow rounded-2xl text-xs font-medium text-white " +
                (backgroundColour ? backgroundColour : "bg-primary-600")
            }
            arrow={false}
            animation={"shift-away-subtle"}
            hideOnClick={false}
            placement={placement ? placement : "top-end"}
            disabled={disabled && disabled}
        >
            <div>{children}</div>
        </Tippy>
    );
}
