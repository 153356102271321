import { useCallback, useEffect, useState } from "react";
import useSearch from "../../../utils/react-hook-search";

import Pagination from "./Pagination";
import Cards from "./Cards";
import Table from "./Table";

export default function PaginatedSearch(props) {
    const {
        loading,
        items,
        itemName,
        ItemRow,
        searchAttributes,
        columnNames,
        customItemsPerPage,
        noTableHead,
        cards,
        filters,
        itemRowToggleOnChange,
        noPreviousAccessUser,
        accessDefaultToggleState,
        latestItemInteracted,
        updateItemStatus,
        setAlert,
        setAlertType,
        fetchItems,
        overflowVisible,
        customPlaceholder,
        customCardGrid,
        inputBackgroundColour,
        inputBorder,
        printMode,
    } = props;

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPaginatedItems, setCurrentPaginatedItems] = useState([]);
    const [itemsArray, setItemsArray] = useState(true);
    const [paginationLoading, setPaginationLoading] = useState(true);

    const itemsPerPage = customItemsPerPage ? customItemsPerPage : 8;

    const [filteredItems, search, setSearch] = useSearch(currentItems, searchAttributes);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % currentItems.length;
        setItemOffset(newOffset);
    };

    const runPagination = useCallback(
        (currentItems) => {
            setPaginationLoading(true);

            const endOffset = itemOffset + itemsPerPage;
            setCurrentPaginatedItems(currentItems.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(currentItems.length / itemsPerPage));

            setPaginationLoading(false);
        },
        [itemOffset, itemsPerPage]
    );

    useEffect(() => {
        if (Array.isArray(items)) {
            setCurrentItems(items);
            setItemsArray(true);
        } else {
            setItemsArray(false);
        }
    }, [items]);

    useEffect(() => {
        runPagination(currentItems);
    }, [currentItems, runPagination, itemsArray]);

    useEffect(() => {
        runPagination(filteredItems);
    }, [filteredItems, itemOffset, itemsPerPage, runPagination, itemsArray]);

    return (
        <div className="flex flex-col gap-4">
            <input
                value={search}
                onChange={setSearch}
                type="text"
                name="search"
                id="search"
                placeholder={customPlaceholder ? customPlaceholder : "Search " + itemName}
                className={
                    "p-3 h-10 focus:outline-none text-sm rounded-xl " +
                    "focus:ring-inset focus:ring-2 focus:ring-primary-300 focus:border-transparent block w-full " +
                    "text-slate-500 border border-slate-100 border-1 shadow-sm " +
                    (inputBackgroundColour ? inputBackgroundColour : "bg-white") +
                    " " +
                    (inputBorder ? inputBorder : "border-slate-100") +
                    " " +
                    (printMode ? "hidden" : "block")
                }
            />

            {filters && <div className={"flex gap-2"}>{filters}</div>}

            {cards ? (
                <Cards
                    customCardGrid={customCardGrid}
                    loading={loading}
                    paginationLoading={paginationLoading}
                    itemsArray={itemsArray}
                    items={currentPaginatedItems}
                    ItemRow={ItemRow}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    updateItemStatus={updateItemStatus}
                />
            ) : (
                <Table
                    customCardGrid={customCardGrid}
                    loading={loading}
                    paginationLoading={paginationLoading}
                    itemsArray={itemsArray}
                    items={currentPaginatedItems}
                    ItemRow={ItemRow}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    updateItemStatus={updateItemStatus}
                    overflowVisible={overflowVisible}
                    noTableHead={noTableHead}
                    columnNames={columnNames}
                    itemRowToggleOnChange={itemRowToggleOnChange}
                    noPreviousAccessUser={noPreviousAccessUser}
                    accessDefaultToggleState={accessDefaultToggleState}
                    latestItemInteracted={latestItemInteracted}
                    fetchItems={fetchItems}
                />
            )}
            {currentItems.length !== 0 && (
                <div className={"flex items-center justify-center w-full print:hidden"}>
                    <Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
                </div>
            )}
        </div>
    );
}
