import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    ArrowUturnLeftIcon,
    DocumentArrowDownIcon,
    DocumentTextIcon,
    TagIcon,
    UserIcon,
} from "@heroicons/react/24/solid";

import { Context } from "../../../../context/Context";
import { BUTTON_VARIANT_TYPES, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../utils/constants";
import { getOrganisationUsersLite } from "../../../../utils/api";
import { uploadReport } from "../../../../utils/api";

import { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Alert from "../../../SharedComponents/Alert";
import Button from "../../../SharedComponents/Button";
import Input from "../../../SharedComponents/Form/Input";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";

export default function UploadReport() {
    const [alert, setAlert] = useState("");
    const [loading, setLoading] = useState(true);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [organisationUsers, setOrganisationUsers] = useState();
    const [reportTags, setReportTags] = useState([]);
    const [chosenUser, setChosenUser] = useState("");
    const [reportName, setReportName] = useState("");
    const [reportFile, setReportFile] = useState("");

    const navigate = useNavigate();

    const context = useContext(Context);

    const handleUploadReport = async () => {
        setUploadLoading(true);

        if (reportName === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter a report name.");
            setUploadLoading(false);
            return;
        }

        if (reportFile === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please attach a report file.");
            setUploadLoading(false);
            return;
        }

        if (chosenUser === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please add a user.");
            setUploadLoading(false);
            return;
        }

        try {
            const sanitizedTags = reportTags.map((reportTag) => reportTag.value);
            await uploadReport(chosenUser, reportFile, reportName, sanitizedTags, "");

            context?.fetchReports();

            navigate("/reports", {
                state: {
                    alertType: ALERT_TYPE.SUCCESS,
                    alertMessage: 'Successfully uploaded report "' + reportName + '"',
                },
            });
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }

        setUploadLoading(false);
    };

    useEffect(() => {
        const fetchOrganisationUsers = async () => {
            setLoading(true);
            try {
                const response = await getOrganisationUsersLite(false);
                const sanitizedResponse = response.map((user) => ({
                    value: user.emailAddress,
                    label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")",
                }));

                setOrganisationUsers(sanitizedResponse);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoading(false);
        };
        fetchOrganisationUsers();
    }, []);

    return (
        <DashboardLayout title={"Upload Report"} requiredPermissions={[CUSTOMER_PORTAL_PERMISSIONS.UPLOAD_REPORTS]}>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex flex-col"}>
                    <div
                        className={
                            "grid grid-flow-row xl:grid-cols-2 gap-6 p-4 border border-slate-100 border-1 rounded-t-xl"
                        }
                    >
                        <div className={"col-span-2 lg:col-span-1 flex flex-col gap-3"}>
                            <Input
                                id="reportName"
                                type="text"
                                label="Report name"
                                placeholder={"Report name"}
                                icon={<DocumentTextIcon className="h-4 w-4 text-slate-500" />}
                                onChange={setReportName}
                            />
                        </div>
                        <div className={"col-span-2 lg:col-span-1 flex flex-col gap-3"}>
                            <div className="flex flex-col gap-2 w-full">
                                <div className={"flex justify-between w-full"}>
                                    <label
                                        htmlFor="reportFile"
                                        className="text-sm font-medium text-primary-600 w-full "
                                    >
                                        Report file
                                    </label>
                                </div>
                                <div className="flex rounded-xl shadow-sm bg-slate-50 w-full cursor-pointer">
                                    <span className="sr-only">Choose File</span>
                                    <input
                                        id="reportFile"
                                        type="file"
                                        className={
                                            "block w-full text-sm text-slate-600 file:mr-4 file:py-2 file:px-4 " +
                                            "file:rounded-xl file:border-0 file:text-xs file:font-semibold " +
                                            "file:bg-primary-50 file:text-primary-700 file:cursor-pointer " +
                                            "hover:file:bg-primary-100 file:file:h-9"
                                        }
                                        onChange={(e) => setReportFile(e.target.files[0])}
                                        accept="application/pdf, application/msword, .pdf, .doc, .docx"
                                    />
                                </div>

                                <div className={"text-xs text-slate-400 w-full text-right"}>
                                    Supported file formats: PDF, DOC, DOCX
                                </div>
                            </div>
                        </div>
                        <div className={"col-span-2 flex flex-col gap-3"}>
                            <MultiSelect
                                items={organisationUsers}
                                labelText={
                                    (context.terminology.coachee ? context.terminology.coachee.singular : "Coachee") +
                                    "/User"
                                }
                                loading={loading}
                                setChosenItems={setChosenUser}
                                icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                            />
                        </div>
                        <div className={"col-span-2 flex flex-col gap-3"}>
                            <MultiSelect
                                isMulti
                                isCreatable
                                items={reportTags}
                                labelText={"Report tags"}
                                setChosenItems={setReportTags}
                                icon={<TagIcon className="h-4 w-4 text-slate-500" />}
                            />
                            <div className={"text-xs text-slate-400 w-full text-right"}>Optional</div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 text-right rounded-b-xl w-full"
                    }
                >
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GRAY}
                        text={"Back"}
                        iconPositionLeft
                        icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5 mr-2" />}
                        onClick={() => navigate("/reports")}
                    />
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text="Upload report"
                        icon={<DocumentArrowDownIcon className="block h-3.5 w-3.5 mr-2" />}
                        iconPositionLeft
                        loading={uploadLoading}
                        onClick={handleUploadReport}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}
