import LoginNavbar from "./LoginNavbar";

export default function LoginLayout(props) {
    const { children } = props;

    return (
        <>
            <LoginNavbar />
            <main className="flex w-full bg-gradient-to-r from-primary-50 via-slate-50 to-secondary-50 bg-slate-50">
                <div className="w-full py-4 px-6 z-10 overflow-y-scroll flex items-center justify-center h-screen">
                    {children}
                </div>
            </main>
        </>
    );
}
