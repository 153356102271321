import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    AtSymbolIcon,
    UserIcon,
    DevicePhoneMobileIcon,
    UsersIcon,
    ArrowUturnLeftIcon,
    BriefcaseIcon,
    MapPinIcon,
    ArrowsRightLeftIcon,
    CheckBadgeIcon,
} from "@heroicons/react/24/solid";

import { getOrganisationUsersLite, setupCoach, setupNewCoach } from "../../../../utils/api";
import {
    BUTTON_VARIANT_TYPES,
    USER_GROUPS,
    CUSTOMER_PORTAL_PERMISSIONS,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Button from "../../../SharedComponents/Button";
import Input from "../../../SharedComponents/Form/Input";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import Textarea from "../../../SharedComponents/Form/Textarea";

export default function SetupCoach() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [existingUserSetup, setExistingUserSetup] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [chosenUserIdentifier, setChosenUserIdentifier] = useState();
    const [mobileNumber, setMobileNumber] = useState("");
    const [biography, setBiography] = useState("");
    const [yearsExperience, setYearsExperience] = useState("");
    const [accreditations, setAccreditations] = useState([]);
    const [coachLocations, setCoachLocations] = useState([]);
    const [organisationLocations, setOrganisationLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectableOrganisationUsers, setSelectableOrganisationUsers] = useState([]);
    const [organisationUsers, setOrganisationUsers] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const navigate = useNavigate();

    const context = useContext(Context);

    const handleExistingUserSetupChange = async () => {
        if (existingUserSetup) {
            setExistingUserSetup(false);
        } else {
            setExistingUserSetup(true);
        }
    };

    const handleChosenUserIdentifierPick = useCallback(async () => {
        if (chosenUserIdentifier) {
            const selectedUser = organisationUsers.find((user) => user.userIdentifier === chosenUserIdentifier);
            setEmailAddress(selectedUser.emailAddress);
        }
    }, [chosenUserIdentifier, organisationUsers]);

    const handleSetupCoach = async () => {
        setSubmitLoading(true);

        try {
            setSubmitLoading(true);

            if (existingUserSetup) {
                await setupCoach(chosenUserIdentifier);
            } else {
                if (!emailAddress || emailAddress.length === 0) {
                    setAlertType(ALERT_TYPE.ERROR);
                    setAlert("Please enter a valid email address");
                    setSubmitLoading(false);
                    return;
                }

                const selectedAccreditations = [];
                accreditations.map((accreditation) => selectedAccreditations.push(accreditation.value));

                const selectedLocations = [];
                coachLocations.map((location) => selectedLocations.push(location.value));

                await setupNewCoach(
                    firstName,
                    lastName,
                    emailAddress,
                    mobileNumber,
                    biography,
                    yearsExperience,
                    selectedAccreditations
                );
            }

            navigate(`/${context.terminology.coach.plural.toLowerCase()}`, {
                state: {
                    alertType: ALERT_TYPE.SUCCESS,
                    alertMessage:
                        "Successfully setup " +
                        firstName +
                        " " +
                        lastName +
                        " as a " +
                        context.terminology.coach.singular.toLowerCase(),
                },
            });
            setSubmitLoading(false);
        } catch (e) {
            setSubmitLoading(false);
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(
                "Sorry, there's been an error setting up your " +
                    context.terminology.coach.singular +
                    ". Check that all details are correct and that the email address isn't already being used by " +
                    "another user"
            );
        }

        setSubmitLoading(false);
    };

    const fetchOrganisationUsers = useCallback(async () => {
        setLoading(true);

        try {
            const response = await getOrganisationUsersLite(true);

            response
                .filter((user) => !user.userGroups.includes(USER_GROUPS.COACH))
                .map((user) =>
                    setSelectableOrganisationUsers((users) => [
                        {
                            value: user.userIdentifier,
                            label: user.firstName + " " + user.lastName,
                        },
                        ...users,
                    ])
                );

            setOrganisationUsers(response);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setLoading(false);
    }, []);

    const fetchOrganisationLocations = useCallback(async () => {
        setLoading(true);
        try {
            const sampleOrganisationLocations = ["Footscray", "Melbourne", "Kew", "Balwyn North", "Coburg", "Kooyong"];

            sampleOrganisationLocations.forEach((location) => {
                setOrganisationLocations((locations) => [
                    {
                        value: location,
                        label: location,
                    },
                    ...locations,
                ]);
            });
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchOrganisationUsers();
        fetchOrganisationLocations();
    }, [fetchOrganisationUsers, fetchOrganisationLocations]);

    useEffect(() => {
        handleChosenUserIdentifierPick();
    }, [handleChosenUserIdentifierPick]);

    return (
        <DashboardLayout
            title={"Setup " + context.terminology.coach ? context.terminology.coach.singular : "Coach"}
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.SETUP_COACH,
                CUSTOMER_PORTAL_PERMISSIONS.CREATE_USER_ACCOUNTS,
                CUSTOMER_PORTAL_PERMISSIONS.MANAGE_USERS_IN_USER_GROUP,
            ]}
        >
            <p className="mb-4 text-sm text-slate-500">
                Please complete the form below to add a new{" "}
                {context.terminology.coach ? context.terminology.coach.singular : "Coach"}
            </p>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4 border border-slate-100 border-1 rounded-t-xl"}>
                        <div
                            className={
                                "grid grid-flow-row lg:grid-cols-2 gap-6 mb-6 " +
                                (existingUserSetup ? "xl:grid-cols-2 xl:grid-rows-1" : "xl:grid-cols-2 xl:grid-rows-1")
                            }
                        >
                            <div className="col-span-2 justify-between flex items-center">
                                <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                    {!existingUserSetup
                                        ? `Set up new ${context.terminology.coach.singular.toLowerCase()}`
                                        : "Set up existing user"}
                                </h2>
                                <div
                                    onClick={handleExistingUserSetupChange}
                                    className={"text-sm text-left font-medium"}
                                >
                                    <div
                                        className={
                                            "text-primary-600 cursor-pointer hover:text-primary-700 bg-primary-50 p-2 rounded-xl shadow-sm flex w-fit"
                                        }
                                    >
                                        <ArrowsRightLeftIcon className="h-4 w-4 text-primary-600 hover:text-primary-700 self-center mr-2" />
                                        {existingUserSetup
                                            ? `Set up new ${context.terminology.coach.singular.toLowerCase()}`
                                            : "Set up existing user"}
                                    </div>
                                </div>
                            </div>
                            {existingUserSetup && (
                                <MultiSelect
                                    items={selectableOrganisationUsers}
                                    labelText={"User"}
                                    loading={loading}
                                    setChosenItems={setChosenUserIdentifier}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                />
                            )}
                            <Input
                                id="email"
                                type="email"
                                label="Email"
                                placeholder="Enter email"
                                onChange={setEmailAddress}
                                icon={<AtSymbolIcon className="h-4 w-4 text-slate-500" />}
                                disabled={existingUserSetup ? "disabled" : ""}
                                value={emailAddress}
                            />
                            {!existingUserSetup && (
                                <Input
                                    optional
                                    id="mobile"
                                    type="number"
                                    label="Mobile"
                                    placeholder="Enter mobile number"
                                    onChange={setMobileNumber}
                                    icon={<DevicePhoneMobileIcon className="h-4 w-4 text-slate-500" />}
                                    disabled={existingUserSetup ? "disabled" : ""}
                                    value={mobileNumber}
                                />
                            )}
                            {!existingUserSetup && (
                                <Input
                                    optional
                                    type="text"
                                    label="First name"
                                    id="firstname"
                                    placeholder={"First name"}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setFirstName}
                                    value={firstName}
                                />
                            )}
                            {!existingUserSetup && (
                                <Input
                                    optional
                                    type="text"
                                    label="Last name"
                                    id="lastname"
                                    placeholder={"Last name"}
                                    icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setLastName}
                                    value={lastName}
                                />
                            )}

                            {!existingUserSetup && (
                                <Input
                                    id="experience"
                                    type="number"
                                    label="Years of experience"
                                    placeholder={"Years of experience (number)"}
                                    icon={<BriefcaseIcon className="h-4 w-4 text-slate-500" />}
                                    extraStyling="col-span-1"
                                    value={yearsExperience}
                                    onChange={setYearsExperience}
                                    optional
                                    hidden // Backend endpoint to create Coach doesn't support Years of experience
                                />
                            )}

                            {!existingUserSetup && (
                                <Textarea
                                    id="biography"
                                    label="Bio"
                                    placeholder={"Write about yourself.."}
                                    extraStyling="col-span-2"
                                    value={biography}
                                    onChange={setBiography}
                                    optional
                                    hidden // Backend endpoint to create Coach doesn't support Biography
                                />
                            )}

                            {!existingUserSetup && (
                                <MultiSelect
                                    isMulti
                                    isCreatable
                                    chosenItems={accreditations}
                                    labelText={"Accreditations"}
                                    setChosenItems={setAccreditations}
                                    extraStyling={"col-span-2"}
                                    icon={<CheckBadgeIcon className="h-4 w-4 text-slate-500" />}
                                    // Backend endpoint to create Coach does support Accreditation
                                    hidden // Hidden for consistency, currently allowing changes to be made when updating profile only.
                                    optional
                                />
                            )}
                            {!existingUserSetup && (
                                <MultiSelect
                                    isMulti
                                    items={organisationLocations}
                                    chosenItems={coachLocations}
                                    labelText={"Location(s)"}
                                    setChosenItems={setCoachLocations}
                                    extraStyling={"col-span-2"}
                                    icon={<MapPinIcon className="h-4 w-4 text-slate-500" />}
                                    optional
                                    hidden
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 text-right rounded-b-xl w-full bg-primary-50"
                    }
                >
                    <Button
                        colour={BUTTON_VARIANT_TYPES.GRAY}
                        text={"Back"}
                        iconPositionLeft
                        icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                        onClick={() => navigate(`/${context.terminology.coach.plural.toLowerCase()}`)}
                    />
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text={`Setup ${context.terminology.coach.singular}`}
                        icon={<UsersIcon className="block h-3.5 w-3.5  mr-2" />}
                        iconPositionLeft
                        onClick={handleSetupCoach}
                        loading={submitLoading}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}
