import CountUp from "react-countup";

import { useContext } from "react";
import { Context } from "../../../../../../../context/Context";

export default function NPSScoreCard(props) {
    const { data } = props;

    const context = useContext(Context);

    let currentScore = "";
    let sectorBenchmark = "";
    let historicalScore = "";

    if (data.data.find((npsItem) => npsItem.name === "NPS Score")) {
        currentScore = data.data.find((npsItem) => npsItem.name === "NPS Score").percentScore;
    }

    if (data.data.find((npsItem) => npsItem.name === "Sector benchmark")) {
        sectorBenchmark = data.data.find((npsItem) => npsItem.name === "Sector benchmark").percentScore;
    }

    if (data.historicalScore) {
        historicalScore = data.historicalScore;
    }

    return (
        <div className="grid print:grid-cols-3 grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2">
            {currentScore !== "" && (
                <div className="flex flex-col p-3 gap-4 bg-blue-100 rounded-xl border-2 border-blue-200 shadow-sm">
                    <div className="flex gap-4 justify-between items-center">
                        <h2 className={"text-lg font-title tracking-tight font-medium text-blue-600"}>
                            {context.aceSurveyReportData.currentYear}
                        </h2>
                    </div>
                    <div className="flex flex-row justify-between items-end gap-2 font-semibold">
                        <span className={"text-2xl font-semibold text-blue-600"}>
                            {currentScore > 0 ? "+" : ""}
                            <CountUp end={currentScore} duration={4} />
                        </span>
                    </div>
                </div>
            )}

            {historicalScore !== "" && (
                <div className="flex flex-col p-3 gap-4 bg-violet-100 rounded-xl border-2 border-violet-200 shadow-sm">
                    <div className="flex gap-4 justify-between items-center">
                        <h2 className={"text-lg font-title tracking-tight font-medium text-violet-600"}>
                            {context.aceSurveyReportData.historicalYear}
                        </h2>
                    </div>
                    <div className="flex flex-row justify-between items-end gap-2 font-semibold">
                        <span className={"text-2xl font-semibold text-violet-600"}>
                            {historicalScore > 0 ? "+" : ""}
                            <CountUp end={historicalScore} duration={4} />
                        </span>
                    </div>
                </div>
            )}

            {sectorBenchmark !== "" && (
                <div className="flex flex-col p-3 gap-4 bg-amber-100 rounded-xl border-2 border-amber-200 shadow-sm">
                    <div className="flex gap-4 justify-between items-center">
                        <h2 className={"text-lg font-title tracking-tight font-medium text-amber-600"}>
                            Sector benchmark
                        </h2>
                    </div>
                    <div className="flex flex-row justify-between items-end gap-2 font-semibold">
                        <span className={"text-2xl font-semibold text-amber-600"}>
                            {sectorBenchmark > 0 ? "+" : ""}
                            <CountUp end={sectorBenchmark} duration={4} />
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}
