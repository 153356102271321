import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { ACE_2_SURVEY_PLATFORM, COACH_PLATFORM_PERMISSIONS, REDIRECT_TYPES, USER_GROUPS } from "./utils/constants";
import { sampleSaleableMenuDrivenReport, sampleSaleableZollRecruitmentScore } from "./utils/mockData";
import { sampleCapabilityMapVGCCCScore } from "./utils/sampleChartData/sampleCapabilityMapVGCCCScore";
import { sampleCapabilityProfileCaseyScore } from "./utils/sampleChartData/sampleCapabilityProfileCaseyScore";
import { sampleCapabilityProfileVGCCCScore } from "./utils/sampleChartData/sampleCapabilityProfileVGCCCScore";
import { sampleCapabilityMapCaseyScore } from "./utils/sampleChartData/sampleCapabilityMapCaseyScore";
import { sampleCapabilityProfileGenericScore } from "./utils/sampleChartData/sampleCapabilityProfileGenericScore";
import { sampleCapabilityMapGenericScore } from "./utils/sampleChartData/sampleCapabilityMapGenericScore.js";
import { sampleCapabilityProfileSocialFuturesScore } from "./utils/sampleChartData/sampleCapabilityProfileSocialFuturesScore";
import { setSessionToken } from "./utils/api";
import { Context } from "./context/Context";
import "./index.css";

import Coachees from "./components/Dashboard/Content/Coachees/Coachees";
import Coaches from "./components/Dashboard/Content/Coaches/Coaches";
import GroupReports from "./components/Dashboard/Content/Reports/GroupReports/GroupReports";
import Import from "./components/SharedComponents/Import.js";
import AssignCoach from "./components/Dashboard/Content/Coachees/AssignCoach";
import SummaryReport from "./components/Dashboard/Content/Reports/SummaryReport";
import Assessments from "./components/Dashboard/Content/Assessments/Assessments";
import Apps from "./components/Dashboard/Content/Apps/Apps";
import CareerProfile from "./components/Dashboard/Content/Coachees/CareerProfile/CareerProfile";
import Sessions from "./components/Dashboard/Content/Sessions/Sessions";
import RedirectToCP from "./components/Auth/RedirectToCP";
import Auth from "./components/Auth/Auth";
import NotFound from "./components/Errors/ErrorPageSections/NotFound";
import SomethingHasGoneWrong from "./components/Errors/ErrorPageSections/SomethingHasGoneWrong";
import SessionExpired from "./components/Errors/ErrorPageSections/SessionExpired";
import Groups from "./components/Dashboard/Content/Groups/Groups";
import Profile from "./components/Dashboard/Content/Profile/Profile";
import OrganisationWelcomePage from "./components/Dashboard/WelcomePages/Organisation/OrganisationWelcomePage";
import Reports from "./components/Dashboard/Content/Reports/Reports";
import Export from "./components/Dashboard/Content/Reports/Export";
import OrganisationUsers from "./components/Dashboard/Content/Organisation/OrganisationUsers";
import OrganisationUserProfile from "./components/Dashboard/Content/Organisation/OrganisationUserProfile";
import Cohorts from "./components/Dashboard/Content/Cohorts/Cohorts";
import Programs from "./components/Dashboard/Content/Programs/Programs";
import CreateCohort from "./components/Dashboard/Content/Cohorts/CreateCohort";
import CreateProgram from "./components/Dashboard/Content/Programs/CreateProgram";
import Report from "./components/Dashboard/Content/Reports/Report/Report";
import Settings from "./components/Dashboard/Content/Settings/Settings";
import DashboardPlaceholder from "./components/SharedComponents/Loading/DashboardLoadingPlaceholder";
import Resources from "./components/Dashboard/Content/Resources/Resources";
import Resource from "./components/Dashboard/Content/Resources/Resource/Resource";
import UploadResource from "./components/Dashboard/Content/Resources/UploadResource";
import Cohort from "./components/Dashboard/Content/Cohorts/Cohort/Cohort";
import CoachProfile from "./components/Dashboard/Content/Coaches/CoachProfile/CoachProfile";
import CoacheeProfile from "./components/Dashboard/Content/Coachees/CoacheeProfile/CoacheeProfile";
import Program from "./components/Dashboard/Content/Programs/Program/Program";
import Session from "./components/Dashboard/Content/Sessions/Session/Session";
import JobSeekers from "./components/Dashboard/Content/JobSeekers/JobSeekers";
import Login from "./components/Auth/Login";
import AssignAppsAndAssessments from "./components/Dashboard/Content/AssignAppsAndAssessments/AssignAppsAndAssessments";
import NoPermission from "./components/Errors/ErrorPageSections/NoPermission";
import CreateSession from "./components/Dashboard/Content/Sessions/CreateSession";
import UploadReport from "./components/Dashboard/Content/Reports/UploadReport";
import SetupCoach from "./components/Dashboard/Content/Coaches/SetupCoach";
import SetupCoachee from "./components/SharedComponents/SetupCoachee";
import CoachTraining from "./components/Dashboard/Content/Coaches/CoachTraining/CoachTraining";
import ReportContent from "./components/Dashboard/Content/Reports/ReportContent/ReportContent";
import Interviews from "./components/Dashboard/Content/PracticeInterviews/Interviews";
import PracticeInterviews from "./components/Dashboard/Content/PracticeInterviews/PracticeInterviews";
import RecommendPracticeInterview from "./components/Dashboard/Content/PracticeInterviews/RecommendPracticeInterview";
import AddPracticeInterviewReviewer from "./components/Dashboard/Content/PracticeInterviews/AddPracticeInterviewReviewer";
import AssignAssessment from "./components/Dashboard/Content/Assessments/AssignAssessment";
import RecommendedJob from "./components/Dashboard/Content/Reports/RecommendedJob";
import UploadResume from "./components/Dashboard/Content/Coachees/UploadResume";
import PracticeInterviewFeedback from "./components/Dashboard/Content/PracticeInterviews/PracticeInterviewFeeback";
import AddCustomQuestions from "./components/Dashboard/Content/AddCustomQuestions/AddCustomQuestions";
import Snapshot from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/Snapshot/Snapshot";
import ExecutiveSummary from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/ExecutiveSummary";
import Sentiment from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/Sentiment/Sentiment";
import Demographics from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/Demographics";
import SetupMultipleCoachees from "./components/Dashboard/Content/Coachees/SetupMultipleCoachees";
import ACEKeyThemes from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/ACEKeyThemes";
import FieldManagerSatisfaction from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/FieldManagerSatisfaction/FieldManagerSatisfaction";
import Services from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/Services/Services";
import Qualitative from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/Qualitative/Qualitative";
import FollowThrough from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/FollowThrough";
import ReportContext from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/ReportContext";
import MenuDriven from "./components/Dashboard/Content/Reports/ReportContent/MenuDriven/MenuDriven";
import ApprenticeshipRiskCareerAssessment from "./components/Dashboard/Content/Reports/ReportContent/ApprenticeshipRiskCareerAssessment/ApprenticeshipRiskCareerAsssessement";
import PrintableSurvey from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/PrintableSurvey";
import AdditionalQuestions from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/Qualitative/AdditionalQuestions";
import CoacheesPaginationTest from "./components/Dashboard/Content/Coachees/CoacheesPaginationTest";
import Saleable from "./components/Dashboard/Content/Reports/ReportContent/Saleable/Saleable";
import CreateReportPDF from "./components/Dashboard/Content/Reports/CreateReportPDF";
import MemberQuestions from "./components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/MemberQuestions/MemberQuestions";
import CapabilityMap from "./components/Dashboard/Content/Reports/ReportContent/CapabilityCo/CapabilityMap/CapabilityMap.js";
import CapabilityProfile from "./components/Dashboard/Content/Reports/ReportContent/CapabilityCo/CapabilityProfile/CapabilityProfile.js";

function App() {
    const context = useContext(Context);

    useEffect(() => {
        if (sessionStorage.token) {
            context.setLoggedIn(true);
            setSessionToken(sessionStorage.token);
        } else {
            context.setLoggedIn(false);
        }
    }, [context]);

    return (
        <div className="App">
            <Router>
                {/* Until full implementation */}
                {/* {context.showAppWelcome && <WelcomePage />} */}
                {(() => {
                    if (
                        context.loggedIn === true &&
                        context.brandingLoading === false &&
                        context.terminologyLoading === false
                    ) {
                        return (
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        context.userType === null ? (
                                            <DashboardPlaceholder />
                                        ) : context.defaultApp === ACE_2_SURVEY_PLATFORM &&
                                          (context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS) ||
                                              context.userPermissions.includes(
                                                  COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENT_STATS_REGION
                                              ) ||
                                              context.userPermissions.includes(
                                                  COACH_PLATFORM_PERMISSIONS.VIEW_COUNTRY_COMPLETIONS
                                              )) ? (
                                            <Navigate replace to="/snapshot" />
                                        ) : context.defaultApp === ACE_2_SURVEY_PLATFORM ? (
                                            <Navigate replace to="/surveys" />
                                        ) : context.userType === USER_GROUPS.COACH ? (
                                            <Navigate
                                                replace
                                                to={encodeURI(`/${context.terminology.coachee.plural.toLowerCase()}`)}
                                            />
                                        ) : context.userType === USER_GROUPS.COACHEE ? (
                                            <Navigate replace to="/assessments" />
                                        ) : (
                                            <Navigate replace to="/assessments" />
                                        )
                                    }
                                />
                                <Route
                                    path={encodeURI(`/${context.terminology.coachee.singular.toLowerCase()}-profile`)}
                                    element={<CoacheeProfile />}
                                />
                                <Route
                                    path={encodeURI(`/${context.terminology.coach.plural.toLowerCase()}`)}
                                    element={<Coaches />}
                                />
                                <Route
                                    path={encodeURI(`/${context.terminology.coach.singular.toLowerCase()}-training`)}
                                    element={<CoachTraining />}
                                />
                                <Route
                                    path={encodeURI(`/${context.terminology.coach.singular.toLowerCase()}-profile`)}
                                    element={<CoachProfile />}
                                />
                                {context.defaultApp === "Coach Platform" ? (
                                    <Route
                                        path={encodeURI(`/${context.terminology.coachee.plural.toLowerCase()}`)}
                                        element={<Coachees />}
                                    />
                                ) : (
                                    <Route
                                        path={encodeURI(`/${context.terminology.coachee.plural.toLowerCase()}`)}
                                        element={<JobSeekers />}
                                    />
                                )}
                                <Route path="/organisation-users" element={<OrganisationUsers />} />
                                <Route path="/organisation-user-profile" element={<OrganisationUserProfile />} />
                                <Route path="/cohorts" element={<Cohorts />} />
                                <Route path="/cohort" element={<Cohort />} />
                                <Route path="/create-cohort" element={<CreateCohort />} />
                                <Route path="/programs" element={<Programs />} />
                                <Route path="/program" element={<Program />} />
                                <Route path="/create-program" element={<CreateProgram />} />
                                <Route path="/groups" element={<Groups />} />
                                <Route path="/group-reports" element={<GroupReports />} />
                                <Route path="/reports" element={<Reports />} />
                                <Route path="/report" element={<Report />} />
                                <Route path="/view-report" element={<ReportContent />} />
                                <Route path="/upload-report" element={<UploadReport />} />
                                <Route path="/recommended-job" element={<RecommendedJob />} />
                                <Route path="/import" element={<Import />} />
                                <Route path="/resources" element={<Resources />} />
                                <Route path="/resource" element={<Resource />} />
                                <Route path="/upload-resource" element={<UploadResource />} />
                                <Route
                                    path={encodeURI(`/setup-user/${context.terminology.coach.singular.toLowerCase()}`)}
                                    element={<SetupCoach />}
                                />
                                <Route
                                    path={encodeURI(
                                        `/setup-user/${context.terminology.coachee.singular.toLowerCase()}`
                                    )}
                                    element={<SetupCoachee />}
                                />
                                <Route
                                    path={encodeURI(`/assign-${context.terminology.coach.singular.toLowerCase()}`)}
                                    element={<AssignCoach />}
                                />
                                <Route path="/summary-report" element={<SummaryReport />} />
                                <Route path="/surveys" element={<Assessments />} />
                                <Route path="/assessments" element={<Assessments />} />
                                <Route path="/apps" element={<Apps />} />
                                <Route path="/sessions" element={<Sessions />} />
                                <Route path="/session" element={<Session />} />
                                <Route path="/create-session" element={<CreateSession />} />
                                <Route path="/export" element={<Export />} />
                                <Route path="/career-profile" element={<CareerProfile />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route
                                    path={encodeURI(
                                        `/${context.terminology.coachee.singular.toLowerCase()}-interviews`
                                    )}
                                    element={<Interviews />}
                                />
                                <Route path="/practice-interviews" element={<PracticeInterviews />} />
                                <Route path="/recommend-practice-interview" element={<RecommendPracticeInterview />} />
                                <Route path="/upload-resume" element={<UploadResume />} />
                                <Route
                                    path="/add-practice-interview-reviewer"
                                    element={<AddPracticeInterviewReviewer />}
                                />
                                <Route path="/practice-interview-feedback" element={<PracticeInterviewFeedback />} />
                                <Route path="/assign-assessment" element={<AssignAssessment />} />
                                <Route path="/assign-assessment-in-bulk" element={<AssignAssessment bulk />} />
                                <Route path="/welcome" element={<OrganisationWelcomePage />} />
                                <Route path="/settings" element={<Settings />} />
                                <Route path="/assign-apps-and-assessments" element={<AssignAppsAndAssessments />} />

                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route
                                        path="/snapshot"
                                        element={<Snapshot demo={process.env.REACT_APP_ENV !== "PRD"} />}
                                    />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route path="/executive-summary" element={<ExecutiveSummary />} />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route path="/sentiment" element={<Sentiment />} />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route path="/demographics" element={<Demographics />} />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route
                                        path="/ace-key-themes"
                                        element={
                                            context.aceSurveyReportData.aceMindsetIndex.length === 0 &&
                                            context.aceSurveyReportData.netPromoterScores.length === 0 ? (
                                                <Navigate replace to="/snapshot" />
                                            ) : (
                                                <ACEKeyThemes />
                                            )
                                        }
                                    />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route
                                        path="/field-manager-satisfaction"
                                        element={
                                            context.aceSurveyReportData.fieldManagers.length === 0 &&
                                            context.aceSurveyReportData.fieldManagersLevel.length === 0 ? (
                                                <Navigate replace to="/snapshot" />
                                            ) : (
                                                <FieldManagerSatisfaction />
                                            )
                                        }
                                    />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route
                                        path="/services"
                                        element={
                                            context.aceSurveyReportData.services.length === 0 ? (
                                                <Navigate replace to="/snapshot" />
                                            ) : (
                                                <Services />
                                            )
                                        }
                                    />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route path="/qualitative" element={<Qualitative />} />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route path="/follow-through" element={<FollowThrough />} />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route path="/report-context" element={<ReportContext />} />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route
                                        path="/additional-questions"
                                        element={
                                            context.aceSurveyReportData.additionalQuestions.length === 0 ? (
                                                <Navigate replace to="/snapshot" />
                                            ) : (
                                                <AdditionalQuestions />
                                            )
                                        }
                                    />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route
                                        path="/member-questions"
                                        element={
                                            context.aceSurveyReportData.memberQuestions.length === 0 ? (
                                                <Navigate replace to="/snapshot" />
                                            ) : (
                                                <MemberQuestions />
                                            )
                                        }
                                    />
                                )}
                                {context.defaultApp === ACE_2_SURVEY_PLATFORM && (
                                    <Route path="/create-report-pdf" element={<CreateReportPDF />} />
                                )}
                                <Route
                                    path={encodeURI(
                                        `/setup-multiple-${context.terminology.coachee.plural.toLowerCase()}`
                                    )}
                                    element={<SetupMultipleCoachees />}
                                />
                                <Route path="/add-custom-questions" element={<AddCustomQuestions />} />
                                <Route
                                    path="/menu-driven-report-demo"
                                    element={<MenuDriven score={sampleSaleableMenuDrivenReport.menuDriven} demo />}
                                />
                                <Route
                                    path="/apprenticeship-risk-career-assessment-report-demo"
                                    element={<ApprenticeshipRiskCareerAssessment />}
                                />

                                {process.env.REACT_APP_ENV === "NPD" && (
                                    <Route
                                        path="/saleable-report-demo"
                                        element={
                                            <Saleable
                                                score={sampleSaleableZollRecruitmentScore}
                                                demo
                                                showOldCoachingGuideline
                                            />
                                        }
                                    />
                                )}

                                {/*Generic*/}
                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-profile-report-demo"
                                        element={
                                            <CapabilityProfile
                                                score={sampleCapabilityProfileGenericScore}
                                                hideScoreNumbers
                                                old
                                            />
                                        }
                                    />
                                )}

                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-map-report-demo"
                                        element={<CapabilityMap score={sampleCapabilityMapGenericScore} />}
                                    />
                                )}

                                {/*City of Casey*/}
                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-profile-city-of-casey-report-demo"
                                        element={
                                            <CapabilityProfile
                                                score={sampleCapabilityProfileCaseyScore}
                                                hideScoreNumbers
                                            />
                                        }
                                    />
                                )}

                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-map-city-of-casey-report-demo"
                                        element={<CapabilityMap score={sampleCapabilityMapCaseyScore} />}
                                    />
                                )}

                                {/*VGCCC*/}
                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-profile-vgccc-report-demo"
                                        element={
                                            <CapabilityProfile
                                                score={sampleCapabilityProfileVGCCCScore}
                                                hideScoreNumbers
                                            />
                                        }
                                    />
                                )}

                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-map-vgccc-report-demo"
                                        element={<CapabilityMap score={sampleCapabilityMapVGCCCScore} />}
                                    />
                                )}

                                {/*Social Futures*/}
                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-profile-social-futures-report-demo"
                                        element={
                                            <CapabilityProfile
                                                score={sampleCapabilityProfileSocialFuturesScore}
                                                hideScoreNumbers
                                            />
                                        }
                                    />
                                )}

                                {(process.env.REACT_APP_ENV === "NPD" ||
                                    process.env.REACT_APP_ENV === "DEMO" ||
                                    process.env.REACT_APP_ENV === "UAT") && (
                                    <Route
                                        path="/capability-map-social-futures-report-demo"
                                        element={<CapabilityMap score={sampleCapabilityMapVGCCCScore} />}
                                    />
                                )}

                                <Route path="/printable-ace-survey-report" element={<PrintableSurvey />} />
                                <Route path="/coachees-pagination-test" element={<CoacheesPaginationTest />} />
                                <Route path="/something-has-gone-wrong" element={<SomethingHasGoneWrong />} />
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/logout"
                                    element={<RedirectToCP redirectType={REDIRECT_TYPES.LOG_OUT} />}
                                />
                                <Route path="/auth/:accessKey" element={<Auth />} />
                                <Route path="/session-expired" element={<SessionExpired />} />
                                <Route path="/no-permission" element={<NoPermission />} />
                                <Route
                                    path="/renew"
                                    element={<RedirectToCP redirectType={REDIRECT_TYPES.RENEW_SESSION} />}
                                />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        );
                    }
                })()}

                {context.loggedIn === false && (
                    <Routes>
                        <Route path="/auth/:accessKey" element={<Auth />} />
                        <Route path="/session-expired" element={<SessionExpired />} />
                        <Route path="*" element={<RedirectToCP redirectType={REDIRECT_TYPES.RENEW_SESSION} />} />
                    </Routes>
                )}

                {(() => {
                    if ((context.loggedIn === true && context.brandingLoading === true) || context.loggedIn == null) {
                        return (
                            <Routes>
                                <Route path="/session-expired" element={<SessionExpired />} />
                                <Route path="*" element={<DashboardPlaceholder />} />
                            </Routes>
                        );
                    }
                })()}
            </Router>
        </div>
    );
}

export default App;
