import { useEffect, useState } from "react";
import { UserIcon } from "@heroicons/react/24/solid";

import IndividualFieldManagerSatisfaction from "./IndividualFieldManagerSatisfaction";
import MultiSelect from "../../../../../../../SharedComponents/Form/MultiSelect";

export default function IndividualFieldManagerSelectSection(props) {
    const { data, displayOnlyContent } = props;

    const [selectableFieldManagerNames, setSelectableFieldManagerNames] = useState([]);
    const [chosenFieldManagerName, setChosenFieldManagerName] = useState([]);

    useEffect(() => {
        if (data.length !== 0) {
            const sanitisedFieldManagerNames = data.map((fieldManager) => ({
                value: fieldManager.name,
                label: fieldManager.name,
            }));

            setChosenFieldManagerName(sanitisedFieldManagerNames[0].label);
            setSelectableFieldManagerNames(sanitisedFieldManagerNames);
        }
    }, [data]);

    return (
        <div className="flex flex-col gap-6 print:gap-8">
            <MultiSelect
                items={selectableFieldManagerNames}
                value={chosenFieldManagerName}
                labelText={"Field manager"}
                setChosenItems={setChosenFieldManagerName}
                icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                backgroundColour="#ffff"
                extraStyling="print:hidden"
            />
            <div className="flex flex-col print:gap-4">
                {data.map((fieldManager, index) => (
                    <div key={index} className="print:break-inside-avoid-page">
                        {displayOnlyContent ? (
                            <IndividualFieldManagerSatisfaction
                                data={fieldManager}
                                displayOnlyContent={displayOnlyContent}
                            />
                        ) : (
                            chosenFieldManagerName === fieldManager.name && (
                                <IndividualFieldManagerSatisfaction
                                    data={fieldManager}
                                    displayOnlyContent={displayOnlyContent}
                                />
                            )
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
