import * as React from "react";
import { motion } from "framer-motion";

const Path = (props) => <motion.path strokeWidth="3" strokeLinecap="round" {...props} />;

export const MenuToggle = ({ toggle }) => (
    <button onClick={toggle} className="block absolute top-6 z-50">
        <svg width="23" height="23" viewBox="0 0 23 23">
            <Path
                className="stroke-primary-600"
                variants={{
                    closed: { d: "M 2 2.5 L 20 2.5" },
                    open: { d: "M 3 16.5 L 17 2.5" },
                }}
            />
            <Path
                className="stroke-primary-600"
                d="M 2 9.423 L 20 9.423"
                variants={{
                    closed: { opacity: 1 },
                    open: { opacity: 0 },
                }}
                transition={{ duration: 0.1 }}
            />
            <Path
                className="stroke-primary-600"
                variants={{
                    closed: { d: "M 2 16.346 L 20 16.346" },
                    open: { d: "M 3 2.5 L 17 16.346" },
                }}
            />
        </svg>
    </button>
);
