export const CustomBottomAxisBars = (props) => {
    const { hideScoreNumbers, score, centeredLabels } = props;

    return (
        <div className={"flex flex-col gap-1.5 " + (hideScoreNumbers ? "mr-0" : "mr-14")}>
            <div className="flex flex-row w-full">
                <div
                    className={"w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5"}
                />
                <div
                    className={"w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5"}
                />
                <div
                    className={"w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5"}
                />
                <div className={"w-25-percentage border-slate-400 border-dashed border-2 border-t-0 h-3.5"} />
            </div>
            <div
                className={
                    "flex flex-row w-full font-title text-sm text-slate-400 font-medium " +
                    (centeredLabels ? "text-center" : "text-right")
                }
            >
                <div className="w-25-percentage">{score.reportLevelNames ? score.reportLevelNames[0] : "Entry"}</div>
                <div className="w-25-percentage">
                    <span className="-ml-2">{score.reportLevelNames ? score.reportLevelNames[1] : "Foundation"}</span>
                </div>
                <div className="w-25-percentage">
                    <span className="-ml-2">{score.reportLevelNames ? score.reportLevelNames[2] : "Advanced"}</span>
                </div>
                <div className="w-25-percentage">
                    <span className="-ml-2">{score.reportLevelNames ? score.reportLevelNames[3] : "Mastery"}</span>
                </div>
            </div>
        </div>
    );
};
