import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CheckIcon, InformationCircleIcon, XMarkIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";

import AlertError from "../Errors/AlertError";

function Alert(props) {
    const { active, close, text, type, className, children, noMargins } = props;

    const [activeState, setActiveState] = useState();

    const getStyle = () => {
        switch (type) {
            case ALERT_TYPE.ERROR:
                return "bg-red-50 text-red-700 shadow-sm border border-red-100 border-1";
            case ALERT_TYPE.WARNING:
                return "bg-amber-50 text-amber-700 shadow-sm border border-amber-100 border-1";
            case ALERT_TYPE.SUCCESS:
                return "bg-green-50 text-green-700 shadow-sm border border-green-100 border-1";
            case ALERT_TYPE.INFO:
                return "bg-blue-50 text-blue-700 shadow-sm border border-blue-100 border-1";
            case ALERT_TYPE.PRIMARY:
                return "bg-primary-50 text-primary-700 shadow-sm border border-primary-100 border-1";
            default:
                return "bg-red-100 text-red-700";
        }
    };

    useEffect(() => {
        if (active) {
            setActiveState(true);

            if (close && type !== ALERT_TYPE.ERROR) {
                const timeId = setTimeout(() => {
                    // After 3 seconds set the show value to false
                    setActiveState(false);
                    close();
                }, 10000);

                return () => {
                    clearTimeout(timeId);
                };
            }
        }
    }, [active, close, type]);

    return (
        <AnimatePresence>
            {active && activeState && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <div
                        className={
                            "rounded-xl p-3 shadow-sm " +
                            getStyle() +
                            (className == null ? " w-full" : " " + className) +
                            " " +
                            (noMargins ? "m-0" : "my-3")
                        }
                    >
                        <div className="flex gap-4 text-sm justify-between">
                            <div className="flex flex-row items-center gap-2 w-full">
                                <button type="button" className="focus:outline-none place-self-start">
                                    {type === ALERT_TYPE.SUCCESS ? (
                                        <CheckIcon className="h-4 w-4 text-green-600 stroke-current stroke-1" />
                                    ) : type === ALERT_TYPE.ERROR ? (
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-600" />
                                    ) : type === ALERT_TYPE.INFO ? (
                                        <InformationCircleIcon className="h-5 w-5 text-blue-600" />
                                    ) : type === ALERT_TYPE.WARNING ? (
                                        <InformationCircleIcon className="h-5 w-5 text-amber-600" />
                                    ) : type === ALERT_TYPE.PRIMARY ? (
                                        <InformationCircleIcon className="h-5 w-5 text-primary-600" />
                                    ) : (
                                        <InformationCircleIcon className="h-5 w-5 text-blue-600" />
                                    )}
                                </button>
                                {type === ALERT_TYPE.SUCCESS ? (
                                    text
                                ) : type === ALERT_TYPE.ERROR ? (
                                    text === "" ? (
                                        <span>
                                            Sorry, there's been an error. Please refresh the page and{" "}
                                            <a
                                                className={"text-slate-600 font-semibold underline"}
                                                href="mailto:techsupport@psychpress.com.au"
                                            >
                                                <span>contact us</span>
                                            </a>{" "}
                                            if this error persists.
                                        </span>
                                    ) : (
                                        <AlertError errorMessage={text} />
                                    )
                                ) : (
                                    text
                                )}
                            </div>
                            {close && (
                                <button type="button" className="focus:outline-none place-self-start" onClick={close}>
                                    <XMarkIcon className="h-4 w-4 stroke-current stroke-1" />
                                </button>
                            )}
                        </div>
                        {children}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default Alert;

export const ALERT_TYPE = { SUCCESS: 1, ERROR: 2, WARNING: 3, INFO: 4, PRIMARY: 5 };
