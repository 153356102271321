import { useNavigate } from "react-router-dom";
import { ArrowUturnLeftIcon, ArrowUpTrayIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../utils/constants";

import Button from "../../SharedComponents/Button";

export default function TabbedNavigationContentSection(props) {
    const {
        children,
        title,
        icon,
        buttonText,
        onSaveChanges,
        loading,
        readonly,
        headerElements,
        handleShowNavigationConfirmation,
    } = props;

    const navigate = useNavigate();

    return (
        <div className={"flex justify-between flex-col"}>
            <div className={"p-0 md:p-4"}>
                <div className="flex justify-between items-center">
                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">{title}</h2>
                    {icon && icon}
                    {headerElements && headerElements}
                </div>
                <div
                    onClick={handleShowNavigationConfirmation && (() => handleShowNavigationConfirmation(true))}
                    className={title ? "my-4" : "my-0"}
                >
                    {children}
                </div>
            </div>
            {!readonly && (
                <div
                    className={
                        "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end " +
                        "text-right rounded-b-xl w-full"
                    }
                >
                    <div className="flex flex-row gap-3">
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                            onClick={() => navigate(-1)}
                        />
                        {onSaveChanges && (
                            <Button
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                text={buttonText ? buttonText : "Save changes"}
                                icon={<ArrowUpTrayIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                                iconPositionLeft
                                onClick={() => {
                                    onSaveChanges();
                                    window.scrollTo({ top: 0, behavior: "smooth" });
                                }}
                                loading={loading}
                                disabled={!onSaveChanges}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
