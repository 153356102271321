import caseyLogo from "../../assets/city-of-casey-logo.png";

export const sampleCapabilityProfileCaseyScore = {
    organisationLogo: caseyLogo,
    reportName: "Capability Profile",
    reportLevelNames: ["Support & Action", "Guide", "Execute & Improve", "Shape"],
    reportDescription:
        "We want you to have a great career, and to grow and develop so that you are ready for the future. This is why we have Future Ready. It is your guide to developing the capabilities that are essential for all roles at Telstra. The capabilities that make us great at our roles today and ready for what comes tomorrow. As a result of completing the Future Ready Insights Questionnaire, you have received this Future Ready Insights Report. It contains rich feedback and insight to support your development and career growth.",
    snapshot: [
        "Every individual has a different combination of Human capabilities, it's what makes you you! Comparing yourself assessment and mindset will help to explain why some activities you find easy and enjoyable while others are more challenging and require more effort and focus to complete. ",
    ],
    understandingYourCapabilityProfile: {
        description: [
            "City of Casey aspires to enhance the experience of its staff and communities by fostering an environment where people thrive, create deeper connections with each other, and find meaning in their work. This aligns with our vision of becoming a more connected, bold, and resilient community.",
            "Human Capabilities serve as markers of a person’s enduring attributes. Based on 30 years of research, we understand that these attributes make each of us unique. Your Capability Profile helps you understand your current capabilities and mindset in relation to the City of Casey's Employee Capability Framework, providing rich insights to support your development and alignment with the Casey's vision.",
            'There is no "ideal" profile; the results are intended to offer deeper understanding and insights, allowing you to harness your innate strengths and potential. This helps you understand what you can achieve today and what could be possible for you in the future.',
            "By design, the Capability Profile does not consider your experience, training, qualifications, and other environmental factors that may impact your development and career. In a work context, your profile results should be considered alongside other available and relevant information.",
        ],
    },
    harnessingYourResults: {
        title: "Harnessing your results",
        description: [
            "In addition to offering a deeper understanding of your capabilities, your report provides tangible actions and developmental suggestions to activate your skills as part of your day-to-day activities.",
        ],
    },
    capabilityLevels: {
        title: "The Capability Levels",
        tableHeaders: ["Level", "With learning and experience you have the ability to:"],
        tableRows: [
            {
                title: "Support & Action",
                points: [
                    "Understand and explain to others, rules, procedures, and the theory behind why something is important",
                    "Plan work so that you can successfully complete what you have been asked to do",
                    "Exercise personal responsibility",
                    "Work with others to find ways to overcome challenges.",
                ],
            },
            {
                title: "Guide",
                points: [
                    "Understand and advise others how to improve existing rules and processes",
                    "Take accountability for meeting planned objectives",
                    "Work in a flexible manner, collaborating with others when appropriate, to identify ways to overcome barriers to success.",
                ],
            },
            {
                title: "Execute & Improve",
                points: [
                    "Work beyond your own area of expertise to extend existing rules, procedures and theoretical knowledge",
                    "Hold accountability for outcomes that have a wider impact than your immediate sphere of influence, for example for the entire organisation or in the community",
                    "Operate in a highly effective way, in both familiar situations as well as those that are new, complex, or challenging.",
                ],
            },
            {
                title: "Shape",
                points: [
                    "Make significant contribution to challenge or advance how existing rules and processes will meet future needs; Have defined accountability for outcomes that have long-term impact in the organisation or community",
                    "Use your knowledge to help people make future planning decisions ",
                    "Use your experience and deep understanding to help others navigate unfamiliar and challenging situations. ",
                ],
            },
        ],
    },
    capabilities: [
        {
            name: "Strategic Direction & Purpose",
            version: "1",
            description: [
                "Share a compelling vision and strategic purpose for a future state that inspires and influences commitment to deliver the outcomes required now and into the  future",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "The City of Casey requires its people to be resourceful and to translate plans into action.",
                "The City of Casey supports its people to initiate action, coordinate effort, and generate commitment from others to the organisation’s strategic direction and purpose.",
                "Strategic Direction and Purpose requires not only personal commitment and initiative, it also necessitates the ability to overcome problems that affect goal attainment or orientation of the workforce to agreed outcomes. It centres on translating plans that deliver an agreed direction into action.",
                "The new Outcomes operating model focusses on outcome ownership and accountability, and the performance management and measurement responsibilities and processes that support them.",
                "Outcome ownership is a significant responsibility, requiring oversight and, possibly, control over resources to ensure they are aligned to outcomes-based roles.",
                "At all levels, evidence of Strategic Direction and Purpose must confirm the ability to think strategically and maintain a constant focus on the future direction. Strategic Direction and Purpose involves providing inspiration, clarity and direction through inspiring commitment to a meaningful vision. This includes focusing teams on priorities, supporting change processes, formulating plans, and attaining organisational goals",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Accountable",
                    strategies: "",
                    band_description: "",
                    percentile: 98,
                },
                {
                    name: "Drive",
                    strategies: "",
                    band_description: "",
                    percentile: 34,
                },
                {
                    name: "Execution",
                    strategies: "",
                    band_description: "",
                    percentile: 45,
                },
                {
                    name: "Purposeful",
                    strategies: "",
                    band_description: "",
                    percentile: 45,
                },
            ],
            selfAssessmentScore: 87,
            mindsetScore: 56,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Shape level. This means you believe you have the skills to: ",
                points: [
                    "Sponsors the construction and review of the long-term vision, values and purpose of the organisation",
                    "Confirms and reports organisational progress against strategic targets",
                    "Tests the alignment of organisational wide plans and change initiatives against vision, values and the long-term purpose",
                    "Draws accurate conclusions from strategic data and other information to ensure the organisation is positioned to meet future challenges",
                    "Assists communicates the organisation’s vision to the wider sector and national decision makers",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Strategic Direction & Purpose mindset tend to:",
                points: [
                    "Have a good capacity to research and source quality strategic business intelligence",
                    "Be respected by peers and decision makers in other organisations as a conscientiousness and hard-working leader",
                    "Take a thorough approach to tasks even when faced with multiple competing demands",
                    "Be able to renegotiate outcomes or requirements that impinge upon quality/standard of the final result",
                    "Persevere when others quit ",
                    "Be a strategic thinker ",
                    "Secure executive level sponsorships and engagement ",
                    "Redesign business practices/processes to deliver improved results",
                    "Have a strong work ethic and responsiveness to new demands",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Take the lead on comprehensive organisational-wide projects to ensure continual alignment with the vision and values.",
                            "Collaborate with experts in your industry to gain a holistic perspective and develop strategic foresight. Participate in cross-disciplinary activities aimed at sharing insights and knowledge.",
                            "Take charge of initiatives that impact various departments and functions to promote the organisation's vision on a broader scale.",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in mentoring relationships with strategic visionaries who have successfully communicated an organisation's vision to national decision makers. Seek structured feedback on your ability to convey the organisation's vision to a broader audience.",
                            "Participate in coaching sessions that focus on strategic foresight and thought leadership to enhance your skills in drawing accurate conclusions from strategic data.",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
        {
            name: "Adaptive Mindset",
            description: [
                "Able to adjust to change and maintain their curiosity while dealing with disruption, pressure and setbacks quickly in a resilient and positive manner",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "Mindsets are the deep assumptions and mental attitudes that drive behaviour. As such, mindsets are not just a way of thinking. They shape a person’s:",
                "• Emotional disposition (feelings and moods);",
                "• Cognitive processes (beliefs and attitudes); and",
                "• Behavioural response (actions).",
                "People with adaptive mindsets understand the need for continuous improvement and change; they know that success – personal and collective – relies on working under pressure, developing ways to cope with stress and anxiety, and continually learn from their experience. This means every experience contributes to action for people, teams, and the organisation.",
                "At the heart of developing an adaptive mindset is self-awareness, curiosity, persistence and ‘grit’ so that City of Casey people can keep going when things get tough or uncertain.",
                "Adopting a growth mindset means we not only cope better but actively look for opportunities for learning and growth (Dweck, 2017). People with a growth mindset see failures and challenges as momentary setbacks and opportunities for personal growth, whereas people with a fixed mindset view failure as a demonstration of the limits to their knowledge and ability.",
                "The Adaptive Mindset is a capability that looks forward to the needs of the future workforce and constantly changing environment. Rather than just taking a traditional focus on what a person does, it examines how they think. It seeks to confirm a person’s ability to maintain their curiosity while dealing with disruption, pressure and setbacks in a resilient, positive manner. It is very much about the neurology of how we think and balance mental models that embrace both fixed and growth mindsets. This reinforces the need for workers to not only learn explicit technical knowledge, but also be an innovator where their abilities, intelligence, and talents are developed through experience and informal interactions that may lead to new and better solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Flexibility",
                    strategies: "",
                    band_description: "Flexible, growth-oriented and planful",
                    percentile: 60,
                },
                {
                    name: "Growth Focus",
                    strategies: "",
                    band_description: "Goal-oriented, self-confident and determined",
                    percentile: 43,
                },
                {
                    name: "Positive Outlook",
                    strategies: "",
                    band_description: "Resilient, tough-minded and determined",
                    percentile: 96,
                },
            ],
            selfAssessmentScore: 78,
            mindsetScore: 67,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Shape level. This means you believe you have the skills to: ",
                points: [
                    "Plays a stabilizing influence in even the most challenging situations",
                    "Possesses the sophisticated self-awareness necessary to make appropriate evaluative judgments about people, events, organizations, and processes",
                    "Enhances business or community capacity to adjust to new perspectives or ideas",
                    "Persists and overcomes obstacles to reach ambitious long-term personal and professional goals",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with Adaptive Mindset tend to: ",
                points: [
                    "Be a systems thinker ",
                    "Be able to engage with others to exchange ideas, information or thoughts",
                    "Deal well with ambiguity and complexity",
                    "Embrace challenges and new situations",
                    "Not procrastinate",
                    "Actively seek new experiences and learning opportunities",
                    "Envision what is possible and seek strategic opportunities",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Take the lead on transformative change processes and projects at the organisational level, focussing on inspiring others to embrace change.",
                            "Implement a program within your organisation focused on adaptive leadership and resilience. ",
                            "Engage in research projects or think-tank teams focusing on innovative technological advancements and emerging practices.",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek executive-level coaching from a seasoned leader who has successfully led transformative change initiatives at the organisational level.",
                            "Join a peer coaching network of seasoned leaders to exchange insights, best practices, and strategies for overcoming obstacles and inspiring teams to embrace change.",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
        {
            name: "Collaboration",
            version: "1",
            description: [
                "Able to work collaboratively with all types of people, contribute to teamwork and to build relationships and networks across a range of people groups ",
                "Focus on developing this capability! You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level. ",
            ],
            longerDescription: [
                "Collaboration encompasses the interaction between the members within a team or across teams or informal groups. The individuals involved may have diverse values, purposes, locations, or cultures but agree to work together to achieve a shared goal or produce a mutually beneficial output.",
                "Collaboration increasingly involves the use of technology to link people and organisations. Powered by an array of video, chat, smart and mobile devices, group messaging apps, or secure cloud-based file sharing, project management and other collaborative applications, everyone can contribute in shared spaces.",
                "Collaboration in practice will involve several broad requirements:",
                "• Working with people",
                "• Respecting different opinions and motivations & balancing diverse perspectives and beliefs before initiating one’s own judgments and decisions",
                "• Seeking, considering, and responding positively to differences in priorities, opinions, perspectives, values, or beliefs",
                "• Valuing and recognising the contribution each person can make",
                "• Actively participating in the collaborative activity",
                "• Influencing others to strengthen the group’s collaborative outcomes",
                "• Making decisions that consider multiple individual contributions",
                "• Aligning one’s own actions, contributions, and priorities to those set by the group.",
                "Achieving the capability requires a significant amount of context-based experience. This experience can be gained while working alone or when collaborating with others who are not part of a team structure. Hey may be part of an informal relationship or, as is increasingly the case in virtual environments, join a network solely based on their mutual interests.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Cooperation",
                    strategies: "",
                    band_description: "Cooperative, collaborative, thoughtful, considerate, and open-minded ",
                    percentile: 90,
                },
                {
                    name: "Considerate",
                    strategies: "",
                    band_description: "",
                    percentile: 56,
                },
                {
                    name: "Respect",
                    strategies: "",
                    band_description: "",
                    percentile: 43,
                },
            ],
            selfAssessmentScore: 67,
            mindsetScore: 98,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Execute & Improve. This means you believe you have the skills to: ",
                points: [
                    "Recognises and addresses issues and problems that impact on goal attainment or cooperation",
                    "Forges consensus and cooperation across groups to enhance goal attainment",
                    "Influences partner’s support for plans or decisions",
                    "Corrects destructive behaviours or workplace systems that affect collaborative and inclusive practices",
                    "Influences and gains commitment from senior decision-makers and partners",
                    "Nurtures high-level relationships through the open exchange of ideas and information",
                    "Manages behaviours or practices that fail to align with Council or community expectations",
                    "Forms collaborative relationships able to generate new ideas, intelligence or perspectives on strategic challenges",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Collaboration mindset tend to:",
                points: [
                    "Be interested in people",
                    "Have a natural ability to engage with others and influence their thinking and practice",
                    "Appreciate and seek the opinions and viewpoints of others",
                    "Encourage others to present their views and feedback",
                    "Listen and seek to understand what other people mean",
                    "Craft communication to the needs of the audience ",
                    "Feel confident dealing with complex issues or topics ",
                    "Be an engaging public speaker",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Take on roles where building purposeful, cross-functional collaborative relationships is essential for enhancing the organisation's strategic capacity and intelligence. ",
                            "Lead initiatives involving strong influencing and negotiation with senior/ Executive/ Board decision-makers and stakeholders. ",
                            "Lead and participate in projects and cross-functional teams focused on generating innovative ideas and diverse perspectives on strategic challenges. ",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Establish a peer coaching group to share advanced insights, provide constructive feedback, and collectively enhance your strategic collaboration abilities.",
                            "Seek mentorship from a colleague known for their excellence in strategic collaboration, forming inclusive relationships, adapting communication to diverse audiences, or mastering difficult negotiations.",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
        {
            name: "Customer Focus",
            version: "1",
            description: [
                "Able to empathise with our customers and communities, understand their needs and use informed decision making to design and deliver service excellence",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "At the City of Casey we are committed to connecting with and servicing our customers and community using an empathetic and solutions-focused approach. Our customers include our community and all those outside our community who engage or transact with the City of Casey. This is also true of our internal teams who in turn support our internal customers that ultimately contribute to the external customer’s experience.",
                "Empathy and Customer Focus is not limited to a service exchange. It encompasses the entire service lifecycle, or customer journey. It requires everyone at the City of Casey to develop a mindset whereby they appreciate not only how to interact with customers but understand why customer needs and expectations underpin the success of any business processes.",
                "The Customer Focus capability, by its very nature, involves active awareness of a customer’s needs. It requires a person to place themselves in the customer’s shoes. If you took the customer’s point of view, what would you want? How do we open a dialogue with customers to ensure we know what they want or where their expectations need to be shaped? What does a satisfied or dissatisfied customer look like? More importantly, how do we anticipate customer needs and continually argue for improving what we do in ways that make customers come back?",
                "Building the Customer Focus capability is essential as we transition to a future-ready workforce. We want to go beyond the knowledge of how to interact with a customer, or how to use simple tools to identify and measure customer experience; it is about a willingness to build a culture where the customer is central to how we do business. It is about individuals who are passionate advocates for the customer",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Service Orientation",
                    strategies: "",
                    band_description: "",
                    percentile: 68,
                },
                {
                    name: "Solutions Orientation",
                    strategies: "",
                    band_description: "",
                    percentile: 85,
                },
                {
                    name: "Support Orientation",
                    strategies: "",
                    band_description: "",
                    percentile: 46,
                },
            ],
            selfAssessmentScore: 89,
            mindsetScore: 78,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Shape level. This means you believe you have the skills to: ",
                points: [
                    "Holds senior leaders accountable for improving the customer experience",
                    "Incorporates balanced strategic targets for long-term customer relationship and value",
                    "Seeks out and shares international research into customer experience trends and future developments",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Customer Focus mindset tend to:",
                points: [
                    "Have a strong and persistent focus on people/ customer",
                    "Always seek to put the customer’s interests first",
                    "Look for opportunities to help people",
                    "Care about others and satisfying their needs",
                    "Be friendly and easy to approach ",
                    "Be personally pleased and happy when a person’s needs are satisfied",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Take on leadership roles where you are accountable for improving the customer experience across the organisation. ",
                            "Initiate strategic actions and lead organisational transformative efforts to improve the customer journey and experience.",
                            "Rotate through roles that require customer-centric leadership and advocacy. ",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Engage in coaching with seasoned customer experience leader to help you explore how to think strategically, initiate actions to improve the customer experience, and design systems for enduring customer value.",
                            "Act as a mentor to aspiring leaders within your organisation, guiding them on how to hold others accountable for improving the customer experience and cultivating a culture that reinforces customer-centric values.",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
        {
            name: "Ethics & Integrity",
            version: "1",
            description: [
                "Able to act with integrity and in conformance with social and professional standards of ethical conduct",
                "This is an area of strength for you. It is likely that you enjoy work and learning in this area and that this capability now comes naturally to you. Consider how you can continue to develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [
                "In an increasingly global world of information, work, and social interaction, it is vitally important for people in work, learning, or life to identify and encourage diverse perspectives, to respect different cultures and values, and to act responsibly and ethically.",
                "In local government acting with integrity is a fundamental requirement to engender public trust.",
                "All too often, we use ethics interchangeably with morals and values, but they are different. Morals are standards of behaviour society adheres to regarding social norms and customs. Morals change from generation to generation, from society to society, and from culture to culture. What is today’s morality might be obsolete tomorrow. Ethics, on the other hand, concerns those values beyond norms and customs that pervade human existence. Ethics are the essence of human dignity and underpin what is necessary to uphold it.",
                "Ethics in local government shape the organisation’s sense of moral duty and its moral conduct in the interest of engendering and maintaining public trust. While individuals may have their own set of ethical beliefs, as an employee they are typically asked to align their sense of morality and values to the standards of behaviour their employer sets in terms of how they behave, act, interact with others, and perform within a business context. Conformance with ethical standards, governance standards and regulatory requirements is also expected when joining the City of Casey.",
                "The Ethics and Integrity standard centres on an individual’s ability to act with integrity and in conformance with social, regulatory and professional standards of ethical conduct. It is about who you are, how you behave, and an awareness of how your decisions affect others.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Integrity",
                    strategies: "",
                    band_description: "",
                    percentile: 56,
                },
                {
                    name: "Morality",
                    strategies: "",
                    band_description: "",
                    percentile: 78,
                },
                {
                    name: "Responsibility",
                    strategies: "",
                    band_description: "Responsible, reliable, dependable, right-minded, and principled",
                    percentile: 76,
                },
            ],
            selfAssessmentScore: 90,
            mindsetScore: 88,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Shape. This means you believe you have the skills to: ",
                points: [
                    "Promotes effective organisational governance and compliance management systems",
                    "Seeks and actively responds to external review of organisational governance and compliance management audits",
                    "Promotes compatible ethical practices and professional standards across the industry and with strategic partners",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Ethics & Integrity mindset tend to:",
                points: [
                    "Act according to their own ethics and conscience",
                    "Make commitments and stick to them",
                    "Be willing to follow behavioural rules and social expectations",
                    "Follow the rules and agreed standards of practice or behaviour",
                    "Seek to learn from mistakes of others and how they deal with ethical dilemmas",
                    "Conscientiously adhere to personal commitments or loyalty",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Take a lead role in promoting effective organisational governance and compliance management, actively identifying and addressing gaps and areas for improvement.",
                            "Seek out and respond to external reviews of organisational governance and compliance management audits, ensuring full compliance with external standards and regulations.",
                            "Promote compatible ethical practices and professional standards across the industry and with strategic partners, collaborating with other organisations to set industry-wide ethical benchmarks.",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor emerging leaders and compliance professionals on advanced governance and compliance strategies and best practices.",
                            "Establish a mentoring program within your organisation to help employees develop their ethical leadership skills and maintain industry-leading ethical practices.",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
        {
            name: "Shared Leadership",
            version: "1",
            description: [
                "Shared leadership allows us to harness our collective capability, helping us be higher performing, create greater customer value, sense of achievement and make our community proud.",
                "Match! Your results indicate that you are applying your innate abilities and can work in a state of 'flow - it is likely that you enjoy work and learning in this area. Consider how you can stretch yourself further and develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [
                "City of Casey has defined shared leadership as “Everyone does the thinking. Everyone takes the initiative. Leaders coach and empower”. This means that leadership is a shared responsibility, and decision-making is appropriately delegated, within an accountability framework, to the people who are closest to the work.",
                "Shared Leadership at Casey is needed as we transform the organisation to better adapt to a continuously evolving external environment.",
                "Shared Leadership better enables the collective achievement of strategic goals and outcomes through a distributed model of leadership, within an environment that fosters autonomy via clarity of delegation, empowering others to act, and establishing clear accountabilities, considering appropriate risks.",
                "Shared leadership engages employees to not only perform better but to invest in work that continues to build their emotional wellbeing in an environment of consistent change. This capability deliberately moves away from a low trust and individualistic model of leadership to reinforce a high trust and collective model where leadership is a shared process and capability that can be enabled in all people, not just leaders.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Determination",
                    strategies: "",
                    band_description: "Determined, persevering, self-confident, steadfast, and cool-headed ",
                    percentile: 78,
                },
                {
                    name: "Influential",
                    strategies: "",
                    band_description: "",
                    percentile: 89,
                },
                {
                    name: "Leadership",
                    strategies: "",
                    band_description: "",
                    percentile: 67,
                },
            ],
            selfAssessmentScore: 66,
            mindsetScore: 56,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Execute & Improve level. This means you believe you have the skills to: ",
                points: [
                    "Delegates and fosters autonomy in others to achieve agreed outcomes.",
                    "Pro-actively seeks opportunities to develop other people’s talent and career opportunities.",
                    "Acknowledges and rewards contributions from others.",
                    "Searches for ways to continually reinforce a sense of collective purpose and achievement.",
                    "Keeps commitments and deals honestly with stakeholders and external business partners.",
                    "Can accurately identify and consider the historical, cultural, community and political elements impacting teams.",
                    "Mobilises support for initiatives that have a long-term impact on strategy and direction.",
                    "Establishes the systems and processes to identify potential and manage talent.",
                    "Represents the organisation with sensitivity and a heightened awareness of global trends and perspectives.",
                    "Motivates and inspires by conveying a compelling vision of what is possible.",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Shared Leadership mindset tend to:",
                points: [
                    "Show the ability to look over the horizon and identify compelling futures",
                    "Be highly self-aware",
                    "Have a strong career plan",
                    "Appreciate trends or major factors shaping technology, markets and strategic direction",
                    "Have a strong ability to inspire others",
                    "Show a high level of motivation towards achieving future goals",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Motivate and inspire others through a shared vision that has deep meaning and resonates with the organisation's mission and values.",
                            "Harness the full capability of your team to deliver on strategic goals and objectives, optimising their talents and resources.",
                            "Lead with a focus on long-term value creation and shareholder growth, prioritising sustainability over short-term gains.",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Mentor emerging leaders and senior executives on advanced leadership strategies for shaping strategy, driving growth, and building a shared vision.",
                            "Establish a peer coaching network of senior leaders to exchange insights and experiences in driving long-term value and sustainable growth.",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
        {
            name: "Improve & Innovate",
            version: "1",
            description: [
                "Enhance an organisation’s capacity to respond to strategic challenges and seeking new opportunities for innovation. This includes maintaining a curious mindset, suspending criticism, testing assumptions & taking calculated risks",
                "This is an area of strength for you. It is likely that you enjoy work and learning in this area and that this capability now comes naturally to you. Consider how you can continue to develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [
                "Improve and Innovate is the capacity to not only apply tools and techniques that stimulate innovation; it is also a mental model whereby the person proactively examines problems or situations from a fresh perspective. This may lead to unconventional solutions or new insights that can be exploited to improve current thinking or practice.",
                "Improve and Innovate is not only centred on the practical improvement to existing technology, processes, or products. It may involve the reshaping of practices and thinking. It could focus on simplifying and adapting what exists to better improve the customer experience and outcomes for communities.",
                "The ability to adapt and think as an innovator underpins the continual search for improvements. This is where Improve and Innovate reinforces not only being agile but embraces the ability to innovate and create improved processes and products.",
                "The person obtaining the Improve and Innovate capability may not necessarily be the most creative thinker. But they can challenge current thinking and practice. They can work with others to champion innovative ideas or adaptive solutions. They can embrace new ways of thinking, listen well, imagine, and envisage opportunities, provide the energy to explore new ideas, and display entrepreneurial behaviours that others admire. This could include taking calculated risks and failing fast and learning from it.",
                "At all levels of proficiency, evidence of Improve and Innovate must confirm the ability to work in an agile manner and make connections between disparate ideas, thinking, or practices. It necessitates the ability to move beyond technical knowledge to actively engage the intellect and emotions to see or to create new opportunities, products, or solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Curiosity",
                    strategies: "",
                    band_description: "Curious, inquisitive, investigative, scrutinising, and fascinated",
                    percentile: 74,
                },
                {
                    name: "Exploration",
                    strategies: "",
                    band_description: "Exploratory, innovative, inventive, adventurous, and far-sighted",
                    percentile: 76,
                },
            ],
            selfAssessmentScore: 77,
            mindsetScore: 78,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Shape level. This means you believe you have the skills to: ",
                points: [
                    "Takes a strategic, end-to-end view of where and how the organisation creates value",
                    "Monitors the external environment to diagnose where external factors will impact major innovations or strategies",
                    "Encourages and leads the search for disruptive innovations and business models that enhance organisational success",
                    "Sponsors effort to seize opportunities to adapt, improve, or innovate",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Improve & Innovate mindset tend to: ",
                points: [
                    "Seek to challenge traditional ideas or thinking",
                    "See things in a new or different perspective",
                    "Imagine and envisage opportunities",
                    "Take the initiative and energy to explore new ideas",
                    "Passionately explore what is possible",
                    "Show the ability to look over the horizon for him/herself and the industry",
                    "Appreciate trends or major factors impacting the organisation ",
                    "Deal with ambiguity and complexity ",
                    "Continuously seek improvement without prompting",
                    "Self-believe and motivate ",
                    "Acknowledge and reward ideas and effort of others",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Take the lead in designing and implementing innovative solutions that revolve around customer needs or that require a strategic, end-to-end view of customer value. ",
                            "Lead cross-functional teams to create innovative strategies that enhance the overall customer experience.",
                            "Act as a change champion within your organisation. Mobilise support by role-modelling enthusiasm for innovative and improvement initiatives.",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek a mentor who excels in designing innovative solutions around customer needs. ",
                            "Engage in executive-level coaching explore your own mindset and skills in responding to challenges, risks and opportunities – this will better enable you to authentically mobilise support and create enthusiasm for change and innovation. ",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
        {
            name: "Build High Performing Teams",
            version: "1",
            description: [
                "Build high performing teams and reinforce a culture of engagement underpinning the strategic purpose and values of the organisation. This includes coaching others, building trust and empowering individuals to act",
                "Match! Your results indicate that you are applying your innate abilities and can work in a state of 'flow - it is likely that you enjoy work and learning in this area. Consider how you can stretch yourself further and develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [
                "The City of Casey will focus on engaging, empowering and motivating team members to achieve high performance in a given cultural context. This requires understanding derived through positive interactions with people from diverse cultural backgrounds and perspectives, especially with regard for differences in attitudes, beliefs, and values.",
                "At all levels of proficiency, attaining the Engagement and Coaching capability requires evidence a person is able to engage with others in culturally diverse settings with sensitivity and regard for a range of different perspectives, values, beliefs, and behaviours.",
                "Attaining the Engagement and Coaching capability may involve the formation of relationships and interactions with peers to assist transfer of knowledge and skills through coaching and mentoring activities.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Empowerment",
                    strategies: "",
                    band_description: "Empowering, inspirational, supportive, constructive, and candid",
                    percentile: 75,
                },
                {
                    name: "Inclusive",
                    strategies: "",
                    band_description: "Inclusive, non-discriminatory, egalitarian, fair-minded, and unprejudiced",
                    percentile: 34,
                },
            ],
            selfAssessmentScore: 45,
            mindsetScore: 34,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Guide level. This means you believe you have the skills to: ",
                points: [
                    "Delegates and empowers other people to achieve agreed outcomes",
                    "Addresses the individual motivations and drivers that may cause conflict or prevent team members working towards agreed goals",
                    "Coaches others to address barriers to action that may derive from an individual’s lack of competence or motivation",
                    "Analyses and assesses when to undertake coaching or mentoring for each individual/situation",
                    "Assigns the authority and resources necessary for employees to be empowered to act",
                    "Acknowledges and rewards contributions from others",
                    "Facilitates individual development planning and prioritisation",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Build High Performing Teams mindset tend to:",
                points: [
                    "Employ a range of different influencing styles effectively",
                    "Have strong negotiation and influencing skills",
                    "Embrace learning",
                    "Have a strong ability to empathise",
                    "Be an effective role model",
                    "Have a strong ability to appreciate and communicate sensitive to emotional and cultural factors",
                    "Appreciate and celebrates everyone’s contribution",
                    "Be an astute navigator of organisational politics",
                    "Be willing to coach and mentor others",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Work-based learning or Experience:",
                        points: [
                            "Lead diverse project teams, leveraging the strengths of each team member for high performance.",
                            "Be a visible role model for reducing personal bias and promoting allyship and inclusivity in the workplace.",
                            "Take the lead in forming and nurturing cross-functional teams, allocating and clarifying roles and responsibilities.",
                        ],
                    },
                    {
                        description: "Coaching, Mentoring or Structured Feedback:",
                        points: [
                            "Seek opportunities to mentor and coach junior team members to help them achieve their maximum potential.",
                            "Engage in a feedback processes to identify and address any personal or hidden bias in your own leadership style.",
                        ],
                    },
                    {
                        description: "Education and Training:",
                        points: ["You will be participating in the Development Pathways program for this capability"],
                    },
                ],
            },
        },
    ],
};
