import { useState } from "react";
import { ChatBubbleBottomCenterTextIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";
import Tippy from "@tippyjs/react";

import { BASIC_ANIMATION_VARIANTS, BUTTON_VARIANT_TYPES } from "../../../../utils/constants";

import Button from "../../../SharedComponents/Button";

export default function PracticeInterviewFeedbackRow(props) {
    const { item: recording, lastItem } = props;

    const [showReviews, setShowReviews] = useState(false);

    const handleShow = () => {
        setShowReviews((showReviews) => !showReviews);
    };

    return (
        <tr>
            <td className={"px-4 py-3 " + (lastItem && "rounded-bl-xl")}>
                <div className="text-sm text-primary-600 font-medium">{recording.question}</div>
            </td>

            <td className={"px-4 py-3 relative w-36 " + (lastItem && "rounded-br-xl")}>
                <div className="flex">
                    {!recording?.reviews.find((review) => review.feedback !== null) || recording?.reviews.length < 1 ? (
                        <Tippy
                            content={"No feedback found"}
                            className={"py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 text-white"}
                            arrow={false}
                            animation={"shift-away-subtle"}
                            placement={"left"}
                            hideOnClick={false}
                        >
                            <div>
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                    icon={<ChatBubbleBottomCenterTextIcon className="h-4 w-4" />}
                                    extraStyling={
                                        "text-xs h-8 w-8 rounded-full p-2 flex items-center justify-center hover"
                                    }
                                    onClick={() => handleShow()}
                                    disabled={
                                        !recording?.reviews.find((review) => review.feedback !== null) ||
                                        recording?.reviews.length < 1
                                    }
                                    iconPositionLeft
                                />
                            </div>
                        </Tippy>
                    ) : (
                        <Button
                            colour={BUTTON_VARIANT_TYPES.SECONDARY}
                            icon={<ChatBubbleBottomCenterTextIcon className="h-4 w-4" />}
                            extraStyling={"text-xs h-8 w-8 rounded-full p-2 flex items-center justify-center hover"}
                            onClick={() => handleShow()}
                            disabled={
                                !recording?.reviews.find((review) => review.feedback !== null) ||
                                recording?.reviews.length < 1
                            }
                            iconPositionLeft
                        />
                    )}
                </div>

                <AnimatePresence>
                    {showReviews && (
                        <>
                            <motion.div
                                initial={"inactive"}
                                animate={["active", { zIndex: 30 }]}
                                exit={"inactive"}
                                transition={{ duration: 0.4 }}
                                variants={BASIC_ANIMATION_VARIANTS}
                                className={
                                    "flex flex-col gap-4 absolute top-3 right-36 p-4 rounded-xl " +
                                    "bg-slate-100 shadow-2xl w-[600px]"
                                }
                            >
                                <div className="flex justify-between items-center gap-8">
                                    <h3 className="font-medium text-primary-600 tracking-tight font-title">
                                        "{recording.question}"
                                    </h3>
                                    <button
                                        type="button"
                                        className="focus:outline-none place-self-start"
                                        onClick={() => handleShow()}
                                    >
                                        <XMarkIcon className="h-5 w-5 text-slate-500 hover:text-slate-700 stroke-current stroke-1" />
                                    </button>
                                </div>
                                <h3 className="font-medium text-secondary-600 tracking-tight font-title">Feedback:</h3>
                                <div className="flex flex-wrap flex-row gap-2 max-h-96 overflow-y-scroll pr-2">
                                    {recording.reviews.map(
                                        (review, index) =>
                                            review.feedback && (
                                                <div className="flex flex-col gap-0 w-full" key={index}>
                                                    <div className="flex flex-col gap-2 px-2 py-2.5 bg-slate-50 rounded-xl">
                                                        <div className="flex flex-row justify-between">
                                                            <div className="flex flex-row items-center gap-1">
                                                                <div className="text-sm font-medium text-primary-600">
                                                                    {review.firstName} {review.lastName}
                                                                </div>
                                                                <div className="text-xs text-primary-600 px-2 py-1 rounded-full bg-primary-50 ">
                                                                    {review.emailAddress}
                                                                </div>
                                                            </div>
                                                            <div className="text-xs font-medium text-secondary-600">
                                                                {new Date(review.date).toLocaleString("en-AU", {
                                                                    dateStyle: "short",
                                                                    timeStyle: "short",
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-sm text-slate-500">{review.feedback}</div>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </div>
                                <div className="flex justify-end">
                                    <Button
                                        text="Close"
                                        onClick={() => handleShow()}
                                        colour={BUTTON_VARIANT_TYPES.GRAY_DARKER}
                                    />
                                </div>
                            </motion.div>

                            <div onClick={() => handleShow()} className="opacity-20 fixed inset-0 z-20 bg-black" />
                        </>
                    )}
                </AnimatePresence>
            </td>
        </tr>
    );
}
