import Tag from "../../../../SharedComponents/Tag";

export default function TrainingRow(props) {
    const { item: trainingModule, updateItemStatus } = props;

    return (
        <tr>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex flex-row gap-4 items-center">
                    <div className="text-sm text-slate-600 font-medium">{trainingModule.trainingModuleName}</div>
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex flex-row gap-4 items-center">
                    <div className="text-sm text-slate-500">{trainingModule.trainingModuleProvider}</div>
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <Tag status={trainingModule.status} />
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"flex flex-row gap-3 items-center"}>
                    <select
                        id="default"
                        name="default"
                        className={
                            "bg-slate-100 rounded-xl shadow-sm pl-3 pr-8 py-2 text-left " +
                            "focus:outline-none focus:ring-2 focus:ring-slate-200 focus:border-transparent " +
                            "sm:text-sm text-slate-500 border border-slate-100"
                        }
                        defaultValue={trainingModule.status}
                        onChange={(e) => updateItemStatus(trainingModule.identifier, e.target.value)}
                    >
                        <option value={"Not Started"}>Not started</option>
                        <option value={"Started"}>Started</option>
                        <option value={"Completed"}>Completed</option>
                    </select>
                </div>
            </td>
        </tr>
    );
}
