import { useState, useEffect, useContext } from "react";
import {
    PlusIcon,
    PresentationChartLineIcon,
    CalendarIcon,
    ClockIcon,
    VideoCameraIcon,
    MapPinIcon,
    ArrowUturnLeftIcon,
    UserIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

import { COACH_PLATFORM_PERMISSIONS, BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import { createSession, getOrganisationUsersLite } from "../../../../utils/api";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import Input from "../../../SharedComponents/Form/Input";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import Button from "../../../SharedComponents/Button";
import RadioButton from "../../../SharedComponents/Form/RadioButton";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";

export default function CreateSession() {
    const [alert, setAlert] = useState("");
    const [sessionType, setSessionType] = useState("onsite");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [organisationUsers, setOrganisationUsers] = useState();
    const [sessionName, setSessionName] = useState("");
    const [sessionDateTime, setSessionDateTime] = useState("");
    const [sessionDuration, setSessionDuration] = useState("");
    const [sessionLink, setSessionLink] = useState(null);
    const [sessionLocation, setSessionLocation] = useState(null);
    const [chosenUsers, setChosenUsers] = useState([]);

    const navigate = useNavigate();

    const context = useContext(Context);

    const handleCreateSession = async () => {
        setSubmitLoading(true);

        if (sessionName === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session name.");
            setSubmitLoading(false);
            return;
        }

        if (sessionType === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please select a session type.");
            setSubmitLoading(false);
            return;
        }

        if (sessionDateTime === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session date time.");
            setSubmitLoading(false);
            return;
        }

        if (sessionDuration < 1 || sessionDuration === "") {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session duration.");
            setSubmitLoading(false);
            return;
        }

        if (sessionType === "onsite" && !sessionLocation) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session location.");
            setSubmitLoading(false);
            return;
        }

        if (sessionType === "remote" && !sessionLink) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session link.");
            setSubmitLoading(false);
            return;
        }

        if (
            (sessionType === "remote" || sessionType === "hybrid") &&
            !document.getElementById("sessionLink").checkValidity()
        ) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Session link is not valid.");
            setSubmitLoading(false);
            return;
        }

        if (sessionType === "hybrid" && (!sessionLink || !sessionLocation)) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter session location and session link.");
            setSubmitLoading(false);
            return;
        }

        if (chosenUsers.length < 2) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Please enter at least 2 participants.");
            setSubmitLoading(false);
            return;
        }

        try {
            const participantsUserIdentifierList = [];

            chosenUsers.map((user) => participantsUserIdentifierList.push(user.value));

            await createSession(
                sessionName,
                sessionDateTime,
                sessionDuration,
                sessionLink,
                sessionLocation,
                participantsUserIdentifierList
            );

            context.fetchSessions();

            navigate("/sessions", {
                state: {
                    alertType: ALERT_TYPE.SUCCESS,
                    alertMessage: 'Successfully created session "' + sessionName + '"',
                },
            });
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }

        setSubmitLoading(false);
    };

    const resetRadioFields = (sessionType) => {
        if (sessionType === "remote") {
            setSessionLink("");
            setSessionLocation(null);
        }
        if (sessionType === "onsite") {
            setSessionLink(null);
            setSessionLocation("");
        }
        if (sessionType === "hybrid") {
            setSessionLink("");
            setSessionLocation("");
        }
    };

    useEffect(() => {
        const fetchOrganisationUsers = async () => {
            setLoading(true);
            try {
                const response = await getOrganisationUsersLite(false);
                const sanitizedResponse = response.map((user) => ({
                    value: user.userIdentifier,
                    label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")",
                }));
                setOrganisationUsers(sanitizedResponse);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoading(false);
        };
        fetchOrganisationUsers();
    }, []);

    useEffect(() => {
        if (Date.now() >= new Date(sessionDateTime)) {
            setAlertType(ALERT_TYPE.WARNING);
            setAlert("Note: you're creating a past event session.");
        } else {
            setAlert(null);
        }
    }, [sessionDateTime]);

    return (
        <DashboardLayout title={"Create Session"} requiredPermissions={[COACH_PLATFORM_PERMISSIONS.CREATE_SESSION]}>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div
                        className={
                            "grid grid-flow-row xl:grid-cols-2 gap-6 p-4 border border-slate-100 border-1 rounded-t-xl"
                        }
                    >
                        <div className={"flex flex-col gap-3 col-span-2 lg:col-span-1"}>
                            <Input
                                id="sessionName"
                                type="text"
                                label="Session name"
                                placeholder={"Session name"}
                                icon={<PresentationChartLineIcon className="h-4 w-4 text-slate-500" />}
                                onChange={setSessionName}
                            />
                        </div>
                        <div className={"flex flex-col gap-3"}>
                            <div className="flex justify-center">
                                <div className="flex flex-col gap-2 w-full">
                                    <div className={"flex justify-between w-full"}>
                                        <label className="text-sm font-medium text-primary-600 w-full ">
                                            Session type
                                        </label>
                                    </div>
                                    <fieldset>
                                        <div
                                            className="mt-2 flex flex-row gap-6"
                                            onChange={(event) => {
                                                resetRadioFields(event.target.value);
                                                setSessionType(event.target.value);
                                            }}
                                        >
                                            <RadioButton
                                                id="onsite"
                                                name="sessionType"
                                                value="onsite"
                                                labelText="Onsite"
                                                defaultChecked
                                            />
                                            <RadioButton
                                                id="remote"
                                                name="sessionType"
                                                value="remote"
                                                labelText="Remote"
                                            />
                                            <RadioButton
                                                id="hybrid"
                                                name="sessionType"
                                                value="hybrid"
                                                labelText="Hybrid"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className={"flex gap-3 col-span-2 lg:col-span-1"}>
                            <Input
                                id="sessionDateTime"
                                type="datetime-local"
                                label="Session date time"
                                placeholder={"Date time"}
                                icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                                onChange={setSessionDateTime}
                            />
                        </div>
                        <div className={"flex gap-3 col-span-2 lg:col-span-1"}>
                            <Input
                                id="sessionDuration"
                                type="number"
                                label="Session duration in minutes"
                                placeholder={"Session duration (minutes)"}
                                icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                                onChange={setSessionDuration}
                            />
                        </div>
                        {sessionType === "remote" && (
                            <div className={"flex gap-3 col-span-2"}>
                                <Input
                                    id="sessionLink"
                                    type="url"
                                    label="Session link"
                                    placeholder={"Session link"}
                                    icon={<VideoCameraIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setSessionLink}
                                />
                            </div>
                        )}
                        {sessionType === "onsite" && (
                            <div className={"flex gap-3 col-span-2"}>
                                <Input
                                    id="sessionLocation"
                                    type="text"
                                    label="Session location"
                                    placeholder={"Session location"}
                                    icon={<MapPinIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setSessionLocation}
                                />
                            </div>
                        )}
                        {sessionType === "hybrid" && (
                            <>
                                <div className={"flex gap-3 col-span-2 lg:col-span-1"}>
                                    <Input
                                        id="sessionLink"
                                        type="url"
                                        label="Session link"
                                        placeholder={"Session link"}
                                        icon={<VideoCameraIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setSessionLink}
                                    />
                                </div>
                                <div className={"flex gap-3 col-span-2 lg:col-span-1"}>
                                    <Input
                                        id="sessionLocation"
                                        type="text"
                                        label="Session location"
                                        placeholder={"Session location"}
                                        icon={<MapPinIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setSessionLocation}
                                    />
                                </div>
                            </>
                        )}
                        <div className={"col-span-2 flex flex-col gap-3"}>
                            <MultiSelect
                                isMulti
                                items={organisationUsers}
                                labelText={"Session participants"}
                                loading={loading}
                                setChosenItems={setChosenUsers}
                                icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 text-right rounded-b-xl w-full"
                        }
                    >
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                            onClick={() => navigate("/sessions")}
                        />
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Create session"
                            icon={<PlusIcon className="block h-3.5 w-3.5  stroke-current stroke-1 mr-2" />}
                            iconPositionLeft
                            onClick={handleCreateSession}
                            loading={submitLoading}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
