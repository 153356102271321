import { useContext } from "react";

import { COACH_PLATFORM_PERMISSIONS } from "../../../../../../../utils/constants";
import { Context } from "../../../../../../../context/Context";

import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import BouncingBalls from "../../../../../../SharedComponents/Loading/BouncingBalls";
import AceReportPicker from "./AceReportPicker";

export default function ExecutiveSummary() {
    const context = useContext(Context);

    return (
        <DashboardLayout
            title="Executive summary"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <div className="flex flex-col gap-3">
                    <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">Key areas</h2>
                    <div dangerouslySetInnerHTML={{ __html: context.aceSurveyReportData.executiveSummary }} />
                </div>
            )}
        </DashboardLayout>
    );
}
