import { useContext } from "react";
import { motion } from "framer-motion";

import { BASIC_ANIMATION_VARIANTS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import AppCard from "./AppCard";
import CardsPlaceholder from "../../../SharedComponents/Loading/CardsPlaceholder";

function Apps() {
    const context = useContext(Context);

    return (
        <DashboardLayout title={"Apps"} requiredPermissions={[]}>
            {context.applicationsLoading ? (
                <CardsPlaceholder active={context.applicationsLoading} />
            ) : context?.userApplications && context?.userApplications.length > 0 ? (
                <motion.div
                    initial={"inactive"}
                    animate={"active"}
                    exit={"inactive"}
                    transition={{ duration: 0.4 }}
                    variants={BASIC_ANIMATION_VARIANTS}
                >
                    <div
                        className={
                            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 " +
                            "2xl:grid-cols-4 gap-6"
                        }
                    >
                        {context.userApplications.map((app, i) => (
                            <AppCard
                                key={i}
                                name={app.name}
                                description={app.description}
                                colour={app.themeColour}
                                logoUrl={app.logoUrl}
                                launchUrl={app.launchUrl}
                                isStatic={app.isStatic}
                            />
                        ))}
                    </div>
                </motion.div>
            ) : (
                <motion.div
                    initial={"inactive"}
                    animate={"active"}
                    exit={"inactive"}
                    transition={{ duration: 0.4 }}
                    variants={BASIC_ANIMATION_VARIANTS}
                >
                    <div className="text-sm text-slate-500">
                        No registered apps! Contact us at{" "}
                        <a
                            className="text-right underline font-medium text-primary-600 hover:text-primary-700"
                            href="mailto:techsupport@psychpress.com.au?subject=What application can I use?"
                        >
                            techsupport@psychpress.com.au
                        </a>{" "}
                        or call us at{" "}
                        <a className="link text-primary-600 hover:text-primary-700" href="tel:+61396700590">
                            +61 (0)3 9670 0590
                        </a>{" "}
                        to see how our apps can help you.
                    </div>
                </motion.div>
            )}
        </DashboardLayout>
    );
}

export default Apps;
