import { Transition } from "@headlessui/react";

export default function BasicTransitionHeadlessUi(props) {
    const { children, show } = props;

    return (
        <Transition
            show={show}
            enter="transition-opacity duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-400"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {children}
        </Transition>
    );
}
