import { useState } from "react";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { BASIC_ANIMATION_VARIANTS } from "../../../../utils/constants";
import { ReactComponent as CircleIcon } from "../../../../assets/circle.svg";

export default function PickableAssessmentCard(props) {
    const { name } = props;

    const [selected, setSelected] = useState(false);

    return (
        <div
            className={
                "shadow-slate-200 shadow-sm rounded-lg grid grid-flow-row bg-white transition cursor-pointer " +
                (selected && "shadow-slate-200 shadow-lg bg-green-25")
            }
            onClick={() => setSelected((selected) => !selected)}
        >
            <div className="p-4 flex flex-row items-center justify-between gap-8">
                <div className={"flex flex-col justify-between gap-2"}>
                    <div
                        className={
                            "text-slate-500 text-sm font-medium transition-colors " +
                            "transition-filter " +
                            (selected ? "grayscale-0" : "grayscale")
                        }
                    >
                        {name}
                    </div>
                </div>
                {selected && (
                    <motion.div
                        initial={"inactive"}
                        animate={"active"}
                        exit={"inactive"}
                        transition={{ duration: 0.6 }}
                        variants={BASIC_ANIMATION_VARIANTS}
                    >
                        <CheckCircleIcon className={"block h-6 w-6 text-green-600"} />
                    </motion.div>
                )}

                {!selected && (
                    <motion.div
                        initial={"inactive"}
                        animate={"active"}
                        exit={"inactive"}
                        transition={{ duration: 0.4 }}
                        variants={BASIC_ANIMATION_VARIANTS}
                    >
                        <CircleIcon className={"block h-6 w-6 text-slate-300"} />
                    </motion.div>
                )}
            </div>
        </div>
    );
}
