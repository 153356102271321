import { useContext } from "react";
import { Context } from "../../context/Context";

function ErrorPage(props) {
    const { title, subtitle, buttons, illustration } = props;

    const context = useContext(Context);

    return (
        <div>
            <div className={"fixed top-0 w-full"}>
                <div className="flex items-center p-6 bg-transparent">
                    <img alt="logo" className="h-9" src={context.getImage("shortLogo")} />
                </div>
            </div>
            <div
                className={
                    "mx-auto h-screen p-8 bg-gradient-to-l from-primary-50 via-slate-50 to-secondary-50 " +
                    "grid grid-flow-row lg:items-center lg:justify-center lg:grid-cols-2 lg:grid-flow-col"
                }
            >
                <div className={"flex items-center justify-center"}>
                    <div className={"2xl:w-3/5 xl:w-8/12 lg:w-9/12 md:1/2 sm:w-3/4 w-4/5"}>{illustration}</div>
                </div>
                <div className={"flex flex-col gap-4 text-center lg:text-left"}>
                    <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">{title}</h1>
                    <div className={"text-sm md:text-base text-slate-500"}>{subtitle}</div>
                    <div className={"flex flex-row justify-center lg:justify-start gap-4"}>{buttons}</div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
