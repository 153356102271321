import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { ArrowDownTrayIcon, DocumentMagnifyingGlassIcon } from "@heroicons/react/24/solid";
import mime from "mime";

import { downloadLatestReportForAssessment } from "../../utils/api";
import { COACH_PLATFORM_PERMISSIONS, BUTTON_VARIANT_TYPES } from "../../utils/constants";
import { Context } from "../../context/Context";

import Tag from "./Tag";
import Button from "./Button";

export default function AssessmentItem(props) {
    const {
        name,
        status,
        documentIdentifier,
        fileType,
        userName,
        setAlert,
        isAssessmentCompletedOnBehalf,
        isCompleted,
        lastItem,
    } = props;

    const [downloading, setDownloading] = useState(false);

    const context = useContext(Context);

    const navigate = useNavigate();

    const handleDownload = async (documentIdentifier) => {
        setDownloading(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DOWNLOAD_REPORTS)) {
            setAlert("You do not have the necessary permission to download a report");
            return;
        }

        const documentResponse = await downloadLatestReportForAssessment(documentIdentifier);

        const { fileType } = documentResponse;

        if (!fileType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            return;
        }

        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            return;
        }

        const blob = await (await fetch(`data:${mimeType};base64,${documentResponse.content}`)).blob();

        if (!blob) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            return;
        }

        const downloadLink = document.createElement("a");

        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = documentResponse.fileName.split(".").join("_");
        downloadLink.click();

        setDownloading(false);
    };

    return (
        <tr className="transition-colors flex w-full items-center">
            <td className={"p-3 w-1/3 whitespace-nowrap" + (lastItem && " rounded-bl-xl")}>
                <div className="text-slate-500 text-xs font-medium whitespace-pre-wrap">{name}</div>
            </td>
            <td className="p-3 w-1/3 whitespace-nowrap">
                <div className="flex gap-3">
                    <Tag status={status} />
                    {isAssessmentCompletedOnBehalf && isCompleted ? (
                        <Tag
                            status={`${context.terminology.coach.singular} completion`}
                            textColour={"text-white bg-secondary-500 "}
                        />
                    ) : isAssessmentCompletedOnBehalf && !isCompleted ? (
                        <Tag
                            status={`${context.terminology.coach.singular} set-up`}
                            textColour={"text-secondary-600 bg-secondary-100 "}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </td>
            <td className={"p-3 w-1/3 whitespace-nowrap" + (lastItem && " rounded-br-xl")}>
                {(() => {
                    if (documentIdentifier === null) {
                        return (
                            <div className="flex flex-col gap-1 text-slate-400 italic text-xs whitespace-pre-wrap tracking-tight">
                                Report unavailable
                            </div>
                        );
                    } else {
                        if (fileType === "JSON" || fileType === "json") {
                            return (
                                <Button
                                    text={"View"}
                                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                    icon={<DocumentMagnifyingGlassIcon className="block h-3 w-3 mr-1" />}
                                    extraStyling={"text-xs text-white"}
                                    iconPositionLeft
                                    smallButton
                                    onClick={() =>
                                        navigate("/view-report", {
                                            state: {
                                                userName: userName,
                                                reportName: name,
                                                reportIdentifier: documentIdentifier,
                                            },
                                        })
                                    }
                                    loadingIconSize={"h-3.5 w-3.5 mr-1"}
                                />
                            );
                        } else {
                            return (
                                <Button
                                    text={"Download"}
                                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                    icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3 w-3 mr-1" />}
                                    extraStyling={"text-xs text-white"}
                                    iconPositionLeft
                                    smallButton
                                    loadingIconSize={"h-3.5 w-3.5 mr-1"}
                                    onClick={() => handleDownload(documentIdentifier)}
                                    loading={downloading}
                                />
                            );
                        }
                    }
                })()}
            </td>
        </tr>
    );
}
