import React from "react";
import { ExclamationTriangleIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../utils/constants";

import Button from "./Button";
import Modal from "./Modal";

const DeleteConfirmation = (props) => {
    const { showDeleteConfirmation, setShowDeleteConfirmation, text, handleDelete, loading } = props;

    return (
        <Modal
            active={showDeleteConfirmation}
            content={
                <div className="flex flex-col gap-4">
                    <div
                        className={
                            "border-0 rounded-xl relative flex flex-col gap-6 w-full bg-white outline-none " +
                            "focus:outline-none"
                        }
                    >
                        <div className="flex flex-col gap-3 pt-5 px-5 rounded-t-xl">
                            <div className="flex justify-between items-center gap-8">
                                <div className="flex flex-row items-center gap-2">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-primary-700" />
                                    <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                        Delete confirmation
                                    </h2>
                                </div>
                                <button
                                    type="button"
                                    className="focus:outline-none place-self-start"
                                    onClick={() => setShowDeleteConfirmation(false)}
                                >
                                    <XMarkIcon className="h-5 w-5 text-slate-500 hover:text-slate-700 stroke-current stroke-1" />
                                </button>
                            </div>
                            <p className="text-slate-500 text-sm">
                                {`Are you sure you want to delete this ${text}? This process cannot be undone.`}
                            </p>
                        </div>
                        <div
                            className={
                                "flex items-center gap-3 justify-end px-5 py-4 rounded-b-xl bg-gradient-to-l " +
                                "from-primary-50 to-primary-100"
                            }
                        >
                            <Button
                                text="Cancel"
                                onClick={() => setShowDeleteConfirmation(false)}
                                colour={BUTTON_VARIANT_TYPES.GRAY}
                                smallButton
                            />
                            <Button
                                colour={BUTTON_VARIANT_TYPES.RED}
                                text="Delete"
                                icon={<TrashIcon className="h-3 w-3 mr-1" />}
                                loadingIconSize="h-3 w-3 mr-1"
                                iconPositionLeft
                                onClick={handleDelete}
                                loading={loading}
                                disabled={loading}
                                smallButton
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default DeleteConfirmation;
