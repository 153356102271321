import React, { useState } from "react";
import { Bars3Icon, PencilIcon, PuzzlePieceIcon, ScaleIcon, TrashIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";

import Input from "../../../SharedComponents/Form/Input";
import Textarea from "../../../SharedComponents/Form/Textarea";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import Toggle from "../../../SharedComponents/Form/Toggle";
import Button from "../../../SharedComponents/Button";
import LikertScale from "./LikertScale";

export default function CustomQuestion(props) {
    const {
        customQuestion,
        index,
        availableQuestionTypes,
        handleCustomQuestion,
        handleDeleteCustomQuestion,
        selectableLikertScaleTypes,
    } = props;

    const [chosenLikertScaleType, setChosenLikertScaleType] = useState([]);
    const [chosenScaleItems, setChosenScaleItems] = useState([]);

    const handleScaleOptions = (event) => {
        setChosenLikertScaleType(event.value);

        setChosenScaleItems(() => {
            const chosenLikertScaleType = selectableLikertScaleTypes.find(
                (likertScaleType) => likertScaleType.label === event.label
            );
            return chosenLikertScaleType.scaleItems;
        });
    };

    const handleQuestionType = (event) => {
        // to reset the likert scale type if any previous value is set
        handleCustomQuestion("likertScaleType", "", index);

        handleCustomQuestion("questionType", event.value, index);
    };

    return (
        <div className={"flex justify-between flex-col col-span-2 gap-6"} key={index}>
            <div
                className={
                    "grid grid-flow-row gap-6 p-4 shadow-sm border border-slate-100 border-1 bg-slate-50 rounded-xl "
                }
            >
                <div className={"flex flex-col gap-3 col-span-2"}>
                    <Input
                        id="questionTitle"
                        type="text"
                        label="Question title"
                        placeholder={"Question title"}
                        icon={<PuzzlePieceIcon className="h-4 w-4 text-slate-500" />}
                        customOnChange={(e) => handleCustomQuestion("questionTitle", e.target.value, index)}
                        currentValue={customQuestion.questionTitle}
                        required={customQuestion.isRequired}
                        backgroundColour="bg-white"
                        valueOnly
                    />
                </div>

                <Textarea
                    id="questionDescription"
                    type="textarea"
                    label="Question description"
                    extraStyling="col-span-2"
                    placeholder={"Question description"}
                    icon={<Bars3Icon className="h-4 w-4 text-slate-500" />}
                    customOnChange={(e) => handleCustomQuestion("questionDescription", e.target.value, index)}
                    value={customQuestion.questionDescription}
                    backgroundColour="bg-white"
                    optional
                />

                {customQuestion.questionType === "text" && (
                    <Textarea
                        id="customText"
                        type="textarea"
                        extraStyling="col-span-2"
                        placeholder={"Custom text input answer"}
                        backgroundColour="bg-slate-200"
                        disabled
                    />
                )}

                {customQuestion.questionType === "likertScale" && (
                    <div className="col-span-2 flex flex-col gap-3">
                        <MultiSelect
                            items={selectableLikertScaleTypes}
                            labelText={"Likert scale type"}
                            loading={false}
                            customOnChange={(event) => {
                                handleScaleOptions(event);
                                handleCustomQuestion("likertScaleType", event.value, index);
                            }}
                            value={chosenLikertScaleType}
                            backgroundColour="#FFFFFF"
                        />
                        {chosenLikertScaleType && chosenLikertScaleType.length > 0 && (
                            <div className="bg-slate-200 p-2 rounded-xl flex flex-row items-center">
                                <LikertScale scaleOptions={chosenScaleItems} setScaleOptions={setChosenScaleItems} />
                            </div>
                        )}
                    </div>
                )}

                <div className={"flex flex-row flex-wrap gap-6 col-span-2 items-center justify-between"}>
                    <div className="basis-full lg:basis-1/2">
                        <MultiSelect
                            items={availableQuestionTypes}
                            labelText={"Question type"}
                            loading={false}
                            customOnChange={(event) => handleQuestionType(event)}
                            value={customQuestion.questionType}
                            backgroundColour="#FFFFFF"
                            icon={
                                customQuestion.questionType === "text" ? (
                                    <PencilIcon className="h-4 w-4 text-slate-500" />
                                ) : (
                                    <ScaleIcon className="h-4 w-4 text-slate-500" />
                                )
                            }
                        />
                    </div>

                    <div className="flex flex-row items-center gap-5 self-end pb-1">
                        <Toggle
                            text={"Reverse scoring"}
                            onChange={(e) => handleCustomQuestion("isReversed", e.target.checked, index)}
                            triggered={customQuestion.isReversed}
                            backgroundColour="bg-secondary-500"
                            small
                        />

                        <Toggle
                            text={"Required"}
                            onChange={(e) => handleCustomQuestion("isRequired", e.target.checked, index)}
                            triggered={customQuestion.isRequired}
                            small
                        />

                        <Button
                            colour={BUTTON_VARIANT_TYPES.RED}
                            text="Delete question"
                            icon={<TrashIcon className="h-3 w-3 mr-1" />}
                            loadingIconSize="h-3 w-3 mr-1"
                            iconPositionLeft
                            onClick={() => handleDeleteCustomQuestion(customQuestion)}
                            disabled={false}
                            smallButton
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
