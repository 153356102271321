export default function RadioButton(props) {
    const { labelText, value, id, name, defaultChecked, editableLabel, onBlur, disabled, checked } = props;

    return (
        <div className="flex items-center">
            <input
                id={id}
                name={name}
                type="radio"
                className={
                    "focus:outline-none focus:ring-0 focus:border-none focus:ring-transparent h-4 w-4 text-primary-600 border-slate-200 shadow-sm cursor-pointer"
                }
                value={value}
                defaultChecked={defaultChecked && defaultChecked}
                disabled={disabled}
                checked={checked}
            />
            <label
                htmlFor={id}
                className={
                    "ml-2.5 block text-sm font-medium focus:ring-0 focus:ring-transparent cursor-pointer " +
                    (checked ? "text-primary-600" : "text-slate-500")
                }
                contentEditable={!!editableLabel}
                onBlur={onBlur}
            >
                {labelText}
            </label>
        </div>
    );
}
