import { BriefcaseIcon, BuildingOfficeIcon, LinkIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";
import Button from "../../../../../SharedComponents/Button";
import Input from "../../../../../SharedComponents/Form/Input";

export default function AddRecommendedJob(props) {
    const { recommendedJobs, setRecommendedJobs, backgroundColour } = props;

    setRecommendedJobs(recommendedJobs);

    function handleEditRecommendedJobs(key, value, id) {
        const newRecommendedJob = recommendedJobs.find((recommendedJob) => recommendedJob.id === id);

        newRecommendedJob[key] = value;

        recommendedJobs.forEach(function (recommendedJob) {
            if (recommendedJob.id === id) {
                recommendedJob = newRecommendedJob;
            }
        });

        setRecommendedJobs([...recommendedJobs]);
    }

    const handleAddTextItem = () => {
        setRecommendedJobs([
            ...recommendedJobs,
            {
                name: "",
                company: "",
                link: "",
                id: (Math.random() + 1).toString(36).substring(7),
            },
        ]);
    };

    const handleDeleteTextItem = (id) => {
        setRecommendedJobs([...recommendedJobs.filter((recommendedJob) => recommendedJob.id !== id)]);
    };

    return (
        <div className="col-span-full rounded-xl">
            <div className={"grid grid-flow-row gap-4"}>
                {recommendedJobs.length === 0 ? (
                    <div className={"p-3.5 bg-white rounded-xl"}>
                        <div className={"text-slate-500 text-sm"}>No recommended job(s) has been added</div>
                    </div>
                ) : (
                    <div className="flex flex-col gap-4">
                        {recommendedJobs.map((recommendedJob, index) => (
                            <div
                                key={index}
                                className={
                                    "flex flex-col gap-2 p-3 rounded-xl " +
                                    (backgroundColour ? backgroundColour : "bg-slate-100")
                                }
                            >
                                <div className={"flex flex-col gap-2"} key={index}>
                                    <div className={"grid grid-flow-row lg:grid-cols-2 gap-6 "}>
                                        <Input
                                            id="recommendedJobName"
                                            type="text"
                                            label="Job name"
                                            placeholder="Recommended job"
                                            icon={<BriefcaseIcon className="h-4 w-4 text-slate-500" />}
                                            backgroundColour={"bg-white"}
                                            currentValue={recommendedJob.name}
                                            customOnChange={(e) =>
                                                handleEditRecommendedJobs("name", e.target.value, recommendedJob.id)
                                            }
                                            extraStyling="col-span-1"
                                            autoFocus
                                        />
                                        <Input
                                            id="company"
                                            type="text"
                                            label="Company"
                                            placeholder="Company"
                                            icon={<BuildingOfficeIcon className="h-4 w-4 text-slate-500" />}
                                            backgroundColour="bg-white"
                                            currentValue={recommendedJob.company}
                                            customOnChange={(e) =>
                                                handleEditRecommendedJobs("company", e.target.value, recommendedJob.id)
                                            }
                                            extraStyling="col-span-1"
                                        />
                                        <Input
                                            id="link"
                                            type="text"
                                            label="Link to job"
                                            placeholder="Link to job"
                                            icon={
                                                <LinkIcon className="h-4 w-4 text-slate-500 stroke-current stroke-1" />
                                            }
                                            backgroundColour="bg-white"
                                            currentValue={recommendedJob.link}
                                            customOnChange={(e) =>
                                                handleEditRecommendedJobs("link", e.target.value, recommendedJob.id)
                                            }
                                            extraStyling="col-span-full"
                                            textColour="text-primary-600"
                                        />
                                    </div>
                                    <Tippy
                                        content="Remove recommended job"
                                        className={
                                            "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                            "bg-red-600 text-red-50"
                                        }
                                        arrow={false}
                                        animation={"shift-away-subtle"}
                                        placement={"bottom-start"}
                                    >
                                        <TrashIcon
                                            onClick={() => handleDeleteTextItem(recommendedJob.id)}
                                            className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-700 transition-colors"
                                        />
                                    </Tippy>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={"w-full text-right col-span-full mt-4"}>
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    text="Add recommended job"
                    icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    smallButton
                    onClick={handleAddTextItem}
                />
            </div>
        </div>
    );
}
