import React, { useEffect, useState } from "react";
import { ResponsiveRadar } from "@nivo/radar";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";
import { Users } from "@phosphor-icons/react";

import {
    CAPABILITIES_BAR_CHART_PALETTE,
    CAPABILITY_MAP_HIGHLIGHTS_COLOURS,
} from "../../../../../../../utils/constants";

import Tag from "../../../../../../SharedComponents/Tag";
import LowMediumHighBubble from "../../../SharedChartComponents/LowMediumHighBubble";
import SausageLegendItem from "../../../Charts/CustomCharts/CustomLegends/SausageLegendItem";
import CategorisedVerticalBarChart from "../../../Charts/CustomCharts/CategorisedVerticalBarChart";
import MultiSelect from "../../../../../../SharedComponents/Form/MultiSelect";

export default function GroupContent(props) {
    const { group, reportLevelNames, printToPDFMode } = props;

    const [filteredRadarFields, setFilteredRadarFields] = useState([]);

    const [defaultFilteredRadarFields, setDefaultFilteredRadarFields] = useState([]);
    const [chosenCategoriesBigRadar, setChosenCategoriesBigRadar] = useState(["selfAssessmentScore", "mindsetScore"]);
    const [individualToCompare, setIndividualToCompare] = useState();

    const capabilities = JSON.parse(JSON.stringify(group.capabilityScores));

    useEffect(() => {
        let initialFilteredRadarFields = [];
        let initialDefaultFilteredRadarFields = [];

        capabilities.map((reportField) => {
            return initialFilteredRadarFields.push({
                name: reportField.name,
                selfAssessmentScore: reportField["selfAssessmentScore"],
                mindsetScore: reportField["mindsetScore"],
            });
        });

        group.capabilityScores.map((reportField) => {
            return initialDefaultFilteredRadarFields.push({
                name: reportField.name,
                selfAssessmentScore: reportField["selfAssessmentScore"],
                mindsetScore: reportField["mindsetScore"],
            });
        });

        if (individualToCompare) {
            initialFilteredRadarFields.map((field) => {
                individualToCompare.score.map((score) => {
                    if (field.name === score.name) {
                        field.individualToCompareSelfAssessmentScore = score.selfAssessmentScore;
                        field.individualToCompareMindsetScore = score.mindsetScore;
                    }
                });
            });

            initialDefaultFilteredRadarFields.map((field) => {
                individualToCompare.score.map((score) => {
                    if (field.name === score.name) {
                        field.individualToCompareSelfAssessmentScore = score.selfAssessmentScore;
                        field.individualToCompareMindsetScore = score.mindsetScore;
                    }
                });
            });

            const updatedChosenCategories = ["selfAssessmentScore", "mindsetScore"];

            updatedChosenCategories.push("individualToCompareSelfAssessmentScore");
            updatedChosenCategories.push("individualToCompareMindsetScore");

            setChosenCategoriesBigRadar(updatedChosenCategories);
        }

        setFilteredRadarFields([...initialFilteredRadarFields]);
        setDefaultFilteredRadarFields([...initialDefaultFilteredRadarFields]);
    }, [group.capabilityScores, individualToCompare]);

    function handleFilteredRadarFieldsChange(fieldName) {
        if (chosenCategoriesBigRadar.includes(fieldName)) {
            let currentFilteredRadarFields = [...filteredRadarFields];

            currentFilteredRadarFields.forEach((currentField) => {
                delete currentField[fieldName];
            });

            setFilteredRadarFields(currentFilteredRadarFields);
            setChosenCategoriesBigRadar(chosenCategoriesBigRadar.filter((category) => category !== fieldName));
        } else {
            let currentFilteredRadarFields = [...filteredRadarFields];
            let currentDefaultFilteredRadarFields = [...defaultFilteredRadarFields];

            currentDefaultFilteredRadarFields.forEach((defaultField) => {
                currentFilteredRadarFields.forEach((filteredRadarField) => {
                    if (defaultField.name === filteredRadarField.name) {
                        if (fieldName === "mindsetScore") {
                            filteredRadarField.mindsetScore = defaultField.mindsetScore;
                        } else if (fieldName === "selfAssessmentScore") {
                            filteredRadarField.selfAssessmentScore = defaultField.selfAssessmentScore;
                        } else if (fieldName === "individualToCompareSelfAssessmentScore") {
                            filteredRadarField.individualToCompareSelfAssessmentScore =
                                defaultField.individualToCompareSelfAssessmentScore;
                        } else {
                            filteredRadarField.individualToCompareMindsetScore =
                                defaultField.individualToCompareMindsetScore;
                        }
                    }
                });
            });

            setFilteredRadarFields(currentFilteredRadarFields);
            setChosenCategoriesBigRadar([...chosenCategoriesBigRadar, fieldName]);
        }
    }

    return (
        <div className={"flex flex-col gap-4 print:gap-8 break-before-page print:-mt-4"}>
            <div data-item="scrollback-content" className="text-primary-600 font-medium text-base hidden print:block">
                {group.name}
            </div>
            <div className={"flex flex-row gap-3 w-full"}>
                {group.highlights.map((highlight) => (
                    <div
                        className={
                            "flex flex-col gap-2 p-3 rounded-xl bg-white shadow-sm w-full border-2 " +
                            ("border-" + CAPABILITY_MAP_HIGHLIGHTS_COLOURS[highlight.highlightType].colour + "-200")
                        }
                    >
                        <div className={"flex flex-row gap-2 justify-between"}>
                            <div className={"flex flex-col gap-0"}>
                                <div className="text-slate-500 text-sm font-medium">{highlight.highlightType}</div>
                                <div
                                    className={
                                        "font-semibold text-lg tracking-tight " +
                                        ("text-" +
                                            CAPABILITY_MAP_HIGHLIGHTS_COLOURS[highlight.highlightType].colour +
                                            "-600")
                                    }
                                >
                                    {highlight.name}
                                </div>
                            </div>
                            {CAPABILITY_MAP_HIGHLIGHTS_COLOURS[highlight.highlightType].icon}
                        </div>
                        <div
                            className={
                                "font-bold text-2xl tracking-tighter " +
                                ("text-" + CAPABILITY_MAP_HIGHLIGHTS_COLOURS[highlight.highlightType].colour + "-600")
                            }
                        >
                            {highlight.percentScore}
                        </div>
                        <p className="text-slate-500 text-sm break-all w-full">{highlight.description}</p>
                    </div>
                ))}
            </div>
            <div
                className={
                    "flex flex-col gap-6 p-4 bg-white rounded-xl border-slate-100 shadow-sm w-full " +
                    "print:p-0 print:shadow-none print:border-none"
                }
            >
                <div data-item="scrollback-content" className="text-secondary-600 font-medium text-base">
                    Average Capability Distribution
                </div>
                <MultiSelect
                    items={group.individualScores.map((individual) => ({
                        label: individual.name,
                        value: individual,
                    }))}
                    labelText={"Choose an individual"}
                    setChosenItems={setIndividualToCompare}
                    icon={<Users className={"fill-slate-500 w-4 h-4"} weight="duotone" />}
                    hidden={printToPDFMode}
                />
                <div className={"flex flex-col print:block"}>
                    <div
                        className={
                            "flex flex-row justify-center items-center gap-2 print:ml-[250px] " +
                            "px-2 py-1 rounded-xl w-fit place-self-center"
                        }
                    >
                        <CursorArrowRaysIcon className="shrink-0 text-secondary-600 block h-6 w-6 print:hidden" />
                        <div className={"flex flex-row justify-center items-center gap-4"}>
                            <SausageLegendItem
                                label="Self-assessed Current Capability"
                                backgroundColour={
                                    chosenCategoriesBigRadar.includes("selfAssessmentScore")
                                        ? "bg-teal-500 hover:bg-teal-700 font-medium"
                                        : "bg-slate-400 hover:bg-slate-600 font-medium"
                                }
                                textColour={
                                    chosenCategoriesBigRadar.includes("selfAssessmentScore")
                                        ? "text-teal-700 hover:text-teal-950 font-medium"
                                        : "text-slate-600 hover:text-slate-800 font-medium"
                                }
                                large
                                clickable
                                onClick={() => handleFilteredRadarFieldsChange("selfAssessmentScore")}
                            />
                            <SausageLegendItem
                                label={"Mindset"}
                                backgroundColour={
                                    chosenCategoriesBigRadar.includes("mindsetScore")
                                        ? "bg-purple-500 hover:bg-purple-700 font-medium"
                                        : "bg-slate-400 hover:bg-slate-600 font-medium"
                                }
                                textColour={
                                    chosenCategoriesBigRadar.includes("mindsetScore")
                                        ? "text-purple-700 hover:text-purple-950 font-medium"
                                        : "text-slate-600 hover:text-slate-800 font-medium"
                                }
                                large
                                clickable
                                onClick={() => handleFilteredRadarFieldsChange("mindsetScore")}
                            />
                            {individualToCompare && (
                                <>
                                    <SausageLegendItem
                                        label={individualToCompare.name + " Self-assessed Current Capability"}
                                        backgroundColour={
                                            chosenCategoriesBigRadar.includes("individualToCompareSelfAssessmentScore")
                                                ? "bg-lime-500 hover:bg-lime-700 font-medium"
                                                : "bg-slate-400 hover:bg-slate-600 font-medium"
                                        }
                                        textColour={
                                            chosenCategoriesBigRadar.includes("individualToCompareSelfAssessmentScore")
                                                ? "text-lime-700 hover:text-lime-950 font-medium"
                                                : "text-slate-600 hover:text-slate-800 font-medium"
                                        }
                                        large
                                        clickable
                                        onClick={() =>
                                            handleFilteredRadarFieldsChange("individualToCompareSelfAssessmentScore")
                                        }
                                    />
                                    <SausageLegendItem
                                        label={individualToCompare.name + " Mindset"}
                                        backgroundColour={
                                            chosenCategoriesBigRadar.includes("individualToCompareMindsetScore")
                                                ? "bg-pink-500 hover:bg-pink-700 font-medium"
                                                : "bg-slate-400 hover:bg-slate-600 font-medium"
                                        }
                                        textColour={
                                            chosenCategoriesBigRadar.includes("individualToCompareMindsetScore")
                                                ? "text-pink-700 hover:text-pink-950 font-medium"
                                                : "text-slate-600 hover:text-slate-800 font-medium"
                                        }
                                        large
                                        clickable
                                        onClick={() =>
                                            handleFilteredRadarFieldsChange("individualToCompareMindsetScore")
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-center print:justify-start print:ml-[40px] -mt-10">
                        <div
                            id="capabilityMapRadar"
                            className={
                                "h-[350px] w-[250px] md:w-[550px] md:h-[450px] xl:w-[600px] xl:h-[500px] " +
                                "2xl:w-[700px] 2xl:h-[600px] print:w-[200px] print:h-[200px] font-medium"
                            }
                        >
                            <ResponsiveRadar
                                data={filteredRadarFields}
                                colors={["#0d9488", "#9333ea", "#84cc16", "#ec4899"]}
                                keys={[
                                    "selfAssessmentScore",
                                    "mindsetScore",
                                    "individualToCompareSelfAssessmentScore",
                                    "individualToCompareMindsetScore",
                                ]}
                                indexBy="name"
                                maxValue={100}
                                valueFormat=">-.2f"
                                margin={{ top: 0, right: 150, bottom: 0, left: 150 }}
                                gridLabelOffset={10}
                                gridShape="linear"
                                dotSize={4}
                                dotColor={{ theme: "background" }}
                                dotBorderWidth={2}
                                blendMode="multiply"
                                motionConfig="wobbly"
                                theme={{
                                    axis: {
                                        fontSize: "16px",
                                        tickColor: "#eee",
                                        ticks: {
                                            line: {
                                                stroke: "#555555",
                                            },
                                            text: {
                                                fill: "#64748b",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                fontFamily: "Inter",
                                                wordBreak: "break-all",
                                            },
                                        },
                                    },
                                }}
                                sliceTooltip={(data) => {
                                    return (
                                        <div
                                            className={
                                                "flex-col gap-6 space-y-2 rounded-xl p-2 border " +
                                                "border-slate-200 font-normal text-xs text-slate-500 " +
                                                "bg-white shadow-lg"
                                            }
                                        >
                                            <div
                                                className={
                                                    "text-sm font-medium flex flex-row justify-between " +
                                                    "gap-4 items-center text-secondary-600"
                                                }
                                            >
                                                {data.index}
                                            </div>
                                            <div className={"flex flex-col gap-4"}>
                                                {data.data
                                                    .sort((a, b) => b.id.localeCompare(a.id))
                                                    .map((dataValue, index) => (
                                                        <div key={index + "tooltip"} className={"flex flex-col gap-4"}>
                                                            <span
                                                                className={
                                                                    "text-xs  font-medium " +
                                                                    (dataValue.id === "mindsetScore"
                                                                        ? "text-purple-600"
                                                                        : dataValue.id === "selfAssessmentScore"
                                                                        ? "text-teal-600"
                                                                        : dataValue.id ===
                                                                          "individualToCompareSelfAssessmentScore"
                                                                        ? "text-lime-600"
                                                                        : "text-pink-600")
                                                                }
                                                            >
                                                                {dataValue.id === "mindsetScore"
                                                                    ? "Mindset"
                                                                    : dataValue.id === "selfAssessment"
                                                                    ? "Self-assessed Current Capability"
                                                                    : dataValue.id ===
                                                                      "individualToCompareSelfAssessmentScore"
                                                                    ? "Individual Self-assessed Current Capability"
                                                                    : "Individual Mindset"}
                                                            </span>
                                                            <div className={"flex flex-row gap-4"}>
                                                                <div
                                                                    className={
                                                                        "font-medium flex flex-row justify-between " +
                                                                        "gap-4 items-center"
                                                                    }
                                                                >
                                                                    <Tag
                                                                        status={
                                                                            dataValue.value &&
                                                                            (dataValue.value > 0 && dataValue.value < 25
                                                                                ? reportLevelNames
                                                                                    ? reportLevelNames[0]
                                                                                    : "Entry"
                                                                                : dataValue.value >= 25 &&
                                                                                  dataValue.value < 50
                                                                                ? reportLevelNames
                                                                                    ? reportLevelNames[1]
                                                                                    : "Foundation"
                                                                                : dataValue.value >= 50 &&
                                                                                  dataValue.value < 75
                                                                                ? reportLevelNames
                                                                                    ? reportLevelNames[2]
                                                                                    : "Advanced"
                                                                                : reportLevelNames
                                                                                ? reportLevelNames[3]
                                                                                : "Mastery")
                                                                        }
                                                                        backgroundColour={
                                                                            dataValue.id === "mindsetScore"
                                                                                ? "bg-purple-100"
                                                                                : dataValue.id === "selfAssessmentScore"
                                                                                ? "bg-teal-100"
                                                                                : dataValue.id ===
                                                                                  "individualToCompareSelfAssessmentScore"
                                                                                ? "bg-lime-100"
                                                                                : "bg-pink-100"
                                                                        }
                                                                        textColour={
                                                                            dataValue.id === "mindsetScore"
                                                                                ? "text-purple-600"
                                                                                : dataValue.id === "selfAssessmentScore"
                                                                                ? "text-teal-600"
                                                                                : dataValue.id ===
                                                                                  "individualToCompareSelfAssessmentScore"
                                                                                ? "text-lime-600"
                                                                                : "text-pink-600"
                                                                        }
                                                                    />
                                                                </div>
                                                                <LowMediumHighBubble
                                                                    percentage={dataValue.value}
                                                                    small
                                                                    customStyling={
                                                                        (dataValue.id === "mindsetScore"
                                                                            ? "bg-purple-100 "
                                                                            : dataValue.id === "selfAssessmentScore"
                                                                            ? "bg-teal-100 "
                                                                            : dataValue.id ===
                                                                              "individualToCompareSelfAssessmentScore"
                                                                            ? "bg-lime-100 "
                                                                            : "bg-pink-100 ") +
                                                                        (dataValue.id === "mindsetScore"
                                                                            ? "text-purple-600 "
                                                                            : dataValue.id === "selfAssessmentScore"
                                                                            ? "text-teal-600 "
                                                                            : dataValue.id ===
                                                                              "individualToCompareSelfAssessmentScore"
                                                                            ? "text-lime-600 "
                                                                            : "text-pink-600 ") +
                                                                        (dataValue.id === "mindsetScore"
                                                                            ? "border-purple-600"
                                                                            : dataValue.id === "selfAssessmentScore"
                                                                            ? "border-teal-600"
                                                                            : dataValue.id ===
                                                                              "individualToCompareSelfAssessmentScore"
                                                                            ? "border-lime-600"
                                                                            : "border-pink-600")
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={
                    "flex flex-col gap-6 shrink-0 bg-white p-4 rounded-xl border border-slate-100 shadow-sm w-full " +
                    "print:p-0 print:shadow-none print:border-none break-before-page print:-mt-8"
                }
            >
                <div className="text-primary-600 font-medium text-base hidden print:block">{group.name}</div>
                <div className="text-secondary-600 font-medium text-base">
                    Average difference between Mindset & Self Assessment
                </div>
                <CategorisedVerticalBarChart
                    colourPalette={CAPABILITIES_BAR_CHART_PALETTE}
                    data={group.mindsetVsSelfAssessmentChartData}
                />
            </div>
        </div>
    );
}
