import { useState } from "react";

import { COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";
import { updateReleaseReportPermissionFromUsers } from "../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import UserReportReleasePermissions from "./UserReportReleasePermissions/UserReportReleasePermissions";

export default function Settings() {
    const [tabIndex, setTabIndex] = useState(0);

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [userIdentifiersWithPermission, setUserIdentifiersWithPermission] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleUpdateReleaseReportPermissionFromUsers = async () => {
        setLoading(true);
        try {
            //Requires EDIT_USER_PERMISSIONS (COACH-U-USRPERMISSIONS) from Backend
            await updateReleaseReportPermissionFromUsers(userIdentifiersWithPermission);
            setAlert("Successfully updated report release permissions");
            setAlertType(ALERT_TYPE.SUCCESS);
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    return (
        <DashboardLayout
            title={"Settings"}
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.VIEW_COHORTS,
                COACH_PLATFORM_PERMISSIONS.VIEW_PROGRAMS,
                COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS,
                COACH_PLATFORM_PERMISSIONS.GRANT_RELEASE_REPORT,
                COACH_PLATFORM_PERMISSIONS.EDIT_USER_PERMISSIONS,
            ]}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <TabbedNavigationLayout
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabList={["User report release permissions"]}
                backgroundColour={"bg-slate-100"}
                loading={loading}
                tabContent={[
                    {
                        title: "User report release permissions",
                        content: (
                            <UserReportReleasePermissions
                                setUserIdentifiersWithPermission={setUserIdentifiersWithPermission}
                                userIdentifiersWithPermission={userIdentifiersWithPermission}
                            />
                        ),
                        onSaveChanges: handleUpdateReleaseReportPermissionFromUsers,
                    },
                ]}
            />
        </DashboardLayout>
    );
}
