import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getPaginatedCoacheesTest } from "../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import CoacheeRow from "./CoacheeRow";
import PaginatedSearchPageFetched from "../../../SharedComponents/Pagination/PaginatedSearchPageFetched";

export default function CoachesPaginationTest() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);

    const location = useLocation();

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout title={"Coachees pagination test"} requiredPermissions={[]}>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearchPageFetched
                itemName={"Coachees"}
                searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                columnNames={["Details", "Coach", "Edit"]}
                ItemRow={CoacheeRow}
                customPlaceholder={"Search coachee by first name, last name or email address"}
                fetchList={getPaginatedCoacheesTest}
            />
        </DashboardLayout>
    );
}
