import { useLocation } from "react-router";

import AtAGlanceCard from "./AtAGlanceCard";
import DetailedSummaryCard from "./DetailedSummaryCard";
import AtAGlanceOverallCard from "./AtAGlanceOverallCard";
import SideSection from "../../SharedChartComponents/SideSection";
import ReportHeader from "../../SharedChartComponents/ReportHeader";

export default function HeadFirst(props) {
    const { score, testId, onlyContent, coacheeName } = props;

    let showDetailedInterpretation = false;

    if (score.reportFields) {
        score.reportFields.forEach((reportField) => {
            if (!reportField.name) {
                reportField.name = "";
            }
        });
    }

    if (score && score.reportFields) {
        score.reportFields.forEach((reportField) => {
            if (reportField.percentage > 34) {
                showDetailedInterpretation = true;
            }
        });
    }

    const location = useLocation();

    return (
        <div data-testid={testId && testId} className="flex flex-col gap-4">
            <div className="text-primary-600 font-medium">HeadFirst</div>
            {!onlyContent && (
                <ReportHeader
                    reportName={score.reportName === "Headfirst" ? "HeadFirst" : score.reportName}
                    userName={location.state?.userName}
                />
            )}
            <div
                className={
                    "grid grid-cols-1 xl:grid-cols-3 gap-6 bg-white rounded-xl p-5 print:p-0 shadow-sm border " +
                    "border-slate-100"
                }
            >
                <div
                    className={
                        "flex flex-col gap-6 " + (onlyContent ? "col-span-full" : "col-span-2 flex flex-col gap-6")
                    }
                >
                    {score?.reportDescription && (
                        <div className="flex flex-col gap-4">
                            <div className="text-sm text-primary-700 font-medium flex flex-row">Introduction</div>
                            <div className="bg-slate-50 rounded-xl p-4 shadow-sm">
                                <div className="text-primary-600 text-sm font-medium mb-2 ">Description</div>
                                <p className="text-slate-500 text-sm">{score.reportDescription}</p>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col gap-4">
                        <div className="text-secondary-600 font-medium flex flex-row">At a glance</div>
                        <div className="bg-slate-50 p-4 rounded-xl shadow-sm md:hidden">
                            <div className="space-y-3">
                                <p className="text-sm font-medium text-primary-700 ">Legend</p>
                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-row items-center gap-2">
                                        <span className="h-4 w-4 bg-red-600 rounded-full" />
                                        <span className="text-red-600 font-medium">High</span>
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                        <span className="h-4 w-4 bg-amber-600 rounded-full" />
                                        <span className="text-amber-600 font-medium">Moderate</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col 2xl:grid 2xl:grid-cols-2 gap-4">
                            {score &&
                                score.reportFields &&
                                score.reportFields
                                    .slice(0)
                                    .reverse()
                                    .map(
                                        (reportField, index) =>
                                            reportField.name.toLowerCase() === "headfirst total score" && (
                                                <AtAGlanceOverallCard scale={reportField} key={index} />
                                            )
                                    )}
                            {score &&
                                score.reportFields &&
                                score.reportFields.map(
                                    (reportField, index) =>
                                        reportField.percentage > 34 &&
                                        reportField.name.toLowerCase() !== "headfirst total score" && (
                                            <AtAGlanceCard scale={reportField} key={index} />
                                        )
                                )}
                        </div>
                    </div>
                    {showDetailedInterpretation && (
                        <div className="flex flex-col gap-4">
                            <div className="text-secondary-600 font-medium flex flex-row"> Detailed Interpretation</div>
                            <div className="grid grid-flow-row gap-8">
                                <div className="grid gap-4">
                                    {score &&
                                        score.reportFields &&
                                        score.reportFields.map(
                                            (reportField, index) =>
                                                reportField.percentage > 34 &&
                                                reportField.name.toLowerCase() !== "headfirst total score" && (
                                                    <DetailedSummaryCard
                                                        scale={reportField}
                                                        key={index}
                                                        coacheeName={
                                                            location.state?.userName
                                                                ? location.state.userName
                                                                : coacheeName
                                                        }
                                                    />
                                                )
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {!onlyContent && <SideSection />}
            </div>
        </div>
    );
}
