import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import {
    BAR_CHART_SELF_ASSESSMENT_AND_MINDSET_COLOUR_PALETTE,
    BASIC_ANIMATION_VARIANTS,
} from "../../../../../../utils/constants";

import FancyCheckBox from "../../../../../SharedComponents/Form/FancyCheckBox";
import Alert, { ALERT_TYPE } from "../../../../../SharedComponents/Alert";
import BarVertical from "./BarVertical";

export default function CategorisedVerticalBarChart(props) {
    const { data, testId, sevenPointScale, noCategories } = props;

    const [categories, setCategories] = useState([]);
    const [chosenCategories, setChosenCategories] = useState([]);

    useEffect(() => {
        let updatedCategories = [];

        if (data) {
            data.map((dataItem) => updatedCategories.push({ name: dataItem.name, colour: dataItem.colour }));
        }

        setCategories(updatedCategories);
        setChosenCategories(updatedCategories);
    }, [data]);

    const chosenBar = (barCategory, percentageScore, backgroundColor, textColour, name) => {
        return (
            <AnimatePresence>
                {chosenCategories.some((category) => category.name === barCategory) && (
                    <motion.div
                        initial={"inactiveWidth"}
                        animate={"activeWidth"}
                        exit={"inactiveWidth"}
                        transition={{ duration: 0.6 }}
                        variants={BASIC_ANIMATION_VARIANTS}
                        className="h-full"
                    >
                        <div className={"h-full flex flex-row gap-1"}>
                            <span
                                style={{ writingMode: "vertical-lr" }}
                                className={
                                    "text-base font-medium text-slate-400 tracking-tight place-self-end rotate-180"
                                }
                            >
                                {name}
                            </span>
                            <BarVertical
                                label={barCategory}
                                score={percentageScore}
                                backgroundColour={backgroundColor}
                                textColour={textColour}
                                separationGap={"mx-0.5"}
                                vertical
                                noLabel
                                smallerBars={sevenPointScale}
                            />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

    function processChosenCategories(category) {
        if (chosenCategories.some((chosenCategory) => chosenCategory.name === category.name)) {
            const currentCategories = chosenCategories.filter(
                (currentCategory) => category.name !== currentCategory.name
            );
            setChosenCategories(currentCategories);
        } else {
            setChosenCategories((oldChosenCategories) => [...oldChosenCategories, category]);
        }
    }

    return (
        <div className="flex flex-col gap-4 print:block print:space-y-4" data-testid={testId && testId}>
            <div className="flex items-center justify-between gap-6">
                {!noCategories && categories.length >= 2 && (
                    <div className="flex flex-row gap-2 text-sm font-title text-primary-800 items-center justify-start overflow-x-scroll print:hidden">
                        {categories.map((category, index) => (
                            <FancyCheckBox
                                testId={category.name.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"}
                                key={index}
                                labelText={category.name}
                                value={chosenCategories.some((chosenCategory) => chosenCategory.name === category.name)}
                                name={category.name}
                                colour={category.colour}
                                setValue={() => processChosenCategories(category)}
                            />
                        ))}
                    </div>
                )}
            </div>

            {data.length !== 0 ? (
                <div className="flex flex-row font-title font-semibold transition-all w-full h-96">
                    <div className="flex flex-col gap-2 items-end -mr-[1.5px]">
                        <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium">100%</div>
                        <div className="flex flex-col items-center justify-center gap-2 h-[121px] transition-all border-slate-300 border-dashed border-2 border-l-0 border-t-0 border-b-0" />
                        <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium">50%</div>
                        <div className="flex flex-col items-center justify-center gap-2 h-[121px] transition-all border-slate-300 border-dashed border-2 border-l-0 border-t-0 border-b-0" />
                        <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium">0%</div>
                    </div>
                    <div className="flex flex-row gap-4 font-title font-semibold transition-all h-96 w-full">
                        {data.map((dataCategory) => (
                            <div className="flex flex-col items-center gap-2 h-full transition-all w-full">
                                <div className="flex flex-row gap-4 justify-between h-full w-full px-2.5">
                                    {dataCategory.data.map((dataItem) =>
                                        chosenBar(
                                            dataCategory.name,
                                            dataItem.percentScore,
                                            BAR_CHART_SELF_ASSESSMENT_AND_MINDSET_COLOUR_PALETTE[dataCategory.name]
                                                .backgroundColour,
                                            BAR_CHART_SELF_ASSESSMENT_AND_MINDSET_COLOUR_PALETTE[dataCategory.name]
                                                .textColour,
                                            dataItem.name
                                        )
                                    )}
                                </div>
                                <AnimatePresence>
                                    {chosenCategories.some((category) => category.name === dataCategory.name) && (
                                        <motion.div
                                            initial={"inactiveWidth"}
                                            animate={"activeWidth"}
                                            exit={"inactiveWidth"}
                                            transition={{ duration: 0.6 }}
                                            variants={BASIC_ANIMATION_VARIANTS}
                                            className="w-full"
                                        >
                                            <div className="flex flex-col gap-2 items-center w-full">
                                                <div
                                                    className={
                                                        "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                                    }
                                                />
                                                <div className="w-full text-center font-title text-base text-slate-400 font-medium truncate ">
                                                    {dataCategory.name}
                                                </div>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <Alert text={"Data set for this chart is empty."} type={ALERT_TYPE.WARNING} active noMargins />
            )}
        </div>
    );
}
