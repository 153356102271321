import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { ReactComponent as CircleIcon } from "../../../assets/circle.svg";
import BasicMotion from "../Motion/BasicMotion";

export default function FancyCheckBox(props) {
    const { labelText, value, id, setValue, colour, testId, minimal, simpleCheckBox } = props;

    return (
        <div
            data-testid={testId && testId}
            className={
                "flex gap-1.5 items-center cursor-pointer border-2 h-max transition-colors  " +
                (minimal
                    ? simpleCheckBox
                        ? "border-none bg-transparent rounded-none w-full "
                        : "border-t-0 border-x-0 border-b-slate-50 bg-transparent rounded-none w-full px-3 py-2.5 " +
                          (!value ? "hover:bg-slate-100 " : "hover:bg-" + colour + "-100 ")
                    : " rounded-xl w-max px-2 py-1.5 ") +
                (value
                    ? "bg-" + colour + "-100 border-" + colour + "-600 text-" + colour + "-700 "
                    : " border-slate-200 bg-slate-50 text-slate-500 ")
            }
            onClick={(e) => setValue(e.target.checked)}
        >
            {value && (
                <BasicMotion>
                    <CheckCircleIcon className={"text-" + colour + "-600 shrink-0 block h-5 w-5 transition-colors"} />
                </BasicMotion>
            )}
            {!value && (
                <BasicMotion>
                    <CircleIcon className={"block h-5 w-5 text-slate-300 transition-colors"} />
                </BasicMotion>
            )}
            <label htmlFor={id} className="block text-sm font-medium cursor-pointer select-none w-max">
                {labelText}
            </label>
        </div>
    );
}
