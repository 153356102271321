import { useNavigate } from "react-router-dom";

import { BUTTON_VARIANT_TYPES } from "../../../utils/constants";
import { ReactComponent as ErrorIllustration } from "../../../assets/time.svg";

import Button from "../../SharedComponents/Button";
import ErrorPage from "../../SharedComponents/ErrorPage";

export default function SessionExpired() {
    const navigate = useNavigate();

    const renew = async () => {
        navigate("/renew");
    };

    return (
        <ErrorPage
            title={"Looks like your session has expired or you haven't logged in."}
            subtitle={"Click the button below to Login again."}
            buttons={
                <Button text="Go back to Psych Press Portal" onClick={renew} colour={BUTTON_VARIANT_TYPES.PRIMARY} />
            }
            illustration={<ErrorIllustration />}
        />
    );
}
