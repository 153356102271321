import React from "react";
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Label,
} from "recharts";

const data01 = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 200 },
    { x: 170, y: 300, z: 200 },
    { x: 140, y: 250, z: 200 },
    { x: 150, y: 400, z: 200 },
    { x: 110, y: 280, z: 200 },
];
const data02 = [
    { x: 321, y: 260, z: 200 },
    { x: 240, y: 390, z: 200 },
    { x: 190, y: 290, z: 200 },
    { x: 198, y: 350, z: 200 },
    { x: 180, y: 280, z: 200 },
    { x: 210, y: 400, z: 200 },
];

const data03 = [
    { x: 321, y: 254, z: 200 },
    { x: 234, y: 343, z: 200 },
    { x: 142, y: 235, z: 200 },
    { x: 254, y: 321, z: 200 },
    { x: 124, y: 241, z: 200 },
    { x: 400, y: 321, z: 200 },
];

const data04 = [
    { x: 111, y: 123, z: 200 },
    { x: 122, y: 322, z: 200 },
    { x: 324, y: 333, z: 200 },
    { x: 212, y: 222, z: 200 },
    { x: 333, y: 124, z: 200 },
    { x: 322, y: 321, z: 200 },
];

export default function ScatterGraph() {
    return (
        <ResponsiveContainer width="100%" height="100%" className={"mt-4"}>
            <ScatterChart
                margin={{
                    top: 15,
                    right: 15,
                    bottom: 30,
                    left: 15,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" dataKey="x" name="Overall points" strokeDasharray="3 3" stroke="#CBD5E1">
                    <Label value="Leadership Style points" offset={-20} position="insideBottom" fill="#64748B" />
                </XAxis>
                z`
                <YAxis type="number" dataKey="y" strokeDasharray="3 3" stroke="#CBD5E1">
                    <Label
                        value="Overall points"
                        angle={270}
                        offset={"-6"}
                        position="left"
                        style={{ textAnchor: "middle" }}
                        fill="#64748B"
                    />
                </YAxis>
                <ZAxis type="number" dataKey="z" range={[60, 400]} name="score" />
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Scatter name="Department 1" data={data01} fill="#DB2777" shape="star" />
                <Scatter name="Department 2" data={data02} fill="#7C3AED" shape="triangle" />
                <Scatter name="Department 3" data={data03} fill="#7C3AED" shape="square" />
                <Scatter name="Department 4" data={data04} fill="#059669" shape="circle" />
            </ScatterChart>
        </ResponsiveContainer>
    );
}
