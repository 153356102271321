import { useContext } from "react";
import { useNavigate } from "react-router";
import { CogIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, NO_ACCESS_TYPES } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Button from "../../../SharedComponents/Button";
import AccessControl from "../../../SharedComponents/AccessControl";
import Tag from "../../../SharedComponents/Tag";

export default function CoachRow(props) {
    const { item: coach } = props;

    const context = useContext(Context);

    const navigate = useNavigate();

    return (
        <tr className={"hover:bg-slate-100 transition-colors group"}>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm font-medium text-primary-600"}>{coach.firstName + " " + coach.lastName}</div>
                <div className="text-sm text-slate-500 mt-1 group-hover:text-primary-600 transition-colors">
                    {coach.emailAddress}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap max-w-xs">
                <div className="flex flex-wrap gap-2">
                    {coach.cohorts.length > 0 ? (
                        coach.cohorts.map((cohort, index) => <Tag key={index} status={cohort} />)
                    ) : (
                        <Tag />
                    )}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div
                    className={
                        "flex items-center justify-center text-xs text-slate-600 font-medium bg-slate-200 " +
                        "h-6 w-6 rounded-full group-hover:text-primary-600 group-hover:bg-primary-100 " +
                        "transition-colors"
                    }
                >
                    {coach.currentCoachees}
                </div>
            </td>
            <AccessControl
                requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_ADDITIONAL_COACH_DETAILS]}
                noAccessType={NO_ACCESS_TYPES.BLANK}
            >
                <td className="px-4 py-3 whitespace-nowrap">
                    <div
                        className={
                            "flex items-center justify-center text-xs text-slate-600 font-medium bg-slate-200 " +
                            "h-6 w-6 rounded-full group-hover:text-primary-600 group-hover:bg-primary-100 " +
                            "transition-colors"
                        }
                    >
                        {coach.allCoachees}
                    </div>
                </td>
            </AccessControl>
            <td className="px-4 py-3 whitespace-nowrap">
                <Button
                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                    icon={<CogIcon className="shrink-0 block h-5 w-5" />}
                    extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full p-2"}
                    iconPositionLeft
                    onClick={() => {
                        navigate(`/${context.terminology.coach.singular.toLowerCase()}-profile`, {
                            state: {
                                userIdentifier: coach.userIdentifier,
                            },
                        });
                    }}
                />
            </td>
        </tr>
    );
}
