import Tippy from "@tippyjs/react";
import { PrinterIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../utils/constants";

import Alert, { ALERT_TYPE } from "./Alert";
import Button from "./Button";

export default function PrintToPDF(props) {
    const { onlyButton, setPrintToPDFMode, setNavigatingToPrint, navigatingToPrint } = props;

    return onlyButton ? (
        <Tippy
            content={
                <Alert
                    text={
                        <div className={"flex flex-col gap-2"}>
                            <div className={"font-medium"}>For best results:</div>
                            <ul className={"list-disc"}>
                                <li>
                                    <span className={"font-medium"}>Use Google Chrome</span>
                                </li>
                                <li>
                                    Destination:
                                    <span className={"font-medium"}> "Save as PDF"</span>
                                </li>
                                <li>
                                    Layout: <span className={"font-medium"}>Portrait</span>
                                </li>
                                <li>
                                    Paper size: <span className={"font-medium"}>A4</span>
                                </li>
                                <li>
                                    <span className={"font-medium"}>Untick "Headers and footers"</span>
                                </li>
                                <li>
                                    <span className={"font-medium"}>Tick "Background graphics"</span>
                                </li>
                            </ul>
                        </div>
                    }
                    type={ALERT_TYPE.PRIMARY}
                    active
                    noMargins
                />
            }
            arrow={false}
            animation={"shift-away-subtle"}
            placement={"bottom-start"}
        >
            <div>
                <Button
                    onClick={() => {
                        setPrintToPDFMode(true);
                        setNavigatingToPrint(true);
                        setTimeout(() => {
                            window.print();
                            setNavigatingToPrint(false);
                        }, 5000);
                    }}
                    text={<span className={"w-max"}>Print to PDF using browser</span>}
                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                    icon={<PrinterIcon className="block h-4 w-4 mr-2" />}
                    iconPositionLeft
                    loading={navigatingToPrint}
                    smallButton
                    extraStyling={"h-max"}
                />
            </div>
        </Tippy>
    ) : (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2 items-center">
                <div className="text-slate-500 font-medium text-sm">Actions:</div>
            </div>
            <Button
                onClick={() => {
                    setPrintToPDFMode(true);
                    setNavigatingToPrint(true);
                    setTimeout(() => {
                        window.print();
                        setNavigatingToPrint(false);
                        setPrintToPDFMode(false);
                    }, 5000);
                }}
                text={"Print to PDF using browser"}
                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                icon={<PrinterIcon className="block h-4 w-4 mr-2" />}
                iconPositionLeft
                loading={navigatingToPrint}
            />
            <Alert
                text={
                    <div className={"flex flex-col gap-2"}>
                        <div className={"font-medium"}>For best results:</div>
                        <ul className={"list-disc"}>
                            <li>
                                <span className={"font-medium"}>Use Google Chrome</span>
                            </li>
                            <li>
                                Destination:
                                <span className={"font-medium"}> "Save as PDF"</span>
                            </li>
                            <li>
                                Layout: <span className={"font-medium"}>Portrait</span>
                            </li>
                            <li>
                                Paper size: <span className={"font-medium"}>A4</span>
                            </li>
                            <li>
                                <span className={"font-medium"}>Untick "Headers and footers"</span>
                            </li>
                            <li>
                                <span className={"font-medium"}>Tick "Background graphics"</span>
                            </li>
                        </ul>
                    </div>
                }
                type={ALERT_TYPE.PRIMARY}
                active
                noMargins
            />
        </div>
    );
}
