import { useCallback, useEffect, useState } from "react";
import {
    AtSymbolIcon,
    BriefcaseIcon,
    CakeIcon,
    CheckBadgeIcon,
    DevicePhoneMobileIcon,
    PencilIcon,
    UserIcon,
} from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../../utils/constants";
import { getUserMediaFile } from "../../../../../utils/api";

import Input from "../../../../SharedComponents/Form/Input";
import Button from "../../../../SharedComponents/Button";
import CircleLoadingPlaceholder from "../../../../SharedComponents/Loading/CircleLoadingPlaceholder";
import BasicMotion from "../../../../SharedComponents/Loading/Transitions/BasicMotion";
import MultiSelect from "../../../../SharedComponents/Form/MultiSelect";
import Textarea from "../../../../SharedComponents/Form/Textarea";
import { ALERT_TYPE } from "../../../../SharedComponents/Alert";

export default function CoachDetails(props) {
    const {
        uploadProfilePhoto,
        coachDetails,
        setCoachDetails,
        canEdit,
        coachAccreditations,
        setCoachAccreditations,
        coachDetailsLoading,
        setAlert,
        setAlertType,
    } = props;

    const [photo, setPhoto] = useState([]);
    const [photoLoading, setPhotoLoading] = useState(false);

    const fetchPhotos = useCallback(async () => {
        setPhotoLoading(true);
        try {
            const photoResponse = await Promise.all(
                coachDetails.mediaFiles
                    .filter((mediaFile) => ["png", "jpg", "jpeg"].includes(mediaFile.fileType))
                    .map(async (photo) => {
                        return { ...photo, content: (await getUserMediaFile(photo.identifier)).content };
                    })
            );
            setPhoto(photoResponse[0]);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setPhotoLoading(false);
    }, [coachDetails.mediaFiles, setAlert, setAlertType]);

    useEffect(() => {
        if (coachDetails.mediaFiles && coachDetails.mediaFiles.length > 0) {
            fetchPhotos();
        }
    }, [fetchPhotos, coachDetails.mediaFiles]);

    const updateProfileDetails = (key) => (value) => {
        setCoachDetails((currProfileDetails) => {
            return { ...currProfileDetails, [key]: value };
        });
    };

    return (
        <div className="xl:grid xl:grid-cols-5 gap-4">
            <div className={"col-span-1 mb-5"}>
                <div className="relative inline-block">
                    {photoLoading || coachDetailsLoading ? (
                        <BasicMotion>
                            <CircleLoadingPlaceholder />
                        </BasicMotion>
                    ) : photo.length !== 0 ? (
                        <BasicMotion>
                            <img
                                key={photo.identifier}
                                className={
                                    "w-40 h-40 xl:w-[12vw] xl:h-[12vw] rounded-full bg-clip-border shadow-sm bg-slate-50"
                                }
                                src={"data:image/png;base64," + photo.content}
                                alt={photo.name}
                            />
                        </BasicMotion>
                    ) : (
                        <BasicMotion>
                            <div className="flex items-center justify-center text-center w-40 h-40 xl:w-[12vw] xl:h-[12vw] rounded-full bg-clip-border shadow-sm bg-slate-50">
                                <span className="text-xs text-slate-400 italic ">No display picture available</span>
                            </div>
                        </BasicMotion>
                    )}
                    {canEdit && (
                        <Button
                            colour={BUTTON_VARIANT_TYPES.SECONDARY}
                            icon={<PencilIcon className="block h-7 w-7" />}
                            extraStyling={
                                "absolute bottom-0 right-5 text-xs h-8 w-8 text-white rounded-full shadow-sm p-2"
                            }
                            iconPositionLeft
                            onClick={uploadProfilePhoto}
                        />
                    )}
                </div>
            </div>
            <div className="items-center lg:gap-8 col-span-4">
                <div className="flex-grow grid grid-flow-row-dense gap-6 w-full">
                    <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-6 lg:mt-0">
                        <Input
                            id="name"
                            type="text"
                            label="First name"
                            placeholder={coachDetailsLoading ? "Loading.." : "First name"}
                            icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                            value={coachDetails.firstName ? coachDetails.firstName : ""}
                            onChange={canEdit && updateProfileDetails("firstName")}
                            backgroundColour={"bg-white"}
                        />
                        <Input
                            id="name"
                            type="text"
                            label="Last name"
                            placeholder={coachDetailsLoading ? "Loading.." : "Last name"}
                            icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                            value={coachDetails.lastName ? coachDetails.lastName : ""}
                            onChange={canEdit && updateProfileDetails("lastName")}
                            backgroundColour={"bg-white"}
                        />
                    </div>
                    <div className="grid grid-flow-row lg:grid-cols-2 gap-6">
                        <Input
                            id="email"
                            type="email"
                            label="Email address"
                            placeholder={coachDetailsLoading ? "Loading.." : "Email address"}
                            icon={<AtSymbolIcon className="h-4 w-4 text-slate-500" />}
                            value={coachDetails.emailAddress ? coachDetails.emailAddress : ""}
                            onChange={canEdit && updateProfileDetails("emailAddress")}
                            backgroundColour={"bg-white"}
                        />
                        <Input
                            id="dob"
                            type="date"
                            label="Date of birth"
                            placeholder={coachDetailsLoading ? "Loading.." : "Date of birth"}
                            icon={<CakeIcon className="h-4 w-4 text-slate-500" />}
                            value={coachDetails.dateOfBirth ? coachDetails.dateOfBirth.substring(0, 10) : ""}
                            onChange={canEdit && updateProfileDetails("dateOfBirth")}
                            backgroundColour={"bg-white"}
                        />
                    </div>
                    <div className="grid grid-flow-row lg:grid-cols-2 gap-6">
                        <Input
                            id="contact-number"
                            type="number"
                            label="Contact number"
                            placeholder={coachDetailsLoading ? "Loading.." : "Contact number"}
                            icon={<DevicePhoneMobileIcon className="h-4 w-4 text-slate-500" />}
                            value={coachDetails.mobileNumber ? coachDetails.mobileNumber : ""}
                            onChange={canEdit && updateProfileDetails("mobileNumber")}
                            backgroundColour={"bg-white"}
                            extraStyling="col-span-1"
                        />
                        <Input
                            id="experience"
                            type="number"
                            label="Years of experience"
                            placeholder={coachDetailsLoading ? "Loading.." : "Years of experience (number)"}
                            icon={<BriefcaseIcon className="h-4 w-4 text-slate-500" />}
                            backgroundColour={"bg-white"}
                            value={coachDetails.experienceYears ? coachDetails.experienceYears : ""}
                            onChange={canEdit && updateProfileDetails("experienceYears")}
                            extraStyling="col-span-1"
                            hidden={process.env.REACT_APP_DEFAULT_APP === "Employment Services"}
                        />
                    </div>
                    <div
                        className={
                            "grid grid-flow-row lg:grid-cols-2 gap-6 " +
                            (process.env.REACT_APP_DEFAULT_APP === "Employment Services" && "hidden")
                        }
                    >
                        <Textarea
                            id="biography"
                            label="Bio"
                            placeholder={coachDetailsLoading ? "Loading.." : "Write about yourself.."}
                            onChange={canEdit && updateProfileDetails("biography")}
                            backgroundColour={"bg-white"}
                            extraStyling="col-span-2"
                            defaultValue={coachDetails.biography ? coachDetails.biography : ""}
                            hidden={process.env.REACT_APP_DEFAULT_APP === "Employment Services"}
                        />
                    </div>
                </div>
                <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-6">
                    <MultiSelect
                        isMulti
                        isCreatable
                        chosenItems={coachAccreditations}
                        labelText={"Accreditations"}
                        setChosenItems={setCoachAccreditations}
                        loading={coachDetailsLoading}
                        extraStyling={"col-span-2"}
                        icon={<CheckBadgeIcon className="h-4 w-4 text-slate-500" />}
                        backgroundColour={"#FFFFFF"}
                    />
                </div>
            </div>
        </div>
    );
}
