import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { loginByAccessKey } from "../../utils/api";
import { Context } from "../../context/Context";

import CustomerPortalAuthProcess from "./CustomerPortalAuthProcess";

function Auth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [error, setError] = useState(false);

    const [accessKey] = useState(useParams().accessKey);
    const navigate = useNavigate();
    const context = useContext(Context);

    const authenticateWithCustomerPortal = useCallback(async () => {
        try {
            setAuthenticated(false);
            await loginByAccessKey(accessKey);
            setTimeout(() => {
                setAuthenticated(true);
                setTimeout(() => {
                    context.setLoggedIn(true);
                }, 1000);
            }, 1000);
        } catch (err) {
            setError(true);
            setAuthenticated(false);
        }
    }, [accessKey, context]);

    useEffect(() => {
        if (context.loggedIn && !context.showWelcomeLoading) {
            if (context.showOrgWelcome) {
                navigate("/welcome");
            } else {
                navigate("/");
            }
        }
    }, [context.loggedIn, context.showOrgWelcome, context.showWelcomeLoading, navigate]);

    useEffect(() => {
        if (!sessionStorage.token) {
            authenticateWithCustomerPortal();
        }
    }, [authenticateWithCustomerPortal]);

    return <CustomerPortalAuthProcess authenticated={authenticated} error={error} />;
}

export default Auth;
