import React, { useContext } from "react";
import { Context } from "../../../../../context/Context";

export default function ReportHeader(props) {
    const { reportName, userName, preparedBy, organisationLogo, noReportWordInTitle, noNegativeTopMargin, largeTitle } =
        props;

    const context = useContext(Context);

    return (
        <div className={"grid grid-cols-3 gap-6 relative  " + (noNegativeTopMargin ? "-mt-0" : "-mt-4")}>
            <div className="col-span-2 flex flex-col gap-1 print:-mt-4">
                <h2 className={"text-primary-600 font-medium " + (largeTitle ? "text-lg" : "text-base")}>
                    {reportName} {!noReportWordInTitle && "Report"} {userName ? "for" : ""}{" "}
                    <span className="text-secondary-600 font-medium">{userName}</span>
                </h2>
                <div className="text-slate-500 text-sm">{preparedBy ? preparedBy : "Prepared by Psych Press"}</div>
            </div>
            {organisationLogo && (
                <img
                    className={
                        "absolute top-0 right-0 hidden print:block print:-mt-4 " +
                        (context.profileDetails.organisationName === "City of Casey"
                            ? "w-[120px]"
                            : context.profileDetails.organisationName === "VGCCC"
                            ? "w-[100px]"
                            : "w-[150px]")
                    }
                    alt=""
                    src={context.getImage("shortLogo")}
                />
            )}
        </div>
    );
}
