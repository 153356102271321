import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const data = [
    {
        name: "Page A",
        visionary: 4000,
        collaborative: 2400,
        influential: -3000,
        entrepreneurial: 2900,
        delivers: -2500,
        enabling: -2800,
        policy: 3500,
        overall: -3800,
    },
];

export default function BarGraphPositiveNegative() {
    return (
        <ResponsiveContainer>
            <BarChart
                barCategoryGap="-10%"
                barGap={70}
                barSize={30}
                layout="vertical"
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: -30,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke="#CBD5E1" />
                <YAxis dataKey="name" type="category" ticks={[1]} strokeDasharray="3 3" stroke="#CBD5E1" />
                <XAxis type="number" strokeDasharray="3 3" stroke="#CBD5E1" />
                <Tooltip cursor={{ fill: "transparent" }} />
                <Bar dataKey="visionary" fill="#E11D48" radius={[0, 8, 8, 0]} />
                <Bar dataKey="collaborative" fill="#DB2777" radius={[0, 8, 8, 0]} />
                <Bar dataKey="influential" fill="#C026D3" radius={[0, 8, 8, 0]} />
                <Bar dataKey="entrepreneurial" fill="#9333EA" radius={[0, 8, 8, 0]} />
                <Bar dataKey="delivers" fill="#4F46E5" radius={[0, 8, 8, 0]} />
                <Bar dataKey="enabling" fill="#2563EB" radius={[0, 8, 8, 0]} />
                <Bar dataKey="policy" fill="#0891B2" radius={[0, 8, 8, 0]} />
                <Bar dataKey="overall" fill="#0D9488" radius={[0, 8, 8, 0]} />
            </BarChart>
        </ResponsiveContainer>
    );
}
