import SimpleMiniDataCard from "../../ChartCards/SimpleMiniDataCard";
import Alert, { ALERT_TYPE } from "../../../../../../../SharedComponents/Alert";

export default function SampleRegionalData() {
    return (
        <>
            <Alert text={"Sample data"} type={ALERT_TYPE.INFO} active noMargins className={"col-span-4"} />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                <SimpleMiniDataCard
                    label="Overall"
                    percentage={78}
                    trendPercentage={13}
                    accompanyingText="in 2023"
                    totalNumber={43}
                    currentNumber={12}
                />
                <SimpleMiniDataCard
                    label="VIC"
                    percentage={67}
                    trendPercentage={14}
                    accompanyingText="in 2023"
                    totalNumber={14}
                    currentNumber={12}
                />
                <SimpleMiniDataCard
                    label="QLD/NT"
                    percentage={46}
                    trendPercentage={-13}
                    accompanyingText="in 2023"
                    totalNumber={14}
                    currentNumber={10}
                />
                <SimpleMiniDataCard
                    label="NSW/ACT"
                    percentage={89}
                    trendPercentage={1}
                    accompanyingText="in 2023"
                    totalNumber={13}
                    currentNumber={5}
                />
                <SimpleMiniDataCard
                    label="TAS"
                    percentage={80}
                    trendPercentage={4}
                    accompanyingText="in 2023"
                    totalNumber={4}
                    currentNumber={2}
                />
                <SimpleMiniDataCard
                    label="WA"
                    percentage={68}
                    trendPercentage={31}
                    accompanyingText="in 2023"
                    totalNumber={12}
                    currentNumber={4}
                />
                <SimpleMiniDataCard
                    label="NZ"
                    percentage={45}
                    trendPercentage={-10}
                    accompanyingText="in 2023"
                    totalNumber={10}
                    currentNumber={4}
                />
            </div>
        </>
    );
}
