import { StarIcon } from "@heroicons/react/24/solid";

export default function InteractiveStar(props) {
    const { readonly, handleStarRatingClick, handleStarHoverRating, number, rating, starHoverRating } = props;

    return (
        <StarIcon
            className={
                "flex-shrink-0 h-6 w-6 transition-all transition duration-300 " +
                (rating > number - 1 || starHoverRating > number - 1 ? "text-yellow-500 " : "text-slate-400 ") +
                (readonly ? "opacity-70" : "hover:scale-125 active:scale-100 cursor-pointer")
            }
            onClick={() => {
                handleStarRatingClick(number);
            }}
            onMouseEnter={() => handleStarHoverRating(true, number)}
            onMouseLeave={() => handleStarHoverRating(false)}
        />
    );
}
