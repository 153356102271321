import React, { useCallback, useEffect, useState } from "react";

import { getCohorts, getResourceAssignment } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import CohortAccessRow from "./CohortAccessRow";

export default function CohortAccess(props) {
    const { resource, setCohortIdentifiersNewAccess, cohortIdentifiersNewAccess } = props;

    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [cohorts, setCohorts] = useState([]);
    const [cohortsWithAccess, setCohortsWithAccess] = useState([]);
    const [loading, setLoading] = useState(true);
    const [latestCohortInteractedAccess, setLatestCohortInteractedAccess] = useState(true);
    const [latestCohortInteractedNonAccess, setLatestCohortInteractedNonAccess] = useState(true);

    const handleAddCohortToggle = (cohortName, toggle, noPreviousAccessCohort) => {
        let cohort;

        if (noPreviousAccessCohort) {
            cohort = cohorts.find((cohort) => cohort === cohortName);
        } else {
            cohort = cohortsWithAccess.find((cohortsWithAccess) => cohortsWithAccess === cohortName);
        }

        if (toggle) {
            setCohortsWithAccess((cohortsWithAccess) => [cohort, ...cohortsWithAccess]);
            setCohorts(cohorts.filter((cohort) => cohort !== cohortName));

            setCohortIdentifiersNewAccess((cohortsWithNewAccess) => [...cohortsWithNewAccess, cohort]);
            setLatestCohortInteractedAccess(cohort);
        } else {
            setCohorts((cohorts) => [cohort, ...cohorts]);
            setCohortsWithAccess(cohortsWithAccess.filter((cohortWithAccess) => cohortWithAccess !== cohortName));

            setCohortIdentifiersNewAccess(
                cohortIdentifiersNewAccess.filter((cohortsWithNewAccess) => cohortsWithNewAccess !== cohortName)
            );
            setLatestCohortInteractedNonAccess(cohort);
        }
    };

    const fetchCohorts = useCallback(async () => {
        setLoading(true);
        try {
            const cohorts = await getCohorts();

            const resourceAssignmentResponse = await getResourceAssignment(resource.identifier);

            setCohortsWithAccess(resourceAssignmentResponse.cohorts);

            const filteredCohorts = cohorts.filter(
                (cohort) =>
                    !resourceAssignmentResponse.cohorts.find((cohortWithAccess) => cohortWithAccess === cohort.name)
            );

            filteredCohorts.map((filteredCohort) => setCohorts((cohorts) => [...cohorts, filteredCohort.name]));

            resourceAssignmentResponse.cohorts.map((cohort) =>
                setCohortIdentifiersNewAccess((cohortIdentifiersNewAccess) => [...cohortIdentifiersNewAccess, cohort])
            );
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, [resource.identifier, setCohortIdentifiersNewAccess]);

    useEffect(() => {
        fetchCohorts();
    }, [fetchCohorts]);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"flex flex-col gap-6"}>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-2">Cohorts</div>
                    <PaginatedSearch
                        loading={loading}
                        items={cohortsWithAccess}
                        itemName={"cohorts with access"}
                        searchAttributes={["name"]}
                        columnNames={["Name", "Access"]}
                        ItemRow={CohortAccessRow}
                        itemRowToggleOnChange={handleAddCohortToggle}
                        customItemsPerPage={4}
                        accessDefaultToggleState={false}
                        latestItemInteracted={latestCohortInteractedAccess}
                    />
                </div>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-4">Grant access to more cohorts</div>
                    <PaginatedSearch
                        loading={loading}
                        items={cohorts}
                        itemName={"other cohorts"}
                        searchAttributes={["name"]}
                        columnNames={["Name", "Access"]}
                        ItemRow={CohortAccessRow}
                        itemRowToggleOnChange={handleAddCohortToggle}
                        customItemsPerPage={8}
                        noPreviousAccessUser={true}
                        accessDefaultToggleState={true}
                        latestItemInteracted={latestCohortInteractedNonAccess}
                        noTableHead
                    />
                </div>
            </div>
        </>
    );
}
