import { useContext, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import {
    BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE,
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE,
    BASIC_ANIMATION_VARIANTS,
} from "../../../../../../utils/constants";

import FancyCheckBox from "../../../../../SharedComponents/Form/FancyCheckBox";
import Alert, { ALERT_TYPE } from "../../../../../SharedComponents/Alert";
import BarVertical from "./BarVertical";
import SausageLegendItem from "./CustomLegends/SausageLegendItem";
import { Context } from "../../../../../../context/Context";
import TrendBubble from "../../SharedChartComponents/TrendBubble";

export default function BarChartForScales(props) {
    const {
        data,
        extraContent,
        testId,
        sevenPointScale,
        ratingDivision,
        flippedPercentageScore,
        noCategories,
        noLegend,
        noInfoBubble,
    } = props;

    const context = useContext(Context);

    const [percentageChangeScore, setPercentageChangeScore] = useState();
    const [noHistoricalData, setNoHistoricalData] = useState(true);

    const defaultCategories = useMemo(
        () => [
            {
                name: context.aceSurveyReportData.currentSurveyLabel,
                colour: "blue",
            },
            {
                name: context.aceSurveyReportData.previousSurveyLabel,
                colour: "violet",
            },
            {
                name: "Sector benchmark",
                colour: "amber",
            },
        ],
        [context.aceSurveyReportData.currentSurveyLabel, context.aceSurveyReportData.previousSurveyLabel]
    );

    const [categories, setCategories] = useState(defaultCategories);

    const [chosenCategories, setChosenCategories] = useState(defaultCategories);

    useEffect(() => {
        let updatedCategories = JSON.parse(JSON.stringify(defaultCategories));

        if (data.historicalPercentScores) {
            setNoHistoricalData(false);
            if (data.data.current.percentScores && data.historicalPercentScores) {
                let tempPercentageScore =
                    data.data.current.percentScores.stronglyAgreePercentage +
                    data.data.current.percentScores.agreePercentage -
                    (data.historicalPercentScores.stronglyAgreePercentage +
                        data.historicalPercentScores.agreePercentage);

                setPercentageChangeScore(tempPercentageScore);
            }
        } else {
            updatedCategories = updatedCategories.filter(
                (category) => category.name !== context.aceSurveyReportData.previousSurveyLabel
            );
            setNoHistoricalData(true);
        }

        if (!data.data.sectorBenchmark) {
            updatedCategories = updatedCategories.filter((category) => category.name !== "Sector benchmark");
        }

        setCategories(updatedCategories);
        setChosenCategories(updatedCategories);
    }, [
        context.aceSurveyReportData.previousSurveyLabel,
        data.data,
        data.historicalPercentScores,
        defaultCategories,
        flippedPercentageScore,
    ]);

    const chosenBar = (barCategory, percentageScore, backgroundColor, textColour) => {
        return (
            <AnimatePresence>
                {chosenCategories.some((category) => category.name === barCategory) && (
                    <motion.div
                        initial={"inactiveWidth"}
                        animate={"activeWidth"}
                        exit={"inactiveWidth"}
                        transition={{ duration: 0.6 }}
                        variants={BASIC_ANIMATION_VARIANTS}
                        className="h-full"
                    >
                        <BarVertical
                            label={barCategory}
                            score={percentageScore}
                            backgroundColour={backgroundColor}
                            textColour={textColour}
                            separationGap={"mx-0.5"}
                            vertical
                            noLabel
                            smallerBars={sevenPointScale}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

    function processChosenCategories(category) {
        if (chosenCategories.some((chosenCategory) => chosenCategory.name === category.name)) {
            const currentCategories = chosenCategories.filter(
                (currentCategory) => category.name !== currentCategory.name
            );
            setChosenCategories(currentCategories);
        } else {
            setChosenCategories((oldChosenCategories) => [...oldChosenCategories, category]);
        }
    }

    return (
        <div className="flex flex-col gap-6" data-testid={testId && testId}>
            {!noInfoBubble && !noHistoricalData && (
                <div className="flex flex-col gap-4 rounded-xl p-3.5 bg-slate-50 border border-slate-200 font-normal w-max">
                    <div className="grid grid-cols-2 gap-3">
                        <span className="col-span-2 text-sm text-slate-500 font-medium print:hidden">
                            Overall agree percentage change
                        </span>
                        <div
                            className={
                                "col-span-2 flex flex-row gap-2 border border-y-0 border-r-0 border-l-2 pl-2 " +
                                (percentageChangeScore === 0
                                    ? "border-slate-500 text-slate-500"
                                    : flippedPercentageScore
                                    ? percentageChangeScore < 0
                                        ? "border-green-500 text-green-500"
                                        : "border-red-500 text-red-500"
                                    : percentageChangeScore > 0
                                    ? "border-green-500 text-green-500"
                                    : percentageChangeScore === undefined
                                    ? "border-slate-500 text-slate-500"
                                    : "border-red-500 text-red-500")
                            }
                        >
                            {!noHistoricalData && (
                                <div className="flex flex-col print:flex-row gap-2 pl-2 print:items-center">
                                    <TrendBubble
                                        percentage={percentageChangeScore}
                                        flippedColours={flippedPercentageScore}
                                        small
                                    />
                                    <span className="text-xs text-slate-500">% point change</span>
                                </div>
                            )}
                            <div className="flex flex-col print:flex-row gap-2 pl-2 print:items-center">
                                <span className="text-base font-medium text-primary-600">
                                    {data &&
                                        data.data.current.percentScores.stronglyAgreePercentage +
                                            data.data.current.percentScores.agreePercentage}
                                    %
                                </span>
                                <span className="text-xs text-slate-500">
                                    {context.aceSurveyReportData.currentSurveyLabel}
                                </span>
                            </div>
                            {!noHistoricalData && (
                                <div className="flex flex-col print:flex-row gap-2 pl-2 print:items-center">
                                    <span className="text-base font-medium text-primary-600">
                                        {!noHistoricalData &&
                                            data.historicalPercentScores &&
                                            data.historicalPercentScores.stronglyAgreePercentage +
                                                data.historicalPercentScores.agreePercentage}
                                        %
                                    </span>
                                    <span className="text-xs text-slate-500">
                                        {context.aceSurveyReportData.previousSurveyLabel}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!noLegend && (
                <div className={"flex flex-row flex-wrap justify-start gap-2"}>
                    <SausageLegendItem
                        label={context.aceSurveyReportData.currentSurveyLabel}
                        backgroundColour="bg-blue-500"
                        large
                    />
                    {!noHistoricalData && (
                        <SausageLegendItem
                            label={context.aceSurveyReportData.previousSurveyLabel}
                            backgroundColour="bg-violet-500"
                            large
                        />
                    )}
                    {data.data.sectorBenchmark && (
                        <SausageLegendItem label="Sector benchmark" backgroundColour="bg-amber-500" large />
                    )}
                </div>
            )}
            <div className="flex flex-col gap-4">
                {extraContent && extraContent}
                {!noCategories && categories.length >= 2 && (
                    <div className="flex flex-row gap-2 text-sm font-title text-primary-800 items-center justify-start overflow-x-scroll print:hidden">
                        {categories.map((category, index) => (
                            <FancyCheckBox
                                testId={category.name.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"}
                                key={index}
                                labelText={category.name}
                                value={chosenCategories.some((chosenCategory) => chosenCategory.name === category.name)}
                                name={category.name}
                                colour={category.colour}
                                setValue={() => processChosenCategories(category)}
                            />
                        ))}
                    </div>
                )}
            </div>
            {data.data.current.percentScores.length !== 0 ? (
                <div className="flex flex-row font-title font-semibold transition-all w-full">
                    <div className="flex flex-col gap-2 items-end -mr-[1.5px]">
                        <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium">100%</div>
                        <div className="flex flex-col items-center justify-center gap-2 h-[121px] transition-all border-slate-300 border-dashed border-2 border-l-0 border-t-0 border-b-0" />
                        <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium">50%</div>
                        <div className="flex flex-col items-center justify-center gap-2 h-[121px] transition-all border-slate-300 border-dashed border-2 border-l-0 border-t-0 border-b-0" />
                        <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium">0%</div>
                    </div>
                    <div className="flex flex-row gap-4 font-title font-semibold transition-all h-96 w-full">
                        <div className="flex flex-col gap-2 h-full transition-all">
                            {sevenPointScale && ratingDivision && (
                                <div className="flex flex-row gap-2 items-center ml-2 ">
                                    <div className="w-full border-slate-300 border-dashed border-2 border-b-0 border-r-0 border-l-0 h-3.5" />
                                    <div className="text-sm w-full text-center font-title text-secondary-600 font-medium mb-3">
                                        Detractors
                                    </div>
                                    <div className="w-full border-slate-300 border-dashed border-2 border-b-0 border-l-0 h-3.5" />
                                </div>
                            )}
                            <div className="flex flex-row gap-4 h-full transition-all">
                                <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                    <div className="flex flex-row h-full pl-2.5">
                                        {chosenBar(
                                            context.aceSurveyReportData.currentSurveyLabel,
                                            data.data.current.percentScores.stronglyDisagreePercentage &&
                                                data.data.current.percentScores.stronglyDisagreePercentage,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                        )}

                                        {!noHistoricalData &&
                                            chosenBar(
                                                context.aceSurveyReportData.previousSurveyLabel,
                                                data.historicalPercentScores.stronglyDisagreePercentage,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                            )}

                                        {data.data.sectorBenchmark &&
                                            chosenBar(
                                                data.data.sectorBenchmark.category,
                                                data.data.sectorBenchmark.percentScores.stronglyDisagreePercentage,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                            )}
                                    </div>
                                    <div className="flex flex-col gap-2 w-full items-center">
                                        <div
                                            className={
                                                "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                            }
                                        />
                                        <div className="w-full text-center font-title text-xs xl:text-sm text-slate-400 font-medium truncate ">
                                            Strongly disagree
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                    <div className="flex flex-row h-full">
                                        {chosenBar(
                                            context.aceSurveyReportData.currentSurveyLabel,
                                            data.data.current.percentScores.disagreePercentage,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                        )}
                                        {!noHistoricalData &&
                                            chosenBar(
                                                context.aceSurveyReportData.previousSurveyLabel,
                                                data.historicalPercentScores.disagreePercentage,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                            )}
                                        {data.data.sectorBenchmark &&
                                            chosenBar(
                                                data.data.sectorBenchmark.category,
                                                data.data.sectorBenchmark.percentScores.disagreePercentage,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                            )}
                                    </div>
                                    <div className="flex flex-col gap-2 w-full items-center">
                                        <div
                                            className={
                                                "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                            }
                                        />
                                        <div className="w-full text-center font-title text-xs xl:text-sm text-slate-400 font-medium">
                                            Disagree
                                        </div>
                                    </div>
                                </div>
                                {sevenPointScale && (
                                    <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                        <div className="flex flex-row h-full">
                                            {chosenBar(
                                                context.aceSurveyReportData.currentSurveyLabel,
                                                data.data.current.percentScores.mildlyDisagreePercentage,
                                                BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                                BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                            )}
                                            {!noHistoricalData &&
                                                chosenBar(
                                                    context.aceSurveyReportData.previousSurveyLabel,
                                                    data.historicalPercentScores.mildlyDisagreePercentage,
                                                    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                                )}
                                            {data.data.sectorBenchmark &&
                                                chosenBar(
                                                    data.data.sectorBenchmark.category,
                                                    data.data.sectorBenchmark.percentScores.mildlyDisagreePercentage,
                                                    BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour,
                                                    BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                                )}
                                        </div>
                                        <div className="flex flex-col gap-2 w-full items-center">
                                            <div
                                                className={
                                                    "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                                }
                                            />
                                            <div className="w-full text-center font-title text-xs xl:text-sm text-slate-400 font-medium">
                                                Mildly disagree
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {((data.data.current && data.data.current.percentScores.uncertainPercentage) ||
                            (data.data.historicalPercentScores && data.historicalPercentScores.uncertainPercentage) ||
                            (data.data.sectorBenchmark &&
                                data.data.sectorBenchmark.percentScores.uncertainPercentage) ||
                            sevenPointScale) && (
                            <div className="flex flex-col gap-2 h-full transition-all">
                                {sevenPointScale && ratingDivision && (
                                    <div className="flex flex-row gap-2 items-center -mr-[1.5px]">
                                        <div className="w-full border-slate-300 border-dashed border-2 border-b-0 border-r-0 h-3.5" />
                                        <div className="text-sm w-full text-center font-title text-secondary-600 font-medium mb-3">
                                            Passive
                                        </div>
                                        <div className="w-full border-slate-300 border-dashed border-2 border-b-0 border-l-0 h-3.5" />
                                    </div>
                                )}

                                <div className="flex flex-row gap-4 h-full transition-all">
                                    <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                        {((data.data.current && data.data.current.percentScores.uncertainPercentage) ||
                                            (data.data.historicalPercentScores &&
                                                data.historicalPercentScores.uncertainPercentage) ||
                                            (data.data.sectorBenchmark &&
                                                data.data.sectorBenchmark.percentScores.uncertainPercentage)) && (
                                            <>
                                                <div className="flex flex-row h-full">
                                                    {chosenBar(
                                                        context.aceSurveyReportData.currentSurveyLabel,
                                                        data.data.current.percentScores.uncertainPercentage,
                                                        BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                                        BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                                    )}
                                                    {!noHistoricalData &&
                                                        chosenBar(
                                                            context.aceSurveyReportData.previousSurveyLabel,
                                                            data.historicalPercentScores.uncertainPercentage,
                                                            BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                            BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                                        )}
                                                    {data.data.sectorBenchmark &&
                                                        chosenBar(
                                                            data.data.sectorBenchmark.category,
                                                            data.data.sectorBenchmark.percentScores.uncertainPercentage,
                                                            BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0]
                                                                .backgroundColour,
                                                            BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                                        )}
                                                </div>
                                                <div className="flex flex-col gap-2 w-full items-center">
                                                    <div
                                                        className={
                                                            "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                                        }
                                                    />
                                                    <div className="w-full text-center font-title text-xs xl:text-sm text-slate-400 font-medium">
                                                        Uncertain
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {sevenPointScale && (
                                        <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                            <div className="flex flex-row h-full">
                                                {chosenBar(
                                                    context.aceSurveyReportData.currentSurveyLabel,
                                                    data.data.current.percentScores.mildlyAgreePercentage,
                                                    BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                                    BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                                )}
                                                {!noHistoricalData &&
                                                    chosenBar(
                                                        context.aceSurveyReportData.previousSurveyLabel,
                                                        data.historicalPercentScores.mildlyAgreePercentage,
                                                        BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                        BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                                    )}
                                                {data.data.sectorBenchmark &&
                                                    chosenBar(
                                                        data.data.sectorBenchmark.category,
                                                        data.data.sectorBenchmark.percentScores.mildlyAgreePercentage,
                                                        BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0]
                                                            .backgroundColour,
                                                        BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                                    )}
                                            </div>
                                            <div className="flex flex-col gap-2 w-full items-center">
                                                <div
                                                    className={
                                                        "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                                    }
                                                />
                                                <div className="w-full text-center font-title text-xs xl:text-sm text-slate-400 font-medium">
                                                    Mildly agree
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col gap-2 h-full transition-all">
                            {sevenPointScale && ratingDivision && (
                                <div className="flex flex-row gap-2 items-center -mr-[1.5px]">
                                    <div className="w-full border-slate-300 border-dashed border-2 border-b-0 border-r-0 h-3.5" />
                                    <div className="text-sm w-full text-center font-title text-secondary-600 font-medium mb-3">
                                        Promoters
                                    </div>
                                    <div className="w-full border-slate-300 border-dashed border-2 border-b-0 border-l-0 h-3.5" />
                                </div>
                            )}
                            <div className="flex flex-row gap-4 h-full transition-all">
                                <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                    <div className="flex flex-row h-full">
                                        {chosenBar(
                                            context.aceSurveyReportData.currentSurveyLabel,
                                            data.data.current.percentScores.agreePercentage,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                        )}
                                        {!noHistoricalData &&
                                            chosenBar(
                                                context.aceSurveyReportData.previousSurveyLabel,
                                                data.historicalPercentScores.agreePercentage,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                            )}
                                        {data.data.sectorBenchmark &&
                                            chosenBar(
                                                data.data.sectorBenchmark.category,
                                                data.data.sectorBenchmark.percentScores.agreePercentage,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                            )}
                                    </div>
                                    <div className="flex flex-col gap-2 w-full items-center">
                                        <div
                                            className={
                                                "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                            }
                                        />
                                        <div className="w-full text-center font-title text-xs xl:text-sm text-slate-400 font-medium">
                                            Agree
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                    <div className="flex flex-row h-full">
                                        {chosenBar(
                                            context.aceSurveyReportData.currentSurveyLabel,
                                            data.data.current.percentScores.stronglyAgreePercentage,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                            BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                        )}
                                        {!noHistoricalData &&
                                            chosenBar(
                                                context.aceSurveyReportData.previousSurveyLabel,
                                                data.historicalPercentScores.stronglyAgreePercentage,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                            )}
                                        {data.data.sectorBenchmark &&
                                            chosenBar(
                                                data.data.sectorBenchmark.category,
                                                data.data.sectorBenchmark.percentScores.stronglyAgreePercentage,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour,
                                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                            )}
                                    </div>
                                    <div className="flex flex-col gap-2 w-full items-center">
                                        <div
                                            className={
                                                "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                            }
                                        />
                                        <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium w-max">
                                            Strongly agree
                                        </div>
                                    </div>
                                </div>
                                {((data.data.current && data.data.current.percentScores.notApplicable) ||
                                    (data.historicalPercentScores && data.historicalPercentScores.notApplicable) ||
                                    (data.data.sectorBenchmark &&
                                        data.data.sectorBenchmark.percentScores.notApplicable)) && (
                                    <div className="flex flex-col items-center justify-center gap-2 h-full transition-all">
                                        <div className="flex flex-row h-full">
                                            {chosenBar(
                                                context.aceSurveyReportData.currentSurveyLabel,
                                                data.data.current.percentScores.notApplicable,
                                                BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour,
                                                BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour
                                            )}
                                            {!noHistoricalData &&
                                                chosenBar(
                                                    context.aceSurveyReportData.previousSurveyLabel,
                                                    data.historicalPercentScores.notApplicable,
                                                    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].backgroundColour,
                                                    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID[3].textColour
                                                )}
                                            {data.data.sectorBenchmark &&
                                                chosenBar(
                                                    data.data.sectorBenchmark.category,
                                                    data.data.sectorBenchmark.percentScores.notApplicable,
                                                    BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour,
                                                    BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour
                                                )}
                                        </div>
                                        <div className="flex flex-col gap-2 w-full items-center">
                                            <div
                                                className={
                                                    "w-full border-slate-300 border-dashed border-2 border-t-0 h-3.5"
                                                }
                                            />
                                            <div className="text-center font-title text-xs xl:text-sm text-slate-400 font-medium w-max">
                                                Not applicable
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Alert text={"Data set for this chart is empty."} type={ALERT_TYPE.WARNING} active noMargins />
            )}
        </div>
    );
}
