import { useContext, useState } from "react";

import { Context } from "../../../../../../../../context/Context";

import SingleWithBenchmark from "../../ChartCards/SingleWithBenchmark";
import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";

export default function GeneralSatisfaction(props) {
    const { demo } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const context = useContext(Context);

    const tabList = ["By regions"];

    if (
        context.aceSurveyReportData.sixDimensionsMemberTypes &&
        context.aceSurveyReportData.sixDimensionsMemberTypes.length !== 0
    ) {
        let memberTypeTitle = "Member Type";

        if (context.aceSurveyReportData.sixDimensionsMemberTypes[0].title) {
            memberTypeTitle = context.aceSurveyReportData.sixDimensionsMemberTypes[0].title.split("-")[1].trim();
        }

        let generalSatisfactionMemberTypeTitle = "By " + memberTypeTitle;
        tabList.push(generalSatisfactionMemberTypeTitle);
    }

    const tabContent = [];

    context.aceSurveyReportData.generalSatisfaction.forEach((generalSatisfactionItem) => {
        generalSatisfactionItem.name !== "Text" &&
            tabContent.push({
                content: (
                    <SingleWithBenchmark
                        onlyBubbleOnFirstBarOnPrint
                        infoBubble
                        demo={demo}
                        withBenchmarks
                        detailed
                        data={generalSatisfactionItem.data}
                        noteworthyTrend={
                            generalSatisfactionItem.noteworthyContent && generalSatisfactionItem.noteworthyContent
                        }
                    />
                ),
                readonly: true,
            });
    });

    if (
        context.aceSurveyReportData.sixDimensionsMemberTypes &&
        context.aceSurveyReportData.sixDimensionsMemberTypes.length !== 0
    ) {
        context.aceSurveyReportData.generalSatisfactionWithMemberTypes.forEach((generalSatisfactionItem) => {
            generalSatisfactionItem.name !== "Text" &&
                tabContent.push({
                    content: (
                        <SingleWithBenchmark
                            onlyBubbleOnFirstBarOnPrint
                            infoBubble
                            demo={demo}
                            withBenchmarks
                            detailed
                            data={generalSatisfactionItem.data}
                        />
                    ),
                    readonly: true,
                });
        });
    }

    return (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            noContainer
            tabContent={tabContent}
            tabsRowOnly
            secondaryTabs
            displayOnlyContent={tabList.length === 1}
        />
    );
}
