import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LockClosedIcon, LockOpenIcon, ShieldExclamationIcon } from "@heroicons/react/24/solid";

import customerPortalLogo from "../../assets/customer-portal-logo.png";

function CustomerPortalAuthProcess(props) {
    const { authenticated, error } = props;

    const variants = {
        teal: {
            x: [-40, 40],
            y: [40, -40],
        },
        blue: {
            x: [40, -40],
            y: [-40, 40],
        },
        tan: {
            x: [0, 0],
            y: [0, 0],
        },
    };

    const transition = {
        x: {
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
        },
        y: {
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
        },
    };

    return (
        <div className={"flex flex-col gap-2 items-center justify-center h-screen -mt-24"}>
            <div className="flex items-center justify-center overflow-visible w-full h-48">
                <AnimatePresence>
                    <motion.svg
                        className={"overflow-visible"}
                        width={47}
                        height={41}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <motion.rect
                            width={48}
                            height={48}
                            rx={64}
                            fill="#89EFD7"
                            variants={variants}
                            transition={transition}
                            animate="teal"
                        />
                        {error ? (
                            <ShieldExclamationIcon className="h-16 w-16 text-red-500" />
                        ) : authenticated ? (
                            <LockOpenIcon className="h-16 w-16 text-green-500" />
                        ) : (
                            <LockClosedIcon className="h-16 w-16 animate-pulse text-purple-500" />
                        )}
                        <motion.rect
                            width={48}
                            height={48}
                            rx={64}
                            fill="#2563F6"
                            variants={variants}
                            transition={transition}
                            animate="blue"
                        />
                    </motion.svg>
                </AnimatePresence>
            </div>
            <div className={"flex items-center justify-center text-slate-500 text-center"}>
                <div className={"max-w-lg"}>
                    {error ? (
                        <div className={"leading-7"}>
                            <p className={"text-slate-600"}>Unfortunately we couldn't authenticate you.</p>
                            <p className={"mt-4"}>
                                Try to{" "}
                                <a
                                    className={"text-secondary-600 hover:text-secondary-900 transition-colors"}
                                    href={process.env.REACT_APP_RENEW_SESSION_REDIRECT_URL}
                                >
                                    {" "}
                                    Logging in again through Psych Press Portal
                                </a>{" "}
                                or contact techsupport at{" "}
                                <a
                                    className={
                                        "text-secondary-600 font-medium hover:text-secondary-900 transition-colors"
                                    }
                                    href="mailto:techsupport@psychpress.com.au"
                                >
                                    {" "}
                                    techsupport@psychpress.com.au{" "}
                                </a>{" "}
                                if the issue persists.
                            </p>
                        </div>
                    ) : authenticated ? (
                        <p className={"text-slate-500"}>You have been authenticated successfully</p>
                    ) : (
                        <p className={"text-slate-500"}>Authenticating you with Psych Press Portal...</p>
                    )}
                </div>
            </div>
            <div className={"fixed bottom-0 w-full"}>
                <div className={"w-full p-8 flex items-center justify-center"}>
                    <img alt="Survey Engine Logo" className="h-8" src={customerPortalLogo} />
                </div>
            </div>
        </div>
    );
}

export default CustomerPortalAuthProcess;
