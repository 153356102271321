import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../../../context/Context";

import SimpleMiniDataCard from "../../ChartCards/SimpleMiniDataCard";
import Alert, { ALERT_TYPE } from "../../../../../../../SharedComponents/Alert";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import SampleRegionalData from "./SampleRegionalData";

export default function CompletionsByRegion(props) {
    const { completions } = props;

    const [overallCompletion, setOverallCompletion] = useState(0);
    const [overallTotalCompleted, setOverallTotalCompleted] = useState(0);
    const [overallTotalSetup, setOverallTotalSetup] = useState(0);

    const context = useContext(Context);

    useEffect(() => {
        let totalSetup = 0;
        let totalCompleted = 0;

        completions.forEach((completionDataItem) => {
            totalSetup = totalSetup + completionDataItem.totalOrderCount;
            totalCompleted = totalCompleted + completionDataItem.submittedOrderCount;
        });

        setOverallTotalSetup(totalSetup);
        setOverallTotalCompleted(totalCompleted);
        setOverallCompletion((totalCompleted / totalSetup) * 100);
    }, [completions, context.aceSurveyRegionalCompletionDataLoading]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                    {context.aceSurveyViewCountryCompletions
                        ? "Completion rate by Country"
                        : "Completion rate by Region"}
                </h2>
                <p className="text-sm text-slate-500">
                    Percentage of survey completion by {context.aceSurveyViewCountryCompletions ? "Country" : "Region"}
                </p>
            </div>
            {context.aceSurveyRegionalCompletionDataLoading ? (
                <div className={"col-span-full flex items-center justify-center pt-10 pb-12 w-full"}>
                    <BouncingBalls />
                </div>
            ) : completions.length === 0 ? (
                process.env.REACT_APP_ENV === "PRD" ? (
                    <Alert text={"No completions have been recorded yet"} type={ALERT_TYPE.INFO} active noMargins />
                ) : (
                    <SampleRegionalData />
                )
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 print:grid-cols-3 gap-2">
                    <SimpleMiniDataCard
                        label="Overall"
                        percentage={overallCompletion}
                        totalNumber={overallTotalSetup}
                        currentNumber={overallTotalCompleted}
                    />
                    {completions.map((completionItem, index) => (
                        <SimpleMiniDataCard
                            key={index + "regionStat"}
                            label={completionItem.region?.trim()}
                            percentage={(completionItem.submittedOrderCount / completionItem.totalOrderCount) * 100}
                            totalNumber={completionItem.totalOrderCount > 2 ? completionItem.totalOrderCount : null}
                            currentNumber={
                                completionItem.totalOrderCount > 2 ? completionItem.submittedOrderCount : null
                            }
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
