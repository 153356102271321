import { motion, AnimatePresence } from "framer-motion";

import { BUTTON_VARIANT_TYPES, NO_ACCESS_TYPES } from "../../utils/constants";

import Menu from "../Dashboard/Menu/Menu";
import Button from "./Button";
import AccessControl from "./AccessControl";
import FormLoadingPlaceholder from "./Loading/FormLoadingPlaceholder";

export default function DashboardLayout(props) {
    const {
        title,
        requiredPermissions,
        children,
        actionButton,
        actionButtonRequiredPermissions,
        actionButtonText,
        actionButtonIcon,
        actionButtonOnClick,
        actionButtonLoading,
        otherElements,
        secondaryActionButton,
        secondaryActionButtonRequiredPermissions,
        secondaryActionButtonText,
        secondaryActionButtonIcon,
        secondaryActionButtonOnClick,
        loading,
    } = props;

    return (
        <AccessControl requiredPermissions={requiredPermissions} noAccessType={NO_ACCESS_TYPES.REDIRECT}>
            <main className="flex relative print:-mt-12">
                <Menu />
                <div className={"w-full h-full py-8 pl-6 pr-4 z-10 print:p-0 lg:mt-0 mt-16"}>
                    {(otherElements || secondaryActionButton || title) && (
                        <div className="flex flex-col gap-4 sm:gap-2 sm:flex-row items-left sm:items-center justify-between mb-2 pb-4 print:pb-8 print:h-0">
                            {loading ? (
                                <FormLoadingPlaceholder />
                            ) : (
                                title && (
                                    <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                                        {title}
                                    </h1>
                                )
                            )}

                            <div className={"flex items-end flex-row gap-2"}>
                                {otherElements && otherElements}

                                {secondaryActionButton && (
                                    <AccessControl
                                        requiredPermissions={secondaryActionButtonRequiredPermissions}
                                        NO_ACCESS_TYPES={NO_ACCESS_TYPES.BLANK}
                                    >
                                        <Button
                                            colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                            text={secondaryActionButtonText}
                                            icon={secondaryActionButtonIcon}
                                            onClick={secondaryActionButtonOnClick}
                                            iconPositionLeft
                                        />
                                    </AccessControl>
                                )}
                                {actionButton && (
                                    <AccessControl
                                        requiredPermissions={actionButtonRequiredPermissions}
                                        NO_ACCESS_TYPES={NO_ACCESS_TYPES.BLANK}
                                    >
                                        <Button
                                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                            text={actionButtonText}
                                            icon={actionButtonIcon}
                                            onClick={actionButtonOnClick}
                                            iconPositionLeft
                                            loading={actionButtonLoading}
                                        />
                                    </AccessControl>
                                )}
                            </div>
                        </div>
                    )}
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            {children}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </main>
        </AccessControl>
    );
}
