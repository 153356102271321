import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../../../../../utils/constants";

import DashboardLayout from "../../../../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QualitativeItemSection from "./QualitativeItemSection";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import AceReportPicker from "../AceReportPicker";

export default function Qualitative() {
    return (
        <DashboardLayout
            title="Qualitative"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <QualitativeElements />
        </DashboardLayout>
    );
}

export function QualitativeElements(props) {
    const { displayOnlyContent, onlyComments, onlyThemes } = props;

    const context = useContext(Context);

    const [tabIndex, setTabIndex] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [tabContent, setTabContent] = useState([]);

    useEffect(() => {
        if (context.aceSurveyReportData.qualitative.length !== 0) {
            let updatedTabList = ["What has worked well", "What would improve the experience"];

            context.aceSurveyReportData.qualitative[0].items.find(
                (item) => item.itemName !== "QUAL_BEST" && item.itemName !== "QUAL_WORST"
            ) && updatedTabList.push("Open-ended questions");

            let updatedTabContent = [];

            updatedTabContent = [
                {
                    content: (
                        <QualitativeItemSection
                            data={context.aceSurveyReportData.qualitative[0].items.find(
                                (reportDataItem) => reportDataItem.itemName === "QUAL_BEST"
                            )}
                            title="What has worked well"
                            noPrintStyles
                            onlyComments={onlyComments}
                            onlyThemes={onlyThemes}
                        />
                    ),
                },
                {
                    content: (
                        <QualitativeItemSection
                            data={context.aceSurveyReportData.qualitative[0].items.find(
                                (reportDataItem) => reportDataItem.itemName === "QUAL_WORST"
                            )}
                            title="What would improve the experience"
                            onlyComments={onlyComments}
                            onlyThemes={onlyThemes}
                        />
                    ),
                },
            ];

            context.aceSurveyReportData.qualitative[0].items.find(
                (item) => item.itemName !== "QUAL_BEST" && item.itemName !== "QUAL_WORST"
            ) &&
                updatedTabContent.push({
                    content: (
                        <div
                            className={
                                "flex flex-col gap-4 w-full " +
                                "print:p-0 print:border-0 print:shadow-none print:bg-white print:gap-0"
                            }
                        >
                            <div className="flex flex-col gap-4">
                                {context.aceSurveyReportData.qualitative[0].items.map(
                                    (item, index) =>
                                        item.itemName !== "QUAL_BEST" &&
                                        item.itemName !== "QUAL_WORST" && (
                                            <QualitativeItemSection
                                                key={index + "qualitativeItemSectionQualitative"}
                                                data={item}
                                                title={"Open-ended question #" + (index - 1)}
                                                onlyComments={onlyComments}
                                                onlyThemes={onlyThemes}
                                            />
                                        )
                                )}
                            </div>
                        </div>
                    ),
                });

            setTabList(updatedTabList);
            setTabContent(updatedTabContent);
        }
    }, [context.aceSurveyReportData, onlyComments, onlyThemes]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                    Open-ended questions
                </h2>
            </div>
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <TabbedNavigationLayout
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabList={tabList}
                    backgroundColour={"bg-white"}
                    tabContent={tabContent}
                    noContainer
                    displayOnlyContent={displayOnlyContent}
                    printGap="print:gap-0"
                    secondaryTabs
                />
            )}
        </div>
    );
}
