import { motion, AnimatePresence } from "framer-motion";

function BouncingBalls() {
    const bounceTransitionBall1 = {
        y: {
            duration: 0.4,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeOut",
        },
        backgroundColor: {
            duration: 0,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeOut",
            repeatDelay: 0.8,
        },
    };
    const bounceTransitionBall2 = {
        y: {
            duration: 0.5,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeOut",
        },

        backgroundColor: {
            duration: 0,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeOut",
            repeatDelay: 0.6,
        },
    };
    const bounceTransitionBall3 = {
        y: {
            duration: 0.6,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeOut",
        },
        backgroundColor: {
            duration: 0,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeOut",
            repeatDelay: 0.4,
        },
    };
    const ballStyle = {
        display: "flex",
        justifyContent: "space-around",
    };

    return (
        <AnimatePresence>
            <div className="flex flex-row gap-0">
                <motion.svg
                    className={"overflow-visible flex flex-row gap-1"}
                    width={111.5}
                    height={32}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <motion.rect
                        width={48}
                        height={48}
                        rx={64}
                        transition={bounceTransitionBall1}
                        animate={{
                            y: ["100%", "-25%"],
                            x: 0,
                        }}
                        className={"fill-primary-600"}
                        style={ballStyle}
                    />
                    <motion.rect
                        width={48}
                        height={48}
                        rx={64}
                        transition={bounceTransitionBall3}
                        animate={{
                            y: ["100%", "-25%"],
                            x: 32,
                        }}
                        className={"fill-primary-900"}
                        style={ballStyle}
                    />
                    <motion.rect
                        width={48}
                        height={48}
                        rx={64}
                        transition={bounceTransitionBall2}
                        animate={{
                            y: ["100%", "-25%"],
                            x: 64,
                        }}
                        className={"fill-primary-600"}
                        style={ballStyle}
                    />
                </motion.svg>
            </div>
        </AnimatePresence>
    );
}

export default BouncingBalls;
