import Tippy from "@tippyjs/react";
import { useState, useEffect, useCallback } from "react";
import Percentile from "./Percentile";

function Scale(props) {
    const { name, percentile, description, band_description, colour, colourPalette, scaleIndex } = props;

    const [percentileBackgroundColour, setPercentileBackgroundColour] = useState("bg-slate-300");

    const [textColor, setTextColor] = useState("text-slate-50");

    const handleSetColour = useCallback(async () => {
        setTimeout(() => {
            setPercentileBackgroundColour(
                colourPalette[scaleIndex]
                    ? `bg-[${colourPalette[scaleIndex]}]`
                    : `bg-[${colourPalette[colourPalette.length - 1]}]`
            );
            setTextColor(
                colourPalette[scaleIndex]
                    ? `text-[${colourPalette[scaleIndex]}]`
                    : `text-[${colourPalette[colourPalette.length - 1]}]`
            );
        }, 100);
    }, [colourPalette, scaleIndex]);

    useEffect(() => {
        handleSetColour(percentile);
    }, [handleSetColour, percentile]);

    return (
        <div>
            {description ? (
                <Tippy
                    content={description}
                    className={"p-2 shadow rounded-2xl bg-primary-500 text-white"}
                    arrow={false}
                    animation={"shift-away-subtle"}
                >
                    <h2
                        className={
                            "cursor-help transition transition-color duration-4000 ease-in-out font-medium " + textColor
                        }
                    >
                        {name}
                    </h2>
                </Tippy>
            ) : (
                <h2 className={"transition transition-color duration-4000 ease-in-out font-medium " + textColor}>
                    {name}
                </h2>
            )}

            <div className="mt-3 grid md:grid-cols-12 grid-flow-row gap-4">
                <div className={"col-span-2 flex items-center jusitfy-center"}>
                    <Percentile
                        size={"large"}
                        percentile={percentile}
                        colour={colour}
                        colourPalette={colourPalette}
                        scaleIndex={scaleIndex}
                    />
                </div>

                <div className={"col-span-10"}>
                    <div
                        className={
                            "transition transition-color duration-4000 p-3 rounded-xl shadow-sm text-white " +
                            percentileBackgroundColour
                        }
                    >
                        {band_description && <p className={"leading-relaxed text-sm"}>{band_description}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Scale;
