import { useEffect, useState } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../utils/constants";

function AddRemoveButton(props) {
    const { identifier, setValue, noPreviousAccessUser, accessDefaultToggleState, addButtonText, removeButtonText } =
        props;
    const [toggleOn, setToggleOn] = useState(false);

    useEffect(() => {
        setToggleOn(accessDefaultToggleState);
    }, [accessDefaultToggleState, identifier]);

    return (
        <div className={"w-24"}>
            <div
                className={
                    "cursor-pointer smallButton " +
                    (toggleOn ? BUTTON_VARIANT_TYPES.PRIMARY : BUTTON_VARIANT_TYPES.RED)
                }
                onClick={() => {
                    setValue(identifier, toggleOn, noPreviousAccessUser);
                    setToggleOn((toggleOn) => !toggleOn);
                }}
            >
                {toggleOn ? (
                    <>
                        <PlusIcon className="block h-3.5 w-3.5 mr-1" />
                        {addButtonText ? addButtonText : "Add"}
                    </>
                ) : (
                    <>
                        <XMarkIcon className="block h-3.5 w-3.5 mr-1" />
                        {removeButtonText ? removeButtonText : "Remove"}
                    </>
                )}
            </div>
        </div>
    );
}

export default AddRemoveButton;
