import { useCallback, useEffect, useState } from "react";
import { AtSymbolIcon, CakeIcon, DevicePhoneMobileIcon, PencilIcon, UserIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import { getAccountMediaFile } from "../../../../utils/api";

import Input from "../../../SharedComponents/Form/Input";
import Button from "../../../SharedComponents/Button";
import CircleLoadingPlaceholder from "../../../SharedComponents/Loading/CircleLoadingPlaceholder";
import BasicMotion from "../../../SharedComponents/Loading/Transitions/BasicMotion";

export default function PersonalDetails(props) {
    const { uploadProfilePhoto, personalDetails, setPersonalDetails, canEdit } = props;

    const [photo, setPhoto] = useState();

    const fetchPhotos = useCallback(async () => {
        const photoResponse = await Promise.all(
            personalDetails.mediaFiles
                .filter((mediaFile) => ["png", "jpg", "jpeg"].includes(mediaFile.fileType))
                .map(async (photo) => {
                    return { ...photo, content: (await getAccountMediaFile(photo.identifier)).content };
                })
        );
        setPhoto(photoResponse[0]);
    }, [personalDetails.mediaFiles]);

    useEffect(() => {
        if (personalDetails && personalDetails.mediaFiles && personalDetails.mediaFiles.length > 0) {
            fetchPhotos();
        }
    }, [fetchPhotos, personalDetails]);

    const updateProfileDetails = (key) => (value) => {
        setPersonalDetails((currProfileDetails) => {
            return { ...currProfileDetails, [key]: value };
        });
    };

    return (
        <div>
            <div className="flex flex-col lg:flex-row items-center lg:gap-8 mt-4">
                <div className={"relative flex-shrink-0"}>
                    <div className={"relative inline-block"}>
                        {photo ? (
                            <BasicMotion>
                                <img
                                    key={photo.identifier}
                                    className={
                                        "w-40 h-40 xl:w-[12vw] xl:h-[12vw] rounded-full bg-clip-border shadow-sm bg-slate-50"
                                    }
                                    src={"data:image/png;base64," + photo.content}
                                    alt={photo.name}
                                />
                            </BasicMotion>
                        ) : (
                            <CircleLoadingPlaceholder />
                        )}
                        {canEdit && (
                            <Button
                                colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                icon={<PencilIcon className="block h-7 w-7" />}
                                extraStyling={
                                    "absolute bottom-0 right-0 text-xs h-8 w-8 text-white rounded-full shadow-sm p-2"
                                }
                                iconPositionLeft
                                onClick={uploadProfilePhoto}
                            />
                        )}
                    </div>
                </div>
                <div className="flex-grow grid grid-flow-row-dense gap-6 w-full">
                    <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-6 lg:mt-0">
                        <Input
                            id="name"
                            type="text"
                            label="First name"
                            placeholder={"First name"}
                            icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                            value={personalDetails.firstName ? personalDetails.firstName : ""}
                            onChange={canEdit && updateProfileDetails("firstName")}
                            backgroundColour="bg-white"
                        />
                        <Input
                            id="name"
                            type="text"
                            label="Last name"
                            placeholder={"Last name"}
                            icon={<UserIcon className="h-4 w-4 text-slate-500" />}
                            value={personalDetails.lastName ? personalDetails.lastName : ""}
                            onChange={canEdit && updateProfileDetails("lastName")}
                            backgroundColour="bg-white"
                        />
                    </div>
                    <Input
                        id="email"
                        type="email"
                        label="Email address"
                        placeholder={"Email address"}
                        icon={<AtSymbolIcon className="h-4 w-4 text-slate-500" />}
                        value={personalDetails.emailAddress ? personalDetails.emailAddress : ""}
                        onChange={canEdit && updateProfileDetails("emailAddress")}
                        backgroundColour="bg-white"
                    />
                </div>
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-6 mt-6">
                <Input
                    id="dob"
                    type="date"
                    label="Date of birth"
                    placeholder={"Date of birth"}
                    icon={<CakeIcon className="h-4 w-4 text-slate-500" />}
                    value={personalDetails.dateOfBirth ? personalDetails.dateOfBirth.substring(0, 10) : ""}
                    onChange={canEdit && updateProfileDetails("dateOfBirth")}
                    backgroundColour="bg-white"
                />
                <Input
                    id="contact-number"
                    type="number"
                    label="Contact number"
                    placeholder={"Contact number"}
                    icon={<DevicePhoneMobileIcon className="h-4 w-4 text-slate-500" />}
                    value={personalDetails.mobileNumber ? personalDetails.mobileNumber : ""}
                    onChange={canEdit && updateProfileDetails("mobileNumber")}
                    backgroundColour="bg-white"
                />
            </div>
        </div>
    );
}
