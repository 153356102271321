import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../../../../context/Context";

import { getCohortUsers } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import CohortUserRow from "./CohortUserRow";

export default function CohortStatus(props) {
    const { cohort, updateCoacheeStatus, updateCohortStatus } = props;
    const context = useContext(Context);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [usersWithAccess, setUsersWithAccess] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bulkUpdate, setBulkUpdate] = useState(false);

    const fetchUsers = useCallback(async () => {
        setBulkUpdate(false);
        setLoading(true);

        try {
            const cohortUsers = await getCohortUsers(cohort.name);
            const sanitizedCohortUsers = cohortUsers.filter((user) => user?.isCoachee === true);
            setUsersWithAccess(sanitizedCohortUsers);
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }

        setLoading(false);
    }, [cohort.name]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers, bulkUpdate]);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"flex flex-col gap-6"}>
                <div>
                    <div className={"w-full mb-2"}>
                        <label className="text-sm font-medium text-primary-600 w-full ">
                            {"Bulk " + context.terminology.coachee.singular + " status update"}
                        </label>
                        <p className="mb-4 mt-2 text-xs text-slate-400">
                            {`This will change the status of all ${context.terminology.coachee.plural} within this cohort in a bulk update, to change
                            individual statuses please look at the table below`}
                        </p>
                    </div>

                    <div className={"flex flex-row gap-4"}>
                        <select
                            id="default"
                            name="default"
                            className={
                                "bg-white rounded-xl shadow-sm pl-3 pr-10 py-2 text-left " +
                                "focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent " +
                                "sm:text-sm text-slate-500 border border-slate-100"
                            }
                            onChange={(e) => {
                                updateCohortStatus(e.target.value);
                                setBulkUpdate(true);
                            }}
                        >
                            <option>Select bulk update status</option>
                            <option value={"Started"}>Started</option>
                            <option value={"Not Started"}>Not started</option>
                            <option value={"Completed"}>Completed</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className={"flex flex-col gap-6 mt-6"}>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-2">
                        {context.terminology.coachee.singular + " statuses"}
                    </div>

                    <p className="mb-4 mt-2 text-xs text-slate-400">
                        {context.terminology.coachee.plural +
                            " are required to be assigned to a " +
                            context.terminology.coach.singular +
                            " to update their status"}
                    </p>

                    <PaginatedSearch
                        loading={loading}
                        items={usersWithAccess}
                        itemName={context.terminology.coachee.plural}
                        searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                        columnNames={["User details", "Status"]}
                        ItemRow={CohortUserRow}
                        customItemsPerPage={8}
                        accessDefaultToggleState={false}
                        updateItemStatus={updateCoacheeStatus}
                    />
                </div>
            </div>
        </>
    );
}
