import React, { useCallback, useEffect, useState } from "react";

import { getCohortUsers, getOrganisationUsersLite } from "../../../../../utils/api";

import Alert, { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import CohortUserRow from "./CohortUserRow";

export default function CohortCoachees(props) {
    const { cohort, setCoacheeIdentifierList, coacheeIdentifierList } = props;
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [organisationUsers, setOrganisationUsers] = useState([]);
    const [usersWithAccess, setUsersWithAccess] = useState([]);
    const [loading, setLoading] = useState(true);
    const [latestUserInteractedAccess, setLatestUserInteractedAccess] = useState(true);
    const [latestUserInteractedNonAccess, setLatestUserInteractedNonAccess] = useState(true);

    const handleAddUserToggle = (userIdentifier, toggle, noPreviousAccessUser) => {
        let user;

        if (noPreviousAccessUser) {
            user = organisationUsers.find((organisationUser) => organisationUser.userIdentifier === userIdentifier);
        } else {
            user = usersWithAccess.find((userWithAccess) => userWithAccess.userIdentifier === userIdentifier);
        }

        if (toggle) {
            setUsersWithAccess((usersWithAccess) => [user, ...usersWithAccess]);
            setOrganisationUsers(
                organisationUsers.filter((organisationUser) => organisationUser.userIdentifier !== userIdentifier)
            );

            setCoacheeIdentifierList((usersWithAccess) => [...usersWithAccess, user.userIdentifier]);

            setLatestUserInteractedAccess(user);
        } else {
            setOrganisationUsers((organisationUsers) => [user, ...organisationUsers]);
            setUsersWithAccess(
                usersWithAccess.filter((userWithAccess) => userWithAccess.userIdentifier !== userIdentifier)
            );
            setCoacheeIdentifierList(
                coacheeIdentifierList.filter((userIdentifierNewAccess) => userIdentifierNewAccess !== userIdentifier)
            );
            setLatestUserInteractedNonAccess(user);
        }
    };

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const organisationUsersResponse = await getOrganisationUsersLite(true);
            const cohortUsers = await getCohortUsers(cohort.name);

            setUsersWithAccess(cohortUsers);

            setOrganisationUsers(
                organisationUsersResponse.filter(
                    (orgUser) =>
                        !cohortUsers.find((userWithAccess) => userWithAccess.userIdentifier === orgUser.userIdentifier)
                )
            );

            cohortUsers.map((user) =>
                setCoacheeIdentifierList((userIdentifiersNewAccess) => [
                    ...userIdentifiersNewAccess,
                    user.userIdentifier,
                ])
            );
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLoading(false);
    }, [cohort.name, setCoacheeIdentifierList]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"flex flex-col gap-6"}>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-2">Cohort members</div>

                    <PaginatedSearch
                        loading={loading}
                        items={usersWithAccess}
                        itemName="members"
                        searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                        columnNames={["User details", "Cohort member"]}
                        ItemRow={CohortUserRow}
                        itemRowToggleOnChange={handleAddUserToggle}
                        customItemsPerPage={4}
                        accessDefaultToggleState={false}
                        latestItemInteracted={latestUserInteractedAccess}
                    />
                </div>
                <div>
                    <div className="text-sm font-medium text-primary-600 w-full mb-4">{"Add more members"}</div>

                    <PaginatedSearch
                        loading={loading}
                        items={organisationUsers}
                        itemName={"other users"}
                        searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                        columnNames={["User details"]}
                        ItemRow={CohortUserRow}
                        itemRowToggleOnChange={handleAddUserToggle}
                        customItemsPerPage={8}
                        noPreviousAccessUser={true}
                        accessDefaultToggleState={true}
                        noTableHead
                        latestItemInteracted={latestUserInteractedNonAccess}
                    />
                </div>
            </div>
        </>
    );
}
