import { useContext, useEffect, useState } from "react";
import {
    CalendarIcon,
    ClockIcon,
    ArrowTopRightOnSquareIcon,
    PuzzlePieceIcon,
    FaceFrownIcon,
    UserIcon,
    BriefcaseIcon,
    AtSymbolIcon,
    InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
import Tippy from "@tippyjs/react";

import {
    ACE_2_SURVEY_PLATFORM,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    USER_GROUPS,
} from "../../../../utils/constants";
import { getAssessmentLaunchUrl, reAssignAssessment } from "../../../../utils/api";
import { Context } from "../../../../context/Context";

import Button from "../../../SharedComponents/Button";
import Tag from "../../../SharedComponents/Tag";
import BasicMotion from "../../../SharedComponents/Motion/BasicMotion";
import { ALERT_TYPE } from "../../../SharedComponents/Alert";

export default function AssessmentCard(props) {
    const { item: assessment, setAlert, setAlertType } = props;

    const [expired, setExpired] = useState(false);
    const [launching, setLaunching] = useState(false);

    const context = useContext(Context);

    const isCoachee = context.profileDetails.userGroups.includes(USER_GROUPS.COACHEE);
    const isCoach = context.profileDetails.userGroups.includes(USER_GROUPS.COACH);
    const isAdmin = context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ALL_COACHEES);
    const isAce2SurveyPlatform = context.defaultApp === ACE_2_SURVEY_PLATFORM;

    const timeLimit = assessment.isTimed
        ? `${parseInt(assessment.timeLimitSecs / 60, 10)} minutes`
        : `${assessment.minTimeMins} - ${assessment.maxTimeMins} minutes`;

    const launchAssessment = async () => {
        setLaunching(true);
        try {
            /*'md': '768px' & isMobile returns true if device type is mobile or tablet */
            if (window.innerWidth <= 768 || isMobile) {
                // Open link directly
                window.location.replace(await getAssessmentLaunchUrl(assessment.orderIdentifier));
            } else {
                // Open link in a new tab
                window.open(await getAssessmentLaunchUrl(assessment.orderIdentifier), "_blank");
            }
        } catch (err) {
            alert("Failed to start the assessment, please try again or contact support.");
        }
        setLaunching(false);
    };

    const launchReAssignAssessment = async () => {
        setLaunching(true);
        try {
            await reAssignAssessment(
                assessment.workflowIdentifier,
                assessment.orderIdentifier,
                null,
                assessment.userIdentifier
            );

            if (window.innerWidth <= 768 || isMobile) {
                window.location.replace(await getAssessmentLaunchUrl(assessment.orderIdentifier));
            } else {
                window.open(await getAssessmentLaunchUrl(assessment.orderIdentifier), "_blank");
            }
        } catch (err) {
            setAlert(err.message);
            setAlertType(ALERT_TYPE.ERROR);
        }
        setLaunching(false);
    };

    useEffect(() => {
        setExpired(assessment.dueDate !== null && Date.now() >= new Date(assessment.dueDate));
    }, [assessment.dueDate]);

    return (
        <BasicMotion>
            <div
                className={
                    "bg-white shadow-sm w-full h-full rounded-xl overflow-hidden flex flex-col border " +
                    "border-slate-100 border-1 justify-between"
                }
            >
                <div className="p-4 grid grid-flow-row gap-4">
                    {!isAce2SurveyPlatform && (
                        <div className="flex flex-wrap gap-5 justify-between items-center">
                            <img className="h-7 justify-self-start" src={assessment.logoUrl} alt="logo" />
                            {(!isCoachee || isAdmin || isCoach) && assessment.onBehalfOfUserIdentifier ? (
                                <Tag
                                    status={"Organisation member"}
                                    backgroundColour="bg-secondary-100"
                                    textColour="text-secondary-600"
                                />
                            ) : (
                                (!isCoachee || isAdmin || isCoach) && (
                                    <Tag
                                        status={"Yours"}
                                        textColour={"text-slate-600"}
                                        backgroundColour={"bg-slate-200"}
                                    />
                                )
                            )}
                        </div>
                    )}
                    <div className="flex flex-col gap-2">
                        <div
                            className={
                                "font-title text-base font-medium tracking-tight text-" +
                                (assessment.themeColour + "-800")
                            }
                        >
                            {assessment.name}
                        </div>
                    </div>
                    {assessment.overview && (
                        <div className="place-self-start">
                            <BasicMotion>
                                <Tippy
                                    content={
                                        <div className="flex flex-col gap-2 leading-relaxed">
                                            <div className="flex items-center justify-between">
                                                <div className="font-medium text-base">{assessment.name}</div>
                                                <InformationCircleIcon
                                                    className={"block h-5 w-5 text-blue-600 fill-blue-600"}
                                                />
                                            </div>
                                            <p className="max-h-40 overflow-y-scroll break-words">
                                                {assessment.overview}
                                            </p>
                                        </div>
                                    }
                                    className={
                                        "py-1.5 px-2 shadow-lg rounded-xl text-sm bg-blue-50 text-blue-600 border border-blue-100"
                                    }
                                    arrow={false}
                                    animation={"shift-away-subtle"}
                                    placement={isMobile ? "bottom-start" : "right"}
                                    interactive
                                    trigger={isMobile ? "click" : "mouseenter focus"}
                                    hideOnClick={true}
                                >
                                    <div className="tag flex flex-row gap-1 bg-blue-100 text-blue-600 items-center justify-center cursor-help place-self-bottom">
                                        <InformationCircleIcon
                                            className={"block h-4 w-4 text-blue-600 fill-blue-600"}
                                        />
                                        Assessment overview
                                    </div>
                                </Tippy>
                            </BasicMotion>
                        </div>
                    )}
                </div>
                <div className="bg-slate-50 p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    <dt className="text-sm font-medium text-slate-500 flex items-center gap-2">
                        <ClockIcon className={"block h-4 w-4 shrink-0"} />
                        Duration
                    </dt>
                    <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-1 md:col-span-2 lg:col-span-2 sm:ml-3 md:ml-5">
                        {assessment.minTimeMins === 0 && assessment.maxTimeMins === 0 && assessment.timeLimitSecs === 0
                            ? ""
                            : timeLimit + ", "}
                        <span className="font-medium">
                            {assessment.isTimed
                                ? "timed"
                                : assessment.minTimeMins === 0 &&
                                  assessment.maxTimeMins === 0 &&
                                  assessment.timeLimitSecs === 0
                                ? "Untimed"
                                : "untimed"}
                        </span>
                    </dd>
                </div>
                {!isAce2SurveyPlatform && (
                    <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-slate-500 flex items-center gap-2">
                            <PuzzlePieceIcon className={"block h-4 w-4 shrink-0"} />
                            <p>
                                Type<span className="text-xs">(s)</span>
                            </p>
                        </dt>

                        <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-1 md:col-span-2 lg:col-span-2 sm:ml-3 md:ml-5">
                            {assessment.type}
                        </dd>
                    </div>
                )}
                {assessment.isCompleted ? (
                    <div
                        className={
                            (isAce2SurveyPlatform ? "" : "bg-slate-50 ") +
                            "p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4"
                        }
                    >
                        <dt className="text-sm font-medium text-slate-500 flex items-center gap-2 -mr-5">
                            <CalendarIcon className="block h-4 w-4 shrink-0" />
                            Completed
                        </dt>

                        <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-1 md:col-span-2 lg:col-span-2 sm:ml-3 md:ml-5">
                            {assessment.lastModifiedAt == null
                                ? "-"
                                : dayjs(new Date(assessment.lastModifiedAt)).format("DD/MM/YYYY")}
                        </dd>
                    </div>
                ) : (
                    <div
                        className={
                            (isAce2SurveyPlatform ? "" : "bg-slate-50 ") +
                            "p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4"
                        }
                    >
                        <dt className="text-sm font-medium text-slate-500 flex items-center gap-2 -mr-5">
                            <CalendarIcon className="block h-4 w-4 shrink-0" />
                            {expired ? "Expired" : "Due date"}
                        </dt>

                        <dd className="mt-1 text-sm text-slate-500 sm:mt-0 sm:col-span-1 md:col-span-2 lg:col-span-2 sm:ml-3 md:ml-5">
                            {assessment.dueDate == null
                                ? "-"
                                : dayjs(new Date(assessment.dueDate)).format(
                                      expired ? "DD/MM/YYYY HH:mm A" : "DD/MM/YYYY HH:mm A"
                                  )}
                        </dd>
                    </div>
                )}
                {!isAce2SurveyPlatform && (!isCoachee || isAdmin || isCoach) && (
                    <div className="p-4 flex flex-col gap-2 h-max">
                        <dt className="text-sm font-medium text-slate-500 flex items-center gap-2 -mr-5">
                            {context.defaultApp === "Employment Services" ? (
                                <BriefcaseIcon className="block h-4 w-4 shrink-0 text-slate-500" />
                            ) : (
                                <UserIcon className="block h-4 w-4 shrink-0 text-slate-500" />
                            )}
                            Complete{assessment.isCompleted ? "d" : ""} on behalf of:
                        </dt>
                        {assessment.onBehalfOfUserIdentifier ? (
                            <dd className="mt-1 text-sm text-secondary-600 p-1.5 bg-secondary-50 border border-secondary-100 rounded-lg">
                                <div className="flex flex-col gap-0.5">
                                    {assessment.onBehalfOfUserFirstName && assessment.onBehalfOfUserLastName && (
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <UserIcon className="block h-3.5 w-3.5 shrink-0" />
                                            <span className="font-medium ">
                                                {`${assessment?.onBehalfOfUserFirstName} ${assessment?.onBehalfOfUserLastName}`}
                                            </span>
                                        </div>
                                    )}
                                    {assessment.onBehalfOfUserEmailAddress && (
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <AtSymbolIcon className="block h-3.5 w-3.5 shrink-0 stroke-current stroke-1" />
                                            <span className="truncate">{assessment?.onBehalfOfUserEmailAddress}</span>
                                        </div>
                                    )}
                                </div>
                            </dd>
                        ) : (
                            <dd className="mt-1 text-sm sm:ml-5 md:ml-6">-</dd>
                        )}
                    </div>
                )}
                <div
                    className={
                        "flex flex-col gap-4 grow-0 w-full " +
                        (assessment.isCompleted
                            ? "bg-green-50 p-4 justify-center"
                            : expired
                            ? "bg-red-50 p-4 justify-center"
                            : "p-3 justify-end place-items-end")
                    }
                >
                    {assessment.isCompleted ? (
                        <div className="flex items-center justify-between text-sm text-emerald-600 font-medium">
                            <div className={"tag text-emerald-600 bg-emerald-100"}>Completed</div>
                            {assessment.isAssessmentEditable &&
                                context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.RE_ASSIGN_ASSESSMENT) && (
                                    <Button
                                        onClick={launchReAssignAssessment}
                                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                        text={"Edit answers"}
                                        icon={
                                            <ArrowTopRightOnSquareIcon
                                                className={"block stroke-current stroke-1 h-3 w-3 ml-1"}
                                            />
                                        }
                                        loading={launching}
                                        smallButton
                                        loadingIconSize="mr-1.5 h-3 w-3"
                                    />
                                )}
                        </div>
                    ) : expired ? (
                        <div className="flex items-center text-sm text-red-600 font-medium">
                            <div className={"tag text-red-600 bg-red-100"}>Expired</div>
                        </div>
                    ) : assessment.orderIdentifier === null ? (
                        <div className="flex items-center text-sm text-red-600 font-medium">
                            <FaceFrownIcon className={"block h-4 w-4 mr-2"} />
                            There was an error, please contact techsupport
                        </div>
                    ) : (
                        <Button
                            onClick={launchAssessment}
                            colour={BUTTON_VARIANT_TYPES.UNCOLOURED_BUTTON}
                            text={isAce2SurveyPlatform ? "Start survey" : "Start assessment"}
                            extraStyling={
                                "w-full grow-0 shadow-sm flex items-center justify-center place-self-end bg-" +
                                (assessment.themeColour + "-600")
                            }
                            icon={
                                <ArrowTopRightOnSquareIcon
                                    className={"block stroke-current stroke-1  h-3.5 w-3.5 ml-1"}
                                />
                            }
                            loading={launching}
                        />
                    )}
                </div>
            </div>
        </BasicMotion>
    );
}
