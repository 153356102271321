import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import {
    ArrowsUpDownIcon,
    UserIcon,
    ArrowDownTrayIcon,
    ArrowUpTrayIcon,
    CogIcon,
    FlagIcon,
    CheckIcon,
    ChevronRightIcon,
    InformationCircleIcon,
} from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away-subtle.css";
import mime from "mime";
import { AnimatePresence, motion } from "framer-motion";

import {
    BASIC_ANIMATION_VARIANTS,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
    EMPLOYMENT_SERVICES,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";
import { downloadLatestReportForAssessment, getAssessmentsAndReport } from "../../../../utils/api";
import userCheckIcon from "../../../../assets/user-check-white.svg";

import Button from "../../../SharedComponents/Button";
import AssessmentItem from "../../../SharedComponents/AssessmentItem";
import Tag from "../../../SharedComponents/Tag";
import BasicMotion from "../../../SharedComponents/Motion/BasicMotion";
import BouncingBalls from "../../../SharedComponents/Loading/BouncingBalls";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";

export default function JobSeekerRow(props) {
    const { item: coachee, setAlert } = props;

    const [showAssessments, setShowAssessments] = useState(false);
    const [assessmentsLoading, setAssessmentsLoading] = useState(false);
    const [assessmentAlert, setAssessmentAlert] = useState("");
    const [assessmentAlertType, setAssessmentAlertType] = useState(ALERT_TYPE.ERROR);

    const context = useContext(Context);

    const navigate = useNavigate();

    const [downloading, setDownloading] = useState(false);

    const handleDownload = async (documentIdentifier) => {
        setDownloading(true);

        // Permission needs to be changed in the future.
        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.DOWNLOAD_REPORTS)) {
            setAlert("You do not have the necessary permission to download a resume");
            setDownloading(false);
            return;
        }

        const documentResponse = await downloadLatestReportForAssessment(documentIdentifier);

        const { fileType } = documentResponse;

        if (!fileType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const blob = await (await fetch(`data:${mimeType};base64,${documentResponse.content}`)).blob();

        if (!blob) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const downloadLink = document.createElement("a");

        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = documentResponse.fileName.split(".").join("_");
        downloadLink.click();

        setDownloading(false);
    };

    const fetchAssessmentsAndReport = async () => {
        setAssessmentsLoading(true);

        try {
            let response = await getAssessmentsAndReport(coachee.userIdentifier);

            response.sort(function (x, y) {
                return x.isCompleted === y.isCompleted ? 0 : x.isCompleted ? 1 : -1;
            });

            coachee.userAssessmentsReports = response;
        } catch (err) {
            setAssessmentAlert(err.message);
            setAssessmentAlertType(ALERT_TYPE.ERROR);
        }

        setAssessmentsLoading(false);
    };

    const handleShowAssessments = () => {
        setShowAssessments((showAssessments) => !showAssessments);

        if (!showAssessments) {
            fetchAssessmentsAndReport();
        }
    };

    return (
        <>
            <tr className="group overflow-visible">
                <td className="px-4 py-3 whitespace-nowrap">
                    {context.defaultApp === EMPLOYMENT_SERVICES ? (
                        coachee.resume ? (
                            <BasicMotion>
                                <Tippy
                                    content={
                                        <div className="flex flex-col gap-2">
                                            {context.terminology.coachee.singular + " has no flags."}
                                        </div>
                                    }
                                    className={
                                        "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-green-100 text-green-600"
                                    }
                                    arrow={false}
                                    animation={"shift-away-subtle"}
                                    placement={"right"}
                                    interactive
                                >
                                    <div className="flex items-center justify-center">
                                        <div
                                            className={
                                                "rounded-full flex items-center justify-center bg-green-100 h-8 w-8 " +
                                                "hover:bg-green-50 transition-colors"
                                            }
                                        >
                                            <CheckIcon
                                                className={"block h-3.5 w-3.5 text-green-600 stroke-current stroke-2"}
                                            />
                                        </div>
                                    </div>
                                </Tippy>
                            </BasicMotion>
                        ) : (
                            <BasicMotion>
                                <Tippy
                                    content={
                                        <div className="flex flex-col gap-2">
                                            <div className="font-bold text-sm">No resume</div>
                                            {context.terminology.coachee.singular +
                                                " has no resume associated with their account."}
                                        </div>
                                    }
                                    className={
                                        "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-red-100 text-red-600"
                                    }
                                    arrow={false}
                                    animation={"shift-away-subtle"}
                                    placement={"right"}
                                >
                                    <div className="flex items-center justify-center">
                                        <div
                                            className={
                                                "rounded-full flex items-center justify-center bg-red-100 h-8 w-8 " +
                                                "hover:bg-red-50 group transition-colors"
                                            }
                                        >
                                            <FlagIcon className={"block h-3.5 w-3.5 text-red-600 transition-colors"} />
                                        </div>
                                    </div>
                                </Tippy>
                            </BasicMotion>
                        )
                    ) : coachee.coachIdentifier ? (
                        <BasicMotion>
                            <Tippy
                                content={
                                    <div className="flex flex-col gap-2">
                                        {context.terminology.coachee.singular + " has no flags."}
                                    </div>
                                }
                                className={
                                    "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-green-100 text-green-600"
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"right"}
                                interactive
                            >
                                <div className="flex items-center justify-center">
                                    <div
                                        className={
                                            "rounded-full flex items-center justify-center bg-green-100 h-8 w-8 " +
                                            "hover:bg-green-50 transition-colors"
                                        }
                                    >
                                        <CheckIcon
                                            className={"block h-3.5 w-3.5 text-green-600 stroke-current stroke-2"}
                                        />
                                    </div>
                                </div>
                            </Tippy>
                        </BasicMotion>
                    ) : (
                        <BasicMotion>
                            <Tippy
                                content={
                                    <div className="flex flex-col gap-2">
                                        <div className="font-bold text-sm">{`No ${context.terminology.coach.singular.toLowerCase()} assigned`}</div>
                                        {"The " +
                                            context.terminology.coachee.singular.toLowerCase() +
                                            " has not been assigned to a " +
                                            context.terminology.coach.singular.toLowerCase()}
                                    </div>
                                }
                                className={"py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-red-100 text-red-600"}
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"right"}
                            >
                                <div className="flex items-center justify-center">
                                    <div
                                        className={
                                            "rounded-full flex items-center justify-center bg-red-100 h-8 w-8 " +
                                            "hover:bg-red-50 group transition-colors"
                                        }
                                    >
                                        <FlagIcon className={"block h-3.5 w-3.5 text-red-600 transition-colors"} />
                                    </div>
                                </div>
                            </Tippy>
                        </BasicMotion>
                    )}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex flex-col gap-1">
                        <div className="text-sm font-medium text-primary-600">
                            {coachee.firstName + " " + coachee.lastName}
                        </div>
                        <div className="text-sm text-slate-500 group-hover:text-primary-600 transition-colors">
                            {coachee.emailAddress}
                        </div>
                        {coachee.jobSeekerId && (
                            <div className="text-xs text-secondary-600 ">
                                JSID: <span className="font-medium">{coachee.jobSeekerId}</span>
                            </div>
                        )}
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap max-w-xs">
                    <div className="flex flex-wrap gap-2">
                        {coachee?.cohorts && coachee.cohorts.length > 0 ? (
                            coachee.cohorts.map((cohort, index) => <Tag key={index} status={cohort} />)
                        ) : (
                            <Tag />
                        )}
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex flex-row gap-3 items-center">
                        <div className="text-xs text-slate-500">
                            {coachee.coachIdentifier ? (
                                <div className="flex items-center justify-between gap-2">
                                    {context?.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH) ? (
                                        <Tippy
                                            content={"Re-assign " + context.terminology.coach.singular.toLowerCase()}
                                            className={
                                                "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                                "bg-primary-600 text-primary-50"
                                            }
                                            arrow={false}
                                            animation={"shift-away-subtle"}
                                            placement={"left"}
                                        >
                                            <span
                                                className={
                                                    "p-1 bg-primary-600 rounded-full cursor-pointer hover:bg-primary-700 " +
                                                    "transition-colors cursor-pointer"
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(
                                                        `/assign-${context.terminology.coach.singular.toLowerCase()}`,
                                                        {
                                                            state: {
                                                                coacheeUserIdentifier: coachee.userIdentifier,
                                                                coachUserIdentifier: coachee.coachIdentifier,
                                                                coacheeFirstName: coachee.firstName,
                                                                coacheeLastName: coachee.lastName,
                                                                coacheeEmailAddress: coachee.emailAddress,
                                                                reassignCoach: true,
                                                            },
                                                        }
                                                    );
                                                }}
                                            >
                                                <ArrowsUpDownIcon
                                                    className={
                                                        "stroke-current stroke-1 text-white transition-colors h-3 w-3 shrink-0 flex"
                                                    }
                                                />
                                            </span>
                                        </Tippy>
                                    ) : (
                                        coachee.coachIdentifier === context.profileDetails.userIdentifier && (
                                            <Tippy
                                                content={"You"}
                                                className={
                                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                                    "bg-primary-600 text-primary-50"
                                                }
                                                arrow={false}
                                                animation={"shift-away-subtle"}
                                                placement={"left"}
                                            >
                                                <span
                                                    className={
                                                        "p-1 bg-primary-100 rounded-full hover:bg-primary-200 " +
                                                        "transition-colors"
                                                    }
                                                >
                                                    <UserIcon
                                                        className={
                                                            "text-primary-600 hover:text-primary-700 transition-colors h-3.5 w-3.5"
                                                        }
                                                    />
                                                </span>
                                            </Tippy>
                                        )
                                    )}
                                    <span className={"text-sm font-medium text-slate-500"}>{coachee.coachName}</span>
                                </div>
                            ) : (
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                    text={`Assign ${context.terminology.coach.singular.toLowerCase()}`}
                                    icon={<img src={userCheckIcon} className="h-4 w-4 mr-1.5" alt="resume builder" />}
                                    extraStyling={"text-xs text-white"}
                                    iconPositionLeft
                                    onClick={() =>
                                        navigate(`/assign-${context.terminology.coach.singular.toLowerCase()}`, {
                                            state: {
                                                coacheeUserIdentifier: coachee.userIdentifier,
                                                coacheeFirstName: coachee.firstName,
                                                coacheeLastName: coachee.lastName,
                                                coacheeEmailAddress: coachee.emailAddress,
                                            },
                                        })
                                    }
                                    smallButton
                                    disabled={
                                        !context?.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        icon={<CogIcon className="shrink-0 block h-5 w-5" />}
                        extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full p-2"}
                        iconPositionLeft
                        onClick={() => {
                            navigate(`/${context.terminology.coachee.singular.toLowerCase()}-profile`, {
                                state: {
                                    userIdentifier: coachee.userIdentifier,
                                },
                            });
                        }}
                    />
                </td>
                {context.defaultApp === EMPLOYMENT_SERVICES && (
                    <td className="px-4 py-3 whitespace-nowrap">
                        {coachee.resume ? (
                            <div className="flex flex-row items-start gap-3">
                                <Tippy
                                    content={"Download resume"}
                                    className={
                                        "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                        "bg-secondary-600 text-secondary-50"
                                    }
                                    arrow={false}
                                    animation={"shift-away-subtle"}
                                    placement={"left"}
                                >
                                    <div className="flex flex-row items-center gap-4">
                                        <Button
                                            colour={
                                                context.isNativeApps
                                                    ? BUTTON_VARIANT_TYPES.SECONDARY
                                                    : BUTTON_VARIANT_TYPES.DARK_SECONDARY
                                            }
                                            icon={
                                                <ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5" />
                                            }
                                            extraStyling={
                                                "flex items-center justify-center text-xs h-8 w-8 text-white rounded-full " +
                                                "shadow-2xl"
                                            }
                                            iconPositionLeft
                                            smallButton
                                            onClick={() => handleDownload(coachee.resume.identifier)}
                                            loading={downloading}
                                            loadingIconSize={"h-3.5 w-3.5"}
                                        />
                                    </div>
                                </Tippy>
                                {context.userPermissions.includes(
                                    COACH_PLATFORM_PERMISSIONS.UPLOAD_DOCUMENT_FOR_OTHER_USER
                                ) && (
                                    <Tippy
                                        content={
                                            "Re-upload resume. Note: This will replace the downloadable resume. To access " +
                                            "previous resumes please contact Psych Press."
                                        }
                                        className={
                                            "py-1.5 px-2 shadow rounded-xl text-xs font-medium bg-red-600 text-red-50"
                                        }
                                        arrow={false}
                                        animation={"shift-away-subtle"}
                                        placement={"top-start"}
                                    >
                                        <div>
                                            <Button
                                                colour={BUTTON_VARIANT_TYPES.GRAY_DARKER}
                                                icon={
                                                    <ArrowUpTrayIcon className="block stroke-current stroke-1  h-3.5 w-3.5" />
                                                }
                                                extraStyling={
                                                    "flex items-center justify-center text-xs h-8 w-8 rounded-full"
                                                }
                                                iconPositionLeft
                                                smallButton
                                                onClick={() =>
                                                    navigate("/upload-resume", {
                                                        state: { coacheeIdentifier: coachee.userIdentifier },
                                                    })
                                                }
                                                loadingIconSize={"h-3.5 w-3.5 mr-1"}
                                            />
                                        </div>
                                    </Tippy>
                                )}
                            </div>
                        ) : context.userPermissions.includes(
                              COACH_PLATFORM_PERMISSIONS.UPLOAD_DOCUMENT_FOR_OTHER_USER
                          ) ? (
                            <Tippy
                                content={"Upload a resume"}
                                className={
                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 text-primary-50"
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"left"}
                            >
                                <div className="w-max">
                                    <Button
                                        colour={
                                            context.isNativeApps
                                                ? BUTTON_VARIANT_TYPES.PRIMARY
                                                : BUTTON_VARIANT_TYPES.DARK_PRIMARY
                                        }
                                        icon={
                                            <ArrowUpTrayIcon className="block stroke-current stroke-1  h-3.5 w-3.5" />
                                        }
                                        extraStyling={
                                            "flex items-center justify-center text-xs h-8 w-8 text-white rounded-full"
                                        }
                                        iconPositionLeft
                                        smallButton
                                        onClick={() =>
                                            navigate("/upload-resume", {
                                                state: { coacheeIdentifier: coachee.userIdentifier },
                                            })
                                        }
                                        loadingIconSize={"h-3.5 w-3.5 mr-1"}
                                    />
                                </div>
                            </Tippy>
                        ) : (
                            <Tippy
                                content={"No permission found to upload a resume"}
                                className={
                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 text-primary-50"
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"right"}
                            >
                                <div className="w-max">
                                    <Button
                                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                        icon={
                                            <ArrowUpTrayIcon className="block stroke-current stroke-1  h-3.5 w-3.5" />
                                        }
                                        extraStyling={
                                            "flex items-center justify-center text-xs h-8 w-8 text-white rounded-full"
                                        }
                                        iconPositionLeft
                                        smallButton
                                        disabled={true}
                                        loadingIconSize={"h-3.5 w-3.5 mr-1"}
                                    />
                                </div>
                            </Tippy>
                        )}
                    </td>
                )}
                <td className="px-4 py-3 rounded-br-xl">
                    <div className={"flex flex-row items-center"}>
                        <Button
                            colour={
                                context.isNativeApps
                                    ? BUTTON_VARIANT_TYPES.SECONDARY
                                    : BUTTON_VARIANT_TYPES.DARK_SECONDARY
                            }
                            icon={
                                <ChevronRightIcon
                                    className={
                                        "h-4 w-4 stroke-current stroke-1 transition-transform transform " +
                                        (showAssessments && "rotate-90")
                                    }
                                />
                            }
                            extraStyling={"text-xs h-8 w-8 rounded-full p-0 items-center flex justify-center"}
                            iconPositionLeft
                            onClick={handleShowAssessments}
                        />
                    </div>
                </td>
            </tr>
            <AnimatePresence>
                {showAssessments && (
                    <tr className={"transition-colors"}>
                        <td className="bg-slate-100" colSpan={"100%"}>
                            <motion.div
                                initial={"inactiveHeight"}
                                animate={"activeHeight"}
                                exit={"inactiveHeight"}
                                transition={{ duration: 0.4 }}
                                variants={BASIC_ANIMATION_VARIANTS}
                            >
                                {assessmentsLoading ? (
                                    <div className={"flex items-center justify-center h-96"}>
                                        <BouncingBalls />
                                    </div>
                                ) : (
                                    <motion.section
                                        initial="collapsed"
                                        animate="expand"
                                        exit="collapsed"
                                        variants={{
                                            expand: { opacity: 1 },
                                            collapsed: { opacity: 0 },
                                        }}
                                        transition={{ duration: 0.4 }}
                                        className={"p-4"}
                                    >
                                        <Alert
                                            text={assessmentAlert}
                                            type={assessmentAlertType}
                                            active={assessmentAlert}
                                            close={() => setAssessmentAlert("")}
                                        />

                                        <table className="text-left w-full rounded-xl bg-white shadow-sm">
                                            <thead className="bg-slate-50 flex w-full rounded-t-xl items-center">
                                                <tr className="flex w-full text-left text-xs text-slate-500 uppercase">
                                                    <th
                                                        className="p-3 w-1/3 font-medium"
                                                        key={"assessmentName"}
                                                        scope={"col"}
                                                    >
                                                        Assessment name
                                                    </th>
                                                    <th className="p-3 w-1/3 font-medium" key={"status"} scope={"col"}>
                                                        Status
                                                    </th>
                                                    <th className="p-3 w-1/3 font-medium" key={"Report"} scope={"col"}>
                                                        Report
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="flex flex-col items-center justify-between overflow-y-auto w-full max-h-[170px] rounded-b-xl">
                                                {coachee.userAssessmentsReports ? (
                                                    coachee.userAssessmentsReports.length > 0 ? (
                                                        coachee.userAssessmentsReports.map((assessment, index) => (
                                                            <AssessmentItem
                                                                key={index}
                                                                name={assessment.assessmentName}
                                                                status={
                                                                    assessment.isCompleted ? "Completed" : "Not Started"
                                                                }
                                                                documentIdentifier={
                                                                    assessment.assessmentReports == null
                                                                        ? null
                                                                        : assessment.assessmentReports[0].identifier
                                                                }
                                                                fileType={
                                                                    assessment.assessmentReports == null
                                                                        ? null
                                                                        : assessment.assessmentReports[0]
                                                                              .documentVersions[0].fileFormat
                                                                }
                                                                userName={coachee.firstName + " " + coachee.lastName}
                                                                setAlert={setAlert}
                                                                isAssessmentCompletedOnBehalf={
                                                                    assessment.isAssessmentCompletedOnBehalf
                                                                }
                                                                isCompleted={assessment.isCompleted}
                                                                lastItem={
                                                                    coachee.userAssessmentsReports.length - 1 === index
                                                                }
                                                            />
                                                        ))
                                                    ) : (
                                                        <tr className="flex w-full">
                                                            <div className="text-xs text-slate-400 italic p-4">
                                                                No assessments found
                                                            </div>
                                                        </tr>
                                                    )
                                                ) : (
                                                    <tr className="flex w-full">
                                                        <div className="text-xs text-slate-400 italic p-4">
                                                            Unavailable
                                                        </div>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {coachee.userAssessmentsReports && coachee.userAssessmentsReports.length > 3 && (
                                            <div className={"flex flex-row gap-1 text-xs mt-3"}>
                                                <InformationCircleIcon className="h-4 w-4 text-slate-400" />
                                                <div className="text-xs text-slate-400">
                                                    Tip: scroll or use the scrollbar on the right to view more
                                                    assessments.
                                                </div>
                                            </div>
                                        )}
                                    </motion.section>
                                )}
                            </motion.div>
                        </td>
                    </tr>
                )}
            </AnimatePresence>
        </>
    );
}
