import { useNavigate } from "react-router-dom";

import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";
import Button from "../../../SharedComponents/Button";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";

export default function PageSlide(props) {
    const { title, description, pageHref, icon, fullScreen } = props;

    const navigate = useNavigate();

    const handleGoToPage = () => {
        navigate(pageHref);
    };
    return (
        <div className={"w-full flex-none flex flex-col mt-8 lg:mt-0 min-w-full " + fullScreen ? "" : "absolute"}>
            <div className={"flex items-center"}>
                {icon}
                <div className="text-lg font-medium tracking-tight text-primary-600 ">{title}</div>
            </div>
            <div className={"text-slate-500 mt-3"}>{description}</div>

            {pageHref && (
                <Button
                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                    text={"Go to " + title}
                    icon={<ArrowLongRightIcon className="block h-5 w-5 mr-2" />}
                    extraStyling={"mt-6"}
                    onClick={handleGoToPage}
                />
            )}
        </div>
    );
}
