import Upload from "../../../SharedComponents/Form/Upload";

export default function UploadPhoto({ mediaFile, setMediaFile }) {
    return (
        <Upload
            files={mediaFile}
            setFiles={setMediaFile}
            infoTextClick={"Click to choose photo"}
            infoTextSecondary={"or drag and drop photo to upload"}
            noFilesUploadedText={"You haven't uploaded a photo yet"}
            instructionText={"Only JPEG and PNG formats accepted, maximum 5MB"}
            formats={"image/jpeg, image/png"}
        />
    );
}
