export default function ResponseCard(props) {
    const { item: response } = props;

    return (
        <div
            className={
                "break-inside-avoid-column w-full mb-4 text-sm text-slate-500 " +
                "bg-slate-50 border border-slate-100 rounded-xl p-2"
            }
        >
            "{response.text}"
        </div>
    );
}
