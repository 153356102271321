import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const data = [
    {
        name: "2018",
        scale_1: 2000,
        scale_2: 2400,
        scale_3: 1049,
        scale_4: 3495,
        amt: 2400,
    },
    {
        name: "2019",
        scale_1: 2500,
        scale_2: 1948,
        scale_3: 3784,
        scale_4: 2927,
        amt: 2000,
    },
    {
        name: "2020",
        scale_1: 1208,
        scale_2: 2636,
        scale_3: 4000,
        scale_4: 2526,
        amt: 2200,
    },
    {
        name: "2021",
        scale_1: 1208,
        scale_2: 2636,
        scale_3: 4000,
        scale_4: 2526,
        amt: 2200,
    },
];

export default function BarGraph() {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{
                    top: 30,
                    right: 15,
                    bottom: 15,
                    left: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" strokeDasharray="3 3" stroke="#CBD5E1" />
                <YAxis strokeDasharray="3 3" stroke="#CBD5E1" />
                <Tooltip />
                <Bar dataKey="scale_1" fill="#0891B2" radius={[8, 8, 0, 0]} />
                <Bar dataKey="scale_2" fill="#2563EB" radius={[8, 8, 0, 0]} />
                <Bar dataKey="scale_3" fill="#4F46E5" radius={[8, 8, 0, 0]} />
                <Bar dataKey="scale_4" fill="#7C3AED" radius={[8, 8, 0, 0]} />
            </BarChart>
        </ResponsiveContainer>
    );
}
