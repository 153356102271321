import { useCallback, useContext, useEffect, useState } from "react";

import useSearch from "../../../../utils/react-hook-search/dist";
import { getOrganisationStructure } from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS, USER_GROUPS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import TreeLink from "./TreeLink";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import MemberCard from "./MemberCard";

export default function Groups() {
    const [groupMembers, setGroupMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [organisationStructure, setOrganisationStructure] = useState([]);
    const [sanitizedUserGroups, setSantizedUserGroups] = useState([]);
    const [currentGroupChoice, setCurrentGroupChoice] = useState();

    const context = useContext(Context);

    const fetchOrganisationStructure = async () => {
        setLoading(true);
        try {
            const response = await getOrganisationStructure();
            setOrganisationStructure(response);
        } catch {
            setOrganisationStructure(null);
        }
        setLoading(false);
    };

    const TreeLinks = ({ groups, parent }) => {
        return (
            <>
                {groups.map((group, index) => {
                    return (
                        <TreeLink
                            key={index}
                            name={group.name}
                            parent={parent}
                            members={group.userIdentifiers}
                            setGroupMembers={setGroupMembers}
                            currentGroupChoice={currentGroupChoice}
                            setCurrentGroupChoice={setCurrentGroupChoice}
                        >
                            {group.childGroups && <TreeLinks groups={group.childGroups} />}
                        </TreeLink>
                    );
                })}
            </>
        );
    };

    let [filteredItems, search, setSearch] = useSearch(sanitizedUserGroups, ["name"]);

    const sanitizedUserGroup = useCallback(() => {
        let result = [];
        if (organisationStructure.userGroups) {
            for (const userGroup of organisationStructure.userGroups) {
                let userGroupObject = { ...userGroup };

                if (userGroup.name === USER_GROUPS.COACH) {
                    userGroupObject.name = context.terminology.coach.plural;
                }
                if (userGroup.name === USER_GROUPS.COACHEE) {
                    userGroupObject.name = context.terminology.coachee.plural;
                }
                result.push(userGroupObject);
            }
        }
        setSantizedUserGroups(result);
    }, [context.terminology.coach.plural, context.terminology.coachee.plural, organisationStructure.userGroups]);

    useEffect(() => {
        fetchOrganisationStructure();
    }, []);

    useEffect(() => {
        sanitizedUserGroup();
    }, [sanitizedUserGroup]);

    return (
        <DashboardLayout
            title={"Groups"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUPS, CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST]}
        >
            <div className={"mb-4"}>
                <input
                    type="text"
                    name="search"
                    id="search"
                    value={search}
                    onChange={setSearch}
                    placeholder={"Search groups"}
                    className={
                        "bg-white p-3 h-10 border border-none focus:outline-none text-sm shadow-sm rounded-xl " +
                        "focus:ring-inset focus:ring-2 focus:ring-primary-300 focus:border-transparent block w-full "
                    }
                />
            </div>
            <div className={"flex flex-row gap-4"}>
                <div>
                    <h2 className="font-medium text-primary-600 tracking-tight ">Parent groups</h2>
                    <div className={"self-start bg-white p-3 rounded-xl shadow-sm mt-2"} style={{ width: "350px" }}>
                        {loading ? (
                            <div className={"flex flex-col gap-2 text-sm text-slate-500"}>Loading groups..</div>
                        ) : (
                            <div className={"flex flex-col gap-2 text-sm"}>
                                {organisationStructure && filteredItems && filteredItems.length > 0 ? (
                                    <TreeLinks groups={filteredItems} parent={true} />
                                ) : filteredItems && filteredItems.length === 0 ? (
                                    <p className="text-slate-500 text-md italic">
                                        Sorry there were no results for your search
                                    </p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className={"flex flex-col gap-2"}>
                    <h2 className="font-medium text-primary-600 tracking-tight ">Members</h2>
                    <div className={"flex flex-wrap gap-2 text-sm"}>
                        {groupMembers.length > 0 ? (
                            organisationStructure.users
                                .filter((user) => groupMembers.includes(user.userIdentifier))
                                .map((user, index) => <MemberCard member={user} key={index} />)
                        ) : (
                            <span className={"text-slate-500 text-md italic"}>No members in group</span>
                        )}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
