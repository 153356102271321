import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowTopRightOnSquareIcon, ChatBubbleLeftRightIcon, DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import { BUTTON_VARIANT_TYPES, PRACTICE_INTERVIEW_LINK, STANDARD_TIME_FORMAT } from "../../../../utils/constants";

import Tag from "../../../SharedComponents/Tag";
import Button from "../../../SharedComponents/Button";

export default function PracticeInterviewCard(props) {
    const { item: interview } = props;

    const [copySuccess, setCopySuccess] = useState(false);
    const [status, setStatus] = useState("Expired");

    const navigate = useNavigate();

    const copyToClipboard = () => {
        const result = navigator.clipboard.writeText(
            `${process.env.REACT_APP_RUTHERE_INTERVIEWS_URL}/${interview.interviewId}`
        );
        result
            .then(() => {
                setCopySuccess(true);
                return true;
            })
            .catch((err) => {
                setCopySuccess(false);
                return false;
            });
    };

    const resetCopyToClipboard = () => {
        setCopySuccess(false);
    };

    useEffect(() => {
        interview.deadline !== null && Date.now() >= new Date(interview.deadline)
            ? setStatus("Expired")
            : interview.completed === null
            ? setStatus("Available")
            : setStatus("Completed");
    }, [interview]);

    return (
        <div className={"bg-white shadow-sm rounded-xl group border border-slate-100 border-1"}>
            {status === "Available" ? (
                <a
                    className="p-4 flex flex-col gap-2 text-slate-600 cursor-pointer"
                    href={PRACTICE_INTERVIEW_LINK(interview.interviewId)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <h3
                        className={
                            "text-base font-title font-medium cursor-pointer transition-colors " +
                            "text-primary-600 group-hover:text-primary-700"
                        }
                    >
                        {interview.campaignName}
                    </h3>
                    {interview.recommended && <Tag status={"Recommended by " + interview.recommendedBy} />}
                </a>
            ) : (
                <div className="p-4 flex flex-col gap-2 text-slate-600">
                    <h3 className={"text-base font-title font-medium transition-colors text-primary-600"}>
                        {interview.campaignName}
                    </h3>
                    {interview.recommended && <Tag status={"Recommended by " + interview.recommendedBy} />}
                </div>
            )}

            <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-4 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 sm:col-span-2">Deadline</dt>
                <dd className="mt-1 text-sm text-primary-600 sm:mt-0 sm:col-span-2 font-medium">
                    {interview.deadline === null ? (
                        <span className={"text-xs text-slate-400 italic font-normal"}>None</span>
                    ) : (
                        <span className={"tracking-wide"}>
                            {new Date(interview.deadline).toLocaleString("en-AU", STANDARD_TIME_FORMAT)}
                        </span>
                    )}
                </dd>
            </div>

            <div className="bg-white flex flex-col items-center p-4 sm:grid sm:grid-cols-4 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 sm:col-span-2">Status</dt>
                <dd className="flex flex-row gap-2 items-center mt-1 text-sm text-slate-600 sm:mt-0 sm:col-span-2">
                    <Tag status={status} />
                </dd>
            </div>

            <div className="bg-slate-50 p-4 sm:grid sm:grid-cols-4 sm:gap-4">
                <dt className="text-sm font-medium text-slate-500 sm:col-span-2">Interview link</dt>
                <div className="mt-1 text-sm text-primary-600 sm:mt-0 sm:col-span-2 flex items-center">
                    {(status !== "Completed" && Date.now() < new Date(interview.deadline)) ||
                    interview.deadline === null ? (
                        <div className="w-full flex-1 flex items-center">
                            <Tippy
                                content={"Open external link"}
                                className={
                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-secondary-600 text-white"
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"top-start"}
                                hideOnClick={false}
                            >
                                <a
                                    href={PRACTICE_INTERVIEW_LINK(interview.interviewId)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="truncate hover:text-secondary-700 underline bg-secondary-50 p-1 px-1.5 rounded-lg"
                                >
                                    <div className="flex items-center space-x-1">
                                        <span className="truncate">
                                            {PRACTICE_INTERVIEW_LINK(interview.interviewId)}
                                        </span>
                                        <ArrowTopRightOnSquareIcon className="shrink-0 h-3.5 w-3.5 self-start stroke-current stroke-.5" />
                                    </div>
                                </a>
                            </Tippy>

                            <Tippy
                                content={copySuccess === true ? "Copied!" : "Click to copy URL"}
                                className={
                                    "py-1.5 px-2 shadow rounded-2xl text-xs font-medium text-white " +
                                    (copySuccess ? "bg-green-500" : "bg-secondary-600")
                                }
                                arrow={false}
                                animation={"shift-away-subtle"}
                                placement={"top-start"}
                                hideOnClick={false}
                            >
                                <DocumentDuplicateIcon
                                    className={"shrink-0 block h-4 w-4 ml-1 cursor-pointer hover:text-secondary-700"}
                                    onClick={copyToClipboard}
                                    onMouseOut={resetCopyToClipboard}
                                />
                            </Tippy>
                        </div>
                    ) : (
                        <span className={"text-xs text-slate-400 italic "}>Unavailable</span>
                    )}
                </div>
            </div>

            <div className="bg-white flex flex-col items-center p-4 sm:grid sm:grid-cols-4 sm:gap-4 rounded-b-xl">
                <dt className="text-sm font-medium text-slate-500 sm:col-span-2">Feedback</dt>
                <dd className="flex flex-row gap-2 items-center mt-1 text-sm text-slate-600 sm:mt-0 sm:col-span-2">
                    {status === "Completed" ? (
                        <div className="relative">
                            <Button
                                colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                text="View feedback"
                                icon={<ChatBubbleLeftRightIcon className="h-3 w-3 mr-1" />}
                                smallButton
                                iconPositionLeft
                                onClick={() =>
                                    navigate("/practice-interview-feedback", {
                                        state: { interview: interview },
                                    })
                                }
                            />
                        </div>
                    ) : (
                        <span className={"text-xs text-slate-400 italic "}>Unavailable</span>
                    )}
                </dd>
            </div>
        </div>
    );
}
