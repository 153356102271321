import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../context/Context";
import { NO_ACCESS_TYPES } from "../../utils/constants";

import DashboardLoadingPlaceholder from "./Loading/DashboardLoadingPlaceholder";

import stopIllustration from "../../assets/stop.svg";

function AccessControl({ requiredPermissions, noAccessType, children }) {
    const context = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (!context.permissionsLoading) {
            if (
                !requiredPermissions.every((requiredPermission) => context.userPermissions.includes(requiredPermission))
            ) {
                if (noAccessType === NO_ACCESS_TYPES.REDIRECT) {
                    navigate("/no-permission");
                }
            }
        }
    }, [context.permissionsLoading, context.userPermissions, navigate, noAccessType, requiredPermissions]);

    return context.permissionsLoading ? (
        <DashboardLoadingPlaceholder />
    ) : requiredPermissions.every((requiredPermission) => context.userPermissions.includes(requiredPermission)) ? (
        children
    ) : (
        noAccessType === NO_ACCESS_TYPES.ERROR_TEXT && (
            <div
                className={
                    "text-sm w-full mx-auto rounded-md px-3 py-3 info " +
                    "bg-amber-100 text-amber-700 align-center justify-center"
                }
            >
                <div className={"flex items-center justify-center"}>
                    <img className={"w-3/4"} alt="" src={stopIllustration} />
                </div>
                <div className={"font-medium text-base mt-4"}>You do not have the necessary permissions</div>
                <div className={"mt-2"}>
                    You do not have the necessary permissions required to view this page, section or to perform this
                    action. Please contact your supervisor if this was unexpected.
                </div>
            </div>
        )
    );
}

export default AccessControl;
