import { useContext, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import {
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../../../../../utils/constants";

import SatisfactionOnSixMainDimensions from "../../ChartCards/SatisfactionOnSixMainDimensions";
import DashboardLayout from "../../../../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import PerDimensionItemsRegional from "./PerDimensionItemsRegional";
import ResponseProportions from "./ResponseProportions";
import QualitativeItemSection from "../Qualitative/QualitativeItemSection";
import PerDimensionRegional from "./PerDimensionRegional";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import GeneralSatisfaction from "./GeneralSatisfaction";
import QuestionsDataChart from "../../ChartCards/QuestionsDataChart";
import SentimentOvertime from "./SentimentOvertime";
import AceReportPicker from "../AceReportPicker";

export default function Sentiment() {
    return (
        <DashboardLayout
            title="Sentiment"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <SentimentMeat />
        </DashboardLayout>
    );
}

export function SentimentMeat(props) {
    const { demo } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const context = useContext(Context);

    const tabList = [
        "General satisfaction",
        "Six dimensions",
        "Six dimensions by region",
        "Six dimensions by area (regions)",
        "Response proportions",
    ];

    const tabContent = [
        {
            title: "Six dimensions",
            content: (
                <SatisfactionOnSixMainDimensions
                    withBenchmarks
                    detailed
                    data={context.aceSurveyReportData.sixDimensions}
                />
            ),
        },
        {
            title: "Six dimensions by region",
            content: <PerDimensionRegional demo={demo} />,
        },
        {
            title:
                "Six dimensions by area " +
                (context.aceSurveyReportData.sixDimensionsRegional.length !== 0 ? "(regions)" : ""),
            content: <PerDimensionItemsRegional demo={demo} />,
        },
        {
            title: "Response proportions",
            content: <ResponseProportions />,
        },
    ];

    // Edit tab names and content if there is no regional data
    if (context.aceSurveyReportData.sixDimensionsRegional.length === 0) {
        delete tabContent[1];
        delete tabList[2];
        tabList[3] = "Six dimensions by area";
    }

    if (
        context.aceSurveyReportData.sixDimensionsMemberTypes &&
        context.aceSurveyReportData.sixDimensionsMemberTypes.length !== 0
    ) {
        let memberTypeTitle = "Member Type";

        if (context.aceSurveyReportData.sixDimensionsMemberTypes[0].title) {
            memberTypeTitle = context.aceSurveyReportData.sixDimensionsMemberTypes[0].title.split("-")[1].trim();
        }

        let sixDimensionsByMemberTypeTitle = "Six dimensions by " + memberTypeTitle;
        let sixDimensionsByAreaTitle = "Six dimensions by area (" + memberTypeTitle + ")";

        tabList.splice(3, 0, sixDimensionsByMemberTypeTitle);
        tabList.splice(5, 0, sixDimensionsByAreaTitle);

        tabContent.splice(2, 0, {
            title: sixDimensionsByMemberTypeTitle,
            content: (
                <PerDimensionRegional demo={demo} data={context.aceSurveyReportData.sixDimensionsWithMemberTypes} />
            ),
        });

        tabContent.splice(4, 0, {
            title: sixDimensionsByAreaTitle,
            content: (
                <PerDimensionItemsRegional
                    demo={demo}
                    data={context.aceSurveyReportData.sixDimensionsWithMemberTypes}
                />
            ),
        });
    }

    context.aceSurveyReportData.generalSatisfaction.forEach((generalSatisfactionItem) => {
        generalSatisfactionItem.name === "Text"
            ? tabList.push("Open-ended questions") &&
              tabContent.push({
                  content: (
                      <div className={"flex flex-col gap-4 w-full"}>
                          <div className="flex flex-col gap-4 ">
                              {generalSatisfactionItem.items.map((item, index) => (
                                  <QualitativeItemSection
                                      key={index + "qualitativeItemSection"}
                                      data={item}
                                      title={"Open-ended question #" + (index + 1)}
                                  />
                              ))}
                          </div>
                      </div>
                  ),
                  readonly: true,
              })
            : tabContent.unshift({
                  content: <GeneralSatisfaction />,
                  readonly: true,
              });
    });

    if (context.aceSurveyReportData.franchisorTeamNotificationOfSeriousDisagreement.length !== 0) {
        let franchisorDisagreementChartDataItem = [];

        context.aceSurveyReportData.franchisorTeamNotificationOfSeriousDisagreement.forEach((disagreementItem) => {
            disagreementItem.items.map((disagreementItemItem) =>
                // Could be moved to Context.js
                franchisorDisagreementChartDataItem.push({
                    itemText: disagreementItemItem.itemText,
                    data: disagreementItemItem.data
                        .map((item) => ({
                            name: item.name,
                            percentScore: item.percentScore.Yes,
                            category: item.name,
                        }))
                        .concat(
                            disagreementItemItem.historicalScore.reverse().map((item) => ({
                                name: item.name,
                                percentScore: item.percentScore,
                                category: item.name,
                            }))
                        ),
                })
            );
        });

        context.aceSurveyReportData.franchisorTeamNotificationOfSeriousDisagreement.forEach((disagreementItem) => {
            disagreementItem.name === "Notified the franchisor team of serious disagreement"
                ? tabList.push("Serious disagreement notification") &&
                  tabContent.push({
                      content: (
                          <QuestionsDataChart
                              data={franchisorDisagreementChartDataItem}
                              title={disagreementItem.name}
                              colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                              questions
                              infoBubble
                              noInfoBubbleOnPrint
                              extraStyling="print:break-before-page"
                              percentageAxisLabels={{
                                  first: "0%",
                                  second: "25%",
                                  third: "50%",
                                  fourth: "75%",
                                  fifth: "100%",
                              }}
                              percentage
                              noTrendLegend
                              percentageAxisMargin
                          />
                      ),
                      readonly: true,
                  })
                : tabContent.unshift({
                      content: <GeneralSatisfaction />,
                      readonly: true,
                  });
        });
    }

    let hasSixDimensionsRegionalHistoricalData = false;
    let hasGeneralSatisfactionHistoricalData = false;
    let hasSixDimensionsHistoricalData = false;

    if (context.aceSurveyReportData.sixDimensionsRegional.length !== 0) {
        context.aceSurveyReportData.sixDimensionsRegional.forEach((dimension) => {
            if (dimension.overallScoreStateLevel) {
                dimension.overallScoreStateLevel.forEach((overallScoreStateLevelScore) => {
                    if (overallScoreStateLevelScore.historicalScores) {
                        if (overallScoreStateLevelScore.historicalScores.length > 2) {
                            hasSixDimensionsRegionalHistoricalData = true;
                        }
                    }
                });
            }
        });
    }

    if (context.aceSurveyReportData.generalSatisfaction.length !== 0) {
        if (context.aceSurveyReportData.generalSatisfaction[0].historicalScores) {
            if (context.aceSurveyReportData.generalSatisfaction[0].historicalScores.length > 2) {
                hasGeneralSatisfactionHistoricalData = true;
            }
        }
    }

    if (context.aceSurveyReportData.sixDimensions.length !== 0) {
        context.aceSurveyReportData.sixDimensions.forEach((sixDimensionsDimension) => {
            if (sixDimensionsDimension.historicalScores) {
                if (sixDimensionsDimension.historicalScores.length > 2) {
                    hasSixDimensionsHistoricalData = true;
                }
            }
        });
    }

    if (
        hasSixDimensionsRegionalHistoricalData ||
        hasGeneralSatisfactionHistoricalData ||
        hasSixDimensionsHistoricalData
    ) {
        tabList.push("Historical results");
        tabContent.push({
            content: (
                <SentimentOvertime
                    hasSixDimensionsRegionalHistoricalData={hasSixDimensionsRegionalHistoricalData}
                    hasGeneralSatisfactionHistoricalData={hasGeneralSatisfactionHistoricalData}
                    hasSixDimensionsHistoricalData={hasSixDimensionsHistoricalData}
                />
            ),
            readonly: true,
        });
    }

    return (
        <div className="flex flex-col gap-6 print:gap-4 print:-mt-2">
            <p className={"text-slate-500 text-sm " + (tabIndex === 0 ? "" : "print:hidden")}>
                This section measures sentiment in the six dimensions which ultimately enhance or detract from the ACE
                Mindset. Their ratings have then been combined, weighted and mapped onto a series of charts showing how
                positive or negative they feel in these six dimensions and 23 areas that fit within these dimensions.
            </p>
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <TabbedNavigationLayout
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabList={tabList}
                    backgroundColour={"bg-white"}
                    noContainer
                    tabContent={tabContent}
                    noTitle
                    secondaryTabs
                />
            )}
        </div>
    );
}
