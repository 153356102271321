import { useContext, useState, useEffect } from "react";
import { motion } from "framer-motion";
import Tippy from "@tippyjs/react";
import CountUp from "react-countup";

import { Context } from "../../../../../../context/Context";

export default function Bar(props) {
    const {
        label,
        score,
        historicalScore,
        backgroundColour,
        textColour,
        separationGap,
        labelAddOn,
        testId,
        barEmptyBackgroundColour,
        transitionDuration,
        hidePercentScoreNumber,
        smallBar,
    } = props;

    const [currentSurveyLabel, setCurrentSurveyLabel] = useState("Current survey");
    const [previousSurveyLabel, setPreviousSurveyLabel] = useState("Previous survey");

    const context = useContext(Context);

    useEffect(() => {
        if (context.aceSurveyReportData) {
            setCurrentSurveyLabel(context.aceSurveyReportData.currentSurveyLabel);
            setPreviousSurveyLabel(context.aceSurveyReportData.previousSurveyLabel);
        }
    }, [context]);

    return (
        <div data-testid={testId} className="flex flex-row gap-2 items-center">
            <div
                className={
                    "relative w-full rounded-xl text-sm font-medium " +
                    separationGap +
                    " " +
                    (barEmptyBackgroundColour ? barEmptyBackgroundColour : "bg-slate-100")
                }
            >
                <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${score}%` }}
                    transition={{ duration: transitionDuration ? transitionDuration : 4 }}
                >
                    <div
                        className={
                            "transition ease-in-out duration-300 rounded-xl   " +
                            backgroundColour +
                            (smallBar ? " h-3" : " p-2 h-9")
                        }
                        style={{ whiteSpace: "nowrap" }}
                    />
                </motion.div>
                {label !== "Sector benchmark" && (historicalScore || historicalScore === 0) && (
                    <motion.div
                        initial={{ width: 0, opacity: 0 }}
                        animate={{ width: historicalScore + "%", opacity: 1 }}
                        transition={{ duration: 3 }}
                        className={
                            "absolute top-0 left-0 flex items-center justify-end h-full " +
                            (historicalScore === 0 ? "ml-2" : "ml-0")
                        }
                    >
                        <Tippy
                            content={previousSurveyLabel + ": " + historicalScore + "%"}
                            className={
                                "py-1.5 px-2 shadow rounded-xl text-xs font-medium " +
                                (historicalScore > score ? "bg-red-600 text-red-50" : "bg-green-600 text-green-50")
                            }
                            arrow={false}
                            animation={"shift-away-subtle"}
                            placement={"top-start"}
                        >
                            <div className={"text-right transition ease-in-out duration-300 cursor-pointer"}>
                                <div
                                    className={
                                        "h-6 w-2 rounded-xl " +
                                        (historicalScore > score
                                            ? "bg-red-600 border border-red-900"
                                            : "bg-green-600 border border-white")
                                    }
                                />
                            </div>
                        </Tippy>
                    </motion.div>
                )}
                {!smallBar && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 2 }}
                        className={"absolute top-0 left-2 flex items-center h-full"}
                    >
                        <span className={"text-left rounded-lg py-0.5 px-1 " + textColour}>
                            {label ? label : currentSurveyLabel}
                        </span>
                    </motion.div>
                )}
            </div>
            {!hidePercentScoreNumber && (
                <div className={"inset-y-2 right-1 transition ease-in-out duration-300 w-12"}>
                    <div className="flex items-center justify-center mr-1 text-sm">
                        <span className={"rounded-lg py-0.5 px-1 font-medium " + textColour}>
                            <CountUp end={score} duration={transitionDuration ? transitionDuration : 4} />%
                            <span className="text-xs font-normal">{labelAddOn}</span>
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}
