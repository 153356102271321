import axios from "axios";
import {
    samplePaginatedListResponsePage1,
    samplePaginatedListResponsePage2,
    samplePaginatedListResponsePage3,
} from "./mockData";

const api = axios.create({
    baseURL: `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/`,
    headers: {
        "Content-Type": "application/json",
    },
});

const terminologyApi = axios.create({
    baseURL: `https://${process.env.REACT_APP_TERMINOLOGY_HOST}:${process.env.REACT_APP_TERMINOLOGY_PORT}/api/`,
});

export const getTerminology = async (organisationIdentifier, applicationIdentifier, language) => {
    return (
        await terminologyApi.get(``, {
            params: {
                organisationIdentifier: organisationIdentifier,
                applicationIdentifier: applicationIdentifier,
                language: language,
            },
        })
    ).data;
};

/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired
 logout the user if the token has expired
 **/

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
                    error.message =
                        error.response.data || "Sorry, your request was not valid. Please double check and try again.";
                    break;
                case 401:
                    setSessionToken(null);
                    window.location.href = "/session-expired";
                    break;
                case 403:
                    error.message =
                        error.response.data || "Sorry, you do not have sufficient permissions to access this feature.";
                    break;
                case 408:
                    error.redirect = "/survey-timed-out";
                    break;
                case 404:
                    error.redirect = "/not-found";
                    break;
                default:
                    error.redirect = "/something-has-gone-wrong";
                    break;
            }
        } else {
            error.redirect = "/something-has-gone-wrong";
        }
        return Promise.reject(error);
    }
);

export const setSessionToken = (token) => {
    if (token) {
        api.defaults.headers.common["X-SESSION-ID"] = token;
        sessionStorage.setItem("token", token);
    } else {
        delete api.defaults.headers.common["X-SESSION-ID"];
        sessionStorage.removeItem("token");
    }
};

export const logout = async () => {
    setSessionToken(null);
    await api.post("authentication/logout");
};

export const loginByAccessKey = async (accessKey) => {
    const sessionToken = (await api.get(`authentication/login/${accessKey}`)).data;
    setSessionToken(sessionToken);
};

export const assignCoach = async (coachUserIdentifier, coacheeUserIdentifier, coachFirstName, coachLastName) => {
    await api.post("userdata/assignCoach", {
        coach: coachUserIdentifier,
        coachee: coacheeUserIdentifier,
        coachFirstName: coachFirstName,
        coachLastName: coachLastName,
    });
};

export const reassignCoach = async (coachUserIdentifier, coacheeUserIdentifier, coachFirstName, coachLastName) => {
    await api.post("userdata/reassignCoach", {
        coach: coachUserIdentifier,
        coachee: coacheeUserIdentifier,
        coachFirstName: coachFirstName,
        coachLastName: coachLastName,
    });
};

export const getCoachees = async () => {
    return (await api.get("userdata/coachees")).data;
};

export const getCoacheesWithOverallScore = async () => {
    return (await api.get("userdata/coachees/withScores")).data;
};

export const getCoacheesForCoachWithOverallScore = async () => {
    return (await api.get("userdata/userCoachees/withScores")).data;
};

export const getCoacheesForCoach = async () => {
    return (await api.get("userdata/userCoachees")).data;
};

export const getCoachesForCoachee = async () => {
    return (await api.get("userdata/userCoaches")).data;
};

export const getCoaches = async () => {
    return (await api.get("userdata/coaches")).data;
};

export const setupNewCoach = async (
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    biography,
    yearsExperience,
    accreditations
) => {
    await api.post("userdata/coach", {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        mobileNumber: mobileNumber,
        biography: biography,
        experienceYears: yearsExperience,
        accreditations: accreditations,
    });
};

export const setupCoach = async (userIdentifier) => {
    await api.post("userdata/coach", {
        userIdentifier: userIdentifier,
    });
};

export const setupNewCoachee = async (
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    cohorts,
    jobSeekerId,
    assignWorkflowOnSetup
) => {
    await api.post("userdata/coachee", {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        mobileNumber: mobileNumber,
        cohorts: cohorts,
        jobSeekerId: jobSeekerId,
        assignWorkflowOnSetup: assignWorkflowOnSetup,
    });
};

export const setupCoachee = async (userIdentifier, cohorts, jobSeekerId, assignWorkflowOnSetup) => {
    await api.post("userdata/coachee", {
        userIdentifier: userIdentifier,
        cohorts: cohorts,
        jobSeekerId: jobSeekerId,
        assignWorkflowOnSetup: assignWorkflowOnSetup,
    });
};

export const getOrganisationUsers = async () => {
    return (await api.get("userdata/organisationUsers")).data;
};

export const getOrganisationUsersLite = async (includeUserGroups) => {
    return (
        await api.get("userdata/organisationUsers/lite", {
            params: {
                includeUserGroups: includeUserGroups,
            },
        })
    ).data;
};

export const getOrganisationStructure = async () => {
    return (await api.get("userdata/organisationStructure")).data;
};

export const getPaginatedCoacheesTest = async (pageNumber, pageSize, searchTerm) => {
    switch (pageNumber) {
        case 0:
            return samplePaginatedListResponsePage1;
        case 1:
            return samplePaginatedListResponsePage2;
        case 2:
            return samplePaginatedListResponsePage3;
        default:
            return samplePaginatedListResponsePage1;
    }
    // Sample endpoint properties
    // await api.post("userdata/coachees", {
    //     pageNumber: pageNumber,
    //     pageSize: pageSize,
    // });
};

export const getProfileDetails = async () => {
    return (await api.get("userdata/details")).data;
};

export const getUserDetails = async (coacheeIdentifier) => {
    return (await api.get(`userdata/${coacheeIdentifier}/details`)).data;
};

export const getCohorts = async () => {
    return (await api.get("cohort")).data;
};

export const createCohort = async (cohortName, cohortMembers, programName) => {
    await api.post("cohort", {
        name: cohortName,
        users: cohortMembers,
        program: programName,
    });
};

export const getCohortUsers = async (cohortName) => {
    return (await api.get(`cohortUsers/${cohortName}`)).data;
};

export const getPrograms = async () => {
    return (await api.get("program")).data;
};

export const createProgram = async (programName) => {
    await api.post("program", {
        name: programName,
    });
};

export const updateProfileDetails = async (
    emailAddress,
    firstName,
    lastName,
    mobileNumber,
    dateOfBirth,
    userGroups
) => {
    await api.put("userdata/account/details", {
        emailAddress: emailAddress,
        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber,
        dateOfBirth: dateOfBirth,
        userGroups: userGroups,
    });
};

export const updateUserDetails = async (
    userIdentifier,
    emailAddress,
    firstName,
    lastName,
    mobileNumber,
    dateOfBirth,
    educations,
    qualifications,
    interests,
    accreditations,
    cohorts,
    programs,
    biography,
    yearsExperience,
    userGroups,
    jobSeekerId
) => {
    await api.put("userdata/user/details", {
        userIdentifier: userIdentifier,
        emailAddress: emailAddress,
        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber,
        dateOfBirth: dateOfBirth,
        educations: educations,
        qualifications: qualifications,
        interests: interests,
        accreditations: accreditations,
        cohorts: cohorts,
        programs: programs,
        biography: biography,
        experienceYears: yearsExperience,
        userGroups: userGroups,
        jobSeekerId: jobSeekerId,
    });
};

export const getUserMediaFile = async (mediaFileIdentifier) => {
    return (await api.get(`userdata/user/media/${mediaFileIdentifier}`)).data;
};

export const getAccountMediaFile = async (mediaFileIdentifier) => {
    return (await api.get(`userdata/account/media/${mediaFileIdentifier}`)).data;
};

export const uploadAccountMedia = async (name, file) => {
    const uploadMediaForm = new FormData();

    if (name) {
        uploadMediaForm.append("name", name);
    }

    uploadMediaForm.append("file", file);

    await api.post("userdata/account/media", uploadMediaForm, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const uploadUserMedia = async (name, file, userIdentifier) => {
    const uploadMediaForm = new FormData();

    if (name) {
        uploadMediaForm.append("name", name);
    }

    uploadMediaForm.append("file", file);

    await api.post("userdata/user/media/" + userIdentifier, uploadMediaForm, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const getUserPermissions = async () => {
    return (await api.get("userdata/permissions")).data;
};

export const getShowWelcome = async () => {
    return (await api.get("userdata/showWelcome")).data;
};

export const updateShowWelcome = async (showOrgWelcome, showAppWelcome) => {
    await api.put("userdata/showWelcome", {
        showOrgWelcome: showOrgWelcome,
        showAppWelcome: showAppWelcome,
    });
};

export const getOrganisationWelcome = async () => {
    return (await api.get("userdata/organisationWelcome")).data;
};

export const getResourceDetails = async (resourceIdentifier) => {
    return (await api.get(`userdata/resources/${resourceIdentifier}/details`)).data;
};

export const uploadResource = async (
    title,
    subtitle,
    description,
    resourceType,
    startTime,
    durationMins,
    location,
    isOrganisationResource,
    resourceLinks,
    files
) => {
    const uploadResourceForm = new FormData();

    uploadResourceForm.append("title", title);
    uploadResourceForm.append("subtitle", subtitle);
    uploadResourceForm.append("description", description);
    uploadResourceForm.append("isOrgResource", isOrganisationResource);
    uploadResourceForm.append("resourceType", resourceType);
    uploadResourceForm.append("startTime", startTime);
    uploadResourceForm.append("durationMins", durationMins);
    uploadResourceForm.append("location", location);

    // Approach to add array of objects as multipart/form-data
    resourceLinks.forEach((link, index) => {
        uploadResourceForm.append(`resourceLinks[${index}].name`, link.name);
        uploadResourceForm.append(`resourceLinks[${index}].url`, link.url);
    });

    files.forEach((file) => uploadResourceForm.append("files", file));

    if (isOrganisationResource) {
        await api.post("resource/organisation", uploadResourceForm, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    } else {
        await api.post("resource/own", uploadResourceForm, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
};

export const updateResource = async (
    resourceIdentifier,
    title,
    subtitle,
    description,
    resourceType,
    startTime,
    durationMins,
    location,
    isOrganisationResource,
    resourceLinks,
    files
) => {
    const updateResourceForm = new FormData();

    updateResourceForm.append("title", title);
    updateResourceForm.append("subtitle", subtitle);
    updateResourceForm.append("description", description);
    updateResourceForm.append("isOrgResource", isOrganisationResource);
    updateResourceForm.append("resourceType", resourceType);
    updateResourceForm.append("startTime", startTime);
    updateResourceForm.append("durationMins", durationMins);
    updateResourceForm.append("location", location);

    // Approach to add array of objects as multipart/form-data
    resourceLinks.forEach((link, index) => {
        updateResourceForm.append(`resourceLinks[${index}].name`, link.name);
        updateResourceForm.append(`resourceLinks[${index}].url`, link.url);
    });

    files.forEach((file) => updateResourceForm.append("files", file));

    if (isOrganisationResource) {
        await api.put(`resource/organisation/${resourceIdentifier}`, updateResourceForm, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    } else {
        await api.put(`resource/own/${resourceIdentifier}`, updateResourceForm, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
};

export const getAllResources = async () => {
    return (await api.get("resource/all")).data;
};

export const getAllResourceTypes = async () => {
    return (await api.get("resource/types")).data;
};

export const getAssignedResources = async () => {
    return (await api.get("resource/coachee")).data;
};

export const assignResourceToUsers = async (userIdentifiers, resourceIdentifier) => {
    await api.post("resource/assign/users", {
        target: resourceIdentifier,
        toAssign: userIdentifiers,
        preserveExisting: false,
    });
};

export const assignResourceToCohorts = async (cohortIdentifiers, resourceIdentifier) => {
    await api.post("resource/assign/cohorts", {
        target: resourceIdentifier,
        toAssign: cohortIdentifiers,
        preserveExisting: false,
    });
};

export const assignResourceToPrograms = async (programIdentifiers, resourceIdentifier) => {
    await api.post("resource/assign/programs", {
        target: resourceIdentifier,
        toAssign: programIdentifiers,
        preserveExisting: false,
    });
};

export const getResourceAssignment = async (resourceIdentifier) => {
    return (await api.get(`resource/assignment/${resourceIdentifier}`)).data;
};

export const updateCohortStatus = async (status, cohortName) => {
    await api.put("cohort/status", {
        status: status,
        target: cohortName,
    });
};

export const updateCohortName = async (previousName, newName) => {
    await api.post("cohortName", {
        previousName: previousName,
        newName: newName,
    });
};

export const assignCohortToUsers = async (userIdentifiers, cohortName) => {
    await api.post("cohort/assign/user", {
        target: cohortName,
        toAssign: userIdentifiers,
        preserveExisting: false,
    });
};

export const updateCoacheeStatus = async (status, userIdentifier) => {
    await api.put("userdata/status", {
        status: status,
        target: userIdentifier,
    });
};

export const updateProgramName = async (previousName, newName) => {
    await api.post("programName", {
        previousName: previousName,
        newName: newName,
    });
};

export const assignProgramToCohorts = async (cohorts, cohortName) => {
    await api.post("program/assign/cohort", {
        target: cohortName,
        toAssign: cohorts,
        preserveExisting: false,
    });
};

export const updateReleaseReportPermissionFromUsers = async (userIdentifiers) => {
    await api.post("userdata/permission/add/releaseReport", {
        userIdentifiers: userIdentifiers,
    });
};

export const getReleaseReportPermissionUsers = async () => {
    return (await api.get("userdata/releaseReportPermissionUsers")).data;
};

export const getBranding = async (organisationIdentifier) => {
    return (await api.get(`userdata/branding/${organisationIdentifier}`)).data;
};

export const getApplications = async () => {
    return (await api.get("userdata/applications")).data;
};

export const getOneTimeUrl = async (application) => {
    return (await api.get(`application/launchurl/${application}`)).data;
};

export const getAssessmentsForUser = async () => {
    return (await api.get("userdata/assessments")).data;
};

export const getAssessmentLaunchUrl = async (orderIdentifier) => {
    return (await api.get(`assessment/launchurl/${orderIdentifier}`)).data;
};

export const getSessionsForUser = async () => {
    return (await api.get("meetings/user")).data;
};

export const getSessionsForOrganisation = async () => {
    return (await api.get("meetings")).data;
};

export const createSession = async (
    sessionName,
    sessionDateTime,
    sessionDuration,
    sessionLink,
    sessionLocation,
    participantsUserIdentifier
) => {
    await api.post("meeting", {
        name: sessionName,
        startTime: sessionDateTime,
        durationMins: sessionDuration,
        isCompleted: false,
        meetingUrl: sessionLink,
        meetingLocation: sessionLocation,
        participantsUserIdentifier: participantsUserIdentifier,
    });
};

export const updateSession = async (
    meetingIdentifier,
    sessionName,
    sessionDateTime,
    sessionDuration,
    isCompleted,
    sessionLink,
    sessionLocation,
    participantsUserIdentifier
) => {
    await api.put(`meeting/${meetingIdentifier}`, {
        name: sessionName,
        startTime: sessionDateTime,
        durationMins: sessionDuration,
        isCompleted: isCompleted,
        meetingUrl: sessionLink,
        meetingLocation: sessionLocation,
        participantsUserIdentifier: participantsUserIdentifier,
    });
};

export const getReports = async () => {
    return (await api.get("document")).data;
};

export const downloadLatestReportForAssessment = async (documentIdentifier) => {
    return (await api.get(`document/${documentIdentifier}/latest`)).data;
};

export const downloadLatestReportForSubsequentFile = async (documentIdentifier, subsequentFileIdentifier) => {
    return (await api.get(`document/${documentIdentifier}/subsequentFile/${subsequentFileIdentifier}`)).data;
};

export const uploadReport = async (email, file, documentName, tags, encType) => {
    const uploadReportForm = new FormData();
    uploadReportForm.append("EmailAddress", email);
    uploadReportForm.append("DocumentName", documentName);
    uploadReportForm.append("Document", file);

    if (tags.length > 0) {
        uploadReportForm.append("Tags", tags);
    }

    uploadReportForm.append("encType", encType);

    return (
        await api.post("document/upload", uploadReportForm, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    ).data;
};

export const renameDocument = async (documentIdentifier, newName) => {
    await api.post("document/rename", {
        identifier: documentIdentifier,
        name: newName,
    });
};

export const editDocumentTags = async (documentIdentifier, tags) => {
    await api.post("document/tags", {
        target: documentIdentifier,
        toAssign: tags,
        preserveExisting: false,
    });
};

export const downloadReportByVersion = async (documentIdentifier, versionNumber) => {
    return (await api.get(`document/${documentIdentifier}/${versionNumber}`)).data;
};

export const downloadSubsequentFile = async (documentIdentifier, subsequentFileIdentifier) => {
    return (await api.get(`document/${documentIdentifier}/subsequentFile/${subsequentFileIdentifier}`)).data;
};

export const getCoacheesWithResumes = async (abortController = null) => {
    return (await api.get("userdata/coachees/withResumes", { signal: abortController?.signal })).data;
};

export const getCoacheesWithResumesForCoach = async (abortController = null) => {
    return (await api.get("userdata/userCoachees/withResumes", { signal: abortController?.signal })).data;
};

export const getUserCohortsCoacheesWithResumes = async (abortController = null) => {
    return (await api.get("userdata/userCohorts/Coachees/withResumes", { signal: abortController?.signal })).data;
};

export const getUserCohortsCoachees = async (abortController = null) => {
    return (await api.get("userdata/userCohorts/Coachees", { signal: abortController?.signal })).data;
};

export const updateResourceRating = async (stars, resourceIdentifier) => {
    await api.put("resource/rating", {
        stars: stars,
        resourceIdentifier: resourceIdentifier,
    });
};

export const createResourceRating = async (stars, resourceIdentifier) => {
    await api.post("resource/rating", {
        stars: stars,
        resourceIdentifier: resourceIdentifier,
    });
};

export const getAllResourceRatings = async (resourceIdentifier) => {
    return (await api.get(`resource/rating/all/${resourceIdentifier}`)).data;
};

export const downloadResourceFile = async (resourceIdentifier, resourceMediaFileIdentifier) => {
    return (await api.get(`resource/${resourceIdentifier}/MediaFile/${resourceMediaFileIdentifier}`)).data;
};

export const deleteResourceFile = async (resourceIdentifier, resourceMediaFileIdentifier) => {
    await api.delete(`resource/${resourceIdentifier}/MediaFile/${resourceMediaFileIdentifier}`);
};

export const getCoachesTrainingModuleStatuses = async () => {
    return (await api.get("trainingModule/coach/status")).data;
};

export const updateCoachTrainingModuleStatus = async (coachTrainingModuleIdentifier, statusName) => {
    return await api.put(`trainingModule/coach/status/${coachTrainingModuleIdentifier}`, {
        statusName: statusName,
    });
};

export const assignAssessment = async (
    userIdentifier,
    onBehalfOfuserIdentifier,
    onBehalfOfUserEmailAddress,
    workflowIdentifier,
    emailAddress,
    firstName,
    lastName,
    dueDate,
    batchIdentifier
) => {
    return await api.post(
        `assessment/assign/${userIdentifier}`,
        {
            workflowIdentifier: workflowIdentifier,
            onBehalfOfUserEmailAddress: onBehalfOfUserEmailAddress,
            emailAddress: emailAddress,
            dueDate: dueDate,
            firstName: firstName,
            lastName: lastName,
            batchIdentifier: batchIdentifier,
        },
        {
            params: {
                onBehalfOfuserIdentifier: onBehalfOfuserIdentifier,
            },
        }
    );
};

export const getOrganisationWorkflows = async () => {
    return (await api.get("organisationWorkflows")).data;
};

export const getRecommendedJobInfo = async (jobCode) => {
    return (await api.get(`careeringAhead/jobsAndCourses/${jobCode}`)).data;
};

export const getRUThereCampaigns = async () => {
    return (await api.get(`ruthere/campaigns`)).data;
};

export const recommendRUThereInterview = async (campaignId, candidateId, deadline) => {
    return await api.post(
        `ruthere/interview/create/${campaignId}?candidateId=${candidateId}` + (deadline ? `&deadline=${deadline}` : "")
    );
};

export const getAllRUThereUserInterviews = async () => {
    return (await api.get(`ruthere/interviews/all`)).data;
};

export const getRUThereUserInterviewsForCoach = async () => {
    return (await api.get(`ruthere/interviews/userCoachees`)).data;
};

export const getRUThereUserInterviewsForUser = async (candidateId) => {
    return (await api.get(`ruthere/interviews/${candidateId}`)).data;
};

export const getRUThereUserCompletedInterviewRecordings = async (interviewId, campaignId) => {
    return (await api.get(`ruthere/interview/${interviewId}/get_completed_recordings_urls/${campaignId}`)).data;
};

export const scoreRUThereInterviewRecording = async (recordingId, reviewerId, feedback, score) => {
    return await api.post(`ruthere/interview/recording/${recordingId}/score`, {
        reviewerId: reviewerId,
        feedback: feedback,
        score: score,
    });
};

export const getUserMetaData = async (userIdentifier, metaDataKey) => {
    return (await api.get(`userMetadata/${metaDataKey}/${userIdentifier}`)).data;
};

export const downloadUserMetaData = async (userIdentifier, metaDataKey) => {
    return (await api.get(`pdf/${metaDataKey}/${userIdentifier}`, { responseType: "blob" })).data;
};

export const updateUserMetaData = async (userIdentifier, metaDataKey, value) => {
    return await api.post(`userMetadata/${userIdentifier}`, {
        key: metaDataKey,
        value: value,
    });
};

export const uploadResume = async (userIdentifier, email, file, documentName) => {
    const uploadResumeForm = new FormData();

    uploadResumeForm.append("EmailAddress", email);
    uploadResumeForm.append("DocumentName", documentName);
    uploadResumeForm.append("Document", file);

    return (
        await api.post(`document/upload/${userIdentifier}/resume`, uploadResumeForm, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    ).data;
};

export const getAssessmentsAndReport = async (userIdentifier) => {
    return (await api.get(`userdata/assessmentsAndReports/${userIdentifier}`)).data;
};

export const getActiveBatchDetails = async (productName) => {
    return (await api.get(`batch/active/${productName}`)).data;
};

export const getRegionalStats = async (batchIdentifier) => {
    return (await api.get(`assessments/region/stats/${batchIdentifier}`)).data;
};

export const getCountryStats = async (batchIdentifier) => {
    return (await api.get(`assessments/country/stats/${batchIdentifier}`)).data;
};

export const getFieldManagerStats = async (batchIdentifier) => {
    return (await api.get(`assessments/fieldManager/stats/${batchIdentifier}`)).data;
};

export const getGroupStats = async (batchIdentifier) => {
    return (await api.get(`assessments/group/stats/${batchIdentifier}`)).data;
};

export const reAssignAssessment = async (workflowIdentifier, orderIdentifier, dueDate, userIdentifier) => {
    return await api.post(`assessment/reassign/${workflowIdentifier}/${orderIdentifier}`, {
        dueDate: dueDate,
        userIdentifier: userIdentifier,
    });
};

export const assignAssessmentInBulk = async (
    userIdentifiers,
    onBehalfOfUserIdentifier,
    workflowIdentifier,
    dueDate,
    batchIdentifier
) => {
    return await api.post("assessment/request/bulkassign", {
        workflowIdentifier: workflowIdentifier,
        userIdentifiers: userIdentifiers,
        dueDate: dueDate,
        onBehalfOfUserIdentifier: onBehalfOfUserIdentifier,
        batchIdentifier: batchIdentifier,
    });
};

export const assignAssessmentInBulkOnBehalf = async (
    workflowIdentifier,
    userIdentifier,
    onBehalfOfUserIdentifiers,
    dueDate,
    batchIdentifier
) => {
    return await api.post("assessment/request/bulkassign/onBehalf", {
        workflowIdentifier: workflowIdentifier,
        userIdentifier: userIdentifier,
        onBehalfOfUserIdentifiers: onBehalfOfUserIdentifiers,
        dueDate: dueDate,
        batchIdentifier: batchIdentifier,
    });
};
