import Upload from "../../../SharedComponents/Form/Upload";
import React from "react";

export default function UploadVideo({ mediaFile, setMediaFile }) {
    return (
        <div className={"flex flex-col gap-3"}>
            <div
                className={
                    "bg-primary-50 p-3 rounded-xl text-primary-600 text-sm shadow-sm border border-primary-100 border-1"
                }
            >
                The purpose of this video is for you to introduce and showcase yourself. Coachees and admins will be
                able to see this video when they click on your profile.
            </div>
            <Upload
                files={mediaFile}
                setFiles={setMediaFile}
                infoTextClick={"Click to choose video"}
                infoTextSecondary={"or drag and drop video to upload"}
                noFilesUploadedText={"You haven't uploaded a video yet"}
                instructionText={"Only MP4 and MPEG formats accepted"}
                formats={"video/mp4, video/mpeg"}
            />
        </div>
    );
}
