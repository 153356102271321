import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";

import Button from "../../../SharedComponents/Button";

export default function PaginatedTabs(props) {
    const { items, handleAddItem, handleRemoveItem, setSelectedPageIndex, selectedPageIndex } = props;

    return (
        <div className="sticky top-6 z-10 col-span-2 -m-4 my-2 hover:shadow-sm">
            <div className="flex flex-row items-center flex-wrap gap-3 w-full border-y border-slate-200 bg-white py-2.5 px-3.5">
                {items.map((item, index) => (
                    <Button
                        key={index}
                        colour={
                            selectedPageIndex === index
                                ? BUTTON_VARIANT_TYPES.PRIMARY
                                : BUTTON_VARIANT_TYPES.LIGHT_PRIMARY
                        }
                        text={"Page " + (index + 1) + (item.pageTitle ? `: ${item.pageTitle}` : "")}
                        smallButton
                        onClick={() => setSelectedPageIndex(index)}
                    />
                ))}

                <p className="text-slate-200">|</p>

                <Button
                    colour={BUTTON_VARIANT_TYPES.LIGHT_SECONDARY}
                    icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    text={"Add new page"}
                    smallButton
                    onClick={handleAddItem}
                />

                <Button
                    colour={BUTTON_VARIANT_TYPES.LIGHT_RED}
                    icon={<TrashIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    text={"Delete current page"}
                    smallButton
                    disabled={items.length <= 1}
                    onClick={() => handleRemoveItem(selectedPageIndex)}
                />
            </div>
        </div>
    );
}
