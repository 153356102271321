import { useContext } from "react";
import { useNavigate } from "react-router";
import { UsersIcon, PencilIcon, ArrowsUpDownIcon, FlagIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away-subtle.css";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Button from "../../../SharedComponents/Button";
import BasicMotion from "../../../SharedComponents/Motion/BasicMotion";

export default function CoacheeRowWithOverall(props) {
    const { item: coachee } = props;

    const context = useContext(Context);

    const navigate = useNavigate();

    return (
        <tr className={"hover:bg-slate-100 transition-colors group"}>
            <td className="px-4 py-3 whitespace-nowrap w-[120px] justify-center">
                <BasicMotion>
                    <div className="flex items-center cursor-auto">
                        {coachee.score.saleableOverallScore && (
                            <div
                                className={
                                    "rounded-full flex gap-2 items-center px-2 py-1 transition-colors " +
                                    (coachee.score.saleableOverallScore <= 39
                                        ? "bg-red-100  text-red-600  border-red-200"
                                        : coachee.score.saleableOverallScore >= 40 &&
                                          coachee.score.saleableOverallScore < 75
                                        ? "bg-amber-100  text-amber-600  border-amber-200"
                                        : "bg-emerald-100  text-emerald-600  border-emerald-200")
                                }
                            >
                                <FlagIcon className={"block h-3.5 w-3.5 stroke-current stroke-2"} />
                                <span className={"text-xs"}>
                                    <span className={"font-semibold"}>{coachee.score.saleableOverallScore}%</span>
                                    <span className={"font-normal text-[12px]"}>ile</span>
                                </span>
                            </div>
                        )}
                    </div>
                </BasicMotion>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm font-medium text-slate-600"}>{coachee.firstName + " " + coachee.lastName}</div>
                <div className="text-sm text-slate-500 mt-1 transition-colors">{coachee.emailAddress}</div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"flex flex-row gap-3 items-center"}>
                    <div className={"text-xs text-slate-500"}>
                        {coachee.coachIdentifier ? (
                            <div className={"flex items-center justify-between gap-2"}>
                                {context?.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH) && (
                                    <Tippy
                                        content={
                                            "Re-assign " +
                                            (context.terminology.coach ? context.terminology.coach.singular : "Coach")
                                        }
                                        className={
                                            "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                            "bg-primary-600 text-primary-50"
                                        }
                                        arrow={false}
                                        animation={"shift-away-subtle"}
                                        placement={"top-start"}
                                    >
                                        <div
                                            className={
                                                "p-1 bg-primary-500 rounded-full cursor-pointer hover:bg-primary-600 " +
                                                "transition-colors"
                                            }
                                            onClick={() => {
                                                navigate(
                                                    `/assign-${context.terminology.coach.singular.toLowerCase()}`,
                                                    {
                                                        state: {
                                                            coacheeUserIdentifier: coachee.userIdentifier,
                                                            coachUserIdentifier: coachee.coachIdentifier,
                                                            coacheeFirstName: coachee.firstName,
                                                            coacheeLastName: coachee.lastName,
                                                            coacheeEmailAddress: coachee.emailAddress,
                                                            reassignCoach: true,
                                                        },
                                                    }
                                                );
                                            }}
                                        >
                                            <ArrowsUpDownIcon
                                                className={
                                                    "text-white transition-colors h-3 w-3 stroke-current stroke-1"
                                                }
                                            />
                                        </div>
                                    </Tippy>
                                )}
                                <div className={"text-sm font-medium text-slate-600"}>{coachee.coachName}</div>
                            </div>
                        ) : (
                            <Button
                                text={
                                    "Assign " +
                                    (context.terminology.coach ? context.terminology.coach.singular : "Coach")
                                }
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                icon={<UsersIcon className="block h-3.5 w-3.5 mr-2" />}
                                extraStyling={"text-xs text-white"}
                                iconPositionLeft
                                smallButton
                                onClick={() => {
                                    navigate(`/assign-${context.terminology.coach.singular.toLowerCase()}`, {
                                        state: {
                                            state: {
                                                coacheeUserIdentifier: coachee.userIdentifier,
                                                coacheeFirstName: coachee.firstName,
                                                coacheeLastName: coachee.lastName,
                                                coacheeEmailAddress: coachee.emailAddress,
                                            },
                                        },
                                    });
                                }}
                                disabled={!context?.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.ASSIGN_COACH)}
                            />
                        )}
                    </div>
                </div>
            </td>

            <td className="px-4 py-3 whitespace-nowrap">
                <Button
                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                    icon={<PencilIcon className="block h-3.5 w-3.5" />}
                    extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full p-2"}
                    iconPositionLeft
                    onClick={() => {
                        navigate(`/${context.terminology.coachee.singular.toLowerCase()}-profile`, {
                            state: {
                                userIdentifier: coachee.userIdentifier,
                            },
                        });
                    }}
                />
            </td>
        </tr>
    );
}
