import { useContext, useEffect, useState } from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate } from "react-router-dom";

import { getCohorts } from "../../../../utils/api";
import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import ReportRow from "./ReportRow";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";

export default function Reports() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [cohorts, setCohorts] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [currentFilter, setCurrentFilter] = useState("all");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const context = useContext(Context);

    const location = useLocation();

    const reports = context?.reports;

    useEffect(() => {
        const userCohort = (userIdentifier) => {
            let userCohorts = [];
            cohorts.forEach((cohort) => {
                if (cohort.users.some((e) => e.userIdentifier === userIdentifier)) {
                    userCohorts.push(cohort.name);
                }
            });
            return userCohorts;
        };

        const sanitizedReports = reports?.map((report) => ({
            ...report,
            cohorts: userCohort(report.userIdentifier),
        }));

        const handleFilter = () => {
            setLoading(true);
            if (currentFilter === "all") {
                setFilteredReports(sanitizedReports);
            } else {
                setFilteredReports(sanitizedReports.filter((report) => report.cohorts.includes(currentFilter)));
            }
            setLoading(false);
        };
        handleFilter();
    }, [cohorts, currentFilter, reports]);

    useEffect(() => {
        const fetchCohorts = async () => {
            setLoading(true);
            try {
                const response = await getCohorts();
                setCohorts(response);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }
            setLoading(false);
        };
        fetchCohorts();
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.alertType) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
            }
        }
    }, [location]);

    return (
        <DashboardLayout
            title={"Reports"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS]}
            actionButtonRequiredPermissions={[CUSTOMER_PORTAL_PERMISSIONS.UPLOAD_REPORTS]}
            actionButton
            actionButtonText={"Upload Report"}
            actionButtonIcon={<ArrowUpTrayIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
            actionButtonOnClick={() => navigate("/upload-report")}
        >
            {filteredReports || reports ? (
                <>
                    <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
                    <PaginatedSearch
                        items={filteredReports}
                        itemName={"reports"}
                        searchAttributes={["userName", "name", "tags", "cohorts", "userEmail", "jobSeekerId"]}
                        columnNames={[
                            context.terminology.coachee ? context.terminology.coachee.singular : "Coachee",
                            "Report name",
                            "Tags",
                            "Cohorts",
                            "Version",
                            "Status",
                            "Download/View",
                            "Manage",
                        ]}
                        ItemRow={ReportRow}
                        loading={loading}
                        setAlert={setAlert}
                        filters={
                            <div className={"lg:flex items-center justify-between w-full"}>
                                <div>
                                    <div className={"text-sm text-slate-400 mb-2"}>Cohort</div>
                                    <select
                                        id="default"
                                        name="default"
                                        className={
                                            "text-primary-600 cursor-pointer hover:text-primary-700 bg-primary-50 " +
                                            "p-2 rounded-xl shadow-sm flex w-fit font-medium pl-3 pr-10 py-2 " +
                                            "text-left focus:outline-none outline-none focus:border-transparent " +
                                            "ring-0 border-none sm:text-sm border border-primary-100"
                                        }
                                        onChange={(e) => setCurrentFilter(e.target.value)}
                                    >
                                        <option value="all">All cohorts</option>

                                        {cohorts.map((cohort, index) => (
                                            <option key={index} value={cohort.name}>
                                                {cohort.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }
                        customPlaceholder="Search reports by report name, user name, user email address, associated user ID or cohort"
                    />
                </>
            ) : (
                <Alert type={ALERT_TYPE.WARNING} active={true}>
                    <div className="text-sm">
                        Oops! It looks like we are having some trouble retrieving your reports. We are working on it. If
                        you require immediate assistance, please contact us at{" "}
                        <a
                            className="link text-primary-600 hover:text-primary-700"
                            href="mailto:techsupport@psychpress.com.au?subject=The assessments server is down."
                        >
                            techsupport@psychpress.com.au
                        </a>{" "}
                        or call us at{" "}
                        <a className="link text-primary-600 hover:text-primary-700" href="tel:+61396700590">
                            +61 (0)3 9670 0590
                        </a>
                    </div>
                </Alert>
            )}
        </DashboardLayout>
    );
}
