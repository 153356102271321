import caseyLogo from "../../assets/city-of-casey-logo.png";

export const sampleCapabilityMapCaseyScore = {
    organisationLogo: caseyLogo,
    reportName: "Capability Map",
    snapshot: [
        "The Human Capabilities are the markers of a person’s enduring attributes we know, from 30 years of research, make each of us unique. Results are intended to provide deeper understanding and insights to a allow you to harness your teams innate strengths and potential.",
        "In the context of work, profile results should be considered in combination with all other available and relevant information. ",
    ],
    understandYourTeam: {
        title: "Understand your Team",
        description: [
            "Your team is made up of unique individuals, each with different combinations of Human capabilities. Understand how to harness their potential",
        ],
    },
    reportLevelNames: ["Support & Action", "Guide", "Execute & Improve", "Shape"],
    groups: [
        {
            name: "Overall",
            capabilityScores: [
                {
                    name: "Strategic Direction & Purpose",
                    selfAssessmentScore: 67,
                    mindsetScore: 50,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 65,
                    mindsetScore: 76,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 89,
                    mindsetScore: 67,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 78,
                    mindsetScore: 65,
                },
                {
                    name: "Ethics & Integrity",
                    selfAssessmentScore: 78,
                    mindsetScore: 56,
                },
                {
                    name: "Shared Leadership",
                    selfAssessmentScore: 67,
                    mindsetScore: 78,
                },
                {
                    name: "Improve & Innovate",
                    selfAssessmentScore: 78,
                    mindsetScore: 70,
                },
                {
                    name: "Build High Performing Teams",
                    selfAssessmentScore: 67,
                    mindsetScore: 78,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Strategic Direction & Purpose",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Ethics & Integrity",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Shared Leadership",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Improve & Innovate",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Build High Performing Teams",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Customer Focus",
                    highlightType: "Most Prevalent",
                    percentScore: "56%",
                    description: "56% of the team are naturally strong in this area",
                },
                {
                    name: "Collaboration",
                    highlightType: "Potential to Unlock",
                    percentScore: "67%",
                    description: "67% of the team have untapped potential in this area",
                },
                {
                    name: "Shared Leadership",
                    highlightType: "Blindspots",
                    percentScore: "12%",
                    description: "12% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "purple",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Ethics & Integrity", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "teal",
                    data: [
                        { name: "Shared Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Build High Performing Teams", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics & Integrity", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
        {
            name: "City Planning and Infrastructure",
            capabilityScores: [
                {
                    name: "Strategic Direction & Purpose",
                    selfAssessmentScore: 87,
                    mindsetScore: 90,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 65,
                    mindsetScore: 54,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 76,
                    mindsetScore: 87,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 65,
                    mindsetScore: 87,
                },
                {
                    name: "Ethics & Integrity",
                    selfAssessmentScore: 67,
                    mindsetScore: 88,
                },
                {
                    name: "Shared Leadership",
                    selfAssessmentScore: 98,
                    mindsetScore: 78,
                },
                {
                    name: "Improve & Innovate",
                    selfAssessmentScore: 78,
                    mindsetScore: 86,
                },
                {
                    name: "Build High Performing Teams",
                    selfAssessmentScore: 47,
                    mindsetScore: 46,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Strategic Direction & Purpose",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Ethics & Integrity",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Shared Leadership",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Improve & Innovate",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Build High Performing Teams",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Leadership",
                    highlightType: "Most Prevalent",
                    percentScore: "76%",
                    description: "76% of the team are naturally strong in this area",
                },
                {
                    name: "Ethics",
                    highlightType: "Potential to Unlock",
                    percentScore: "34%",
                    description: "34% of the team have untapped potential in this area",
                },
                {
                    name: "Shared Leadership",
                    highlightType: "Blindspots",
                    percentScore: "12%",
                    description: "12% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "teal",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Improve & Innovate", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "purple",
                    data: [
                        { name: "Shared Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Build High Performing Teams", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics & Integrity", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
        {
            name: "Community Life",
            capabilityScores: [
                {
                    name: "Strategic Direction & Purpose",
                    selfAssessmentScore: 53,
                    mindsetScore: 50,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 52,
                    mindsetScore: 48,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 61,
                    mindsetScore: 58,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 45,
                    mindsetScore: 51,
                },
                {
                    name: "Ethics & Integrity",
                    selfAssessmentScore: 55,
                    mindsetScore: 53,
                },
                {
                    name: "Shared Leadership",
                    selfAssessmentScore: 51,
                    mindsetScore: 48,
                },
                {
                    name: "Improve & Innovate",
                    selfAssessmentScore: 54,
                    mindsetScore: 51,
                },
                {
                    name: "Build High Performing Teams",
                    selfAssessmentScore: 47,
                    mindsetScore: 46,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Strategic Direction & Purpose",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Ethics & Integrity",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Shared Leadership",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Improve & Innovate",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Build High Performing Teams",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Customer Focus",
                    highlightType: "Most Prevalent",
                    percentScore: "65%",
                    description: "65% of the team are naturally strong in this area",
                },
                {
                    name: "Improve & Innovate",
                    highlightType: "Potential to Unlock",
                    percentScore: "50%",
                    description: "50% of the team have untapped potential in this area",
                },
                {
                    name: "Shared Leadership",
                    highlightType: "Blindspots",
                    percentScore: "21%",
                    description: "21 of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "teal",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Improve & Innovate", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "purple",
                    data: [
                        { name: "Shared Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Build High Performing Teams", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics & Integrity", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
        {
            name: "Customer and Corporate Services",
            capabilityScores: [
                {
                    name: "Strategic Direction & Purpose",
                    selfAssessmentScore: 78,
                    mindsetScore: 86,
                },
                {
                    name: "Adaptive Mindset",
                    selfAssessmentScore: 67,
                    mindsetScore: 57,
                },
                {
                    name: "Collaboration",
                    selfAssessmentScore: 67,
                    mindsetScore: 56,
                },
                {
                    name: "Customer Focus",
                    selfAssessmentScore: 87,
                    mindsetScore: 87,
                },
                {
                    name: "Ethics & Integrity",
                    selfAssessmentScore: 54,
                    mindsetScore: 78,
                },
                {
                    name: "Shared Leadership",
                    selfAssessmentScore: 77,
                    mindsetScore: 88,
                },
                {
                    name: "Improve & Innovate",
                    selfAssessmentScore: 87,
                    mindsetScore: 56,
                },
                {
                    name: "Build High Performing Teams",
                    selfAssessmentScore: 47,
                    mindsetScore: 46,
                },
            ],
            individualScores: [
                {
                    name: "Ivy Graham",
                    score: [
                        {
                            name: "Strategic Direction & Purpose",
                            selfAssessmentScore: 45,
                            mindsetScore: 45,
                        },
                        {
                            name: "Adaptive Mindset",
                            selfAssessmentScore: 36,
                            mindsetScore: 56,
                        },
                        {
                            name: "Collaboration",
                            selfAssessmentScore: 67,
                            mindsetScore: 78,
                        },
                        {
                            name: "Customer Focus",
                            selfAssessmentScore: 87,
                            mindsetScore: 75,
                        },
                        {
                            name: "Ethics & Integrity",
                            selfAssessmentScore: 77,
                            mindsetScore: 55,
                        },
                        {
                            name: "Shared Leadership",
                            selfAssessmentScore: 68,
                            mindsetScore: 57,
                        },
                        {
                            name: "Improve & Innovate",
                            selfAssessmentScore: 34,
                            mindsetScore: 64,
                        },
                        {
                            name: "Build High Performing Teams",
                            selfAssessmentScore: 54,
                            mindsetScore: 45,
                        },
                    ],
                },
            ],
            highlights: [
                {
                    name: "Customer Focus",
                    highlightType: "Most Prevalent",
                    percentScore: "88%",
                    description: "88% of the team are naturally strong in this area",
                },
                {
                    name: "Collaboration",
                    highlightType: "Potential to Unlock",
                    percentScore: "55%",
                    description: "55% of the team have untapped potential in this area",
                },
                {
                    name: "Shared Leadership",
                    highlightType: "Blindspots",
                    percentScore: "9%",
                    description: "9% of the team may find this area challenging and require more effort to focus",
                },
            ],
            mindsetVsSelfAssessmentChartData: [
                {
                    name: "Potential to be unlocked",
                    colour: "teal",
                    data: [
                        {
                            name: "Collaboration",
                            percentScore: 59,
                        },
                        { name: "Improve & Innovate", percentScore: 49 },
                        {
                            name: "Customer Focus",
                            percentScore: 44,
                        },
                        {
                            name: "Adaptive Mindset",
                            percentScore: 21,
                        },
                    ],
                },
                {
                    name: "Blindspots",
                    colour: "purple",
                    data: [
                        { name: "Shared Leadership", percentScore: 11, category: "Blindspots" },
                        { name: "Build High Performing Teams", percentScore: 49, category: "Blindspots" },
                        { name: "Improve & Innovate", percentScore: 50, category: "Blindspots" },
                        { name: "Ethics & Integrity", percentScore: 65, category: "Blindspots" },
                    ],
                },
            ],
        },
    ],
};
