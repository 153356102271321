import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Bars2Icon,
    CalendarIcon,
    ClockIcon,
    ArrowUpTrayIcon,
    BuildingLibraryIcon,
    MapPinIcon,
    ArchiveBoxIcon,
    ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES, COACH_PLATFORM_PERMISSIONS, RESOURCE_TYPES } from "../../../../utils/constants";
import { getAllResourceTypes, uploadResource } from "../../../../utils/api";
import { Context } from "../../../../context/Context";

import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import Button from "../../../SharedComponents/Button";
import Upload from "../../../SharedComponents/Form/Upload";
import Input from "../../../SharedComponents/Form/Input";
import MultiSelect from "../../../SharedComponents/Form/MultiSelect";
import Textarea from "../../../SharedComponents/Form/Textarea";
import AddLinks from "../Coachees/CoacheeProfile/InteractiveForms/AddLinks";

export default function UploadResource() {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [name, setName] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [resourceType, setResourceType] = useState("");
    const [description, setDescription] = useState("");
    const [isOrganisationResource, setIsOrganisationResource] = useState(false);
    const [resourceLinks, setResourceLinks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [durationMins, setDurationMins] = useState("");
    const [startTime, setStartTime] = useState("");
    const [location, setLocation] = useState("");
    const [selectableResourceTypes, setSelectableResourceTypes] = useState([]);
    const [loadingResourceTypes, setLoadingResourceTypes] = useState(false);

    const context = useContext(Context);

    const navigate = useNavigate();

    const handleUploadResource = async () => {
        setLoading(true);

        if (!name) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("Resource name is empty. Please enter a resource name.");
            setLoading(false);
            return;
        }

        try {
            if (resourceType === RESOURCE_TYPES.READING) {
                // null can't be passed in multipart-formdata, therefore '' are used.
                // backend will render '' to null automatically.
                await uploadResource(
                    name,
                    subtitle,
                    description,
                    resourceType,
                    "",
                    durationMins,
                    "",
                    isOrganisationResource,
                    resourceLinks,
                    files
                );
            } else {
                await uploadResource(
                    name,
                    subtitle,
                    description,
                    resourceType,
                    startTime,
                    durationMins,
                    location,
                    isOrganisationResource,
                    resourceLinks,
                    files
                );
            }

            navigate("/resources", {
                state: {
                    alertType: ALERT_TYPE.SUCCESS,
                    alertMessage: 'Successfully uploaded resource "' + name + '"',
                },
            });
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchResourceTypes = async () => {
            setLoadingResourceTypes(true);

            try {
                const response = await getAllResourceTypes();

                const sanitizedResponse = response.map((resourceType) => ({
                    value: resourceType,
                    label: resourceType,
                }));

                setSelectableResourceTypes(sanitizedResponse);
            } catch (err) {
                setAlert(err.message);
                setAlertType(ALERT_TYPE.ERROR);
            }

            setLoadingResourceTypes(false);
        };

        fetchResourceTypes();
    }, [setAlert, setAlertType]);

    return (
        <DashboardLayout
            title={"Upload resource"}
            requiredPermissions={[
                COACH_PLATFORM_PERMISSIONS.UPLOAD_OWN_RESOURCES,
                COACH_PLATFORM_PERMISSIONS.UPLOAD_ORG_RESOURCES,
            ]}
        >
            <p className="mb-4 text-sm text-slate-500">Please complete the form below to upload a resource:</p>
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <div className={"focus:outline-none shadow-sm border-0 rounded-xl w-full bg-white outline-none"}>
                <div className={"flex justify-between flex-col"}>
                    <div className={"p-4 flex flex-col gap-6 border border-slate-100 border-1 rounded-t-xl"}>
                        <div className={"flex flex-col lg:flex-row gap-6"}>
                            <Input
                                type="text"
                                label="Name"
                                id="name"
                                placeholder={"Resource name"}
                                icon={<BuildingLibraryIcon className="h-4 w-4 text-slate-500" />}
                                onChange={setName}
                                value={name}
                                extraStyling={"basis-1/2"}
                            />
                            <Input
                                type="text"
                                label="Subtitle"
                                id="subtitle"
                                placeholder={"Subtitle"}
                                icon={<Bars2Icon className="h-4 w-4 text-slate-500" />}
                                onChange={setSubtitle}
                                value={subtitle}
                                extraStyling={"basis-1/2"}
                                optional
                            />
                        </div>
                        <Textarea
                            label="Description"
                            id="description"
                            placeholder={"Description"}
                            onChange={setDescription}
                            extraStyling={"basis-full"}
                            value={description}
                            optional
                        />
                        <div className={"flex flex-col lg:flex-row gap-6"}>
                            <div className={"basis-1/2"}>
                                <div className={"w-full mb-2"}>
                                    <label className="text-sm font-medium text-primary-600 w-full ">
                                        Resource upload
                                    </label>
                                    <p className="mb-4 mt-2 text-xs text-slate-400">
                                        If ticked, you will upload this resource as your organisation and your whole
                                        organisation will have access to it.
                                    </p>
                                </div>
                                <div
                                    className={
                                        "flex items-center " +
                                        (!context.userPermissions.includes(
                                            COACH_PLATFORM_PERMISSIONS.UPLOAD_ORG_RESOURCES
                                        ) && "opacity-50")
                                    }
                                >
                                    <input
                                        id="isOrganisationResource"
                                        name="isOrganisationResource"
                                        type="checkbox"
                                        className="focus:ring- h-4 w-4 text-primary-600 border-gray-300 rounded-md"
                                        onChange={(e) => setIsOrganisationResource(e.target.checked)}
                                        disabled={
                                            !context.userPermissions.includes(
                                                COACH_PLATFORM_PERMISSIONS.UPLOAD_ORG_RESOURCES
                                            )
                                        }
                                    />
                                    <label htmlFor="push-nothing" className="ml-3 block text-sm text-slate-600">
                                        Upload as organisation
                                    </label>
                                </div>
                            </div>

                            <MultiSelect
                                items={selectableResourceTypes}
                                setChosenItems={setResourceType}
                                placeholder={"Select resource type"}
                                labelText={"Type"}
                                value={resourceType}
                                extraStyling={"basis-1/2"}
                                icon={<ArchiveBoxIcon className="h-4 w-4 text-slate-500" />}
                                loading={loadingResourceTypes}
                            />
                        </div>

                        {resourceType === RESOURCE_TYPES.READING && (
                            <div className={"flex flex-col lg:flex-row gap-6"}>
                                <Input
                                    type="number"
                                    label="Reading duration in minutes"
                                    id="durationMinutes"
                                    placeholder={"Estimated reading duration (minutes)"}
                                    icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                                    onChange={setDurationMins}
                                    value={durationMins}
                                />
                            </div>
                        )}

                        {resourceType === RESOURCE_TYPES.WORKSHOP && (
                            <>
                                <div className={"flex flex-col lg:flex-row gap-6"}>
                                    <Input
                                        id="startTime"
                                        type="datetime-local"
                                        label="Workshop date time"
                                        placeholder={"Date time"}
                                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setStartTime}
                                        value={startTime}
                                    />

                                    <Input
                                        type="number"
                                        label="Workshop duration in minutes"
                                        id="durationMinutes"
                                        placeholder={"Workshop duration (minutes)"}
                                        icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setDurationMins}
                                        value={durationMins}
                                    />
                                </div>

                                <div className={"flex flex-col lg:flex-row gap-6"}>
                                    <Input
                                        id="location"
                                        type="text"
                                        label="Workshop location"
                                        placeholder={"Workshop location"}
                                        icon={<MapPinIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setLocation}
                                        value={location}
                                    />
                                </div>
                            </>
                        )}

                        {resourceType === RESOURCE_TYPES.COURSE && (
                            <>
                                <div className={"flex flex-col lg:flex-row gap-6"}>
                                    <Input
                                        id="startTime"
                                        type="datetime-local"
                                        label="Course date time"
                                        placeholder={"Date time"}
                                        icon={<CalendarIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setStartTime}
                                        value={startTime}
                                    />

                                    <Input
                                        type="number"
                                        label="Course duration in minutes"
                                        id="durationMinutes"
                                        placeholder={"Course duration (minutes)"}
                                        icon={<ClockIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setDurationMins}
                                        value={durationMins}
                                    />
                                </div>

                                <div className={"flex flex-col lg:flex-row gap-6"}>
                                    <Input
                                        id="location"
                                        type="text"
                                        label="Course location"
                                        placeholder={"Course location"}
                                        icon={<MapPinIcon className="h-4 w-4 text-slate-500" />}
                                        onChange={setLocation}
                                        value={location}
                                    />
                                </div>
                            </>
                        )}

                        {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.ADD_RESOURCE_LINK) && (
                            <div>
                                <div className="text-sm font-medium text-primary-600 w-full mt-6 mb-4">Links</div>
                                <AddLinks
                                    itemName={"link"}
                                    links={resourceLinks ? resourceLinks : []}
                                    setLinks={setResourceLinks}
                                />
                            </div>
                        )}

                        <div>
                            <div className="text-sm font-medium text-primary-600 w-full mb-2">Attachments</div>
                            <Upload
                                files={files}
                                setFiles={setFiles}
                                infoTextClick={"Click to choose file"}
                                infoTextSecondary={"or drag and drop file to upload"}
                                noFilesUploadedText={"You haven't uploaded any files"}
                                formats={
                                    "image/jpeg, image/png, application/pdf, application/vnd.ms-powerpoint, " +
                                    "application/vnd.ms-excel, application/msword, " +
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document, "
                                }
                            />
                        </div>
                    </div>

                    <div
                        className={
                            "p-4 bg-gradient-to-l from-primary-50 to-primary-100 flex items-center justify-end gap-4 " +
                            "text-right rounded-b-xl w-full"
                        }
                    >
                        <Button
                            colour={BUTTON_VARIANT_TYPES.GRAY}
                            text={"Back"}
                            iconPositionLeft
                            icon={<ArrowUturnLeftIcon className="block h-3.5 w-3.5  mr-2" />}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Upload resource"
                            icon={<ArrowUpTrayIcon className="block h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                            onClick={handleUploadResource}
                            loading={loading}
                            iconPositionLeft
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
