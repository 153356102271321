import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../../utils/constants";
import { updateCoachTrainingModuleStatus } from "../../../../../utils/api";

import { ALERT_TYPE } from "../../../../SharedComponents/Alert";
import Button from "../../../../SharedComponents/Button";
import PaginatedSearch from "../../../../SharedComponents/Pagination/PaginatedSearch";
import TrainingRow from "./TrainingRow";

export default function CoachTrainingRow(props) {
    const { item: coach, fetchItems, setAlert, setAlertType, latestItemInteracted: latestCoachInteracted } = props;

    const [modulesCompleted, setModulesCompleted] = useState(0);
    const [showSubsequentFiles, setShowSubsequentFiles] = useState(false);

    const handleShowSubsequentFiles = () => {
        setShowSubsequentFiles((showSubsequentFiles) => !showSubsequentFiles);
    };

    const handleUpdateTrainingModuleStatus = async (coachTrainingModuleIdentifier, statusName) => {
        try {
            await updateCoachTrainingModuleStatus(coachTrainingModuleIdentifier, statusName);

            setAlert("Successfully edited " + coach.coachName + "'s training module status");
            setAlertType(ALERT_TYPE.SUCCESS);

            setTimeout(() => {
                fetchItems(coach.coachEmail);
            }, 500);
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
    };

    useEffect(() => {
        coach.coachTrainingStatus.forEach((trainingModule) => {
            if (trainingModule.status === "Completed") {
                setModulesCompleted((modulesCompleted) => modulesCompleted + 1);
            }
        });
    }, [coach.coachTrainingStatus]);

    useEffect(() => {
        if (latestCoachInteracted === coach.coachEmail) {
            setShowSubsequentFiles(true);
        }
    }, [coach.coachEmail, coach.identifier, latestCoachInteracted]);

    return (
        <>
            <tr className="transition-colors">
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"text-sm font-medium text-primary-600"}>{coach.coachName}</div>
                    <div className="text-sm text-slate-500 mt-1 group-hover:text-primary-600 transition-colors">
                        {coach.coachEmail}
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"text-xs text-slate-400 italic "}>Location not found</div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"text-sm text-slate-500 group-hover:text-primary-600 transition-colors"}></div>
                    <div className="relative w-full bg-slate-300 rounded-xl overflow-hidden">
                        <motion.div
                            initial={{ width: 0 }}
                            animate={{ width: (modulesCompleted / coach.coachTrainingStatus.length) * 100 + "%" }}
                            transition={{ duration: 4 }}
                        >
                            <div
                                className={
                                    "bg-secondary-600 text-xs font-medium text-left transition ease-in-out rounded-xl " +
                                    "p-1.5 px-2 text-white font-medium"
                                }
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {modulesCompleted}/{coach.coachTrainingStatus.length} modules completed
                            </div>
                        </motion.div>
                    </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"flex flex-row items-center"}>
                        <Button
                            colour={BUTTON_VARIANT_TYPES.SECONDARY}
                            icon={
                                <ChevronRightIcon
                                    className={
                                        "h-4 w-4 stroke-current stroke-1 transition-transform transform " +
                                        (showSubsequentFiles && "rotate-90")
                                    }
                                />
                            }
                            extraStyling={"text-xs h-8 w-8 rounded-full p-0 items-center flex justify-center"}
                            iconPositionLeft
                            onClick={handleShowSubsequentFiles}
                        />
                    </div>
                </td>
            </tr>
            <AnimatePresence>
                {showSubsequentFiles && (
                    <tr>
                        <td className="bg-slate-100" colSpan={4}>
                            <motion.section
                                initial="closed"
                                animate="open"
                                exit="closed"
                                variants={{
                                    open: {
                                        opacity: 1,
                                        height: coach.coachTrainingStatus.length === 0 ? "auto" : "428px",
                                    },
                                    closed: { opacity: 0, height: 0 },
                                }}
                                transition={{ type: "tween", duration: 0.4, stiffness: 100 }}
                            >
                                <div className="bg-slate-100 p-4">
                                    <PaginatedSearch
                                        items={coach.coachTrainingStatus}
                                        itemName={"Training modules"}
                                        searchAttributes={["trainingModuleName", "trainingModuleProvider", "status"]}
                                        columnNames={["Training Module", "Provider", "Status", "Mark as"]}
                                        ItemRow={TrainingRow}
                                        updateItemStatus={handleUpdateTrainingModuleStatus}
                                        customItemsPerPage={4}
                                    />
                                </div>
                            </motion.section>
                        </td>
                    </tr>
                )}
            </AnimatePresence>
        </>
    );
}
