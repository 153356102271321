export const apprenticeshipRiskCareerAssessmentMockScoreData = {
    reportName: "Apprenticeship Risk & Career Assessment",
    reportDescription:
        "The purpose of the assessment has been to provide further information to assess the work readiness and employment retention. The second section of this report is intended to be used to understand what potential derailers an individual may experience in being able to be effective at work. The report provides an overview of what the candidate sees as their current state of wellbeing. It is intended to assist employers to help individuals be effective in the workplace as they manage these challenges.",
    apprenticeshipRisks: {
        applicant:
            "The following profile has been based on psychological and cognitive screening tests. It provides elements of insight and understanding into the participant's relevant strengths and development needs in the context of competencies predictive of success. It is recommended that all the information provided in this profile be considered in conjunction with your individual business competency models in making your personnel selection or development decisions. It is also recommended that the information in this leadership profile be combined with other information and managers' observations gathered about the applicant. Information from the profile is designed to complement, not replace, information from other sources. This may include previous work history, managers /supervisors' feedback etc.",
        reportFields: {
            englishReadingAbility: "Not well",
            englishWritingAbility: "Well",
            englishSpeakingAbility: "Very well",
            HaveStableSupport: "Yes",
            NeedAnySupport: ["Caring Responsibilities", "Family Stress"],
            HaveStartedTraining: "Yes",
            CopingWithStudy: "Yes",
            HighestQualification: "Certificate III",
            QualificationName: "Diploma",
            StillAtSchool: "No",
            MainActivityLastTwoYears: "Paid Full-Time Work",
            LongestLengthEmployment: "2 - 5 years",
            WhatBasisEmployed: "Full Time",
            HappyWithHours: "Yes",
            AlternativeEmployment: "No",
            StableSafeAccommodation: "Yes",
            RiskOfHomeless: "No",
            CostOfTransport: "Yes",
            RequriedWorkCloths: "Yes",
            ManagingWithPay: "Yes",
            HealthIssues: "Yes",
            WhatHealthIssues: "Mental health issues",
            DrugsOrAlcohol: "No",
            NeedDriversLicence: "Yes",
            DoOwnCar: "No",
            CurrentLicense: "Yes - Open Manual",
            IssuesGettingToWork: "Yes",
            IssuesGettingToWorkDescription: "Public transportation delays",
            AnyLegaIssues: "Yes",
            TimeInCurrentPosition: "Less than 1 week",
            HowImportant: 5,
            WillBenefit: "Yes",
            HowSatisfied: 5,
            UnderstandRole: "Yes",
            ConfidentKeepJob: "Yes",
            RelationshipsAtWork: "Yes",
            WhoToGoToForHelp: "Yes",
            WorkEnvironmentComfort: "Yes",
            WorkLifeBalance: "Yes",
            FinalComments: "I am excited to start this apprenticeship!",
        },
    },
    headfirst: {
        reportFields: [
            {
                name: "Anxiety",
                version: "1",
                description: "",
                icon: "anxietyIllustrationHeadFirst",
                category: "HeadFirst",
                subCategories: [
                    { name: "PreQuestion", strategies: "", band_description: "" },
                    {
                        name: "Fear of failure",
                        strategies: "",
                        band_description: "",
                    },
                    {
                        name: "Intensity of worry/anxiety",
                        strategies: "",
                        band_description: "",
                    },
                    {
                        name: "Anxiety avoidance/ apprehension",
                        strategies:
                            "Supporting the employee during high-pressure tasks and graduated exposure to new tasks may provide a supportive environment, and reduce potential avoidance behaviour. Welcoming and rewarding active participation in discussions involving decisions that affect arjun dhakal's working situations will help arjun dhakal develop a greater sense of control. Providing clear and obtainable expectations that are suitable to arjun dhakal's competencies will also enhance engagement and reduce the likelihood of avoidance. Provision of resources to prepare for tasks ahead of time can reduce anxiety and avoidance behaviour.",
                        band_description:
                            "This result indicates that arjun dhakal experiences moderate level of anxiety avoidance/apprehension.",
                    },
                ],
                rawScore: 5,
                percentage: 31,
                strategies: "",
                band_description:
                    "Overall, these results indicate that arjun dhakal persistently reports mild anxiety.",
            },
            {
                name: "Depression",
                version: "1",
                description: "depressionIllustrationHeadFirst",
                icon: "",
                category: "HeadFirst",
                subCategories: [
                    { name: "PreQuestion", strategies: "", band_description: "" },
                    {
                        name: "Motivation",
                        strategies:
                            "An employer can support arjun dhakal's performance by providing work goals that are specific, measurable, achievable, relevant, and timebound (SMART).Further, goals could be broken down into smaller tasks that allow arjun dhakal to celebrate small milestones and recognise daily progress. Where possible, arjun dhakal should be allocated tasks that are challenging but interesting, to encourage growth but maintain attention.",
                        band_description: "This result indicates that arjun dhakal often has low motivation.",
                    },
                    {
                        name: "Perceived self-worth",
                        strategies:
                            "By exploring arjun dhakal's areas of strengths and weaknesses, employers can delagate tasks thoughtfully to grow arjun dhakal's confidence and positive contributions to the organisation. Open and ongoing communication with arjun dhakal, including on their perceived strengths and challenges, contributions to the team, and to provide feedback will be beneficial. An employer can support arjun dhakal's performance by expressing any negative feedback in a constructive way, and by providing positive feedback and encouragement as often as is appropriate.",
                        band_description:
                            "This result indicates that arjun dhakal often has moderate feelings of worthlessness.",
                    },
                    {
                        name: "Hopelessness about future",
                        strategies: "",
                        band_description: "",
                    },
                ],
                rawScore: 6,
                percentage: 38,
                strategies:
                    "An employer can aid arjun dhakal by encouraging [him/her/them] to break tasks into smaller, more manageable pieces, and providing a supportive environment. Additonally, it may be prudent to provide access to an external provider such as an employee assistance program or a Disability Employment Services provider to assist them to manage their thoughts and emotions in the workplace.",
                band_description:
                    "Overall, these results indicate that arjun dhakal often feels moderately despondant, sad or depressed.",
            },
            {
                name: "Stress",
                version: "1",
                description: "",
                icon: "stressIllustrationHeadFirst",
                category: "HeadFirst",
                subCategories: [
                    { name: "PreQuestion", strategies: "", band_description: "" },
                    {
                        name: "Agitation",
                        strategies:
                            "Given arjun dhakal may struggle to keep emotional composure when they are put in a stressful situation, an employer can support their performance by perceptive workplace management. Including appropriate induction, provision of work tasks at an appropriate level of difficulty and completion time, clear performance expectations, getting necessary resources or support from colleagues, and setting some work-life boundaries.",
                        band_description:
                            "This result indicates that arjun dhakal has a moderate awareness of greater susceptibility to stress than others.",
                    },
                    {
                        name: "Ability to relax/destress",
                        strategies:
                            "An employer can support arjun dhakal's performance by ensuring they are able to maintain a healthy work/life balance to allow arjun dhakal time to unwind and relax. Managers may not only provide stress-management resources on how to de-stress during demanding situations at work, but outline how to develop healthy responses beyond work, instead of alcohol use or fast food. Any form of physical activity is beneficial, as is making time for hobbies and favourite activities. Reading a novel, gardening, going to community activites, concerts, films, amateur theatre or playing games with the family \u2013 whatever brings pleasure. Accepting help from trusted friends and family members can improve arjun dhakal's ability to manage stress. Getting enough good-quality sleep is also important.",
                        band_description:
                            "This result indicates that arjun dhakal often feels moderately unable to unwind from stress.",
                    },
                    { name: "Tense", strategies: "", band_description: "" },
                ],
                rawScore: 7,
                percentage: 44,
                strategies:
                    "An employer can support arjun dhakal's performance by prioritising de-stress activities in the workplace, such as ensuring regular tea and lunch breaks are taken; balancing group and individual work; promoting a break to celebrate a group or goal achievement; and supporting flexible employment options such as a part time contact or recreational leave at half pay, or access to employer resources such as an employee sssistance provider for further stress management support. Uncertainty is also reduced when management actions are consistent with organisational values.",
                band_description:
                    "Overall, these results indicate that arjun dhakal often feels a moderate level of stress.",
            },
            {
                name: "Substance Use",
                version: "1",
                description: "",
                icon: "substanceAbuseIllustrationHeadFirst",
                category: "HeadFirst",
                subCategories: [
                    {
                        name: "PreQuestion",
                        strategies: "",
                        band_description:
                            "This score indicates arjun dhakal has a high recognition of how they are using alcohol or drugs as a coping strategy.",
                    },
                    {
                        name: "Disregard for alcohol/drug consequences",
                        strategies:
                            "An employer can support arjun dhakal's work performance by translating such recognition into lifestyle and workplace behavour change by communicating SMART performance and conduct expectations using an active voice and assertive language, indicating alcohol/drug abuse is not tolerated. Open-ended questions and active listening will support reflection about alcohol and/or drug use with reference to workplace standards, safety and career prospects.",
                        band_description:
                            "This result indicates that arjun dhakal has a moderate recognition that their alcohol/drug use continues despite adverse consequences.",
                    },
                    {
                        name: "Alcohol/substance use concern",
                        strategies:
                            "An employer can support personal reflection around appropriate drinking/drug usage and support arjun dhakal's performance by provide them with resources about substance abuse to objectively judge whether their alcohol/substance use might impair employment prospects or career progress.",
                        band_description:
                            "arjun dhakal has indicated that their alcohol/substance use is moderately concerning.",
                    },
                    {
                        name: "Recognition of consequences",
                        strategies: "",
                        band_description: "",
                    },
                ],
                rawScore: 7,
                percentage: 78,
                strategies:
                    "An employer can support arjun dhakal's performance using environmental strategies that support a lifestyle change. These include prioritising employment that does not involve alcohol, for example hospitality or teams with a drinking or drug culture; reference to an Alcohol and Drugs Policy; an awareness of alcohol and/or drugs as an occupational health and safety issue; training managers to have assertive conversations about work performance and sensitive information such as arjun dhakal's alcohol or drug use; and use of an Employee Assistance Program. Personal strategies might include providing resources to help with alcohol/substance use problems, such as recovery information, or personal leave to manage withdrawal symptoms. Overall, these results have indicated that arjun dhakal may be experiencing significant issues as a result of drug/alcohol use. Strategies may also include references to drug and alcohol treatment programs either locally or online support. These may include helpline/hotline numbers, (1800 250 015 - National Alcohol and Other Drugs Hotline) or apps such as 'I am Sober'. Employment could be prioritised to ensure that employment does not include drug or alcohol use (i.e., a hospitality environment). Strategies available to address this issue for arjun dhakal include: offering additional workplace support and/or referring them to a mental health specialist such as a psychologist for further counselling and support.",
                band_description:
                    "This result indicates that arjun dhakal experiences moderate consequences of using alcohol and/or drugs.",
            },
            {
                name: "HeadFirst Total Score",
                version: "1",
                description: "",
                icon: "overallWellbeingIllustrationHeadFirst",
                category: "HeadFirst",
                subCategories: [],
                rawScore: 25,
                percentage: 39,
                strategies: "",
                band_description:
                    "This individual appears to be have moderate levels of wellbeing and is likely to require some assistance or support to be effective in the workplace.",
            },
        ],
    },
    interests: {
        reportName: "Careering Ahead Interests",
        reportDescription: [
            {
                description:
                    "The following CareeringAhead\u2122 Interest report has been prepared to provide tailored vocational suggestions based on your responses to the vocational interests questionnaires. In addition, the report gives insight into what might be potential areas that you might need assistance or require further clarity about",
                bullets: [
                    "The vocational interest report outlines your level of interest in seven different general work categories.",
                ],
            },
            {
                description: "This report will:",
                bullets: [
                    "Aid you to identify your interests and its level of clarity about careers as it relates to a work environment.",
                    "Provide clarity in the vocational choices most suited to you, and",
                    "Suggest a range of suitable career options tailored to your needs.",
                ],
            },
            {
                description:
                    "In the final section of this report there is a printed list of job titles suited to you results from the vocational interest inventory.",
                bullets: null,
            },
        ],
        categoriesDescriptions: [
            {
                name: "Interests",
                description1:
                    "The graph and chart below displays your interest within seven vocational categories from most preferred to least preferred. Your scores are based on a comparison to the general population. The highest scores show the type of activities you like best. More than one high score indicates a combination of interests. The lowest scores indicate which activities you are least interested at this stage.",
                description2:
                    "In the chart below read the descriptions within each category to give you an understanding of the different types of work interests. The occupations named in each category provide examples as opposed to extensive lists of skills. Use your two highest scores to help you find jobs that match your interests. Scores are between 20 and 100.",
            },
        ],
        recommendation: {
            text: "Based on your interests and values, some careers that you may wish to consider are listed below. These jobs are generated based on a statistical analysis of your responses, compared to responses of people who work in these jobs. The jobs listed are ordered from your closest to least close match in terms of your results from your 'Careering Ahead' assessment results. This is not a full list of jobs that you could pursue; rather it is a selection of possible options to consider. It is advised that you also take into account your results from the skills questionnaires while investigating these options. Whilst most of the jobs listed below match your interests, values, skills and level of education (i.e. your job zone), we also listed some which require a slightly different level of education to yours. Note: Please select the recommended job tittle according to Job Zone as per your education level.",
            table: [
                {
                    jobZone: "Job Zone 5",
                    description: "Typically require either a bachelor's or a post-graduate degree.",
                },
                {
                    jobZone: "Job Zone 4",
                    description: "Typically require a four - year bachelor's degree.",
                },
                {
                    jobZone: "Job Zone 3",
                    description:
                        "Typically require related on-the-job experience, TAFE qualifications or a bachelor's degree.",
                },
                {
                    jobZone: "Job Zone 2",
                    description:
                        "Typically require secondary school (or high school) certificate, TAFE qualifications or a bachelor's degree.",
                },
                {
                    jobZone: "Job Zone 1",
                    description: "Typically require a secondary school (high school) certificate.",
                },
            ],
        },
        recommendedJobs: [
            {
                jobTitle: "Mathematical Technicians",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "15-2091.00",
                jobZone: 4,
                jobDetails:
                    "Apply standardized mathematical formulas, principles, and methodology to technological problems in engineering and physical sciences in relation to specific industrial and research objectives, processes, equipment, and products.",
            },
            {
                jobTitle: "Biologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-1020.01",
                jobZone: 5,
                jobDetails:
                    "Research or study basic principles of plant and animal life, such as origin, relationship, development, anatomy, and functions.",
            },
            {
                jobTitle: "Anaesthesiologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "29-1061.00",
                jobZone: 5,
                jobDetails: "Administer anaesthetics during surgery or other medical procedures.",
            },
            {
                jobTitle: "Fire Investigators",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "33-2021.02",
                jobZone: 3,
                jobDetails: "Conduct investigations to determine causes of fires and explosions.",
            },
            {
                jobTitle: "Surgeons",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "29-1067.00",
                jobZone: 5,
                jobDetails:
                    "Treat diseases, injuries, and deformities by invasive methods, such as manual manipulation or by using instruments and appliances.",
            },
            {
                jobTitle: "Microbiologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-1022.00",
                jobZone: 5,
                jobDetails:
                    "Investigate the growth, structure, development, and other characteristics of microscopic organisms, such as bacteria, algae, or fungi. Includes medical microbiologists who study the relationship between organisms and disease or the effects of antibiotics on microorganisms.",
            },
            {
                jobTitle: "Medical Scientists, Except Epidemiologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-1042.00",
                jobZone: 5,
                jobDetails:
                    "Conduct research dealing with the understanding of human diseases and the improvement of human health. Engage in clinical investigation or other research, production, technical writing, or related activities.",
            },
            {
                jobTitle: "Industrial Engineering Technicians",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-3026.00",
                jobZone: 3,
                jobDetails:
                    "Apply engineering theory and principles to problems of industrial layout or manufacturing production, usually under the direction of engineering staff. May study and record time, motion, method, and speed involved in performance of production, maintenance, clerical, and other worker operations for such purposes as establishing standard production rates or improving efficiency.",
            },
            {
                jobTitle: "Animal Scientists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-1011.00",
                jobZone: 5,
                jobDetails:
                    "Conduct research in the genetics, nutrition, reproduction, growth, and development of domestic farm animals.",
            },
            {
                jobTitle: "Food Scientists and Technologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-1012.00",
                jobZone: 5,
                jobDetails:
                    "Use chemistry, microbiology, engineering, and other sciences to study the principles underlying the processing and deterioration of foods; analyse food content to determine levels of vitamins, fat, sugar, and protein; discover new food sources; research ways to make processed foods safe, palatable, and healthful; and apply food science knowledge to determine best ways to process, package, preserve, store, and distribute food.",
            },
            {
                jobTitle: "Environmental Science and Protection Technicians, Including Health",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-4091.00",
                jobZone: 4,
                jobDetails:
                    "Performs laboratory and field tests to monitor the environment and investigate sources of pollution, including those that affect health. Under direction of an environmental scientist or specialist, may collect samples of gases, soil, water, and other materials for testing and take corrective actions as assigned.",
            },
            {
                jobTitle: "Forensic Science Technicians",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-4092.00",
                jobZone: 4,
                jobDetails:
                    "Collect, identify, classify, and analyse physical evidence related to criminal investigations. Perform tests on weapons or substances, such as fibre, hair, and tissue to determine significance to investigation. May testify as expert witnesses on evidence or crime laboratory techniques. May serve as specialists in area of expertise, such as ballistics, fingerprinting, handwriting, or biochemistry.",
            },
            {
                jobTitle: "Medical and Clinical Laboratory Technologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "29-2011.00",
                jobZone: 4,
                jobDetails:
                    "Perform complex medical laboratory tests for diagnosis, treatment, and prevention of disease. May train or supervise staff.",
            },
            {
                jobTitle: "Nuclear Medicine Technologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "29-2033.00",
                jobZone: 3,
                jobDetails:
                    "Prepare, administer, and measure radioactive isotopes in therapeutic, diagnostic, and tracer studies utilizing a variety of radioisotope equipment. Prepare stock solutions of radioactive materials and calculate doses to be administered by radiologists. Subject patients to radiation. Execute blood volume, red cell survival, and fat absorption studies following standard laboratory techniques.",
            },
            {
                jobTitle: "Computer and Information Scientists, Research",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "15-1011.00",
                jobZone: 5,
                jobDetails:
                    "Conduct research into fundamental computer and information science as theorists, designers, or inventors. Solve or develop solutions to problems in the field of computer hardware and software.",
            },
            {
                jobTitle: "Computer Software Engineers, Applications",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "15-1031.00",
                jobZone: 4,
                jobDetails:
                    "Develop, create, and modify general computer applications software or specialized utility programs. Analyse user needs and develop software solutions. Design software or customize software for client use with the aim of optimizing operational efficiency. May analyse and design databases within an application area, working individually or coordinating database development as part of a team.",
            },
            {
                jobTitle: "Aerospace Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2011.00",
                jobZone: 5,
                jobDetails:
                    "Perform a variety of engineering work in designing, constructing, and testing aircraft, missiles, and spacecraft. May conduct basic and applied research to evaluate adaptability of materials and equipment to aircraft design and manufacture. May recommend improvements in testing equipment and techniques.",
            },
            {
                jobTitle: "Agricultural Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2021.00",
                jobZone: 4,
                jobDetails:
                    "Apply knowledge of engineering technology and biological science to agricultural problems concerned with power and machinery, electrification, structures, soil and water conservation, and processing of agricultural products.",
            },
            {
                jobTitle: "Biomedical Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2031.00",
                jobZone: 4,
                jobDetails:
                    "Apply knowledge of engineering, biology, and biomechanical principles to the design, development, and evaluation of biological and health systems and products, such as artificial organs, prostheses, instrumentation, medical information systems, and health management and care delivery systems.",
            },
            {
                jobTitle: "Chemical Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2041.00",
                jobZone: 4,
                jobDetails:
                    "Design chemical plant equipment and devise processes for manufacturing chemicals and products, such as gasoline, synthetic rubber, plastics, detergents, cement, paper, and pulp, by applying principles and technology of chemistry, physics, and engineering.",
            },
            {
                jobTitle: "Physicists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-2012.00",
                jobZone: 5,
                jobDetails:
                    "Conduct research into the phases of physical phenomena, develop theories and laws on the basis of observation and experiments, and devise methods to apply laws and theories to industry and other fields.",
            },
            {
                jobTitle: "Atmospheric and Space Scientists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-2021.00",
                jobZone: 4,
                jobDetails:
                    "Investigate atmospheric phenomena and interpret meteorological data gathered by surface and air stations, satellites, and radar to prepare reports and forecasts for public and other uses.",
            },
            {
                jobTitle: "Chemists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-2031.00",
                jobZone: 4,
                jobDetails:
                    "Conduct qualitative and quantitative chemical analyses or chemical experiments in laboratories for quality or process control or to develop new products or knowledge.",
            },
            {
                jobTitle: "Materials Scientists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-2032.00",
                jobZone: 5,
                jobDetails:
                    "Research and study the structures and chemical properties of various natural and manmade materials, including metals, alloys, rubber, ceramics, semiconductors, polymers, and glass. Determine ways to strengthen or combine materials or develop new materials with new or specific properties for use in a variety of products and applications.",
            },
            {
                jobTitle: "Hydrologists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-2043.00",
                jobZone: 5,
                jobDetails:
                    "Research the distribution, circulation, and physical properties of underground and surface waters; study the form and intensity of precipitation, its rate of infiltration into the soil, movement through the earth, and its return to the ocean and atmosphere.",
            },
            {
                jobTitle: "Network and Computer Systems Administrators",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "15-1071.00",
                jobZone: 4,
                jobDetails:
                    "Install, configure, and support an organization's local area network (LAN), wide area network (WAN), and Internet system or a segment of a network system. Maintain network hardware and software. Monitor network to ensure network availability to all system users and perform necessary maintenance to support network availability. May supervise other network support and client server specialists and plan, coordinate, and implement network security measures.",
            },
            {
                jobTitle: "Computer Hardware Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2061.00",
                jobZone: 4,
                jobDetails:
                    "Research, design, develop, and test computer or computer-related equipment for commercial, industrial, military, or scientific use. May supervise the manufacturing and installation of computer or computer-related equipment and components.",
            },
            {
                jobTitle: "Computer Systems Engineers/Architects",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "15-1099.02",
                jobZone: 4,
                jobDetails:
                    "Design and develop solutions to complex applications problems, system administration issues, or network concerns. Perform systems management and integration functions.",
            },
            {
                jobTitle: "Electrical Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2071.00",
                jobZone: 4,
                jobDetails:
                    "Design, develop, test, or supervise the manufacturing and installation of electrical equipment, components, or systems for commercial, industrial, military, or scientific use.",
            },
            {
                jobTitle: "Electronics Engineers, Except Computer",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2072.00",
                jobZone: 4,
                jobDetails:
                    "Research, design, develop, and test electronic components and systems for commercial, industrial, military, or scientific use utilizing knowledge of electronic theory and materials properties. Design electronic circuits and components for use in fields such as telecommunications, aerospace guidance and propulsion control, acoustics, or instruments and controls.",
            },
            {
                jobTitle: "Fire-Prevention and Protection Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2111.02",
                jobZone: 4,
                jobDetails:
                    "Research causes of fires, determine fire protection methods, and design or recommend materials or equipment such as structural components or fire-detection equipment to assist organizations in safeguarding life and property against fire, explosion, and related hazards.",
            },
            {
                jobTitle: "Product Safety Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2111.03",
                jobZone: 4,
                jobDetails:
                    "Develop and conduct tests to evaluate product safety levels and recommend measures to reduce or eliminate hazards.",
            },
            {
                jobTitle: "Marine Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2121.01",
                jobZone: 4,
                jobDetails:
                    "Design, develop, and take responsibility for the installation of ship machinery and related equipment including propulsion machines and power supply systems.",
            },
            {
                jobTitle: "Marine Architects",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2121.02",
                jobZone: 4,
                jobDetails:
                    "Design and oversee construction and repair of marine craft and floating structures such as ships, barges, tugs, dredges, submarines, torpedoes, floats, and buoys. May confer with marine engineers.",
            },
            {
                jobTitle: "Materials Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2131.00",
                jobZone: 4,
                jobDetails:
                    "Evaluate materials and develop machinery and processes to manufacture materials for use in products that must meet specialized design and performance specifications. Develop new uses for known materials. Includes those working with composite materials or specializing in one type of material, such as graphite, metal and metal alloys, ceramics and glass, plastics and polymers, and naturally occurring materials.",
            },
            {
                jobTitle: "Engineering Teachers, Postsecondary",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "25-1032.00",
                jobZone: 5,
                jobDetails:
                    "Teach courses pertaining to the application of physical laws and principles of engineering for the development of machines, materials, instruments, processes, and services. Includes teachers of subjects, such as chemical, civil, electrical, industrial, mechanical, mineral, and petroleum engineering. Includes both teachers primarily engaged in teaching and those who do a combination of both teaching and research.",
            },
            {
                jobTitle: "Dentists, General",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "29-1021.00",
                jobZone: 5,
                jobDetails:
                    "Diagnose and treat diseases, injuries, and malformations of teeth and gums and related oral structures. May treat diseases of nerve, pulp, and other dental tissues affecting vitality of teeth.",
            },
            {
                jobTitle: "Orthodontists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "29-1023.00",
                jobZone: 5,
                jobDetails:
                    "Examine, diagnose, and treat dental malocclusions and oral cavity anomalies. Design and fabricate appliances to realign teeth and jaws to produce and maintain normal function and to improve appearance.",
            },
            {
                jobTitle: "Prosthodontists",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "29-1024.00",
                jobZone: 5,
                jobDetails:
                    "Construct oral prostheses to replace missing teeth and other oral structures to correct natural and acquired deformation of mouth and jaws, to restore and maintain oral function, such as chewing and speaking, and to improve appearance.",
            },
            {
                jobTitle: "Mechanical Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2141.00",
                jobZone: 4,
                jobDetails:
                    "Perform engineering duties in planning and designing tools, engines, machines, and other mechanically functioning equipment. Oversee installation, operation, maintenance, and repair of such equipment as centralized heat, gas, water, and steam systems.",
            },
            {
                jobTitle: "Chemical Technicians",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "19-4031.00",
                jobZone: 3,
                jobDetails:
                    "Conduct chemical and physical laboratory tests to assist scientists in making qualitative and quantitative analyses of solids, liquids, and gaseous materials for purposes, such as research and development of new products or processes, quality control, maintenance of environmental standards, and other work involving experimental, theoretical, or practical application of chemistry and related sciences.",
            },
            {
                jobTitle: "Nuclear Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2161.00",
                jobZone: 4,
                jobDetails:
                    "Conduct research on nuclear engineering problems or apply principles and theory of nuclear science to problems concerned with release, control, and utilization of nuclear energy and nuclear waste disposal.",
            },
            {
                jobTitle: "Petroleum Engineers",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "17-2171.00",
                jobZone: 4,
                jobDetails:
                    "Devise methods to improve oil and gas well production and determine the need for new or modified tool designs. Oversee drilling and offer technical advice to achieve economical and satisfactory progress.",
            },
            {
                jobTitle: "Coroners",
                firstJobInterestArea: "Science",
                secondJobInterestArea: "Practical",
                jobCode: "13-1041.06",
                jobZone: 5,
                jobDetails:
                    "Direct activities such as autopsies, pathological and toxicological analyses, and inquests relating to the investigation of deaths occurring within a legal jurisdiction to determine cause of death or to fix responsibility for accidental, violent, or unexplained deaths.",
            },
        ],
        reportFields: [
            {
                name: "Science",
                version: "",
                description:
                    "Individuals scoring highly on the Scientific interest category enjoy investigating, researching, analysing and experimenting. They often enjoy working within the fields of medicine and health. People who exhibit Scientific interests often work well in work roles such as doctors, nutritionists, anthropologists, economists.",
                icon: "",
                category: "Interests",
                rawScore: 27,
                percentile: 90,
                band_description: "",
            },
            {
                name: "Practical",
                version: "",
                description:
                    "Individuals scoring highly on the Practical interest category enjoy working with tools, equipment or machinery. They often enjoy manipulating equipment, physically making products and operating or repairing machinery. It also involves the application of scientific principles to design or develop structures, machines, apparatus, or manufacturing processes. People with a Practical interest usually enjoy jobs such as engineering, mechanics, aviation operation and computer-related and technical work.",
                icon: "",
                category: "Interests",
                rawScore: 24,
                percentile: 72,
                band_description: "",
            },
            {
                name: "Office",
                version: "",
                description:
                    "Individuals scoring highly on the Office interest category enjoy working indoors and undertaking clerical, computer or finance-related tasks. Those who demonstrate Office - related interests are likely to enjoy work roles such as cost analyst, accountant, librarian, auditor or court reporter.",
                icon: "",
                category: "Interests",
                rawScore: 19,
                percentile: 65,
                band_description: "",
            },
            {
                name: "Business",
                version: "",
                description:
                    "Individuals scoring highly on the Business interest category enjoy working with people in terms of leading, discussing ideas and inspiring others into action. People who are Business oriented are likely to enjoy working within areas such as business management, public relations or politics.",
                icon: "",
                category: "Interests",
                rawScore: 17,
                percentile: 59,
                band_description: "",
            },
            {
                name: "Creative",
                version: "",
                description:
                    "Individuals scoring highly on the Creative interest category enjoy communicating creatively, and considering and implementing innovative solutions. People who demonstrate Creative interest enjoy roles in artistic, creative and literary occupations. Preferred roles include artistry, photography, dance, music composition and interior design.",
                icon: "",
                category: "Interests",
                rawScore: 14,
                percentile: 47,
                band_description: "",
            },
            {
                name: "People Contact",
                version: "",
                description:
                    "Individuals scoring highly on the People Contact interest category enjoy interacting with others and working in roles that involve helping other people Those who exhibit People Contact interests often like to work in fields such as teaching, social work or psychology",
                icon: "",
                category: "Interests",
                rawScore: 13,
                percentile: 42,
                band_description: "",
            },
            {
                name: "Outdoor",
                version: "",
                description:
                    "Individuals scoring highly on the Outdoor interest category generally enjoy working outside or in active roles as opposed to spending time indoors or behind a desk. This may involve travelling in or driving vehicles. They often have an interest in areas such as animal care, athletics, gardening, landscaping, farming, horticulture or surveying.",
                icon: "",
                category: "Interests",
                rawScore: 12,
                percentile: 28,
                band_description: "",
            },
        ],
    },
};

export const samplePaginatedListResponsePage1 = {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 3,
    totalRecords: 22,
    data: [
        {
            firstName: "Charles",
            lastName: "Dickens",
            emailAddress: "charles@dickens.com",
            userIdentifier: "3a2e46e8-c6ef-42bc-921b-f9aa43a43ebd",
            coachName: "Fabio Monsalve",
            coachIdentifier: "90e74feb-6094-4d8c-8433-eb2135ff0dd8",
            statusName: "Not Started",
            setupDate: "2022-09-19T06:28:40.5354048",
            cohorts: ["Turtles", "Heroes"],
            jobSeekerId: null,
        },
        {
            firstName: "Declan",
            lastName: "DeMoira",
            emailAddress: "dtest@psychpress.com.au",
            userIdentifier: "3607ed88-0611-4c77-aad5-4e3d15c35112",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Edmond",
            lastName: "Pike",
            emailAddress: "eptest@psychpress.com.au",
            userIdentifier: "0f807f20-6301-4515-b4ea-26b6ec2d3e4d",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Fabian",
            lastName: "Montsalvat",
            emailAddress: "ppfmondu@gmail.com",
            userIdentifier: "0bc23cea-c090-4597-b355-8094872c56a0",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: [],
            jobSeekerId: null,
        },
        {
            firstName: "Fabio",
            lastName: "Monsalve",
            emailAddress: "fm@psychpress.com.au",
            userIdentifier: "90e74feb-6094-4d8c-8433-eb2135ff0dd8",
            coachName: "Fako Faker",
            coachIdentifier: "e1075d57-d3ee-40ba-9bdc-fc98fd2d2f8e",
            statusName: "Not Started",
            setupDate: "2022-04-11T02:13:54.6060127",
            cohorts: ["IceCream", "Plantain"],
            jobSeekerId: null,
        },
        {
            firstName: "Fabio",
            lastName: "Monsalve",
            emailAddress: "fmondu@protonmail.com",
            userIdentifier: "90c5b451-424f-45cd-b1ee-ce1b6fe3ff6e",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Flab",
            lastName: "Dab",
            emailAddress: "flabdab@flabdab.com.au",
            userIdentifier: "f17a9273-f981-449a-ac51-bf0cd692a23a",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Fred",
            lastName: "Lazlo",
            emailAddress: "fl@pp.com.au",
            userIdentifier: "6081dd84-bcef-451d-8de6-b15db38864c7",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Garland",
            lastName: "Prime",
            emailAddress: "garland@prime.com.au",
            userIdentifier: "f771193d-831d-478c-99c9-e9a18624ebba",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Gerardo",
            lastName: "Gonzalo",
            emailAddress: "gerado@gonzalo.com",
            userIdentifier: "ab75fe6f-8eb9-4fa9-ac7c-135a851c0985",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
    ],
};

export const samplePaginatedListResponsePage2 = {
    pageNumber: 2,
    pageSize: 10,
    totalPages: 3,
    totalRecords: 22,
    data: [
        {
            firstName: "Gerardo",
            lastName: "Gonzalo",
            emailAddress: "gerardo@gonzalo.com",
            userIdentifier: "59f9c8d5-64d2-46ce-a284-ecf1f02902f1",
            coachName: "Fabio Monsalve",
            coachIdentifier: "90e74feb-6094-4d8c-8433-eb2135ff0dd8",
            statusName: "Not Started",
            setupDate: "2022-09-25T23:41:55.4221282",
            cohorts: ["IceCream"],
            jobSeekerId: null,
        },
        {
            firstName: "Henriette",
            lastName: "Lang",
            emailAddress: "hltest@psychpress.com.au",
            userIdentifier: "72875f92-1885-4387-9f7f-a6636cad9631",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "James",
            lastName: "Rodriguez",
            emailAddress: "james@rodriguez.com",
            userIdentifier: "fea01f61-6a80-428a-b40f-9f05292dc5e9",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Matty",
            lastName: "Ayres",
            emailAddress: "ma@psychpress.com.au",
            userIdentifier: "8b71213d-8df2-4374-b6ad-f43cbb486f88",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: [],
            jobSeekerId: null,
        },
        {
            firstName: "Michael",
            lastName: "Jordan",
            emailAddress: "michael@jordan.com",
            userIdentifier: "5710ad94-6603-4e7a-956a-bc75fd2a32f6",
            coachName: "Fabio Monsalve",
            coachIdentifier: "90e74feb-6094-4d8c-8433-eb2135ff0dd8",
            statusName: "Not Started",
            setupDate: "2022-09-19T06:29:46.4175235",
            cohorts: ["Felines"],
            jobSeekerId: null,
        },
        {
            firstName: "Petardo",
            lastName: "Reinaldo",
            emailAddress: "prtest@psychpress.com.au",
            userIdentifier: "5e692bfb-3e07-41f1-a7f3-738a2d8ad781",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Robertos",
            lastName: "Burns",
            emailAddress: "robert@burns.com",
            userIdentifier: "9f32c431-9b4e-442d-954b-b131511ed17d",
            coachName: "Fabio Monsalve",
            coachIdentifier: "90e74feb-6094-4d8c-8433-eb2135ff0dd8",
            statusName: "Not Started",
            setupDate: "2022-09-25T23:42:33.4179042",
            cohorts: ["Plantain"],
            jobSeekerId: null,
        },
        {
            firstName: "Ronald",
            lastName: "McDonald",
            emailAddress: "ronald@mcdonald.com",
            userIdentifier: "dfce1654-f22c-46b6-a09c-ec3f4e03dfe9",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Tomas",
            lastName: "Restrepo",
            emailAddress: "tr@psychpress.com.au",
            userIdentifier: "d86e0c86-cbf2-44ac-9c30-5908617fe9a7",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
        {
            firstName: "Tommy",
            lastName: "Figaro",
            emailAddress: "tftest@psychpress.com.au",
            userIdentifier: "0f6ff255-aad4-421f-99f2-e8f8ac7523e5",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
    ],
};

export const samplePaginatedListResponsePage3 = {
    pageNumber: 3,
    pageSize: 10,
    totalPages: 3,
    totalRecords: 22,
    data: [
        {
            firstName: "Tony",
            lastName: "Soprano",
            emailAddress: "tony@soprano.com.au",
            userIdentifier: "61bcc869-e33b-4edc-96c0-7c849e6ab220",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: [],
            jobSeekerId: null,
        },
        {
            firstName: "Zachary",
            lastName: "Zendarski",
            emailAddress: "zz@psychpress.com.au",
            userIdentifier: "217cb21f-d4ac-4785-ad1d-b1139129d553",
            coachName: null,
            coachIdentifier: null,
            statusName: null,
            setupDate: null,
            cohorts: null,
            jobSeekerId: null,
        },
    ],
};

export const sampleSaleableReport = {
    reportName: "Saleable",
    reportDescription:
        "Saleable\u2122 is based upon research examining the link between personality traits and sales success. Relevant research has been reviewed to indicate which personality traits are most often correlated with sales success in different contexts.",
    reportFields: [
        {
            name: "Awareness",
            version: "",
            description: "",
            icon: "",
            category: "Discovering and responding to needs",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Bluntness",
            rawScore: 19,
            percentile: 6,
            bandDescription:
                "A low score on the Personal Diplomacy scale indicates that Test lastname will experience difficulty in managing their emotions, and adapting their approach to suit various customers. They are likely to be perceived as very direct in their communication, and while some customers will appreciate this, others may find it somewhat confronting. They may lack insight into modifying their own behaviours to suit the client, which may have a negative impact on their engagement with customers. Test lastname may particularly struggle to maintain a polite demeanour when working with difficult or belligerent customers.",
        },
        {
            name: "Competitiveness",
            version: "",
            description: "",
            icon: "",
            category: "Closing",
            superCategory: "Selling",
            index: "Personality Profile Index",
            inverseName: "Contentment",
            rawScore: 24,
            percentile: 79,
            bandDescription:
                "Test lastname high score on the Competitiveness scale suggests that they are a highly driven individual, and not given to letting distractions or interruptions get in the way of making a sale. They are likely to prefer more intense interactions with customers, and to work hard to ensure that each customer ends up making a purchase, despite any objections which may need to be overcome.",
        },
        {
            name: "Collaborative",
            version: "",
            description: "",
            icon: "",
            category: "Managing Objections",
            superCategory: "Selling",
            index: "Personality Profile Index",
            inverseName: "Directive",
            rawScore: 22,
            percentile: 97,
            bandDescription:
                "Test lastname high score on the Collaboration scale shows a clear preference for assisting co-workers, and cooperating with customers to achieve their goals. They are likely to prefer learning about the customer's desires and working with the customer to satisfy them, rather than trying to change the customer's mind. This approach invites customers to participate more in the sales process, and can be very effective in generating positive feedback and lasting customer relations.",
        },
        {
            name: "Success focused",
            version: "",
            description: "",
            icon: "",
            category: "Discovering and responding to needs",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Distractible",
            rawScore: 16,
            percentile: 24,
            bandDescription:
                "Test lastname scored in the low range for the Success Focus scale, which suggests that they may face difficulties with keeping their sales goals in mind. They may tend to focus more on the process of selling, or engaging with new customers, and sometimes forget to single-mindedly pursue the end goal of successfully making a sale. They may be particularly susceptible to distractions, and would benefit from regular reminders about their sales and performance goals.",
        },
        {
            name: "Proactivity",
            version: "",
            description: "",
            icon: "",
            category: "Initiating Contact",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Hesitance",
            rawScore: 20,
            percentile: 9,
            bandDescription:
                "Test lastname low score on the Initiative scale suggests that they are not normally given to seeking out and contacting new prospective clients on their own. While they may be able to interact effectively with established customers, the act of contacting new prospects and working to establish a new relationship is something that is unlikely to come naturally to them. They are likely to prefer working in roles where sales are inbound, and they are able to respond to customer queries rather than actively pursue sales opportunities.",
        },
        {
            name: "Extroversion",
            version: "",
            description: "",
            icon: "",
            category: "Initiating Contact",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Introversion",
            rawScore: 18,
            percentile: 1,
            bandDescription:
                "Test lastname has scored in the low range on the Extraversion scale, suggesting that they are less outgoing and energetic in work settings than most. When a sales pitch calls for a bright and enthusiastic salesperson, they may struggle to rise to the occasion, and potential customers may be left feeling indifferent by this subdued approach. They may feel drained by repeated contacts with prospective customers, and would be most effective when making only a few such contacts each day.",
        },
        {
            name: "Motivation",
            version: "",
            description: "",
            icon: "",
            category: "Discovering and responding to needs",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Relaxed Approach",
            rawScore: 18,
            percentile: 1,
            bandDescription:
                "Test lastname has scored in the lower range on the motivation scale, which suggests that they may struggle to persevere with set goals. They may be strongly discouraged by adverse situations such as strong rejection by prospects or seemingly difficult sales quotas. They are more likely to succeed in sales roles which involve responding to existing requests rather than requiring the motivation to generate new business.",
        },
        {
            name: "Persistence",
            version: "",
            description: "",
            icon: "",
            category: "Managing Objections",
            superCategory: "Selling",
            index: "Personality Profile Index",
            inverseName: "Self-Concerned",
            rawScore: 20,
            percentile: 15,
            bandDescription:
                "A low score on the Persistence scale, as obtained by Test lastname, indicates that they are more likely to be easily affected by difficult circumstances, which may consequently affect their performance. They may tend to become anxious about repeatedly engaging with customers, and may be easily discouraged by resistance. They may also worry about the way their sales approach will be perceived or interpreted.",
        },
        {
            name: "Goal Orientation",
            version: "",
            description: "",
            icon: "",
            category: "Setting A Strategy",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Short Term Focus",
            rawScore: 21,
            percentile: 2,
            bandDescription:
                "Their low score on the Goal Orientation scale indicates that Test lastname will tend to do little strategic planning, and will prefer to deal with issues in the sales process as they occur. They may often be prone to distraction and procrastination, especially if a task is complex or boring. They may have a tendency to lose concentration when it is necessary to focus on prospecting tasks for long periods of time, such as when planning presentations. There is a high likelihood that they may sometimes face difficulties with prioritising sales goals and planning efficiently. In addition, the quality of their work may suffer due to interruptions.",
        },
        {
            name: "Planning",
            version: "",
            description: "",
            icon: "",
            category: "Setting A Strategy",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Spontaneity",
            rawScore: 26,
            percentile: 5,
            bandDescription:
                "Test lastname low score on the Planning scale indicates that they are likely to be less efficient when working in an independent, self-managed sales role. They are likely to succeed in a highly supervised role, and may require prompting in order to avoid distractions and remain focused on prospecting effectively. They are also unlikely to enjoy strategic planning, and would probably be happy to leave the strategic and planning elements of sales to others.",
        },
        {
            name: "Managerial",
            version: "",
            description: "",
            icon: "",
            category: "Management Growth And Refferals",
            superCategory: "Growth",
            index: "Personality Profile Index",
            inverseName: "Subordinate",
            rawScore: 30,
            percentile: 97,
            bandDescription:
                "Test lastname scored in the high range on the Managerial scale, meaning they should be comfortable with taking on roles of leadership and management within the sales team. This could involve effectively directing the sales activities of others, or simply managing their own time and resources to perform efficiently in the sales role. They are likely to be able to gain the support of co-workers, and use this to help accomplish organizational goals and objectives.",
        },
        {
            name: "Relaxed Style",
            version: "",
            description: "",
            icon: "",
            category: "Ensuring Satisfaction",
            superCategory: "Growth",
            index: "Personality Profile Index",
            inverseName: "Tenseness",
            rawScore: 29,
            percentile: 9,
            bandDescription:
                "Test lastname obtained a low score on the Relaxed Style scale, indicating that they will seem worried or nervous during stressful situations. This result may be indicative of an ongoing tension which may negatively impair their relationships with clients. It is unlikely that they will always be able to manage their emotions or reactions effectively, and this may adversely affect their client relationships.",
        },
        {
            name: "Assertiveness",
            version: "",
            description: "",
            icon: "",
            category: "Managing Objections",
            superCategory: "Selling",
            index: "Personality Profile Index",
            inverseName: "Timidness",
            rawScore: 16,
            percentile: 1,
            bandDescription:
                "The low score on the Assertiveness scale obtained by Test lastname suggests that they will not be perceived as someone who is very assertive. They has the tendency to be passive in the workplace and will not communicate thoughts and ideas to others. Rather Test lastname will prefer going along with the majority, particularly if faced with significant opposition. Test lastname has scored in the lower range on the Assertiveness scale, suggesting that they will be unlikely to assert their opinion during sales interactions. This is useful in sales processes where the desires and satisfaction of the customer is paramount, but is less beneficial when their knowledge as a salesperson really should be imparted to the customer. Sales roles invariably include times when the salesperson should speak up and clearly communicate their position to the customer, and Test lastname may benefit from strengthening this skill.",
        },
        {
            name: "Self-Confidence",
            version: "",
            description: "",
            icon: "",
            category: "Initiating Contact",
            superCategory: "Prospecting",
            index: "Personality Profile Index",
            inverseName: "Uncertainty",
            rawScore: 23,
            percentile: 38,
            bandDescription:
                "The average score obtained by Test lastname on the Self-Confidence scale suggests that he/she has a moderate belief in their ability to direct the course of the sales process. They will be most confident in product areas where they has experience; and should be able to speak confidently about such topics. However, in less familiar sales areas they are likely to speak less confidently, and may communicate this cautiousness to the customer.",
        },
        {
            name: "Team Player",
            version: "",
            description: "",
            icon: "",
            category: "Ensuring Satisfaction",
            superCategory: "Growth",
            index: "Personality Profile Index",
            inverseName: "Works Alone",
            rawScore: 18,
            percentile: 12,
            bandDescription:
                "A low score on the Team Player scale indicates that Test lastname may show little desire to work in a group situation, and tends to prefer working alone wherever possible. They may believe that individuals are more effective and productive working alone on sales tasks than in groups or teams. They may perceive the input of colleagues or sales team members as interference, and dislikes working in a team context. Test lastname may also be more motivated by individual success than group success, and therefore put their own goals ahead of the goals of the team. They tends to perform better alone than in a group.",
        },
        {
            name: "Infrequency",
            version: "",
            description: "",
            icon: "",
            category: "Infrequency",
            superCategory: "",
            index: "Response Style Index",
            inverseName: "",
            rawScore: 2,
            percentile: 60,
            bandDescription:
                "Test lastname ranked in the average range on the Infrequency measure. This indicates that they mostly answered questions in a reasonably consistent way, which suggests that they was reading the questions and giving a reasonable amount of thought to their responses. While there is some suggestion of unusual response patterns which could indicate carelessness, this was no greater than for the average respondent.",
        },
        {
            name: "Social Desirability",
            version: "",
            description: "",
            icon: "",
            category: "Social Desirability",
            superCategory: "",
            index: "Response Style Index",
            inverseName: "",
            rawScore: 11,
            percentile: 35,
            bandDescription:
                "Test lastname ranked in the average range on the Social Desirability measure. This suggests that their responses to the questionnaire items may have been somewhat influenced by considerations of what they thinks other people would want them to say, but no more so than the average respondent.",
        },
        {
            name: "Extreme Scores",
            version: "",
            description: "",
            icon: "",
            category: "Extreme Scores",
            superCategory: "",
            index: "Response Style Index",
            inverseName: "",
            rawScore: 2,
            percentile: 2,
            bandDescription:
                "Test lastname ranked in the lower range on the Extreme Scores measure. This indicates that they did not select the 'extreme' scores (such as ones and fives on a one-to-five scale) than would normally be expected. This suggests that they reserved their extreme responses for only those items which they genuinely felt strongly about.",
        },
        {
            name: "Central Tendency",
            version: "",
            description: "",
            icon: "",
            category: "Central Tendency",
            superCategory: "",
            index: "Response Style Index",
            inverseName: "",
            rawScore: 33,
            percentile: 28,
            bandDescription:
                "Test lastname ranked in the lower range on the Central Tendency measure. This indicates that their response pattern shows very little tendency towards constantly selecting the 'middling' response, such as 'sometimes', 'yes and no', or 'no more or less'. This suggests that they are likely to have been decisive and committed in their response patterns, with little or no fence-sitting.",
        },
    ],
    introduction: {
        title: "Introduction",
        description: null,
        subHeadings: [
            {
                title: "Confidentiality",
                description: [
                    "This is a highly confidential document. It is provided to the company or executive named on the coversheet on the basis that it is accepted and recognised as confidential and that such confidentiality will be strictly maintained.",
                    "It should therefore only be read by senior staff specifically involved with the selection, promotion or development of the person named, and stored securely with minimum access.",
                    "If such responsibility is not accepted, or the need for the report or the organisational relationship with the individual concerned ceases, the report should be returned to Psych Press or destroyed. In a recruitment context, reports on unsuccessful candidates should be deleted.",
                    "Should a report be required at a later date, it can be obtained without further cost from Psych Press archives.",
                ],
            },
            {
                title: "Purpose",
                description: [
                    "The report has been written specifically for a sales related role and this purpose has determined the manner in which information has been conveyed, the interpretation provided and the conclusions drawn. Therefore it should not be used for any other purpose as the information and results are likely to be inaccurate and misleading within a new context.",
                    "Therefore it is a condition of the provision of this report that it will not be provided to the individual concerned. The risk of its misinterpretation and the possible consequences for those involved require that the information be provided in an appropriate form and context.",
                ],
            },
            {
                title: "Feedback",
                description: [
                    "Psych Press recognises the ethical responsibilities to both candidate and client and will always provide appropriate feedback to the individual concerned. Either telephone or face-to-face discussion can provide informed professional feedback on the assessment results within the context of personal and career development.",
                ],
            },
            {
                title: "Cross validation of Outcomes",
                description: [
                    "This assessment report provides objective and independent information on a candidate's personality attributes in relationship to a sales related role, which may not be available from other sources. However such data should always be used to complement and not substitute for information obtained from other sources. Best practice and legal obligations require that decisions be made from a synthesis and assessment of information acquired from different sources and not on this report alone. Psych Press can assist and advise in the development of other data sources such as structured interviews, referee reports, bio-data and the validation of this data against competencies relevant to job performance or role development.",
                ],
            },
            {
                title: "Ongoing Support",
                description: [
                    "Psych Press offers continuing support in the use of psychometric information. Clients are invited to discuss the issues flowing from this report at either an individual or organisation level.",
                ],
            },
        ],
    },
    rationale: {
        title: "",
        description: [
            "Current theory supports the observation that success metrics in a business can be approached through three sources of competitive advantage: financial, technological or human resources. The intellectual capital of human resources is the primary and most valuable asset for an organisation (Cook, 1988). As sales are the lifeblood of any revenue-seeking organisation (Subhani et al., 2011) it is imperative to understand the psychological factors impacting upon sales success, and to predict sales performance, reduce selection error and increase profit and revenue. Reviews of the psychological literature have identified personality as one of five effective predictors of sales performance (personality, bio-data, demographics, sales knowledge and sales attitude) (Charan, 2008; Barrick & Mount, 1991; Shippman, Switzer and Roth, 1988).",
            "Thus for any organisation, the selection of the candidate that is the 'best fit' is of critical importance. Studies have demonstrated that valid selection practices are also crucial in contributing to considerable financial savings for an organisation (Hunter & Hunter, 1984; Compton & Nankervis, 1991). Such selection practices can impact directly on output quality and quantity at the individual, group and organisational level.",
            "Sales are related to wealth and opportunity creation at both a commercial/organisational level as well as a personal/career development level. At the organisational level, understanding sales drivers contributes toward the commercial success of an organisation \u2013 in both revenue and profitability. At an individual level such insights and predictive ability will assist an organisation to appropriately develop the careers of their salespeople with initiatives firmly based on applied behavioural research.",
            "The Saleable\u2122 profile provides personality data to understand candidate's potential for success in a sales context. Both strengths and development needs are identified and related to effectiveness in a sales role.",
            "The Saleable\u2122 profile is based upon research examining the link between personality traits and sales success. Relevant research has been examined to indicate which personality traits are most often associated with sales success. Results for these traits are clearly defined and explained in the context of a universally recognised sales cycle (Dubinsky, 1980), in order to be easily interpretable - in commercial terms. The sales cycle consists of seven stages from prospecting to making the sale to gaining referrals from valued customers.",
            "The relationship between the identified traits and sales success has been explored with individual companies in different industries, and in general terms across all industries for respondents involved in sales in order to ensure its practical value.",
        ],
        subHeadings: null,
    },
    generalInformation: {
        title: "General Information for Interpreting this Report",
        description: [
            "This report provides objective self-report information on the candidate's suitability for sales roles. It is based on research examining the link between personality and sales success. The information provided gives an understanding of the candidates preferred approach to sales within a universally recognised sales cycle and enables an objective comparison with other candidates and a normative group. The results can promote useful discussion and consideration of suitability when compared and contrasted with other selection data.",
            "Individuals do not 'pass or fail' on personality dimensions - preferences are simply compared with a relevant population group to assist selection, performance management or career development decisions.",
            "The results presented in this report are expressed in terms of a percentile (%) score for each test or questionnaire administered. A percentile is a score below which a certain percentage of the members of a selected sample group fall. For example, when using an Australian adult sample as a comparison group, the 78th percentile is that score below which 78 percent of the total group falls.",
        ],
        subHeadings: [
            {
                title: "Specific Population Comparisons",
                description: [
                    "A general adult sample is only one reference group against which an individual's scores can be compared. Since client companies often need or may wish to compare individual performance to their own company data or to an industry or specific sales sample, Psych Press can build or provide such normative data for comparison purposes wherever possible.",
                ],
            },
            {
                title: "Interpretation",
                description: [
                    "Percentile scores can be misleading if small differences between individuals' scores are interpreted as implying significant differences in suitability or work performance.",
                ],
            },
            {
                title: "Score Ranges",
                description: [
                    "To avoid misinterpretation, score ranges are used to group scores and relate them to a 'normal curve of distribution'. Put simply, this means that on any assessment where an individual is compared with a large comparison group, a greater number of people will fall in the 'middle' or average range than at either end of a particular scale. This is reflected in the percentile ranges as shown below.",
                    "The contents of this confidential report are designed to be read by managers who are either experienced or trained in the use of psychologically based decision support tools.",
                    "The report is based upon objectively derived information and is expressly intended to be used in conjunction with other elements of recruitment or development information such as information derived from resumes or CV's indicating previous work history and achievements, interview data, reference checking, and perhaps assessment centre and/or bio-data information. Such sources of data, combined with professional judgement, allow the statements contained in this report to be viewed as professional opinion which can be compared and validated against other relevant sources of information. Interpretive statements should not be considered in isolation to this complementary information and the purpose for which the report was written.",
                    "Here it can be seen that the spread of scores within each range moves from a narrow band at each end of the scale through to a broader central 'average' band as scores approach the Average category. When used to interpret certain occupational interests and personality scales scores do not refer to a specific aptitude or ability, but rather are an indicator of preference, liking or opinion in comparison to a group of people.",
                ],
            },
            {
                title: "Psych Press uses a basic score range for ability percentile scores:",
                description: [
                    "91st - 99th percentile : Superior performance",
                    "63rd - 90th percentile : Above Average performance",
                    "37th - 62nd percentile : Average performance",
                    "10th - 36th percentile : Below Average performance",
                    "1st - 9th percentile : Poor performance",
                ],
            },
        ],
    },
    personalityProfileIndex: {
        title: "Personality Profile Index",
        description: [
            "Personality assessments measure the candidate's relatively stable preferences for certain behaviours and give insight into how their dispositions will fit into your sales team and how much of a benefit they will be to your organization's goal of increasing revenue and growth. Personality scores should be interpreted as the individual's preferred style of action in the sales environment.",
            "The following graphs represent the individual's scores on a continuum from low to high. The characteristic corresponding with a low score is listed to the left of the graph, whilst the characteristic corresponding with a high score is listed to the right of the graph. If an individual scores high, the bar will be approaching the characteristic on the right, indicating the individual's preference for that type of strategy or behaviour. If the individual scores low, their scores reflect an individual preference for the characteristic on the left, and the bar will end closer to the left side of the continuum.",
        ],
        subHeadings: null,
    },
    responseStyleIndex: {
        title: "Response Style Index",
        description: [
            "Unusual results on any of these scales may suggest that candidates are responding randomly, attempting to present themselves in an artificially favourable light, entering artificially moderate responses or otherwise failing to complete the assessment in a complete and accurate manner. Conscious and unconscious distortions in self-report data may be grouped into several categories, each of which is addressed by the scales interpreted below",
        ],
        subHeadings: null,
    },
    furtherInformation: {
        title: "Further Information",
        description: [
            "For additional information regarding the practicality, theoretical background, administration, and scoring of the Saleable\u2122 assessment, please refer to the Saleable\u2122 manual. This resource should also assist you in further interpreting the candidate's results detailed in this report. Additionally, do not hesitate to contact a Psych Press representative via email info@psychpress.com.au; phone +61 3 9670 0590 or fax +61 3 9642 3577.",
        ],
        subHeadings: null,
    },
};

export const sampleSaleableMenuDrivenReport = {
    saleable: {
        reportName: "Saleable",
        reportDescription:
            "Saleable\u2122 is based upon research examining the link between personality traits and sales success. Relevant research has been reviewed to indicate which personality traits are most often correlated with sales success in different contexts.",
        reportFields: [
            {
                name: "Awareness",
                version: "",
                description: "",
                icon: "",
                category: "Discovering and responding to needs",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Bluntness",
                rawScore: 18,
                percentile: 2,
                bandDescription:
                    "A low score on the Personal Diplomacy scale indicates that john Doe will experience difficulty in managing his emotions, and adapting his approach to suit various customers. He is likely to be perceived as very direct in his communication, and while some customers will appreciate this, others may find it somewhat confronting. He may lack insight into modifying his own behaviours to suit the client, which may have a negative impact on his engagement with customers. john Doe may particularly struggle to maintain a polite demeanour when working with difficult or belligerent customers.",
            },
            {
                name: "Competitiveness",
                version: "",
                description: "",
                icon: "",
                category: "Closing",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Contentment",
                rawScore: 24,
                percentile: 79,
                bandDescription:
                    "john Doe high score on the Competitiveness scale suggests that he is a highly driven individual, and not given to letting distractions or interruptions get in the way of making a sale. He is likely to prefer more intense interactions with customers, and to work hard to ensure that each customer ends up making a purchase, despite any objections which may need to be overcome.",
            },
            {
                name: "Collaborative",
                version: "",
                description: "",
                icon: "",
                category: "Managing Objections",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Directive",
                rawScore: 21,
                percentile: 93,
                bandDescription:
                    "john Doe high score on the Collaboration scale shows a clear preference for assisting co-workers, and cooperating with customers to achieve their goals. He is likely to prefer learning about the customer's desires and working with the customer to satisfy them, rather than trying to change the customer's mind. This approach invites customers to participate more in the sales process, and can be very effective in generating positive feedback and lasting customer relations.",
            },
            {
                name: "Success focused",
                version: "",
                description: "",
                icon: "",
                category: "Discovering and responding to needs",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Distractible",
                rawScore: 19,
                percentile: 74,
                bandDescription:
                    "john Doe scored in the high range for the Success Focus scale, suggesting that he is a driven individual who is always focused on finding pathways to success. This desire to succeed should be reflected in his approach to prospecting, in which time-consuming customers, a challenging sales environment or low interest from prospects should not be able to distract him from aggressively pursuing his sales quotas and performance goals.",
            },
            {
                name: "Proactivity",
                version: "",
                description: "",
                icon: "",
                category: "Initiating Contact",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Hesitance",
                rawScore: 15,
                percentile: 1,
                bandDescription:
                    "john Doe low score on the Initiative scale suggests that he is not normally given to seeking out and contacting new prospective clients on his own. While he may be able to interact effectively with established customers, the act of contacting new prospects and working to establish a new relationship is something that is unlikely to come naturally to him. He is likely to prefer working in roles where sales are inbound, and he is able to respond to customer queries rather than actively pursue sales opportunities.",
            },
            {
                name: "Extroversion",
                version: "",
                description: "",
                icon: "",
                category: "Initiating Contact",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Introversion",
                rawScore: 21,
                percentile: 1,
                bandDescription:
                    "john Doe has scored in the low range on the Extraversion scale, suggesting that he is less outgoing and energetic in work settings than most. When a sales pitch calls for a bright and enthusiastic salesperson, he may struggle to rise to the occasion, and potential customers may be left feeling indifferent by this subdued approach. He may feel drained by repeated contacts with prospective customers, and would be most effective when making only a few such contacts each day.",
            },
            {
                name: "Motivation",
                version: "",
                description: "",
                icon: "",
                category: "Discovering and responding to needs",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Relaxed Approach",
                rawScore: 17,
                percentile: 1,
                bandDescription:
                    "john Doe has scored in the lower range on the motivation scale, which suggests that he may struggle to persevere with set goals. He may be strongly discouraged by adverse situations such as strong rejection by prospects or seemingly difficult sales quotas. He is more likely to succeed in sales roles which involve responding to existing requests rather than requiring the motivation to generate new business.",
            },
            {
                name: "Persistence",
                version: "",
                description: "",
                icon: "",
                category: "Managing Objections",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Self-Concerned",
                rawScore: 20,
                percentile: 15,
                bandDescription:
                    "A low score on the Persistence scale, as obtained by john Doe, indicates that he is more likely to be easily affected by difficult circumstances, which may consequently affect his performance. He may tend to become anxious about repeatedly engaging with customers, and may be easily discouraged by resistance. He may also worry about the way his sales approach will be perceived or interpreted.",
            },
            {
                name: "Goal Orientation",
                version: "",
                description: "",
                icon: "",
                category: "Setting A Strategy",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Short Term Focus",
                rawScore: 21,
                percentile: 2,
                bandDescription:
                    "His low score on the Goal Orientation scale indicates that john Doe will tend to do little strategic planning, and will prefer to deal with issues in the sales process as they occur. He may often be prone to distraction and procrastination, especially if a task is complex or boring. He may have a tendency to lose concentration when it is necessary to focus on prospecting tasks for long periods of time, such as when planning presentations. There is a high likelihood that he may sometimes face difficulties with prioritising sales goals and planning efficiently. In addition, the quality of his work may suffer due to interruptions.",
            },
            {
                name: "Planning",
                version: "",
                description: "",
                icon: "",
                category: "Setting A Strategy",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Spontaneity",
                rawScore: 30,
                percentile: 29,
                bandDescription:
                    "john Doe low score on the Planning scale indicates that he is likely to be less efficient when working in an independent, self-managed sales role. He is likely to succeed in a highly supervised role, and may require prompting in order to avoid distractions and remain focused on prospecting effectively. He is also unlikely to enjoy strategic planning, and would probably be happy to leave the strategic and planning elements of sales to others.",
            },
            {
                name: "Managerial",
                version: "",
                description: "",
                icon: "",
                category: "Management Growth And Refferals",
                superCategory: "Growth",
                index: "Personality Profile Index",
                inverseName: "Subordinate",
                rawScore: 30,
                percentile: 97,
                bandDescription:
                    "john Doe scored in the high range on the Managerial scale, meaning he should be comfortable with taking on roles of leadership and management within the sales team. This could involve effectively directing the sales activities of others, or simply managing his own time and resources to perform efficiently in the sales role. He is likely to be able to gain the support of co-workers, and use this to help accomplish organizational goals and objectives.",
            },
            {
                name: "Relaxed Style",
                version: "",
                description: "",
                icon: "",
                category: "Ensuring Satisfaction",
                superCategory: "Growth",
                index: "Personality Profile Index",
                inverseName: "Tenseness",
                rawScore: 36,
                percentile: 60,
                bandDescription:
                    "john Doe obtained an average score on the Relaxed Style scale, indicating that he is able to remain calm during some periods of pressure or stress. He will be motivated by a moderate level of stress and will generally be relaxed. However, if confronted by particularly difficult or rude clients, his emotions may get the better of him, but this is unlikely to endanger worthwhile client relationships in the long run.",
            },
            {
                name: "Assertiveness",
                version: "",
                description: "",
                icon: "",
                category: "Managing Objections",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Timidness",
                rawScore: 16,
                percentile: 1,
                bandDescription:
                    "The low score on the Assertiveness scale obtained by john Doe suggests that he will not be perceived as someone who is very assertive. He has the tendency to be passive in the workplace and will not communicate thoughts and ideas to others. Rather john Doe will prefer going along with the majority, particularly if faced with significant opposition. john Doe has scored in the lower range on the Assertiveness scale, suggesting that he will be unlikely to assert his opinion during sales interactions. This is useful in sales processes where the desires and satisfaction of the customer is paramount, but is less beneficial when his knowledge as a salesperson really should be imparted to the customer. Sales roles invariably include times when the salesperson should speak up and clearly communicate their position to the customer, and john Doe may benefit from strengthening this skill.",
            },
            {
                name: "Self-Confidence",
                version: "",
                description: "",
                icon: "",
                category: "Initiating Contact",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Uncertainty",
                rawScore: 23,
                percentile: 38,
                bandDescription:
                    "The average score obtained by john Doe on the Self-Confidence scale suggests that he/she has a moderate belief in his ability to direct the course of the sales process. He will be most confident in product areas where he has experience; and should be able to speak confidently about such topics. However, in less familiar sales areas he is likely to speak less confidently, and may communicate this cautiousness to the customer.",
            },
            {
                name: "Team Player",
                version: "",
                description: "",
                icon: "",
                category: "Ensuring Satisfaction",
                superCategory: "Growth",
                index: "Personality Profile Index",
                inverseName: "Works Alone",
                rawScore: 16,
                percentile: 4,
                bandDescription:
                    "A low score on the Team Player scale indicates that john Doe may show little desire to work in a group situation, and tends to prefer working alone wherever possible. He may believe that individuals are more effective and productive working alone on sales tasks than in groups or teams. He may perceive the input of colleagues or sales team members as interference, and dislikes working in a team context. john Doe may also be more motivated by individual success than group success, and therefore put his own goals ahead of the goals of the team. He tends to perform better alone than in a group.",
            },
            {
                name: "Infrequency",
                version: "",
                description: "",
                icon: "",
                category: "Infrequency",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                rawScore: 5,
                percentile: 1,
                bandDescription:
                    "john Doe ranked in the lower range on the Infrequency measure. This indicates that he appears to have considered each question and responded thoughtfully, as his response pattern is quite consistent with the norm, and shows little evidence that he may have been responding to items in a random fashion.",
            },
            {
                name: "Social Desirability",
                version: "",
                description: "",
                icon: "",
                category: "Social Desirability",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                rawScore: 8,
                percentile: 1,
                bandDescription:
                    "john Doe ranked in the lower range on the Social Desirability measure. This suggests that he responded to the questionnaire items with little consideration for what he thought other people would expect him to say.",
            },
            {
                name: "Extreme Scores",
                version: "",
                description: "",
                icon: "",
                category: "Extreme Scores",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                percentile: 25,
                bandDescription:
                    "john Doe ranked in the lower range on the Extreme Scores measure. This indicates that he did not select the 'extreme' scores (such as ones and fives on a one-to-five scale) than would normally be expected. This suggests that he reserved his extreme responses for only those items which he genuinely felt strongly about.",
            },
            {
                name: "Central Tendency",
                version: "",
                description: "",
                icon: "",
                category: "Central Tendency",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                rawScore: 30,
                percentile: 25,
                bandDescription:
                    "john Doe ranked in the lower range on the Central Tendency measure. This indicates that his response pattern shows very little tendency towards constantly selecting the 'middling' response, such as 'sometimes', 'yes and no', or 'no more or less'. This suggests that he is likely to have been decisive and committed in his response patterns, with little or no fence-sitting.",
            },
        ],
        introduction: {
            title: "Introduction",
            description: null,
            subHeadings: [
                {
                    title: "Confidentiality",
                    description: [
                        "This is a highly confidential document. It is provided to the company or executive named on the coversheet on the basis that it is accepted and recognised as confidential and that such confidentiality will be strictly maintained.",
                        "It should therefore only be read by senior staff specifically involved with the selection, promotion or development of the person named, and stored securely with minimum access.",
                        "If such responsibility is not accepted, or the need for the report or the organisational relationship with the individual concerned ceases, the report should be returned to Psych Press or destroyed. In a recruitment context, reports on unsuccessful candidates should be deleted.",
                        "Should a report be required at a later date, it can be obtained without further cost from Psych Press archives.",
                    ],
                },
                {
                    title: "Purpose",
                    description: [
                        "The report has been written specifically for a sales related role and this purpose has determined the manner in which information has been conveyed, the interpretation provided and the conclusions drawn. Therefore it should not be used for any other purpose as the information and results are likely to be inaccurate and misleading within a new context.",
                        "Therefore it is a condition of the provision of this report that it will not be provided to the individual concerned. The risk of its misinterpretation and the possible consequences for those involved require that the information be provided in an appropriate form and context.",
                    ],
                },
                {
                    title: "Feedback",
                    description: [
                        "Psych Press recognises the ethical responsibilities to both candidate and client and will always provide appropriate feedback to the individual concerned. Either telephone or face-to-face discussion can provide informed professional feedback on the assessment results within the context of personal and career development.",
                    ],
                },
                {
                    title: "Cross validation of Outcomes",
                    description: [
                        "This assessment report provides objective and independent information on a candidate's personality attributes in relationship to a sales related role, which may not be available from other sources. However such data should always be used to complement and not substitute for information obtained from other sources. Best practice and legal obligations require that decisions be made from a synthesis and assessment of information acquired from different sources and not on this report alone. Psych Press can assist and advise in the development of other data sources such as structured interviews, referee reports, bio-data and the validation of this data against competencies relevant to job performance or role development.",
                    ],
                },
                {
                    title: "Ongoing Support",
                    description: [
                        "Psych Press offers continuing support in the use of psychometric information. Clients are invited to discuss the issues flowing from this report at either an individual or organisation level.",
                    ],
                },
            ],
        },
        rationale: {
            title: "Rationale",
            description: [
                "Current theory supports the observation that success metrics in a business can be approached through three sources of competitive advantage: financial, technological or human resources. The intellectual capital of human resources is the primary and most valuable asset for an organisation (Cook, 1988). As sales are the lifeblood of any revenue-seeking organisation (Subhani et al., 2011) it is imperative to understand the psychological factors impacting upon sales success, and to predict sales performance, reduce selection error and increase profit and revenue. Reviews of the psychological literature have identified personality as one of five effective predictors of sales performance (personality, bio-data, demographics, sales knowledge and sales attitude) (Charan, 2008; Barrick & Mount, 1991; Shippman, Switzer and Roth, 1988).",
                "Thus for any organisation, the selection of the candidate that is the 'best fit' is of critical importance. Studies have demonstrated that valid selection practices are also crucial in contributing to considerable financial savings for an organisation (Hunter & Hunter, 1984; Compton & Nankervis, 1991). Such selection practices can impact directly on output quality and quantity at the individual, group and organisational level.",
                "Sales are related to wealth and opportunity creation at both a commercial/organisational level as well as a personal/career development level. At the organisational level, understanding sales drivers contributes toward the commercial success of an organisation \u2013 in both revenue and profitability. At an individual level such insights and predictive ability will assist an organisation to appropriately develop the careers of their salespeople with initiatives firmly based on applied behavioural research.",
                "The Saleable\u2122 profile provides personality data to understand candidate's potential for success in a sales context. Both strengths and development needs are identified and related to effectiveness in a sales role.",
                "The Saleable\u2122 profile is based upon research examining the link between personality traits and sales success. Relevant research has been examined to indicate which personality traits are most often associated with sales success. Results for these traits are clearly defined and explained in the context of a universally recognised sales cycle (Dubinsky, 1980), in order to be easily interpretable - in commercial terms. The sales cycle consists of seven stages from prospecting to making the sale to gaining referrals from valued customers.",
                "The relationship between the identified traits and sales success has been explored with individual companies in different industries, and in general terms across all industries for respondents involved in sales in order to ensure its practical value.",
            ],
            subHeadings: null,
        },
        generalInformation: {
            title: "General Information for Interpreting this Report",
            description: [
                "This report provides objective self-report information on the candidate's suitability for sales roles. It is based on research examining the link between personality and sales success. The information provided gives an understanding of the candidates preferred approach to sales within a universally recognised sales cycle and enables an objective comparison with other candidates and a normative group. The results can promote useful discussion and consideration of suitability when compared and contrasted with other selection data.",
                "Individuals do not 'pass or fail' on personality dimensions - preferences are simply compared with a relevant population group to assist selection, performance management or career development decisions.",
                "The results presented in this report are expressed in terms of a percentile (%) score for each test or questionnaire administered. A percentile is a score below which a certain percentage of the members of a selected sample group fall. For example, when using an Australian adult sample as a comparison group, the 78th percentile is that score below which 78 percent of the total group falls.",
            ],
            subHeadings: [
                {
                    title: "Specific Population Comparisons",
                    description: [
                        "A general adult sample is only one reference group against which an individual's scores can be compared. Since client companies often need or may wish to compare individual performance to their own company data or to an industry or specific sales sample, Psych Press can build or provide such normative data for comparison purposes wherever possible.",
                    ],
                },
                {
                    title: "Interpretation",
                    description: [
                        "Percentile scores can be misleading if small differences between individuals' scores are interpreted as implying significant differences in suitability or work performance.",
                    ],
                },
                {
                    title: "Score Ranges",
                    description: [
                        "To avoid misinterpretation, score ranges are used to group scores and relate them to a 'normal curve of distribution'. Put simply, this means that on any assessment where an individual is compared with a large comparison group, a greater number of people will fall in the 'middle' or average range than at either end of a particular scale. This is reflected in the percentile ranges as shown below.",
                        "The contents of this confidential report are designed to be read by managers who are either experienced or trained in the use of psychologically based decision support tools.",
                        "The report is based upon objectively derived information and is expressly intended to be used in conjunction with other elements of recruitment or development information such as information derived from resumes or CV's indicating previous work history and achievements, interview data, reference checking, and perhaps assessment centre and/or bio-data information. Such sources of data, combined with professional judgement, allow the statements contained in this report to be viewed as professional opinion which can be compared and validated against other relevant sources of information. Interpretive statements should not be considered in isolation to this complementary information and the purpose for which the report was written.",
                        "Here it can be seen that the spread of scores within each range moves from a narrow band at each end of the scale through to a broader central 'average' band as scores approach the Average category. When used to interpret certain occupational interests and personality scales scores do not refer to a specific aptitude or ability, but rather are an indicator of preference, liking or opinion in comparison to a group of people.",
                    ],
                },
                {
                    title: "Psych Press uses a basic score range for ability percentile scores:",
                    description: [
                        "91st - 99th percentile : Superior performance",
                        "63rd - 90th percentile : Above Average performance",
                        "37th - 62nd percentile : Average performance",
                        "10th - 36th percentile : Below Average performance",
                        "1st - 9th percentile : Poor performance",
                    ],
                },
            ],
        },
        personalityProfileIndex: {
            title: "Personality Profile Index",
            description: [
                "Personality assessments measure the candidate's relatively stable preferences for certain behaviours and give insight into how their dispositions will fit into your sales team and how much of a benefit they will be to your organization's goal of increasing revenue and growth. Personality scores should be interpreted as the individual's preferred style of action in the sales environment.",
                "The following graphs represent the individual's scores on a continuum from low to high. The characteristic corresponding with a low score is listed to the left of the graph, whilst the characteristic corresponding with a high score is listed to the right of the graph. If an individual scores high, the bar will be approaching the characteristic on the right, indicating the individual's preference for that type of strategy or behaviour. If the individual scores low, their scores reflect an individual preference for the characteristic on the left, and the bar will end closer to the left side of the continuum.",
            ],
            subHeadings: null,
        },
        responseStyleIndex: {
            title: "Response Style Index",
            description: [
                "Unusual results on any of these scales may suggest that candidates are responding randomly, attempting to present themselves in an artificially favourable light, entering artificially moderate responses or otherwise failing to complete the assessment in a complete and accurate manner. Conscious and unconscious distortions in self-report data may be grouped into several categories, each of which is addressed by the scales interpreted below",
            ],
            subHeadings: null,
        },
        furtherInformation: {
            title: "Further Information",
            description: [
                "For additional information regarding the practicality, theoretical background, administration, and scoring of the Saleable\u2122 assessment, please refer to the Saleable\u2122 manual. This resource should also assist you in further interpreting the candidate's results detailed in this report. Additionally, do not hesitate to contact a Psych Press representative via email info@psychpress.com.au; phone +61 3 9670 0590 or fax +61 3 9642 3577.",
            ],
            subHeadings: null,
        },
    },
    menuDriven: {
        reportName: "Menu Driven Recruitment",
        reportDescription: "",
        reportFields: [
            {
                name: "Dedication",
                version: "",
                description:
                    "This scale identifies the extent to which an individual will feel a sense of duty and moral obligation and how committed to an organisation they will be. This scale also examines the extent to which an individual is dependable and reliable.",
                icon: "",
                category: "Managing Tasks",
                rawScore: 19,
                percentile: 8,
                bandDescription:
                    "A low score on this scale indicates that an individual feels less bound by social or work obligations, and may be seen by others as independent, unreliable or irresponsible. They often find contracts, rules and regulation to be overly confining and may avoid these things where possible. These individuals may however be more adaptable in their ability to change their approach quickly as the situation requires.",
            },
            {
                name: "Diligence",
                version: "",
                description:
                    "This scale assesses a tendency to work hard and examines the inclination to set goals. This scale also assesses the individual's level of motivation to achieve these goals.",
                icon: "",
                category: "Managing Tasks",
                rawScore: 13,
                percentile: 1,
                bandDescription:
                    "A low score on this scale indicates an individual with the tendency to have lower levels of self-discipline and to place less emphasis on setting goals. Low scorers are less likely to be motivated by achieving goals and may struggle to continue when goals become difficult.",
            },
            {
                name: "Tolerance for ambiguity",
                version: "",
                description:
                    "This scale measures an individual's tendency to make sense of ambiguous information and an individual's preference towards having an analytical approach to thinking. This scale also assesses an individual's preference for working in an ambiguous environment.",
                icon: "",
                category: "Managing Tasks",
                rawScore: 33,
                percentile: 54,
                bandDescription:
                    "An average score on this scale indicates that an individual is likely to have some capacity to deal with uncertainty in the workplace, however they may not be able to comprehend how all the components and aspects involved are related. Average scorers may also tend to find solutions to ambiguous tasks by assessing some of the possibilities, but not always approaching all of them.",
            },
            {
                name: "Autonomy",
                version: "",
                description:
                    "This scale measures the extent to which an individual is likely to be comfortable acting with independence. This scale also examines the likelihood a person is able to work without supervision.",
                icon: "",
                category: "Managing Self",
                rawScore: 13,
                percentile: 1,
                bandDescription:
                    "A low score on this scale indicates an individual who finds that input from others is welcomed and aids them in the decision making process. Low scores may also struggle to work autonomously and therefore may require supervision when undertaking tasks.",
            },
            {
                name: "Self-discipline",
                version: "",
                description:
                    "This scale identifies the extent to which an individual is self-controlled. This scale also examines the level of which an individual is able to begin and follow through on plans, projects and goals.",
                icon: "",
                category: "Managing Self",
                rawScore: 19,
                percentile: 5,
                bandDescription:
                    "A low score on this scale indicates an individual that is likely to be easily distracted from a task. While this should help with their ability to multi-task, it may cause them to lose concentration and delay in completing repetitive or detailed work. This is well suited to roles with numerous small tasks which compete for attention, but may be less appropriate for roles involving long or complex projects which require a disciplined approach.",
            },
            {
                name: "Strategic Orientation",
                version: "",
                description:
                    "This scale examines the preference toward having either a short-term or long-term perspective when making plans and decisions. This scale also measures the amount of time an individual is likely to spend considering the outcomes of their decisions.",
                icon: "",
                category: "Managing Self",
                rawScore: 19,
                percentile: 45,
                bandDescription:
                    "An average score on this scale indicates an individual who tends to exhibit both long and short-term perspectives depending on situational demands. These individuals may take some time and deliberate over options when making decisions, however, at times they may decide quickly without much consideration.",
            },
            {
                name: "Ecological Resilience",
                version: "",
                description:
                    "This scale examines the degree to which an individual is composed, determined and resourceful. This scale also captures the capacity for an individual to work towards goals in a persistent and committed manner regardless of obstacles.",
                icon: "",
                category: "Managing Self",
                rawScore: 14,
                percentile: 1,
                bandDescription:
                    "A low score on this scale reflects an individual that is less likely to be determined in their work. Low scorers also tend to be less goal oriented, and lose motivation quicker when complications arise or conflicting interests are present.",
            },
            {
                name: "Engineering Resilience",
                version: "",
                description:
                    "This scale measures the extent to which an individual has the capacity to recover and 'bounce back' following difficult events or circumstances, as well as the speed of this recovery process. This scale further assesses whether an individual's emotional coping styles are effective in the face of difficulty.",
                icon: "",
                category: "Managing Self",
                rawScore: 8,
                percentile: 1,
                bandDescription:
                    "A low score on this scale indicates that the individual is more likely to find it difficult to emotionally recover following hardships and setbacks. Low scorers also tend to lack effective coping styles, such as relaxation and seeking support, when dealing with stressful situations.",
            },
            {
                name: "Adaptive Resilience",
                version: "",
                description:
                    "This scale identifies an individual's capacity to adapt effectively to changes in routine or circumstance. This scale therefore examines whether an individual enjoys and is open to new experiences.",
                icon: "",
                category: "Managing Self",
                rawScore: 14,
                percentile: 14,
                bandDescription:
                    "A low score on this scale indicates that the individual tends not to adjust effectively and promptly to changes in environment or circumstance. Low scorers are also less likely to be open to novel and different ideas and experiences.",
            },
            {
                name: "Compassion",
                version: "",
                description:
                    "This scale examines the tendency for an individual to have concern for other people\u2019s problems and issues. This also examines the degree to which an individual can balance these concerns with objectivity and separate themselves from what others are experiencing.",
                icon: "",
                category: "Managing Others",
                rawScore: 23,
                percentile: 97,
                bandDescription:
                    "A high score on this scale reflects an individual who is more likely to show a preference towards being tender-minded and caring. Individuals who score high on this scale tend to show a high level of respect and consideration towards their clients and colleagues, and will be sympathetic to the needs and interests of others.",
            },
            {
                name: "Helpfulness",
                version: "",
                description:
                    "This scale examines an individual\u2019s willingness and inclination to assist other people. This scale also identifies the degree to which an individual can balance their consideration for clients and colleagues with objective and practical concerns.",
                icon: "",
                category: "Managing Others",
                rawScore: 21,
                percentile: 41,
                bandDescription:
                    "An average score on this scale indicates an individual that is willing to help others when required, but may not actively seek out opportunities to do so. Their likelihood of helping colleagues will probably depend on their own workload and deadlines. This result represents a balance between appearing unsupportive towards their colleagues, and being too willing to help at the expense of their own productivity.",
            },
            {
                name: "Approachability",
                version: "",
                description:
                    "This scale examines the extent an individual is friendly and easy to talk to, compared to being impersonal and objective. This scale also examines an individual\u2019s tendency toward reaching out to others on a personal level.",
                icon: "",
                category: "Managing Others",
                rawScore: 19,
                percentile: 22,
                bandDescription:
                    "A low score on this scale indicates an individual with the tendency to not reach out to others on a personal level, and as such may be seen as distant and reserved. These individuals may take a long time to 'warm' to people, and are less likely to openly demonstrate positive feelings towards others.",
            },
            {
                name: "Greed-Avoidance",
                version: "",
                description:
                    "This scale measures the degree of importance an individual places on material objects. This scale also assesses how concerned an individual is with obtaining wealth and being successful.",
                icon: "",
                category: "Managing Risk",
                rawScore: 19,
                percentile: 26,
                bandDescription:
                    "A low score on this scale reflects an individual who tends to place high value in material possessions, status, and wealth. It is likely that low scorers are concerned with obtaining, enjoying and displaying their wealth and success.",
            },
            {
                name: "Morality",
                version: "",
                description:
                    "This scale measures the degree an individual prefers to be straightforward and truthful when dealing with others. This scale also assesses the level an individual is guarded in regards to telling the truth, and their belief around the necessity of deception in social relationships.",
                icon: "",
                category: "Managing Risk",
                rawScore: 17,
                percentile: 7,
                bandDescription:
                    "A low score on this scale indicates that an individual may not place importance on telling the truth or being straightforward. These individuals may have a tendency to speak with hidden meanings, and rarely make their intentions plainly obvious.",
            },
            {
                name: "Humility",
                version: "",
                description:
                    "This scale refers to the way in which an individual deals with recognition for their achievements. This scale therefore examines the extent an individual may appear arrogant and proud, instead of modest and humble.",
                icon: "",
                category: "Managing Risk",
                rawScore: 19,
                percentile: 52,
                bandDescription:
                    "An average score on this scale reflects an individual who is likely to be willing to talk about themselves and their achievements in necessary situations. They understand that they and their work may benefit by acknowledging their own strengths. They are also likely to be content with not disclosing achievements, if doing so would not provide any benefit, or could be seen as arrogance or bragging.",
            },
            {
                name: "Risk Taking",
                version: "",
                description:
                    "This scale measures the extent to which an individual is willing to take risks in order to achieve desired goals. It also measures a preference for taking risks, without being deterred by the possibility of making mistakes or facing negative outcomes.",
                icon: "",
                category: "Managing Risk",
                rawScore: 31,
                percentile: 27,
                bandDescription:
                    "A low score on this scale indicates an individual who is more likely to avoid taking risks regardless of the possible gains that may be obtained. This individual likely prefers predictable situations where there is little chance to be exposed to novelty and are therefore suited to roles that require a systematic approach guided by rules and regulations.",
            },
        ],
        introduction: {
            title: "Introduction",
            description: [
                "The following profile has been based on psychological and cognitive screening assessments. It provides elements of insight and understanding into Nakhorn's relevant strengths and development needs in the context of Psych Press's leadership competencies. In this profile you will also find a list of the applicant's key strengths and potential development areas based on the applicant's screening results.",
                "This report is based on one of the most researched and respected models of personality, identifying behaviour preferences across Cattell's 16 personality constructs (Cattell, 1946) and the big five personality traits (McCrae and Costa, 1987). These provide insight into how people typically think, feel and interact in ways that may be productive or counter-productive for an organisation:",
                "Extensive research, conducted over many years, has consistently demonstrated that 10-30% of the variance in job performance is attributable to personality differences. Moreover, a person's potential for burnout, their trainability and subsequent job satisfaction, have all been shown to be strongly influenced by personality. Thus personality assessment forms a central part of most careers guidance and counselling programmes, with the aim of helping individuals maximise their potential by finding an optimal match between their personality and their choice of career. The model below illustrates the relationship between behavioural preferences and job performance:",
            ],
            subHeadings: null,
        },
        backGroundInformation: {
            title: "Background Information for understanding and interpreting this report",
            description: null,
            subHeadings: [
                {
                    title: "Independently Compared",
                    description: [
                        "Tests, inventories and questionnaires are used to objectively measure and compare an individual's scores to a comparison group that is usually an Australian general or managerial population sample.",
                    ],
                },
                {
                    title: "Informed Decision Making",
                    description: [
                        "Individuals cannot 'pass or fail' on any ability or personality dimension \u2013 their performance is simply based on comparisons with a relevant population group to aid selection, performance management or career development decisions.",
                    ],
                },
                {
                    title: "Population comparisons",
                    description: [
                        "This report presents results in terms of a percentile (%) score for each test or questionnaire administered. A percentile is a score below which a certain percentage of the members of a sample group fall. For example, the 78th percentile of an Australian adult sample, is the score below which 78% of the total group falls.",
                    ],
                },
                {
                    title: "Specific population comparisons",
                    description: [
                        "An Australian adult sample is only one example of a reference group against which an individual's scores can be compared. Psych Press can create or provide normative data for comparison purposes to various other data sets such as client company data, or industry or management samples.",
                    ],
                },
                {
                    title: "Score Differences",
                    description: [
                        "When different norm groups are used, such scores will differ from original scores. For example, when using an Australian Adult sample, a score on the 70th percentile may reduce to the 30th percentile when compared against a tertiary qualified management sample, because members of this group will have higher abilities overall. This does not mean a person with a score at the 30th percentile has 'failed' a test; simply that in comparison to this specific sample they outperform only 30% of the members of that group on the abilities assessed.",
                    ],
                },
                {
                    title: "Confirming Results & Cross Validation of Outcomes",
                    description: [
                        "This report provides independent and objective information on a candidate's ability to work in a business setting, which is often not available from other sources. However, such data should be used to complement, not substitute information obtained from other sources. Best practice and legal obligations require that decisions be made from a combination and evaluation of information acquired from additional, differing sources such as interviews or other reports. Psych Press can assist and offer recommendations in the development of various other data sources such as structured interviews, referee reports, bio-data and the validation of this data against measures and competencies relevant to job performance or role development.",
                    ],
                },
                {
                    title: "Score Ranges Aid Interpretation",
                    description: [
                        "Minor differences between individuals' scores should not be understood as representing significant differences in work performance. Thus score ranges are provided as the use of percentile scores in this context can be misleading.",
                    ],
                },
                {
                    title: "Population Based Score Ranges",
                    description: [
                        "In order to avoid misinterpretation, score ranges are used to group scores and place them on a 'normal curve of distribution'. This normal curve of distribution shows that within a large comparison group, there will be a larger number of people in the 'average' range compared to the outstandingly good or very poor range. Clients can therefore relate abilities and aptitudes to different ranges within a population. This is reflected in the percentile ranges as shown below.",
                    ],
                },
                {
                    title: "Indicating Preferences",
                    description: [
                        "When percentile scores are used to interpret other constructs and contexts, they are subsequently interpreted differently. In the context of Personality Profiles and Occupational Interests, percentile scores do not imply or refer to a specific aptitude or ability, rather as an indicator of preference, liking or opinion compared to a specific population group.",
                    ],
                },
                {
                    title: "Score Ranges",
                    description: [
                        "General score ranges for intelligence and aptitude percentile scores are as follows: 94th-99th percentile \u2013 Exceptional/Outstanding 85th-93rd percentile \u2013 Superior 70th-84thpercentile \u2013 High Average/Above Average 31st-69th percentile \u2013 Average 16th-30thpercentile \u2013 Low Average/Below Average 07th-15th percentile \u2013 Poor 01st-06th percentile \u2013Very Poor As noted, the spread of scores within each range moves from a broader central average band in the 'Average' category to smaller and narrower bands at each end of the scale.",
                    ],
                },
                {
                    title: "Interpretation",
                    description: [
                        "Objectively-derived information regarding a range of individual attributes has been collected and collated in order to inform this report, and it is expressly intended to be utilized in combination with other facets of recruitment or development data, as necessitated by and in alignment with best practice procedures. Such elements may include, but are not limited to:data obtained from resumes or CV's detailing past work history and accomplishments,interview-derived information, reference-checking, bio-data, and information attained through assessment centers. These sources of information, in conjunction with professional judgement, permit the statements within this report to be considered as professional opinion that can be validated against and assessed in relation to other relevant sources of data. Statements of an interpretive nature should not be considered alone, but should be understood in relation to this additional information and the purposes for which the report was created.",
                    ],
                },
            ],
        },
    },
};

export const sampleSaleableZollRecruitmentScore = {
    reportName: "Saleable",
    reportDescription: "",
    generalZollProfile: {
        reportName: "General Zoll Profile",
        reportDescription:
            "Saleable\u2122 is based upon research examining the link between personality traits and sales success. Relevant research has been reviewed to indicate which personality traits are most often correlated with sales success in different contexts.",
        reportFields: [
            {
                name: "Awareness",
                version: "",
                description: "",
                icon: "",
                category: "Discovering and responding to needs",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Bluntness",
                rawScore: 22,
                percentile: 45,
                bandDescription:
                    "john Doe has scored in the average range on the Awareness scale, indicating that he is reasonably aware of the importance of tact and positive interactions when responding to the needs of customers. He should be able to remain diplomatic and moderately spoken in most prospecting situations. He may find it difficult to maintain this politeness when dealing with particularly challenging prospects, but will generally remain aware of how his behaviour might affect clients and stakeholders.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "Tend to have balance between not caring enough and caring too much about their relationships and behaviour towards other industry stakeholders.",
                        "Are likely to show a moderate level of empathy and consideration for others.",
                        "May feel as if they don't need to improve their industry interpersonal skills leading to stagnation.",
                        "May perform averagely on sales targets instead of excelling as they do not go above and beyond to please clients.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Discuss the benefits of attentive customer service and a wide career network.",
                        "Set challenges to make a sale with an existing customer or using an industry connection. This may help demonstrate how such connections are useful when working in sales and encourage further attentiveness in the future.",
                        "Host training sessions to improve interpersonal skills such as active listening and empathetic responding.",
                        "Provide feedback on how interactions were handled and how to improve in the future.",
                        "Expose them to a diverse range of clients so they can develop awareness of a broad range of consumers' needs.",
                    ],
                },
            },
            {
                name: "Competitiveness",
                version: "",
                description: "",
                icon: "",
                category: "Closing",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Contentment",
                rawScore: 20,
                percentile: 36,
                bandDescription:
                    "A score within the average range on the Competitiveness scale indicates that john Doe is most likely to strive for achievement in familiar sales situations. While his career advancement is likely to be an effective motivator, he may balance this with needs for job security and a healthy work-life balance. Although he is likely to hold a moderate desire for status as an excellent salesperson, such motivators are unlikely to be a driving force for success and advancement.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "Tend to have a balanced approach to sales.",
                        "Are likely to foster healthy competition but are not excessively emotionally affected by performance outcomes. Clients can see their engagement and commitment to meeting their needs but are not overwhelmed with their intensity and desperation to close a sale.",
                        "May base some of their success on financial and social incentives, but also on long term client satisfaction and team collaboration. This means they are more emotionally well rounded as they can obtain self-esteem from many different areas if one area becomes lacking.",
                        "Are likely to be adaptable to more competitive and less competitive sales environments. They can meet the needs of busy and direct clients as well as more laid-back clients who take their time to make a purchase.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Utilise their strengths by placing them in diverse roles which include a range of contexts such as individual and teamwork projects or projects with urban versus rural clients due to their role adaptability.",
                        "Provide regular feedback reinforcing situations where they have been adaptable and providing areas for improvement when they failed to adapt.",
                        "Provide opportunities for continuous learning and refinement of both individual and collaborative skills to ensure all their skills are being maintained.",
                    ],
                },
            },
            {
                name: "Collaborative",
                version: "",
                description: "",
                icon: "",
                category: "Managing Objections",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Directive",
                rawScore: 13,
                percentile: 2,
                bandDescription:
                    "A low score on the Collaboration scale indicates that john Doe may prefer to give directions to clients and colleagues rather than work in conjunction with them. A low score does not necessarily imply uncooperativeness, but may instead indicate a need for independence in his work. Being quite individualistic, john Doe may not give as much weight to the suggestions of clients/colleagues. He may be a tough negotiator, willing to argue for his desired outcomes and less willing to settle for a win-win solution. He should be very capable of making decisions independently, and should enjoy working in sales roles that promote autonomy. He may be less satisfied working in sales environments requiring extensive cooperation and compromise.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May be less effective with sales of more complicated products that require cross disciplinary and expert input.",
                        "May be less social and willing to inspire other team members, potentially resulting in a decrease in team morale. Often times this can lead to decreases in performance across the entire team and not just the individual.",
                        "May find it harder to manage workloads because they are less likely to divide and delegate tasks. This can result in lower quality output of work and undermine sales presentations.",
                        "May not receive as much social support from peers and therefore may be less resilient in stressful times. This can lead to unsuccessful handling of difficult customers.",
                        "May be involved in increased intra team interpersonal conflicts, due to the individual being seen as uncooperative.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Team training has been shown as an effective team development intervention for increasing teamwork and team performance. The use of role-playing and task-based situations can help to increase communication skills amongst teams.",
                        "Mentoring programs can benefit individuals with lower collaboration by shadowing those with higher collaboration scores. This can shift their personal sales approach and make sales of more complex products increasingly effective.",
                    ],
                },
            },
            {
                name: "Success focused",
                version: "",
                description: "",
                icon: "",
                category: "Discovering and responding to needs",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Distractible",
                rawScore: 15,
                percentile: 12,
                bandDescription:
                    "john Doe scored in the low range for the Success Focus scale, which suggests that he may face difficulties with keeping his sales goals in mind. He may tend to focus more on the process of selling, or engaging with new customers, and sometimes forget to single-mindedly pursue the end goal of successfully making a sale. He may be particularly susceptible to distractions, and would benefit from regular reminders about his sales and performance goals.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May be more inclined to prioritise customer needs and experience over profit-based success metrics.",
                        "Are likely to have lower motivation in reaching sales targets, which could impact individual and team performance.",
                        "They will tend to shy away from anything difficult, often taking the easy way out.",
                        "They will be less likely to set goals.",
                        "This person is unlikely to be driven by performance-based incentives.",
                        "Will be unlikely to develop further skills relating to negotiating or assertiveness.",
                        "May be defensive or indifferent to performance-based feedback.",
                        "Are unlikely to approach customers if they do not have to.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Assign these individuals to a team. These individuals may work better in a team setting as they are less concerned with personal accomplishments and are more likely to contribute to group objectives.",
                        "Identify what internally motivates the individual and align these motivations with their role, responsibilities and the product or service they are selling.",
                        "Work with the Individual to set easy and simple goals so they can improve their performance and desire to be success focused.",
                        "Provide consistent feedback, emphasising their strengths and how they can leverage them.",
                    ],
                },
            },
            {
                name: "Proactivity",
                version: "",
                description: "",
                icon: "",
                category: "Initiating Contact",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Hesitance",
                rawScore: 18,
                percentile: 2,
                bandDescription:
                    "john Doe low score on the Initiative scale suggests that he is not normally given to seeking out and contacting new prospective clients on his own. While he may be able to interact effectively with established customers, the act of contacting new prospects and working to establish a new relationship is something that is unlikely to come naturally to him. He is likely to prefer working in roles where sales are inbound, and he is able to respond to customer queries rather than actively pursue sales opportunities.",
                interactiveEffects: [
                    "High extroversion, high self-confidence, and high proactivity. May tend to come across as too pushy towards clients which may scare them off before making a sale.",
                ],
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Tend to respond to situations as they arise instead of anticipating them. This can be problematic as they may find themselves constantly playing catchup and being underprepared.",
                        "May miss potential clients as they are not actively prospecting.",
                        "May fall behind on career development as they are not seeking opportunities to upskill.",
                        "May lose competitive edge as a lack of prospecting means obtaining less of the market share, as competitors will seize on missed sales opportunities.",
                        "May consistently underperform as they are slow to address gaps in the market, and competitors who are highly proactive would consistently get there first.",
                        "May be good at quickly responding to immediate demands.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Explore ways to boost motivation and to promote proactive behaviours, such as providing a motivational mentor.",
                        "Encourage them to converse with their colleagues in the same sales field about the strategies they use for identifying gaps in the market and prospective customers.",
                        "Encourage them to network with clients and colleagues to expand sales opportunities.",
                        "Encourage professional development by recommending resources to help refine forecasting skills and trend analysis may help improve their sales performance.",
                    ],
                },
            },
            {
                name: "Extroversion",
                version: "",
                description: "",
                icon: "",
                category: "Initiating Contact",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Introversion",
                rawScore: 22,
                percentile: 1,
                bandDescription:
                    "john Doe has scored in the low range on the Extraversion scale, suggesting that he is less outgoing and energetic in work settings than most. When a sales pitch calls for a bright and enthusiastic salesperson, he may struggle to rise to the occasion, and potential customers may be left feeling indifferent by this subdued approach. He may feel drained by repeated contacts with prospective customers, and would be most effective when making only a few such contacts each day.",
                interactiveEffects: [
                    "High extroversion, high self-confidence, and high proactivity. May tend to come across as too pushy towards clients which may scare them off before making a sale.",
                ],
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May lack energy levels when interacting with their colleagues. This may hinder their professional growth and limit their leadership capabilities.",
                        "May struggle with presentation skills due to a lack of confidence and charisma. This may result in losing potential clients if they cannot engage them in a sales pitch.",
                        "May struggle making sales if they cannot engage potential customers, or have the confidence required to follow up and maintain relationships with existing clients.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Help them to improve their confidence in interacting with customers by encouraging them to practice sales pitches before they occur, and by encouraging gradual exposure to more social situations.",
                        "Develop some conversation starters they can say to customers entering the shop.",
                        "Encourage them to attend networking events to facilitate professional relationship building.",
                        "Reassessing their employment in a sale's specific context, as sales is a highly customer-facing job and requires constant social interaction. Their skills may be useful in different areas of the organisation.",
                        "Ensure they do not burnout by encouraging them to take time away from work to be alone and recharge. Working in a sales context is a highly social environment, so introverted people may need to focus more on socially recharging.",
                    ],
                },
            },
            {
                name: "Motivation",
                version: "",
                description: "",
                icon: "",
                category: "Discovering and responding to needs",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Relaxed Approach",
                rawScore: 21,
                percentile: 44,
                bandDescription:
                    "john Doe has scored in the average range on the Motivation scale, suggesting that he is reasonably well-motivated to perform well in his work. As prospecting often gives little in the way of reinforcement, his moderate levels of motivation should be enough to see him through most challenging times, while maintaining the drive to work hard and achieve objectives. While his motivation is likely to suffer in particularly challenging prospecting situations, he should generally be able to deal with the challenges of the role, and remain enthused enough to interact effectively with prospective buyers.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "May perform consistently; however, are unlikely to exceed their duties.",
                        "May have career goals but their plans of achieving them are not well defined.",
                        "May engage in professional development opportunities when they are offered, but not actively seek them out.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Provide well rounded feedback. If only negative feedback is provided, then motivation can decrease future due to decreased competence.",
                        "Discuss personal values and goals and develop a plan to align their work and personal goals. If they feel that their work is meaningfully contributing to something they care about they may be more likely to do well.",
                        "Put them in contact with professional development opportunities to improve their sales skills.",
                    ],
                },
            },
            {
                name: "Persistence",
                version: "",
                description: "",
                icon: "",
                category: "Managing Objections",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Self-Concerned",
                rawScore: 23,
                percentile: 42,
                bandDescription:
                    "john Doe scored in the average range on the Persistence scale, and is likely to be persistent as most when it comes to selling. This represents a balance between not giving up easily on a resistant customer, and having a definite understanding of when he should give up on a sale. While this means he may miss out on making sales that a more persistent salesperson might achieve, it has positive implications for his ability to avoid upsetting sensitive customers, and for helping to preserve the possibility of making a successful sale in future.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "May not feel the need to push past their comfort zone, which can lead to average service to customers and a lack of distinctive effort.",
                        "May demonstrate a resistance to take up either leadership or support roles in teams as they lack the drive to push in either direction. This can lead to worse team dynamics and synergy, resulting in poorer overall performance.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Goal setting interventions, such as digital badges, can boost persistence and performance in learning, which may help less persistent salespeople in skill development and sale execution.",
                        "Salespeople with average persistence levels can struggle in gauging persistence levels. Feeback from experienced managers can guide and enhance their sales performance and motivation.",
                    ],
                },
            },
            {
                name: "Goal Orientation",
                version: "",
                description: "",
                icon: "",
                category: "Setting A Strategy",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Short Term Focus",
                rawScore: 28,
                percentile: 56,
                bandDescription:
                    "His average score on Goal Orientation indicates john Doe will be quite able to focus on prospecting, but will not necessarily do so at all times. While he is much less likely to become distracted or bored when planning sales strategies in areas where he has an interest, he will tend to lose focus in work environments where there are many distractions. Although he can be self-disciplined, efficient and good at strategic planning, he may at times become distracted from prospecting because of issues that 'come up' and had not been prioritised for that time period.",
                interactiveEffects: [
                    "Low goal orientation, high planning - Tendency to have a lot of plans without an overall direction or focus for them.",
                    "High goal orientation, low planning - Tendency to be idealistic with big goals but without actionable steps to achieve them.",
                ],
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "May not proactively seek learning opportunities because they feel comfortable with their current skills. This can lead to unrealised potential in sales performance, as upskilling has been shown to improve sales performance.",
                        "May struggle with procrastination.",
                        "May tend to meet minimum sales requirements and not strive for larger targets.",
                        "Tend to lack an intrinsic motivation to excel.",
                        "May be more extrinsically than intrinsically motivated, meaning effort is not placed into areas of the job where there are not observable rewards. Such as checking in with clients after purchases are already made.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Making sure goals and performance expectations are clearly understood. This can help employees stay engaged and focused on tasks by providing a sense of purpose. Goal setting studies in academic settings have demonstrated that writing down goals and whether they have been achieved can prevent procrastination and increase content engagement.",
                        "Managers could incentivise higher achievement by providing external rewards which may promote motivation as there is less reliance on employee's intrinsic motivation.",
                        "Seek out motivational speakers. They can promote motivation by offering new insight and inspirational stories. It is important to seek speakers who can offer practical advice.",
                        "Individuals should challenge themselves by occasionally setting slightly higher sales targets. It is important to ensure the targets are still achievable, as if they succeed, it can help them realise their capabilities and motivate them to aim higher by increasing their self-efficacy.",
                        "Invest in ongoing skill development workshops for sales employees. Having refined and up to date skills can indirectly enhance employee motivation by increasing their self-efficacy and making tasks feel more manageable.",
                    ],
                },
            },
            {
                name: "Planning",
                version: "",
                description: "",
                icon: "",
                category: "Setting A Strategy",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Spontaneity",
                rawScore: 31,
                percentile: 38,
                bandDescription:
                    "His average score on the Planning scale suggests that john Doe will show most confidence with strategic planning in areas where he has a high level of experience. He is able to work independently in organising his time, but may occasionally require some prompting. This would be particularly true in areas of prospecting that hold little interest for he, or where he has little experience. He may set goals, but struggle to persist in working towards them, or may prioritise inefficiently at times.",
                interactiveEffects: [
                    "Low goal orientation, high planning. Tendency to have a lot of plans without an overall direction or focus for them.",
                    "High goal orientation, low planning. Tendency to be idealistic with big goals but without actionable steps to achieve them.",
                ],
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "Tend to have some level of planning in their approach, focussing on sales opportunities that are currently active.",
                        "May have an inconsistent approach in the planning of their sales process.",
                        "May lack a long-term comprehensive sales strategy. With reasonable planning skills they may be on top of short-term projects but not fully account for long term goals.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Identify the situations in which planning and organisational ability is lacking and tackle the reasons why there is an inefficiency in this area.",
                        "Have meetings with supervisors to address long-term sales strategies.",
                        "Use a structured calendar to develop a weekly plan and make clear which tasks to prioritise.",
                    ],
                },
            },
            {
                name: "Managerial",
                version: "",
                description: "",
                icon: "",
                category: "Management Growth And Refferals",
                superCategory: "Growth",
                index: "Personality Profile Index",
                inverseName: "Subordinate",
                rawScore: 25,
                percentile: 67,
                bandDescription:
                    "An average score on the Managerial scale indicates that john Doe believes in his ability to effectively manage others, but this belief is less strong in areas with which he is less familiar. \u00a0He can be very persuasive in convincing others to follow his lead, and this tends to occur mostly in areas which he has a high level of knowledge, expertise, or experience. He may be dissuaded by obvious opposition to his way of doing things, and may forego trying to persuade others to understand his point of view when under time constraints or stress. However, john Doe is capable of motivating others to succeed in the workplace when the circumstances are right. He will tend to perform more effectively in sales roles that do not solely rely on one's ability to influence others.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "May feel comfortable taking on leadership roles with projects or goals they have experience in.",
                        "May be inconsistent in their ability to manage and motivate their sales team, finding it challenging to go beyond basic managerial tasks and lead their teams to higher performance.",
                        "Are able to follow standard operational procedures for managing a team; however, they may be unable to adapt quickly to change.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Help the individual become competent by gradually increasing their responsibilities. Acknowledge their efforts and provide positive feedback to boost their motivation and confidence.",
                        "Provide them with a mentor or pair them up with a more competent manager who can facilitate their growth by showing them the way.",
                        "Employ role-playing to expose the person to scenarios they may feel uncomfortable in, such as handling conflict or addressing and motivating the team.",
                        "Provide them with established managerial strategies and practices to promote further learning and development.",
                    ],
                },
            },
            {
                name: "Relaxed Style",
                version: "",
                description: "",
                icon: "",
                category: "Ensuring Satisfaction",
                superCategory: "Growth",
                index: "Personality Profile Index",
                inverseName: "Tenseness",
                rawScore: 29,
                percentile: 9,
                bandDescription:
                    "john Doe obtained a low score on the Relaxed Style scale, indicating that he will seem worried or nervous during stressful situations. This result may be indicative of an ongoing tension which may negatively impair his relationships with clients. It is unlikely that he will always be able to manage his emotions or reactions effectively, and this may adversely affect his client relationships.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May not spend ample time thoroughly exploring the customer's needs and preferences before providing recommendations of products and services.",
                        "May not be invested in building and maintaining strong relationships with customers that can result in repeat business.",
                        "May not be prioritising product quality in order to meet expectations and build customer trust.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Discuss what the expectations of product quality are within the organisation and ensure that employees are aware of the negative outcomes of low-quality products and services on the business.",
                        "Encourage employees to consider the views, perspectives, and values of the customer when communicating with them to further understand their needs.",
                        "Train employees to be effective communicators and deliver information in a manner that the customer understands and encourage employees to personalise their interactions with customers.",
                        "Enable employees to make accurate recommendations for products and services by ensuring they have adequate information and knowledge about solutions available.",
                    ],
                },
            },
            {
                name: "Assertiveness",
                version: "",
                description: "",
                icon: "",
                category: "Managing Objections",
                superCategory: "Selling",
                index: "Personality Profile Index",
                inverseName: "Timidness",
                rawScore: 20,
                percentile: 99,
                bandDescription:
                    "john Doe high score on the Assertiveness scale indicates that he is a forthright person, who is able to communicate effectively in a variety of sales environments. As an assertive salesperson, he should be able to efficiently share ideas and information with others, and provide feedback without being aggressive or upsetting customers. He is also unlikely to be intimidated by aggressive or belligerent customers, and should be capable of holding his ground in a calm and level-headed manner.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "High scorers such as john Doe:",
                    points: [
                        "Will be able to effectively communicate and provide clients with necessary feedback and negotiate deals.",
                        "Are unlikely to be intimidated by aggressive or belligerent customers or clients.",
                        "May come across as too pushy to more introverted or laid back clients.",
                        "May struggle to take on feedback from their employer or a customer, which may result in missed opportunities to improve their sales strategy.",
                        "May be too focused on own goals, which may result in poor teamwork or a lack of attentiveness for client needs in effort to make a sale.",
                        "Will be very likely to approach prospective clients or customers.",
                        "Tend to show confidence in their actions, opinions, and abilities.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Teach them active listening skills. This can enhance their ability to understand the perspectives of others and work in a collaborative manner within a team and their prospective customers.",
                        "Help them to distinguish between being assertive and being aggressive. Assertiveness should be done in a way that respects others and doesn't come across as overly dominating or intimidating.",
                        "Encourage them to be receptive to feedback. Even though they might feel confident in their actions, there is always room for growth and improvement.",
                        "Encourage them to recognise and appreciate the contributions of others, even when they take leadership roles or out-perform others.",
                        "Teach them how to use open-ended questions to encourage dialogue and understanding rather than imposing their viewpoints.",
                        "Challenge them in healthy ways by tasking them with something that may be slightly outside their experience, such as leading a team, reaching a higher sales goal, or producing a sales strategy for a new product.",
                    ],
                },
            },
            {
                name: "Self-Confidence",
                version: "",
                description: "",
                icon: "",
                category: "Initiating Contact",
                superCategory: "Prospecting",
                index: "Personality Profile Index",
                inverseName: "Uncertainty",
                rawScore: 26,
                percentile: 78,
                bandDescription:
                    "john Doe scored in the high range on the Self-Confidence scale, which indicates that he is quite certain of his ability to influence sales outcomes when given the chance to do so. Highly self-confident individuals like john Doe see themselves as effective individuals who are able to direct sales situations, and this confidence is communicated to prospective clients through their certain and reassuring manner. He should be able to make good contact with customers, and speak credibly and convincingly throughout the sales process.",
                interactiveEffects: [
                    "High extroversion, high self-confidence, and high proactivity. May tend to come across as too pushy towards clients which may scare them off before making a sale.",
                ],
                considerationsWithinTheWorkplace: {
                    heading: "High scorers such as john Doe:",
                    points: [
                        "May overpromise on deliverables, leading to unrealistic client expectations and strained relationships.",
                        "May be less able to integrate advice, making the tailoring of sales more difficult.",
                        "May demonstrate rigid attitudes and sales approaches, even when circumstances require a different approach to be adopted.",
                        "May resist constructive feedback, limiting their growth opportunities.",
                        "May overstep boundaries, resulting in decisions that don't align with the overall strategy and vision of an organisation.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Shifting salespeople's focus from individual achievement to group achievement, through leadership that fosters team spirit, can help to improve enthusiasm and performance.",
                        "Promoting collaboration to emphasise teamwork can encourage team bonding and knowledge-sharing, which can positively influence business and sales performance.",
                        "Active listening coaching can directly increase sales outcomes by enabling better understanding of customer's needs and deeper interpersonal relationships within teams.",
                    ],
                },
            },
            {
                name: "Team Player",
                version: "",
                description: "",
                icon: "",
                category: "Ensuring Satisfaction",
                superCategory: "Growth",
                index: "Personality Profile Index",
                inverseName: "Works Alone",
                rawScore: 20,
                percentile: 30,
                bandDescription:
                    "A low score on the Team Player scale indicates that john Doe may show little desire to work in a group situation, and tends to prefer working alone wherever possible. He may believe that individuals are more effective and productive working alone on sales tasks than in groups or teams. He may perceive the input of colleagues or sales team members as interference, and dislikes working in a team context. john Doe may also be more motivated by individual success than group success, and therefore put his own goals ahead of the goals of the team. He tends to perform better alone than in a group.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Tend to avoid engaging with other team members and instead work by themselves. This may limit the opportunities the employee has to collaborate and work towards a shared goal with other team members.",
                        "May not be comfortable asking for help from supervisors or other team members and may be unwilling to give help or support others.",
                        "May not communicate with other team members effectively and appropriately, which may lead to misunderstandings when completing tasks or friction within the team.",
                        "May be overly focused on individual achievements rather than joint efforts of the team.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Encourage the employee to be aware of how their behaviour and working style impacts the sale performance of the team. Self-reflection may allow an individual to map out their strengths, weaknesses, and potential areas of improvement.",
                        "Shifting the focus from constantly setting individual goals to focus more on team goals may help the individual integrate into a collaborate working environment.",
                        "Encourage the individual to possess a positive and optimistic perspective on teamwork and encourage them to participate more in team meetings and discussions.",
                        "Make attempts to celebrate successes within the team frequently, as it may serve as motivation for further participation and involvement.",
                    ],
                },
            },
            {
                name: "Infrequency",
                version: "",
                description: "",
                icon: "",
                category: "Infrequency",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                rawScore: 1,
                percentile: 80,
                bandDescription:
                    "john Doe ranked in the higher range on the Infrequency measure. This indicates that he responded to questions in an unpredictable and unusual way, with little similarity to the response pattern of other respondents. This suggests that he may have been responding at random, and not really trying to make accurate responses to each item, possibly out of tiredness or boredom. This needs to be carefully considered before attempting to interpret any meaningful conclusions from his results.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: null,
                coachingTips: null,
            },
            {
                name: "Social Desirability",
                version: "",
                description: "",
                icon: "",
                category: "Social Desirability",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                rawScore: 10,
                percentile: 13,
                bandDescription:
                    "john Doe ranked in the lower range on the Social Desirability measure. This suggests that he responded to the questionnaire items with little consideration for what he thought other people would expect him to say.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: null,
                coachingTips: null,
            },
            {
                name: "Extreme Scores",
                version: "",
                description: "",
                icon: "",
                category: "Extreme Scores",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                rawScore: 40,
                percentile: 33,
                bandDescription:
                    "john Doe ranked in the lower range on the Extreme Scores measure. This indicates that he did not select the 'extreme' scores (such as ones and fives on a one-to-five scale) than would normally be expected. This suggests that he reserved his extreme responses for only those items which he genuinely felt strongly about.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: null,
                coachingTips: null,
            },
            {
                name: "Central Tendency",
                version: "",
                description: "",
                icon: "",
                category: "Central Tendency",
                superCategory: "",
                index: "Response Style Index",
                inverseName: "",
                rawScore: 27,
                percentile: 22,
                bandDescription:
                    "john Doe ranked in the lower range on the Central Tendency measure. This indicates that his response pattern shows very little tendency towards constantly selecting the 'middling' response, such as 'sometimes', 'yes and no', or 'no more or less'. This suggests that he is likely to have been decisive and committed in his response patterns, with little or no fence-sitting.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: null,
                coachingTips: null,
            },
        ],
        introduction: {
            title: "Introduction",
            description: null,
            subHeadings: [
                {
                    title: "Confidentiality",
                    description: [
                        "This is a highly confidential document. It is provided to the company or executive named on the coversheet on the basis that it is accepted and recognised as confidential and that such confidentiality will be strictly maintained.",
                        "It should therefore only be read by senior staff specifically involved with the selection, promotion or development of the person named, and stored securely with minimum access.",
                        "If such responsibility is not accepted, or the need for the report or the organisational relationship with the individual concerned ceases, the report should be returned to Psych Press or destroyed. In a recruitment context, reports on unsuccessful candidates should be deleted.",
                        "Should a report be required at a later date, it can be obtained without further cost from Psych Press archives.",
                    ],
                },
                {
                    title: "Purpose",
                    description: [
                        "The report has been written specifically for a sales related role and this purpose has determined the manner in which information has been conveyed, the interpretation provided and the conclusions drawn. Therefore it should not be used for any other purpose as the information and results are likely to be inaccurate and misleading within a new context.",
                        "Therefore it is a condition of the provision of this report that it will not be provided to the individual concerned. The risk of its misinterpretation and the possible consequences for those involved require that the information be provided in an appropriate form and context.",
                    ],
                },
                {
                    title: "Feedback",
                    description: [
                        "Psych Press recognises the ethical responsibilities to both candidate and client and will always provide appropriate feedback to the individual concerned. Either telephone or face-to-face discussion can provide informed professional feedback on the assessment results within the context of personal and career development.",
                    ],
                },
                {
                    title: "Cross validation of Outcomes",
                    description: [
                        "This assessment report provides objective and independent information on a candidate's personality attributes in relationship to a sales related role, which may not be available from other sources. However such data should always be used to complement and not substitute for information obtained from other sources. Best practice and legal obligations require that decisions be made from a synthesis and assessment of information acquired from different sources and not on this report alone. Psych Press can assist and advise in the development of other data sources such as structured interviews, referee reports, bio-data and the validation of this data against competencies relevant to job performance or role development.",
                    ],
                },
                {
                    title: "Ongoing Support",
                    description: [
                        "Psych Press offers continuing support in the use of psychometric information. Clients are invited to discuss the issues flowing from this report at either an individual or organisation level.",
                    ],
                },
            ],
        },
        rationale: {
            title: "Rationale",
            description: [
                "Current theory supports the observation that success metrics in a business can be approached through three sources of competitive advantage: financial, technological or human resources. The intellectual capital of human resources is the primary and most valuable asset for an organisation (Cook, 1988). As sales are the lifeblood of any revenue-seeking organisation (Subhani et al., 2011) it is imperative to understand the psychological factors impacting upon sales success, and to predict sales performance, reduce selection error and increase profit and revenue. Reviews of the psychological literature have identified personality as one of five effective predictors of sales performance (personality, bio-data, demographics, sales knowledge and sales attitude) (Charan, 2008; Barrick & Mount, 1991; Shippman, Switzer and Roth, 1988).",
                "Thus for any organisation, the selection of the candidate that is the 'best fit' is of critical importance. Studies have demonstrated that valid selection practices are also crucial in contributing to considerable financial savings for an organisation (Hunter & Hunter, 1984; Compton & Nankervis, 1991). Such selection practices can impact directly on output quality and quantity at the individual, group and organisational level.",
                "Sales are related to wealth and opportunity creation at both a commercial/organisational level as well as a personal/career development level. At the organisational level, understanding sales drivers contributes toward the commercial success of an organisation \u2013 in both revenue and profitability. At an individual level such insights and predictive ability will assist an organisation to appropriately develop the careers of their salespeople with initiatives firmly based on applied behavioural research.",
                "The Saleable\u2122 profile provides personality data to understand candidate's potential for success in a sales context. Both strengths and development needs are identified and related to effectiveness in a sales role.",
                "The Saleable\u2122 profile is based upon research examining the link between personality traits and sales success. Relevant research has been examined to indicate which personality traits are most often associated with sales success. Results for these traits are clearly defined and explained in the context of a universally recognised sales cycle (Dubinsky, 1980), in order to be easily interpretable - in commercial terms. The sales cycle consists of seven stages from prospecting to making the sale to gaining referrals from valued customers.",
                "The relationship between the identified traits and sales success has been explored with individual companies in different industries, and in general terms across all industries for respondents involved in sales in order to ensure its practical value.",
            ],
            subHeadings: null,
        },
        generalInformation: {
            title: "General Information for Interpreting this Report",
            description: [
                "This report provides objective self-report information on the candidate's suitability for sales roles. It is based on research examining the link between personality and sales success. The information provided gives an understanding of the candidates preferred approach to sales within a universally recognised sales cycle and enables an objective comparison with other candidates and a normative group. The results can promote useful discussion and consideration of suitability when compared and contrasted with other selection data.",
                "Individuals do not 'pass or fail' on personality dimensions - preferences are simply compared with a relevant population group to assist selection, performance management or career development decisions.",
                "The results presented in this report are expressed in terms of a percentile (%) score for each test or questionnaire administered. A percentile is a score below which a certain percentage of the members of a selected sample group fall. For example, when using an Australian adult sample as a comparison group, the 78th percentile is that score below which 78 percent of the total group falls.",
            ],
            subHeadings: [
                {
                    title: "Specific Population Comparisons",
                    description: [
                        "A general adult sample is only one reference group against which an individual's scores can be compared. Since client companies often need or may wish to compare individual performance to their own company data or to an industry or specific sales sample, Psych Press can build or provide such normative data for comparison purposes wherever possible.",
                    ],
                },
                {
                    title: "Interpretation",
                    description: [
                        "Percentile scores can be misleading if small differences between individuals' scores are interpreted as implying significant differences in suitability or work performance.",
                    ],
                },
                {
                    title: "Score Ranges",
                    description: [
                        "To avoid misinterpretation, score ranges are used to group scores and relate them to a 'normal curve of distribution'. Put simply, this means that on any assessment where an individual is compared with a large comparison group, a greater number of people will fall in the 'middle' or average range than at either end of a particular scale. This is reflected in the percentile ranges as shown below.",
                        "The contents of this confidential report are designed to be read by managers who are either experienced or trained in the use of psychologically based decision support tools.",
                        "The report is based upon objectively derived information and is expressly intended to be used in conjunction with other elements of recruitment or development information such as information derived from resumes or CV's indicating previous work history and achievements, interview data, reference checking, and perhaps assessment centre and/or bio-data information. Such sources of data, combined with professional judgement, allow the statements contained in this report to be viewed as professional opinion which can be compared and validated against other relevant sources of information. Interpretive statements should not be considered in isolation to this complementary information and the purpose for which the report was written.",
                        "Here it can be seen that the spread of scores within each range moves from a narrow band at each end of the scale through to a broader central 'average' band as scores approach the Average category. When used to interpret certain occupational interests and personality scales scores do not refer to a specific aptitude or ability, but rather are an indicator of preference, liking or opinion in comparison to a group of people.",
                    ],
                },
                {
                    title: "Psych Press uses a basic score range for ability percentile scores:",
                    description: [
                        "91st - 99th percentile : Superior performance",
                        "63rd - 90th percentile : Above Average performance",
                        "37th - 62nd percentile : Average performance",
                        "10th - 36th percentile : Below Average performance",
                        "1st - 9th percentile : Poor performance",
                    ],
                },
            ],
        },
        personalityProfileIndex: {
            title: "Personality Profile Index",
            description: [
                "Personality assessments measure the candidate's relatively stable preferences for certain behaviours and give insight into how their dispositions will fit into your sales team and how much of a benefit they will be to your organization's goal of increasing revenue and growth. Personality scores should be interpreted as the individual's preferred style of action in the sales environment.",
                "The following graphs represent the individual's scores on a continuum from low to high. The characteristic corresponding with a low score is listed to the left of the graph, whilst the characteristic corresponding with a high score is listed to the right of the graph. If an individual scores high, the bar will be approaching the characteristic on the right, indicating the individual's preference for that type of strategy or behaviour. If the individual scores low, their scores reflect an individual preference for the characteristic on the left, and the bar will end closer to the left side of the continuum.",
            ],
            subHeadings: null,
        },
        responseStyleIndex: {
            title: "Response Style Index",
            description: [
                "Unusual results on any of these scales may suggest that candidates are responding randomly, attempting to present themselves in an artificially favourable light, entering artificially moderate responses or otherwise failing to complete the assessment in a complete and accurate manner. Conscious and unconscious distortions in self-report data may be grouped into several categories, each of which is addressed by the scales interpreted below",
            ],
            subHeadings: null,
        },
        furtherInformation: {
            title: "Further Information",
            description: [
                "For additional information regarding the practicality, theoretical background, administration, and scoring of the Saleable\u2122 assessment, please refer to the Saleable\u2122 manual. This resource should also assist you in further interpreting the candidate's results detailed in this report. Additionally, do not hesitate to contact a Psych Press representative via email info@psychpress.com.au; phone +61 3 9670 0590 or fax +61 3 9642 3577.",
            ],
            subHeadings: null,
        },
    },
    saleableZollRecruitmentProfile: {
        reportName: "Saleable Zoll Recruitment Profile",
        reportDescription: "",
        reportFields: [
            {
                name: "Dedication",
                version: "",
                description:
                    "This scale identifies the extent to which an individual will feel a sense of duty and moral obligation and how committed to an organisation they will be. This scale also examines the extent to which an individual is dependable and reliable.",
                icon: "",
                category: "Managing Tasks",
                rawScore: 18,
                percentile: 4,
                bandDescription:
                    "A low score on this scale indicates that an individual feels less bound by social or work obligations, and may be seen by others as independent, unreliable or irresponsible. They often find contracts, rules and regulation to be overly confining and may avoid these things where possible. These individuals may however be more adaptable in their ability to change their approach quickly as the situation requires.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May find it challenging to complete tasks in time, adhere to rules, and produce good quality work.",
                        "May be less inclined to actively participate and collaborate with their team members.",
                        "May do the minimum required of them and not take on additional responsibilities or show initiative, which can hinder innovation and growth.",
                        "May be likely to leave the company sooner, leading to higher turnover rates.",
                        "Often require more supervision, management intervention, and resources.",
                        "May exhibit a more flexible and adaptable mindset, as they are less tied to specific ways of thinking or rigid beliefs.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Encourage the employee to find meaning and purpose in their work. Help them understand how their contributions align with the organisation's goals and values. By fostering work engagement, dedication can naturally follow.",
                        "Collaborate with the employee to set specific, measurable, attainable, relevant, and time-bound (SMART) goals. Break these goals down into smaller milestones to track progress and provide a sense of accomplishment. Regularly review and provide feedback on their progress.",
                        "Provide learning and development opportunities that match the employee's interests and developmental needs.",
                        "Prompt the employee to reflect on their strengths, areas for development, and career aspirations. Self-reflection can help them gain clarity, set personal goals, and cultivate a sense of purpose that fuels dedication.",
                        "If the employee's low dedication stems from personal or work-related issues, provide a supportive environment to discuss and address those concerns. Consider offering resources like counselling services or employee assistance programs.",
                        "Empower the employee by giving them autonomy and decision-making authority over their work. Encourage them to take ownership of their tasks and projects, which can increase their sense of responsibility and dedication.",
                        "Encourage teamwork and collaboration among employees. Assign projects or tasks that require cooperation, allowing the employee to experience the benefits of working together towards a common goal. This can enhance dedication through shared accountability and camaraderie.",
                        "Offer regular feedback on the employee's performance, highlighting both areas of improvement and accomplishments. Recognise and celebrate their achievements to reinforce positive behaviour and boost morale.",
                    ],
                },
            },
            {
                name: "Diligence",
                version: "",
                description:
                    "This scale assesses a tendency to work hard and examines the inclination to set goals. This scale also assesses the individual's level of motivation to achieve these goals.",
                icon: "",
                category: "Managing Tasks",
                rawScore: 17,
                percentile: 1,
                bandDescription:
                    "A low score on this scale indicates an individual with the tendency to have lower levels of self-discipline and to place less emphasis on setting goals. Low scorers are less likely to be motivated by achieving goals and may struggle to continue when goals become difficult.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Often have a flexible approach to their work. They are less tied to a strict routine and might adapt to changing circumstances more readily.",
                        "May thrive in dynamic environments where things often change at the last minute.",
                        "May often come up with out-of-the-box ideas and can therefore be highly valuable in roles or industries that value innovation and creativity.",
                        "May be efficient multitaskers and might be good at quickly shifting from one task to another, picking up new skills and knowledge on the go; valuable in fast-paced environments where individuals are to juggle multiple tasks in their roles.",
                        "May be quick to give up tasks when faced with difficulties.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Resilience training can assist individuals with low diligence to persevere despite facing challenges during tasks.",
                        "Set clear expectations and break down larger tasks into manageable parts and SMART goals that can make it easier for them to stay focused.",
                        "While individuals low on diligence may resist structure, a well-balanced routine that includes ample breaks for rejuvenation can help improve their productivity and stay focused.",
                        "A hands-on coaching style to guide them through tasks may be particularly helpful for individuals who learn best by doing.",
                        "Individuals with low diligence may often frustrate other team members based on how they present themselves: encourage individuals to be vocal on their progress which may often be overlooked.",
                    ],
                },
            },
            {
                name: "Tolerance for ambiguity",
                version: "",
                description:
                    "This scale measures an individual's tendency to make sense of ambiguous information and an individual's preference towards having an analytical approach to thinking. This scale also assesses an individual's preference for working in an ambiguous environment.",
                icon: "",
                category: "Managing Tasks",
                rawScore: 28,
                percentile: 15,
                bandDescription:
                    "A low score on this scale indicates that the individual is likely to find an environment with a lot of uncertainty very difficult to work in. Low scoring individuals may struggle working with information that is inconsistent and are likely to opt for solutions they perceive as easy without assessing all the possibilities.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Are particularly well suited to roles where precision and completeness are important.",
                        "Don't hesitate to seek clarification and further information when situations seem less than completely defined.",
                        "Rarely accept evasive or vague responses, making them good at chasing down definite answers and missing information.",
                        "Often require extra time to adjust to loosely defined and uncertain situations.",
                        "Tend to prefer using methods they are familiar with.",
                        "Strongly prefer structured tasks in which all required information is readily available.",
                        "May fail to make progress on a task when they feel there is insufficient information available for them to continue.",
                        "Are able to thoroughly analyse information and situations before making decisions.",
                        "May be hesitant to take risks due to their preference for predictable outcomes and established procedures.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Place them in an ambiguous situation and coach them on how to cope with it; experience will develop confidence to deal with uncertainty alone. Ensure that this is a gradual exposure to not overwhelm this person.",
                        "Help individuals recognise that there are times when decisions need to be made where the outcome is not clear.",
                        "Be objective, clear, and logical when presenting them with new information.",
                        "Make sure proposals are set out in a complete fashion and show an organised plan with the important details in it.",
                        "Encourage this person to work in incremental steps towards a larger goal, which will help the outcome become clearer as they progress.",
                        "Under uncertainty it helps to define the problem, figure out what causes it. Keep asking why. This increases the chance of a better solution and better decisions. Focusing on the solutions or information first often slows things down, learn to ask more questions.",
                        "Visualise the problems and create a story board or a list of pros/cons to conceptualise the issue to be addressed.",
                        "Encourage this person to collaborate with others about their ideas and perspective.",
                    ],
                },
            },
            {
                name: "Autonomy",
                version: "",
                description:
                    "This scale measures the extent to which an individual is likely to be comfortable acting with independence. This scale also examines the likelihood a person is able to work without supervision.",
                icon: "",
                category: "Managing Self",
                rawScore: 20,
                percentile: 4,
                bandDescription:
                    "A low score on this scale indicates an individual who finds that input from others is welcomed and aids them in the decision making process. Low scores may also struggle to work autonomously and therefore may require supervision when undertaking tasks.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May require constant supervision.",
                        "Tend to value collaboration and teamwork where they can seek input and guidance from others.",
                        "Prefer clear, detailed, and ongoing instructions to perform tasks effectively.",
                        "Are able to work well in structured environments with clear guidelines, procedures, and routines.",
                        "May often seek approval or instruction from supervisors before taking any action even on smaller tasks.",
                        "May have limited initiative and self-direction and require reminders to stay on track.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Gradually increase the individual's level of responsibility to provide them with opportunities to make decisions and take ownership.",
                        "Provide clear guidelines and expectations before beginning a task to provide a structured framework that they can operate in.",
                        "Encourage their initiative and independent thinking while providing support and suggestions for growth. Specifically, encourage them to analyse situations, consider different perspectives, and evaluate potential solutions by asking open-ended questions.",
                        "Find opportunities to build self-confidence to help individuals identify and relate to their strengths, allowing them to feel competent enough to appropriately perform tasks alone.",
                        "Encourage collaboration and teamwork to enable the individual to gain confidence and autonomy.",
                        "Foster a growth mindset whereby the individual perceives autonomy as a developmental journey rather than a trait that simply exists, allowing them to embrace change, improvement, and learning.",
                    ],
                },
            },
            {
                name: "Self-discipline",
                version: "",
                description:
                    "This scale identifies the extent to which an individual is self-controlled. This scale also examines the level of which an individual is able to begin and follow through on plans, projects and goals.",
                icon: "",
                category: "Managing Self",
                rawScore: 19,
                percentile: 5,
                bandDescription:
                    "A low score on this scale indicates an individual that is likely to be easily distracted from a task. While this should help with their ability to multi-task, it may cause them to lose concentration and delay in completing repetitive or detailed work. This is well suited to roles with numerous small tasks which compete for attention, but may be less appropriate for roles involving long or complex projects which require a disciplined approach.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May struggle to initiate and follow through on goals, projects, and plans.",
                        "May feel overwhelmed by the magnitude of a task or have difficulty prioritising, which can hinder progress.",
                        "Are likely to avoid, procrastinate on, and be easily distracted from tasks.",
                        "May find it challenging to stay committed to goals without accountability and support.",
                        "May struggle to maintain resilience and build momentum for continuous progress on long or complex projects.",
                        "Are prone to losing motivation by perceiving small setbacks as major failures.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Help them define clear and meaningful goals that align with their values and aspirations. This can involve reflecting on the reasons behind their goals and visualising the positive outcomes they can achieve.",
                        "Assist them to break down their goals into smaller, manageable steps and encourage them to practice time management techniques such as setting deadlines, creating schedules, and using productivity tools like the Pomodoro technique (working in focused intervals with short breaks).",
                        "Encourage them to prioritise tasks based on importance and urgency, and to focus on one task at a time rather than to try to tackle everything at once.",
                        "Encourage them to establish daily routines and habits that support their goals.",
                        "Promote mindfulness and self-awareness practices to recognise and manage impulses or distractions that may derail their progress.",
                        "Encourage them to seek accountability partners such as friends, family, or mentors who can provide encouragement and hold them accountable for their progress.",
                        "Acknowledge progress and celebrate small victories and milestones along the way and encourage them to focus on the progress made rather than dwelling on setbacks or perceived failures.",
                        "Teach them to view challenges as learning opportunities and to practice self-compassion when things do not go as planned so that they can learn from mistakes, make necessary adjustments and keep moving forward.",
                        "Help them recognise perfectionistic tendencies and emphasise the importance of taking imperfect action and iterating along the way to maintain a focus on progress.",
                    ],
                },
            },
            {
                name: "Strategic Orientation",
                version: "",
                description:
                    "This scale examines the preference toward having either a short-term or long-term perspective when making plans and decisions. This scale also measures the amount of time an individual is likely to spend considering the outcomes of their decisions.",
                icon: "",
                category: "Managing Self",
                rawScore: 21,
                percentile: 70,
                bandDescription:
                    "A high score on this scale indicates an individual who tends to have a long-term perspective, takes time to formulate possibilities, and deliberates over options when undertaking decisions. They also tend to be thoughtful in this regard and are likely to consider the potential consequences of their decisions.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "High scorers such as john Doe:",
                    points: [
                        "Encourage them to stay updated on industry trends, emerging technologies, and best practices in strategic thinking.",
                        "Help them to maintain a growth mindset and embrace new challenges. Learn to see setbacks as learning opportunities and encourage them to seek feedback from colleagues, mentors, or experts.",
                        "Encourage them to collaborate across different departments or teams within the organisation. This will help them gain diverse perspectives, challenge their assumptions, and develop well-rounded strategies.",
                        "Help to tailor their strategic vision to different audiences and leverage storytelling techniques to make their strategies more compelling.",
                        "Help them to set goals that push their boundaries and require innovative approaches to problem-solving.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Tend to have a clear and compelling long-term vision for themselves and their organisations.",
                        "They take time to think and reflect on problems to develop a wider understanding and strategy.",
                        "Has the ability to proactively anticipate future trends, disruptions, and opportunities.",
                        "Are more open to innovation and willing to explore new ideas, technologies, and approaches.",
                        "Will have a holistic view understanding of their own organisations' operations, market position, and competitive landscape.",
                        "Will be motivated by continuous learning and expanding their knowledge base.",
                    ],
                },
            },
            {
                name: "Ecological Resilience",
                version: "",
                description:
                    "This scale examines the degree to which an individual is composed, determined and resourceful. This scale also captures the capacity for an individual to work towards goals in a persistent and committed manner regardless of obstacles.",
                icon: "",
                category: "Managing Self",
                rawScore: 10,
                percentile: 1,
                bandDescription:
                    "A low score on this scale reflects an individual that is less likely to be determined in their work. Low scorers also tend to be less goal oriented, and lose motivation quicker when complications arise or conflicting interests are present.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May struggle with determination in their work. They might find it difficult to set clear goals and stay focused on them.",
                        "May have trouble coming up with creative solutions to problems. They may rely heavily on conventional methods and struggle when faced with novel challenges.",
                        "Are more likely to lose motivation when they encounter obstacles or conflicts of interest. They may give up easily and not persevere through difficulties.",
                        "May have a hard time maintaining composure in stressful situations. They may become overwhelmed and less effective when faced with adversity.",
                        "May find setting and sticking to goals to be a challenge. They may lack a sense of purpose and direction in their work.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Help individuals set clear, achievable goals and develop strategies to maintain motivation. Break down larger goals into smaller, manageable steps to reduce feelings of being overwhelmed. Teach them techniques like positive self-talk and visualization to boost motivation.",
                        "Coach them in stress management techniques such as mindfulness, deep breathing, and time management. Encourage the development of coping skills to handle adversity more effectively, such as problem-solving and seeking social support.",
                        "Work on enhancing their resourcefulness by encouraging creative problem-solving exercises. Help them explore different ways to approach challenges and encourage a growth mindset to embrace learning from failures.",
                    ],
                },
            },
            {
                name: "Engineering Resilience",
                version: "",
                description:
                    "This scale measures the extent to which an individual has the capacity to recover and 'bounce back' following difficult events or circumstances, as well as the speed of this recovery process. This scale further assesses whether an individual's emotional coping styles are effective in the face of difficulty.",
                icon: "",
                category: "Managing Self",
                rawScore: 11,
                percentile: 1,
                bandDescription:
                    "A low score on this scale indicates that the individual is more likely to find it difficult to emotionally recover following hardships and setbacks. Low scorers also tend to lack effective coping styles, such as relaxation and seeking support, when dealing with stressful situations.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May struggle to recover effectively after facing hardships. They might find it challenging to adapt to changes or setbacks in their personal or professional life.",
                        "May experience higher levels of stress and anxiety, especially during difficult situations. They tend to overthink problems and may engage in rumination, which can exacerbate their stress levels.",
                        "Might resort to negative coping mechanisms such as agitation, avoidance, or denial when faced with challenges.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Encourage individuals with low resilience scores to proactively build a toolkit of resilience strategies. This could include practices like mindfulness, relaxation techniques, and physical exercise. Encourage them to commit to regular use of these tools to help them bounce back from difficulties more effectively.",
                        "Work on shifting their mindset from negative to positive. Teach them to challenge and reframe negative thoughts. Encourage them to focus on their strengths and past successes as evidence of their ability to overcome challenges.",
                        "Provide stress management techniques such as deep breathing exercises, time management strategies, and relaxation practices. Help them identify sources of stress and develop a plan to reduce or manage these stressors more effectively.",
                    ],
                },
            },
            {
                name: "Adaptive Resilience",
                version: "",
                description:
                    "This scale identifies an individual's capacity to adapt effectively to changes in routine or circumstance. This scale therefore examines whether an individual enjoys and is open to new experiences.",
                icon: "",
                category: "Managing Self",
                rawScore: 14,
                percentile: 14,
                bandDescription:
                    "A low score on this scale indicates that the individual tends not to adjust effectively and promptly to changes in environment or circumstance. Low scorers are also less likely to be open to novel and different ideas and experiences.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May struggle to adapt effectively and promptly to changes in their routine or environment. They may resist change and find it unsettling or anxiety-inducing.",
                        "Are less likely to be open to new ideas and experiences. They may prefer sticking to familiar routines and methods, which can hinder their ability to learn and grow.",
                        "May exhibit a rigid mindset, making it challenging to embrace different perspectives or alternative solutions. They may resist feedback and be less open to feedback-driven improvement.",
                        "Can have increased stress when confronted with unfamiliar or unpredictable situations, as they lack the flexibility to handle these challenges effectively.",
                        "May have difficulty problem-solving in novel situations, as they tend to rely on previously established routines and methods even when they may not be applicable.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Focus on gradually exposing them to manageable changes and helping them build confidence in adapting. Encourage small adjustments to routines and provide support in navigating these transitions.",
                        "Work on shifting their mindset from viewing change as a threat to seeing it as an opportunity for growth. Explore the benefits of learning from new experiences and discuss the potential positive outcomes of adapting to change.",
                        "Help them develop stress management techniques to cope with the discomfort and anxiety that often accompany change. Teach relaxation exercises, mindfulness, and ways to identify and challenge negative thought patterns.",
                    ],
                },
            },
            {
                name: "Compassion",
                version: "",
                description:
                    "This scale examines the tendency for an individual to have concern for other people's problems and issues. This also examines the degree to which an individual can balance these concerns with objectivity and separate themselves from what others are experiencing.",
                icon: "",
                category: "Managing Others",
                rawScore: 19,
                percentile: 65,
                bandDescription:
                    "An average score on this scale indicates an individual who is capable of empathising with others and being concerned for their problems and issues. Average scorers tend to be respectful and considerate of the emotions of others, however may also prefer to remain objective in certain situations.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Average scorers such as john Doe:",
                    points: [
                        "Are capable of empathising with others and being concerned for their problems and issues.",
                        "Tend to be respectful and considerate of the emotions of others.",
                        "May prefer to remain objective in certain situations.",
                        "Have the potential to develop meaningful emotional connections with others.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Guide them to understand when compassion is most needed and beneficial. Sometimes, objectivity is necessary, but there are also times when showing compassion can significantly improve a situation.",
                        "Help them further develop their emotional intelligence. This can help them better understand, use, and manage their own emotions in positive ways to relieve stress, communicate effectively, empathise with others.",
                        "Promote active listening to better understand the needs and emotions of others.",
                        "Recognise the importance of self-compassion. Being kind to themselves can help them extend this kindness to others more easily.",
                    ],
                },
            },
            {
                name: "Helpfulness",
                version: "",
                description:
                    "This scale examines an individual's willingness and inclination to assist other people. This scale also identifies the degree to which an individual can balance their consideration for clients and colleagues with objective and practical concerns.",
                icon: "",
                category: "Managing Others",
                rawScore: 25,
                percentile: 85,
                bandDescription:
                    "A high score on this scale indicates an individual that is likely to have a strong willingness to assist colleagues in need, and that they are likely to find helping others to be personally rewarding. This will benefit others in the workplace who can count on their assistance when required. However, such willingness to pursue every opportunity to help others could impact upon their ability to keep up with their own work, and may lead others to take advantage of them.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "High scorers such as john Doe:",
                    points: [
                        "Have a strong willingness to assist colleagues in need.",
                        "Will find helping others to be personally rewarding.",
                        "Are willing to actively help others at every opportunity which may have repercussions on their ability to manage their own workload.",
                        "May be taken advantage of due to their helpful nature.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Teach the individual to manage their time more efficiently, so they can help others while also keeping on top of their own workload.",
                        "Coach them on how to prioritise tasks, both their own and those of others. This can help them balance their helpfulness with their own responsibilities.",
                        "If they can do so, teach them how to delegate tasks effectively. This can help spread the workload more evenly and ensure they are not overburdened.",
                        "Even the most helpful individuals need to set boundaries to ensure they are not taken advantage of. Encourage them to learn when it is appropriate to say 'no' or delegate tasks.",
                        "Encourage them to regularly reflect on their practice of helping others. Are they feeling drained or taken advantage of? Are they still finding it rewarding? This will help them stay aware of their own needs and adjust their behaviour accordingly.",
                    ],
                },
            },
            {
                name: "Approachability",
                version: "",
                description:
                    "This scale examines the extent an individual is friendly and easy to talk to, compared to being impersonal and objective. This scale also examines an individual's tendency toward reaching out to others on a personal level.",
                icon: "",
                category: "Managing Others",
                rawScore: 20,
                percentile: 31,
                bandDescription:
                    "A low score on this scale indicates an individual with the tendency to not reach out to others on a personal level, and as such may be seen as distant and reserved. These individuals may take a long time to 'warm' to people, and are less likely to openly demonstrate positive feelings towards others.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Tend to not reach out to others on a personal level.",
                        "Can be distant and reserved.",
                        "Tend to take a longer time to 'warm' to people.",
                        "Are less likely to openly demonstrate positive feelings towards others.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Develop their interpersonal skills through training and role-play exercises. These can include active listening, expressing empathy, and learning how to engage in small talk.",
                        "Encourage them to participate in networking and team-building events. The more comfortable they become in social settings, the more approachable they will become.",
                        "Help them understand the benefits of being approachable, such as building stronger relationships, improving team cohesion, and enhancing workplace communication.",
                        "Train them in active listening, which is not only about hearing the words, but understanding the complete message being sent. Active listeners are often perceived as more approachable.",
                        "Cultivate an environment that makes them feel safe and valued. This can encourage them to open up more and increase their approachability.",
                    ],
                },
            },
            {
                name: "Greed Avoidance",
                version: "",
                description:
                    "This scale measures the degree of importance an individual places on material objects. This scale also assesses how concerned an individual is with obtaining wealth and being successful.",
                icon: "",
                category: "Managing Risk",
                rawScore: 19,
                percentile: 26,
                bandDescription:
                    "A low score on this scale reflects an individual who tends to place high value in material possessions, status, and wealth. It is likely that low scorers are concerned with obtaining, enjoying and displaying their wealth and success.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Are likely to be highly motivated to achieve financial success and to seek out roles, projects, and responsibilities that offer substantial financial rewards. This could lead them to be high performers in roles that are directly tied to financial results.",
                        "Tend to set high financial goals for themselves and can be focused and persistent in working towards these goals. This could lead to strong results in roles that require a strong focus on outcomes.",
                        "May be comfortable with and motivated by competitive situations. This can be a strength in roles that require a competitive mindset.",
                        "Can sometimes be tempted to engage in unethical behaviour, such as cutting corners or bending rules, to achieve their goals.",
                        "Could potentially strain relationships with colleagues, especially if they are perceived as being overly competitive or as valuing money over teamwork or other considerations.",
                        "Might become disengaged or look for opportunities elsewhere, if they don't see the potential for financial reward in their current role or organisation.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Discuss with them how their personal financial goals can align with the organisation's goals to create a mutually beneficial relationship.",
                        "Reinforce the importance of ethical behaviour and adherence to the company's code of conduct. Trevino & Nelson's model of ethical decision-making in business can be a useful tool to discuss during coaching sessions.",
                        "Foster a sense of team identity and mutual support, encouraging them to see the value in collaboration and not only competition.",
                        "Encourage them to find intrinsic value and satisfaction in their work beyond just financial reward.",
                        "Help them balance their drive for financial success with other important aspects of life, like health and relationships.",
                        "The Corporate Social Responsibility (CSR) Pyramid suggests that businesses have responsibilities to society beyond just making a profit. Discuss how they can contribute to the organisation's CSR initiatives and find fulfillment in making a positive impact on society.",
                    ],
                },
            },
            {
                name: "Morality",
                version: "",
                description:
                    "This scale measures the degree an individual prefers to be straightforward and truthful when dealing with others. This scale also assesses the level an individual is guarded in regards to telling the truth, and their belief around the necessity of deception in social relationships.",
                icon: "",
                category: "Managing Risk",
                rawScore: 13,
                percentile: 1,
                bandDescription:
                    "A low score on this scale indicates that an individual may not place importance on telling the truth or being straightforward. These individuals may have a tendency to speak with hidden meanings, and rarely make their intentions plainly obvious.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Are likely to be straightforward in their communication with others across situations.",
                        "Are likely to believe that a certain level of deception is necessary in their social relationships.",
                        "Are less likely to make their intentions clear than their peers.",
                        "Tend to have hidden meanings behind what they say.",
                        "May not value speaking the truth and being candid in their expression.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Teach them effective communication skills, particularly emphasising the importance of clarity and honesty. Miscommunication and misunderstandings can often stem from a lack of straightforwardness.",
                        "Help them to understand the role of trust in building relationships and value of fostering rapport with others.",
                        "Provide regular feedback on their communication and interpersonal skills, which can help them to attain a broader understanding of how indirect communication affects others and the overall team dynamics.",
                        "Inform them of the importance of discretion and tactfulness in certain situations and help them to recognise these contexts.",
                    ],
                },
            },
            {
                name: "Humility",
                version: "",
                description:
                    "This scale refers to the way in which an individual deals with recognition for their achievements. This scale therefore examines the extent an individual may appear arrogant and proud, instead of modest and humble.",
                icon: "",
                category: "Managing Risk",
                rawScore: 14,
                percentile: 6,
                bandDescription:
                    "A low score on this scale reflects an individual that tends to be comfortable mentioning their achievements and strengths. Low scorers may even see themselves as superior to others, with a belief that they are better and more capable due to their successes.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "May present as individuals who often mention their achievements and strengths, often as explicit bragging or the practice of humblebrag, thus presenting as unlikeable colleagues.",
                        "May see themselves as superior to others even if this is not objectively the case and may hold beliefs that they are more capable than their colleagues due to their successes.",
                        "May lack awareness and have difficulty in accepting feedback, valuing themselves based on their achievements.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Coaching self-awareness would be substantial in improving levels of humility in individuals who score low on this scale.",
                        "As achievements may be overly valued, self-awareness along with guidance towards clarity and honesty may allow individuals to present as more likeable and easier to worth with.",
                        "Individuals may underestimate their limitations while overestimating their abilities, which may hinder future progress. Fostering an environment that focuses not mainly on achievements, but consistent progress may gradually expose low scorers to the importance of proper recognition.",
                    ],
                },
            },
            {
                name: "Risk Taking",
                version: "",
                description:
                    "This scale measures the extent to which an individual is willing to take risks in order to achieve desired goals. It also measures a preference for taking risks, without being deterred by the possibility of making mistakes or facing negative outcomes.",
                icon: "",
                category: "Managing Risk",
                rawScore: 28,
                percentile: 10,
                bandDescription:
                    "A low score on this scale indicates an individual who is more likely to avoid taking risks regardless of the possible gains that may be obtained. This individual likely prefers predictable situations where there is little chance to be exposed to novelty and are therefore suited to roles that require a systematic approach guided by rules and regulations.",
                interactiveEffects: null,
                considerationsWithinTheWorkplace: {
                    heading: "Low scorers such as john Doe:",
                    points: [
                        "Are unlikely to be the source of risk-related problems requiring damage control.",
                        "Are well-suited to roles in which risk taking would have dire consequences, or in which 'playing it safe' can still result in good business outcomes.",
                        "May present as stable individuals who are sure-footed in their work practices, which may be reassuring to colleagues and clients.",
                        "May forego favourable yet risky business opportunities or prospects in favour of safer options, which can counteract competitive growth.",
                        "May be viewed by co-workers as stubborn or insecure.",
                        "Are diligent in following established procedures and guidelines, resulting in them being reluctant to change. This can contribute to the inhibition of change, resulting in organisational processes becoming stagnant and unproductive.",
                        "May excel in identifying and mitigating potential risks.",
                        "May avoid taking on new challenges or stepping outside of their comfort zone, which can limit their professional growth and development.",
                    ],
                },
                coachingTips: {
                    heading: "",
                    points: [
                        "Highlight to this person how the key to organisational success over the long run is to take some calculated risks and use failures as opportunities to learn.",
                        "When dealing with areas of discomfort, pair this person with people who seem particularly skilled at making decisions that involve these kinds of risks. Encourage a discussion of the risk taker's decision-making process, so this individual can apply what they have learned. ",
                        "Ask this person to deliberately look at the issue from different viewpoints. This will provide a complete understanding of the issue and improve confidence to make educated risks.",
                        "Emphasise that the workplace is a safe environment where mistakes can be made and highlight that performance can be acceptable rather than perfect. Encourage a growth mindset where failures are reframed as learning opportunities.",
                        "Encourage this person to start with small, low-risk projects to boost their confidence and then gradually increase high-risk tasks and work methods. Ensure that you are recognising and appreciating their willingness to step outside of their comfort zone.",
                        "Avoid bringing this person into prominence in the group and allow them to contribute without being in the spotlight.",
                        "When this person is concerned about the risk of a task, show them how your suggested actions conform to established procedures and how it is relatively low risk.",
                        "Be direct when giving instructions whereby you should tell this person that you want the task to be completed your way/the established way.",
                        "When they are concerned about the downside of an action, encourage them to write down their worries and then consider the positives. Encourage them to act, get feedback on the results, refine, and then act again.",
                    ],
                },
            },
        ],
        introduction: {
            title: "Introduction",
            description: [
                "The following profile has been based on psychological and cognitive screening assessments. It provides elements of insight and understanding into Nakhorn's relevant strengths and development needs in the context of Psych Press's leadership competencies. In this profile you will also find a list of the applicant's key strengths and potential development areas based on the applicant's screening results.",
                "This report is based on one of the most researched and respected models of personality, identifying behaviour preferences across Cattell's 16 personality constructs (Cattell, 1946) and the big five personality traits (McCrae and Costa, 1987). These provide insight into how people typically think, feel and interact in ways that may be productive or counter-productive for an organisation:",
                "Extensive research, conducted over many years, has consistently demonstrated that 10-30% of the variance in job performance is attributable to personality differences. Moreover, a person's potential for burnout, their trainability and subsequent job satisfaction, have all been shown to be strongly influenced by personality. Thus personality assessment forms a central part of most careers guidance and counselling programmes, with the aim of helping individuals maximise their potential by finding an optimal match between their personality and their choice of career. The model below illustrates the relationship between behavioural preferences and job performance:",
            ],
            subHeadings: null,
        },
        backGroundInformation: {
            title: "Background Information for understanding and interpreting this report",
            description: null,
            subHeadings: [
                {
                    title: "Independently Compared",
                    description: [
                        "Tests, inventories and questionnaires are used to objectively measure and compare an individual's scores to a comparison group that is usually an Australian general or managerial population sample.",
                    ],
                },
                {
                    title: "Informed Decision Making",
                    description: [
                        "Individuals cannot 'pass or fail' on any ability or personality dimension \u2013 their performance is simply based on comparisons with a relevant population group to aid selection, performance management or career development decisions.",
                    ],
                },
                {
                    title: "Population comparisons",
                    description: [
                        "This report presents results in terms of a percentile (%) score for each test or questionnaire administered. A percentile is a score below which a certain percentage of the members of a sample group fall. For example, the 78th percentile of an Australian adult sample, is the score below which 78% of the total group falls.",
                    ],
                },
                {
                    title: "Specific population comparisons",
                    description: [
                        "An Australian adult sample is only one example of a reference group against which an individual's scores can be compared. Psych Press can create or provide normative data for comparison purposes to various other data sets such as client company data, or industry or management samples.",
                    ],
                },
                {
                    title: "Score Differences",
                    description: [
                        "When different norm groups are used, such scores will differ from original scores. For example, when using an Australian Adult sample, a score on the 70th percentile may reduce to the 30th percentile when compared against a tertiary qualified management sample, because members of this group will have higher abilities overall. This does not mean a person with a score at the 30th percentile has 'failed' a test; simply that in comparison to this specific sample they outperform only 30% of the members of that group on the abilities assessed.",
                    ],
                },
                {
                    title: "Confirming Results & Cross Validation of Outcomes",
                    description: [
                        "This report provides independent and objective information on a candidate's ability to work in a business setting, which is often not available from other sources. However, such data should be used to complement, not substitute information obtained from other sources. Best practice and legal obligations require that decisions be made from a combination and evaluation of information acquired from additional, differing sources such as interviews or other reports. Psych Press can assist and offer recommendations in the development of various other data sources such as structured interviews, referee reports, bio-data and the validation of this data against measures and competencies relevant to job performance or role development.",
                    ],
                },
                {
                    title: "Score Ranges Aid Interpretation",
                    description: [
                        "Minor differences between individuals' scores should not be understood as representing significant differences in work performance. Thus score ranges are provided as the use of percentile scores in this context can be misleading.",
                    ],
                },
                {
                    title: "Population Based Score Ranges",
                    description: [
                        "In order to avoid misinterpretation, score ranges are used to group scores and place them on a 'normal curve of distribution'. This normal curve of distribution shows that within a large comparison group, there will be a larger number of people in the 'average' range compared to the outstandingly good or very poor range. Clients can therefore relate abilities and aptitudes to different ranges within a population. This is reflected in the percentile ranges as shown below.",
                    ],
                },
                {
                    title: "Indicating Preferences",
                    description: [
                        "When percentile scores are used to interpret other constructs and contexts, they are subsequently interpreted differently. In the context of Personality Profiles and Occupational Interests, percentile scores do not imply or refer to a specific aptitude or ability, rather as an indicator of preference, liking or opinion compared to a specific population group.",
                    ],
                },
                {
                    title: "Score Ranges",
                    description: [
                        "General score ranges for intelligence and aptitude percentile scores are as follows: 94th-99th percentile \u2013 Exceptional/Outstanding 85th-93rd percentile \u2013 Superior 70th-84thpercentile \u2013 High Average/Above Average 31st-69th percentile \u2013 Average 16th-30thpercentile \u2013 Low Average/Below Average 07th-15th percentile \u2013 Poor 01st-06th percentile \u2013Very Poor As noted, the spread of scores within each range moves from a broader central average band in the 'Average' category to smaller and narrower bands at each end of the scale.",
                    ],
                },
                {
                    title: "Interpretation",
                    description: [
                        "Objectively-derived information regarding a range of individual attributes has been collected and collated in order to inform this report, and it is expressly intended to be utilized in combination with other facets of recruitment or development data, as necessitated by and in alignment with best practice procedures. Such elements may include, but are not limited to:data obtained from resumes or CV's detailing past work history and accomplishments,interview-derived information, reference-checking, bio-data, and information attained through assessment centers. These sources of information, in conjunction with professional judgement, permit the statements within this report to be considered as professional opinion that can be validated against and assessed in relation to other relevant sources of data. Statements of an interpretive nature should not be considered alone, but should be understood in relation to this additional information and the purposes for which the report was created.",
                    ],
                },
            ],
        },
    },
};

export const sampleACEScore = {};

export const sampleProvinceReport = {};

export const sampleMemberTypesACEScore = {};
