import { ArrowTrendingDownIcon, ArrowTrendingUpIcon } from "@heroicons/react/24/solid";

export default function TrendBubble(props) {
    const { percentage, text, small, flippedColours, testId } = props;

    return (
        <div
            data-testid={testId && testId}
            className={
                "flex flex-row items-center gap-2 rounded-xl w-max " +
                (small ? "py-1 px-1.5 " : "py-1.5 px-2 ") +
                (percentage === 0
                    ? "bg-slate-100 border border-slate-400 text-slate-500"
                    : flippedColours
                    ? percentage < 0
                        ? "bg-emerald-100 border border-emerald-400 text-emerald-600"
                        : "bg-red-100 border border-red-400 text-red-600"
                    : percentage > 0
                    ? "bg-emerald-100 border border-emerald-400 text-emerald-600"
                    : "bg-red-100 border border-red-400 text-red-600")
            }
        >
            <span className={"font-medium " + (small ? " text-xs" : "text-sm")}>
                {percentage === 0 ? <span className="font-normal">No change </span> : percentage > 0 && "+"}
                {percentage !== 0 && percentage + "% "}
                <span className="opacity-80 font-normal text-xs">{text}</span>
            </span>
            {percentage === 0 ? (
                <span className="h-0 -ml-2"></span>
            ) : percentage > 0 ? (
                <ArrowTrendingUpIcon className={small ? "h-4 w-4" : "h-5 w-5"} />
            ) : (
                <ArrowTrendingDownIcon className={small ? "h-4 w-4" : "h-5 w-5"} />
            )}
        </div>
    );
}
