import { useState, useContext } from "react";
import { ChatBubbleBottomCenterTextIcon, PlayIcon, StarIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";
import ReactPlayer from "react-player";
import Tippy from "@tippyjs/react";

import {
    BASIC_ANIMATION_VARIANTS,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../utils/constants";
import { Context } from "../../../../context/Context";

import Button from "../../../SharedComponents/Button";
import Modal from "../../../SharedComponents/Modal";
import StarRating from "../../../SharedComponents/StarRating";
import FeedbackForm from "./FeedbackForm";
import { scoreRUThereInterviewRecording } from "../../../../utils/api";
import { ALERT_TYPE } from "../../../SharedComponents/Alert";
import DeleteConfirmation from "../../../SharedComponents/DeleteConfirmation";

export default function RecordingRow(props) {
    const { item: recording, lastItem, updateItemStatus, setAlert, setAlertType } = props;

    const context = useContext(Context);

    const [showRecording, setShowRecording] = useState(false);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [showReviews, setShowReviews] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const length = recording?.reviews.filter((review) => review?.score !== null).length;

    const handleShow = (component) => {
        if (component === "recording") {
            setShowRecording((showRecording) => !showRecording);
        } else if (component === "feedbackForm") {
            setShowFeedbackForm((showFeedbackForm) => !showFeedbackForm);
        } else {
            setShowReviews((showReviews) => !showReviews);
        }
    };

    const handleDelete = async () => {
        setLoadingDelete(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.SAVE_INTERVIEW_RECORDING_SCORE)) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert("You do not have the necessary permission to delete a review");
            setLoadingDelete(false);
            return;
        }

        try {
            await scoreRUThereInterviewRecording(
                recording.recordingId,
                context.profileDetails.userIdentifier,
                null,
                null
            );

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully deleted your review from interview recording");
        } catch (e) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(e.message);
        }
        updateItemStatus();

        setLoadingDelete(false);
    };

    const averageRating = () => {
        let sum = 0;
        recording.reviews.forEach((review) => (sum += review.score));

        return sum / length;
    };

    return (
        <tr>
            <td className={"px-4 py-3 " + (lastItem && "rounded-bl-xl")}>
                <span className="text-sm text-primary-600 font-medium block w-full md:w-96 lg:w-[350px] xl:w-[380px] 2xl:w-[600px]">
                    {recording.question}
                </span>
            </td>
            <td className={"px-4 py-3 " + (lastItem && "rounded-br-xl")}>
                <div className="flex flex-row gap-2">
                    <Button
                        colour={BUTTON_VARIANT_TYPES.SECONDARY}
                        icon={<PlayIcon className="h-4 w-4" />}
                        extraStyling={"text-xs h-8 w-8 rounded-full p-2 flex items-center justify-center"}
                        iconPositionLeft
                        onClick={() => handleShow("recording")}
                    />
                </div>
                <Modal
                    active={showRecording}
                    closeModal={() => handleShow("recording")}
                    content={
                        <div className="flex flex-col gap-4 max-w-[640px]">
                            <div className="flex flex-row items-center justify-end">
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.GRAY}
                                    icon={<XMarkIcon className="h-3.5 w-3.5 stroke-current stroke-1 mr-2" />}
                                    text="Close video"
                                    iconPositionLeft
                                    onClick={() => handleShow("recording")}
                                    smallButton
                                />
                            </div>
                            <div
                                className={
                                    "flex flex-col gap-2 items-center justify-center overflow-hidden rounded-xl " +
                                    "bg-black"
                                }
                            >
                                <ReactPlayer url={recording.recordingUrl} controls />
                            </div>
                            <div className="flex flex-row gap-2 flex-wrap ">
                                <div className="py-1.5 px-2.5 bg-secondary-600 rounded-xl w-max">
                                    <div className="text-xs text-white">{recording.question}</div>
                                </div>
                                <div className="flex flex-col gap-2 py-1.5 px-2.5 bg-primary-600 rounded-2xl w-max">
                                    <div className={"text-xs font-medium text-white whitespace-pre-wrap"}>
                                        {recording.interviewInfo.campaignName}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 py-1.5 px-2.5 bg-primary-100 rounded-2xl w-max">
                                    <div className="flex flex-row gap-2">
                                        <div className={"text-xs text-primary-600 font-medium"}>
                                            {recording.interviewInfo.firstName + " " + recording.interviewInfo.lastName}{" "}
                                            {recording.interviewInfo.emailAddress}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            </td>
            <td className="px-4 py-3 relative">
                <div className="flex">
                    {!recording?.reviews.find((review) => review.feedback !== null) || recording?.reviews.length < 1 ? (
                        <Tippy
                            content={"No feedback found"}
                            className={"py-1.5 px-2 shadow rounded-2xl text-xs font-medium bg-primary-600 text-white"}
                            arrow={false}
                            animation={"shift-away-subtle"}
                            placement={"right"}
                            hideOnClick={false}
                        >
                            <div>
                                <Button
                                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                                    icon={<ChatBubbleBottomCenterTextIcon className="h-4 w-4" />}
                                    extraStyling={
                                        "text-xs h-8 w-8 rounded-full p-2 flex items-center justify-center hover"
                                    }
                                    onClick={() => handleShow("reviews")}
                                    disabled={
                                        !recording?.reviews.find((review) => review.feedback !== null) ||
                                        recording?.reviews.length < 1
                                    }
                                    iconPositionLeft
                                />
                            </div>
                        </Tippy>
                    ) : (
                        <Button
                            colour={BUTTON_VARIANT_TYPES.SECONDARY}
                            icon={<ChatBubbleBottomCenterTextIcon className="h-4 w-4" />}
                            extraStyling={"text-xs h-8 w-8 rounded-full p-2 flex items-center justify-center hover"}
                            onClick={() => handleShow("reviews")}
                            disabled={
                                !recording?.reviews.find((review) => review.feedback !== null) ||
                                recording?.reviews.length < 1
                            }
                            iconPositionLeft
                        />
                    )}
                </div>

                <AnimatePresence>
                    {showReviews && (
                        <>
                            <motion.div
                                initial={"inactive"}
                                animate={["active", { zIndex: 30 }]}
                                exit={"inactive"}
                                transition={{ duration: 0.4 }}
                                variants={BASIC_ANIMATION_VARIANTS}
                                className={
                                    "flex flex-col fixed gap-3 fixed m-auto inset-x-0 inset-y-0 h-max p-4 rounded-xl " +
                                    "bg-slate-100 shadow-2xl w-[600px]"
                                }
                            >
                                <div className="flex justify-between items-center gap-8">
                                    <h3 className="font-medium text-primary-600 tracking-tight font-title">
                                        "{recording.question}"
                                    </h3>
                                    <button
                                        type="button"
                                        className="focus:outline-none place-self-start"
                                        onClick={() => handleShow("reviews")}
                                    >
                                        <XMarkIcon className="h-5 w-5 text-slate-500 hover:text-slate-700 stroke-current stroke-1" />
                                    </button>
                                </div>
                                <h3 className="font-medium text-secondary-600 tracking-tight font-title">
                                    Organisation feedback:
                                </h3>
                                <div className="flex flex-wrap flex-row gap-2 max-h-96 overflow-y-scroll pr-2">
                                    {recording.reviews.map(
                                        (review, index) =>
                                            review.feedback && (
                                                <div className="flex flex-col gap-0 w-full" key={index}>
                                                    <div className="flex flex-col gap-2 px-2 py-2.5 bg-slate-50 rounded-xl">
                                                        <div className="flex flex-row justify-between">
                                                            <div className="flex flex-row items-center gap-1">
                                                                <div className="text-sm font-medium text-primary-600">
                                                                    {review.firstName} {review.lastName}
                                                                </div>
                                                                <div className="text-xs text-primary-600 px-2 py-1 rounded-full bg-primary-50 ">
                                                                    {review.emailAddress}
                                                                </div>
                                                            </div>
                                                            <div className="text-xs font-medium text-secondary-600">
                                                                {new Date(review.date).toLocaleString("en-AU", {
                                                                    dateStyle: "short",
                                                                    timeStyle: "short",
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-sm text-slate-500">{review.feedback}</div>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </div>
                                <div className="flex justify-end">
                                    <Button
                                        text="Close"
                                        onClick={() => handleShow("reviews")}
                                        colour={BUTTON_VARIANT_TYPES.GRAY_DARKER}
                                    />
                                </div>
                            </motion.div>
                            <div
                                onClick={() => handleShow("reviews")}
                                className="opacity-20 fixed inset-0 z-20 bg-black"
                            />
                        </>
                    )}
                </AnimatePresence>
            </td>
            <td className="px-4 py-3">
                <div className="flex gap-2 ">
                    <StarRating starsRating={averageRating} readonly={true} />
                    <span className={"text-sm font-medium text-slate-500"}>({length})</span>
                </div>
            </td>
            {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.SAVE_INTERVIEW_RECORDING_SCORE) && (
                <td className={"px-4 py-3 relative " + (lastItem && "rounded-br-xl")}>
                    {recording.isEditable ? (
                        <div className="flex flex-row gap-3">
                            <Button
                                colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                text="Edit"
                                icon={<StarIcon className="h-3 w-3 mr-1" />}
                                iconPositionLeft
                                onClick={() => handleShow("feedbackForm")}
                                smallButton
                            />
                            <Button
                                colour={BUTTON_VARIANT_TYPES.RED}
                                text="Review"
                                icon={<TrashIcon className="h-3 w-3 mr-1" />}
                                loadingIconSize="h-3 w-3 mr-1"
                                iconPositionLeft
                                onClick={() => setShowDeleteConfirmation(true)}
                                loading={loadingDelete}
                                disabled={loadingDelete}
                                smallButton
                            />
                        </div>
                    ) : (
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Review"
                            icon={<StarIcon className="h-3 w-3 mr-1" />}
                            iconPositionLeft
                            onClick={() => handleShow("feedbackForm")}
                            smallButton
                        />
                    )}

                    <FeedbackForm
                        handleShow={handleShow}
                        recording={recording}
                        showFeedbackForm={showFeedbackForm}
                        updateItemStatus={updateItemStatus}
                        setAlert={setAlert}
                        setAlertType={setAlertType}
                    />

                    <DeleteConfirmation
                        showDeleteConfirmation={showDeleteConfirmation}
                        setShowDeleteConfirmation={setShowDeleteConfirmation}
                        text={"recording review"}
                        handleDelete={handleDelete}
                        loading={loadingDelete}
                    />
                </td>
            )}
        </tr>
    );
}
