import { motion, AnimatePresence } from "framer-motion";

function CardsPlaceholder(props) {
    const { active } = props;

    const Card = () => (
        <div className={"grid grid-flow-row gap-4"}>
            <div className="h-6 bg-slate-200 rounded-xl w-3/4" />
            <div className="h-4 bg-slate-200 rounded-xl w-2/4" />
            <div className="h-24 bg-slate-200 rounded-xl w-6/6" />
            <div className="h-8 bg-slate-200 rounded-xl w-6/6" />
        </div>
    );

    return (
        <AnimatePresence>
            {active && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <div className="animate-pulse flex space-x-4">
                        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-12 w-full">
                            {Array.from({ length: 6 }, (index, k) => (
                                <Card key={k} />
                            ))}
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default CardsPlaceholder;
