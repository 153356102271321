import { useEffect, useState } from "react";

import { COACH_PLATFORM_PERMISSIONS, CUSTOMER_PORTAL_PERMISSIONS } from "../../../../utils/constants";
import { getOrganisationUsersLite } from "../../../../utils/api";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import OrganisationUserRow from "./OrganisationUserRow";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";
import PaginatedSearch from "../../../SharedComponents/Pagination/PaginatedSearch";

export default function OrganisationUsers() {
    const [alert, setAlert] = useState(null);
    const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
    const [organisationUsers, setOrganisationUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchOrganisationUsers = async () => {
        setLoading(true);
        try {
            const organisationUserResponse = await getOrganisationUsersLite(true);
            setOrganisationUsers(organisationUserResponse);
        } catch (error) {
            setAlertType(ALERT_TYPE.ERROR);
            setAlert(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchOrganisationUsers();
    }, []);

    return (
        <DashboardLayout
            title={"Organisation users"}
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUPS, CUSTOMER_PORTAL_PERMISSIONS.VIEW_USERS_LIST]}
        >
            <Alert text={alert} type={alertType} active={alert} close={() => setAlert("")} />
            <PaginatedSearch
                loading={loading}
                items={organisationUsers}
                itemName={"Organisation users"}
                searchAttributes={["firstName", "lastName", "emailAddress", "mobileNumber"]}
                columnNames={["User details", "Mobile number", "Type", "View"]}
                ItemRow={OrganisationUserRow}
            />
        </DashboardLayout>
    );
}
