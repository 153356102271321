import { ForwardIcon, UserIcon } from "@heroicons/react/24/solid";
import ReactMarkdown from "react-markdown";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";

import Scale from "../MenuDriven/ChartCards/Scale";
import CollapsibleCard from "../../../../../SharedComponents/CollapsibleCard";
import { RadarAndBarChart } from "../../SharedChartComponents/RadarAndBarChart";

export default function CapabilityCard(props) {
    const {
        capability,
        testId,
        jumpToRef,
        onlyCharts,
        firstItem,
        levelNames,
        printToPDFMode,
        userName,
        hidePercentScoreNumbers,
        highlightLevel,
        old,
    } = props;

    return (
        <div
            ref={jumpToRef}
            className={
                "flex flex-col gap-4 w-full p-4 rounded-xl border shadow-sm print:-mt-4  " +
                "print:p-0 print:shadow-none print:border-none bg-white border-slate-100 " +
                (firstItem ? "break-inside-avoid-page" : "break-before-page")
            }
            data-testid={testId && testId}
        >
            <span className={"font-medium text-base text-secondary-600"}>{capability.name}</span>
            <div className={"flex flex-col gap-4 print:gap-8"}>
                <div className={"flex-col gap-2 " + (onlyCharts ? "hidden" : "flex")}>
                    {capability.description.map((paragraph, index) => (
                        <div key={index + "Description"} className="text-slate-500 text-sm">
                            <ReactMarkdown>{paragraph}</ReactMarkdown>
                        </div>
                    ))}
                </div>
                <Scale
                    item={{
                        coacheeName: userName ? userName : "Capability Co",
                        percentage: capability["selfAssessmentScore"],
                        name: "Self-assessed Current Capability",
                        scoreDescription: capability.bandDescription,
                        colour: "teal",
                        textColour: "text-teal-600",
                    }}
                    backgroundColour={"bg-teal-50 border-teal-100"}
                    hideBarPercentage
                    fourPartsScale
                    barAdjectives={levelNames ? levelNames : ["Entry", "Foundation", "Advanced", "Mastery"]}
                    darkPercentageScoreBubble
                    hidePercentScoreNumber={hidePercentScoreNumbers}
                    hideBottomAxis={hidePercentScoreNumbers || old}
                    highlightLevel={highlightLevel}
                    evenLevels
                    capabilityProfile={!old}
                    old={old}
                />
                <Scale
                    item={{
                        coacheeName: userName ? userName : "Capability Co",
                        percentage: capability["mindsetScore"],
                        name: "Mindset",
                        scoreDescription: capability.bandDescription,
                        colour: "purple",
                        textColour: "text-purple-600",
                        extraContent: (
                            <div
                                className={
                                    "flex flex-col gap-8 bg-gradient-to-r from-fuchsia-100 via-pink-50 " +
                                    "to-rose-50 p-4 rounded-xl shadow-sm border border-fuchsia-100 print:p-0 " +
                                    "print:shadow-none print:border-none print:from-transparent " +
                                    "print:via-transparent print:to-transparent print:block print:space-y-8 "
                                }
                            >
                                <span className={"font-medium text-base text-purple-600"}>Mindset Sub-components</span>
                                <RadarAndBarChart
                                    capability={capability}
                                    levelNames={levelNames}
                                    hidePercentScoreNumbers={hidePercentScoreNumbers || old}
                                    old={old}
                                />
                            </div>
                        ),
                    }}
                    backgroundColour={"bg-purple-50 border-purple-100"}
                    hideBarPercentage
                    fourPartsScale
                    mindsetBands
                    barAdjectives={levelNames ? levelNames : ["Entry", "Foundation", "Advanced", "Mastery"]}
                    darkPercentageScoreBubble
                    hidePercentScoreNumber={hidePercentScoreNumbers}
                    hideBottomAxis={hidePercentScoreNumbers || old}
                    highlightLevel={highlightLevel}
                    evenLevels
                    capabilityProfile={!old}
                    old={old}
                />
                <div className={"flex flex-col gap-4 print:gap-6 break-before-page print:-mt-6"}>
                    <CollapsibleCard
                        title={"Current capability & Mindset"}
                        backgroundColour={"bg-secondary-50"}
                        borderColour={"border-secondary-100"}
                        buttonVariant={BUTTON_VARIANT_TYPES.SECONDARY}
                        titleTextColour={"text-secondary-600"}
                        icon={<UserIcon className={"h-4.5 w-4.5"} />}
                        content={
                            <div className={"flex flex-col gap-4"}>
                                <div className={"flex flex-col gap-2 mt-4"}>
                                    <span className={"font-medium text-base text-secondary-700"}>
                                        {capability.currentCapability.title}
                                    </span>
                                    <div className={"flex flex-col gap-3 text-slate-500 text-sm"}>
                                        <p className={"text-secondary-900 font-medium"}>
                                            {capability.currentCapability.description}
                                        </p>
                                        <ul className={"list-disc ml-6 grid grid-flow-row gap-y-1.5 gap-x-6"}>
                                            {capability.currentCapability.points.map((point, index) => (
                                                <li key={index + "currentCapabilityPoint"}>{point}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className={"flex flex-col gap-2"}>
                                    <span className={"font-medium text-base text-secondary-700"}>
                                        {capability.mindset.title}
                                    </span>
                                    <div className={"flex flex-col gap-3 text-slate-500 text-sm"}>
                                        <p className={"text-secondary-900 font-medium"}>
                                            {capability.mindset.description}
                                        </p>
                                        <ul className={"list-disc ml-6 grid grid-flow-row gap-y-1.5 gap-x-6"}>
                                            {capability.mindset.points.map((point, index) => (
                                                <li key={index + "mindsetPoint"}>{point}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        alwaysShow={printToPDFMode}
                    />
                    <CollapsibleCard
                        title={capability.nextSteps.title}
                        backgroundColour={"bg-emerald-50"}
                        borderColour={"border-emerald-100"}
                        buttonVariant={BUTTON_VARIANT_TYPES.GREEN}
                        titleTextColour={"text-emerald-600"}
                        icon={<ForwardIcon className={"h-4.5 w-4.5"} />}
                        content={
                            <div className={"flex flex-col gap-4 h-max"}>
                                <div className={"mt-4 flex flex-col gap-2"}>
                                    <span className={"font-medium text-base text-emerald-700"}>Next steps</span>
                                    <div className={"flex flex-col gap-3 text-slate-500 text-sm"}>
                                        <p>{capability.nextSteps.description}</p>
                                        <span className={"font-medium text-base text-emerald-700"}>Suggestions</span>
                                        {capability.nextSteps.suggestions.map((suggestion, index) => (
                                            <div key={index + "suggestions"} className={"flex flex-col gap-2"}>
                                                <p className={"text-emerald-900 font-medium"}>
                                                    {suggestion.description}
                                                </p>
                                                <ul className={"list-disc ml-6 grid grid-flow-row gap-y-1.5 gap-x-6"}>
                                                    {suggestion.points.map((point, index) => (
                                                        <li key={index + "suggestionsPoint"}>{point}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                        alwaysShow={printToPDFMode}
                    />
                </div>
            </div>
        </div>
    );
}
