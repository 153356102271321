import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";

import Button from "../../../../../SharedComponents/Button";
import Input from "../../../../../SharedComponents/Form/Input";

export default function AddTextItem(props) {
    const {
        textItemName,
        backgroundColour,
        inputIcon,
        customGrid,
        customGridItem,
        textItems,
        setTextItems,
        addItemsToParent,
        parentId,
    } = props;

    function handleEditTextItems(key, value, id) {
        textItems.forEach(function (textItem) {
            if (textItem.id === id) {
                textItem[key] = value;
            }
        });

        setTextItems([...textItems]);

        if (addItemsToParent) {
            addItemsToParent(textItems, parentId);
        }
    }

    const handleAddTextItem = () => {
        setTextItems([
            ...textItems,
            {
                name: "",
                id: (Math.random() + 1).toString(36).substring(7),
            },
        ]);
    };

    const handleDeleteTextItem = (id) => {
        setTextItems([...textItems.filter((textItem) => textItem.id !== id)]);
    };

    return (
        <div className="col-span-full rounded-xl">
            <div className={"grid grid-flow-row gap-4"}>
                {textItems && textItems.length === 0 ? (
                    <div className={"p-3.5 bg-white rounded-xl"}>
                        <div className={"text-slate-500 text-sm"}>
                            No {textItemName.toLowerCase()}(s) has been added
                        </div>
                    </div>
                ) : (
                    <div className={customGrid ? customGrid : "flex flex-col gap-4"}>
                        {textItems &&
                            textItems.map((textItem, index) => (
                                <div
                                    key={index}
                                    className={
                                        "gap-2 p-3 rounded-xl " +
                                        (backgroundColour ? backgroundColour : "bg-slate-100") +
                                        " " +
                                        (customGridItem ? customGridItem : " flex flex-col")
                                    }
                                >
                                    <div className={"flex flex-col gap-2"} key={index}>
                                        <div className={"grid grid-flow-row lg:grid-cols-2 gap-6 "}>
                                            <Input
                                                id="name"
                                                type="text"
                                                label={textItemName + " " + (index + 1)}
                                                placeholder={textItemName}
                                                icon={inputIcon}
                                                backgroundColour={"bg-white"}
                                                currentValue={textItem.name}
                                                customOnChange={(e) =>
                                                    handleEditTextItems("name", e.target.value, textItem.id)
                                                }
                                                extraStyling="col-span-full"
                                                autoFocus
                                                endElements={
                                                    <Tippy
                                                        content={"Remove " + textItemName.toLowerCase()}
                                                        className={
                                                            "py-1.5 px-2 shadow rounded-2xl text-xs font-medium " +
                                                            "bg-red-600 text-red-50"
                                                        }
                                                        arrow={false}
                                                        animation={"shift-away-subtle"}
                                                        placement={"top-start"}
                                                    >
                                                        <TrashIcon
                                                            onClick={() => handleDeleteTextItem(textItem.id)}
                                                            className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-700 transition-colors"
                                                        />
                                                    </Tippy>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
            <div className={"w-full text-right col-span-full mt-4"}>
                <Button
                    colour={BUTTON_VARIANT_TYPES.SECONDARY}
                    text={"Add " + textItemName.toLowerCase()}
                    icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                    iconPositionLeft
                    smallButton
                    onClick={handleAddTextItem}
                />
            </div>
        </div>
    );
}
