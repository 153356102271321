import { BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../../utils/constants";

import BarChartForScales from "../../../Charts/CustomCharts/BarChartForScales";

export default function AchievementItemVerticalBarChart(props) {
    const {
        data,
        ratingDivision,
        flippedPercentageScore,
        sevenPointScale,
        noCategories,
        noLegend,
        noTrendBubble,
        noInfoBubble,
    } = props;

    return (
        <div
            className={
                "flex flex-col gap-4 w-max bg-white p-4 print:p-0 print:border-0 print:shadow-none rounded-xl " +
                "border border-slate-100 shadow-sm print:break-inside-avoid-page"
            }
        >
            <div className="flex flex-row justify-between items-center gap-2">
                <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">{data.itemTitle}</h2>
            </div>
            <div className="flex flex-col gap-6">
                <div
                    className={
                        "text-sm text-secondary-600 font-medium bg-secondary-50 border border-secondary-100 " +
                        "rounded-xl w-fit p-2"
                    }
                >
                    {data.itemText && data.itemText}
                </div>
                <BarChartForScales
                    multiple
                    colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                    data={data}
                    sevenPointScale={sevenPointScale}
                    ratingDivision={ratingDivision}
                    flippedPercentageScore={flippedPercentageScore}
                    noCategories={noCategories}
                    noLegend={noLegend}
                    noTrendBubble={noTrendBubble}
                    noInfoBubble={noInfoBubble}
                />
            </div>
        </div>
    );
}
