import vgcccLogo from "../../assets/vgccc-logo.png";

export const sampleCapabilityProfileVGCCCScore = {
    organisationLogo: vgcccLogo,
    reportName: "Capability Profile",
    reportLevelNames: ["Foundational", "Applied", "Accomplished", "Leading"],
    reportDescription:
        "We want you to have a great career, and to grow and develop so that you are ready for the future. This is why we have Future Ready. It is your guide to developing the capabilities that are essential for all roles at Telstra. The capabilities that make us great at our roles today and ready for what comes tomorrow. As a result of completing the Future Ready Insights Questionnaire, you have received this Future Ready Insights Report. It contains rich feedback and insight to support your development and career growth.",
    snapshot: [
        "Every individual has a different combination of Human capabilities, it's what makes you you! Comparing yourself assessment and mindset will help to explain why some activities you find easy and enjoyable while others are more challenging and require more effort and focus to complete. ",
    ],
    understandingYourCapabilityProfile: {
        description: [
            "Congratulations on completing your individual Capability Self-Assessment.  This Capability Profile has been developed to support VGCCC's capability framwork, providing valuable insights to support your continued development and growth.",
            "Our Purpose and Strategic Ambitions defines and unites our focus, providing a common lens through which to view problems, develop solutions and make difficult decisions. To achieve our Purpose and Strategic Ambitions, we are committed to delivering an exceptional employee experience, supported through the delivery of highly effective learning and development programs and iniatiives.  We are investing in the capabilities and expertise of our people, who are at the heart of our organisational capability. ",
            "The VGCCC Capability Framework defines the capabilities required to achieve our shared purpose now and into the future.",
            'Your Capability Profile helps you understand your current capability and mindset against the VGCCC Capabilities and provides insights to support your development and growth in VGCCC. There is no “ideal” or "correct" profile. Results are intended to provide you with a deeper understanding and insights to a allow you to harness your innate strengths and potential - understanding what you can do today and what could be possible for you in the future.',
            "The Capability Profile by design does not, consider your experience, training and qualifications, and other environmental factors that will impact your development and career. Your Capability Profile should be considered in the context of other available and relevant information.",
            "Next steps:",
            "- Reflect on your strengths and development opportunities",
            "- Seek feedback on specific skills and mindsets",
            "- Explore career pathways by understanding the capabilities that are critical for different roles and functions",
            "- Use the Development Roadmaps in this report to create and support your development goals",
        ],
    },
    harnessingYourResults: {
        title: "Harnessing your results",
        description: [
            "As well as providing deeper understand of you, your Capability Profile provides tangible actions and development material to activate your capabilities as part of your day to day work.",
        ],
    },
    capabilityLevels: {
        title: "The Capability Levels",
        tableHeaders: ["Level", "With learning and experience you have the ability to:"],
        tableRows: [
            {
                title: "Foundational",
                points: [
                    "Seeks guidance on generalised skills and those specific to their role",
                    "Works under close supervision, gradually increasing autonomy on specific tasks",
                    "Are likely to be engaged in routine administrative and operational tasks that support the work of the team",
                ],
            },
            {
                title: "Applied",
                points: [
                    "Seeks guidance and support as required from peers and line managers",
                    "Increasingly work autonomously and gain confidence in dealing with complex or non-routine situations",
                    "May take the lead on certain initiatives and, in larger teams, may have some responsibility for the activities of others",
                ],
            },
            {
                title: "Accomplished",
                points: [
                    "Mastery in work and a breadth of understanding of the skills and mindset required of their role and career pathway",
                    "Trusted advisers to colleagues, a source of guidance for boards and others, particularly on regulatory and legislative matters",
                    "Exercises good judgement across planned and unplanned situations and can react accordingly",
                    "Constantly maintains a watching brief on external influences that may affect the organisation",
                ],
            },
            {
                title: "Leading",
                points: [
                    "Seasoned expert or strategic leader",
                    "May have extensive experience and a strong external network",
                    "Influences organisational purpose and direction",
                    "Frequently consulted for their deep expertise or lead initiatives across the organisation and beyond",
                    "Actively supports the development of other staff members and leaders and contribute to policy and outcomes both within the VGCCC and their sector. ",
                ],
            },
        ],
    },
    capabilities: [
        {
            name: "Adaptive Mindset",
            description: [
                "Curious, courageous and prepared to challenge the status quo. Maintaining a mindset that is resilient, flexible, responsive and able to continually learn, adapt and embrace change",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "Mindsets are the deep assumptions and mental attitudes that drive behaviour. As such, mindsets are not just a way of thinking. They shape a person’s:",
                "• Emotional disposition (feelings and moods);",
                "• Cognitive processes (beliefs and attitudes); and",
                "• Behavioural response (actions).",
                "People with adaptive mindsets understand the need for continuous improvement and change; they know that success – personal and collective – relies on working under pressure, developing ways to cope with stress and anxiety, and continually learn from their experience. This means every experience contributes to action for people, teams, and the organisation.",
                "At the heart of developing an adaptive mindset is self-awareness, curiosity, persistence and ‘grit’ so that City of Casey people can keep going when things get tough or uncertain.",
                "Adopting a growth mindset means we not only cope better but actively look for opportunities for learning and growth (Dweck, 2017). People with a growth mindset see failures and challenges as momentary setbacks and opportunities for personal growth, whereas people with a fixed mindset view failure as a demonstration of the limits to their knowledge and ability.",
                "The Adaptive Mindset is a capability that looks forward to the needs of the future workforce and constantly changing environment. Rather than just taking a traditional focus on what a person does, it examines how they think. It seeks to confirm a person’s ability to maintain their curiosity while dealing with disruption, pressure and setbacks in a resilient, positive manner. It is very much about the neurology of how we think and balance mental models that embrace both fixed and growth mindsets. This reinforces the need for workers to not only learn explicit technical knowledge, but also be an innovator where their abilities, intelligence, and talents are developed through experience and informal interactions that may lead to new and better solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Flexibility",
                    strategies: "",
                    band_description: "Flexible, growth-oriented and planful",
                    percentile: 60,
                },
                {
                    name: "Growth Focus",
                    strategies: "",
                    band_description: "Goal-oriented, self-confident and determined",
                    percentile: 43,
                },
                {
                    name: "Positive Outlook",
                    strategies: "",
                    band_description: "Resilient, tough-minded and determined",
                    percentile: 96,
                },
            ],
            selfAssessmentScore: 78,
            mindsetScore: 67,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading level. This means you believe you have the skills to: ",
                points: [
                    "Creates a climate which encourages and supports openness, persistence and genuine debate around critical issues",
                    "Possesses the sophisticated self-awareness necessary to make appropriate evaluative judgements about people, events, organisations and processes",
                    "Enhances business or community capacity to challenge and adapt to new perspectives or ideas",
                    "Persists and overcomes obstacles to reach ambitious long-term personal and professional goals",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with Adaptive Mindset tend to: ",
                points: [
                    "Have a growth mindset and remain curious and prepared to learn and adapt",
                    "Are confident and prepared to stretch their own and others’ thinking",
                    "Embrace challenges and new situations with an open mind",
                    "Be independent thinkers and prepared to challenge poor practices",
                    "Be curious and envision what is possible",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Initiate and sponsor the construction of change projects with a focus on ‘why’ and are meaningfully engaging people in the adaptive challenge. Extend the change agenda away from just a focus on processes and plans that people ‘must’ execute. Lead these initiatives from planning to implementation, demonstrating your ability to build in sense of shared purpose, and the behavioral and mindset aspects people can develop to prepare them for the next adaptive challenge.",
                            "Take responsibility for planning and conducting training sessions for other leaders. Empower them to enhance employee engagement and mental agility, leveraging your own self-awareness and evaluative judgment skills.",
                            "Engage in strategic rotations or tasks that focus on identifying how the skills and knowledge of individuals and teams can best respond to emerging opportunities. Leverage your high-level strategic view to drive adaptability within the organisation.",
                            "Join or establish thought leadership communities within your organisation that are dedicated to embracing change, adaptability, and innovation. Share insights, research, and thought-provoking discussions.",
                            "Explore opportunities to actively explore cross-generational relationships and business tasks or projects where you can work alongside younger or different generations. Exchange experiences and insights with both early-career and experienced professionals to promote continuous learning and adaptability.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Collaborate with early career/ emerging leaders to use coaching with authentic 2-way learning to ensure you and the trainee isolate what will work to raise employee engagement. Explore strategies to create a climate that encourages openness, persistence, and genuine debate around factors inhibiting how teams respond to the organisation’s adaptive challenges.",
                            "Gather feedback from a diverse range of stakeholders, including those outside your immediate team, to assess your impact on enhancing the organisation's capacity to challenge and adapt to new perspectives and ideas.",
                            "Partner with a mentor who possesses a high-level strategic view and is skilled at developing agile practices and methods across business units. Learn how to persist and overcome obstacles to reach ambitious long-term personal and professional goals while enhancing the organisation's adaptability.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Adaptivity",
                            "Change",
                            "Resilience",
                            "Emotional Intelligence",
                        ],
                    },
                ],
            },
        },
        {
            name: "Innovate & Improve",
            version: "1",
            description: [
                "Actively identifies and experiments with ways to test, learn and improve the quality of systems, processes or services",
                "This is an area of strength for you. It is likely that you enjoy work and learning in this area and that this capability now comes naturally to you. Consider how you can continue to develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [
                "Improve and Innovate is the capacity to not only apply tools and techniques that stimulate innovation; it is also a mental model whereby the person proactively examines problems or situations from a fresh perspective. This may lead to unconventional solutions or new insights that can be exploited to improve current thinking or practice.",
                "Improve and Innovate is not only centred on the practical improvement to existing technology, processes, or products. It may involve the reshaping of practices and thinking. It could focus on simplifying and adapting what exists to better improve the customer experience and outcomes for communities.",
                "The ability to adapt and think as an innovator underpins the continual search for improvements. This is where Improve and Innovate reinforces not only being agile but embraces the ability to innovate and create improved processes and products.",
                "The person obtaining the Improve and Innovate capability may not necessarily be the most creative thinker. But they can challenge current thinking and practice. They can work with others to champion innovative ideas or adaptive solutions. They can embrace new ways of thinking, listen well, imagine, and envisage opportunities, provide the energy to explore new ideas, and display entrepreneurial behaviours that others admire. This could include taking calculated risks and failing fast and learning from it.",
                "At all levels of proficiency, evidence of Improve and Innovate must confirm the ability to work in an agile manner and make connections between disparate ideas, thinking, or practices. It necessitates the ability to move beyond technical knowledge to actively engage the intellect and emotions to see or to create new opportunities, products, or solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Curiosity",
                    strategies: "",
                    band_description: "Curious, inquisitive, investigative, scrutinising, and fascinated",
                    percentile: 74,
                },
                {
                    name: "Exploration",
                    strategies: "",
                    band_description: "Exploratory, innovative, inventive, adventurous, and far-sighted",
                    percentile: 76,
                },
            ],
            selfAssessmentScore: 77,
            mindsetScore: 78,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading level. This means you believe you have the skills to: ",
                points: [
                    "Takes a strategic, end-to-end view of where and how the organisation creates value",
                    "Monitors the external environment to diagnose where external factors will impact major innovations or strategies",
                    "Encourages and leads the search for innovations or new and different ways of working that will enhance organisational outcomes",
                    "Sponsors efforts to seize opportunities to adapt, improve, or innovate",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Innovate & Improve mindset tend to: ",
                points: [
                    "Suspend criticism as a default",
                    "Seek to challenge traditional ideas or thinking",
                    "Imagine and envisage opportunities",
                    "Take the initiative and energy to explore new ideas",
                    "Passionately explore what is possible",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Establish strategic innovation. These labs serve as dedicated spaces for cross-functional teams to collaborate, ideate, and experiment with innovative solutions. Encourage teams to tackle high-impact projects that enhance organisational outcomes.",
                            "Lead sessions focused on mapping the end-to-end value creation process. Engage with teams to identify opportunities for improvement and innovation at various stages of the value chain. Foster a holistic view of value delivery.",
                            "Develop a routine for monitoring the external environment and identifying factors that may impact major innovations or strategies. Share your findings and insights with leadership teams to inform strategic decisions.",
                            "Organise innovation challenges at the organisational level. Encourage team members to submit innovative ideas and participate in projects that drive improvements. Sponsor and support initiatives with the potential to create significant value.",
                            "Host an annual innovation symposium where leaders and teams gather to showcase successful innovations and share lessons learned. Create a platform (in-person forum, virtual noticeboard, etc.) for cross-pollination of ideas and collaboration on projects with the potential to transform the organisation.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Offer coaching sessions to emerging leaders on taking a strategic view of innovation. Share your experiences in identifying opportunities for organisational enhancement and provide guidance on leading innovation efforts effectively.",
                            "Actively seek feedback from cross-functional teams and colleagues on your innovation leadership approach. Use their input to refine your strategies for encouraging and leading innovation within the organisation.",
                            "Mentor emerging leaders in the art of sponsorship for innovation and improvement initiatives. Share your expertise in identifying, supporting, and scaling innovative projects that align with the organisation's goals.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Innovation",
                            "Continuous Improvement",
                            "Entrepreneurship",
                            "Experimentation",
                        ],
                    },
                ],
            },
        },
        {
            name: "Work Together",
            version: "1",
            description: [
                "Works across functions and disciplines, consults, keeps people informed and maintains positive working relationships",
                "Focus on developing this capability! You can unlock your potential by developing this capability! You have a natural strength and the potential, with experience and learning, to perform at a higher level. ",
            ],
            longerDescription: [
                "Working Together encompasses the interaction between the members within a team or across teams or informal groups. The individuals involved may have diverse values, purposes, locations, or cultures but agree to work together to achieve a shared goal or produce a mutually beneficial output.",
                "Working Together increasingly involves the use of technology to link people and organisations. Powered by an array of video, chat, smart and mobile devices, group messaging apps, or secure cloud-based file sharing, project management and other collaborative applications, everyone can contribute in shared spaces.",
                "Working Together in practice will involve several broad requirements:",
                "• Working with people",
                "• Respecting different opinions and motivations & balancing diverse perspectives and beliefs before initiating one’s own judgments and decisions",
                "• Seeking, considering, and responding positively to differences in priorities, opinions, perspectives, values, or beliefs",
                "• Valuing and recognising the contribution each person can make",
                "• Actively participating in the collaborative activity",
                "• Influencing others to strengthen the group’s collaborative outcomes",
                "• Making decisions that consider multiple individual contributions",
                "• Aligning one’s own actions, contributions, and priorities to those set by the group.",
                "Achieving the capability requires a significant amount of context-based experience. This experience can be gained while working alone or when collaborating with others who are not part of a team structure. Hey may be part of an informal relationship or, as is increasingly the case in virtual environments, join a network solely based on their mutual interests.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Cooperation",
                    strategies: "",
                    band_description: "Cooperative, collaborative, thoughtful, considerate, and open-minded ",
                    percentile: 90,
                },
                {
                    name: "Considerate",
                    strategies: "",
                    band_description: "",
                    percentile: 56,
                },
                {
                    name: "Respect",
                    strategies: "",
                    band_description: "",
                    percentile: 43,
                },
            ],
            selfAssessmentScore: 75,
            mindsetScore: 98,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading. This means you believe you have the skills to: ",
                points: [
                    "Guides others to create a culture of collaboration",
                    "Navigates organisational politics and handles situations effectively and with discretion",
                    "Identifies and works to overcome barriers to knowledge or information sharing",
                    "Identifies opportunities to work with other teams to deliver outcomes",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Work Together mindset tend to:",
                points: [
                    "Be interested in people",
                    "Feel comfortable engaging with others",
                    "Make others feel at ease",
                    "Willingly work with other people",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Host regular brainstorming sessions focused on identifying potential synergies between teams or projects. Encourage creative thinking and collaboration to come up with innovative ways to work together more efficiently.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Solicit feedback on your efforts to facilitate cross-functional collaborations. Ensure that your actions are achieving the desired results, by asking for feedback and ensure you adjust your strategies based on feedback received.",
                            "Work with a mentor who is skilled at encouraging intra-organisational collaboration. Choose leaders who are well-connected and inspire and motivate cross-functional teams to work together seamlessly.",
                            "Seek coaching from an experienced colleague who excels at building collaborative relationships to enhance accountability, problem-solving, and cross-functional improvement activities at all levels. Learn how to establish meaningful connections, foster collaboration, and remain in touch with the day-to-day realities of your organisation.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Collaboration",
                            "Communication",
                            "Engagement",
                            "Teamwork",
                        ],
                    },
                ],
            },
        },
        {
            name: "Make it Happen",
            version: "1",
            description: [
                "Takes a long-term vision, creates plans, and prioritises actions to ensure the VGCCC’s purpose is attained",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "The City of Casey requires its people to be resourceful and to translate plans into action.",
                "The City of Casey supports its people to initiate action, coordinate effort, and generate commitment from others to the organisation’s strategic direction and purpose.",
                "Strategic Direction and Purpose requires not only personal commitment and initiative, it also necessitates the ability to overcome problems that affect goal attainment or orientation of the workforce to agreed outcomes. It centres on translating plans that deliver an agreed direction into action.",
                "The new Outcomes operating model focusses on outcome ownership and accountability, and the performance management and measurement responsibilities and processes that support them.",
                "Outcome ownership is a significant responsibility, requiring oversight and, possibly, control over resources to ensure they are aligned to outcomes-based roles.",
                "At all levels, evidence of Strategic Direction and Purpose must confirm the ability to think strategically and maintain a constant focus on the future direction. Strategic Direction and Purpose involves providing inspiration, clarity and direction through inspiring commitment to a meaningful vision. This includes focusing teams on priorities, supporting change processes, formulating plans, and attaining organisational goals",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Accountable",
                    strategies: "",
                    band_description: "",
                    percentile: 98,
                },
                {
                    name: "Drive",
                    strategies: "",
                    band_description: "",
                    percentile: 34,
                },
                {
                    name: "Execution",
                    strategies: "",
                    band_description: "",
                    percentile: 45,
                },
                {
                    name: "Purposeful",
                    strategies: "",
                    band_description: "",
                    percentile: 45,
                },
            ],
            selfAssessmentScore: 87,
            mindsetScore: 56,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading level. This means you believe you have the skills to: ",
                points: [
                    "Sponsors the research and review of external and internal issues that influence the strategic purpose and direction of the organisation",
                    "Confirms and reports organisational progress against strategic targets",
                    "Shapes a purpose statement to clearly align organisational strategy with its core values and intent towards the government, stakeholders and other stakeholders",
                    "Draws accurate conclusions from strategic data and other information to ensure the organisation is positioned to meet future challenges",
                    "Assists communicate the organisation’s purpose to the government, community, and wider industry",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Make It Happen mindset tend to:",
                points: [
                    "Have a good capacity to research and source quality strategic business intelligence",
                    "Take a thorough approach to tasks even when faced with multiple competing demands",
                    "Persevere when others quit",
                    "Secure executive-level sponsorships and engagement",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Sponsor research and review efforts that focus on external and internal issues influencing the organisation's strategic purpose and direction.",
                            "Proactively instill a culture of confirming and reporting organisational progress against strategic targets. Find meaningful ways to translate vision into own and colleagues/ team mates work.",
                            "Organise purpose alignment workshops to shape a purpose statement that clearly aligns organisational strategy with core values. Engage government, community, and industry stakeholders in the process.",
                            "Guide teams in drawing accurate conclusions from strategic data and information. Emphasise the importance of positioning the organisation to meet current and future challenges through data-driven decision-making.",
                            "Host purpose communication seminars to convey the organisation's purpose to government, community, and wider industry. Share effective communication strategies for aligning stakeholders with the organisation's vision.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Offer coaching to emerging leaders on strategic issue research and in-depth environmental analysis. Share your expertise in shaping organisational purpose and driving data-driven strategic decisions.",
                            "Provide feedback to government, community, and industry stakeholders on their understanding and alignment with the organisation's purpose. Offer insights for enhancing their support and engagement.",
                            "Mentor team members seeking to excel in leadership roles by shaping strategic direction and purpose alignment. Share your experiences in influencing the strategic landscape and engaging diverse stakeholders.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Strategic Planning",
                            "Strategic Leadership",
                            "Planning",
                            "Organisational Performance",
                        ],
                    },
                ],
            },
        },
        {
            name: "Performance & Coaching",
            version: "1",
            description: [
                "Ruthlessly prioritises what’s important while harnessing each team member’s diverse strengths to enhance engagement, performance and attainment of the organisation’s purpose",
                "Match! Your results indicate that you are applying your innate abilities and can work in a state of 'flow - it is likely that you enjoy work and learning in this area. Consider how you can stretch yourself further and develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [
                "The City of Casey will focus on engaging, empowering and motivating team members to achieve high performance in a given cultural context. This requires understanding derived through positive interactions with people from diverse cultural backgrounds and perspectives, especially with regard for differences in attitudes, beliefs, and values.",
                "At all levels of proficiency, attaining the Engagement and Coaching capability requires evidence a person is able to engage with others in culturally diverse settings with sensitivity and regard for a range of different perspectives, values, beliefs, and behaviours.",
                "Attaining the Engagement and Coaching capability may involve the formation of relationships and interactions with peers to assist transfer of knowledge and skills through coaching and mentoring activities.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Empowerment",
                    strategies: "",
                    band_description: "Empowering, inspirational, supportive, constructive, and candid",
                    percentile: 75,
                },
                {
                    name: "Inclusive",
                    strategies: "",
                    band_description: "Inclusive, non-discriminatory, egalitarian, fair-minded, and unprejudiced",
                    percentile: 34,
                },
            ],
            selfAssessmentScore: 45,
            mindsetScore: 34,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Applied level. This means you believe you have the skills to: ",
                points: [
                    "Aligns work activities with operational plans and organisational values and goals through effective people management and role modelling",
                    "Allocates role responsibilities and expectations",
                    "Recognises and models behaviours and actions that promote a culture of inclusion and participation",
                    "Encourages an environment where everyone feels comfortable providing constructive feedback and contributions",
                    "Identifies individual needs and what motivates and encourages employee engagement",
                    "Vests the authority and resources necessary for employees to achieve agreed responsibilities and goals",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Performance & Coaching mindset tend to:",
                points: [
                    "Employ a range of different influencing styles effectively",
                    "Be inclusive and values diversity",
                    "Be an effective role model",
                    "Creates the environment for high performance amongst the team",
                    "Have a strong ability to appreciate and communicate sensitively to emotional and cultural factors",
                    "Appreciate and celebrate everyone’s contribution",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Introduce strengths-based coaching within the team. Accomplished leaders can coach team members individually, helping them maximise their unique strengths for improved performance. Abolish and reject shame and blame techniques.",
                            "Determine specific capability gaps that are inhibiting success in team member’s role or career and put in place actions to develop each person.",
                            "Collaborate with team members to develop and implement engagement initiatives. Encourage accomplished team members to take the lead in creating a more engaging work environment.",
                            "Specifically recruit and source the relevant diverse thinking required to support your teams’ goals. You support the team to understand and incorporate the different viewpoints in decision making and build a psychologically safe team.",
                            "Encourage team members to share success stories related to their contributions to the organisation's purpose. These stories can inspire others and provide valuable insights.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Implement a 360-degree feedback exchange with peers, team members, and leaders. Collect specific feedback on your coaching, inclusivity, and performance enhancement efforts. Use this feedback to set concrete goals for improvement.",
                            "Shadow an experienced coach doing Development Planning session, completing a staff member's PDP, and setting up a 70:20:10 approach.",
                            "Dedicate time to practice advanced coaching techniques. Experiment with individualized coaching plans and performance optimization strategies. Reflect and discuss your experiences and lessons learned.",
                            "Arrange structured mentoring sessions with an accomplished colleague. Use these sessions to explore in-depth coaching and performance improvement strategies. Request practical examples and case studies.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Coaching",
                            "People / Performance Management",
                            "Employee Engagement",
                            "Inclusivity / Diversity",
                            "Workforce Planning",
                            "Psychological Safety",
                        ],
                    },
                ],
            },
        },
        {
            name: "Leadership",
            version: "1",
            description: [
                "Leads transformational processes, builds an environment of collaboration and models the behaviours that inspire commitment to our purpose and values",
                "Match! Your results indicate that you are applying your innate abilities and can work in a state of 'flow - it is likely that you enjoy work and learning in this area. Consider how you can stretch yourself further and develop your mindset and skills through learning and experience.",
            ],
            longerDescription: [
                "City of Casey has defined shared leadership as “Everyone does the thinking. Everyone takes the initiative. Leaders coach and empower”. This means that leadership is a shared responsibility, and decision-making is appropriately delegated, within an accountability framework, to the people who are closest to the work.",
                "Leadership at Casey is needed as we transform the organisation to better adapt to a continuously evolving external environment.",
                "Leadership better enables the collective achievement of strategic goals and outcomes through a distributed model of leadership, within an environment that fosters autonomy via clarity of delegation, empowering others to act, and establishing clear accountabilities, considering appropriate risks.",
                "Leadership engages employees to not only perform better but to invest in work that continues to build their emotional wellbeing in an environment of consistent change. This capability deliberately moves away from a low trust and individualistic model of leadership to reinforce a high trust and collective model where leadership is a shared process and capability that can be enabled in all people, not just leaders.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Determination",
                    strategies: "",
                    band_description: "Determined, persevering, self-confident, steadfast, and cool-headed ",
                    percentile: 78,
                },
                {
                    name: "Influential",
                    strategies: "",
                    band_description: "",
                    percentile: 89,
                },
                {
                    name: "Leadership",
                    strategies: "",
                    band_description: "",
                    percentile: 67,
                },
            ],
            selfAssessmentScore: 66,
            mindsetScore: 56,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Accomplished level. This means you believe you have the skills to: ",
                points: [
                    "Motivates and inspires others by conveying a compelling vision of what is possible",
                    "Mobilises support for initiatives that have a long-term impact on strategy and direction",
                    "Searches for ways to continually reinforce a sense of collective purpose and achievement",
                    "Represents the organisation with sensitivity and a heightened awareness of future trends",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Leadership mindset tend to:",
                points: [
                    "Show the ability to look over the horizon and identify compelling futures",
                    "Be highly self-aware",
                    "Be optimistic and able to envision the future",
                    "Appreciate trends or major factors shaping technology, markets, and strategic direction",
                    "Have a strong ability to inspire others",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Champion an organisational culture that inspires commitment and people engagement as a priority. Establish a Culture Leadership Forum or community within your organisation where leaders can share best practices and insights on creating an inspiring culture.",
                            "Shape strategy and champion new opportunities for growth. Collaborate with senior leadership to develop and refine the organisation's strategic vision and growth initiatives.",
                            "Harness capability to deliver on purpose, strategic goals, and values. Identify and develop talent within your organisation to ensure alignment with the strategic direction.",
                            "Investigate the organisation's future by continually re-evaluating the environment, trends, risks, and ways of working. Encourage leaders to participate in ongoing environmental scanning activities and share insights.",
                            "Organise executive leadership forums meetings where senior leaders can engage in deep discussions about culture, strategy, and the future of the organisation. Use these forums to foster collective learning and strategic alignment.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Establish feedback mechanisms within your leadership team to collect input on your culture leadership efforts and strategic visioning. Use this feedback to refine your leadership approach.",
                            "Engage in coaching sessions focused on advanced leadership in culture development and strategic visioning. Work with a coach to enhance your capability development strategies.",
                            "Seek mentoring from a seasoned executive leader known for their ability to drive organisational growth and effectively scan the environment for future trends. Learn how to make decisive strategic judgments that align with organisational purpose and values.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Harm Minimization",
                            "Responsible Gambling",
                            "Regulatory Compliance",
                            "Stakeholder Engagement",
                            "Policy Development",
                        ],
                    },
                ],
            },
        },
        {
            name: "Digital & Data",
            description: [
                "Uses digital technology and data safely and securely to undertake workplace tasks, analyse information and improve operational outcomes",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "Mindsets are the deep assumptions and mental attitudes that drive behaviour. As such, mindsets are not just a way of thinking. They shape a person’s:",
                "• Emotional disposition (feelings and moods);",
                "• Cognitive processes (beliefs and attitudes); and",
                "• Behavioural response (actions).",
                "People with adaptive mindsets understand the need for continuous improvement and change; they know that success – personal and collective – relies on working under pressure, developing ways to cope with stress and anxiety, and continually learn from their experience. This means every experience contributes to action for people, teams, and the organisation.",
                "At the heart of developing an adaptive mindset is self-awareness, curiosity, persistence and ‘grit’ so that City of Casey people can keep going when things get tough or uncertain.",
                "Adopting a growth mindset means we not only cope better but actively look for opportunities for learning and growth (Dweck, 2017). People with a growth mindset see failures and challenges as momentary setbacks and opportunities for personal growth, whereas people with a fixed mindset view failure as a demonstration of the limits to their knowledge and ability.",
                "The Adaptive Mindset is a capability that looks forward to the needs of the future workforce and constantly changing environment. Rather than just taking a traditional focus on what a person does, it examines how they think. It seeks to confirm a person’s ability to maintain their curiosity while dealing with disruption, pressure and setbacks in a resilient, positive manner. It is very much about the neurology of how we think and balance mental models that embrace both fixed and growth mindsets. This reinforces the need for workers to not only learn explicit technical knowledge, but also be an innovator where their abilities, intelligence, and talents are developed through experience and informal interactions that may lead to new and better solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Investigative",
                    strategies: "",
                    band_description: "",
                    percentile: 60,
                },
                {
                    name: "Innovative",
                    strategies: "",
                    band_description: "",
                    percentile: 43,
                },
                {
                    name: "Conceptual",
                    strategies: "",
                    band_description: "",
                    percentile: 96,
                },
                {
                    name: "Divergent Thinking",
                    strategies: "",
                    band_description: "",
                    percentile: 78,
                },
            ],
            selfAssessmentScore: 71,
            mindsetScore: 64,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading level. This means you believe you have the skills to: ",
                points: [
                    "Coordinates effort to ensure high level decision makers have access to reliable data dashboards that show patterns, trends and opportunities for improvement",
                    "Champions the execution of digital engagement and cybersecurity strategic responses across all operations",
                    "Ensures organisational governance of digital information and technology adheres to appropriate policies, processes and VPS standards",
                    "Seeks expert intelligence on trends on relevant digital technologies (e.g. AI, cloud computing) and data management approaches that may affect future operational, stakeholder or regulatory outcomes",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Digital & Data and Influence mindset tend to: ",
                points: [
                    "Be prepared to learn and adopt digital technology and data",
                    "Actively explore new ways to deploy or use digital technology and data",
                    "Go out of their way to learn how to use technology to improve life and work",
                    "Use data and insights to identify operational problems",
                    "Embrace the use of digital technology to make linkages between seemingly unrelated data",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Coordinate efforts to ensure that high-level decision-makers have access to reliable data dashboards showing patterns, trends, and opportunities for improvement. Share insights derived from these dashboards with your leadership team.",
                            "Champion the execution of digital engagement and cybersecurity strategic responses across all operations. Promote a cybersecurity-conscious culture at the organisational level.",
                            "Ensure that organisational governance of digital information and technology adheres to appropriate policies, processes, and VPS (Victorian Public Service) standards. Collaborate with relevant stakeholders to improve governance practices.",
                            "Seek expert intelligence on trends in relevant digital technologies and data management approaches that may affect future operational, stakeholder, or regulatory outcomes. Share this intelligence with your leadership team.",
                            "Create a Digital Leadership Forum or community. Invite senior leaders, and cross-functional experts discuss emerging trends in digital and data management approaches. Encourage open dialogue and knowledge sharing to stay ahead of industry advancements and their potential impact on future operational, stakeholder, or regulatory outcomes.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Establish feedback mechanisms within your leadership team to collect input on data dashboard coordination and cybersecurity strategies. Use this feedback to refine your leadership approach.",
                            "Seek mentoring from a digital governance expert who can guide you in aligning governance practices with organisational policies and VPS standards.",
                            "Engage in coaching sessions focused on advanced leadership in digital engagement and cybersecurity. Work with a coach to develop strategies for promoting data-driven decision-making at the organisational level.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Digital Transformation",
                            "Data Analysis / Data Visualisation",
                            "Data Integrity and Validation",
                            "Data Privacy and Security",
                            "Cybersecurity",
                        ],
                    },
                ],
            },
        },
        {
            name: "Accountability & Outcomes",
            description: [
                "Holds self and others accountable for delivery of priority organisational and regulatory outcomes that improve outcomes for all Victorians",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "Mindsets are the deep assumptions and mental attitudes that drive behaviour. As such, mindsets are not just a way of thinking. They shape a person’s:",
                "• Emotional disposition (feelings and moods);",
                "• Cognitive processes (beliefs and attitudes); and",
                "• Behavioural response (actions).",
                "People with adaptive mindsets understand the need for continuous improvement and change; they know that success – personal and collective – relies on working under pressure, developing ways to cope with stress and anxiety, and continually learn from their experience. This means every experience contributes to action for people, teams, and the organisation.",
                "At the heart of developing an adaptive mindset is self-awareness, curiosity, persistence and ‘grit’ so that City of Casey people can keep going when things get tough or uncertain.",
                "Adopting a growth mindset means we not only cope better but actively look for opportunities for learning and growth (Dweck, 2017). People with a growth mindset see failures and challenges as momentary setbacks and opportunities for personal growth, whereas people with a fixed mindset view failure as a demonstration of the limits to their knowledge and ability.",
                "The Adaptive Mindset is a capability that looks forward to the needs of the future workforce and constantly changing environment. Rather than just taking a traditional focus on what a person does, it examines how they think. It seeks to confirm a person’s ability to maintain their curiosity while dealing with disruption, pressure and setbacks in a resilient, positive manner. It is very much about the neurology of how we think and balance mental models that embrace both fixed and growth mindsets. This reinforces the need for workers to not only learn explicit technical knowledge, but also be an innovator where their abilities, intelligence, and talents are developed through experience and informal interactions that may lead to new and better solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Responsibility",
                    strategies: "",
                    band_description: "",
                    percentile: 60,
                },
                {
                    name: "Commitment",
                    strategies: "",
                    band_description: "",
                    percentile: 43,
                },
                {
                    name: "Diligence",
                    strategies: "",
                    band_description: "",
                    percentile: 96,
                },
                {
                    name: "Goal Oriented",
                    strategies: "",
                    band_description: "",
                    percentile: 78,
                },
            ],
            selfAssessmentScore: 75,
            mindsetScore: 65,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading level. This means you believe you have the skills to: ",
                points: [
                    "Ensures operating and delivery model is designed in ways that create a positive impact on organisational performance and community outcomes",
                    "Fosters a culture of shared accountability and transparency by formulating strategies that are aligned to measurable outcomes",
                    "Demonstrates leadership and influence in driving future results across the organisation",
                    "Establishes processes to report organisational performance and goal attainment",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with an Accountability & Outcomes and Influence mindset tend to: ",
                points: [
                    "Have a conscious sense of urgency",
                    "Be accountable for making things happen",
                    "Be sincere and follows through from words to actions",
                    "Be motivated and move to deliberate action",
                    "Be purposeful and prioritises ruthlessly",
                    "Hold self and others accountable",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Ensure the operating and delivery model is designed to create a positive impact on organisational performance and community outcomes. Share your strategic insights with your leadership team to align everyone's efforts.",
                            "Foster a culture of shared accountability and transparency by formulating strategies that are directly linked to measurable outcomes. Involve your entire leadership team in discussions and decision-making to strengthen alignment.",
                            "Demonstrate leadership and influence in driving future results across the organisation. Research and analyse trends and technological developments that will affect the industry and engage in cross-functional and external collaboration to include and inspire others.",
                            "Establish robust processes to report organisational performance and goal attainment. Use these processes to inform and educate your leadership team, ensuring everyone is aligned with the organisation's vision.",
                            "Encourage a culture of feedback within your leadership team. Regularly seek input on strategies, alignment efforts, and accountability practices to continuously improve.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Create feedback mechanisms for your leadership team to provide input on strategic initiatives and accountability efforts. Use this feedback to refine your approach and strengthen alignment.",
                            "Seek mentoring from a seasoned executive leader known for their results-driven leadership style. Learn how they consider a broad range of external factors, to navigate complex organisational challenges and drive future results. Share your own observations and build collective knowledge.",
                            "Engage in coaching sessions focused on advanced leadership and influence techniques. Work with a coach to enhance your ability to create a culture of shared accountability with the means to continually measure and report outcomes.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Accountability",
                            "High Performing Teams",
                            "Delegation",
                            "Operational Excellence",
                        ],
                    },
                ],
            },
        },
        {
            name: "Decision Making & Judgment",
            version: "1",
            description: [
                "Maintains professional skepticism and independently analyses data to solve problems or make well-reasoned insights that inform judgment and decision making",
                "This is a priority capability for growth! You have potential to unlock by developing these capabilities! You have a natural strength and have the potential, with experience and learning, to perform at a higher level.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Objectivity",
                    strategies: "",
                    band_description: "Objective, unbiased, impartial, open-minded, and inquiring",
                    percentile: 96,
                },
                {
                    name: "Reasoning",
                    strategies: "",
                    band_description: "Analytical, logical, rational, focussed, and well-informed",
                    percentile: 97,
                },
            ],
            selfAssessmentScore: 56,
            mindsetScore: 98,
            currentCapability: {
                title: "Current capability",
                description: "You have self-assessed your current capability at the Accomplished level. This means: ",
                points: [
                    "Thinks and acts with a deep awareness of the consequences for the wider organisation, community, or regulatory context",
                    "Thinks strategically and makes judgements in a decisive manner",
                    "Uses data to confirm, predict or anticipate future problems or emerging risks",
                    "Manages data to better support uplift in operational outcomes (e.g. tracking and reporting, harm monitoring and detection)",
                    "Takes responsibility for making hard business decisions or choices",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Decision Making & Judgment mindset tend to: ",
                points: [
                    "Be able to focus on essential elements of a problem",
                    "Balance data and facts before making a judgement",
                    "Reason logically and formulate clear options",
                    "Be objective and act on facts not emotional responses",
                    "Make linkages between seemingly unrelated but important issues or information",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Build capabilities and allocate resources that facilitate faster problem-solving and decision-making at all levels of the organisation. Share your strategies and resources with leadership teams.",
                            "Think pragmatically and work on removing complexity, barriers, and roadblocks that slow down organisational decision-making. Collaborate with colleagues to streamline processes.",
                            "Promote the collection, cleansing and use of data to identify systemic patterns and provide strategic intelligence. Lead initiatives that harness data for better decision support.",
                            "Actively sponsor and support the inclusion of diverse voices, ways of thinking, and experiences in decision processes. Champion diversity in decision-making forums.",
                            "Consider a broad range of stakeholder needs and collaborate across government at senior levels to develop and deliver sustainable solutions. Lead cross-functional teams to address complex challenges.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Establish feedback mechanisms within your leadership teams to collect input on the effectiveness of resource allocation and complexity reduction efforts. Use this feedback to refine your strategies.",
                            "Seek mentoring from experienced leaders known for their ability to promote diversity and inclusion in decision processes. Learn how they leverage diversity for better decision outcomes.",
                            "Engage in coaching sessions focused on advanced leadership and decision-making techniques. Work with a coach to enhance your ability to build resource allocation capabilities and streamline complex decision processes.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Data-Driven Decision Making",
                            "Problem Solving",
                            "Decision Making",
                            "Root Cause Analysis",
                        ],
                    },
                ],
            },
        },
        {
            name: "Stakeholder Relationships & Influence",
            description: [
                "Holds self and others accountable for delivery of priority organisational and regulatory outcomes that improve outcomes for all Victorians",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "Mindsets are the deep assumptions and mental attitudes that drive behaviour. As such, mindsets are not just a way of thinking. They shape a person’s:",
                "• Emotional disposition (feelings and moods);",
                "• Cognitive processes (beliefs and attitudes); and",
                "• Behavioural response (actions).",
                "People with adaptive mindsets understand the need for continuous improvement and change; they know that success – personal and collective – relies on working under pressure, developing ways to cope with stress and anxiety, and continually learn from their experience. This means every experience contributes to action for people, teams, and the organisation.",
                "At the heart of developing an adaptive mindset is self-awareness, curiosity, persistence and ‘grit’ so that City of Casey people can keep going when things get tough or uncertain.",
                "Adopting a growth mindset means we not only cope better but actively look for opportunities for learning and growth (Dweck, 2017). People with a growth mindset see failures and challenges as momentary setbacks and opportunities for personal growth, whereas people with a fixed mindset view failure as a demonstration of the limits to their knowledge and ability.",
                "The Adaptive Mindset is a capability that looks forward to the needs of the future workforce and constantly changing environment. Rather than just taking a traditional focus on what a person does, it examines how they think. It seeks to confirm a person’s ability to maintain their curiosity while dealing with disruption, pressure and setbacks in a resilient, positive manner. It is very much about the neurology of how we think and balance mental models that embrace both fixed and growth mindsets. This reinforces the need for workers to not only learn explicit technical knowledge, but also be an innovator where their abilities, intelligence, and talents are developed through experience and informal interactions that may lead to new and better solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Cooperation",
                    strategies: "",
                    band_description: "Cooperative, collaborative, thoughtful, considerate, and open-minded ",
                    percentile: 90,
                },
                {
                    name: "Considerate",
                    strategies: "",
                    band_description: "",
                    percentile: 56,
                },
                {
                    name: "Respect",
                    strategies: "",
                    band_description: "",
                    percentile: 43,
                },
            ],
            selfAssessmentScore: 79,
            mindsetScore: 69,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading level. This means you believe you have the skills to: ",
                points: [
                    "Forges connections that build enduring long-term strategic alliances and partnerships",
                    "Identifies and manages a range of complex and often competing stakeholder needs",
                    "Anticipates how changing regulatory and government priorities may impact various stakeholder groups and manages these relationships accordingly",
                    "Guides and mediates the alignment of the organisation’s culture and values with stakeholder and community expectations",
                    "Secures support from stakeholders to inform and support future plans",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Stakeholder Relationships & Influence tend to: ",
                points: [
                    "Feel comfortable engaging with others and building purposeful relationships",
                    "Employ a range of different influencing styles effectively",
                    "Value having long-term close relationships with people",
                    "Seek to ensure mutual benefit from relationships",
                    "Initiate and reach out to people",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Actively build and participate in strategic alliances and partnerships that build enduring, long-term relationships. Share strategies for forging connections that extend beyond immediate needs.",
                            "Foster discussions and exercises on managing a range of complex and often competing stakeholder needs. Encourage leaders to develop advanced skills in stakeholder management.",
                            "Track and provide proactive guidance on how changing regulatory and government priorities may impact various stakeholder groups or services to those groups. Encourage proactive relationship management in response to these changes.",
                            "Host workshops on guiding and mediating the alignment of the organisation's culture and values with stakeholder and community expectations. Share strategies for securing support from stakeholders to inform and support plans and approaches.",
                            "Encourage leaders to explore advanced techniques for garnering stakeholder commitment and how to identify and influence key decision-makers in all contexts.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Coach emerging leaders on building strategic alliances, managing complex stakeholder needs, and mediating alignment with stakeholder expectations. Share your experiences in advanced stakeholder relationship management.",
                            "Provide feedback to colleagues and team members on their strategic relationship-building efforts and alignment initiatives. Offer insights for achieving long-term strategic goals through stakeholder engagement.",
                            "Mentor individuals seeking to excel in leadership roles by developing advanced skills in stakeholder influence and relationship management. Share your expertise in securing support from diverse stakeholders and navigating complex stakeholder landscapes.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Stakeholder Engagement",
                            "Influencing",
                            "Relationship Building",
                            "Advocacy",
                        ],
                    },
                ],
            },
        },
        {
            name: "Systems Thinking",
            version: "1",
            description: [
                "Considers future needs and the wider system context and components to better inform choices or actions",
                "Focus on developing this capability! You have potential to unlock by developing these capabilities! You have a natural strength and have the potential, with experience and learning, to perform at a higher level. ",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Strategic",
                    strategies: "",
                    band_description: "",
                    percentile: 76,
                },
                {
                    name: "Vision",
                    strategies: "",
                    band_description: "",
                    percentile: 96,
                },
                {
                    name: "Future Focussed",
                    strategies: "",
                    band_description: "",
                    percentile: 97,
                },
            ],
            selfAssessmentScore: 45,
            mindsetScore: 56,
            currentCapability: {
                title: "Current capability",
                description: "You have self-assessed your current capability at the Accomplished level. This means: ",
                points: [
                    "Diagnoses trends, obstacles and opportunities in the internal and external environment that may affect personal and collective objectives",
                    "Coaches others in using systems thinking to solve problems and create solutions",
                    "Appreciates the future consequences of not improving current thinking and practices",
                    "Ensures teams communicate and share lessons learnt from previous mistakes or errors when choices had negative consequences for the business or stakeholders",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Systems Thinking mindset tend to: ",
                points: [
                    "Think strategically and know the core business goals and priorities",
                    "Plan and reinforce actions that reinforce the organisation’s values",
                    "Ensure the organisation has the capacity and capability to implement plans and achieve the goals",
                    "Have a clear strategic perspective that recognises limitations",
                    "Fully appreciate the future strategic elements and their interrelationship when planning or building capability",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Diagnose trends, obstacles, and opportunities in the internal and external environment. Share your findings with your team to enhance awareness of what may affect personal and collective objectives.",
                            "Coach others in using systems thinking to solve problems and create solutions. Provide guidance and mentorship to team members on applying systems thinking principles.",
                            "Appreciate the future consequences of not improving current thinking and practices. Engage in discussions with colleagues about the potential risks of not evolving with changing circumstances.",
                            "Ensure that teams communicate and share lessons learned from previous mistakes or errors when choices had negative consequences for the business or stakeholders. Encourage a culture of learning from past experiences by adding this as an agenda item to team meetings.",
                            "Organise and facilitate scenario planning workshops for your team or department. Encourage participants to use systems thinking to diagnose trends and explore possible future scenarios.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Create feedback mechanisms within your team to collect input for systems thinking efforts. Use this feedback to refine your own coaching approach and enhance team members' systems thinking skills.",
                            "Seek mentoring from a seasoned leader known for their systems thinking expertise. Learn how to diagnose complex trends and obstacles and how to foster systems thinking at a higher level.",
                            "Engage in coaching sessions focused on advanced coaching techniques for systems thinking. Work with a coach to refine your ability to foster a culture of learning from mistakes and applying systems thinking at a collective level.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Systems Thinking",
                            "Environmental Analysis",
                            "Big Picture Thinking",
                            "Holistic Problem-Solving",
                            "Systems Theory",
                        ],
                    },
                ],
            },
        },
        {
            name: "Social Responsibility & Harm Minimisation",
            description: [
                "Promotes the prevention of harm and acts to support legislation, broader societal expectations and efforts by the VGCCC to enforce acceptable behaviour and practices",
                "Your results indicate that you are already utilising your innate abilities however doing work that calls on this capability may not come as naturally to you.  It may be possible to develop your mindset and skills with effort",
            ],
            longerDescription: [
                "Mindsets are the deep assumptions and mental attitudes that drive behaviour. As such, mindsets are not just a way of thinking. They shape a person’s:",
                "• Emotional disposition (feelings and moods);",
                "• Cognitive processes (beliefs and attitudes); and",
                "• Behavioural response (actions).",
                "People with adaptive mindsets understand the need for continuous improvement and change; they know that success – personal and collective – relies on working under pressure, developing ways to cope with stress and anxiety, and continually learn from their experience. This means every experience contributes to action for people, teams, and the organisation.",
                "At the heart of developing an adaptive mindset is self-awareness, curiosity, persistence and ‘grit’ so that City of Casey people can keep going when things get tough or uncertain.",
                "Adopting a growth mindset means we not only cope better but actively look for opportunities for learning and growth (Dweck, 2017). People with a growth mindset see failures and challenges as momentary setbacks and opportunities for personal growth, whereas people with a fixed mindset view failure as a demonstration of the limits to their knowledge and ability.",
                "The Adaptive Mindset is a capability that looks forward to the needs of the future workforce and constantly changing environment. Rather than just taking a traditional focus on what a person does, it examines how they think. It seeks to confirm a person’s ability to maintain their curiosity while dealing with disruption, pressure and setbacks in a resilient, positive manner. It is very much about the neurology of how we think and balance mental models that embrace both fixed and growth mindsets. This reinforces the need for workers to not only learn explicit technical knowledge, but also be an innovator where their abilities, intelligence, and talents are developed through experience and informal interactions that may lead to new and better solutions.",
            ],
            icon: "",
            subCategories: [
                {
                    name: "Morality",
                    strategies: "",
                    band_description: "",
                    percentile: 78,
                },
                {
                    name: "Risk Taking",
                    strategies: "",
                    band_description: "",
                    percentile: 43,
                },
                {
                    name: "Open Minded",
                    strategies: "",
                    band_description: "",
                    percentile: 78,
                },
                {
                    name: "Openness to Change",
                    strategies: "",
                    band_description: "",
                    percentile: 45,
                },
            ],
            selfAssessmentScore: 74,
            mindsetScore: 61,
            currentCapability: {
                title: "Current capability",
                description:
                    "You have self-assessed your current capability at the Leading level. This means you believe you have the skills to: ",
                points: [
                    "Monitors and ensures organisational policies and procedures effectively meet the VGCCC and the Commission’s legislative and social objectives with regards to responsible gambling practices",
                    "Ensures the VGCCC’s regulatory responses are proportionate to the risks posed and harm caused by industry participants' failure to take responsibility for preventing gambling harm",
                    "Monitors and ensures harm minimisation requirements are integrated into all the VGCCC’s decision-making processes",
                    "Possesses the courage to decisively deal with any gambling providers that have harmful offerings, breach their duty of care, or do not comply with their legal obligations",
                    "Adopts and embeds leading risk-based and intelligence-led practices to identify and minimise where harm exists or might reasonably be suspected",
                    "Implements zero tolerance for those who opportunistically or deliberately contravene their obligations to protect the community from gambling harm",
                ],
            },
            mindset: {
                title: "Mindset",
                description: "People with a Social Responsibility and Harm Minimisation mindset tend to: ",
                points: [
                    "Be able to modify personal actions with regard to possible environmental consequences",
                    "Tightly associates business objectives with social and environmental priorities",
                    "Appreciate the link between changing consumer expectations and product design",
                    "Maintains a conscious awareness of the need to balance environmental and business needs",
                    "Actively seeks to find ways to reduce waste and save time, materials or resources",
                    "Acts as a role model for habits and behaviors that are sustainable",
                ],
            },
            nextSteps: {
                title: "Next steps & suggestions",
                description: "Here are some tips for developing your capability:",
                suggestions: [
                    {
                        description: "Informal - Work Based - On the job:",
                        points: [
                            "Conduct in-depth data analysis to effectively prioritise harm prevention. This involves acquiring the skills to gather substantial evidence that supports robust harm reduction strategies.",
                            "Obtain hands-on experience by interacting directly with industry participants to actively promote harm minimisation principles and to cultivate a positive influence on stakeholders.",
                            "Take responsibility for enforcing licensing regulations, policies, and harm minimisation principles. Collaborate closely with gambling providers to ensure the well-being of stakeholders and the community.",
                            "Actively enforce regulatory requirements and address non-compliant practices. Use your position to make a meaningful impact, sharing vital information with peers regarding non-compliant environments or products.",
                            "Collaborate on projects aimed at enhancing regulatory systems for more effective harm minimisation. This hands-on experience provides practical insights into regulatory improvements.",
                        ],
                    },
                    {
                        description: "Coaching - Mentoring - Feedback:",
                        points: [
                            "Collaborate with colleagues on intelligence gathering initiatives related to harm minimisation. Learn how to analyse data and gather insights to inform strategies and policies effectively.",
                            "Work with colleagues to navigate political priorities and community perceptions of harm caused by gambling. Gain exposure to the complex landscape of political decision-making and community engagement.",
                            "Receive coaching on the Commission's legislative and social objectives related to responsible gambling practices. This coaching will help you align your leadership efforts with the organisation's mission and values.",
                        ],
                    },
                    {
                        description: "Formal Learning - Training - On the Job:",
                        points: [
                            "Identify formal learning or training programs externally or on the VGCCC Nexus LMS. Look for programs with the following themes",
                            "Harm Minimization",
                            "Responsible Gambling",
                            "Regulatory Compliance",
                            "Stakeholder Engagement",
                            "Policy Development",
                        ],
                    },
                ],
            },
        },
    ],
};
