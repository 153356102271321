import { useContext, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import { BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../../../utils/constants";

import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QuestionsDataChart from "../../ChartCards/QuestionsDataChart";

export default function PerDimensionRegional(props) {
    const { displayOnlyContent, data } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const context = useContext(Context);

    let tabList = [];
    let tabContent = [];

    let currentPerDimensionData;

    if (data) {
        currentPerDimensionData = data;
    } else {
        currentPerDimensionData = context.aceSurveyReportData.sixDimensions;
    }

    currentPerDimensionData.map(
        (dimension) =>
            tabList.push(dimension.name) &&
            tabContent.push({
                content: (
                    <div className="flex flex-col gap-4">
                        <QuestionsDataChart
                            sixDimensions
                            data={
                                dimension.regionalData ? dimension.data.concat(dimension.regionalData) : dimension.data
                            }
                            title={dimension.name}
                            colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                            emphasiseOrganisationCategory
                            infoBubble
                            onlyBubbleOnFirstBarOnPrint
                        />
                    </div>
                ),
                readonly: true,
            })
    );

    return (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            tabContent={tabContent}
            tabsRowOnly
            secondaryTabs
            gapLessTabsAndContent
            noContainer
            displayOnlyContent={displayOnlyContent}
            noTitle
        />
    );
}
