import { useContext } from "react";

import { Context } from "../../../../../../../context/Context";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../../../../utils/constants";

import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import SatisfactionScaleVerticalScores from "../ChartCards/SatisfactionScaleVerticalScores";
import BouncingBalls from "../../../../../../SharedComponents/Loading/BouncingBalls";
import AceReportPicker from "./AceReportPicker";

export default function FollowThrough() {
    return (
        <DashboardLayout
            title="Follow through"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <FollowThroughElements />
        </DashboardLayout>
    );
}

export function FollowThroughElements() {
    const context = useContext(Context);

    return context.aceSurveyReportDataLoading ? (
        <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
            <BouncingBalls />
        </div>
    ) : (
        <SatisfactionScaleVerticalScores data={context.aceSurveyReportData.followThrough[0].items[0]} />
    );
}
