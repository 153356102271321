import { useState } from "react";
import { ArrowDownTrayIcon, ChevronRightIcon, DocumentMagnifyingGlassIcon } from "@heroicons/react/24/solid";
import mime from "mime";

import { downloadReportByVersion } from "../../../../../utils/api";
import { BUTTON_VARIANT_TYPES } from "../../../../../utils/constants";

import SubsequentFileRow from "./SubsequentFileRow";
import Button from "../../../../SharedComponents/Button";
import { useNavigate } from "react-router-dom";

export default function ReportVersionRow(props) {
    const { item: report, setAlert } = props;
    const navigate = useNavigate();
    const [showSubsequentFiles, setShowSubsequentFiles] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const handleShowSubsequentFiles = () => {
        setShowSubsequentFiles((fileOpen) => !fileOpen);
    };

    const sanitizedSubsequentFiles = report?.subsequentFiles.map((subsequentFile) => ({
        ...subsequentFile,
        documentIdentifier: report?.documentIdentifier,
    }));

    const handleDownload = async (documentResponse, fileType) => {
        fileType = documentResponse.fileType ? documentResponse.fileType : fileType;
        const mimeType = mime.getType(fileType);

        if (!mimeType) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const blob = await (await fetch(`data:${mimeType};base64,${documentResponse.content}`)).blob();

        if (!blob) {
            setAlert("Sorry there was an error, try again later or contact techsupport@psychpress.com.au");
            setDownloading(false);
            return;
        }

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob, { type: mimeType });
        downloadLink.download = documentResponse.fileName.split(".").join("_");
        downloadLink.click();
    };

    const handleDownloadVersion = async (documentIdentifier, versionNumber, fileType) => {
        setDownloading(true);

        try {
            const documentResponse = await downloadReportByVersion(documentIdentifier, versionNumber);
            handleDownload(documentResponse, fileType);
        } catch (err) {
            setAlert(err.message);
        }

        setDownloading(false);
    };

    return (
        <>
            <tr>
                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"flex flex-row gap-3 items-center"}>
                        <div className={"text-xs text-slate-500 "}>{report.versionNumber}</div>
                    </div>
                </td>

                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"flex flex-row gap-3 items-center"}>
                        <div className={"text-xs text-slate-500 "}>
                            {new Date(report.reportDate).toLocaleDateString()}
                        </div>
                    </div>
                </td>

                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"flex flex-row gap-3 items-center"}>
                        <div className={"text-xs text-slate-500 "}>{report?.fileFormat.toUpperCase()}</div>
                    </div>
                </td>

                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"flex flex-row gap-2 items-center"}>
                        {report.fileFormat === "JSON" || report.fileFormat === "json" ? (
                            <Button
                                iconPositionLeft
                                icon={<DocumentMagnifyingGlassIcon className="block h-4 w-4 mr-2" />}
                                colour={BUTTON_VARIANT_TYPES.UNCOLOURED}
                                text="View"
                                extraStyling="shadow-none text-primary-600 p-0 text-xs focus:ring-0 focus:transparent hover:text-primary-700"
                                loading={downloading}
                                loadingIconColour="text-primary"
                                onClick={() =>
                                    navigate("/view-report", {
                                        state: {
                                            userName: report.userName,
                                            reportName: report.name,
                                            reportIdentifier: report.identifier,
                                        },
                                    })
                                }
                            />
                        ) : (
                            <Button
                                iconPositionLeft
                                icon={<ArrowDownTrayIcon className="block stroke-current stroke-1 h-3.5 w-3.5 mr-2" />}
                                colour={BUTTON_VARIANT_TYPES.UNCOLOURED}
                                text="Download"
                                extraStyling="shadow-none text-primary-600 p-0 text-xs focus:ring-0 focus:transparent hover:text-primary-700"
                                loading={downloading}
                                loadingIconColour="text-primary"
                                onClick={() =>
                                    handleDownloadVersion(
                                        report.documentIdentifier,
                                        report.versionNumber,
                                        report.fileFormat
                                    )
                                }
                            />
                        )}
                    </div>
                </td>

                <td className="px-4 py-3 whitespace-nowrap">
                    <div className={"flex flex-row gap-2 items-center"}>
                        <Button
                            colour={BUTTON_VARIANT_TYPES.SECONDARY}
                            icon={
                                <ChevronRightIcon
                                    className={
                                        "h-3 w-3 stroke-current stroke-1 transition-transform transform " +
                                        (showSubsequentFiles && "rotate-90")
                                    }
                                />
                            }
                            extraStyling={"text-xs h-6 w-6 rounded-full p-0 items-center flex justify-center"}
                            iconPositionLeft
                            onClick={handleShowSubsequentFiles}
                        />
                    </div>
                </td>
            </tr>

            {showSubsequentFiles && (
                <tr className="bg-slate-100">
                    <td colSpan={5} className="bg-slate-100">
                        <div className="flex justify-center">
                            {report.subsequentFiles.length !== 0 ? (
                                <div className="overflow-hidden border-none w-full">
                                    <table className="w-full">
                                        <thead className="bg-slate-100">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className={
                                                        "px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase " +
                                                        ""
                                                    }
                                                >
                                                    Additional File Name
                                                </th>

                                                <th
                                                    scope="col"
                                                    className={
                                                        "px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase " +
                                                        ""
                                                    }
                                                >
                                                    File Type
                                                </th>

                                                <th
                                                    scope="col"
                                                    className={
                                                        "px-4 py-3 text-left text-xs font-medium text-slate-500 uppercase " +
                                                        ""
                                                    }
                                                >
                                                    Download
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody className="bg-white">
                                            {sanitizedSubsequentFiles.map((subsequentFile, index) => (
                                                <SubsequentFileRow
                                                    key={index}
                                                    subsequentFile={subsequentFile}
                                                    setAlert={setAlert}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className={"px-2 py-3 text-slate-500 text-xs "}>
                                    This version of the document has no additional files
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}
