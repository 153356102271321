import React from "react";

import DashboardLayout from "../../../SharedComponents/DashboardLayout";
import BarGraphPositiveNegative from "./Charts/RechartCharts/BarGraphPositiveNegative";

export default function SummaryReport() {
    return (
        <DashboardLayout title={"Coachee Summary"} requiredPermissions={[]}>
            <div className={"grid grid-cols-10 gap-8"}>
                <div className={"col-span-full md:col-span-5"}>
                    <h2 className="text-lg text-primary-600 tracking-tight">Summary Report</h2>
                    <div className={"p-3 bg-white rounded-xl shadow-sm mt-4"} style={{ height: "945px" }}>
                        <BarGraphPositiveNegative />
                    </div>
                </div>
                <div className={"col-span-5 md:col-span-5"}>
                    <h2 className="text-lg text-primary-600 tracking-tight">Competencies</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mt-4">
                        <div className={"bg-rose-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-lg"}>Visionary</div>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Vision</li>
                                <li>Conceptual</li>
                                <li>Dedication</li>
                                <li>Open-minded</li>
                                <li>Strategic Orientation</li>
                            </ul>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Vision</li>
                                <li>Conceptual</li>
                                <li>Dedication</li>
                                <li>Open-minded</li>
                                <li>Strategic Orientation</li>
                            </ul>
                        </div>
                        <div className={"bg-pink-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-base"}>Collaborative</div>
                            <div className={"font-medium mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Personal Development</li>
                                <li>Influential</li>
                                <li>Active Leadership</li>
                                <li>Interpersonal Warmth</li>
                                <li>Helpfulness</li>
                                <li>Social Skills</li>
                            </ul>
                            <div className={"font-medium text-base mt-2 col-span-1"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Humility</li>
                                <li>Excitement</li>
                            </ul>
                        </div>
                        <div className={"bg-fuchsia-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-base"}>Influential</div>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Teamwork</li>
                                <li>Liveliness</li>
                                <li>Networking</li>
                                <li>Compassion</li>
                                <li>Openness to Change</li>
                            </ul>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Self-Assuredness</li>
                                <li>Self-Controlled</li>
                            </ul>
                        </div>
                        <div className={"bg-purple-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-base"}>Entrepreneurial</div>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Sincerity</li>
                                <li>Trust</li>
                                <li>Communication</li>
                                <li>Self Discipline</li>
                                <li>Resilience</li>
                            </ul>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Approachability</li>
                                <li>Candour</li>
                                <li>Securedness</li>
                            </ul>
                        </div>
                        <div className={"bg-primary-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-base"}>Personal Development</div>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Influential</li>
                                <li>Active Leadership</li>
                                <li>Interpersonal Warmth</li>
                                <li>Helpfulness</li>
                                <li>Social Skills</li>
                            </ul>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Humility</li>
                                <li>Excitement</li>
                            </ul>
                        </div>
                        <div className={"bg-secondary-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-base"}>Delivers</div>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Sincerity</li>
                                <li>Trust</li>
                                <li>Communication</li>
                                <li>Self Discipline</li>
                                <li>Resilience</li>
                            </ul>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Approachability</li>
                                <li>Candour</li>
                                <li>Securedness</li>
                            </ul>
                        </div>
                        <div className={"bg-cyan-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-base"}>Policy</div>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Influential</li>
                                <li>Active Leadership</li>
                                <li>Interpersonal Warmth</li>
                                <li>Helpfulness</li>
                                <li>Social Skills</li>
                            </ul>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Humility</li>
                                <li>Excitement</li>
                            </ul>
                        </div>
                        <div className={"bg-teal-600 text-white p-3 rounded-xl text-sm"}>
                            <div className={"font-medium text-base"}>Overall</div>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Drivers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Sincerity</li>
                                <li>Trust</li>
                                <li>Communication</li>
                                <li>Self Discipline</li>
                                <li>Resilience</li>
                            </ul>
                            <div className={"font-medium text-base mt-2"}>
                                <u>Derailers</u>
                            </div>
                            <ul className={"mt-2"}>
                                <li>Approachability</li>
                                <li>Candour</li>
                                <li>Securedness</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
