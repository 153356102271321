import { AnimatePresence, motion } from "framer-motion";
import { ChevronRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../utils/constants";

import Button from "./Button";
import { useEffect, useState } from "react";

export default function CollapsibleCard(props) {
    const {
        icon,
        title,
        content,
        backgroundColour,
        titleTextColour,
        borderColour,
        buttonVariant,
        sneakPeek,
        noContainer,
        alwaysShow,
        extraStyling,
    } = props;

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (alwaysShow) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [alwaysShow, setShow]);

    function handleSetShow() {
        if (show) {
            setShow(false);
        } else {
            setShow(true);
        }
    }

    return (
        <div
            className={
                !noContainer
                    ? "p-3 rounded-xl border shadow-sm h-max " +
                      (backgroundColour ? backgroundColour : "bg-slate-50") +
                      " " +
                      (borderColour ? borderColour : "border-slate-100") +
                      " " +
                      extraStyling
                    : ""
            }
        >
            {(title || sneakPeek) && (
                <div className="w-full flex justify-between">
                    <div className="flex flex-row gap-2 items-center justify-between w-full">
                        {title && (
                            <div
                                className={
                                    "flex flex-row gap-2 items-center font-medium text-base w-max " +
                                    (titleTextColour ? titleTextColour : "text-primary-600")
                                }
                            >
                                {icon}
                                {title}
                            </div>
                        )}
                        {!sneakPeek && !alwaysShow && (
                            <Button
                                colour={buttonVariant ? buttonVariant : BUTTON_VARIANT_TYPES.PRIMARY}
                                icon={
                                    <ChevronRightIcon
                                        className={
                                            "h-3 w-3 stroke-current stroke-2 transition-transform transform " +
                                            (show && "rotate-90")
                                        }
                                    />
                                }
                                extraStyling={
                                    "shrink-0 text-xs h-6 w-6 rounded-full p-0 items-center flex justify-center"
                                }
                                iconPositionLeft
                                onClick={() => handleSetShow()}
                            />
                        )}
                    </div>
                </div>
            )}
            {sneakPeek && sneakPeek}
            <AnimatePresence>
                {show && (
                    <motion.section
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                            open: {
                                opacity: 1,
                                height: "auto",
                            },
                            closed: { opacity: 0, height: 0 },
                        }}
                        transition={{ type: "tween", duration: 0.4, stiffness: 100 }}
                    >
                        {content}
                    </motion.section>
                )}
            </AnimatePresence>
            {sneakPeek && !alwaysShow && (
                <div className="w-full flex justify-between print:hidden">
                    <div className={"flex flex-row gap-2 items-center justify-between w-full mt-3"}>
                        <Button
                            text={show ? "Show less" : "Show more"}
                            colour={buttonVariant ? buttonVariant : BUTTON_VARIANT_TYPES.UNCOLOURED_BUTTON}
                            icon={
                                show ? (
                                    <MinusIcon className={"h-3 w-3 stroke-current stroke-1 ml-1.5"} />
                                ) : (
                                    <PlusIcon className={"h-3 w-3 stroke-current stroke-2 ml-1.5"} />
                                )
                            }
                            extraStyling={"text-primary-600 shadow-none p-0 text-sm font-semibold items-center"}
                            onClick={() => handleSetShow()}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
