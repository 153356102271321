import { GlobeAltIcon, PlusIcon, TrashIcon, WindowIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../../../utils/constants";

import Button from "../../../../../SharedComponents/Button";
import Input from "../../../../../SharedComponents/Form/Input";

function AddLinks(props) {
    const { itemName, links, setLinks, readOnly } = props;

    const handleEditLink = (key, value, index) => {
        const newLinks = [...links];

        newLinks[index][key] = value;

        setLinks(newLinks);
    };

    const handleAddLink = () =>
        setLinks([
            ...links,
            {
                name: "",
                url: "",
            },
        ]);

    const handleDeleteLink = (item) => setLinks(links.filter((link) => link !== item));

    return (
        <>
            <div className={"grid grid-flow-row gap-6"}>
                {links.length === 0 ? (
                    <div className={"p-3.5 bg-slate-50 rounded-xl  border border-slate-100"}>
                        <div className={"text-slate-400 text-xs italic"}>No resource link has been added</div>
                    </div>
                ) : (
                    links.map((link, index) => (
                        <div
                            key={index}
                            className={"flex flex-col gap-2 p-3.5 bg-slate-50  border border-slate-100 rounded-xl"}
                        >
                            <div className={"grid grid-flow-row lg:grid-cols-2 gap-6 "}>
                                <Input
                                    id="name"
                                    type="text"
                                    label="Name"
                                    placeholder={"Link name"}
                                    icon={<WindowIcon className="h-4 w-4 text-slate-500" />}
                                    customOnChange={(e) => handleEditLink("name", e.target.value, index)}
                                    currentValue={link.name}
                                    backgroundColour="bg-white"
                                    disabled={readOnly}
                                    valueOnly
                                    optional
                                />

                                <Input
                                    id="url"
                                    type="text"
                                    label="URL"
                                    placeholder={"URL"}
                                    icon={<GlobeAltIcon className="h-4 w-4 text-slate-500" />}
                                    customOnChange={(e) => handleEditLink("url", e.target.value, index)}
                                    currentValue={link.url}
                                    backgroundColour={"bg-white"}
                                    disabled={readOnly}
                                    valueOnly
                                />
                            </div>

                            {!readOnly && (
                                <div className="flex flex-row items-center gap-5 self-end pb-1 pt-3 lg:pt-0">
                                    <Button
                                        colour={BUTTON_VARIANT_TYPES.RED}
                                        text="Delete link"
                                        icon={<TrashIcon className="h-3 w-3 mr-1" />}
                                        loadingIconSize="h-3 w-3 mr-1"
                                        iconPositionLeft
                                        onClick={() => handleDeleteLink(link)}
                                        disabled={false}
                                        smallButton
                                    />
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>

            {!readOnly && (
                <div className={"w-full text-right col-span-2 mt-6"}>
                    <Button
                        colour={BUTTON_VARIANT_TYPES.SECONDARY}
                        text={"Add " + itemName.toLowerCase()}
                        icon={<PlusIcon className="block h-3 w-3 stroke-current stroke-1 mr-1" />}
                        iconPositionLeft
                        smallButton
                        onClick={handleAddLink}
                    />
                </div>
            )}
        </>
    );
}

export default AddLinks;
