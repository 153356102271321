import { useContext, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";

import { Context } from "../../../context/Context";
import { checkIfArrayIncludesContentsOfArray } from "../../../utils/utils";

import MenuItem from "./MenuItem";

export default function MenuSection(props) {
    const { menuSection, mobile } = props;

    const context = useContext(Context);

    let numberOfActiveLinks = 0;

    menuSection.links.forEach((menuItem) => {
        if (
            !menuItem.shadowLink &&
            !menuItem.hidden &&
            checkIfArrayIncludesContentsOfArray(context.userPermissions, menuItem.permissions)
        ) {
            numberOfActiveLinks = numberOfActiveLinks + 1;
        }
    });

    const [expanded, setExpanded] = useState(menuSection.open);

    return (
        <>
            {menuSection.naked ? (
                menuSection.links.length > 0 && (
                    <div className={"grid " + (mobile ? "gap-3" : "gap-3")}>
                        {menuSection.links.map(
                            (link, index) => !link.hidden && <MenuItem mobile={mobile} link={link} key={index} />
                        )}
                    </div>
                )
            ) : numberOfActiveLinks < 3 && numberOfActiveLinks > 0 ? (
                <div className={"flex flex-col gap-3"}>
                    <div
                        className={
                            "font-medium group-hover:text-primary-600 transition-colors " +
                            (expanded ? "text-primary-600" : "text-slate-500")
                        }
                    >
                        {menuSection.name}
                    </div>
                    <div className={"grid " + (mobile ? "gap-3" : "gap-3")}>
                        {menuSection.links.map(
                            (link, index) => !link.hidden && <MenuItem mobile={mobile} link={link} key={index} />
                        )}
                    </div>
                </div>
            ) : (
                <div className={"flex-col " + (numberOfActiveLinks === 0 ? "hidden" : "flex")}>
                    <div
                        className={
                            "flex items-center justify-between group cursor-pointer transition-margin duration-400 " +
                            (expanded ? "mb-4" : "mb-0")
                        }
                        onClick={() => setExpanded((expanded) => !expanded)}
                    >
                        {!context.menuHidden && (
                            <div
                                className={
                                    "font-medium group-hover:text-primary-600 transition-colors " +
                                    (expanded ? "text-primary-600" : "text-slate-500")
                                }
                            >
                                {menuSection.name}
                            </div>
                        )}
                        <ChevronRightIcon
                            className={
                                "w-3.5 h-3.5 group-hover:text-primary-600 transition-transform transform stroke-current stroke-2 " +
                                (expanded ? "rotate-90 text-primary-600" : "text-slate-500")
                            }
                        />
                    </div>
                    <AnimatePresence>
                        {expanded && (
                            <motion.section
                                initial={menuSection.open ? "open" : "closed"}
                                animate="open"
                                exit="closed"
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    closed: { opacity: 0, height: 0 },
                                }}
                                transition={{ type: "tween", duration: 0.4, stiffness: 100 }}
                            >
                                <div className="grid gap-4">
                                    {menuSection.links.map(
                                        (link, index) =>
                                            !link.hidden && <MenuItem mobile={mobile} link={link} key={index} />
                                    )}
                                </div>
                            </motion.section>
                        )}
                    </AnimatePresence>
                </div>
            )}
        </>
    );
}
