import { useContext } from "react";

import { Context } from "../../../../../../../../context/Context";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../../../../../utils/constants";
import { checkIfArrayIncludesContentsOfArray } from "../../../../../../../../utils/utils";

import DashboardLayout from "../../../../../../../SharedComponents/DashboardLayout";
import SingleWithBenchmark from "../../ChartCards/SingleWithBenchmark";
import Completions from "./Completions";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import AceReportPicker from "../AceReportPicker";
import BasicMotion from "../../../../../../../SharedComponents/Motion/BasicMotion";
import Alert, { ALERT_TYPE } from "../../../../../../../SharedComponents/Alert";

export default function Snapshot(props) {
    const { demo } = props;

    const context = useContext(Context);

    return (
        <DashboardLayout title="Snapshot" requiredPermissions={[]} otherElements={[]}>
            <div className="flex flex-col gap-6">
                {checkIfArrayIncludesContentsOfArray(context.userPermissions, [
                    COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS,
                ]) &&
                    context.errorLoadingReports && (
                        <BasicMotion>
                            <Alert
                                text={
                                    <div className={"flex flex-col gap-2"}>
                                        <span className={"text-sm font-medium"}>
                                            Sorry, there was an issue retrieving ACE 2.0 reports
                                        </span>
                                        <div className={"text-sm text-slate-500"}>
                                            Please refresh the page and{" "}
                                            <a
                                                className={"font-semibold underline"}
                                                href="mailto:techsupport@psychpress.com.au"
                                            >
                                                contact us
                                            </a>{" "}
                                            if this error persists.
                                        </div>
                                    </div>
                                }
                                type={ALERT_TYPE.WARNING}
                                active
                                noMargins
                            />
                        </BasicMotion>
                    )}
                {(context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_ASSESSMENT_STATS_REGION) ||
                    context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_COUNTRY_COMPLETIONS)) && (
                    <Completions />
                )}
                {context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS) && (
                    <div className="col-span-4 flex flex-col gap-4 break-inside-avoid-page">
                        <div className="flex flex-col gap-2">
                            <div className={"flex flex-row justify-between items-center"}>
                                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                                    Overview
                                </h2>
                                <AceReportPicker small />
                            </div>
                        </div>
                        {context.aceSurveyReportDataLoading ? (
                            <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                                <BouncingBalls />
                            </div>
                        ) : (
                            <div className="col-span-full flex flex-col gap-8">
                                <SingleWithBenchmark
                                    demo={demo}
                                    withBenchmarks
                                    detailed
                                    data={
                                        context.aceSurveyReportData.generalSatisfaction.length > 0 &&
                                        context.aceSurveyReportData.generalSatisfaction[0].data
                                    }
                                    infoBubble
                                    infoBubblePlacement={"bottom-start"}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </DashboardLayout>
    );
}
