import { useNavigate } from "react-router";
import { CogIcon } from "@heroicons/react/24/solid";

import { BUTTON_VARIANT_TYPES } from "../../../../utils/constants";

import Button from "../../../SharedComponents/Button";

export default function CoachRow(props) {
    const { item: cohort } = props;

    const navigate = useNavigate();

    return (
        <tr className={"hover:bg-slate-100 transition-colors group"}>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm font-medium text-primary-600"}>{cohort.name}</div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <div className={"text-sm text-slate-500 group-hover:text-primary-600 transition-colors"}>
                    {cohort.program}
                </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                {cohort.numUsers !== 0 ? (
                    <div
                        className={
                            "flex items-center justify-center text-xs text-slate-600 font-medium bg-slate-200 " +
                            "h-6 w-6 rounded-full group-hover:text-primary-600 group-hover:bg-primary-100 " +
                            "transition-colors"
                        }
                    >
                        {cohort.numUsers}
                    </div>
                ) : (
                    <span className={"text-xs text-slate-400 italic "}>No members</span>
                )}
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
                <Button
                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                    icon={<CogIcon className="shrink-0 block h-5 w-5" />}
                    extraStyling={"flex items-center justify-center text-xs h-8 w-8 text-white rounded-full p-2"}
                    iconPositionLeft
                    onClick={() => {
                        navigate("/cohort", { state: { cohort: cohort } });
                    }}
                />
            </td>
        </tr>
    );
}
