import { AXIS_LABELS, BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "./constants";

import QualitativeItemSection from "../components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/Qualitative/QualitativeItemSection";
import SingleWithBenchmark from "../components/Dashboard/Content/Reports/ReportContent/ACESurvey/ChartCards/SingleWithBenchmark";
import QuestionsDataChart from "../components/Dashboard/Content/Reports/ReportContent/ACESurvey/ChartCards/QuestionsDataChart";
import AchievementItemVerticalBarChart from "../components/Dashboard/Content/Reports/ReportContent/ACESurvey/ChartCards/AchievementItemVerticalBarChart";
import IndividualFieldManagerComparison from "../components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/FieldManagerSatisfaction/IndividualFieldManagerComparison";
import IndividualFieldManager from "../components/Dashboard/Content/Reports/ReportContent/ACESurvey/Pages/FieldManagerSatisfaction/IndividualFieldManager";

export const checkIfArrayIncludesContentsOfArray = (firstArray, secondArray) => {
    return secondArray.every((item) => {
        return firstArray.includes(item);
    });
};

export function generateLevelNumber(percentage, scaleType) {
    let level = 0;

    if (scaleType === "mindset") {
        if (percentage < 6) {
            level = 1;
        } else if (percentage >= 6 && percentage < 36) {
            level = 2;
        } else if (percentage >= 36 && percentage < 66) {
            level = 3;
        } else if (percentage >= 66) {
            level = 4;
        }
    } else {
        if (percentage <= 38) {
            level = 1;
        } else if (percentage >= 39 && percentage <= 63) {
            level = 2;
        } else if (percentage >= 64 && percentage <= 88) {
            level = 3;
        } else if (percentage > 88) {
            level = 4;
        }
    }

    return level;
}

export function generateProportionalPercentage(percentage, scaleType) {
    let updatedProportionalPercentage = 0;

    if (scaleType === "mindset") {
        if (percentage < 6) {
            updatedProportionalPercentage = Math.floor((percentage / 6) * 25); // Scale 0-6 to 0-25
        } else if (percentage >= 6 && percentage < 36) {
            updatedProportionalPercentage = Math.floor(((percentage - 6) / 30) * 25 + 25); // Scale 6-36 to 25-50
        } else if (percentage >= 36 && percentage < 66) {
            updatedProportionalPercentage = Math.floor(((percentage - 36) / 30) * 25 + 50); // Scale 36-66 to 50-75
        } else if (percentage >= 66) {
            updatedProportionalPercentage = Math.floor(((percentage - 66) / 34) * 25 + 75); // Scale 66-100 to 75-100
        }
    } else {
        if (percentage <= 38) {
            updatedProportionalPercentage = Math.floor((percentage / 38) * 25); // Scale 0-38 to 0-25
        } else if (percentage >= 39 && percentage <= 63) {
            updatedProportionalPercentage = Math.floor(((percentage - 39) / 24) * 25 + 25); // Scale 39-63 to 25-50
        } else if (percentage >= 64 && percentage <= 88) {
            updatedProportionalPercentage = Math.floor(((percentage - 64) / 24) * 25 + 50); // Scale 64-88 to 50-75
        } else if (percentage > 88) {
            updatedProportionalPercentage = Math.floor(((percentage - 88) / 12) * 25 + 75); // Scale 88-100 to 75-100
        }
    }

    return updatedProportionalPercentage;
}

export function generateBarLevelPercentageBasedOnLevel(level) {
    switch (level) {
        case 1:
            return 25;
        case 2:
            return 50;
        case 3:
            return 75;
        case 4:
            return 100;
        default:
            return 0;
    }
}

export function createFieldManagerTabContent(
    fieldManagersDataCurrent,
    fieldManagersLevelDataCurrent,
    noOpenEndedQuestions,
    displayOnlyContent
) {
    let updatedTabList = [];
    let updatedTabContent = [];
    let fieldManagers = [];
    let fieldManagerComparison = { business: { data: [] }, relationship: { data: [] } };

    fieldManagersDataCurrent.forEach((fieldManagerItem, index) => {
        fieldManagerItem.name === "Text"
            ? !noOpenEndedQuestions &&
              updatedTabList.push("Open-ended questions") &&
              updatedTabContent.push({
                  content: (
                      <div
                          key={index + "openEndedQuestionsSection"}
                          className={
                              "flex flex-col gap-4 w-full " +
                              "print:p-0 print:shadow-none print:border-none print:bg-white"
                          }
                      >
                          <div className="flex flex-col gap-4">
                              {fieldManagerItem.items.map((item, index) => (
                                  <QualitativeItemSection
                                      key={index + "qualitativeItemSection"}
                                      data={item}
                                      title={"Open-ended question #" + (index + 1)}
                                  />
                              ))}
                          </div>
                      </div>
                  ),
                  readonly: true,
              })
            : fieldManagerItem.name !== "Support and Succession Planning"
            ? updatedTabList.push(
                  fieldManagerItem.name === "Business competence"
                      ? "Business capability"
                      : fieldManagerItem.name === "Relationship competence"
                      ? "Relationship capability"
                      : fieldManagerItem.name
              ) &&
              updatedTabContent.push({
                  content: (
                      <div
                          className={
                              "flex flex-col gap-4 print:gap-8 " + (index === 1 ? "print:break-before-page" : "")
                          }
                      >
                          <SingleWithBenchmark
                              infoBubble
                              withBenchmarks
                              detailed
                              data={fieldManagerItem.data}
                              title={
                                  fieldManagerItem.name === "Business competence"
                                      ? "Business capability"
                                      : fieldManagerItem.name === "Relationship competence"
                                      ? "Relationship capability"
                                      : fieldManagerItem.name
                              }
                              axisLabels={AXIS_LABELS.stronglyDissatisfied}
                              extraStyling={index !== 0 ? "print:-mt-4" : ""}
                          />
                          <QuestionsDataChart
                              data={fieldManagerItem.items}
                              title={
                                  fieldManagerItem.name === "Business competence"
                                      ? "Business capability by item level"
                                      : fieldManagerItem.name === "Relationship competence"
                                      ? "Relationship capability by item level"
                                      : fieldManagerItem.name + " by item level"
                              }
                              colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                              noItemTitles
                              topXAxis
                              questions
                              axisLabels={AXIS_LABELS.stronglyDissatisfied}
                              infoBubble
                              noInfoBubbleOnPrint
                              extraStyling={"print:-mt-5 break-before-page"}
                              breakBeforePage
                          />
                      </div>
                  ),
                  readonly: true,
              })
            : updatedTabList.push(fieldManagerItem.name) &&
              updatedTabContent.push({
                  content: (
                      <div
                          key={index + "openEndedQuestionsSection"}
                          className={
                              "flex flex-col gap-4 w-full bg-slate-100 p-4 rounded-xl border border-slate-200 shadow-sm " +
                              "print:p-0 print:shadow-none print:border-none print:bg-white"
                          }
                      >
                          <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                              {fieldManagerItem.name}
                          </h2>
                          <div className="flex flex-col gap-4">
                              {fieldManagerItem.items.map((item, index) => (
                                  <AchievementItemVerticalBarChart
                                      key={index}
                                      data={item}
                                      title={item.name}
                                      noTrendBubble
                                      noBenchmarkAgree
                                      noLegend
                                      noCategories
                                      noTrendLegend
                                      noInfoBubble
                                  />
                              ))}
                          </div>
                      </div>
                  ),
                  readonly: true,
              });
    });

    if (fieldManagersLevelDataCurrent.length !== 0) {
        updatedTabList.push("Field manager comparison");
        updatedTabContent.push({
            content: (
                <IndividualFieldManagerComparison
                    data={fieldManagerComparison}
                    displayOnlyContent={displayOnlyContent}
                />
            ),
            readonly: true,
        });
    }

    // A bit of data cleanup here TODO: Move this to Context.js at some point
    fieldManagersLevelDataCurrent.forEach((fieldManager) => {
        if (!fieldManagers.find((fieldManagerItem) => fieldManagerItem.name === fieldManager.name)) {
            fieldManagers.push({
                name: fieldManager.name,
                relationship: {},
                business: {},
                type: fieldManager.type,
            });
        }
    });

    if (fieldManagersLevelDataCurrent.length !== 0) {
        updatedTabList.push("Individual field managers");
    }

    fieldManagersLevelDataCurrent.forEach((fieldManager) => {
        fieldManagers.forEach((fieldManagerItem) => {
            if (fieldManagerItem.name === fieldManager.name) {
                fieldManagerItem[fieldManager.fieldManagerCompetence].data = fieldManager.data;
                fieldManagerItem[fieldManager.fieldManagerCompetence].items = fieldManager.items;
            }
        });

        fieldManager.data.forEach((fieldManagerDataItem) => {
            fieldManagerComparison[fieldManager.fieldManagerCompetence].data.push(fieldManagerDataItem);
        });
    });

    fieldManagersDataCurrent.forEach((fieldManagerItem) => {
        if (fieldManagerItem.name === "Business competence") {
            // Add benchmark and current data to individual field manager data
            fieldManagerComparison.business.data = fieldManagerComparison.business.data.concat(fieldManagerItem.data);

            fieldManagers.forEach((fieldManager) => {
                fieldManager.business.data = fieldManager.business.data.concat(fieldManagerItem.data);
                fieldManagerItem.items.forEach((fieldManagerItemItem) => {
                    fieldManager.business.items.forEach((fieldManagerItem) => {
                        if (fieldManagerItem.data.length === 1) {
                            if (fieldManagerItemItem.itemName === fieldManagerItem.itemName) {
                                fieldManagerItem.data = fieldManagerItem.data.concat(fieldManagerItemItem.data);
                            }
                        }
                    });
                });
            });
        } else if (fieldManagerItem.name === "Relationship competence") {
            // Add benchmark and current data to individual field manager data
            fieldManagerComparison.relationship.data = fieldManagerComparison.relationship.data.concat(
                fieldManagerItem.data
            );

            fieldManagers.forEach((fieldManager) => {
                fieldManager.relationship.data = fieldManager.relationship.data.concat(fieldManagerItem.data);
                fieldManagerItem.items.forEach((fieldManagerItemItem) => {
                    fieldManager.relationship.items.forEach((fieldManagerItem) => {
                        if (fieldManagerItem.data.length === 1) {
                            if (fieldManagerItemItem.itemName === fieldManagerItem.itemName) {
                                fieldManagerItem.data = fieldManagerItem.data.concat(fieldManagerItemItem.data);
                            }
                        }
                    });
                });
            });
        }
    });

    if (fieldManagersLevelDataCurrent.length !== 0) {
        updatedTabContent.push({
            content: <IndividualFieldManager data={fieldManagers} displayOnlyContent={displayOnlyContent} />,
            readonly: true,
        });
    }

    return { tabList: updatedTabList, tabContent: updatedTabContent };
}
