import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useCycle } from "framer-motion";
import { ArrowLongUpIcon, PrinterIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router";
import Tippy from "@tippyjs/react";

import { BUTTON_VARIANT_TYPES, REPORT_NAMES } from "../../../../../../utils/constants";
import { ReactComponent as SubMenuItemArrow } from "../../../../../../assets/return-down-forward-outline.svg";

import JumpToLink from "../../SharedChartComponents/JumpToLink";
import DashboardLayout from "../../../../../SharedComponents/DashboardLayout";
import ReportHeader from "../../SharedChartComponents/ReportHeader";
import Recruitment from "../MenuDriven/Recruitment";
import SideSection from "../../SharedChartComponents/SideSection";
import TabbedNavigationLayout from "../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import Toggle from "../../../../../SharedComponents/Form/Toggle";
import SaleableContent from "./SaleableContent";
import CoachingGuideline from "../MenuDriven/CoachingGuideline";
import Button from "../../../../../SharedComponents/Button";
import Alert, { ALERT_TYPE } from "../../../../../SharedComponents/Alert";

export default function Saleable(props) {
    return (
        <DashboardLayout title={"Report"} requiredPermissions={[]}>
            <SaleableMeat {...props} />
        </DashboardLayout>
    );
}

export function SaleableMeat(props) {
    const { score, testId, demo, coacheeName, showOldCoachingGuideline } = props;

    const [onlyCharts, setOnlyCharts] = useCycle(false, true);
    const [showCoachingGuideline, setShowCoachingGuideline] = useCycle(true, false);
    const [tabIndex, setTabIndex] = useState(0);
    const [printToPDFMode, setPrintToPDFMode] = useState(false);
    const [navigatingToPrint, setNavigatingToPrint] = useState(false);

    const location = useLocation();

    let currentCoacheeName = location.state?.userName
        ? location.state.userName
        : coacheeName
        ? coacheeName
        : "Psych Press";

    const recruitmentReport = useRef(null);
    const snapshot = useRef(null);
    const detailedSummary = useRef(null);
    const coachingGuideline = useRef(null);
    const leadership = useRef(null);
    const influencing = useRef(null);
    const subordinate = useRef(null);
    const teamRoles = useRef(null);
    const interpersonal = useRef(null);
    const thinking = useRef(null);
    const coping = useRef(null);
    const background = useRef(null);
    const rationale = useRef(null);
    const responsibilityStyleIndex = useRef(null);
    const personalityProfileIndex = useRef(null);
    const generalInformation = useRef(null);
    const top = useRef(null);
    const recruitmentIntroduction = useRef(null);
    const recruitmentBackground = useRef(null);
    const saleableIntroduction = useRef(null);

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop - (window.innerWidth < 1280 ? 170 : 20),
            behavior: "smooth",
        });
    };

    const tabList = [score.reportName === REPORT_NAMES.SALEABLE_ZOLL ? "Zoll Sales Profile" : "Sales Profile"];

    if (score.saleableZollRecruitmentProfile || demo) {
        tabList.push(score.reportName === REPORT_NAMES.SALEABLE_ZOLL ? "Zoll General Profile" : "General Profile");
    }

    if (showOldCoachingGuideline) {
        tabList.push("Coaching guideline");
    }

    const tabContent = [
        {
            title: tabList[0],
            content: (
                <SaleableContent
                    score={score.generalZollProfile ? score.generalZollProfile : score}
                    coacheeName={currentCoacheeName}
                    showCoachingGuideline={showCoachingGuideline}
                    onlyCharts={onlyCharts}
                    jumpToRefs={{
                        saleableIntroduction: saleableIntroduction,
                        rationale: rationale,
                        responsibilityStyleIndex: responsibilityStyleIndex,
                        personalityProfileIndex: personalityProfileIndex,
                        generalInformation: generalInformation,
                    }}
                    printToPDFMode={printToPDFMode}
                />
            ),
            readonly: true,
        },
    ];

    if (score.saleableZollRecruitmentProfile || demo) {
        tabContent.push({
            title: tabList[1],
            content: (
                <Recruitment
                    onlyCharts={onlyCharts}
                    score={score.saleableZollRecruitmentProfile}
                    coacheeName={currentCoacheeName}
                    showCoachingGuideline={showCoachingGuideline}
                    jumpToRefs={{
                        recruitmentReport: recruitmentReport,
                        leadership: leadership,
                        snapshot: snapshot,
                        detailedSummary: detailedSummary,
                        recruitmentIntroduction: recruitmentIntroduction,
                        recruitmentBackground: recruitmentBackground,
                    }}
                    noTitle
                    printToPDFMode={printToPDFMode}
                />
            ),
            readonly: true,
        });
    }

    if (showOldCoachingGuideline) {
        tabContent.push({
            title: "Coaching guideline",
            content: (
                <CoachingGuideline
                    score={score.coachingGuideline}
                    coacheeName={currentCoacheeName}
                    onlyCharts={onlyCharts}
                    jumpToRefs={{
                        coachingGuideline: coachingGuideline,
                        leadership: leadership,
                        influencing: influencing,
                        subordinate: subordinate,
                        teamRoles: teamRoles,
                        interpersonal: interpersonal,
                        thinking: thinking,
                        coping: coping,
                        background: background,
                    }}
                    noTitle
                />
            ),
            readonly: true,
        });
    }

    const findFirstElementInViewPort = (elements) =>
        Array.prototype.find.call(elements, (element) => element.getBoundingClientRect().y > 0);

    const containerRef = useRef(null);

    const scrollTo = useMemo(() => {
        const nodeElements = containerRef.current?.querySelectorAll("[data-item]");
        if (nodeElements) {
            return findFirstElementInViewPort(nodeElements);
        }

        return undefined;

        // Find a way to avoid using useMemo
        // eslint-disable-next-line
    }, [onlyCharts]);

    useLayoutEffect(() => {
        if (scrollTo) {
            scrollTo.scrollIntoView();
            window.scrollBy(0, -32);
        }
    }, [scrollTo, onlyCharts]);

    function handleReportInteraction() {
        setPrintToPDFMode(false);
    }

    const JumpToLinks = (props) => {
        const { singles } = props;

        return (
            <>
                {!singles && (
                    <div className="flex flex-row gap-2 items-center">
                        <div className="text-slate-500 font-medium text-sm">Jump to:</div>
                    </div>
                )}
                <div className={"flex gap-3 " + (singles ? "flex-row" : "flex-col")}>
                    <JumpToLink
                        text="Top"
                        icon={!singles && <ArrowLongUpIcon className="block h-4 w-4" />}
                        colour={"primary"}
                        scrollTo={() => scrollToSection(top)}
                    />
                    <div
                        className={
                            "flex gap-2 " +
                            (tabIndex === 0 ? "block " : "hidden ") +
                            (singles ? "flex-row" : "flex-col")
                        }
                    >
                        <div className={"flex gap-2 flex-wrap " + (singles ? "flex-row" : "flex-col ml-2")}>
                            <JumpToLink
                                text="Personality profile index"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(personalityProfileIndex)}
                            />
                            <JumpToLink
                                text="Response style index"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(responsibilityStyleIndex)}
                            />
                            <JumpToLink
                                text="Overview"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(saleableIntroduction)}
                                hidden={onlyCharts}
                            />
                            <JumpToLink
                                text="Rationale"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(rationale)}
                                hidden={onlyCharts}
                            />
                            <JumpToLink
                                text="Further & general information"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(generalInformation)}
                                hidden={onlyCharts}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            "flex flex-col gap-2 " +
                            (tabIndex === 1 ? "block " : "hidden ") +
                            (singles ? "flex-row" : "flex-col")
                        }
                    >
                        <div className={"flex flex-wrap gap-2 " + (singles ? "flex-row" : "flex-col ml-2")}>
                            <JumpToLink
                                text="Snapshot"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(snapshot)}
                            />
                            <JumpToLink
                                text="Detailed Summary"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(detailedSummary)}
                            />
                            <JumpToLink
                                text="Overview"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(recruitmentIntroduction)}
                                hidden={onlyCharts}
                            />
                            <JumpToLink
                                text="Background"
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(recruitmentBackground)}
                                hidden={onlyCharts}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            "flex flex-col gap-2 " +
                            (tabIndex === 2 ? "block " : "hidden ") +
                            (singles ? "flex-row" : "flex-col")
                        }
                    >
                        <div className={"flex flex-wrap gap-2 " + (singles ? "flex-row" : "flex-col ml-2")}>
                            <JumpToLink
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                text={"Leadership styles"}
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(leadership)}
                            />
                            <JumpToLink
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                text="Influencing styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(influencing)}
                            />
                            <JumpToLink
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                text="Subordinate Styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(subordinate)}
                            />
                            <JumpToLink
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                text="Team roles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(teamRoles)}
                            />
                            <JumpToLink
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                text="Interpersonal and relationships building styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(interpersonal)}
                                hidden={onlyCharts}
                            />
                            <JumpToLink
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                text="Thinking and decision‐making styles"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(thinking)}
                                hidden={onlyCharts}
                            />
                            <JumpToLink
                                icon={!singles && <SubMenuItemArrow className="block h-4 w-4" />}
                                text="Coping with pressure and stress"
                                colour={"secondary"}
                                scrollTo={() => scrollToSection(coping)}
                                hidden={onlyCharts}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div ref={top} className="flex flex-col gap-6" data-testid={testId && testId}>
            <ReportHeader reportName="Saleable" userName={currentCoacheeName} />
            <div
                className={
                    "sticky top-4 left-4 bg-white p-3 rounded-xl border border-slate-100 shadow-lg " +
                    "z-50 xl:hidden print:hidden w-fit"
                }
            >
                <JumpToLinks singles={true} />
                <Tippy
                    content={
                        <Alert
                            text={
                                <div className={"flex flex-col gap-2"}>
                                    <div className={"font-medium"}>For best results:</div>
                                    <ul className={"list-disc"}>
                                        <li>
                                            <span className={"font-medium"}>Use Google Chrome</span>
                                        </li>
                                        <li>
                                            Destination:
                                            <span className={"font-medium"}> "Save as PDF"</span>
                                        </li>
                                        <li>
                                            Layout: <span className={"font-medium"}>Portrait</span>
                                        </li>
                                        <li>
                                            Paper size: <span className={"font-medium"}>A4</span>
                                        </li>
                                        <li>
                                            <span className={"font-medium"}>Un-tick "Headers and footers"</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                            type={ALERT_TYPE.PRIMARY}
                            active
                            noMargins
                        />
                    }
                    arrow={false}
                    animation={"shift-away-subtle"}
                    placement={"bottom-start"}
                >
                    <div>
                        <Button
                            onClick={() => {
                                setPrintToPDFMode(true);
                                setNavigatingToPrint(true);
                                setTimeout(() => {
                                    window.print();
                                    setNavigatingToPrint(false);
                                }, 5000);
                            }}
                            text={<span className={"w-max"}>Print to PDF using browser</span>}
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            icon={<PrinterIcon className="block h-4 w-4 mr-2" />}
                            iconPositionLeft
                            loading={navigatingToPrint}
                            smallButton
                            extraStyling={"h-max mt-4"}
                        />
                    </div>
                </Tippy>
            </div>
            <div className="xl:hidden bg-white flex flex-row gap-4 p-2 px-2.5 rounded-xl w-fit border border-slate-100 shadow-sm print:hidden">
                <div className="w-max" onClick={() => setOnlyCharts()}>
                    <Toggle text={"Executive mode"} triggered={onlyCharts} />
                </div>
                <div
                    className={"w-max " + (tabIndex !== 1 ? "hidden" : "block")}
                    onClick={() => setShowCoachingGuideline()}
                >
                    <Toggle
                        text={"Coaching guidelines"}
                        triggered={showCoachingGuideline}
                        backgroundColour={"bg-emerald-600"}
                        textColour={"text-emerald-600"}
                    />
                </div>
            </div>
            <div className={"grid grid-cols-1 xl:grid-cols-3 gap-6 print:p-0"}>
                <div ref={containerRef} className="col-span-2 flex flex-col gap-8">
                    <TabbedNavigationLayout
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabList={tabList}
                        tabContent={tabContent}
                        tabsRowOnly
                        noContainer
                        secondaryTabs
                        noTitle={!printToPDFMode}
                        displayOnlyContent={printToPDFMode}
                    />
                </div>
                <SideSection
                    extraContent={
                        <div
                            className={
                                "flex flex-col gap-4 print:hidden sticky top-4 left-4 p-4 rounded-xl " +
                                "shadow-xl border border-slate-100 z-50 hidden w-auto xl:flex 2xl:w-max " +
                                "print:hidden bg-white " +
                                (navigatingToPrint ? "pointer-events-none mt-[43px]" : "pointer-events-auto mt-[60px]")
                            }
                            {...(!navigatingToPrint && { onMouseLeave: handleReportInteraction })}
                        >
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="text-slate-500 font-medium text-sm">Actions:</div>
                                </div>
                                <Button
                                    onClick={() => {
                                        setPrintToPDFMode(true);
                                        setNavigatingToPrint(true);
                                        setTimeout(() => {
                                            window.print();
                                            setNavigatingToPrint(false);
                                        }, 5000);
                                    }}
                                    text={"Print to PDF using browser"}
                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                    icon={<PrinterIcon className="block h-4 w-4 mr-2" />}
                                    iconPositionLeft
                                    loading={navigatingToPrint}
                                />
                                <Alert
                                    text={
                                        <div className={"flex flex-col gap-2"}>
                                            <div className={"font-medium"}>For best results:</div>
                                            <ul className={"list-disc"}>
                                                <li>
                                                    <span className={"font-medium"}>Use Google Chrome</span>
                                                </li>
                                                <li>
                                                    Destination:
                                                    <span className={"font-medium"}> "Save as PDF"</span>
                                                </li>
                                                <li>
                                                    Layout: <span className={"font-medium"}>Portrait</span>
                                                </li>
                                                <li>
                                                    Paper size: <span className={"font-medium"}>A4</span>
                                                </li>
                                                <li>
                                                    <span className={"font-medium"}>Un-tick "Headers and footers"</span>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    type={ALERT_TYPE.PRIMARY}
                                    active
                                    noMargins
                                />
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="text-slate-500 font-medium text-sm">Options:</div>
                                </div>
                                <div className={"flex flex-col gap-4 "}>
                                    <div className="w-max" onClick={() => setOnlyCharts()}>
                                        <Toggle text={"Executive mode"} triggered={onlyCharts} />
                                    </div>
                                    <div
                                        className={"w-max " + (tabIndex === 3 ? "hidden" : "block")}
                                        onClick={() => setShowCoachingGuideline()}
                                    >
                                        <Toggle
                                            text={"Coaching guidelines"}
                                            triggered={showCoachingGuideline}
                                            backgroundColour={"bg-emerald-600"}
                                            textColour={"text-emerald-600"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <JumpToLinks singles={false} />
                        </div>
                    }
                />
            </div>
        </div>
    );
}
