import React, { useState, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/solid";

import {
    BASIC_ANIMATION_VARIANTS,
    BUTTON_VARIANT_TYPES,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../utils/constants";
import { scoreRUThereInterviewRecording } from "../../../../utils/api";
import { ReactComponent as ReviewIllustration } from "../../../../assets/review.svg";

import { Context } from "../../../../context/Context";
import StarRating from "../../../SharedComponents/StarRating";
import Textarea from "../../../SharedComponents/Form/Textarea";
import Button from "../../../SharedComponents/Button";
import Alert, { ALERT_TYPE } from "../../../SharedComponents/Alert";

const FeedbackForm = (props) => {
    const { recording, handleShow, showFeedbackForm, updateItemStatus, setAlert, setAlertType } = props;

    const context = useContext(Context);

    const [feedback, setFeedback] = useState("");
    const [rating, setRating] = useState(0);
    const [loading, setLoading] = useState(false);
    const [feedbackAlert, setFeedbackAlert] = useState("");
    const [feedbackAlertType, setFeedbackAlertType] = useState(ALERT_TYPE.ERROR);

    const handleSubmit = async () => {
        setLoading(true);

        if (!context.userPermissions.includes(COACH_PLATFORM_PERMISSIONS.SAVE_INTERVIEW_RECORDING_SCORE)) {
            setFeedbackAlertType(ALERT_TYPE.ERROR);
            setFeedbackAlert("You do not have the necessary permission to submit a review");
            setLoading(false);
            return;
        }

        if ((!rating || rating === 0) && (!feedback || feedback === "")) {
            setFeedbackAlertType(ALERT_TYPE.ERROR);
            setFeedbackAlert("Please enter either a rating or feedback");
            setLoading(false);
            return;
        }

        try {
            await scoreRUThereInterviewRecording(
                recording.recordingId,
                context.profileDetails.userIdentifier,
                feedback === "" ? null : feedback,
                rating < 1 ? null : rating
            );

            setAlertType(ALERT_TYPE.SUCCESS);
            setAlert("Successfully submitted review for interview recording");
        } catch (e) {
            setFeedbackAlertType(ALERT_TYPE.ERROR);
            setFeedbackAlert(e.message);
        }
        updateItemStatus();

        setLoading(false);
    };

    return (
        <AnimatePresence>
            {showFeedbackForm && (
                <>
                    <motion.div
                        initial={"inactive"}
                        animate={["active", { zIndex: 30 }]}
                        exit={"inactive"}
                        transition={{ duration: 0.4 }}
                        variants={BASIC_ANIMATION_VARIANTS}
                        className={
                            "flex flex-col fixed gap-3 fixed m-auto inset-x-0 inset-y-0 h-max p-4 rounded-xl " +
                            "bg-slate-100 shadow-2xl w-[600px]"
                        }
                    >
                        <div className="flex justify-between items-center gap-8">
                            <h3 className="font-medium text-primary-600 tracking-tight font-title">
                                "{recording.question}"
                            </h3>

                            <button
                                type="button"
                                className="focus:outline-none place-self-start"
                                onClick={() => handleShow("feedbackForm")}
                            >
                                <XMarkIcon className="h-5 w-5 text-slate-500 hover:text-slate-700 stroke-current stroke-1" />
                            </button>
                        </div>

                        <Alert
                            text={feedbackAlert}
                            type={feedbackAlertType}
                            active={feedbackAlert}
                            close={() => setFeedbackAlert("")}
                        />

                        <div className="flex flex-col gap-2">
                            <span className="tracking-tight text-sm text-primary-600 font-medium ">Rating</span>

                            <StarRating handleRatingExternally setOwnRating={setRating} starsRating={rating} />
                        </div>

                        <div className="flex flex-col gap-6">
                            <div className="flex flex-col gap-1">
                                <span className={"tracking-tight text-sm text-primary-600 font-medium "}>Feedback</span>

                                <Textarea
                                    id="feedback"
                                    type="textarea"
                                    placeholder="Please write your feedback here.."
                                    backgroundColour="bg-white"
                                    extraStyling="col-span-2"
                                    value={feedback}
                                    onChange={setFeedback}
                                />
                            </div>

                            <div className="flex justify-between items-end">
                                <div className="shrink-0 w-40">
                                    <ReviewIllustration />
                                </div>

                                <div className="flex gap-2 justify-end">
                                    <Button
                                        text="Cancel"
                                        onClick={() => handleShow("feedbackForm")}
                                        colour={BUTTON_VARIANT_TYPES.GRAY_DARKER}
                                    />

                                    <Button
                                        text="Submit"
                                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                        loading={loading}
                                        onClick={handleSubmit}
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    <div
                        onClick={() => handleShow("feedbackForm")}
                        className="opacity-20 fixed inset-0 z-20 bg-black"
                    />
                </>
            )}
        </AnimatePresence>
    );
};

export default FeedbackForm;
